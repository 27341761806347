import React from "react";
import styles from "../../task.module.scss";

const StatusDetailsField = ({ statusStyle, fieldValue }) => {
  return (
    <div className={styles.statusDetail}>
      <div className={styles.operationSymbol} style={statusStyle} />
      <div className={styles.detailField}>{fieldValue}</div>
    </div>
  );
};

export default StatusDetailsField;
