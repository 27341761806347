import React from "react";
import styles from "../damages.module.scss";
import fileUpload from "../../../../images/damages/image-upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { useDropzone } from "react-dropzone";

const ImageInput = ({
  index,
  file,
  files,
  filePreviews,
  setFiles,
  setFilePreviews,
  imgCount,
  setImgCount,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, acceptedFiles[0]]);
      setFilePreviews([...filePreviews, URL.createObjectURL(acceptedFiles[0])]);
      setImgCount(imgCount + 1);
    },
  });
  return (
    <div className={styles.imageUpload} key={index}>
      <label for={`file-input-${index}`}>
        <img
          className={styles.imageUploader}
          src={file ? filePreviews[index] : fileUpload}
          style={{ cursor: "pointer" }}
        ></img>
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input
          {...getInputProps({ id: `file-input-${index}` })}
          disabled={filePreviews[index]}
        />
      </div>
      {file && (
        <div className={styles.imageTimesIcon}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="sm"
            onClick={() => {
              setFiles(files.filter((file, i) => i !== index));
              setFilePreviews(filePreviews.filter((file, i) => i !== index));
              setImgCount(imgCount - 1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageInput;
