import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowToBottom,
  faChevronLeft
} from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import UploadDocumentModal from "./UploadDocumentModal";
import DocumentModal from "./DocumentModal";
import DisplayDocumentModal from "./DisplayDocumentModal";
import RenderImage from "../RenderImage";
import { m, formatNavTitle } from "../../../utils";
import "../vehicle.scss";

class DocumentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docModal: false,
      doc: null,
      add: false,
      display: "documents",
      edit: false
    };
  }

  componentDidMount() {
    const {
      history,
      store,
      vehicle,
      setOpenList,
      tab,
      title,
      tabNav
    } = this.props;
    store.changeNavigatedPage(
      formatNavTitle(
        vehicle,
        history.location.pathname + `/${tab}/${title}`,
        setOpenList
      )
    );
  }

  toggleAdd = () => this.setState({ add: !this.state.add });

  handleClick = doc => {
    this.setState({ docModal: true, doc: doc });
  };

  handleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  handleBack = () => {
    this.props.setOpenList("");
  };

  handleClose = () => {
    this.setState({ docModal: false, doc: null });
  };

  render() {
    const { documents, refetch, vehicleId, setOpenList, title } = this.props;
    const { docModal, doc, add, edit } = this.state;

    console.log("DOCUMENTS", documents);

    const orderedDocuments = _.orderBy(
      documents,
      ["uploadedAt", "name"],
      ["desc", "asc"]
    );

    console.log("ORDERED_DOCUMENTS", orderedDocuments);

    if (orderedDocuments.length < 1) {
      return <div>No records</div>;
    } else {
      return (
        <Fragment>
          <UploadDocumentModal
            vehicleId={vehicleId}
            toggleAdd={this.toggleAdd} // handle upload document modal
            add={add} // upload document modal open/close state
            refetch={refetch}
            title={title}
          />

          {
            // <div className="back-button">
            //   <FontAwesomeIcon
            //     className="blue"
            //     icon={faChevronLeft}
            //     onClick={this.handleBack}
            //   />
            //   <p className="blue back-text" onClick={this.handleBack}>
            //     Back
            //   </p>
            // </div>
          }
          <div className="documents-table">
            <div className="doc-title">
              <div className="big-title">{title}</div>
              <div
                className="blue edit-tab"
                style={{ fontWeight: "normal" }}
                onClick={this.toggleAdd}
              >
                Add {title}
              </div>
            </div>
            <div className="doc-details-layout">
              <div>
                <div className="medium-title">Last Uploaded Document</div>
                <div
                  className="doc-container"
                  onClick={() => {
                    this.handleClick(orderedDocuments[0]);
                  }}
                >
                  <TableRow
                    width="328px"
                    align="center"
                    color="#FFFFFF"
                    height="72px"
                    margin="0 0 12px 0"
                    padding="0.5em 12px"
                    text="left"
                    hover={true}
                    hoverColor="#414141"
                    hoverText="#FFFFFF"
                  >
                    <TableCell width="15%">
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          window.open(orderedDocuments[0].imageLink, "_blank");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <RenderImage
                          src={orderedDocuments[0].imageLink}
                          imageClass="upload-image"
                        />
                      </div>
                    </TableCell>

                    <TableCell width={"40%"}>
                      <div className="doc-body">
                        <p className="small-title">Uploaded</p>
                        <p style={{ margin: "0" }}>
                          {m(orderedDocuments[0].uploadedAt).format(
                            "MMM D, YYYY"
                          )}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell width={"40%"}>
                      <div className="doc-body">
                        {orderedDocuments[0].expiryDate === null ? (
                          <span>
                            <p className="small-title">Expiry</p>
                            <p style={{ margin: "0" }}>N/A</p>
                          </span>
                        ) : (
                          <div>
                            <p className="small-title">Expiry</p>
                            <p style={{ margin: "0" }}>
                              {m(orderedDocuments[0].expiryDate).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell width={"5%"}>
                      <div
                        className="table-edit"
                        onClick={e => {
                          e.stopPropagation();
                          window.open(orderedDocuments[0].imageLink, "_blank");
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowToBottom}
                          style={{ color: "#128DFF", fontSize: "23px" }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </div>

                <div className="medium-title">Previous Documents</div>
                {orderedDocuments
                  .slice(1, orderedDocuments.length)
                  .map((doc, index) => (
                    <div
                      className="doc-container"
                      onClick={() => {
                        this.handleClick(doc);
                      }}
                    >
                      <TableRow
                        width="328px"
                        align="center"
                        color="#FFFFFF"
                        height="72px"
                        margin="0 0 12px 0"
                        padding="0.5em 12px"
                        text="left"
                        hover={true}
                        hoverColor="#414141"
                        hoverText="#FFFFFF"
                      >
                        <TableCell width="15%">
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              window.open(doc.imageLink, "_blank");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <RenderImage
                              src={doc.imageLink}
                              imageClass="upload-image"
                            />
                          </div>
                        </TableCell>

                        <TableCell width={"40%"}>
                          <div className="doc-body">
                            <p className="small-title">Uploaded</p>
                            <p style={{ margin: "0" }}>
                              {m(orderedDocuments[0].uploadedAt).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell width={"40%"}>
                          <div className="doc-body">
                            {doc.expiryDate === null ? (
                              <span>
                                <p className="small-title">Expiry</p>
                                <p style={{ margin: "0" }}>N/A</p>
                              </span>
                            ) : (
                              <div>
                                <p className="small-title">Expiry</p>
                                <p style={{ margin: "0" }}>
                                  {m(orderedDocuments[0].expiryDate).format(
                                    "MMM D, YYYY"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell width={"5%"}>
                          <div
                            className="table-edit"
                            onClick={e => {
                              e.stopPropagation();
                              window.open(doc.imageLink, "_blank");
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowToBottom}
                              style={{ color: "#128DFF", fontSize: "23px" }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </div>
                  ))}
              </div>

              <div>
                {docModal ? (
                  <DocumentModal
                    doc={doc}
                    handleClose={this.handleClose}
                    refetch={refetch}
                  />
                ) : (
                  <div className="document-preview"></div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default DocumentDetails;
