import _ from "lodash";
import moment from "moment";
import TextValue from "./CellValues/Base/TextValue";
import UserValue from "./CellValues/Base/UserValue";
import DetailsValue from "./CellValues/Base/DetailsValue";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor].value,
    };
  };
};

const baseDateProcessor = (accessor, vehicleTimeZone) => {
  return (data) => {
    const value = data[accessor].value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz("UTC")
            .format("MMM D, YYYY h:mm:ss A z")
        : "N/A",
    };
  };
};

const rowComponentsTimeline = (vehicleTimeZone) => ({
  date: {
    component: TextValue,
    dataProcessor: baseDateProcessor("timelineDate", vehicleTimeZone),
  },
  category: {
    component: TextValue,
    dataProcessor: baseTextProcessor("timelineCategory"),
  },
  user: {
    component: UserValue,
    dataProcessor: (data) => {
      const user = data["userDetails"].value;
      return {
        user: user,
      };
    },
  },
  details: {
    component: DetailsValue,
    dataProcessor: (data) => {
      const details = data["timelineDetails"].value;
      return {
        details: details,
      };
    },
  },
});

export { rowComponentsTimeline };
