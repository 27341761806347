import React, { useState } from "react";
import Option from "../../../../artzu/src/Option";
import SelectField from "../../../../artzu/src/SelectField";
import styles from "./dropdown.module.scss";

const Dropdown = ({
  items,
  onChange,
  label,
  initialValue,
  error,
  width,
  height,
  parkingSpecific,
}) => {
  const initialItem = items.find((item) => item.id == initialValue);
  const prompt = (initialItem && initialItem.name) || "Select one";

  return (
    <div className={styles.dropdown} style={{ margin: parkingSpecific && 0 }}>
      <SelectField
        initialValue={initialValue}
        borderRadius="12px"
        prompt={prompt}
        width={width ? width : "124px"}
        height={height ? height : "24px"}
        containerHeight={"52px"}
        fontSize="16px"
        fontWeight="400"
        primaryColor={"#414141"}
        margin={"8px auto 0"}
        title={label}
        onChange={(value, _) => onChange(value)}
        error={error}
        parkingSpecific
      >
        {items.map((item) => (
          <Option
            width="124px"
            height="20px"
            fontSize="16px"
            fontWeight="400"
            label={item.name}
            value={item.id}
          />
        ))}
      </SelectField>
    </div>
  );
};

export default Dropdown;
