import SelectDateTime from "./SelectDateTime";
import React from "react";
import styles from "../changeStatus.module.scss";

const TimeBounds = ({ startTime, endTime, changeStatusArgs, showEndTime }) => (
  <div className={styles.datesContainer}>
    <SelectDateTime
      name="Start Date"
      handleChange={value => changeStatusArgs("startTime", value)}
      dateTime={startTime}
    />
    {showEndTime && (
      <SelectDateTime
        name="End Date"
        handleChange={value => changeStatusArgs("endTime", value)}
        dateTime={endTime}
      />
    )}
  </div>
);

export default TimeBounds;
