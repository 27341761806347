import React, { Fragment, useState } from "react";
import styles from "./parkingSpot.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import EditStatus from "../InformationTable/BatchActions/ParkingBatchActions/EditStatus";
import Modal from "../../artzu/src/Modal";

const ParkingSpotTitle = ({ identifier, refetch, spotId }) => {
  const [isEdit, setIsEdit] = useState();

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <Fragment>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Spot: {identifier}</div>
        <button className={styles.editButton} onClick={toggleEdit}>
          <FontAwesomeIcon icon={faPencil} className={styles.editIcon} /> Edit
        </button>
      </div>
      <Modal open={isEdit} padding="none" backgroundColor={"none"}>
        <div className={styles.actionContainer}>
          <EditStatus
            selectedOption={"Edit status"}
            close={toggleEdit}
            toggleRefresh={refetch}
            spotId={spotId}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default ParkingSpotTitle;
