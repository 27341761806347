import React from "react";
import styled from "styled-components";
import { getColor, getSize, isDisabled } from "./buttonHelpers.js";

const StyledButton = styled.button`
	${({ primary, theme, color = theme.main }) => getColor(primary, color)}

	${({ fullSize = "reg" }) => getSize(fullSize)}
	${({ disabled }) => isDisabled(disabled)}
	background-color: ${({ backColor }) => backColor};
	border-radius: ${({ radius }) => radius || "50px"};
	border: ${({ border }) => border};
	font-weight: ${({ weight }) => weight || "500"};
	margin: ${({ margin }) => margin || "0"};
	padding: ${({ padding }) => padding || "0.36em 1em"};
	font-size: ${({ size }) => size};
	width: ${({ fullWidth }) => (fullWidth ? fullWidth : "8.5em")};
	height: ${({ height }) => height};
	overflow-x: ${({ overX }) => overX};
	white-space: ${({ space }) => space};
	text-overflow: ${({ textOver }) => textOver};
	${({ disabled }) => isDisabled(disabled)}

	transition: 0.25s;
	&:focus {
		outline: none;
	}
`;

const Button = ({ label = "", ...props }) => (
  <StyledButton {...props}>{label}</StyledButton>
);

export default Button;
