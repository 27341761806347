import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DATA, SAVE_EDITS, UPSERT_PRESET } from "./misc/gqlQueries";

import Table from "./Table";
import ActionBar from "./ActionBar";
import NavBar from "./NavBar";
import Tabs from "./Tabs";
import styles from "./informationTable.module.scss";
import Loading from "./Loading";
import Footer from "./Footer";
import BatchActions from "./BatchActions";
import _ from "lodash";
import { observer, inject } from "mobx-react";
import ManageAllTagsModal from "./BatchActions/Actions/AddTag/ManageTags/ManageAll";
import { Fragment } from "react";
import TaskBatchActions from "./BatchActions/TaskBatchActions";
import ParkingBatchActions from "./BatchActions/ParkingBatchActions";
import DamageBatchActions from "./BatchActions/DamageBatchActions";

const InformationTable = inject("store")(
  observer(
    ({
      store,
      navbar,
      hasTabs,
      editable,
      background,
      rowComponentType,
      startTerms,
      item,
      popupSpecific,
      popupFunc,
      tableName,
      vehicleSpecific,
      parkingLots,
      changeLot,
      id,
      additionalFilters,
      vehicleTimeZone,
    }) => {
      const [paginationArguments, setPaginationArguments] = useState({
        page: 1,
        itemsPerPage: 10,
      });

      const [saveEditsMutation] = useMutation(SAVE_EDITS);

      const [searchValue, setSearchValue] = useState(null);

      const [selectedRows, setSelectedRows] = useState({});

      const [isEditModeActivated, setIsEditModeActivated] = useState(false);

      const [edits, setEdits] = useState({});

      const [isEditModalOpen, setIsEditModalOpen] = useState(false);

      const [isManageTagModalOpen, setIsManageTagModalOpen] = useState(false);

      const modifyEdits = (rowId, changedValues) => {
        const editsCopy = _.cloneDeep(edits);
        const newValues = { ...(editsCopy[rowId] || {}), ...changedValues };
        editsCopy[rowId] = newValues;
        setEdits(editsCopy);
      };

      const saveEdits = () => {
        const saveArguments = _.reduce(
          edits,
          (result, value, key) => {
            const row = {
              itemId: key,
              changes: _.reduce(
                value,
                (r, v, k) => {
                  r.push({
                    key: k,
                    value: v.newValue,
                  });
                  return r;
                },
                []
              ),
            };
            result.push(row);
            return result;
          },
          []
        );
        toggleEditModalOpen();
        endEdits();
        saveEditsMutation({
          variables: {
            tableName: "vehicles",
            edits: saveArguments,
          },
        }).then((data) => {
          refetch({});
        });
      };

      const reviewEdits =
        Object.keys(edits).length > 0
          ? () => {
              setIsEditModalOpen(true);
            }
          : null;

      const deleteEdit = (rowId) => {
        const editsCopy = _.cloneDeep(edits);
        delete editsCopy[rowId];
        setEdits(editsCopy);
      };

      const startEdit = () => setIsEditModeActivated(true);
      const endEdits = () => {
        setEdits({});
        setIsEditModeActivated(false);
      };
      const toggleEditModalOpen = () => setIsEditModalOpen(!isEditModalOpen);
      const startManageTag = () => setIsManageTagModalOpen(true);
      const closeManageTag = () => setIsManageTagModalOpen(false);

      const selectRow = (rowId) => {
        const stateCopy = { ...selectedRows };
        const rowState = stateCopy[rowId];

        const newState = rowState ? false : true;

        stateCopy[rowId] = newState;
        setSelectedRows(stateCopy);
      };

      const baseVariables = {
        tableName: tableName,
        paginationArguments: paginationArguments,
        searchValue: searchValue,
        idToFilter: id ? id : null,
        additionalFilters: additionalFilters,
      };

      const {
        loading,
        error,
        data,
        refetch,
        startPolling,
        stopPolling,
      } = useQuery(GET_DATA, {
        variables: baseVariables,
        fetchPolicy: "network-only",
        // pollInterval: 1000,
      });
      useEffect(() => {
        startPolling(60000);
        return () => {
          stopPolling();
        };
      }, [startPolling, stopPolling]);
      // console.log(data);

      const reloadPage = (changedVariables) => {
        const newVariables = {
          ...baseVariables,
          ...changedVariables,
        };
        refetch(newVariables);
      };

      const changeSort = (column, direction) => {
        const newVariables = {
          sortArguments: {
            column: column,
            direction: direction,
          },
        };

        reloadPage(newVariables);
      };

      const changePage = (newPage) => {
        const newPaginationArguments = {
          ...paginationArguments,
          page: newPage,
        };

        const newVariables = {
          paginationArguments: newPaginationArguments,
        };

        setPaginationArguments(newPaginationArguments);
        reloadPage(newVariables);
      };

      const changeSearchValue = (newSearchValue) => {
        const newVariables = {
          searchValue: searchValue,
        };

        setSearchValue(newSearchValue);
        reloadPage(newVariables);
      };

      const changeColumnsValue = (newColumnsValue) => {
        const newVariables = {
          columnNames: newColumnsValue,
        };
        reloadPage(newVariables);
      };

      const changeSelectedTab = (newSelectedTab) => {
        const newVariables = {
          selectedTab: newSelectedTab,
        };
        reloadPage(newVariables);
      };

      const changeParkingLotFilter = (newParkingLotNames) => {
        const newVariables = {
          parkingLotNames: newParkingLotNames,
        };
        reloadPage(newVariables);
      };

      const changeFilters = (newFilters) => {
        const newVariables = {
          filters: newFilters,
        };

        reloadPage(newVariables);
      };

      const changeAdminUserOnly = (childData) => {
        const newVariables = {
          currentAdminUserOnly: childData,
        };

        reloadPage(newVariables);
      };

      // Not a good solution, however it will do until we determine a better pattern on how to handle these filters
      const setParkingLotFilter = (selectedLotNames) => {
        changeParkingLotFilter(selectedLotNames);
      };

      const selectedLotNames =
        store.selectedLots && store.selectedLots.map((lot) => lot.name);

      if (data) {
        console.log(data);
        const { table, filters, columns, sort, tabs } = data.getInformationPage;
        const savedSelectedLotNames = filters.parkingLotNames;
        const savedLotDifference = _.xor(
          savedSelectedLotNames,
          selectedLotNames
        );
        if (selectedLotNames && savedLotDifference.length > 0) {
          setParkingLotFilter(selectedLotNames);
        }

        const currentTabKeys = [];
        const currentTabIds = currentTabKeys.map((key) => key.id);

        const currentTabKeysObject = currentTabKeys.reduce((h, value) => {
          h[value.id] = value;
          return h;
        }, {});

        const isAllSelected =
          !_.isEmpty(selectedRows) &&
          !_.some(selectedRows, (value, key) => value === false) &&
          Object.keys(selectedRows).length === currentTabIds.length;

        const clearAllSelections = () => {
          const newSelected = currentTabIds.reduce((h, value) => {
            h[String(value)] = false;
            return h;
          }, {});
          setSelectedRows(newSelected);
        };

        const selectAllRows = () => {
          if (isAllSelected) {
            const newSelected = currentTabIds.reduce((h, value) => {
              h[String(value)] = false;
              return h;
            }, {});
            setSelectedRows(newSelected);
          } else {
            const newSelected = _.reduce(
              currentTabIds,
              (h, value, index) => {
                h[String(value)] = true;
                return h;
              },
              {}
            );
            setSelectedRows(newSelected);
          }
        };

        const selectedRowCount = Object.keys(
          _.filter(selectedRows, (value) => value)
        ).length;

        const selectedRowsWithIdentifier = _.reduce(
          selectedRows,
          (result, value, key) => {
            if (value) {
              result.push(currentTabKeysObject[key]);
            }
            return result;
          },
          []
        );

        const removeSelection = (id) => selectRow(id);
        return (
          <Fragment>
            <div
              className={
                vehicleSpecific
                  ? styles.containerSmall
                  : popupSpecific
                  ? styles.containerPopup
                  : styles.container
              }
            >
              <div>
                {navbar && !popupSpecific && <NavBar navbar={navbar} />}
                <ActionBar
                  deleteEdit={deleteEdit}
                  saveEdits={saveEdits}
                  reviewEdits={reviewEdits}
                  edits={edits}
                  rowIdToIdentifierObject={currentTabKeysObject}
                  modifyEdits={modifyEdits}
                  isEditModeActivated={isEditModeActivated}
                  isEditModalOpen={isEditModalOpen}
                  toggleEditModalOpen={toggleEditModalOpen}
                  startEdit={startEdit}
                  endEdits={endEdits}
                  startManageTag={startManageTag}
                  filters={filters}
                  columns={columns}
                  searchValue={searchValue}
                  changeSearchValue={changeSearchValue}
                  changeColumnsValue={changeColumnsValue}
                  changeFilters={changeFilters}
                  clearAllSelections={clearAllSelections}
                  selectedRowsCount={selectedRowCount}
                  editable={editable}
                  background={background}
                  startTerms={startTerms}
                  popupSpecific={popupSpecific}
                  changeAdminUserOnly={changeAdminUserOnly}
                  tableName={tableName}
                  vehicleId={id}
                  parkingLots={parkingLots}
                  changeLot={changeLot}
                  currentParkingLotId={id}
                />
                {hasTabs && (
                  <Tabs tabs={tabs} changeSelectedTab={changeSelectedTab} />
                )}
                <Table
                  edits={edits}
                  isEditModeActivated={isEditModeActivated}
                  modifyEdits={modifyEdits}
                  isAllSelected={isAllSelected}
                  selectAllRows={selectAllRows}
                  selectRow={selectRow}
                  selectedRows={selectedRows}
                  tabs={tabs}
                  tableData={table}
                  changeSort={changeSort}
                  currentSort={sort}
                  paginationArguments={paginationArguments}
                  changePage={changePage}
                  changeSelectedTab={changeSelectedTab}
                  rowComponentType={rowComponentType}
                  refetch={refetch}
                  baseVariables={baseVariables}
                  popupSpecific={popupSpecific}
                  popupFunc={popupFunc}
                  tableName={tableName}
                  vehicleTimeZone={vehicleTimeZone}
                  hasTabs={hasTabs}
                />
              </div>
              <Footer
                totalRowCount={table.totalRowCount}
                paginationArguments={paginationArguments}
                changePage={changePage}
                item={item}
              />
            </div>
            {tableName === "vehicles" && (
              <BatchActions
                isOpen={selectedRowCount > 0}
                selections={selectedRows}
                removeSelection={removeSelection}
                refetch={refetch}
              />
            )}
            {tableName === "tasks" && (
              <TaskBatchActions
                isOpen={selectedRowCount > 0}
                selectedRows={selectedRows}
                clearAllSelections={clearAllSelections}
                removeSelection={removeSelection}
                tabs={tabs}
                refetch={refetch}
              />
            )}
            {tableName === "parking_spots" && (
              <ParkingBatchActions
                isOpen={selectedRowCount > 0}
                selections={selectedRows}
                removeSelection={removeSelection}
                refetch={refetch}
              />
            )}
            {tableName === "damages" && (
              <DamageBatchActions
                isOpen={selectedRowCount > 0}
                selectedRows={selectedRows}
                removeSelection={removeSelection}
                refetch={refetch}
                clearAllSelections={clearAllSelections}
              />
            )}
            {tableName === "vehicles" && (
              <ManageAllTagsModal
                open={isManageTagModalOpen}
                parentCallbackClose={closeManageTag}
              />
            )}
          </Fragment>
        );
      } else {
        return (
          <div className={styles.container}>
            <Loading />
          </div>
        );
      }
    }
  )
);

export default InformationTable;
