import gql from "graphql-tag";

const GET_CURRENT_ADMIN = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      email
    }
  }
`;

const GET_PARKING_SPOT = gql`
  query getParkingSpot($parkingSpotId: ID!) {
    getParkingSpot(parkingSpotId: $parkingSpotId) {
      id
      identifier
      status
      licensePlate
      updatedAt
      timeInStatus
      bookingReference
      hasCharger
    }
    getSpotTimelineEvents(parkingSpotId: $parkingSpotId) {
      id
      status
      licensePlate
      relatedBooking
      capturedAt
      origin
      originLink
      plateLink
      bookingLink
    }
  }
`;

export { GET_CURRENT_ADMIN, GET_PARKING_SPOT };
