import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledRow = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "space-around"};
  align-items: ${({ align }) => align};
  font-size: ${({ size }) => size};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ color }) => color};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  color: ${({ textColor }) => textColor};
  text-align: ${({ text }) => text};
  border-bottom: ${({ borderB }) => borderB};
  border-top: ${({ borderT }) => borderT};
  cursor: ${({cursor}) => cursor};
  @media (max-width: 1024px) {
    justify-content: ${({ justifyIpad }) => justifyIpad};
  }
  min-width: ${({ minWidth }) => minWidth}
    ${({ hover, hoverColor, hoverText }) =>
      hover
        ? `:hover {
    background-color: ${hoverColor || "#d9d6d585"};
    color: ${hoverText}
    transition: 0.3s;
  }`
        : null};
`;

export const _tableRow = props => {
  return <StyledRow {...props}>{props.children}</StyledRow>;
};

_tableRow.defaultProps = {
  children: "Children required for <TableRow>",
  color: "none",
  hover: false,
  padding: "0.5em 2em",
  border: "1px solid #d3d3d3"
};

_tableRow.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  hover: PropTypes.bool,
  padding: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func
};
