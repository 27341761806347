import React, { useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import styles from "./actionButtons.module.scss";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { historyGpsString } from "../../../Queries/queryStrings";
import Modal from "../../../artzu/src/Modal";
import Button from "../../../artzu/src/Button";
import CommandConfirm from "./CommandConfirm";
import { useApolloClient } from "@apollo/client";
import MessageBox from "./MessageBox";
import ErrorBox from "./ErrorBox";
import LoadingBox from "./LoadingBox";

const AvcAction = ({ icon, action, vehicleId, toggleRefresh }) => {
  const [modalOpen, setModalOpen] = useState();
  const [msgOpen, setMsgOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState();
  const [vehicleUUID, setVehicleUUID] = useState();
  const [vehicleStatus, setVehicleStatus] = useState();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleMsgModal = () => {
    setMsgOpen(!msgOpen);
  };

  const toggleErrorModal = () => {
    setErrorOpen(!errorOpen);
  };

  const AVC_ACTION = gql`
    mutation avcEvent($vehicleId: ID!, $action: String!) {
      avcEvent(vehicleId: $vehicleId, action: $action) {
        command {
          uuid
          status
        }
      }
    }
  `;
  const [AvcActionMutation, { loading, error }] = useMutation(AVC_ACTION);

  const sendCommand = () => {
    AvcActionMutation({
      variables: {
        vehicleId: vehicleId,
        action: action,
      },
    }).then(({ data: { avcEvent } }) => {
      if (avcEvent.command) {
        setVehicleUUID(avcEvent.command.uuid);
        setVehicleStatus(avcEvent.command.status);
        toggleMsgModal();
        console.log("Succeed!");
      } else {
        toggleErrorModal();
        console.log("error!");
      }
    });
    toggleModal();
  };

  return (
    <Fragment>
      <CommandConfirm
        open={modalOpen}
        close={toggleModal}
        confirm={sendCommand}
        action={action}
      />
      <MessageBox
        open={msgOpen}
        close={toggleMsgModal}
        action={action}
        vehicleUUID={vehicleUUID}
        vehicleStatus={vehicleStatus}
        sendCommand={sendCommand}
        toggleRefresh={toggleRefresh}
        toggleModal={toggleModal}
      />
      {/* <ErrorBox
        open={errorOpen}
        close={toggleErrorModal}
        action={action}
        sendCommand={sendCommand}
      /> */}
      {/* <LoadingBox open={msgOpen} /> */}
      <div
        className={classNames(styles.button, styles["blue"])}
        onClick={toggleModal}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    </Fragment>
  );
};

export default AvcAction;
