import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import { ApolloConsumer } from "@apollo/client";
import "../Components/Login/login.css";
import "../Components/Layout/layout.css";

class SignOutMutation extends Component {
  SIGN_OUT = gql`
    mutation SignUserOut {
      signOut {
        signedOut
      }
    }
  `;

  render() {
    const { open } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <Mutation
            mutation={this.SIGN_OUT}
            onCompleted={data => {
              client.resetStore();
              this.props.history.push("/login");
            }}
          >
            {signOut => (
              <div
                onClick={signOut}
                className={`dropdown ${open ? "visible" : "invisible"}`}
              >
                <p className="blue">Sign out</p>
              </div>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}
export default withRouter(SignOutMutation);
