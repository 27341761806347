import React from "react";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ size = "2x" }) => (
	<div className="upload__modal__loading">
		<FontAwesomeIcon className="blue" spin size={size} icon={faSpinner} />
	</div>
);

export default Loading;
