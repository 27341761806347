import React from "react";
import styles from "./damages.module.scss";

import Diagram from "./Diagram";
import { countGenerator } from "./utils";

const DamageDiagram = ({ vehicleDamages, setAdditionalFilters }) => {
  const driverFront = vehicleDamages?.driverFrontDamages;
  const driverRear = vehicleDamages?.driverRearDamages;
  const passengerFront = vehicleDamages?.passengerFrontDamages;
  const passengerRear = vehicleDamages?.passengerRearDamages;

  const driverFrontCounts = countGenerator(driverFront);
  const driverRearCounts = countGenerator(driverRear);
  const passengerFrontCounts = countGenerator(passengerFront);
  const passengerRearCounts = countGenerator(passengerRear);

  return (
    <div className={styles.damageDiagram}>
      <Diagram
        driverFrontCounts={driverFrontCounts}
        driverRearCounts={driverRearCounts}
        passengerFrontCounts={passengerFrontCounts}
        passengerRearCounts={passengerRearCounts}
        setAdditionalFilters={setAdditionalFilters}
      />
    </div>
  );
};

export default DamageDiagram;
