import React, { useEffect, useState } from "react";
import styles from "../damages.module.scss";
import _, { set } from "lodash";

const DetailRight = ({ damage }) => {
  const images = damage?.imageLinks;
  const [curImage, setCurImage] = useState(images?.[0]);

  useEffect(() => {
    setCurImage(images?.[0]);
  }, [images]);

  return (
    <div className={styles.detailImages}>
      <img className={styles.mainImage} src={curImage}></img>
      <div className={styles.displayImages}>
        {images?.map((image) => (
          <img
            className={
              curImage === image
                ? styles.displayImage
                : styles.fadedDisplayImage
            }
            src={image}
            onClick={() => setCurImage(image)}
          ></img>
        ))}
      </div>
    </div>
  );
};

export default DetailRight;
