import React, { useState } from "react";
import Modal from "../../../../artzu/src/Modal";
import styles from "./statusDetails.module.scss";

import MakeAvailable from "./MakeAvailable";
import ChangeStatus from "./ChangeStatus";
import Loading from "../../../../Containers/Loading";

const StatusDetails = ({ open, newStatus, vehicle, closeModal }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal open={open} backgroundColor="#F2F2F2">
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.modalContainer}>
          {newStatus == "available" ? (
            <MakeAvailable
              vehicle={vehicle}
              setLoading={setLoading}
              closeModal={closeModal}
            />
          ) : (
            <ChangeStatus
              vehicle={vehicle}
              setLoading={setLoading}
              closeModal={closeModal}
              newStatus={newStatus}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default StatusDetails;
