import React, { Component } from "react";
// import Input from "@material-ui/core/Input";
// import { withStyles } from "@material-ui/core/styles";

import TextField from "../../../artzu/src/TextField";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./search.module.scss";

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { store, findCars, updateSearch } = this.props;
    return (
      <div>
        {
          // <div className="filter-icon-box">
          //   <FontAwesomeIcon
          //     icon={faSlidersH}
          //     className="select-filter-icon"
          //     style={
          //       toggle ? { width: "24px", color: "#128DFF" } : { width: "24px" }
          //     }
          //     onClick={() => {
          //       toggleFilter(!openFilter);
          //       setOpenFilter(!openFilter);
          //       setToggle(!toggle);
          //     }}
          //   />
          //   <FontAwesomeIcon
          //     icon={chooseIcon(toggle)}
          //     className="select-filter-arrow"
          //     style={
          //       toggle ? { width: "24px", color: "#128DFF" } : { width: "24px" }
          //     }
          //     onClick={() => {
          //       toggleFilter(!openFilter);
          //       setOpenFilter(!openFilter);
          //       setToggle(!toggle);
          //     }}
          //   />
          // </div>
        }
        <div className={styles.searchContainer}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon className={styles.icon} icon={faSearch} />
          </div>
          <TextField
            width="60%"
            placeholder="Search"
            onChange={evt => updateSearch(evt.target.value)}
            noBorder={true}
          />
        </div>
      </div>
    );
  }
}

export default SearchBar;
