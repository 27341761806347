import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const StyledTextField = styled.input`
  width: ${({ width }) => width};
  font-size: ${({ size }) => size};
  border: none;
  outline: none;
  background: none;
  margin-left: 0.3em;
  color: #414141;
  opacity: ${({ value }) => (value ? "1" : "0.5")};
`;

const ContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
};

const IconStyle = {
  display: "flex",
  alignItems: "center"
};

const SearchBar = ({
  width,
  placeholder,
  size,
  onChange,
  value,
  onKeyPress,
  clearSearch
}) => (
  <div style={ContainerStyle}>
    <div style={IconStyle}>
      <FontAwesomeIcon className="" icon={faSearch} color="#A2A2A2" />
    </div>
    <StyledTextField
      placeholder={placeholder}
      width={width}
      size={size}
      onChange={e => onChange(e)}
      value={value}
      test={value}
      onKeyPress={onKeyPress}
    />
    {value && clearSearch && (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ fontSize: "20px" }}
        onClick={clearSearch}
      />
    )}
  </div>
);

export default SearchBar;

SearchBar.defaultProps = {
  width: "12em",
  placeholder: "Search..",
  size: "20px"
};

SearchBar.propTypes = {
  width: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
};
