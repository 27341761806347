export const bmwTopGreen =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/BMW+green.png";

export const bmwTopBlue =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/BMW+blue.png";

export const bmwTopOrange =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/BMW+orange.png";

export const dodgeTopGreen =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/Dodge+green.png";

export const dodgeTopBlue =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/Dodge+blue.png";

export const dodgeTopOrange =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/Dodge+orange.png";

export const bmwTopGrey =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/BMW+grey.png";

export const dodgeTopGrey =
  "https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/Dodge+grey.png";
