import React from "react";
import "../../../Fleet/fleet.css";
import "../../vehicle.scss";

const VehicleStatus = ({ status }) => {
  const statusCat = status && status.subtext;
  const statusColor = status && status.styles.color;
  const isSolid = status && status.styles.isSolid;
  const isHardware = statusCat === "Hardware Status Subtext";
  const statusName = status && status.name;

  return (
    <div className="operation-container">
      {status && (
        <div className={isHardware ? "status-line1-large" : "status-line1"}>
          <div
            className="operationSymbol"
            style={{ background: `${statusColor}` }}
          />

          <div className="operationStatus">{statusName}</div>
        </div>
      )}

      <div className="detailed-info">{statusCat}</div>
    </div>
  );
};

export default VehicleStatus;
