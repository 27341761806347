import React, { Fragment, useState } from "react";
import gql from "graphql-tag";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowFromBottom, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useDropzone } from "react-dropzone";

import NotesModal from "../../../Components/Misc/NotesModal";
import Button from "../../../artzu/src/Button";
import Modal from "../../../artzu/src/Button";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";
import TextField from "../../../artzu/src/TextField";

import Loading from "./Loading";
import { directUploadFile } from "../../../utils/FileUploads";
import { useApolloClient } from "@apollo/client";

const UploadDocumentModal = ({ vehicleId, toggleAdd, add, refetch, title }) => {
  const [name, setName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [otherInput, setOtherInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [heading, setHeading] = useState("");
  const client = useApolloClient();
  const handleInputChange = val => {
    if (val === "other") {
      setOtherInput(true);
      setName(null);
    } else {
      setName(val);
      setOtherInput(false);
    }
  };

  const handleCancel = () => {
    toggleAdd();
    setErrors([]);
    setName("");
    setExpiryDate(null);
    setFile(null);
    setFilePreview(null);
    setOtherInput(false);
    setLoading(false);
  };

  const formValidation = (file, name) => {
    const errors = [];
    if (!file) {
      errors.push("File required.");
    }
    if (!name) {
      errors.push("Title can't be blank.");
    }
    return errors;
  };

  const ADD_DOCUMENT = gql`
    mutation newDocument($document: DocumentAttributes!) {
      newDocument(params: $document) {
        success
        errors
      }
    }
  `;

  const handleSubmit = () => {
    const document = {
      name,
      vehicleId,
      description: "",
      expiryDate,
      status: ""
    };
    const errors = formValidation(file, name);
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      setLoading(true);
      directUploadFile(file, client).then(result => {
        client
          .mutate({
            mutation: ADD_DOCUMENT,
            context: { hasUpload: true },
            variables: {
              document: {
                ...document,
                fileBlob: result
              }
            }
          })
          .then(res => {
            setLoading(false);
            if (res.data.newDocument.success) {
              refetch();
              handleCancel();
            } else {
              setErrors(res.data.newDocument.errors);
            }
          });
      });
    }
  };

  const SelectType = () => {
    if (title) {
      return (
        <SelectField
          prompt={heading || "Select One"}
          onChange={(val, heading) => {
            handleInputChange(val);
            setHeading(heading);
          }}
          primaryColor="#414141"
          className="upload__modal__inputs__title--options"
          radius="0px"
          width="289px"
          height="24px"
          text="17.5px"
          color="#414141"
          weight="0"
          padding="0.7em"
        >
          {title === "DMV Registration" ? (
            <Option label="DMV Registration" value="dmv_registration" />
          ) : null}
          {title === "DMV Registration" ? (
            <Option
              label="Temporary DMV Registration"
              value="temp_dmv_registration"
            />
          ) : null}
          {title === "Ownership" ? (
            <Option label="Ownership" value="ownership" />
          ) : null}
          {title === "Safety" ? (
            <Option label="Safety Inspection" value="safety" />
          ) : null}
          {title === "TLC Inspection" ? (
            <Option label="TLC License" value="tlc" />
          ) : null}
          {title === "TLC Inspection" ? (
            <Option
              label="TLC Inspection Appointment"
              value="tlc_inspection_appointment"
            />
          ) : null}
          {title === "TLC Inspection" ? (
            <Option label="TLC Inspection FAIL" value="tlc_inspection_fail" />
          ) : null}
          {title === "TLC Inspection" ? (
            <Option label="TLC Inspection PASS" value="tlc_inspection_pass" />
          ) : null}
          {title === "Insurance" ? (
            <Option label="Vehicle Insurance" value="insurance" />
          ) : null}
          {title === "Other" ? (
            <Option label="Service Invoice" value="service_invoice" />
          ) : null}
          {title === "Other" ? <Option label="Toll Fee" value="toll" /> : null}
          {title === "Other" ? <Option label="Other" value="other" /> : null}
        </SelectField>
      );
    } else {
      return (
        <SelectField
          prompt={heading || "Select One"}
          onChange={(val, heading) => {
            handleInputChange(val);
            setHeading(heading);
          }}
          primaryColor="#414141"
          className="upload__modal__inputs__title--options"
          width="289px"
          radius="0px"
          height="24px"
          text="17.5px"
          color="#414141"
          weight="0"
          padding="0.7em"
        >
          <Option label="DMV Registration" value="dmv_registration" />
          <Option label="Ownership" value="ownership" />
          <Option label="Safety Inspection" value="safety" />
          <Option label="Service Invoice" value="service_invoice" />
          <Option
            label="Temporary DMV Registration"
            value="temp_dmv_registration"
          />
          <Option label="TLC License" value="tlc" />
          <Option
            label="TLC Inspection Appointment"
            value="tlc_inspection_appointment"
          />
          <Option label="TLC Inspection FAIL" value="tlc_inspection_fail" />
          <Option label="TLC Inspection PASS" value="tlc_inspection_pass" />
          <Option label="Toll Fee" value="toll" />
          <Option label="Vehicle Insurance" value="insurance" />
          <Option label="Other" value="other" />
        </SelectField>
      );
    }
  };

  const FileDiv = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        setFile(acceptedFiles[0]);
        setFilePreview(URL.createObjectURL(acceptedFiles[0]));
      }
    });

    return (
      <>
        <div
          className="upload__modal__preview"
          style={
            file
              ? { "background-image": `url(${filePreview})` }
              : { "background-color": "#FFFFFF", opacity: "0.9" }
          }
        >
          <div className="upload-box">
            <div
              className="upload__modal__preview__icon"
              onClick={() => {
                document.getElementById("image-upload").click();
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "#414141", "margin-left": "14px" }}
                size="2x"
              />
              <div style={{ color: "#414141" }}>
                {file ? "Update" : "Upload"}
              </div>
            </div>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps({ id: "image-upload" })} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <NotesModal
        open={add}
        id="upload-modal"
        title="Add Document"
        closeModal={handleCancel}
      >
        <div className="upload__modal">
          {loading ? (
            <Loading />
          ) : (
            <div className="upload__modal__inputs">
              <div style={{ marginRight: "79px" }}>
                <Fragment>
                  <div className="upload__modal__inputs__title">
                    <div className="upload__modal__inputs__title--name">
                      Document Type:
                    </div>
                    <SelectType />
                  </div>

                  {otherInput ? (
                    <div className="upload__modal__inputs__title--other">
                      <TextField
                        placeholder="Other"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        width="15em"
                      />
                    </div>
                  ) : null}
                  <div className="upload__modal__inputs__expDate">
                    <div className="upload__modal__inputs__expDate--name">
                      Expiry Date:
                    </div>
                    <DatePicker
                      selected={expiryDate}
                      onChange={e => setExpiryDate(e)}
                      placeholderText="Date"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      className="upload__modal__inputs__expDate--input"
                      color="grey"
                    />
                  </div>
                  <br />
                  {errors.length > 0 ? (
                    <div>
                      {errors.map(error => (
                        <div className="upload__modal__error"> {error}</div>
                      ))}
                    </div>
                  ) : null}
                </Fragment>
              </div>
              <div>
                <div style={{ fontWeight: "bold", fontSize: "17.5px" }}>
                  Document Image/File
                </div>
                <FileDiv />
              </div>
            </div>
          )}
        </div>
        <div className="upload__modal__inputs">
          <div className="upload__modal__inputs__buttons">
            <Button
              color="#414141"
              label="Cancel"
              onClick={handleCancel}
              border="none"
              width="154px"
              height="40px"
              weight="bold"
            />
            <Button
              color="#128DFF"
              label="Add"
              onClick={handleSubmit}
              border="none"
              width="154px"
              height="40px"
              weight="bold"
            />
          </div>
        </div>
      </NotesModal>
    </Fragment>
  );
};

export default UploadDocumentModal;
