import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import _ from "lodash";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../artzu/src/Button";

class NewServiceEvent extends Component {
  NEW_SERVICE_EVENT = gql`
    mutation NewServiceEvent($newServiceParams: NewServiceEventAttributes!) {
      newServiceEvent(params: $newServiceParams) {
        success
        bookingRelevant
        bookingSuccess
        errors
      }
    }
  `;

  filterParams = params => {
    delete params.id;
    Object.keys(params).forEach(key => {
      if (params[key] === "") {
        delete params[key];
      }
      if (
        ["treadDepth"].includes(key) &&
        !_.every(params[key], tread => tread !== "")
      ) {
        delete params[key];
      }
      if (["odometerReading", "cost", "vehicleId"].includes(key)) {
        params[key] = Number(params[key]);
      }
    });
    return params;
  };

  validateParams = () => {
    const { params, addErrors, prevOdometerReading } = this.props;

    const errors = [];
    if (params.name === "inspection") {
      if (
        //description no longer cause of error in inspection
        Number(params.odometerReading) - Number(prevOdometerReading) >=
        5000
      ) {
        errors.push("odometer");
        return false;
      } else {
        return true;
      }
    } else {
      if (!params.description) {
        errors.push("descriptionRequired");
      }
      if (!params.vendor) {
        errors.push("noVendor");
      }
      if (!params.name) {
        errors.push("noName");
      }
      if (!params.odometerReading) {
        errors.push("noOdometer");
      }
      if (errors.length > 0) {
        addErrors(errors);
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const { params, clear, refetch, openConfirmModal } = this.props;
    return (
      <Mutation
        mutation={this.NEW_SERVICE_EVENT}
        variables={{ newServiceParams: this.filterParams(params) }}
        onCompleted={data => {
          refetch();
          console.log(data);
          if (data.newServiceEvent.bookingRelevant) {
            openConfirmModal(data.newServiceEvent, "new");
          } else {
            clear();
          }
        }}
        onError={data => {
          console.log("error", data);
        }}
        refetchQueries={["getServiceEvents", "getAllVehicles", "getVehicle"]}
        awaitRefetchQueries={true}
      >
        {(newServiceEvent, { loading }) =>
          loading ? (
            <div className="buttons-container">
              <div className="service-action-buttons">
                <FontAwesomeIcon spin icon={faSpinner} />
              </div>
            </div>
          ) : (
            <div className="buttons-container">
              <Button
                onClick={() => {
                  if (this.validateParams()) {
                    console.log(this.filterParams(params));
                    newServiceEvent();
                  }
                }}
                label="Create event"
                className="service-action-buttons"
              />
            </div>
          )
        }
      </Mutation>
    );
  }
}

const inspectionDataKeys = [
  "exteriorWash",
  "interiorWash",
  "tirePressure",
  "dashLights",
  "washerFluid",
  "socket",
  "vinyl",
  "insuranceSlip",
  "vehicleOwnership",
  "safetyCertificate",
  "treadDepth"
];

export default NewServiceEvent;
