import React from "react";
import styles from "../../task.module.scss";
import moment from "moment-timezone";

const DateAndNotificationBox = ({ msg, taskData }) => {
  return (
    <div className={styles.dateLine}>
      <div className={styles.line}></div>
      <div className={styles.notificationContainer}>
        <span style={{ fontSize: "12px" }}>
          {moment(msg.sentAt).format("h:mm A [EST] MMM D, YYYY ")}{" "}
        </span>
        <span style={{ margin: "8px 0" }}>
          {msg.text === "Accident report submitted"
            ? `Created by admin: ${taskData.accidentReport.origin}`
            : msg.label === "damage"
            ? `Created by: ${msg?.senderName}`
            : msg.text}{" "}
        </span>
        <span style={{ fontSize: "12px", opacity: "0.5" }}>
          {msg.senderName &&
            msg.label !== "damage" &&
            `By
      ${msg.senderName}`}
        </span>
      </div>
      <div className={styles.line}></div>
    </div>
  );
};

export default DateAndNotificationBox;
