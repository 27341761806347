import styles from "../changeStatus.module.scss";

import React from "react";

const Errors = ({ errors }) => (
  <div className={styles.fieldsErrorsContainer}>
    {errors
      ? errors.map(error => (
          <div className={styles.fieldsErrorsText}> {error}</div>
        ))
      : null}
  </div>
);

export default Errors;
