import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../artzu/src/Button";

class Toggle extends Component {
  render() {
    const { checked, handleClick, value, title } = this.props;
    return (
      <Button
        className={`${value}-button map-toggle`}
        onClick={() => handleClick(value)}
        label={title}
        primary={checked}
      />
    );
  }
}

const styles = theme => ({
  label: {
    fontWeight: "700"
  },
  root: {
    color: theme.palette.grey.light,
    "&$checked": {
      color: theme.palette.blue
    }
  },
  checked: {}
});

export default withStyles(styles)(Toggle);
