import gql from "graphql-tag";

const CANCEL_STATUS_CHANGE = gql`
  mutation cancelStatusChange($reservationUuid: String!) {
    cancelStatusChange(reservationUuid: $reservationUuid) {
      success
      error
    }
  }
`;

export default CANCEL_STATUS_CHANGE;
