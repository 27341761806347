import React from "react";
import StatusList from "./StatusList";

const ActionButtons = ({ operationStatus, vehicle, toggleRefresh }) => {
  return (
    <div className="threeDot">
      <StatusList
        operationStatus={operationStatus}
        vehicle={vehicle}
        toggleRefresh={toggleRefresh}
      />
    </div>
  );
};

export default ActionButtons;
