import React from "react";
import InformationTable from "../InformationTable";
import withAuth from "../../Containers/withAuth";

const DamageIndex = () => {
  return (
    <InformationTable
      tableName={"damages"}
      navbar={"Damages"}
      hasTabs={true}
      background={"grey"}
      rowComponentType={"damage"}
      startTerms={"Search by Plate"}
      item={"damages"}
    />
  );
};

export default withAuth(DamageIndex);
