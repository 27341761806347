import { TableCell, TableRow } from "../../../artzu/src/Table";

import styles from "./statusLog.module.scss";
import React from "react";

const StatusHeader = ({}) => (
  <TableRow padding="32px 0 0 0" margin="0 32px 24px 32px" text="left" borderB="1px solid #414141">
    <TableCell width={`${100 / 3}%`}>
      <div className={styles.tableHeaderText}>Status</div>
    </TableCell>
    <TableCell width={`${100 / 3}%`}>
      <div className={styles.tableHeaderText}>User</div>
    </TableCell>
    <TableCell width={`${100 / 3}%`}>
      <div className={styles.tableHeaderText}>Comments</div>
    </TableCell>
  </TableRow>
);

export default StatusHeader;
