import React from "react";
import _ from "lodash";
import TaskActionContainer from "./TaskActionContainer";
import Modal from "../../../../artzu/src/Modal";
import styles from "../batchActions.module.scss";
import TaskSelections from "./TaskSelections";

const TaskActionModal = ({
  isOpen,
  selectedTasks,
  selectedBatchAction,
  batchOptionsHash,
  closeModal,
  toggleRefresh,
  clearAllSelections,
  removeSelection,
}) => {
  const selectedOption = batchOptionsHash[selectedBatchAction];
  return (
    <Modal open={isOpen} padding="none" backgroundColor={"none"}>
      <div className={styles.actionModal} style={{ height: "700px" }}>
        <TaskSelections
          selections={selectedTasks}
          removeSelection={removeSelection}
          close={closeModal}
        />
        {selectedOption && (
          <TaskActionContainer
            selectedOption={selectedOption}
            selectedTasks={selectedTasks}
            close={closeModal}
            open={isOpen}
            toggleRefresh={toggleRefresh}
            clearAllSelections={clearAllSelections}
          />
        )}
      </div>
    </Modal>
  );
};

export default TaskActionModal;
