import React, { useState } from "react";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../../Shared/ToggleButton";
import Menu from "./Menu";
import styles from "./miscSettings.module.scss";

const MiscSettings = ({ startEdit, startManageTag }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpenState = () => setIsOpen(!isOpen);

  return (
    <div className={styles.miscSettings}>
      <ToggleButton
        isOpen={isOpen}
        toggleOpenState={toggleOpenState}
        faIcon={faEllipsisH}
      />
      <Menu
        isOpen={isOpen}
        startEdit={() => {
          startEdit();
          toggleOpenState();
        }}
        startManageTag={() => {
          startManageTag();
          toggleOpenState();
        }}
      />
    </div>
  );
};

export default MiscSettings;
