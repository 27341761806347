import React from "react";
import { useQuery } from "@apollo/client";
import { sourceString } from "./queryStrings";
import { gql } from "@apollo/client";
import { Redirect } from "react-router";

const GET_VEHICLE_ADMIN = gql`
  ${sourceString}
`;

const GetVehicleFromAdminNew = (props) => {
  const uuid = props.match.params.uuid;
  const { loading, error, data, refetch } = useQuery(GET_VEHICLE_ADMIN, {
    variables: { vehicleUuid: uuid },
  });
  console.log(data);
  if (data) {
    return <Redirect to={`/vehicles/${data.getVehicle.id}`} />;
  } else {
    return null;
  }
};

export default GetVehicleFromAdminNew;
