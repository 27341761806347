import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "./sidebar.module.scss";
import { titleize } from "../../../utils";

const TabItem = ({ title, selectedTab }) => {
  return (
    <div
      className={styles.title}
      style={
        title === selectedTab
          ? { color: "black" }
          : { color: "#F2F2F2", opacity: "0.5" }
      }
    >
      {titleize(title)}
    </div>
  );
};

export default TabItem;
