import React from "react";
import EditsButtons from "./EditsButtons";
import ReviewChanges from "./ReviewChanges";

const Edits = ({
  areButtonsShowing,
  isEditModalOpen,
  toggleEditModalOpen,
  endEdits,
  saveEdits,
  edits,
  modifyEdits,
  rowIdToIdentifierObject,
  deleteEdit,
  reviewEdits
}) => {
  return (
    <div>
      {areButtonsShowing && (
        <EditsButtons endEdits={endEdits} reviewEdits={reviewEdits} />
      )}
      <ReviewChanges
        isOpen={isEditModalOpen}
        edits={edits}
        modifyEdits={modifyEdits}
        deleteEdit={deleteEdit}
        toggleEditModalOpen={toggleEditModalOpen}
        rowIdToIdentifierObject={rowIdToIdentifierObject}
        saveEdits={saveEdits}
      />
    </div>
  );
};

export default Edits;
