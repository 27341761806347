import gql from "graphql-tag";

const GET_DAMAGES = gql`
  query getVehicleDamages($vehicleId: ID!) {
    getVehicleDamages(vehicleId: $vehicleId) {
      lifetimeDamagesCount
      currentDamagesCount
      currentDamages {
        id
        status
        location
        type
        description
        severity
        damageTags {
          id
          name
        }
        imageLinks
      }
      lastConfirmedDamage

      driverFrontDamages {
        id
        location
        severity
        status
      }
      driverRearDamages {
        id
        location
        severity
        status
      }
      passengerFrontDamages {
        id
        location
        status
        severity
      }
      passengerRearDamages {
        id
        location
        status
        severity
      }
    }
  }
`;

const ADD_NEW_DAMAGE = gql`
  mutation addDamage(
    $addDamageParams: AddDamageAttributes!
    $tagsToAdd: [String!]
    $images: [String!]
  ) {
    addDamage(
      addDamageParams: $addDamageParams
      tagsToAdd: $tagsToAdd
      images: $images
    ) {
      success
      error
    }
  }
`;

const EDIT_DAMAGE = gql`
  mutation editDamage(
    $editDamageParams: EditDamageAttributes!
    $damageId: ID!
    $newImages: [String!]
    $oldImages: [String!]
  ) {
    editDamage(
      editDamageParams: $editDamageParams
      damageId: $damageId
      newImages: $newImages
      oldImages: $oldImages
    ) {
      success
      error
    }
  }
`;

const DELETE_DAMAGE = gql`
  mutation deleteDamage($damageIds: [ID!]!) {
    deleteDamage(damageIds: $damageIds) {
      success
      error
    }
  }
`;

const GET_DAMAGE = gql`
  query getDamage($damageId: ID!) {
    getDamage(damageId: $damageId) {
      id
      status
      location
      type
      description
      severity
      damageTags {
        id
        name
      }
      imageLinks
      attachedImageIds
      notes {
        id
        details
        pinned
        userId
        user
        userImage
        vehicleId
        date
      }
    }
  }
`;

const CHANGE_DAMAGE_STATUS = gql`
  mutation changeDamageStatus(
    $damageIds: [ID!]!
    $status: String!
    $type: String
    $severity: String
    $estimate: String
    $vendor: String
    $repairCost: String
  ) {
    changeDamageStatus(
      damageIds: $damageIds
      status: $status
      type: $type
      severity: $severity
      estimate: $estimate
      vendor: $vendor
      repairCost: $repairCost
    ) {
      success
      error
    }
  }
`;

const GET_DAMAGE_TASKS = gql`
  query getDamageTasks($damageId: ID!) {
    getDamageTasks(damageId: $damageId) {
      id
      status
      taskType
      description
      daysInStatus
      dueDate
      tags {
        id
        name
      }
      origin
      createdAt
    }
  }
`;

const CREATE_DAMAGE_TASK = gql`
  mutation createDamageTask($damageId: ID!) {
    createDamageTask(damageId: $damageId) {
      task {
        id
        status
      }
      success
      error
    }
  }
`;

export {
  GET_DAMAGES,
  ADD_NEW_DAMAGE,
  GET_DAMAGE,
  CHANGE_DAMAGE_STATUS,
  EDIT_DAMAGE,
  DELETE_DAMAGE,
  GET_DAMAGE_TASKS,
  CREATE_DAMAGE_TASK,
};
