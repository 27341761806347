import moment from "moment-timezone";

const shiftTimes = [
	moment()
		.hour(3)
		.minute(0)
		.second(0),
	moment()
		.hour(7)
		.minute(0)
		.second(0),
	moment()
		.hour(11)
		.minute(0)
		.second(0),
	moment()
		.hour(15)
		.minute(0)
		.second(0),
	moment()
		.hour(19)
		.minute(0)
		.second(0),
	moment()
		.hour(23)
		.minute(0)
		.second(0),
	moment()
		.add(1, "day")
		.hour(3)
		.minute(0)
		.second(0)
];

export { shiftTimes };
