import { getMirrorImage } from "../../AreaObjects/helperFunctions";

import {
	frontPassengerWindow,
	rearPassengerWindow,
	centerPassengerWindow,
	frontPassengerDoor,
	rearPassengerDoor,
	passengerBottomTrim,
	rearPassengerQuarterPanel,
	frontPassengerQuarterPanel,
	frontPassengerBumper,
	rearPassengerBumper,
	frontPassengerWheel,
	rearPassengerWheel,
	sidePassengerHeadlight,
	sidePassengerTaillight,
	passengerSideMirror,
	passengerBonnet
} from "./passengerView";

const frontDriverWindow = getMirrorImage(frontPassengerWindow);
const rearDriverWindow = getMirrorImage(rearPassengerWindow);
const centerDriverWindow = getMirrorImage(centerPassengerWindow);
const frontDriverDoor = getMirrorImage(frontPassengerDoor);
const rearDriverDoor = getMirrorImage(rearPassengerDoor);
const driverBottomTrim = getMirrorImage(passengerBottomTrim);
const rearDriverQuarterPanel = getMirrorImage(rearPassengerQuarterPanel);
const frontDriverQuarterPanel = getMirrorImage(frontPassengerQuarterPanel);
const frontDriverBumper = getMirrorImage(frontPassengerBumper);
const rearDriverBumper = getMirrorImage(rearPassengerBumper);
const frontDriverWheel = getMirrorImage(frontPassengerWheel);
const rearDriverWheel = getMirrorImage(rearPassengerWheel);
const sideDriverHeadlight = getMirrorImage(sidePassengerHeadlight);
const sideDriverTaillight = getMirrorImage(sidePassengerTaillight);
const driverSideMirror = getMirrorImage(passengerSideMirror);
const driverBonnet = getMirrorImage(passengerBonnet);

export {
	frontDriverWindow,
	rearDriverWindow,
	centerDriverWindow,
	frontDriverDoor,
	rearDriverDoor,
	driverBottomTrim,
	rearDriverQuarterPanel,
	frontDriverQuarterPanel,
	frontDriverBumper,
	rearDriverBumper,
	frontDriverWheel,
	rearDriverWheel,
	sideDriverHeadlight,
	sideDriverTaillight,
	driverSideMirror,
	driverBonnet
};
