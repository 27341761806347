import gql from "graphql-tag";
const GET_INSPECTION = gql`
  query getPrebookingInspection($prebookingInspectionId: ID!) {
    getPrebookingInspection(inspectionId: $prebookingInspectionId) {
      safeToDrive
      safetyImage1
      safetyImage2
      safetyImage3
      safetyImage4
      safetyImage5
      safetyImage6
      cleanlinessImage1
      cleanlinessImage2
      cleanlinessImage3
      cleanlinessImage4
      cleanlinessImage5
      cleanlinessImage6
      frontCenter
      frontLeft
      frontRight
      rearCenter
      rearLeft
      rearRight
      reasonForFailure
      completed
      passed
      issueDescription
      cleanliness
      status
      currentStep
      cleanlinessComment
    }
  }
`;

export { GET_INSPECTION };
