import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowToBottom,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import { Motion, spring } from "react-motion";
import { TableRow, TableCell } from "../../artzu/src/Table";
import DocumentModal from "./UploadDocuments/DocumentModal";
import UploadDocumentModal from "./UploadDocuments/UploadDocumentModal";
import RenderImage from "./RenderImage";
import { m, sortDocuments, otherDocs, formatNavTitle } from "../../utils";
import DocumentDetails from "./UploadDocuments/DocumentDetails";
import "./vehicle.scss";

class VehicleDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docModal: false,
      doc: null,
      add: false,
    };
  }

  componentDidMount() {
    const { history, vehicle, tab, tabNav, store } = this.props;
    tabNav("documents");
    store.changeNavigatedPage(
      formatNavTitle(vehicle, history.location.pathname + `/${tab}`, tabNav)
    );
  }

  toggleAdd = () => this.setState({ add: !this.state.add });

  handleClick = (doc) => {
    this.setState({ docModal: true, doc });
  };

  handleClose = () => {
    this.setState({ docModal: false, doc: null });
  };

  render() {
    const {
      documents,
      refetch,
      vehicleId,
      prev,
      history,
      store,
      vehicle,
      tab,
      docTab,
      setDocTab,
      tabNav,
    } = this.props;
    const { docModal, doc, add } = this.state;
    console.log(docTab);

    const orderedDocuments = _.orderBy(
      documents,
      ["uploadedAt", "name"],
      ["desc", "asc"]
    );

    const dmvDocs = sortDocuments(orderedDocuments, [
      "dmv",
      "dmv_registration",
      "temp_dmv_registration",
      "DMV",
    ]);
    const insuranceDocs = sortDocuments(orderedDocuments, [
      "insurance",
      "Insurance",
    ]);
    const ownershipDocs = sortDocuments(orderedDocuments, [
      "ownership",
      "Ownership",
    ]);
    const safetyDocs = sortDocuments(orderedDocuments, ["safety", "Safety"]);
    const tlcDocs = sortDocuments(orderedDocuments, [
      "tlc_inspection_fail",
      "tlc_inspection_appointment",
      "tlc",
      "tlc_inspection_pass",
      "TLC",
    ]);
    const otherDocuments = otherDocs(orderedDocuments, [
      "DMV",
      "dmv_registration",
      "temp_dmv_registration",
      "dmv",
      "insurance",
      "Insurance",
      "ownership",
      "Ownership",
      "safety",
      "Safety",
      "tlc_inspection_fail",
      "tlc_inspection_appointment",
      "tlc",
      "TLC",
      "tlc_inspection_pass",
    ]);

    const docLists = [
      dmvDocs.length > 0
        ? {
            name: "DMV Registration",
            image: dmvDocs[0].imageLink,
            uploaded: dmvDocs[0].uploadedAt,
            updated: dmvDocs[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: dmvDocs[0].expiryDate,
          }
        : null,
      insuranceDocs.length > 0
        ? {
            name: "Insurance",
            image: insuranceDocs[0].imageLink,
            uploaded: insuranceDocs[0].uploadedAt,
            updated: insuranceDocs[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: insuranceDocs[0].expiryDate,
          }
        : null,
      ownershipDocs.length > 0
        ? {
            name: "Ownership",
            image: ownershipDocs[0].imageLink,
            uploaded: ownershipDocs[0].uploadedAt,
            updated: ownershipDocs[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: ownershipDocs[0].expiryDate,
          }
        : null,
      safetyDocs.length > 0
        ? {
            name: "Safety",
            image: safetyDocs[0].imageLink,
            uploaded: safetyDocs[0].uploadedAt,
            updated: safetyDocs[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: safetyDocs[0].expiryDate,
          }
        : null,
      tlcDocs.length > 0
        ? {
            name: "TLC Inspection",
            image: tlcDocs[0].imageLink,
            uploaded: tlcDocs[0].uploadedAt,
            updated: tlcDocs[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: tlcDocs[0].expiryDate,
          }
        : null,
      otherDocuments.length > 0
        ? {
            name: "Other",
            image: otherDocuments[0].imageLink,
            uploaded: otherDocuments[0].uploadedAt,
            updated: otherDocuments[0].uploadedAt,
            uploadedBy: "Jai Rajan",
            updatedBy: "Jai Rajan",
            expiry: otherDocuments[0].expiryDate,
          }
        : null,
    ];

    const documentsList = _.filter(docLists, (doc) => {
      return doc !== null;
    });

    console.log("DOCUMENTS", documents);

    console.log("ORDERED_DOCUMENTS", orderedDocuments);

    if (docTab === "DMV Registration") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={dmvDocs.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"DMV Registration"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else if (docTab === "Insurance") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={insuranceDocs.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"Insurance"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else if (docTab === "Ownership") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={ownershipDocs.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"Ownership"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else if (docTab === "Safety") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={safetyDocs.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"Safety"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else if (docTab === "TLC Inspection") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={tlcDocs.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"TLC Inspection"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else if (docTab === "Other") {
      return (
        <DocumentDetails
          vehicleId={vehicleId}
          documents={otherDocuments.sort((a, b) => a.uploadedAt - b.uploadedAt)}
          refetch={refetch}
          setOpenList={tabNav}
          title={"Other"}
          vehicle={vehicle}
          history={history}
          store={store}
          tab={tab}
        />
      );
    } else {
      return (
        <div className="vehicle-page-layout">
          <UploadDocumentModal
            vehicleId={vehicleId}
            toggleAdd={this.toggleAdd} // handle upload document modal
            add={add} // upload document modal open/close state
            refetch={refetch}
            title={null}
          />
          {
            // <Motion
            //   defaultStyle={{ x: -200, opacity: 0 }}
            //   style={{
            //     y: spring(0, { stiffness: 150, damping: 18 }),
            //     opacity: spring(1)
            //   }}
            // >
            //   {style => (
            //     <div className="vehicle-redirects">
            //       <div
            //         className="back-button link"
            //         onClick={() => history.push(prev)}
            //       >
            //         <FontAwesomeIcon className="blue" icon={faChevronLeft} />
            //         <p className="blue back-text">
            //           {prev === "/" ? "Fleet" : "Map"}
            //         </p>
            //       </div>
            //     </div>
            //   )}
            // </Motion>
          }
          <div className="documents-table">
            <TableRow
              width="704px"
              align="center"
              color="#FFFFFF"
              height="72px"
              margin="0 0 12px 0"
              padding="0.5em 14px"
              text="left"
            >
              <TableCell width={"9%"}></TableCell>
              <TableCell width={"21.5%"}>
                <div className="doc-header">Title</div>
              </TableCell>
              <TableCell width={"21.5%"}>
                <div className="doc-header">Uploaded</div>
              </TableCell>
              <TableCell width={"21.5%"}>
                <div className="doc-header">Updated</div>
              </TableCell>
              <TableCell width={"21.5%"}>
                <div className="doc-header">Expiry</div>
              </TableCell>
              <TableCell width={"5%"}>
                <div
                  className="blue table-edit"
                  style={{ fontWeight: "normal" }}
                  onClick={this.toggleAdd}
                >
                  +
                </div>
              </TableCell>
            </TableRow>
            {documentsList.map((list, index) => (
              <div
                onClick={() => {
                  setDocTab(list.name);
                  store.changeNavigatedPage(
                    formatNavTitle(
                      vehicle,
                      history.location.pathname + `/${tab}/${list.name}`,
                      tabNav
                    )
                  );
                }}
              >
                <TableRow
                  width="704px"
                  align="center"
                  color="#FFFFFF"
                  height="72px"
                  margin="0 0 12px 0"
                  padding="0.5em 14px"
                  text="left"
                  hover={true}
                  hoverColor="#414141"
                  hoverText="#FFFFFF"
                >
                  <TableCell width={"9%"}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(list.image, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <RenderImage src={list.image} imageClass="upload-image" />
                    </div>
                  </TableCell>
                  <TableCell width={"21.5%"}>
                    <div className="doc-body">{list.name}</div>
                  </TableCell>
                  <TableCell width={"21.5%"}>
                    <div className="doc-body">
                      {m(list.uploaded).format("MMM D, YYYY")}
                    </div>
                  </TableCell>
                  <TableCell width={"21.5%"}>
                    <div className="doc-body">
                      {m(list.updated).format("MMM D, YYYY")}
                    </div>
                  </TableCell>
                  <TableCell width={"21.5%"}>
                    <div className="doc-body">
                      {m(list.expiry).format("MMM D, YYYY")}
                    </div>
                  </TableCell>
                  <TableCell width={"5%"}>
                    <div
                      className="table-edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(list.image, "_blank");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowToBottom}
                        style={{ color: "#128DFF", fontSize: "23px" }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

export default VehicleDocuments;
