import React, { Component } from "react";
import Papa from "papaparse";

class UploadCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = file => {
    if (file) {
      console.log("file_uploaded");
      Papa.parse(file, {
        header: false,
        complete: results => {
          console.log(results);
          this.props.getData(results.data);
        }
      });
    } else {
      console.log("No file");
    }
  };

  render() {
    return (
      <input
        id="edit_csv"
        // style={{ display: "none" }}
        type="file"
        accept=".csv"
        onChange={evt => this.handleChange(evt.target.files[0])}
      />
    );
  }
}

export default UploadCsv;
