import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import LocationSelectContent from "./LocationSelectContent";
import { observer, inject } from "mobx-react";

const LocationSelect = observer(
  inject("store")(({ currentUser, store }) => {
    const [open, setOpen] = useState(false);
    const [lots, setLots] = useState({});
    const [lotsSelected, setLotsSelected] = useState(
      JSON.parse(localStorage.getItem("parkingLots")) || []
    );
    const [lotsSelectedName, setLotsSelectedName] = useState("");
    const [citiesSelected, setCitiesSelected] = useState(
      JSON.parse(localStorage.getItem("parkingCities")) || []
    );
    const [citiesSelectedName, setCitiesSelectedName] = useState("");

    useEffect(() => {
      setLots(_.groupBy(currentUser.parkingLots, (lot) => lot.city.name));
    }, [currentUser]);

    const toggleOpen = () => {
      setOpen(!open);
    };

    const selectCities = (city) => {
      const includedLotsArray = _.intersectionWith(
        lots[city],
        lotsSelected,
        _.isEqual
      );

      if (includedLotsArray.length === 0) {
        setLotsSelected(_.concat(lotsSelected, lots[city]));
      } else {
        setLotsSelected((lotsList) =>
          lotsList.filter(
            (lot) =>
              !includedLotsArray.some(
                (includedLot) => includedLot.id === lot.id
              )
          )
        );
      }
      let newCities = [...citiesSelected];
      const included = _.includes(newCities, city);
      included
        ? _.remove(newCities, (item) => item === city)
        : newCities.push(city);
      setCitiesSelected(newCities);
    };

    useEffect(() => {
      let citiesName = "";
      let curCities = [...citiesSelected];
      if (curCities.length === 1) {
        citiesName = curCities[0];
      } else if (curCities.length > 1) {
        citiesName = "Multiple Cities";
      }
      setCitiesSelectedName(citiesName);
    }, [citiesSelected]);

    useEffect(() => {
      let lotsName = "";
      let curLots = [...lotsSelected];
      if (curLots.length === 1) {
        lotsName = curLots[0].name;
      } else if (curLots.length > 1) {
        lotsName = "Multiple Lots";
      }
      setLotsSelectedName(lotsName);
    }, [lotsSelected]);

    const selectLots = (lot) => {
      let newLots = [...lotsSelected];
      const included = _.includes(JSON.stringify(newLots), JSON.stringify(lot));
      included
        ? _.remove(newLots, (item) => _.isEqual(item, lot))
        : newLots.push(lot);

      let newCities = [...citiesSelected];
      const allLotsOfCityFromAddedLot = lots[lot.city.name].map(
        (lot) => lot.name
      );
      const isCityOfAddedLotSelected = allLotsOfCityFromAddedLot.every(
        (lotName) => newLots.map((lot) => lot.name).includes(lotName)
      );

      included && _.remove(newCities, (item) => item === lot.city.name);
      !included &&
        !_.includes(newCities, lot.city.name) &&
        (lots[lot.city.name].length === 1 || isCityOfAddedLotSelected) &&
        newCities.push(lot.city.name);
      setCitiesSelected(newCities);

      setLotsSelected(newLots);
      let lotsName = "";
      if (newLots.length === 1) {
        lotsName = newLots[0].name;
      } else if (newLots.length > 1) {
        lotsName = "Multiple Lots";
      }
      setLotsSelectedName(lotsName);
    };

    const reset = () => {
      setLotsSelected([]);
      setLotsSelectedName("");
      setCitiesSelected([]);
      setCitiesSelectedName("");
      localStorage.removeItem("parkingLots");
      localStorage.removeItem("parkingCities");
    };
    localStorage.setItem("parkingLots", JSON.stringify(lotsSelected));
    localStorage.setItem("parkingCities", JSON.stringify(citiesSelected));

    return (
      <LocationSelectContent
        store={store}
        open={open}
        toggleOpen={toggleOpen}
        lotsSelectedName={lotsSelectedName}
        citiesSelectedName={citiesSelectedName}
        lots={lots}
        lotsSelected={lotsSelected}
        citiesSelected={citiesSelected}
        selectCities={selectCities}
        selectLots={selectLots}
        reset={reset}
        close={() => setOpen(false)}
      />
    );
  })
);

export default LocationSelect;
