import React, { Component } from "react";
import DamageImage from "./DamageImage";
import NewDamageImage from "./NewDamageImage";
import NavDisplay from "../Shareable/NavDisplay";
import ViewType from "./ViewType";
import { inject, observer } from "mobx-react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNavTitle } from "../../utils/index";
import "./damage.scss";
import "../Vehicle/vehicle.scss";

const DamageReport = inject("store")(
  observer(
    class DamageReport extends Component {
      constructor(props) {
        super(props);
        // at 60vw wide, original W/H ratio is 60 : 38.13916
        // this is to maintain image size relative to a single dynamic screen param
        const width = window.innerWidth;
        this.state = {
          mode: "overview",
          imageWidth: width * 0.6,
          imageHeight: width * 0.3813916,
          location: "",
          locationX: null,
          locationY: null,
          scope: "exterior",
        };
      }

      componentDidMount() {
        const { store, id, plate, history } = this.props;
        // console.log(history.location.pathname);
      }
      alterDamageState = (stateObject) => this.setState(stateObject);

      render() {
        const {
          id,
          plate,
          make,
          damageReportView,
          exteriorDamage,
          interiorDamage,
          history,
          refetch,
        } = this.props;
        const {
          mode,
          location,
          locationX,
          locationY,
          imageWidth,
          imageHeight,
          scope,
        } = this.state;

        return (
          <div>
            <div className="vehicle-redirects">
              <NavDisplay
                navPath={this.props.store.navigatedPage}
                history={history}
              />
            </div>
            <div className="report-container">
              <div className="damage-left">
                <h1 className="report-created">{plate}</h1>
                <ViewType
                  scope={scope}
                  vehicleId={id}
                  make={make}
                  interiorDamage={interiorDamage}
                  exteriorDamage={exteriorDamage}
                  alterDamageState={this.alterDamageState}
                  mode={mode}
                  refetch={refetch}
                  location={location}
                  locationX={locationX}
                  locationY={locationY}
                />
              </div>
              <div className="damage-right">
                {mode !== "new" || scope === "interior" ? (
                  <DamageImage
                    mode={mode}
                    src={damageReportView}
                    data={exteriorDamage}
                    alterDamageState={this.alterDamageState}
                    width={imageWidth}
                    height={imageHeight}
                  />
                ) : (
                  <NewDamageImage
                    src={damageReportView}
                    make={make}
                    alterDamageState={this.alterDamageState}
                    width={imageWidth}
                    height={imageHeight}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default DamageReport;
