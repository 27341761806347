import React from "react";
import "./service.css";
import styles from "./service.module.scss";
import Button from "../../artzu/src/Button";

const getText = type => {
  switch (type) {
    case "newSuccess":
      return "A service booking was successfully created for this service event.";
    case "newFail":
      return "A service booking was NOT created for this service event.";
    case "updateSuccess":
      return "An existing service booking was successfully updated for this service event.";
    case "updateFail":
      return "A service booking was NOT updated for this service event.";
    default:
      return "ERROR";
  }
};

const ConfirmModal = ({ confirmType, clear }) => (
  <div className="modal event-modal">
    <div className={styles.modalContent}>
      <div className={styles.modalText}> {getText(confirmType)}</div>
      <Button onClick={clear} label="Okay" />
    </div>
  </div>
);

export default ConfirmModal;
