import React, { useState } from "react";
import Dropdown from "../../../Shared/Dropdown/index";
import classsNames from "classnames";
import Button from "../../../../../artzu/src/Button";
import styles from "../../batchActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Fragment } from "react";
import DatePicker from "react-datepicker";
import MessageBox from "./MessageBox";
import CHANGE_STATUS from "./query";
import { useMutation } from "@apollo/client";
import Loading from "../../../../../Containers/Loading";

const ChangeStatus = ({ selectedOption, selections, toggleRefresh, close }) => {
  let vehicle;
  if (selections.length === 1) {
    vehicle = selections[0];
  }

  const availableStatus = vehicle.availableStatusChanges;
  const statuses = [];
  availableStatus &&
    availableStatus.map((status) =>
      statuses.push({ name: status.name, id: status.name })
    );

  const [selected, setSelected] = useState("");
  const [statusAction, setStatusAction] = useState("now");
  const [startDate, setStartDate] = useState();
  const [isMessage, setIsMessage] = useState(false);
  const [comment, setComment] = useState("");
  const [errorMsg, setErrorMsg] = useState();

  const trigger = (e) => {
    setSelected(e);
  };
  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };
  const handleAction = (e) => {
    setStatusAction(e.target.value);
  };
  const toggleMessage = () => {
    setIsMessage(!isMessage);
  };
  const closeMsg = () => {
    setIsMessage(false);
  };

  const statusSelected = selected !== "";
  const addable = statusSelected && statusAction && comment;

  const [changeStatusMutation, { loading, error }] = useMutation(CHANGE_STATUS);
  const changeVehicleStatus = (cancelReservations = null) => {
    changeStatusMutation({
      variables: {
        vehicleId: vehicle.id,
        startTime: startDate && startDate.toDate(),
        statusType: selected.toLowerCase(),
        comments: comment,
        cancelUserReservations: cancelReservations,
      },
    }).then(({ data: { scheduleVehicleStatusChange } }) => {
      if (scheduleVehicleStatusChange.success) {
        close();
        console.log("Succeed!");
      } else {
        toggleMessage();
        setErrorMsg(scheduleVehicleStatusChange.errors);
      }
      toggleRefresh();
    });
  };

  return (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div
        className={classsNames(
          styles.contentContainer,
          styles.contentContainer__changeStatus
        )}
      >
        <div className={styles.optionContainer}>
          <div className={styles.optionBox}>
            Change status to
            <Dropdown initialValue="" items={statuses} onChange={trigger} />
          </div>
        </div>
        <div className={styles.actionList} onChange={handleAction}>
          <div className={styles.choice}>
            <input type="radio" name="tag" value="now" defaultChecked /> Apply
            change immediately
          </div>
          <div className={styles.choice}>
            <input type="radio" name="tag" value="future" /> Scheduled change
            for future
          </div>
          <div className={styles.datepicker}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className={styles.dateInput}
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMM D, YYYY h:mm a"
              showTimeSelect
              timeCaption="Time"
              todayButton={"Today"}
              popperPlacement="top"
              placeholderText="Select date & time"
            />
            <div className={styles.chevronDown}>
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  fontSize: "12px",
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.comment}>
          <h4>Comment</h4>
          <textarea
            className={classsNames(styles.textBox, styles.commentBox)}
            onChange={handleCommentInput}
          ></textarea>
        </div>
        <div className={styles.notesSaveButton}>
          <Button
            label={loading ? <Loading size="1x" /> : "Save"}
            height="39px"
            fullWidth="150px"
            color={addable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              addable && changeVehicleStatus();
            }}
          />
        </div>
      </div>
      {isMessage && (
        <MessageBox open={isMessage} loading={loading} close={closeMsg} errorMsg={errorMsg} confirm={() => changeVehicleStatus(true)}/>
      )}
    </Fragment>
  );
};

export default ChangeStatus;
