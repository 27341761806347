import gql from "graphql-tag";

const GET_CURRENT_ADMIN = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      email
    }
  }
`;

const GET_TASK = gql`
  query getTask($taskId: ID!) {
    getTask(taskId: $taskId) {
      id
      status
      taskType
      description
      dueDate
      user {
        id
        fullName
      }
      vehicle {
        id
        plate
        operationStatus
        model
        odometer {
          reading
        }
        lockboxCode
        parkingLot {
          id
          name
        }
        vin
        parkingSpot {
          identifier
        }
        imageLink
        statuses {
          operationStatus {
            name
            styles {
              color
              isSolid
            }
          }
          lockStatus {
            name
            subtext
            styles {
              color
              isSolid
            }
          }
        }
        availableStatusChanges {
          name
          value
        }
      }
      messages {
        text
        sender {
          fullName
        }
        senderName
        direction
        label
        sentAt
      }
      tags {
        id
        name
      }
      reminderDate
      taggedUsers
      accidentReport {
        id
        injuredPeople
        statusDescription
        ridesharePlatform
        towed
        towingCompany
        towingAddress
        accidentDescription
        accidentLocation
        accidentTime
        frontImage
        driverImage
        backImage
        passengerImage
        autzuDamageImages
        thirdPartyIndividuals {
          name
          phone
          email
          individualImages
        }
        thirdPartyVehicles {
          id
          licensePlate
          year
          make
          model
          color
          licensePlateImage
          licenseImage
          insuranceImage
          vehicleDamageImages
        }
        origin
        ticketReferenceUrl
      }
    }
  }
`;

const GET_ALL_SELECTED_TASKS = gql`
  query getAllTasks($taskIds: [ID!]!) {
    getAllTasks(taskIds: $taskIds) {
      tasks {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

const GET_VEHICLE = gql`
  query getVehicle($params: FindVehicleAttributes!) {
    getVehicle(params: $params) {
      id
      operationStatus
      plate
      make
      model
      year
      vin
      imageLink
      fleet {
        id
        name
      }
      odometer {
        id
        reading
        capturedAt
      }
      parkingLot {
        name
        id
      }
      parkingSpot {
        id
        identifier
      }
      statuses {
        operationStatus {
          name
          styles {
            color
            isSolid
          }
        }
        lockStatus {
          name
          subtext
          styles {
            color
            isSolid
          }
        }
      }
    }
  }
`;

const GET_USER_PROFILE = gql`
  query getUserProfile($id: ID) {
    getUserProfile(id: $id) {
      id
      firstName
      lastName
      email
      fullName
    }
  }
`;

const GET_ADMIN_NAME = gql`
  query allAdminName {
    allAdminName {
      id
      firstName
      lastName
    }
  }
`;

const GET_ALL_TEMPLATE = gql`
  query getAllTemplates {
    getAllTemplates {
      id
      name
      text
    }
  }
`;

const CREATE_NEW_TASK = gql`
  mutation createTask(
    $vehicleId: ID!
    $type: String!
    $description: String!
    $userId: ID
    $dueDate: DateTime
  ) {
    createTask(
      vehicleId: $vehicleId
      type: $type
      description: $description
      userId: $userId
      dueDate: $dueDate
    ) {
      task {
        id
        status
        user {
          firstName
        }
        vehicle {
          id
          plate
        }
        dueDate
      }
      error
    }
  }
`;

const ASSIGN_TASK = gql`
  mutation assignTask($taskId: ID!, $adminUserId: ID!) {
    assignTask(taskId: $taskId, adminUserId: $adminUserId) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const RESOLVE_TASK = gql`
  mutation resolveTask($taskId: ID!) {
    resolveTask(taskId: $taskId) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const UNRESOLVE_TASK = gql`
  mutation unresolveTask($taskId: ID!, $reason: String!) {
    unresolveTask(taskId: $taskId, reason: $reason) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const DELETE_TASK = gql`
  mutation deleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const RESTORE_TASK = gql`
  mutation restoreTask($taskId: ID!) {
    restoreTask(taskId: $taskId) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const BACKLOG_TASK = gql`
  mutation backlogTask($taskId: ID!) {
    backlogTask(taskId: $taskId) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const REMOVE_FROM_BACKLOG = gql`
  mutation removeTaskFromBacklog($taskId: ID!, $dueDate: DateTime) {
    removeTaskFromBacklog(taskId: $taskId, dueDate: $dueDate) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask(
    $taskId: ID!
    $type: String
    $userId: ID
    $description: String
    $dueDate: DateTime
  ) {
    updateTask(
      taskId: $taskId
      type: $type
      userId: $userId
      description: $description
      dueDate: $dueDate
    ) {
      task {
        id
        status
        user {
          fullName
        }
      }
      success
      error
    }
  }
`;

const VEHICLE_ACTION = gql`
  mutation avcEvent($vehicleId: ID!, $action: String!) {
    avcEvent(vehicleId: $vehicleId, action: $action) {
      command {
        uuid
        status
      }
    }
  }
`;

const CHANGE_VEHICLE_STATUS = gql`
  mutation changeStatus(
    $vehicleId: ID!
    $startTime: DateTime
    $statusType: String!
    $comments: String
  ) {
    scheduleVehicleStatusChange(
      vehicleId: $vehicleId
      startTime: $startTime
      statusType: $statusType
      comments: $comments
    ) {
      success
      errors {
        errorType
        message
      }
    }
  }
`;

const HANDLE_SEND_IMAGE = gql`
  mutation sendImageMessage($taskId: ID!, $fileBlob: String!) {
    sendImageMessage(taskId: $taskId, fileBlob: $fileBlob) {
      task {
        id
      }
      success
      error
    }
  }
`;

const ADD_TASK_TAG = gql`
  mutation addTag($taskId: ID!, $tagName: String!) {
    addTag(taskId: $taskId, tagName: $tagName) {
      success
      error
    }
  }
`;

const REMOVE_TASK_TAG = gql`
  mutation removeTag($taskId: ID!, $tagName: String!) {
    removeTag(taskId: $taskId, tagName: $tagName) {
      success
      error
    }
  }
`;
const ADD_NEW_TEMPLATE = gql`
  mutation addTemplate($name: String!, $text: String!) {
    addTemplate(name: $name, text: $text) {
      template {
        name
        text
      }
      success
      error
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation editTemplate($templateId: ID!, $name: String!, $text: String!) {
    editTemplate(templateId: $templateId, name: $name, text: $text) {
      template {
        name
        text
      }
      success
      error
    }
  }
`;

const REMOVE_TEMPLATE = gql`
  mutation removeTemplate($templateId: ID!) {
    removeTemplate(templateId: $templateId) {
      success
      error
    }
  }
`;

const TASK_REMINDER = gql`
  mutation remindAdmin($taskId: ID!, $adminId: ID!, $reminderDate: DateTime!) {
    remindAdmin(
      taskId: $taskId
      adminId: $adminId
      reminderDate: $reminderDate
    ) {
      success
      error
    }
  }
`;

const REMOVE_REMINDER = gql`
  mutation removeReminder($taskId: ID!, $adminId: ID!, $adminName: String!) {
    removeReminder(taskId: $taskId, adminId: $adminId, adminName: $adminName) {
      success
      error
    }
  }
`;

const GET_AVAILABLE_SPOTS = gql`
  query getAllSpotsAtHub($parkingLotId: ID!) {
    getAllSpotsAtHub(parkingLotId: $parkingLotId) {
      id
      identifier
    }
  }
`;

export {
  GET_CURRENT_ADMIN,
  GET_TASK,
  GET_USER_PROFILE,
  GET_ADMIN_NAME,
  GET_VEHICLE,
  CREATE_NEW_TASK,
  ASSIGN_TASK,
  RESOLVE_TASK,
  UNRESOLVE_TASK,
  DELETE_TASK,
  RESTORE_TASK,
  BACKLOG_TASK,
  REMOVE_FROM_BACKLOG,
  UPDATE_TASK,
  VEHICLE_ACTION,
  CHANGE_VEHICLE_STATUS,
  HANDLE_SEND_IMAGE,
  ADD_TASK_TAG,
  REMOVE_TASK_TAG,
  GET_ALL_TEMPLATE,
  ADD_NEW_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
  TASK_REMINDER,
  REMOVE_REMINDER,
  GET_ALL_SELECTED_TASKS,
  GET_AVAILABLE_SPOTS,
};
