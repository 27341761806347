import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import ServiceEvents from "../Components/ServiceEvents";
import withAuth from "../Containers/withAuth";
// import Loading from "../Components/Fleet"

import { serviceEventsString } from "./queryStrings";

class GetServiceEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "upcoming",
      vehicleId: this.props.match.params.id
    };
  }

  changeMode = mode => this.setState({ mode });
  historyQuery = () => {
    const fleetManager = this.props.roles.filter(
      role => role.type === "fleet_manager"
    ).length;
    return (
      <Query
        query={gql`
          ${serviceEventsString}
        `}
        variables={{
          vehicleId: this.state.vehicleId
        }}
      >
        {({ loading, error, data, refetch }) => {
          const { history, location } = this.props;
          if (loading || error) {
            return null;
          }
          if (data) {
            return (
              <ServiceEvents
                fleetManager={fleetManager}
                data={data.getVehicle}
                mode={this.state.mode}
                changeMode={this.changeMode}
                refetch={refetch}
                location={location}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  };

  authorized = () => {
    let auth = false;
    this.props.roles.forEach(role => {
      if (role.type === "read_fleet") {
        if (role.fleet.vehicleIds.includes(this.state.vehicleId)) {
          auth = true;
        }
      }
    });
    return auth;
  };

  render() {
    if (this.authorized()) {
      return this.historyQuery();
    } else {
      // return <Redirect to="/fleets" />
      // replace this with above line, or similar link
      return <p style={{ marginTop: "6em" }}>Not available</p>;
    }
  }
}

export default withAuth(GetServiceEvents);
