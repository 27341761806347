import React, { Component, Fragment } from "react";
import { TableRow, TableCell } from "../../../../artzu/src/Table";
import StatusInfo from "../StatusInfo";
import _ from "lodash";

const LogTable = ({ statusLogData, isShowingAll, fullSize, timezone }) => {
  const statusLogLength = statusLogData.length;

  const maxLength = isShowingAll
    ? statusLogLength
    : _.min([10, statusLogLength]);
  console.log(statusLogLength, maxLength);
  return (
    <Fragment>
      <div style={{ marginBottom: "32px", minHeight: "304px" }}>
        {statusLogData.slice(0, maxLength).map((statusInterval, index) => {
          return (
            <TableRow
              justify="space-between"
              align="center"
              margin="0 32px 32px 32px"
              padding="0"
            >
              <StatusInfo
                statusInterval={statusInterval}
                showBar={maxLength - 1 !== index}
                fullSize={fullSize}
                timezone={timezone}
              />
            </TableRow>
          );
        })}
      </div>
    </Fragment>
  );
};

export default LogTable;
