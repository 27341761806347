import React, { useState } from "react";
import Dropdown from "../../../Shared/Dropdown/index";
import classsNames from "classnames";
import Button from "../../../../artzu/src/Button";
import styles from "../batchActions.module.scss";
import { Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../../Containers/Loading";
import { ALL_AVAILABLE_PLATES, BATCH_EDIT_STATUS, EDIT_STATUS } from "./query";

const EditStatus = ({ selections, toggleRefresh, close, spotId }) => {
  const statuses = [
    { name: "Available", value: "available" },
    { name: "Occupied by Autzu", value: "occupied_by_autzu" },
    { name: "Occupied by Driver", value: "occupied_by_driver" },
    { name: "Unauthorized", value: "unauthorized" },
  ];

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLicensePlate, setSelectedLicensePlate] = useState();
  const [comment, setComment] = useState("");

  const triggerStatus = (childData) => {
    setSelectedStatus(childData);
  };
  const triggerLicensePlate = (childData) => {
    setSelectedLicensePlate(childData);
  };
  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };

  const addable = selectedStatus;

  const { loading, error, data, refetch } = useQuery(ALL_AVAILABLE_PLATES);
  const availablePlates = data?.getAllAvailablePlates.map((plate) => {
    return { name: plate, value: plate };
  });

  console.log(availablePlates);

  const [changeStatusMutation, { loading: isBatchLoading }] = useMutation(
    BATCH_EDIT_STATUS
  );
  const changeSpotsStatus = () => {
    changeStatusMutation({
      variables: {
        parkingSpotIds: selections,
        status: selectedStatus,
        comment: comment,
        plate: selectedLicensePlate || null,
      },
    }).then(({ data: { editStatus } }) => {
      if (editStatus.success) {
        close();
        console.log("Edit Parking Spots Status Succeed!");
      } else {
        console.log("Edit parking spots status error!");
      }
    });
  };

  const [changeOneStatusMutation, { loading: isSingleLoading }] = useMutation(
    EDIT_STATUS
  );
  const changeSingleSpotStatus = () => {
    changeOneStatusMutation({
      variables: {
        parkingSpotId: spotId,
        status: selectedStatus,
        comment: comment,
        plate: selectedLicensePlate || null,
      },
    }).then(({ data: { editParkingSpot } }) => {
      if (editParkingSpot.success) {
        close();
        console.log("Edit Parking Spot Status Succeed!");
      } else {
        console.log("Edit parking spot status error!");
      }
    });
  };

  const functionToTrigger = () => {
    if (selections) {
      return changeSpotsStatus();
    } else {
      return changeSingleSpotStatus();
    }
  };

  const assignPlate = !selections || selections.length === 1;

  return (
    <Fragment>
      <div className={styles.darkText}>Change status</div>
      <div
        className={classsNames(
          styles.contentContainer,
          styles.contentContainer__changeStatus
        )}
      >
        <div className={styles.optionContainer}>
          <div className={styles.optionBox}>
            <div className={styles.subscript}>Change status to:</div>
            <Dropdown
              width="436px"
              height="30px"
              promptColor="#dedede"
              items={statuses}
              onChange={triggerStatus}
            />
          </div>
        </div>

        {selectedStatus === "occupied_by_autzu" && assignPlate && (
          <div className={styles.optionContainer}>
            <div className={styles.optionBox}>
              <div className={styles.subscript}>Autzu vehicle license:</div>
              <Dropdown
                width="436px"
                height="30px"
                promptColor="#dedede"
                items={availablePlates}
                onChange={triggerLicensePlate}
              />
            </div>
          </div>
        )}

        <div>
          <div className={styles.subscript} style={{ marginBottom: "5px" }}>
            Comment:
          </div>
          <textarea
            className={classsNames(styles.textBox, styles.commentBox)}
            onChange={handleCommentInput}
          ></textarea>
        </div>
        <div className={styles.notesCancelButton}>
          <Button
            label="Cancel"
            height="39px"
            fullWidth="150px"
            color="#414141"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              close();
            }}
          />
        </div>
        <div className={styles.notesSaveButton}>
          <Button
            label={
              isBatchLoading || isSingleLoading ? <Loading size="1x" /> : "Save"
            }
            height="39px"
            fullWidth="150px"
            color={addable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              addable && functionToTrigger();
              toggleRefresh();
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default EditStatus;
