import React from "react";
import styles from "./footer.module.scss";
import classNames from "classnames";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviousPage = ({ currentPage, changePage }) => {
  const isActive = currentPage > 1;
  return (
    <div
      className={classNames(styles.previousPage, !isActive && styles.disabled)}
      onClick={() => (isActive ? changePage(currentPage - 1) : null)}
    >
      <FontAwesomeIcon
        className={styles.previousPageIcon}
        icon={faChevronLeft}
        size="sm"
      />
    </div>
  );
};

export default PreviousPage;
