import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import ActionButton from "../ActionButton";
import _ from "lodash";
import ActionModal from "../ActionModal";

const ParkingBatchActions = ({
  isOpen,
  selections,
  removeSelection,
  refetch,
}) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState();

  const allIcon = [
    {
      value: "edit",
      text: "Edit",
      icon: faPencil,
    },
  ];

  const batchOptionsHash = _.reduce(
    allIcon,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const closeModal = () => setSelectedBatchAction(null);

  return (
    <>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {allIcon.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                onClick={() => setSelectedBatchAction(icon.value)}
              />
            ))}
          </div>
          <ActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selections={selections}
            removeSelection={removeSelection}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            item="Spots"
          />
        </div>
      )}
    </>
  );
};

export default ParkingBatchActions;
