import React from "react";
import { Fragment } from "react";

const AverageUsage = ({ avgFleetMileage, avgMileage, city }) => {
  return (
    <Fragment>
      <div className="vehicle-information">
        <p className="tight-margin-details">Average fleet usage</p>
        <p className="tight-margin-content">
          {avgFleetMileage} {city.name === "toronto" ? " km" : " mi"}
          /day
        </p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Average vehicle usage</p>
        <p className="tight-margin-content">
          {avgMileage} {city.name === "toronto" ? " km" : " mi"}/day
        </p>
      </div>
    </Fragment>
  );
};

export default AverageUsage;
