import React, { Component, Fragment } from "react";
import VehicleDetails from "./VehicleDetails";
import StatusLog from "./StatusLog";
import VehicleTab from "./VehicleTab";
import DocumentDetails from "./UploadDocuments/DocumentDetails";
import TicketDetails from "./UploadTickets/TicketDetails";
import VehicleNotes from "./VehicleNotes";
import UploadDocumentModal from "./UploadDocuments/UploadDocumentModal";
import UploadTicketModal from "./UploadTickets/UploadTicketModal";
import Button from "../../artzu/src/Button";
import UpdateEditedFields from "../../Mutations/UpdateEditedFields";
import _ from "lodash";
import style from "../Fleet/FieldInputs/odometerModal.module.scss";
import Modal from "../../artzu/src/Modal";
import classNames from "classnames";
import "./vehicle.scss";
import moment from "moment";

class VehicleInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false, // handle 'edit' button for vehicle information update
      add: false, // handle 'add' button for document upload modal
      addTicket: false, // handle 'add' button for ticket upload modal
      display: "information",
      newNote: "",
      addNote: false,
      editParams: [],
      editedValues: {},
      editInputs: true,
      vehicleEdit: {},
      savedValues: {},
      modal: false,
      confirm: false,
      editNote: null,
      lockboxHistoryOpen: false,
    };
  }

  toggleEdit = () => this.setState({ edit: !this.state.edit });
  toggleAdd = () => this.setState({ add: !this.state.add });
  toggleAddNote = () => this.setState({ addNote: !this.state.addNote });
  toggleAddTickets = () => this.setState({ addTicket: !this.state.addTicket });
  toggleDisplay = (display) => this.setState({ display });
  onChange = (text) => this.setState({ newNote: text });
  editChange = (text) => this.setState({ editNote: text });
  toggleLockboxHistory = () =>
    this.setState({ lockboxHistoryOpen: !this.state.lockboxHistoryOpen });

  editedValuesParams = (vehicleId, type, value) => {
    let params = this.state.editParams;
    let values = {
      vehicleId,
      value: value,
      type: type,
    };

    if (params.length > 0) {
      _.remove(params, (param) => {
        return param.vehicleId === vehicleId && param.type === type;
      });

      if (value) {
        params.push(values);
      }
    } else {
      if (value) {
        params.push(values);
      }
    }

    this.setState({ editParams: params });
  };

  selectVehicleEdit = (vehicleId, type) => {
    let editableField = this.state.vehicleEdit;
    if (editableField[type]) {
      editableField[type].push(vehicleId);
    } else {
      editableField[type] = [];
      editableField[type].push(vehicleId);
    }

    this.setState({
      vehicleEdit: editableField,
    });
  };

  updateEditedValues = (plate, type, value) => {
    let edited = this.state.editedValues;
    if (edited[plate]) {
      edited[plate][type] = value;
    } else {
      edited[plate] = {};
      edited[plate][type] = value;
    }

    this.setState({ editedValues: edited });
  };

  saveEditValues = (edited) => {
    let saved = this.state.savedValues;

    const savedVals = { ...saved };

    for (let value in edited) {
      if (savedVals[value]) {
        savedVals[value] = { ...saved[value], ...edited[value] };
      } else {
        savedVals[value] = edited[value];
      }
    }
    this.setState({ savedValues: savedVals });
  };

  resetEditValues = () => this.setState({ editParams: [] });
  resetVehicleEdit = () => this.setState({ vehicleEdit: {} });
  resetUpdateEdits = () => this.setState({ editedValues: {} });

  toggleEditedInput = (value) =>
    this.setState({
      editInput: value,
    });

  setModal = (value) => {
    this.setState({ modal: value });
  };

  setConfirm = (value) => {
    this.setState({ confirm: value });
  };

  renderTap = (display) => {
    const {
      color,
      vin,
      rin,
      transponder,
      lockboxCode,
      lockboxHistory,
      parkingSpot,
      serialNumber,
      odometer,
      vehicleId,
      plate,
      avgMileage,
      avgFleetMileage,
      gasLevel,
      statusLog,
      refetch,
      city,
      medallionNumber,
      parkingLots,
      parkingLot,
      notes,
      serviceLocations,
      currentServiceLocation,
      currentLocation,
      currentUser,
      inParkingLot,
      store,
      showLocation,
      vehicle,
      uuid,
      parkingPassId,
      status,
      userSuggestions,
      userEmails,
      availableParkingSpots,
    } = this.props;

    const { edit, newNote, addNote, editNote } = this.state;

    switch (display) {
      default:
      case "information":
        return (
          <VehicleDetails
            currentUser={currentUser}
            serviceLocations={serviceLocations}
            color={color}
            vin={vin}
            rin={rin}
            currentServiceLocation={currentServiceLocation}
            currentLocation={currentLocation}
            medallionNumber={medallionNumber}
            transponder={transponder}
            lockboxCode={lockboxCode}
            lockboxHistory={lockboxHistory}
            availableParkingSpots={availableParkingSpots}
            parkingSpot={parkingSpot}
            serialNumber={serialNumber}
            odometer={odometer}
            vehicleId={vehicleId}
            plate={plate}
            avgMileage={avgMileage}
            avgFleetMileage={avgFleetMileage}
            gasLevel={gasLevel}
            refetch={refetch}
            toggleEdit={this.toggleEdit}
            edit={edit}
            city={city}
            parkingLots={parkingLots}
            parkingLot={parkingLot}
            parkingPassId={parkingPassId}
            store={store}
            updateEditParams={this.editedValuesParams}
            updateEditedValues={this.updateEditedValues}
            openHistoryModal={this.toggleLockboxHistory}
          />
        );
      case "bookings":
        return (
          <StatusLog
            vehicle={vehicle}
            currentUser={currentUser}
            city={city}
            vehicleId={vehicleId}
            statusLog={statusLog}
            parkingLot={parkingLot}
            inParkingLot={inParkingLot}
            store={store}
            uuid={uuid}
            showLocation={showLocation}
            status={status}
            fullSize
          />
        );

      case "notes":
        return (
          <VehicleNotes
            notes={notes}
            onChange={this.onChange}
            newNote={newNote}
            addNote={addNote}
            closeNote={this.toggleAddNote}
            vehicleId={vehicleId}
            refetch={refetch}
            currentUser={currentUser}
            editChange={this.editChange}
            editedNote={editNote}
            suggestions={userSuggestions}
            userEmails={userEmails}
          />
        );
    }
  };

  renderButton = (display) => {
    const { refetch, store, lockboxHistory } = this.props;
    const {
      edit,
      editParams,
      editedValues,
      modal,
      confirm,
      lockboxHistoryOpen,
    } = this.state;
    console.log(editParams);
    switch (display) {
      default:
      case "details":
        return (
          <Fragment>
            <Modal open={modal}>
              <div className={style.container}>
                <div className={classNames(style.text, style.marginBottom)}>
                  {`Odometer value entered for vehicle is invalid! Please enter a valid odometer
            reading before saving.`}
                </div>
                <div className={style.buttons}>
                  <Button
                    label="OK"
                    onClick={(event) => {
                      this.setModal(false);
                      this.setConfirm(false);
                    }}
                    color="#414141"
                  />
                </div>
              </div>
            </Modal>
            <Modal open={lockboxHistoryOpen}>
              <div className={style.container}>
                <div
                  className={classNames(style.titleText, style.marginBottom)}
                >
                  The last three lockbox codes were:
                </div>
                {lockboxHistory
                  ? lockboxHistory.map((hist) => (
                      <div
                        className={classNames(style.text, style.marginBottom)}
                      >
                        {" "}
                        {hist.code} (
                        {moment(hist.createdAt).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        )}
                        )
                      </div>
                    ))
                  : null}
                <div className={style.buttons}>
                  <Button
                    label="OK"
                    onClick={this.toggleLockboxHistory}
                    color="#414141"
                  />
                </div>
              </div>
            </Modal>
            <div
              className="edit-tab top"
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              {edit === false ? (
                <Button
                  label="Edit Information"
                  color="#128DFF"
                  border="none"
                  margin="0"
                  size="17px"
                  fullWidth="160px"
                  height="34px"
                  radius="25px"
                  onClick={() => {
                    this.toggleEdit();
                  }}
                ></Button>
              ) : (
                <div className="save-buttons-details">
                  <Button
                    label="Cancel"
                    size="17px"
                    fullWidth="100px"
                    height="34px"
                    border="none"
                    radius="25px"
                    color="#414141"
                    onClick={() => {
                      this.toggleEdit();
                      this.resetEditValues();
                      this.resetVehicleEdit();
                      this.resetUpdateEdits();
                    }}
                  />
                  <UpdateEditedFields
                    toggleEdit={this.toggleEdit}
                    refetch={refetch}
                    updateEditedFieldsParams={editParams}
                    resetEditParams={this.resetEditValues}
                    saveErrors={store.saveEditError}
                    setPlates={this.setErrorPlates}
                    setModal={this.setModal}
                    modal={modal}
                    confirm={confirm}
                    setConfirm={this.setConfirm}
                    resetVehicleEdit={this.resetVehicleEdit}
                    resetEditedFields={this.resetUpdateEdits}
                    saveEditValues={this.saveEditValues}
                    editedValues={editedValues}
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      case "notes":
        return null;
      case "bookings":
        return null;
      case "documents":
        return (
          <Fragment>
            <p className="blue tight-margin top" onClick={this.toggleAdd}>
              Add
            </p>
          </Fragment>
        );
      case "tickets":
        return (
          <Fragment>
            <p
              className="blue tight-margin top"
              onClick={this.toggleAddTickets}
            >
              Add
            </p>
          </Fragment>
        );
    }
  };

  render() {
    const { statusLog, vehicleId, refetch } = this.props;
    const { add, addTicket, display } = this.state;
    return (
      <Fragment>
        <UploadTicketModal
          vehicleId={vehicleId}
          toggleAddTickets={this.toggleAddTickets}
          addTicket={addTicket}
          refetch={refetch}
        />
        <div className="vehicle-details-tab">
          <div className="vehicle-col full-width">
            <div className="vehicle-details-tab">
              <div className="vehicle-details-tab">
                <VehicleTab
                  name="Information"
                  type="information"
                  toggle={this.toggleDisplay}
                  display={display}
                />

                {statusLog ? (
                  <VehicleTab
                    name="Log"
                    type="bookings"
                    toggle={this.toggleDisplay}
                    display={display}
                  />
                ) : null}

                <VehicleTab
                  name="Notes"
                  type="notes"
                  toggle={this.toggleDisplay}
                  display={display}
                />
              </div>

              {this.renderButton(display)}
            </div>
            {this.renderTap(display)}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default VehicleInfo;
