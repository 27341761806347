import React from "react";
import styles from "./statusInfo.module.scss";
import _ from "lodash";
import { m } from "../../../../utils";
import moment from "moment";

const Subtext = ({ startTime, endTime, comments, timezone }) => {
  const startTimeMoment = startTime && m(startTime);
  const endTimeMoment = endTime && m(endTime);
  return (
    <div className={styles.subtextContainer}>
      <div className={styles.timeContainer}>
        {endTimeText(endTimeMoment, timezone)}
      </div>
      <div className={styles.timeContainer}>
        {startTimeText(startTimeMoment, timezone)}
      </div>
      {comments && (
        <div className={styles.timeContainer}>{`Comments: ${_.truncate(
          comments,
          { length: 30 }
        )}`}</div>
      )}
    </div>
  );
};

const startTimeText = (startTime, timezone) => {
  return timeText(["Entered", "Enters"], startTime, timezone);
};

const endTimeText = (endTime, timezone) => {
  return timeText(["Exited", "Exits"], endTime, timezone);
};

const timeText = (tenses, time, timezone) => {
  const prefix = time && m().isAfter(time) ? tenses[0] : tenses[1];
  const timeString = time
    ? `${moment(time)
        .tz(timezone)
        .format("hA, ll")}`
    : "N/A";
  return `${prefix}: ${timeString}`;
};

export default Subtext;
