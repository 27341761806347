import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";

import _ from "lodash";

import "../../Vehicle/vehicle.scss";

class LockboxInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockboxCode: this.props.lockboxCode
    };
  }

  verifyInput = () => {
    if (!_.isNaN(_.toNumber(this.state.lockboxCode))) {
      // verify input is number
      return true;
    }
    return false;
  };

  render() {
    const { lockboxCode } = this.state;
    const {
      vehicleId,
      refetch,
      toggleEdit,
      classes,
      plate,
      type,
      updateEdit,
      editParams,
      editError,
      details
    } = this.props;

    return (
      <div className="lockbox" style={details ? { textAlign: "left" } : null}>
        <Input
          className={
            this.verifyInput() === false
              ? classes.lockboxError
              : lockboxCode !== this.props.lockboxCode
              ? classes.lockboxEdit
              : classes.lockboxInput
          }
          classes={{ underline: classes.underline }}
          type="number"
          autoFocus={details ? false : true}
          value={lockboxCode}
          onChange={e => {
            this.setState({ lockboxCode: e.target.value });
          }}
          onBlur={() => {
            if (this.verifyInput() && lockboxCode !== this.props.lockboxCode) {
              editParams(Number(vehicleId), type, lockboxCode);
              updateEdit(plate, type, lockboxCode);
            }
          }}
          style={details ? { width: "200px" } : null}
        />
      </div>
    );
  }
}

const styles = theme => ({
  lockboxInput: {
    width: "100px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  lockboxEdit: {
    width: "100px",
    color: "#128DFF",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  lockboxError: {
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  }
  // underline: {
  //   "&:after": {
  //     borderBottom: `2px solid ${theme.palette.blue}`,
  //   },
  // },
});

export default withStyles(styles)(LockboxInput);
