import React from "react";
import StatusList from "../../StatusChange/ActionButtons";
import ActionButtons from "../../ActionButtons";
import OdoGasDisplay from "./OdoGasDisplay";

const HeaderBot = ({ vehicle, city, showLocation, status, toggleRefresh }) => {
  const { odometer, gasLevel } = vehicle;
  return (
    <div className="action-display">
      <OdoGasDisplay odometer={odometer} city={city} gasLevel={gasLevel} />
      <div className="botRight">
        <ActionButtons
          vehicle={vehicle}
          showLocation={showLocation}
          toggleRefresh={toggleRefresh}
        />
        <StatusList
          vehicle={vehicle}
          operationStatus={status}
          toggleRefresh={toggleRefresh}
        />
      </div>
    </div>
  );
};

export default HeaderBot;
