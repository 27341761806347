import React, { Component } from "react";
import ImageDisplay from "./ImageDisplay";
import DamageButtons from "./DamageButtons";
import DamageHeading from "./DamageHeading";
import NewDamageSelection from "./NewDamageSelection";

class NewDamageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      filePreview: null,
      description: "",
    };
  }

  updateDamage = (type, val) => this.setState({ [type]: val });

  render() {
    const {
      refetch,
      locationX,
      locationY,
      location,
      vehicleId,
      make,
      scope,
      alterDamageState,
    } = this.props;
    const { file, filePreview, description } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DamageHeading
          scope={scope}
          alterDamageState={alterDamageState}
          text="Cancel"
        />
        <NewDamageSelection
          location={location}
          alterDamageState={alterDamageState}
          scope={scope}
          make={make}
        />
        <textarea
          className="textbox-input"
          placeholder="Describe the damage here..."
          value={description}
          rows="4"
          cols="40"
          onChange={(evt) => this.updateDamage("description", evt.target.value)}
        />

        {file ? <ImageDisplay filePreview={filePreview} /> : null}
        <DamageButtons
          file={file}
          updateDamage={this.updateDamage}
          alterDamageState={alterDamageState}
          newDamageReportParams={{
            damage: {
              scope,
              location,
              locationX: locationX ? locationX : 0,
              locationY: locationY ? locationY : 0,
              description,
              vehicleId,
            },
          }}
          refetch={refetch}
        />
      </div>
    );
  }
}

export default NewDamageForm;
