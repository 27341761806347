const getColor = (primary, color) =>
	`background: ${primary ? color : 'white'};
	color: ${primary ? 'white' : color};`

const getBorderWidth = (first, last) => {
	if (first && last) return 'border: none;'
	else if (first) return 'border-width: 0 2px 2px 2px;'
	else if (last) return 'border-width: 2px 2px 0 2px;'
	else return 'border-width: 2px;'
}

const isSelected = (selectedValue, optionValue) => selectedValue === optionValue

const getSelectedBorder = (
	selected,
	primaryColor,
	secondaryColor,
	index,
	length
) => {
	if (selected) {
		return `
		 	border-color: ${primaryColor};
			border-radius: 26px;
		  ${getBorderWidth(index === 0, index === length - 1)}
		`
	} else return `color: ${secondaryColor};`
}

export { getColor, getSelectedBorder, isSelected }
