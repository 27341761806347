import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import "../Components/Vehicle/vehicle.scss";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../artzu/src/Button";
import style from "../Components/Fleet/FieldInputs/odometerModal.module.scss";
import Modal from "../artzu/src/Modal";
import classNames from "classnames";
import _ from "lodash";

class UpdateEditedFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UPDATE_EDITEDFIELDS = gql`
    mutation updateEditedFields(
      $updateEditedFieldsParams: [UpdateEditedFieldsAttributes!]!
    ) {
      updateEditedFields(params: $updateEditedFieldsParams) {
        errors
        success
      }
    }
  `;

  REFRESH_PARKING_SPOT_DATA = gql`
    {
      getAllVehicles {
        id
        availableParkingSpots {
          id
          identifier
        }
        lockboxCode
      }
    }
  `;

  setModal = value => {
    this.setState({ modal: value });
  };

  errorCheck = errors => {
    let vehiclePlates = [];
    let check = false;
    for (let error in errors) {
      if (
        errors[error].code ||
        errors[error].parking ||
        errors[error].odometer
      ) {
        vehiclePlates.push(error);
        check = true;
      }
    }
    if (this.props.setPlates) {
      this.props.setPlates(vehiclePlates);
    }
    return { plates: vehiclePlates, error: check };
  };

  render() {
    const {
      refetch,
      updateEditedFieldsParams,
      toggleEdit,
      resetEditParams,
      saveErrors,
      setPlates,
      modal,
      setModal,
      confirm,
      setConfirm,
      toggleEditInputs,
      resetVehicleEdit,
      saveEditValues,
      resetEditedFields,
      editedValues
    } = this.props;

    return (
      <div>
        <Mutation
          mutation={this.UPDATE_EDITEDFIELDS}
          variables={{ updateEditedFieldsParams }}
          onCompleted={() => {
            resetEditParams();
            toggleEdit();
            resetVehicleEdit();
            if (refetch) {
              refetch();
            }
          }}
          onError={data =>
            console.log("Error updating fields that were edited", data)
          }
          refetchQueries={[
            {
              query: this.REFRESH_PARKING_SPOT_DATA
            }
          ]}
        >
          {updateEditedFields => (
            <Button
              label="Save"
              size="17px"
              fullWidth="100px"
              height="34px"
              border="none"
              radius="25px"
              color="#128dff"
              onClick={() => {
                if (this.errorCheck(saveErrors).error === true) {
                  setModal(true);
                } else {
                  if (updateEditedFieldsParams.length > 0) {
                    saveEditValues(editedValues);
                    resetEditedFields();
                    updateEditedFields();
                  } else {
                    resetEditParams();
                    toggleEdit();
                  }
                }
              }}
            />
          )}
        </Mutation>
      </div>
    );
  }
}

export default UpdateEditedFields;
