import { gql } from "@apollo/client";

const BULK_ADD_COMMENT = gql`
  mutation addComment($taskIds: [ID!]!, $comment: String!) {
    addComment(taskIds: $taskIds, comment: $comment) {
      success
      error
    }
  }
`;

const BULK_UPDATE_TAGS = gql`
  mutation modifyTag(
    $tagsToRemove: [String!]
    $newTags: [String!]
    $taskIds: [ID!]
  ) {
    modifyTag(
      tagsToRemove: $tagsToRemove
      newTags: $newTags
      taskIds: $taskIds
    ) {
      success
      errors
    }
  }
`;

const BULK_ASSIGN_ADMIN = gql`
  mutation assignToAdmin(
    $taskIds: [ID!]!
    $adminUserId: ID!
    $comment: String
  ) {
    assignToAdmin(
      taskIds: $taskIds
      adminUserId: $adminUserId
      comment: $comment
    ) {
      success
      error
    }
  }
`;

const BULK_CHANGE_TASK_STATUS = gql`
  mutation changeTaskStatus(
    $taskIds: [ID!]!
    $status: String!
    $comment: String
  ) {
    changeTaskStatus(taskIds: $taskIds, status: $status, comment: $comment) {
      success
      error
    }
  }
`;

export {
  BULK_ADD_COMMENT,
  BULK_UPDATE_TAGS,
  BULK_ASSIGN_ADMIN,
  BULK_CHANGE_TASK_STATUS,
};
