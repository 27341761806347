import React from "react";
import Dropdown from "../../../Shared/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import styles from "../../task.module.scss";

const TagsDropdownAdding = ({
  newTag,
  allTagList,
  triggerTag,
  taskData,
  addable,
  handleAddTag,
}) => {
  return (
    <div className={styles.searchAddContainer}>
      <Dropdown
        initialValue={newTag}
        items={allTagList}
        onChange={triggerTag}
        width="250px"
        height="30px"
        disabled={!taskData}
        promptColor={"#dedede"}
      />
      <button
        className={
          addable ? styles.addButtonContainer : styles.invalidAddButtonContainer
        }
      >
        <div
          className={addable ? styles.addTagButton : styles.invalidAddTagButton}
          onClick={() => {
            addable && handleAddTag();
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="md"
            className={styles.plusIconNew}
          />
          <div style={{ fontWeight: "600" }}>Add</div>
        </div>
      </button>
    </div>
  );
};

export default TagsDropdownAdding;
