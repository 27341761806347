import React from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_NEW_TASK,
  RESOLVE_TASK,
  RESTORE_TASK,
  REMOVE_FROM_BACKLOG,
  UPDATE_TASK,
} from "../queries";
import { useHistory } from "react-router";
import { Fragment } from "react";
import { useState } from "react";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../task.module.scss";
import AssignAdminModal from "./AssignAdminModal";
import TaskActionPopup from "./TaskActionPopup";
import RemoveFromBacklogPopup from "./RemoveFromBacklogPopup";
import { getLabel } from "../utils";

const ChangeStatusButton = ({
  taskData,
  refreshTaskCallback,
  ableToCreate,
  vehicleId,
  dueDate,
  curType,
  curDescription,
  adminId,
  adminName,
  adminIdCallback,
  isEdit,
  toggleEdit,
  currentAdmin,
  cancelEdit,
  resetUpdate,
}) => {
  const history = useHistory();
  const [isAssign, setIsAssign] = useState();
  const [isPopup, setIsPopup] = useState();
  const [isRemove, setIsRemove] = useState();

  const toggleAssign = () => {
    setIsAssign(!isAssign);
  };

  const toggleRemove = () => {
    setIsRemove(!isRemove);
  };

  const togglePopup = () => {
    setIsPopup(!isPopup);
  };

  const getAction = (status) => {
    switch (status) {
      case "in_progress":
        resolveTask();
        break;
      case "resolved":
        togglePopup();
        break;
      case "unassigned":
        toggleAssign();
        break;
      case "deleted":
        restoreTask();
        break;
      case "new":
        createTask();
        break;
      case "save":
        updateTask();
        break;
      case "backlog":
        taskData.dueDate ? handleRemoveBacklog() : toggleRemove();
        break;
      default:
        break;
    }
  };

  const [restoreTaskMutation, { loading: isRestoreLoading }] = useMutation(
    RESTORE_TASK
  );
  const restoreTask = () => {
    restoreTaskMutation({
      variables: {
        taskId: taskData?.id,
      },
    }).then(({ data: { restoreTask } }) => {
      if (restoreTask.success) {
        refreshTaskCallback({ taskId: taskData.id });
        console.log("Restore Task Succeed!");
      } else {
        console.log("Restore Task error!");
      }
    });
  };

  const [resolveTaskMutation, { loading: isResolveLoading }] = useMutation(
    RESOLVE_TASK
  );
  const resolveTask = () => {
    resolveTaskMutation({
      variables: {
        taskId: taskData.id,
      },
    }).then(({ data: { resolveTask } }) => {
      if (resolveTask.task) {
        refreshTaskCallback({ taskId: taskData.id });
        console.log("Resolve Succeed!");
      } else {
        console.log("Resolve error!");
      }
    });
  };

  const [removeFromBacklogMutation, loading] = useMutation(REMOVE_FROM_BACKLOG);
  const handleRemoveBacklog = () => {
    removeFromBacklogMutation({
      variables: {
        taskId: taskData?.id,
        dueDate: null,
      },
    }).then(({ data: { removeTaskFromBacklog } }) => {
      if (removeTaskFromBacklog.success) {
        refreshTaskCallback({ taskId: taskData.id });
        console.log("Recover Succeed!");
      } else {
        console.log("Recover error!");
      }
    });
  };

  const [updateTaskMutation, { loading: isUpdateLoading }] = useMutation(
    UPDATE_TASK
  );
  const updateTask = () => {
    updateTaskMutation({
      variables: {
        taskId: taskData.id,
        userId: adminId ? adminId : null,
        dueDate: dueDate !== undefined ? dueDate : taskData?.dueDate,
        type: curType ? curType : null,
        description: curDescription ? curDescription : null,
      },
    }).then(({ data: { updateTask } }) => {
      if (updateTask.success) {
        toggleEdit();
        resetUpdate();
        refreshTaskCallback({ taskId: taskData.id });
        // window.location.reload();
        console.log("Update Succeed!");
      } else {
        console.log("Update error!");
      }
    });
  };
  const [createTaskMutation, { loading: isCreateLoading }] = useMutation(
    CREATE_NEW_TASK
  );
  const createTask = () => {
    createTaskMutation({
      variables: {
        vehicleId: vehicleId,
        type: curType,
        description: curDescription,
        dueDate: dueDate ? dueDate : null,
        userId: adminId ? adminId : null,
      },
    }).then(({ data: { createTask } }) => {
      if (createTask.task) {
        console.log("Create Succeed!");
        history.push({
          pathname: `/tasks/${createTask.task.id}`,
        });
      } else {
        console.log("Create error!");
      }
    });
  };

  const isUpdated =
    curType ||
    curDescription ||
    dueDate === null ||
    dueDate ||
    (taskData?.user && taskData.user.id !== adminId);

  const ableToAct = () => {
    if (taskData?.status) {
      if (isEdit) {
        return isUpdated;
      } else {
        return true;
      }
    } else {
      return ableToCreate;
    }
  };
  const isLoading = isCreateLoading;
  const buttonStyle =
    taskData?.status === "backlog"
      ? styles.removeFromBacklogActionButton
      : styles.multiActionButton;
  const statusName = isEdit ? "save" : taskData ? taskData.status : "new";

  return (
    <Fragment>
      <div
        className={
          isEdit ? styles.changeStatusButtonEditMode : styles.changeStatusButton
        }
      >
        <button
          className={buttonStyle}
          style={{ backgroundColor: ableToAct() ? "#128dff" : "#87cefa" }}
          onClick={() => !isLoading && ableToAct() && getAction(statusName)}
        >
          {isLoading ? (
            <FontAwesomeIcon
              style={{ color: "#ffffff" }}
              spin
              size={"1x"}
              icon={faSpinner}
            />
          ) : (
            getLabel(statusName)
          )}
        </button>
        {isEdit && (
          <button
            className={styles.editModeCancelButton}
            onClick={() => cancelEdit()}
          >
            Cancel
          </button>
        )}
      </div>
      <AssignAdminModal
        open={isAssign}
        adminIdCallback={adminIdCallback}
        taskData={taskData}
        refreshTaskCallback={refreshTaskCallback}
        toggleAdmin={toggleAssign}
        directAssign={true}
        currentAdmin={currentAdmin}
      />
      <RemoveFromBacklogPopup
        open={isRemove}
        toggleRemove={toggleRemove}
        refreshTaskCallback={refreshTaskCallback}
        taskData={taskData}
      />
      <TaskActionPopup
        open={isPopup}
        action={getLabel(taskData ? taskData.status : "new")}
        refreshTaskCallback={refreshTaskCallback}
        togglePopup={togglePopup}
        taskData={taskData}
      />
    </Fragment>
  );
};

export default ChangeStatusButton;
