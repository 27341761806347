import React from "react";

import Checkbox from "@material-ui/core/Checkbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

import styles from "../changeStatus.module.scss";

const ToggleEndDate = ({ endDateSelected, toggleEndDateSelected }) => {
  return (
    <div className={styles.toggleEndDate}>
      <div className={styles.fieldsComponentHeader}> End Date?</div>
      <Checkbox
        className="sf-check"
        checked={endDateSelected}
        style={{
          width: 12,
          height: 12,
          color: "#128DFF",
          marginLeft: "12px"
        }}
        icon={
          <FontAwesomeIcon
            icon={faSquare}
            className={styles.checkBoxUnchecked}
          />
        }
        checkedIcon={
          <FontAwesomeIcon
            className={styles.checkBoxUnchecked}
            icon={faCheckSquare}
          />
        }
        onChange={toggleEndDateSelected}
        inputProps={{
          "aria-label": "secondary checkbox"
        }}
      />
    </div>
  );
};

export default ToggleEndDate;
