import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import "../Components/Vehicle/vehicle.scss";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../artzu/src/Modal";
import { Fragment } from "react";
import styles from "../Components/VehicleNew/VehicleNotes/notes.module.scss";
import Button from "../artzu/src/Button";

const DeleteNote = ({ refetch, noteId }) => {
  const [deleteOpen, setDeleteOpen] = useState();

  const toggleDeleteModal = () => {
    setDeleteOpen(!deleteOpen);
  };

  const closeModal = () => {
    setDeleteOpen(false);
  };

  const DELETE_NOTE = gql`
    mutation deleteNote($noteId: ID!) {
      deleteNote(id: $noteId) {
        success
        errors
      }
    }
  `;

  return (
    <Mutation
      mutation={DELETE_NOTE}
      onCompleted={() => refetch()}
      variables={{ noteId }}
      onError={(data) => {
        console.log("error", data);
      }}
    >
      {(deleteNote) => (
        <Fragment>
          <div className="iconButtons">
            <FontAwesomeIcon
              className="delete-note"
              icon={faTrashAlt}
              onClick={toggleDeleteModal}
            />
          </div>
          <Modal open={deleteOpen} backgroundColor="none" padding="0">
            <div className={styles.deleteContainer}>
              <div className={styles.message}>
                Are you sure you want to delete this note?
              </div>
              <div className={styles.actionButtons}>
                <div>
                  <Button
                    label={"No"}
                    fullWidth="152px"
                    height="40px"
                    color="#414141"
                    backColor="#ffffff"
                    radius="20px"
                    size="16px"
                    weight="700"
                    border="1px solid #FFFFFF"
                    onClick={closeModal}
                  />
                </div>
                <div>
                  <Button
                    label={"Yes"}
                    fullWidth="152px"
                    height="40px"
                    color="#128DFF"
                    backColor="#FFFFFF"
                    radius="20px"
                    size="16px"
                    weight="700"
                    border="1px solid #FFFFFF"
                    onClick={() => {
                      deleteNote();
                      toggleDeleteModal();
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </Fragment>
      )}
    </Mutation>
  );
};

export default DeleteNote;

// comment
