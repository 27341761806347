import styles from "../statusLog.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import Input from "@material-ui/core/Input";
import React from "react";

const SearchBar = ({ handleSearch }) => {
  return (
    <div className={styles.searchBar}>
      <div className={styles.searchIcon}>
        <FontAwesomeIcon icon={faSearch} color="#414141" />
      </div>
      <input
        placeholder="Search by User or Status"
        type="text"
        onChange={(evt) => {
          handleSearch(evt.target.value);
        }}
        className={styles.searchInput}
      />
    </div>
  );
};

export default SearchBar;
