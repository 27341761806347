import React, { useEffect } from "react";
import Body from "./Body";
import Header from "./Header";
import styles from "../informationTable.module.scss";

const Table = ({
  tableData: { rows, headers },
  changeSort,
  currentSort,
  tabs,
  changeSelectedTab,
  selectedRows,
  selectRow,
  selectAllRows,
  isAllSelected,
  isEditModeActivated,
  modifyEdits,
  edits,
  rowComponentType,
  refetch,
  baseVariables,
  popupSpecific,
  popupFunc,
  tableName,
  vehicleTimeZone,
  hasTabs,
}) => {
  return (
    <div
      className={
        popupSpecific ? styles.tableContainerPopup : styles.tableContainer
      }
    >
      <div className={styles.table}>
        <Header
          isAllSelected={isAllSelected}
          selectedRows={selectedRows}
          selectAllRows={selectAllRows}
          tabs={tabs}
          headers={headers}
          changeSort={changeSort}
          currentSort={currentSort}
          changeSelectedTab={changeSelectedTab}
          popupSpecific={popupSpecific}
          tableName={tableName}
          hasTabs={hasTabs}
        />
        <Body
          edits={edits}
          rows={rows}
          selectedRows={selectedRows}
          selectRow={selectRow}
          isEditModeActivated={isEditModeActivated}
          modifyEdits={modifyEdits}
          rowComponentType={rowComponentType}
          popupSpecific={popupSpecific}
          popupFunc={popupFunc}
          tableName={tableName}
          vehicleTimeZone={vehicleTimeZone}
        />
      </div>
    </div>
  );
};

export default Table;
