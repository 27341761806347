import React from "react";
import styled from "styled-components";
import { getSelectedBorder, isSelected } from "../SelectField/selectHelpers";

const StyledOption = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0 8px;
  margin: 4px 0;
  height: ${({ height }) => height || "100%"};
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  font-size: ${({ fontSize }) => fontSize};
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    height: ${({ height }) => `calc(${height} - 1px)` || "100%"};
  }
  :hover {
    background-color: #dedede;
    transition: 0.3s;
  }
  ${({
    selectedValue,
    value,
    primaryColor,
    theme,
    secondaryColor = theme.darkGrey,
    index,
    length,
  }) =>
    getSelectedBorder(
      isSelected(selectedValue, value),
      primaryColor,
      secondaryColor,
      index,
      length
    )}
`;

const Option = ({ label = "", value = null, newSelection, ...props }) => (
  <StyledOption
    {...props}
    value={value}
    onClick={() => newSelection(label, value)}
  >
    <div
      style={{
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {label}
    </div>
  </StyledOption>
);

export default Option;
