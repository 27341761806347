import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import styles from "../task.module.scss";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../artzu/src/Modal";
import { REMOVE_FROM_BACKLOG } from "../queries";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "react-datepicker";

const RemoveFromBacklogPopup = ({
  open,
  toggleRemove,
  refreshTaskCallback,
  taskData,
}) => {
  const [selectedDue, setSelectedDue] = useState();

  const [removeFromBacklogMutation, { loading, error }] = useMutation(
    REMOVE_FROM_BACKLOG
  );
  const handleRemoveBacklog = () => {
    removeFromBacklogMutation({
      variables: {
        taskId: taskData?.id,
        dueDate: selectedDue,
      },
    }).then(({ data: { removeTaskFromBacklog } }) => {
      if (removeTaskFromBacklog.success) {
        refreshTaskCallback({ taskId: taskData.id });
        toggleRemove();
        console.log("Recover Succeed!");
      } else {
        console.log("Recover error!");
      }
    });
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.popupContainer}>
        <div className={styles.popupTitle}>Remove from backlog</div>
        <div className={styles.datepickerContainer}>
          <DatePicker
            selected={selectedDue}
            onChange={(date) => {
              setSelectedDue(date);
            }}
            dateFormat="MMM DD, YYYY"
            todayButton={"Today"}
            placeholderText="Select date"
            popperPlacement="bottom"
            showTimeSelect
            timeFormat="H:mm"
            className={styles.dateInputPop}
          />
          <FontAwesomeIcon
            icon={faCalendar}
            className={styles.dueDateCalendarPop}
            size="lg"
          />
        </div>
        <div className={styles.reasonButtons}>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => {
                toggleRemove();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                selectedDue ? styles.chooseButton : styles.chooseButtonInvalid
              }
              onClick={() => {
                handleRemoveBacklog();
              }}
            >
              {loading ? (
                <FontAwesomeIcon
                  style={{ color: "#128dff" }}
                  spin
                  size={"1x"}
                  icon={faSpinner}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveFromBacklogPopup;
