import React from "react";
import Button from "../../../artzu/src/Button";
import Modal from "../../../artzu/src/Modal";
import styles from "./actionButtons.module.scss";

const CommandConfirm = ({ open, close, confirm, action }) => (
  <Modal open={open}>
    <div className={styles.modal}>
      <div>Are you sure that you want to {action} this car?</div>
      <div className={styles.buttonContainer}>
        {" "}
        <Button label={"Cancel"} onClick={close} />
        <Button label={"Yes"} onClick={confirm} primary />
      </div>
    </div>
  </Modal>
);

export default CommandConfirm;
