import React, { useEffect, useState } from "react";
import styles from "../batchActions.module.scss";
import { faPen, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import ActionButton from "../ActionButton";
import _ from "lodash";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import DamageActionModal from "./DamageActionModal";

const DamageBatchActions = ({
  isOpen,
  selectedRows,
  clearAllSelections,
  tabs,
  refetch,
  removeSelection,
}) => {
  const allIcon = [
    {
      value: "changeStatus",
      text: "Change status",
      icon: faCircle,
    },
    {
      value: "edit",
      text: "Edit",
      icon: faPen,
    },
    {
      value: "delete",
      text: "Delete",
      icon: faTrashAlt,
    },
    {
      value: "deselect",
      text: "Deselect",
      icon: faTimes,
    },
  ];

  const multiSelectIcon = [
    {
      value: "changeStatus",
      text: "Change status",
      icon: faCircle,
    },
    {
      value: "delete",
      text: "Delete",
      icon: faTrashAlt,
    },
    {
      value: "deselect",
      text: "Deselect",
      icon: faTimes,
    },
  ];

  const [selectedBatchAction, setSelectedBatchAction] = useState();
  const [icons, setIcons] = useState();

  const selectedDamages = Object.keys(_.pickBy(selectedRows));

  useEffect(() => {
    if (selectedDamages.length > 1) {
      setIcons(multiSelectIcon);
    } else {
      setIcons(allIcon);
    }
  }, [selectedDamages.length]);

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const closeModal = () => setSelectedBatchAction(null);

  return (
    <>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {icons.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                onClick={(e) => {
                  icon.text === "Deselect"
                    ? clearAllSelections()
                    : setSelectedBatchAction(icon.value);
                }}
              />
            ))}
          </div>
          <DamageActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selectedDamages={selectedDamages}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            clearAllSelections={clearAllSelections}
            removeSelection={removeSelection}
          />
        </div>
      )}
    </>
  );
};

export default DamageBatchActions;
