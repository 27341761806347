import React from "react";
import ListPopup from "../../../../Shareable/ListPopup";
import classNames from "classnames";
import styles from "../../../../Shareable/ListPopup/listPopup.module.scss";
import Button from "../../../../../artzu/src/Button";

const statuses = [
  { name: "Available", value: "available" },
  { name: "Service", value: "service" },
  { name: "Offline", value: "offline" },
  { name: "Deactivated", value: "deactivated" }
];

const ButtonComponent = ({ onClick }) => (
  <button onClick={onClick} className={styles.button}>
    {". . ."}
  </button>
);

const SelectedButtonComponent = ({ onClick }) => (
  <button
    onClick={onClick}
    className={classNames(styles.button, styles.listOpen)}
  >
    {". . ."}
  </button>
);

const StatusList = ({ startStatusChange, operationStatus }) => (
  <div className={styles.marginLeft}>
    <ListPopup
      currentValue={operationStatus}
      onItemClick={startStatusChange}
      listItems={statuses}
      buttonComponent={ButtonComponent}
      selectedButtonComponent={SelectedButtonComponent}
    />
  </div>
);

export default StatusList;
