import React, { useState, Fragment } from "react";
import styles from "../../batchActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Button from "../../../../../artzu/src/Button";

const AddNewTags = ({
  newTag,
  triggerTag,
  parentCallback,
  added,
  commonTags,
  pc,
  duplicate,
}) => {
  // let addable = allTagList.includes(newTag);
  const [userInput, setUserInput] = useState();
  const onChange = (e) => {
    let currentInput = e.target.value;
    setUserInput(currentInput);
    triggerTag(currentInput);
    e.preventDefault();
  };
  return (
    <Fragment>
      <div className={styles.addTagContainer}>
        {/* <Autocomplete
          options={allTagList}
          parentCallback={triggerTag}
          handleAdd={parentCallback}
          newTag={newTag}
          addable={addable}
        /> */}
        <div className={styles.autocompleteClose}>
          <FontAwesomeIcon icon={faSearch} />
          <input
            className={styles.inputBox}
            onChange={onChange}
            value={newTag !== "" ? userInput : newTag}
          />
        </div>
        <div className={styles.addtagAddButton}>
          <Button
            fullWidth="80px"
            height="34px"
            radius="17px"
            border="none"
            color="#FFFFFF"
            backColor={"#128DFF"}
            padding="0 15px 0 15px"
            label={
              <div className={styles.addButton}>
                <FontAwesomeIcon icon={faPlus} class={styles.faplusIcon} />
                <div style={{ fontSize: "16px", fontWeight: "700" }}>Add</div>
              </div>
            }
            onClick={() => {
              if (newTag) {
                parentCallback(newTag);
              }
            }}
          />
        </div>
      </div>
      <div className={styles.tagBox} style={{ height: "145px" }}>
        {added.map((t) => (
          <div className={commonTags.includes(t) ? styles.tagRed : styles.tag}>
            {t}{" "}
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.times}
              onClick={() => pc(t)}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.subscript}
        style={{ marginTop: 32, marginBottom: 4 }}
      >
        Common tags on selected tasks
      </div>
      {duplicate ? (
        <span className={styles.already}>Already exists*</span>
      ) : null}
    </Fragment>
  );
};

export default AddNewTags;
