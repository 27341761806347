import React from "react";
import _ from "lodash";
import styles from "./statusInfo.module.scss";
import OperationStatusSymbol from "./OperationStatusSymbol";
import Subtext from "./Subtext";
import { TableCell } from "../../../../artzu/src/Table";
import EntryModificationOptions from "./EntryModificationOptions";
import moment from "moment";

const OperationStatus = ({ statusInterval, showBar, fullSize, timezone }) => {
  const {
    operationStatus,
    startTime,
    endTime,
    userName,
    comments,
    identificationKey,
  } = statusInterval;

  const formattedOperationStatus = _.startCase(operationStatus);

  return (
    <TableCell width={"100%"}>
      <div className={styles.operationStatusIdentifier}>
        <div
          className={showBar ? styles.symbolLine : styles.symbolLineCoverup}
        />
        <OperationStatusSymbol operationStatus={formattedOperationStatus} />
        <div className={styles.textContainer}>
          <div className={styles.operationStatusTextContainer}>
            <div className={styles.operationStatusText}>
              {formattedOperationStatus}
            </div>
            {fullSize && moment().isBefore(startTime) && (
              <EntryModificationOptions
                operationStatus={operationStatus}
                identificationKey={identificationKey}
              />
            )}
            {!fullSize && userName && <div>{` - ${userName}`}</div>}
          </div>
          <Subtext
            startTime={startTime}
            endTime={endTime}
            comments={!fullSize && comments}
            timezone={timezone}
          />
        </div>
      </div>
    </TableCell>
  );
};

export default OperationStatus;
