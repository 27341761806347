import React, { Component } from "react";
import { Link } from "react-router-dom";
import ServiceHeader from "./ServiceHeader";
import HistoryTable from "./HistoryTable";
import UpcomingTable from "./UpcomingTable";
import InspectionsTable from "./InspectionsTable";
import NavDisplay from "../Shareable/NavDisplay";
import EventTabs from "./EventTabs";
import EventModal from "./EventModal";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Motion, spring } from "react-motion";
import { inject, observer } from "mobx-react";
import { formatNavTitle } from "../../utils/index";
import Button from "../../artzu/src/Button";
import "./service.css";
import "../Fleet/fleet.css";

const ServiceEvents = inject("store")(
  observer(
    class ServiceEvents extends Component {
      constructor(props) {
        super(props);
        this.state = {
          open: false,
          type: "",
          eventId: null,
          action: ""
        };
      }

      toggle = (action, type, eventId) =>
        this.setState({ open: !this.state.open, type, action, eventId });

      findEvent = () => {
        const {
          upcomingEvents,
          pastServiceEvents,
          inspections
        } = this.props.data;
        const { eventId } = this.state;
        return [...upcomingEvents, ...pastServiceEvents, ...inspections].filter(
          event => event.id === eventId
        )[0];
      };

      componentDidMount() {
        const { store, data, history, location } = this.props;
        // if (history) {
        //   store.changeNavigatedPage(
        //     formatNavTitle(data, history.location.pathname, null)
        //   );
        // }
      }

      render() {
        const {
          data,
          mode,
          changeMode,
          refetch,
          fleetManager,
          history
        } = this.props;
        const { open, type, action } = this.state;
        return (
          <div>
            <div className="vehicle-redirects">
              <NavDisplay
                navPath={this.props.store.navigatedPage}
                history={this.props.history}
              />
            </div>
            <div className="service-top">
              {
                // <Link to={`/vehicles/${data.id}`} className="back-to-vehicle link">
                //   <FontAwesomeIcon className="blue" icon={faChevronLeft} />
                //   <p className="blue">Vehicle details</p>
                // </Link>
              }
              <div className="above-table">
                <ServiceHeader
                  year={data.year}
                  make={data.make}
                  model={data.model}
                  plate={data.plate}
                />
                <Motion
                  defaultStyle={{ x: -200, opacity: 0 }}
                  style={{
                    x: spring(0, { stiffness: 200, damping: 25 }),
                    opacity: spring(1)
                  }}
                >
                  {style => (
                    <div
                      className="top-right service-button-container"
                      style={{
                        transform: `translateY(${style.y}px)`,
                        opacity: style.opacity,
                        width: "450px"
                      }}
                    >
                      <Button
                        fullWidth
                        label="Create service ticket"
                        onClick={() => this.toggle("new", "service")}
                      />
                      <Button
                        fullWidth
                        onClick={() => this.toggle("new", "inspection")}
                        label="Vehicle Inspection"
                      />
                    </div>
                  )}
                </Motion>
              </div>
              <EventTabs mode={mode} changeMode={changeMode} />
              <Motion
                defaultStyle={{ y: 200, opacity: 0 }}
                style={{
                  y: spring(0, { stiffness: 200, damping: 25 }),
                  opacity: spring(1)
                }}
              >
                {style => (
                  <div
                    style={{
                      transform: `translateY(${style.y}px)`,
                      opacity: style.opacity
                    }}
                  >
                    {(() => {
                      switch (mode) {
                        case "history":
                          return (
                            <HistoryTable
                              events={data.pastServiceEvents}
                              vehicleId={data.id}
                              toggle={this.toggle}
                              refetch={refetch}
                              units={
                                data.parkingLot.city.name === "toronto"
                                  ? "km"
                                  : "mi"
                              }
                            />
                          );
                        case "upcoming":
                          return (
                            <UpcomingTable
                              events={data.upcomingEvents}
                              vehicleId={data.id}
                              toggle={this.toggle}
                              refetch={refetch}
                            />
                          );
                        case "inspection":
                          return (
                            <InspectionsTable
                              events={data.inspections}
                              vehicleId={data.id}
                              toggle={this.toggle}
                              units={
                                data.parkingLot.city.name === "toronto"
                                  ? "km"
                                  : "mi"
                              }
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                )}
              </Motion>
              <EventModal
                fleetManager={fleetManager}
                open={open}
                action={action}
                type={type}
                data={this.findEvent()}
                toggle={this.toggle}
                odometerReading={data.odometer.reading}
                prevOdometerReading={data.odometer.reading}
                vehicleId={data.id}
                refetch={refetch}
                serviceCatsNames={data.serviceCatsNames}
              />
            </div>
          </div>
        );
      }
    }
  )
);

export default ServiceEvents;
