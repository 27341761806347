import React from "react";
import InputContent from "./InputContent";
import styles from "../task.module.scss";

const InputContainer = ({
  activeTab,
  handleSend,
  taskData,
  refreshTaskCallback,
  assignTask,
}) => {
  return (
    <div className={styles.inputContainer}>
      <InputContent
        handleSend={handleSend}
        tab={activeTab}
        taskData={taskData}
        refreshTaskCallback={refreshTaskCallback}
        assignTask={assignTask}
      />
    </div>
  );
};

export default InputContainer;
