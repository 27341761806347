import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { renderColor, titleize } from "../../../../utils";
import _, { capitalize } from "lodash";

const ParkingStatusValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;

  const getText = () => {
    switch (text) {
      case "occupied_by_autzu":
        return "Autzu";
      case "occupied_by_driver":
        return "Driver";
      case "available":
        return "Available";
      case "unauthorized":
        return "Unauthorized";
      default:
        break;
    }
  };
  const getColor = (text) => {
    switch (text) {
      case "available":
        return "#f2f2f2";
      case "occupied_by_autzu":
        return "#128dff";
      case "occupied_by_driver":
        return "orange";
      case "unauthorized":
        return "#ff0000";
      default:
        break;
    }
  };

  const getBackColor = (text) => {
    switch (text) {
      case "available":
        return "#414141";
      case "occupied_by_autzu":
        return "rgba(18, 140, 255, 0.3)";
      case "occupied_by_driver":
        return "#ffe2c1";
      case "unauthorized":
        return "#ffbaba";
      default:
        break;
    }
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={styles.parkingTag}
        style={{
          color: `${getColor(text ? text : "new")}`,
          backgroundColor: `${getBackColor(text ? text : "new")}`,
          border: `1px ${getBackColor(text ? text : "new")} solid;`,
        }}
      >
        {getText()}
      </div>
    </div>
  );
};

export default ParkingStatusValue;
