import React, { useEffect, useState } from "react";
import styles from "../damages.module.scss";
import Modal from "../../../../artzu/src/Modal";
import DropdownInput from "./DropdownInput";
import { getDropdownOptions } from "../utils";
import TextInput from "./TextInput";
import TagInput from "./TagInput";
import ImageInput from "./ImageInput";
import _times from "lodash/times";
import { ADD_NEW_DAMAGE } from "../queries";
import { directUploadFile } from "../../../../utils/FileUploads";
import { useApolloClient, useMutation } from "@apollo/client";

const CreateDamage = ({ open, toggleCreate, vehicleId, refetch }) => {
  const damageInputsInitialStates = {
    status: null,
    location: null,
    severity: null,
    type: null,
    description: null,
    damageTags: [],
  };

  const [damageInputs, setDamageInputs] = useState(damageInputsInitialStates);
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [imgCount, setImgCount] = useState(0);
  const [fileBlobs, setFileBlobs] = useState([]);

  const triggerStatusChange = (child) => {
    setDamageInputs({ ...damageInputs, status: child });
  };

  const triggerLocationChange = (child) => {
    setDamageInputs({ ...damageInputs, location: child });
  };
  const triggerTypeChange = (child) => {
    setDamageInputs({ ...damageInputs, type: child });
  };
  const triggerSeverityChange = (child) => {
    setDamageInputs({ ...damageInputs, severity: child });
  };
  const triggerDescriptionChange = (e) => {
    setDamageInputs({ ...damageInputs, description: e.target.value });
  };

  const triggerAddTag = (child) => {
    setDamageInputs({
      ...damageInputs,
      damageTags: [...damageInputs.damageTags, child],
    });
  };

  const triggerRemoveTag = (child) => {
    setDamageInputs({
      ...damageInputs,
      damageTags: damageInputs.damageTags.filter((tag) => tag !== child),
    });
  };

  const clearInput = () => {
    setDamageInputs(damageInputsInitialStates);
    setFiles([]);
    setFilePreviews([]);
    setImgCount(0);
  };
  const client = useApolloClient();

  useEffect(() => {
    if (files.length > 0) {
      let tempBlobs = [];
      files.map((file) => {
        directUploadFile(file, client).then((result) => {
          tempBlobs.push(result);
        });
      });
      setFileBlobs(tempBlobs);
    }
    if (files.length == 0) {
      setFileBlobs([]);
    }
  }, [files.length]);

  const [newDamageMutation, { loading, error }] = useMutation(ADD_NEW_DAMAGE);
  const handleCreate = () => {
    {
      newDamageMutation({
        variables: {
          addDamageParams: {
            vehicleId: vehicleId,
            status: damageInputs.status,
            location: damageInputs.location,
            damageType: damageInputs.type,
            severity: damageInputs.severity,
            description: damageInputs.description,
            origin: "Fleet Agent",
          },
          tagsToAdd: damageInputs.damageTags,
          images: fileBlobs,
        },
      }).then(({ data: { addDamage } }) => {
        if (addDamage.success) {
          refetch();
          window.location.reload();
          toggleCreate();
          console.log("Create Damage Succeed!");
        } else {
          console.log("Create damage error!");
        }
      });
    }
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.damageModalContainer}>
        <div className={styles.title}>Add damage</div>
        <DropdownInput
          text={"Status"}
          value={damageInputs.status}
          options={getDropdownOptions("status")}
          triggerFieldChange={triggerStatusChange}
        />
        <DropdownInput
          text={"Location"}
          value={damageInputs.location}
          options={getDropdownOptions("location")}
          triggerFieldChange={triggerLocationChange}
        />
        <DropdownInput
          text={"Type"}
          value={damageInputs.type}
          options={getDropdownOptions("type")}
          triggerFieldChange={triggerTypeChange}
        />
        <DropdownInput
          text={"Severity"}
          value={damageInputs.severity}
          options={getDropdownOptions("severity")}
          triggerFieldChange={triggerSeverityChange}
        />
        <TextInput
          text={"Description"}
          triggerFieldChange={triggerDescriptionChange}
        />
        <TagInput
          tags={damageInputs.damageTags}
          triggerAddTag={triggerAddTag}
          triggerRemoveTag={triggerRemoveTag}
        />
        <div>
          <div className={styles.subscript} style={{ marginBottom: "8px" }}>
            {"Images"}
          </div>
          <div className={styles.imageField}>
            {_times(imgCount + 1, (index) => (
              <ImageInput
                index={index}
                file={files[index]}
                files={files}
                filePreviews={filePreviews}
                setFiles={setFiles}
                setFilePreviews={setFilePreviews}
                imgCount={imgCount}
                setImgCount={setImgCount}
              />
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              clearInput();
              toggleCreate();
            }}
          >
            Cancel
          </button>
          <button className={styles.applyButton} onClick={handleCreate}>
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateDamage;
