import React, { useState, useEffect, useRef } from "react";
import SignOutMutation from "../../Mutations/SignOutMutation";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import settingsIcon from "../../images/NavBar/Settings.png";

import "./layout.css";

const UserNav = ({ name, imageLink }) => {
  const [open, setOpen] = useState(false);
  const myRef = useRef();

  const toggle = () => (open ? setOpen(false) : setOpen(true));

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div ref={myRef} onClick={() => toggle()}>
      <div className="user-nav">
        <img src={imageLink} alt="" className="user-image" />
        {
          // <p className="navbar-link">{name}</p>
          // <FontAwesomeIcon className="white" icon={faAngleDown} />
        }
        <div>
          <img
            color="#FFFFFF"
            src={settingsIcon}
            style={{ opacity: "0.5" }}
            width="21.53px"
            height="21.53px"
          />
        </div>
      </div>
      <SignOutMutation open={open} />
    </div>
  );
};
export default UserNav;
