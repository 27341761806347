import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import styles from "./changesTable.module.scss";
import classNames from "classnames";

const ChangeModifications = ({
  startEdit,
  confirmEdit,
  resetEdit,
  deleteRow,
  isEditing
}) => {
  return (
    <div className={styles.actions}>
      <div className={styles.actionGroup}>
        {isEditing ? (
          <>
            <FontAwesomeIcon
              onClick={resetEdit}
              className={classNames(styles.blue, styles.smallMarginRight)}
              icon={faRedoAlt}
            />
            <FontAwesomeIcon
              onClick={confirmEdit}
              className={classNames(styles.blue, styles.check)}
              icon={faCheck}
            />
          </>
        ) : (
          <FontAwesomeIcon
            className={styles.blue}
            onClick={startEdit}
            icon={faPen}
          />
        )}
      </div>
      <div className={classNames(styles.actionGroup, styles.marginLeft)}>
        <FontAwesomeIcon icon={faTimes} onClick={deleteRow} />
      </div>
    </div>
  );
};

export default ChangeModifications;
