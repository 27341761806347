import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import {
  faCommentAltLines,
  faTag,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import ActionButton from "../ActionButton";
import _ from "lodash";
import TaskActionModal from "./TaskActionModal";

const TaskBatchActions = ({
  isOpen,
  selectedRows,
  clearAllSelections,
  tabs,
  refetch,
  removeSelection,
}) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState();

  const allIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "assignTo",
      text: "Assign to",
      icon: faPen,
    },
    {
      value: "changeStatus",
      text: "Change task status",
      icon: faCircle,
    },
  ];

  const resolvedIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },

    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "changeStatus",
      text: "Change task status",
      icon: faCircle,
    },
  ];

  const deletedIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "changeStatus",
      text: "Change task status",
      icon: faCircle,
    },
  ];

  const icons =
    tabs.currentTab === "deleted" || tabs.currentTab === "resolved"
      ? resolvedIcon
      : allIcon;

  const selectedTasks = Object.keys(_.pickBy(selectedRows));

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const closeModal = () => setSelectedBatchAction(null);

  return (
    <>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {icons.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                onClick={() => setSelectedBatchAction(icon.value)}
              />
            ))}
          </div>
          <TaskActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selectedTasks={selectedTasks}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            clearAllSelections={clearAllSelections}
            removeSelection={removeSelection}
          />
        </div>
      )}
    </>
  );
};

export default TaskBatchActions;
