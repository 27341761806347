import React from "react";
import { TableCell } from "../../artzu/src/Table";
import styles from "./parkingSpot.module.scss";
import { getBackColor, getColor, getText, getTimeInCorrectUnit } from "./utils";
import moment from "moment-timezone";

const TimelineFieldCellValue = ({ text, value, link }) => {
  const valueFormatter = () => {
    if (text === "Status") {
      return (
        <div
          className={styles.statusTag}
          style={{
            color: `${getColor(value)}`,
            backgroundColor: `${getBackColor(value)}`,
          }}
        >
          {getText(value)}
        </div>
      );
    } else if (text === "Time stamp") {
      return <div>{moment(value).format("hh:mm A MMM D, YYYY")}</div>;
    } else if (text === "By") {
      return link ? (
        <div>
          Driver:{" "}
          <a href={link} className={styles.byLink} target="_blank">
            {value}
          </a>
        </div>
      ) : (
        <div>{value}</div>
      );
    } else if (text === "License plate") {
      return link ? (
        <a href={link} className={styles.byLink} target="_blank">
          {value}
        </a>
      ) : (
        <div>{value}</div>
      );
    } else if (text === "Related booking") {
      return value ? (
        <a href={link} className={styles.byLink} target="_blank">
          {value}
        </a>
      ) : (
        <div className={styles.notApplicable}>N/A</div>
      );
    }
  };
  return (
    <TableCell width={"20%"}>
      <div className={styles.basicField}>{valueFormatter()}</div>
    </TableCell>
  );
};

export default TimelineFieldCellValue;
