import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const topRoof = [
	0.3033,
	0.3895,
	0.3591,
	0.3895,
	0.4191,
	0.3878,
	0.4898,
	0.3828,
	0.5402,
	0.3794,
	0.5648,
	0.376,
	0.5788,
	0.3743,
	0.5852,
	0.4131,
	0.5884,
	0.4586,
	0.5884,
	0.5008,
	0.5884,
	0.5413,
	0.5852,
	0.5817,
	0.582,
	0.6104,
	0.5798,
	0.6256,
	0.5241,
	0.6171,
	0.4748,
	0.6138,
	0.4137,
	0.6104,
	0.3666,
	0.6104,
	0.3237,
	0.607,
	0.3033,
	0.6087,
	0.3023,
	0.5649,
	0.3012,
	0.5227,
	0.3012,
	0.4755,
	0.3012,
	0.4249
];

const topBonnet = [
	0.6731,
	0.3676,
	0.6785,
	0.3625,
	0.6838,
	0.3592,
	0.6956,
	0.3608,
	0.717,
	0.3642,
	0.7503,
	0.371,
	0.7631,
	0.4013,
	0.7663,
	0.4081,
	0.7706,
	0.4131,
	0.7728,
	0.4148,
	0.776,
	0.462,
	0.7771,
	0.5008,
	0.776,
	0.5345,
	0.7749,
	0.548,
	0.7738,
	0.5733,
	0.7728,
	0.58,
	0.7717,
	0.5834,
	0.7696,
	0.5851,
	0.7663,
	0.5902,
	0.7588,
	0.6087,
	0.7513,
	0.6256,
	0.7438,
	0.6273,
	0.7278,
	0.6306,
	0.7053,
	0.634,
	0.6881,
	0.6357,
	0.6827,
	0.6357,
	0.6785,
	0.634,
	0.6731,
	0.6289,
	0.6785,
	0.6036,
	0.6849,
	0.5632,
	0.687,
	0.5362,
	0.6881,
	0.4991,
	0.687,
	0.462,
	0.6849,
	0.4266,
	0.6795,
	0.3946
];

const frontWindshield = [
	0.5798,
	0.3726,
	0.5831,
	0.3912,
	0.5863,
	0.4283,
	0.5884,
	0.4654,
	0.5884,
	0.5025,
	0.5884,
	0.5295,
	0.5863,
	0.5682,
	0.5831,
	0.6003,
	0.5788,
	0.6239,
	0.6024,
	0.6289,
	0.6313,
	0.6357,
	0.6559,
	0.6424,
	0.6602,
	0.6424,
	0.6635,
	0.6407,
	0.6699,
	0.634,
	0.6731,
	0.6289,
	0.6817,
	0.5902,
	0.686,
	0.5379,
	0.687,
	0.4856,
	0.6849,
	0.435,
	0.6795,
	0.3979,
	0.6763,
	0.3794,
	0.6731,
	0.3659,
	0.6677,
	0.3592,
	0.6635,
	0.3558,
	0.6452,
	0.3575
];

const rearWindshield = [
	0.2647,
	0.3794,
	0.2733,
	0.3844,
	0.2872,
	0.3878,
	0.2851,
	0.4603,
	0.2851,
	0.5362,
	0.2862,
	0.5767,
	0.2883,
	0.6087,
	0.2787,
	0.6121,
	0.2712,
	0.6154,
	0.2647,
	0.6171,
	0.2615,
	0.5902,
	0.2594,
	0.5598,
	0.2572,
	0.5261,
	0.2572,
	0.4873,
	0.2594,
	0.4418,
	0.2615,
	0.4081
];

const topTrunk = [
	0.3033,
	0.3895,
	0.3033,
	0.607,
	0.2937,
	0.6087,
	0.284,
	0.6121,
	0.2744,
	0.6154,
	0.269,
	0.6188,
	0.2647,
	0.6205,
	0.2605,
	0.6222,
	0.2562,
	0.6239,
	0.2551,
	0.6222,
	0.2529,
	0.602,
	0.2497,
	0.5665,
	0.2476,
	0.5261,
	0.2476,
	0.4873,
	0.2487,
	0.4536,
	0.2508,
	0.4165,
	0.2529,
	0.3946,
	0.2551,
	0.3811,
	0.2551,
	0.3777,
	0.2562,
	0.3743,
	0.268,
	0.3794,
	0.2808,
	0.3844,
	0.2915,
	0.3878
];

const topPassengerSideMirror = [
	0.6217,
	0.6424,
	0.6313,
	0.6458,
	0.6334,
	0.6492,
	0.6334,
	0.6509,
	0.6313,
	0.6559,
	0.6313,
	0.661,
	0.6313,
	0.6643,
	0.627,
	0.6795,
	0.6238,
	0.6863,
	0.6227,
	0.688,
	0.6152,
	0.693,
	0.6131,
	0.693,
	0.6195,
	0.6677,
	0.6217,
	0.6559,
	0.6227,
	0.6525
];

const topDriverSideMirror = getMirrorImage(topPassengerSideMirror);

const topDriverFrontWindow = [
	0.5295,
	0.3406,
	0.5263,
	0.3676,
	0.5552,
	0.3659,
	0.5702,
	0.3642,
	0.5809,
	0.3625,
	0.5981,
	0.3592,
	0.6141,
	0.3541,
	0.627,
	0.3524,
	0.6409,
	0.3507,
	0.6592,
	0.3473
];

const topPassengerFrontWindow = getMirrorImage(topDriverFrontWindow);

const topDriverCenterWindow = [
	0.4126,
	0.344,
	0.4116,
	0.3676,
	0.5091,
	0.3642,
	0.5113,
	0.3406
];

const topPassengerCenterWindow = getMirrorImage(topDriverCenterWindow);

const topDriverRearWindow = [
	0.3944,
	0.344,
	0.3966,
	0.371,
	0.3548,
	0.371,
	0.3237,
	0.371,
	0.3119,
	0.3693,
	0.3055,
	0.3676,
	0.3012,
	0.3659,
	0.2915,
	0.3541
];

const topPassengerRearWindow = getMirrorImage(topDriverRearWindow);

const topDriverRearQuarter = [
	0.3976,
	0.3878,
	0.3698,
	0.3878,
	0.3408,
	0.3895,
	0.3173,
	0.3895,
	0.3033,
	0.3895,
	0.2958,
	0.3878,
	0.2851,
	0.3861,
	0.2776,
	0.3828,
	0.2701,
	0.3794,
	0.2637,
	0.376,
	0.2615,
	0.3743,
	0.2626,
	0.3693,
	0.2637,
	0.3659,
	0.2669,
	0.3608,
	0.2712,
	0.3575,
	0.2765,
	0.3541,
	0.2808,
	0.3507,
	0.2862,
	0.3473,
	0.298,
	0.3457,
	0.3076,
	0.3406,
	0.3151,
	0.3355,
	0.3301,
	0.3339,
	0.3548,
	0.3322,
	0.3719,
	0.3322,
	0.3826,
	0.3322,
	0.3901,
	0.3322,
	0.3944,
	0.3339
];

const topPassengerRearQuarter = getMirrorImage(topDriverRearQuarter);

const topDriverFrontDoor = [
	0.5177,
	0.3828,
	0.537,
	0.3811,
	0.5584,
	0.3777,
	0.5723,
	0.376,
	0.5949,
	0.371,
	0.6174,
	0.3659,
	0.6324,
	0.3625,
	0.6345,
	0.3625,
	0.6356,
	0.3473,
	0.6367,
	0.3389,
	0.6141,
	0.3372,
	0.5895,
	0.3355,
	0.5702,
	0.3339,
	0.5531,
	0.3339,
	0.5498,
	0.3322,
	0.5391,
	0.3322,
	0.5316,
	0.3305,
	0.5284,
	0.3322,
	0.5252,
	0.3339,
	0.5209,
	0.3339
];

const topPassengerFrontDoor = getMirrorImage(topDriverFrontDoor);

const topDriverRearDoor = [
	0.5177,
	0.3828,
	0.5188,
	0.3592,
	0.5209,
	0.3322,
	0.5123,
	0.3322,
	0.5091,
	0.3322,
	0.4973,
	0.3322,
	0.4887,
	0.3322,
	0.4652,
	0.3339,
	0.4394,
	0.3339,
	0.4126,
	0.3372,
	0.3934,
	0.3355,
	0.3955,
	0.3524,
	0.3966,
	0.3743,
	0.3976,
	0.3895,
	0.4405,
	0.3861,
	0.4705,
	0.3861,
	0.4984,
	0.3844
];

const topPassengerRearDoor = getMirrorImage(topDriverRearDoor);

const topFrontWipers = [
	0.6613,
	0.6407,
	0.6656,
	0.6391,
	0.6731,
	0.6306,
	0.6785,
	0.607,
	0.6849,
	0.5598,
	0.6881,
	0.5008,
	0.686,
	0.4502,
	0.6817,
	0.4081,
	0.6763,
	0.3794,
	0.6731,
	0.3676,
	0.6699,
	0.3625,
	0.6656,
	0.3575,
	0.6602,
	0.3558,
	0.657,
	0.3558,
	0.6549,
	0.3558,
	0.6602,
	0.376,
	0.6635,
	0.403,
	0.6667,
	0.4266,
	0.6677,
	0.4553,
	0.6677,
	0.4772,
	0.6677,
	0.5025,
	0.6667,
	0.5446,
	0.6667,
	0.5699,
	0.6635,
	0.5969,
	0.6602,
	0.6188,
	0.657,
	0.6273,
	0.6527,
	0.6407
];

const topRearWipers = [
	0.2647,
	0.3794,
	0.2615,
	0.4114,
	0.2594,
	0.4367,
	0.2583,
	0.4586,
	0.2583,
	0.4839,
	0.2583,
	0.5058,
	0.2583,
	0.5328,
	0.2594,
	0.5547,
	0.2615,
	0.575,
	0.2626,
	0.5969,
	0.2658,
	0.6154,
	0.268,
	0.6138,
	0.2658,
	0.5935,
	0.2637,
	0.5733,
	0.2626,
	0.5429,
	0.2615,
	0.5193,
	0.2615,
	0.4789,
	0.2615,
	0.4435,
	0.2637,
	0.4165,
	0.2658,
	0.3962,
	0.268,
	0.3794
];

const topDriverFrontQuarter = [
	0.6731,
	0.3659,
	0.6785,
	0.3625,
	0.6849,
	0.3592,
	0.6999,
	0.3608,
	0.717,
	0.3642,
	0.7503,
	0.371,
	0.7406,
	0.3507,
	0.7342,
	0.344,
	0.731,
	0.3406,
	0.7235,
	0.3372,
	0.7106,
	0.3355,
	0.6902,
	0.3339,
	0.671,
	0.3322,
	0.6624,
	0.3322,
	0.6517,
	0.3339,
	0.6442,
	0.3355,
	0.6409,
	0.3372,
	0.6388,
	0.3372,
	0.6367,
	0.3372,
	0.6345,
	0.3457,
	0.6581,
	0.3457,
	0.6345,
	0.3507,
	0.6367,
	0.3592,
	0.6602,
	0.3558,
	0.6667,
	0.3575
];

const topPassengerFrontQuarter = getMirrorImage(topDriverFrontQuarter);

const topFrontBumper = [
	0.7717,
	0.4148,
	0.7685,
	0.4097,
	0.7631,
	0.4013,
	0.7567,
	0.3861,
	0.7492,
	0.3693,
	0.7406,
	0.3507,
	0.746,
	0.3524,
	0.7546,
	0.3541,
	0.7631,
	0.3592,
	0.7696,
	0.3659,
	0.7749,
	0.3777,
	0.7803,
	0.3962,
	0.7856,
	0.4232,
	0.7889,
	0.4569,
	0.791,
	0.4856,
	0.791,
	0.5143,
	0.7889,
	0.5396,
	0.7856,
	0.5682,
	0.7835,
	0.5834,
	0.7792,
	0.6036,
	0.7749,
	0.6205,
	0.7685,
	0.6306,
	0.7642,
	0.6357,
	0.7588,
	0.6391,
	0.7503,
	0.6424,
	0.7417,
	0.6441,
	0.7449,
	0.634,
	0.7503,
	0.6256,
	0.7567,
	0.6104,
	0.7642,
	0.5918,
	0.7685,
	0.5834,
	0.7728,
	0.58,
	0.7749,
	0.5446,
	0.776,
	0.5177,
	0.776,
	0.494,
	0.776,
	0.4671,
	0.7749,
	0.4468,
	0.7728,
	0.4283
];

const topRearBumper = [
	0.2787,
	0.3493,
	0.2744,
	0.3524,
	0.268,
	0.3575,
	0.2637,
	0.3608,
	0.2615,
	0.3659,
	0.2605,
	0.3726,
	0.2605,
	0.3743,
	0.2572,
	0.3726,
	0.2551,
	0.3726,
	0.254,
	0.3777,
	0.2519,
	0.3962,
	0.2508,
	0.4114,
	0.2487,
	0.4333,
	0.2487,
	0.4519,
	0.2476,
	0.4721,
	0.2476,
	0.494,
	0.2476,
	0.5177,
	0.2487,
	0.5429,
	0.2497,
	0.5632,
	0.2508,
	0.5851,
	0.2529,
	0.6036,
	0.254,
	0.6171,
	0.2551,
	0.6205,
	0.2562,
	0.6205,
	0.2605,
	0.6188,
	0.2605,
	0.6256,
	0.2626,
	0.6306,
	0.2669,
	0.6357,
	0.2722,
	0.6391,
	0.2765,
	0.6424,
	0.2797,
	0.6441,
	0.2776,
	0.6475,
	0.2722,
	0.6458,
	0.2658,
	0.6441,
	0.2572,
	0.6407,
	0.2529,
	0.6374,
	0.2497,
	0.6357,
	0.2465,
	0.6289,
	0.2444,
	0.6222,
	0.2422,
	0.6104,
	0.2401,
	0.5935,
	0.2379,
	0.575,
	0.2369,
	0.5547,
	0.2358,
	0.5345,
	0.2358,
	0.516,
	0.2358,
	0.4856,
	0.2369,
	0.4553,
	0.2379,
	0.4283,
	0.2401,
	0.4064,
	0.2422,
	0.3895,
	0.2444,
	0.376,
	0.2465,
	0.3676,
	0.2497,
	0.3608,
	0.2583,
	0.3541,
	0.2722,
	0.3493
];

export {
	topRoof,
	topBonnet,
	frontWindshield,
	rearWindshield,
	topTrunk,
	topPassengerSideMirror,
	topDriverSideMirror,
	topDriverFrontWindow,
	topPassengerFrontWindow,
	topDriverCenterWindow,
	topPassengerCenterWindow,
	topDriverRearWindow,
	topPassengerRearWindow,
	topDriverRearQuarter,
	topPassengerRearQuarter,
	topDriverFrontDoor,
	topPassengerFrontDoor,
	topDriverRearDoor,
	topPassengerRearDoor,
	topFrontWipers,
	topRearWipers,
	topDriverFrontQuarter,
	topPassengerFrontQuarter,
	topFrontBumper,
	topRearBumper
};
