import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import VehicleNotes from "../Components/Vehicle/VehicleNotes";

import { noteString } from "./queryStrings";

class GetNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newNote: "",
      editNote: null
    };
  }

  onChange = newNote => this.setState({ newNote });
  editChange = text => this.setState({ editNote: text });

  render() {
    const { vehicleId, mapView, addNote, closeNote, currentUser } = this.props;
    const { newNote, editNote } = this.state;

    return (
      <Query
        query={gql`
          ${noteString}
        `}
        variables={{ vehicleId }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading || error) {
            return null;
          }
          if (data.getVehicle) {
            const userSuggestions =
              data.getEveryUser &&
              data.getEveryUser.map(user => {
                return user.fullName;
              });

            const userEmails =
              data.getEveryUser &&
              data.getEveryUser.map(user => {
                return user.email;
              });
            return (
              <VehicleNotes
                notes={data.getVehicle.notes}
                newNote={newNote}
                onChange={this.onChange}
                refetch={refetch}
                vehicleId={vehicleId}
                addNote={addNote}
                closeNote={closeNote}
                map={mapView}
                currentUser={currentUser}
                suggestions={userSuggestions}
                userEmails={userEmails}
                editChange={this.editChange}
                editedNote={editNote}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default GetNotes;
