import React from "react";

import { m, capitalize } from "../../../utils";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SaveChanges from "../SaveChanges";
import VehicleInspectionInput from "./VehicleInspectionInput";

import "./../service.css";
import "../../Fleet/fleet.css";

const InspectionModal = ({
  action,
  inspectionData,
  onChange,
  fleetManager,
  errors,
  relevantState,
  vehicleId,
  refetch,
  clear,
  addErrors,
  prevOdometerReading
}) => (
  <div className="modal inspection-modal">
    <div className="modal-top">
      <h2 className="inspection-title">
        {capitalize(action)} Vehicle Inspection
      </h2>
      <FontAwesomeIcon
        className="blue"
        icon={faTimes}
        size="2x"
        onClick={() => clear()}
      />
    </div>
    <VehicleInspectionInput
      data={inspectionData}
      onEdit={onChange}
      fleetManager={fleetManager}
      vehicleId={vehicleId}
    />
    {errors.map((error, index) => (
      <div key={index} className="inspection-error">
        {" "}
        {error}{" "}
      </div>
    ))}
    <SaveChanges
      action={action}
      params={{
        ...relevantState,
        name: "inspection",
        complete: true,
        completedDate: relevantState.completedDate || m(),
        vehicleId
      }}
      refetch={refetch}
      clear={clear}
      addErrors={addErrors}
      prevOdometerReading={prevOdometerReading}
    />
  </div>
);

export default InspectionModal;
