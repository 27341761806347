import React, { Fragment, useState } from "react";
import styles from "../../../task.module.scss";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../../../../InformationTable/Shared/ToggleButton";
import VehicleActionOptions from "./VehicleActionOptions";

const VehicleActions = ({ taskData, refreshTaskCallback, toggleEdit }) => {
  const [isOpen, setIsOpen] = useState();
  const [curAction, setCurAction] = useState();

  const toggleOpenState = () => setIsOpen(!isOpen);

  return (
    <Fragment>
      <div className={styles.vehicleActionButtonsContainer}>
        <ToggleButton
          isOpen={isOpen}
          toggleOpenState={toggleOpenState}
          faIcon={faEllipsisH}
          taskData={taskData}
          ticketSpecific={true}
        />
        <VehicleActionOptions
          isOpen={isOpen}
          toggleOpenState={toggleOpenState}
          curAction={curAction}
          taskData={taskData}
          setCurAction={setCurAction}
          vehicleId={taskData?.vehicle.id}
          refreshTaskCallback={refreshTaskCallback}
          toggleEdit={toggleEdit}
        />
      </div>
    </Fragment>
  );
};

export default VehicleActions;
