import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import styles from "./actionButtons.module.scss";
import classNames from "classnames";

import gql from "graphql-tag";
import { historyGpsString } from "../../../Queries/queryStrings";
import { useApolloClient } from "@apollo/client";

class ActionChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "ready"
    };
  }

  REMOTE_SERVICES = gql`
    mutation remoteServices($vehicleId: ID!, $serviceType: String!) {
      remoteServices(
        params: { vehicleId: $vehicleId, serviceType: $serviceType }
      ) {
        command {
          id
          name
          status
        }
        errors
      }
    }
  `;

  getColour = () => {
    const { status } = this.state;
    switch (status) {
      case "pending":
      case "ready":
      case "delivered":
      case "executed":
        return "blue";
      case "failed":
        return "red";
    }
  };

  sendCommand = () => {
    const { client, vehicleId, action } = this.props;
    client
      .mutate({
        mutation: this.REMOTE_SERVICES,
        variables: {
          vehicleId: vehicleId,
          serviceType: action
        }
      })
      .then(({ data }) => {
        if (data.remoteServices.command) {
          const status = data.remoteServices.command.status;
          const commandId = data.remoteServices.command.id;

          this.setState({ status });
          if (status != "failed") {
            this.updateStatus(commandId);
          }
        } else {
          this.setState({ status: "failed" });
        }
      });
  };

  async statusQuery(commandId) {
    const { client } = this.props;

    const results = await client.query({
      query: gql`
        query commandStatus($commandId: ID!) {
          checkBmwCommandStatus(id: $commandId)
        }
      `,
      variables: {
        commandId
      },
      fetchPolicy: "network-only"
    });

    const status = results.data.checkBmwCommandStatus;
    return status;
  }

  async updateStatus(commandId) {
    const status = await this.statusQuery(commandId);
    setTimeout(() => {
      if (status === "executed" || status === "failed") {
        this.setState({ status });
        this.clearStatus();
      } else {
        this.updateStatus(commandId);
      }
    }, 1000);
  }

  clearStatus = () => {
    setTimeout(() => {
      this.setState({ status: "ready" });
    }, 5000);
  };

  handleClick = () => {
    const { status } = this.state;
    if (status == "ready") {
      this.setState({ status: "pending" });
      this.sendCommand();
    }
  };

  render() {
    const { icon } = this.props;
    const { status } = this.state;
    return (
      <div
        className={classNames(styles.button, styles[this.getColour()])}
        onClick={this.handleClick}
      >
        {(() => {
          switch (status) {
            case "ready":
              return <FontAwesomeIcon icon={icon} />;
            case "pending":
            case "delivered":
              return <FontAwesomeIcon icon={faSpinner} spin />;
            case "executed":
              return (
                <Fragment>
                  <FontAwesomeIcon icon={faCheck} />
                  <div className={styles.tiny}> Success </div>
                </Fragment>
              );
            case "failed":
              return (
                <Fragment>
                  <FontAwesomeIcon icon={faTimes} />
                  <div className={styles.tiny}> Failed </div>
                </Fragment>
              );
          }
        })()}
      </div>
    );
  }
}

const Action = props => {
  const client = useApolloClient();
  return <ActionChild client={client} {...props} />;
};

export default Action;
