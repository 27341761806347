import React from "react";
import styles from "./navBar.module.scss";

const NavBar = ({ navbar }) => {
  return (
    <div className={styles.navHeader}>
      <div className={styles.nav}> {navbar}</div>
    </div>
  );
};

export default NavBar;
