import React from "react";
import EditNoteModal from "./EditNoteModal";
import Button from "../../../../../artzu/src/Button";
import styles from "./notes.module.scss";
import { EDIT_DAMAGE_NOTE } from "./queries";
import { useMutation } from "@apollo/client";

const EditNoteModalPanel = ({
  editNote,
  editedNote,
  selectedNote,
  editChange,
  refetch,
  toggleEditNote,
  resetNoteState,
}) => {
  const noteValue =
    editedNote || editedNote === ""
      ? editedNote
      : selectedNote
      ? selectedNote.details
      : null;

  const [editNoteMutation, { loading, error }] = useMutation(EDIT_DAMAGE_NOTE);

  const handleEditNote = () => {
    editNoteMutation({
      variables: {
        params: {
          userId: selectedNote && selectedNote.userId,
          details: editedNote
            ? editedNote
            : selectedNote && selectedNote.details,
          noteId: selectedNote && selectedNote.id,
        },
      },
    }).then(({ data: { editDamageNote } }) => {
      if (editDamageNote.success) {
        refetch();
        editChange("");
        resetNoteState();
        toggleEditNote();
        console.log("Edit Note Succeed!");
      } else {
        console.log("Edit note error!");
      }
    });
  };

  return (
    <EditNoteModal
      open={editNote}
      closeModal={toggleEditNote}
      title="Edit Note"
    >
      <textarea
        className={styles.editTextboxInput}
        placeholder="Enter note here..."
        value={noteValue}
        rows="4"
        onChange={(evt) => {
          editChange(evt.target.value);
        }}
      />
      <div className={styles.editNoteButtons}>
        <div className={styles.cancelNote}>
          <Button
            label="Cancel"
            fullWidth="154px"
            height="39px"
            color="#414141"
            border="none"
            onClick={() => {
              toggleEditNote();
            }}
            weight="bold"
            size="17px"
          />
        </div>
        <div className={styles.editNote}>
          <Button
            label="Save"
            fullWidth="154px"
            height="39px"
            color="#128DFF"
            border="none"
            onClick={() => {
              if (editedNote) {
                handleEditNote();
              } else {
                toggleEditNote();
              }
            }}
            weight="bold"
            size="17px"
          ></Button>
        </div>
      </div>
    </EditNoteModal>
  );
};

export default EditNoteModalPanel;
