const frontPassengerWindow = [
	0.4737,
	0.7305,
	0.4892,
	0.8055,
	0.567,
	0.812,
	0.5659,
	0.8104,
	0.5649,
	0.8055,
	0.5638,
	0.8022,
	0.5638,
	0.7957,
	0.5649,
	0.7908,
	0.5659,
	0.7859,
	0.568,
	0.7827,
	0.5711,
	0.781,
	0.5576,
	0.768,
	0.5431,
	0.7533,
	0.5328,
	0.7452,
	0.5193,
	0.7387,
	0.5089,
	0.7338,
	0.4996,
	0.7305,
	0.4861,
	0.7289,
	0.4768,
	0.7272,
	0.4737,
	0.7272
];

const rearPassengerWindow = [
	0.3483,
	0.7974,
	0.4654,
	0.8039,
	0.4561,
	0.7289,
	0.4457,
	0.7289,
	0.4333,
	0.7289,
	0.4218,
	0.7289,
	0.4115,
	0.7321,
	0.399,
	0.7354,
	0.3845,
	0.7403,
	0.3762,
	0.7435,
	0.3628,
	0.7517,
	0.3524,
	0.7615,
	0.3472,
	0.7664,
	0.3431,
	0.7713,
	0.341,
	0.7762
];

const frontPassengerDoor = [
	0.4851,
	0.9392,
	0.6198,
	0.9441,
	0.624,
	0.8936,
	0.6229,
	0.8577,
	0.6209,
	0.8398,
	0.6177,
	0.8267,
	0.6084,
	0.8137,
	0.5949,
	0.799,
	0.5804,
	0.7794,
	0.5597,
	0.7615,
	0.5452,
	0.7468,
	0.5276,
	0.7338,
	0.5131,
	0.7272,
	0.4934,
	0.724,
	0.482,
	0.724,
	0.4706,
	0.724,
	0.4654,
	0.7256,
	0.4789,
	0.8022,
	0.483,
	0.8251,
	0.484,
	0.8349
];

const rearPassengerDoor = [
	0.484,
	0.9392,
	0.4861,
	0.8463,
	0.484,
	0.83,
	0.482,
	0.8169,
	0.4789,
	0.8022,
	0.4633,
	0.7207,
	0.4498,
	0.7207,
	0.4384,
	0.7207,
	0.4291,
	0.7223,
	0.4177,
	0.724,
	0.4073,
	0.7256,
	0.3949,
	0.7289,
	0.3783,
	0.737,
	0.3586,
	0.7517,
	0.3472,
	0.7615,
	0.341,
	0.768,
	0.3379,
	0.7729,
	0.3379,
	0.7827,
	0.341,
	0.7925,
	0.4032,
	0.9311
];

const passengerBottomTrim = [
	0.3897,
	0.9294,
	0.3897,
	0.9653,
	0.654,
	0.9686,
	0.6519,
	0.9441
];

const rearPassengerQuarterPanel = [
	0.4032,
	0.9294,
	0.3566,
	0.8251,
	0.3441,
	0.799,
	0.34,
	0.7876,
	0.3369,
	0.781,
	0.3369,
	0.7696,
	0.3431,
	0.7615,
	0.3566,
	0.7501,
	0.3876,
	0.7321,
	0.4364,
	0.7223,
	0.4664,
	0.7223,
	0.4985,
	0.724,
	0.5265,
	0.7354,
	0.5804,
	0.7778,
	0.5981,
	0.8006,
	0.6012,
	0.7941,
	0.5794,
	0.7729,
	0.5504,
	0.7435,
	0.5255,
	0.7272,
	0.4985,
	0.7191,
	0.4768,
	0.7158,
	0.4592,
	0.7158,
	0.4436,
	0.7158,
	0.4281,
	0.7158,
	0.4125,
	0.7191,
	0.3959,
	0.7223,
	0.3783,
	0.7289,
	0.3586,
	0.7387,
	0.3462,
	0.7468,
	0.3317,
	0.7566,
	0.3203,
	0.7647,
	0.3068,
	0.7762,
	0.2964,
	0.781,
	0.2819,
	0.7843,
	0.2674,
	0.7876,
	0.2488,
	0.7908,
	0.2374,
	0.7925,
	0.2311,
	0.7941,
	0.227,
	0.8006,
	0.228,
	0.8039,
	0.2291,
	0.8234,
	0.2353,
	0.8153,
	0.2374,
	0.8104,
	0.2374,
	0.8055,
	0.2456,
	0.8055,
	0.2529,
	0.8071,
	0.2612,
	0.8137,
	0.2653,
	0.8202,
	0.2643,
	0.8218,
	0.2591,
	0.83,
	0.2539,
	0.8365,
	0.2477,
	0.8414,
	0.2425,
	0.8446,
	0.3151,
	0.8707,
	0.3317,
	0.8609,
	0.3462,
	0.8593,
	0.3576,
	0.8642,
	0.3659,
	0.8707,
	0.3773,
	0.8854,
	0.3856,
	0.9082,
	0.3876,
	0.918,
	0.3887,
	0.9278
];

const frontPassengerQuarterPanel = [
	0.6188,
	0.9408,
	0.6229,
	0.8887,
	0.624,
	0.8577,
	0.6209,
	0.8381,
	0.6177,
	0.8267,
	0.6115,
	0.8169,
	0.5981,
	0.8006,
	0.6032,
	0.7957,
	0.6105,
	0.8006,
	0.6157,
	0.8039,
	0.626,
	0.8022,
	0.6499,
	0.8022,
	0.6665,
	0.8039,
	0.6862,
	0.8071,
	0.7027,
	0.8104,
	0.7255,
	0.8186,
	0.7442,
	0.8251,
	0.7618,
	0.8332,
	0.7743,
	0.8414,
	0.7846,
	0.8479,
	0.7784,
	0.8512,
	0.7774,
	0.8544,
	0.7722,
	0.8561,
	0.766,
	0.8528,
	0.7608,
	0.8544,
	0.7535,
	0.8512,
	0.7421,
	0.8577,
	0.7411,
	0.8577,
	0.7318,
	0.8609,
	0.7255,
	0.8675,
	0.7121,
	0.8577,
	0.6965,
	0.8561,
	0.6748,
	0.8658,
	0.6613,
	0.8919,
	0.6561,
	0.9099,
	0.6519,
	0.9441
];

const frontPassengerBumper = [
	0.7245,
	0.8691,
	0.7318,
	0.8577,
	0.7556,
	0.8691,
	0.766,
	0.8789,
	0.7805,
	0.8854,
	0.7815,
	0.8658,
	0.7732,
	0.8593,
	0.7784,
	0.8512,
	0.7857,
	0.8495,
	0.7919,
	0.8561,
	0.796,
	0.8658,
	0.796,
	0.8903,
	0.7991,
	0.8968,
	0.7991,
	0.9033,
	0.7971,
	0.9115,
	0.7971,
	0.9506,
	0.795,
	0.9555,
	0.7898,
	0.9604,
	0.7525,
	0.9637,
	0.7504,
	0.9262,
	0.7463,
	0.9017,
	0.7369,
	0.8789
];

const rearPassengerBumper = [
	0.3141,
	0.8756,
	0.257,
	0.8463,
	0.2415,
	0.843,
	0.2322,
	0.8463,
	0.2301,
	0.8593,
	0.2249,
	0.8707,
	0.2208,
	0.8756,
	0.2187,
	0.8773,
	0.2177,
	0.9001,
	0.2218,
	0.9017,
	0.227,
	0.9115,
	0.2291,
	0.9196,
	0.2363,
	0.9376,
	0.2923,
	0.9555,
	0.2944,
	0.9245,
	0.3027,
	0.8903
];

const frontPassengerWheel = [0.702, 0.9341];

const rearPassengerWheel = [0.343, 0.9358];

const sidePassengerHeadlight = [
	0.74,
	0.8577,
	0.7515,
	0.8642,
	0.7556,
	0.8707,
	0.7629,
	0.8773,
	0.7743,
	0.8838,
	0.7774,
	0.8854,
	0.7805,
	0.8838,
	0.7805,
	0.8707,
	0.7815,
	0.8626,
	0.7711,
	0.8561,
	0.7618,
	0.8495,
	0.7556,
	0.8495
];

const sidePassengerTaillight = [
	0.2291,
	0.8479,
	0.2415,
	0.8446,
	0.2529,
	0.8365,
	0.2602,
	0.8267,
	0.2643,
	0.8202,
	0.2581,
	0.8104,
	0.2477,
	0.8088,
	0.2374,
	0.8071,
	0.2374,
	0.812,
	0.2291,
	0.8218,
	0.2291,
	0.8398,
	0.2301,
	0.8479
];

const passengerSideMirror = [
	0.568,
	0.8104,
	0.5638,
	0.8022,
	0.5638,
	0.7941,
	0.5649,
	0.7859,
	0.5721,
	0.781,
	0.5773,
	0.7827,
	0.5846,
	0.7892,
	0.5867,
	0.7974,
	0.5867,
	0.8039,
	0.5846,
	0.8088
];

export {
	frontPassengerWindow,
	rearPassengerWindow,
	frontPassengerDoor,
	rearPassengerDoor,
	passengerBottomTrim,
	rearPassengerQuarterPanel,
	frontPassengerQuarterPanel,
	frontPassengerBumper,
	rearPassengerBumper,
	frontPassengerWheel,
	rearPassengerWheel,
	sidePassengerHeadlight,
	sidePassengerTaillight,
	passengerSideMirror
};
