import React from "react";

const EditableLockboxCode = ({ isEdit, lockboxCode, handleCodeChange }) => {
  return isEdit ? (
    <div className="parking-input-box">
      <input
        className="parking-spot-input"
        type="text"
        value={lockboxCode}
        onChange={handleCodeChange}
      ></input>
    </div>
  ) : (
    <p className="tight-margin-content">{lockboxCode || "--"}</p>
  );
};

export default EditableLockboxCode;
