import React, { useState } from "react";
import styles from "../damages.module.scss";
import DetailLeft from "./DetailLeft";
import DetailRight from "./DetailRight";

const DetailsBody = ({ damage, refetch }) => {
  return (
    <div className={styles.detailsPage}>
      <DetailLeft damage={damage} refetch={refetch} />
      <DetailRight damage={damage} refetch={refetch} />
    </div>
  );
};

export default DetailsBody;
