import React, { Component } from "react";
import VehicleHeader from "./VehicleHeader";
import VehicleInfo from "./VehicleInfo";
import VehicleService from "./VehicleService";
import NavDisplay from "../Shareable/NavDisplay";
import { inject, observer } from "mobx-react";

import NavigationSidebar from "./NavigationSidebar";
import VehicleDocuments from "./VehicleDocuments";
import TicketDetails from "./UploadTickets/TicketDetails";
import DamageReport from "../DamageReport";
import ServiceEvents from "../ServiceEvents";
import VehicleObd from "./VehicleObd";
import { overallStatus, formatNavTitle, capitalize } from "../../utils";
import _ from "lodash";

import "./vehicle.scss";
import "../Fleet/fleet.css";
import { useApolloClient } from "@apollo/client";

const VehicleChild = observer(
  class Vehicle extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newNote: "",
        tab: "Details",
        mode: "upcoming",
        docTab: "",
      };
    }

    onChange = (text) => this.setState({ newNote: text });

    showLocation = (vehicle) => {
      this.props.store.changeSelectedVehicle(vehicle);
    };

    currentTab = (page) => {
      this.setState({ tab: page });
    };

    setDocTab = (type) => {
      this.setState({ docTab: type });
    };

    currentTabNav = (page) => {
      const { store, history, getVehicle } = this.props;
      const { docTab, tab } = this.state;

      if (page === docTab) {
        this.setDocTab(page);
      }

      if (page !== docTab) {
        this.setState({ tab: page });
        this.setDocTab("");
        store.changeNavigatedPage(
          formatNavTitle(
            getVehicle,
            history.location.pathname + `/${tab}`,
            this.currentTabNav
          )
        );
      }
    };

    serviceNames = () => {
      const { getVehicle } = this.props;
      let pastServiceEvents = getVehicle.pastServiceEvents;
      let upcomingEvents = getVehicle.upcomingEvents;
      let allEvents = pastServiceEvents.concat(upcomingEvents);
      let filteredEvents = _.uniqBy(allEvents, "name");
      let serviceNames = [];
      filteredEvents.forEach((event) => {
        serviceNames.push(event.name);
      });
      return serviceNames;
    };

    serviceVendors = () => {
      const { getVehicle } = this.props;
      let pastServiceEvents = getVehicle.pastServiceEvents;
      let upcomingEvents = getVehicle.upcomingEvents;
      let allEvents = pastServiceEvents.concat(upcomingEvents);
      let filteredEvents = _.uniqBy(allEvents, "vendor");
      let serviceVendors = [];
      filteredEvents.forEach((event) => {
        serviceVendors.push(event.vendor);
      });
      return serviceVendors;
    };

    changeMode = (mode) => this.setState({ mode });

    componentDidMount() {
      const { store, getVehicle, history } = this.props;
      store.changeNavigatedPage(
        formatNavTitle(
          getVehicle,
          history.location.pathname + `/${this.state.tab}`,
          this.currentTabNav
        )
      );
    }

    render() {
      const {
        getVehicle,
        store,
        history,
        prev,
        refetch,
        currentUser,
        availableUsers,
      } = this.props;
      const { tab, docTab } = this.state;
      const tabs = [
        { name: "Details" },
        { name: "Documents" },
        { name: "Incidents" },
        { name: "Inspection" },
        { name: "Service" },
        { name: "AVC" },
        { name: "Camera" },
        { name: "Tickets" },
      ];

      const userSuggestions = availableUsers.map((user) => {
        return user.fullName;
      });

      const userEmails = availableUsers.map((user) => {
        return user.email;
      });

      return (
        <div
          style={{
            maxWidth: "calc(100vw - 197px)",
            position: "fixed",
          }}
        >
          <div className="vehicle-redirects">
            <NavDisplay
              navPath={store.navigatedPage}
              history={history}
              tab={tab}
              vehicle={getVehicle}
              store={store}
            />
          </div>
          <div id="vehicle-page">
            <div className="vehicle-container">
              <NavigationSidebar
                make={getVehicle.make}
                model={getVehicle.model}
                year={getVehicle.year}
                plate={getVehicle.plate}
                currentTab={this.currentTab}
                currentTabNav={this.currentTabNav}
                tabs={tabs}
                store={store}
                vehicle={getVehicle}
                history={history}
                selectedTab={tab}
                status={capitalize(getVehicle.operationStatus)}
              />

              {tab === "Details" ? (
                <div style={{ height: "100%", width: "100vw" }}>
                  <div className="vehicle-details">
                    <VehicleHeader
                      vehicle={getVehicle}
                      showLocation={this.showLocation}
                      store={store}
                      uuid={
                        getVehicle.piloDevice ? getVehicle.piloDevice.uuid : ""
                      }
                      odometer={getVehicle.odometer}
                      city={getVehicle.parkingLot.city}
                      status={capitalize(getVehicle.operationStatus)}
                      serviceStatus={overallStatus(
                        getVehicle.recentProjectedServices
                      )}
                      mileageUntil={
                        getVehicle.recentProjectedServices[0]
                          ? getVehicle.recentProjectedServices[0].mileageUntil
                          : null
                      }
                      units={
                        getVehicle.parkingLot.city.name === "toronto"
                          ? " km"
                          : " mi"
                      }
                      gasLevel={getVehicle.gasLevel}
                      currentUser={currentUser}
                      service={getVehicle.upcomingEvents}
                      serviceNames={this.serviceNames}
                      serviceVendors={this.serviceVendors}
                      refetch={refetch}
                    />
                    <VehicleInfo
                      vehicle={getVehicle}
                      currentUser={currentUser}
                      color={getVehicle.color}
                      odometer={getVehicle.odometer}
                      vin={getVehicle.vin}
                      rin={getVehicle.rin}
                      currentServiceLocation={getVehicle.currentServiceLocation}
                      serviceLocations={getVehicle.serviceLocations}
                      medallionNumber={getVehicle.medallionNumber}
                      transponder={getVehicle.transponder}
                      parkingSpot={
                        getVehicle.parkingSpot &&
                        getVehicle.parkingSpot.identifier
                      }
                      lockboxCode={getVehicle.lockboxCode}
                      lockboxHistory={
                        getVehicle.parkingSpot &&
                        getVehicle.parkingSpot.lockboxHistory
                      }
                      availableParkingSpots={getVehicle.availableParkingSpots}
                      serialNumber={
                        getVehicle.piloDevice ? getVehicle.piloDevice.uuid : ""
                      }
                      status={capitalize(getVehicle.operationStatus)}
                      vehicleId={getVehicle.id}
                      plate={getVehicle.plate}
                      avgMileage={getVehicle.avgMileage}
                      avgFleetMileage={getVehicle.avgFleetMileage}
                      gasLevel={getVehicle.gasLevel}
                      parkingLots={getVehicle.availableParkingLots}
                      parkingLot={getVehicle.parkingLot}
                      inParkingLot={getVehicle.inParking}
                      statusLog={getVehicle.statusLog}
                      refetch={refetch}
                      city={getVehicle.parkingLot.city}
                      documents={getVehicle.documents}
                      tickets={getVehicle.tickets}
                      notes={getVehicle.notes}
                      showLocation={this.showLocation}
                      store={store}
                      uuid={
                        getVehicle.piloDevice ? getVehicle.piloDevice.uuid : ""
                      }
                      userSuggestions={userSuggestions}
                      userEmails={userEmails}
                    />
                  </div>
                </div>
              ) : null}
              {tab === "Tickets" ? (
                <div>
                  <TicketDetails
                    vehicleId={getVehicle.id}
                    tickets={getVehicle.tickets}
                    refetch={refetch}
                  />
                </div>
              ) : null}
              {tab === "Documents" ? (
                <div>
                  <VehicleDocuments
                    vehicleId={getVehicle.id}
                    vehicle={getVehicle}
                    documents={getVehicle.documents.slice().sort((a, b) => {
                      console.log(a.uploadedAt, b.uploadedAt);
                      return a.uploadedAt - b.uploadedAt;
                    })}
                    refetch={refetch}
                    prev={prev}
                    history={history}
                    store={store}
                    tab={tab}
                    setDocTab={this.setDocTab}
                    docTab={docTab}
                    tabNav={this.currentTabNav}
                  />
                </div>
              ) : null}
              {tab === "Incidents" ? (
                <div>
                  <DamageReport
                    id={getVehicle.id}
                    plate={getVehicle.plate}
                    make={getVehicle.make}
                    damageReportView={getVehicle.damageReportView}
                    history={history}
                    interiorDamage={getVehicle.interiorDamageReports}
                    exteriorDamage={getVehicle.exteriorDamageReports}
                    refetch={refetch}
                    tab={tab}
                  />
                </div>
              ) : null}
              {tab === "Inspection" ? (
                <div>
                  <ServiceEvents
                    fleetManager={getVehicle.currentUser}
                    data={getVehicle}
                    mode={this.state.mode}
                    changeMode={this.changeMode}
                    refetch={refetch}
                    history={history}
                  />
                </div>
              ) : null}
              {tab === "Service" ? (
                <div>
                  <VehicleService
                    addMaintenanceExtension={this.addMaintenanceExtension}
                    upcomingEvents={getVehicle.upcomingEvents}
                    projectedRoutineServices={
                      getVehicle.projectedRoutineServices
                    }
                    recentServiceHistory={getVehicle.recentServiceHistory}
                    city={getVehicle.parkingLot.city.name}
                    odometer={getVehicle.odometer}
                  />
                </div>
              ) : null}
              {tab === "OBD" ? (
                <VehicleObd
                  vehicle={getVehicle}
                  showLocation={this.showLocation}
                  store={store}
                  uuid={getVehicle.piloDevice ? getVehicle.piloDevice.uuid : ""}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
);

const Vehicle = (props) => {
  const client = useApolloClient();
  return <VehicleChild client={client} {...props} />;
};

export default inject("store")(Vehicle);
