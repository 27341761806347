import React from "react";
import styles from "./filterBody.module.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const AddCategory = ({ addBlankFilter }) => (
  <div className={styles.addCategory}>
    <div className={styles.addCategoryText} onClick={addBlankFilter}>
      <FontAwesomeIcon
        className={classNames(styles.blue, styles.icon)}
        icon={faPlus}
      />{" "}
      <div className={classNames(styles.blue, styles.marginLeft, styles.bold)}>
        Add
      </div>{" "}
    </div>
  </div>
);

export default AddCategory;
