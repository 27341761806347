import React, { Fragment, useState, useEffect } from "react";
import gql from "graphql-tag";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowFromBottom, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useDropzone } from "react-dropzone";

import Button from "../../../artzu/src/Button";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";
import TextField from "../../../artzu/src/TextField";

import { m } from "../../../utils";
import Loading from "../UploadDocuments/Loading";
import { useApolloClient } from "@apollo/client";

const OPTION_LIST = ["parking", "red_light", "speeding", "stop_sign"];

const EditTicketModalChild = ({ ticket, handleClose, refetch, client }) => {
  const {
    description,
    imageLink,
    expiryDate: ticketExpiryDate,
    status: ticketStatus
  } = ticket;
  const [title, setTitle] = useState(description || "");
  const [expiryDate, setExpiryDate] = useState(ticketExpiryDate || null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(imageLink || null);
  const [otherInput, setOtherInput] = useState(false);
  const [other, setOther] = useState(
    OPTION_LIST.includes(description) ? false : true
  );
  const [status, setStatus] = useState(ticketStatus || "");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (other) {
      setOtherInput(true);
    }
  }, [other]);

  const handleInputChange = val => {
    switch (val) {
      case "other":
        setOtherInput(true);
        setTitle(null);
        break;
      default:
        setOtherInput(false);
        setTitle(val);
    }
  };

  const formValidation = (file, name, status) => {
    const errors = [];
    if (!file) {
      errors.push("File required.");
    }
    if (!name) {
      errors.push("Title can't be blank.");
    }
    if (!status) {
      errors.push("Status can't be blank.");
    }
    return errors;
  };

  const UPDATE_TICKET_DOCUMENT = gql`
    mutation updateTicketDocument($updateTicket: UpdateDocumentAttributes!) {
      updateDocument(params: $updateTicket) {
        success
        errors
      }
    }
  `;

  const handleSubmit = () => {
    const { id } = ticket;
    const updateTicket = {
      id,
      name: "ticket",
      description: title,
      expiryDate,
      status
    };
    if (file) updateTicket.file = file;
    console.log("update tickets", { updateTicket });
    const errors = formValidation(filePreview, title, status);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    } else {
      setLoading(true);
      client
        .mutate({
          mutation: UPDATE_TICKET_DOCUMENT,
          context: { hasUpload: true },
          variables: {
            updateTicket
          }
        })
        .then(res => {
          console.log(res);
          setLoading(false);
          if (res.data.updateDocument.success) {
            refetch();
            handleClose();
          } else {
            setErrors(res.data.updateDocument.errors);
          }
        });
    }
  };

  const FileDiv = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        setFile(acceptedFiles[0]);
        setFilePreview(URL.createObjectURL(acceptedFiles[0]));
      }
    });

    return (
      <>
        <div
          className="upload__modal__preview"
          style={
            filePreview
              ? { "background-image": `url(${filePreview})` }
              : { "background-color": "grey", opacity: "0.9" }
          }
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps({ id: "image-udpate" })} />
          </div>
        </div>
        <div
          className="upload__modal__preview__icon"
          onClick={() => {
            document.getElementById("image-udpate").click();
          }}
        >
          <FontAwesomeIcon
            icon={faArrowFromBottom}
            style={{ color: "##FFFFFF", "margin-left": "14px" }}
            size="2x"
          />
          <div>{file ? "Update" : "Upload"}</div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <div className="upload__modal">
        <FileDiv />
        {loading ? (
          <Loading />
        ) : (
          <div className="upload__modal__inputs">
            <Fragment>
              <div className="upload__modal__inputs--close">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="2x"
                  onClick={handleClose}
                />
              </div>
              <div className="upload__modal__inputs__title">
                <div className="upload__modal__inputs__title--name">Title:</div>
                <SelectField
                  prompt={other ? "Other" : description}
                  onChange={val => handleInputChange(val)}
                  primaryColor="#414141"
                  className="upload__modal__inputs__title--options"
                  width="17em"
                >
                  <Option label="Parking" value="parking" />
                  <Option label="Red Light" value="red_light" />
                  <Option label="Speeding" value="speeding" />
                  <Option label="Stop Sign" value="stop_sign" />
                  <Option label="Other" value="other" />
                </SelectField>
              </div>
              {otherInput ? (
                <div className="upload__modal__inputs__title--other">
                  <TextField
                    placeholder="Other"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </div>
              ) : null}
              <div className="upload__modal__inputs__expDate">
                <div className="upload__modal__inputs__expDate--name">
                  Expiry Date:
                </div>
                <DatePicker
                  selected={expiryDate && m(expiryDate)}
                  onChange={e => setExpiryDate(e)}
                  placeholderText="Date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  className="upload__modal__inputs__expDate--input"
                />
              </div>
              <div className="upload__modal__inputs__title">
                <div className="upload__modal__inputs__title--name">Status</div>
                <SelectField
                  prompt={status || "Status"}
                  onChange={val => setStatus(val)}
                  primaryColor="#414141"
                  className="upload__modal__inputs__title--options"
                  width="14.3em"
                >
                  <Option label="Resolved" value="resolved" />
                  <Option label="Unresolved" value="unresolved" />
                </SelectField>
              </div>
              <br />
              {errors.length > 0 ? (
                <div>
                  {errors.map(error => (
                    <div className="upload__modal__error"> {error}</div>
                  ))}
                </div>
              ) : null}
            </Fragment>
            <div className="upload__modal__inputs__buttons">
              <Button color="#414141" label="Cancel" onClick={handleClose} />
              <Button color="#128DFF" label="Save" onClick={handleSubmit} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const EditTicketModal = props => {
  const client = useApolloClient();
  return <EditTicketModalChild client={client} {...props} />;
};

export default EditTicketModal;
