import React from "react";
import { Fragment } from "react";
import VehicleStatus from "./VehicleStatus";
import "../../vehicle.scss";

const StatusDisplayRight = ({
  serviceStatus,
  teslaVehicleStatus,
  inspectionStatus,
  valetModeStatus,
}) => {
  return (
    <div className="status-right">
      <div className="statusBox">
        <div className="operationTitle">Service Status:</div>
        <VehicleStatus status={serviceStatus} />
      </div>
      <div className="statusBox">
        <div className="operationTitle">Inspection Status:</div>
        <VehicleStatus status={inspectionStatus} />
      </div>
      <div className="statusBox">
        <div className="operationTitle">Vehicle Status:</div>
        <VehicleStatus status={teslaVehicleStatus} />
      </div>
      <div className="statusBox">
        <div className="operationTitle">Valet Mode:</div>
        <VehicleStatus status={valetModeStatus} />
      </div>
    </div>
  );
};

export default StatusDisplayRight;
