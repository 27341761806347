import React from "react";
import styles from "../filterRow.module.scss";
import moment from "moment";

const InputBox = ({ date, onClick }) => {
  const text = date ? moment(date).format("MM/DD/YY,kk:mm") : "Select one";
  return (
    <div className={styles.dateInput} onClick={onClick}>
      {text}
    </div>
  );
};

export default InputBox;
