import React, { Fragment } from "react";
import styles from "./makeAvailable.module.scss";

const ConfirmationMessage = ({ isWarning, message }) => (
  <Fragment>
    {isWarning && <div className={styles.title}>WARNING</div>}
    <div className={styles.text}>{message}</div>
  </Fragment>
);

export default ConfirmationMessage;
