import React from "react";
import { Fragment } from "react";
import styles from "../../../../InformationTable/BatchActions/batchActions.module.scss";
import Loading from "../../../../../Containers/Loading";
import Button from "../../../../../artzu/src/Button";

const ChangeStatusModalButtons = ({
  close,
  toggleOpenState,
  loading,
  addable,
  changeVehicleStatus,
  toggleRefresh,
}) => {
  return (
    <Fragment>
      <div className={styles.notesCancelButton}>
        <Button
          label="Cancel"
          height="39px"
          fullWidth="150px"
          color="#414141"
          backColor="#ffffff"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #FFFFFF"
          onClick={() => {
            close();
            toggleOpenState();
          }}
        />
      </div>
      <div className={styles.notesSaveButton}>
        <Button
          label={loading ? <Loading size="1x" /> : "Save"}
          height="39px"
          fullWidth="150px"
          color={addable ? "#128dff" : "#87CEFA"}
          backColor="#ffffff"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #FFFFFF"
          onClick={() => {
            addable && changeVehicleStatus();
            toggleRefresh();
          }}
        />
      </div>
    </Fragment>
  );
};

export default ChangeStatusModalButtons;
