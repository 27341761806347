import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import Button from "../artzu/src/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Components/Vehicle/vehicle.scss";

class NewNote extends Component {
  NEW_NOTE = gql`
    mutation NewNote($newNoteParams: NewNoteAttributes!) {
      newNote(params: $newNoteParams) {
        success
        errors
      }
    }
  `;

  render() {
    const {
      newNoteParams,
      refetch,
      onChange,
      closeNote,
      resetNote,
    } = this.props;
    const addable = newNoteParams.details;
    return (
      <Mutation
        mutation={this.NEW_NOTE}
        variables={{ newNoteParams }}
        onCompleted={() => {
          refetch();
          onChange("");
          resetNote();
          closeNote();
        }}
        onError={(data) => {
          console.log("error", data);
        }}
      >
        {(newNote) => (
          <Button
            label={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1.5" }} />
                Add
              </div>
            }
            fullWidth="80px"
            height="34px"
            color="#FFFFFF"
            backColor={addable ? "#128DFF" : "#87CEFA"}
            border="none"
            radius="17px"
            padding="0 15px 0 15px"
            onClick={() => {
              newNote();
            }}
            size="16px"
          ></Button>
        )}
      </Mutation>
    );
  }
}

export default NewNote;
