import React from "react";
import { Fragment } from "react";
import EditNoteModalPanel from "./EditNoteModalPanel";

const NotePopup = ({
  editNote,
  editedNote,
  selectedNote,
  editChange,
  refetch,
  toggleEditNote,
  resetNoteState,
}) => {
  return (
    <Fragment>
      <EditNoteModalPanel
        editNote={editNote}
        editedNote={editedNote}
        selectedNote={selectedNote}
        editChange={editChange}
        refetch={refetch}
        toggleEditNote={toggleEditNote}
        resetNoteState={resetNoteState}
      />
    </Fragment>
  );
};

export default NotePopup;
