import React from "react";
import styles from "../../task.module.scss";
import Modal from "../../../../artzu/src/Modal";

const DiscardModal = ({
  open,
  toggleDiscard,
  clearCurrentTemplate,
  closeNew,
  clearInput,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.discardModal}>
        <div className={styles.templateTitle}>Discard template</div>
        <div>Are you sure you want to discard this template?</div>
        <div className={styles.templateButtons}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              toggleDiscard();
            }}
          >
            No
          </button>
          <button
            className={styles.chooseButton}
            onClick={() => {
              clearInput();
              clearCurrentTemplate();
              toggleDiscard();
              closeNew();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardModal;
