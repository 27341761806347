import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../locationSelect.module.scss";
import _ from "lodash";
import formatCityName from "./FormatCityName";
import formatLotName from "./FormatLotName";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react";
import LocationSelectDropdown from "./LocationSelectDropdown";

const LocationSelectContent = observer(
  ({
    open,
    toggleOpen,
    lotsSelectedName,
    citiesSelectedName,
    lots,
    lotsSelected,
    citiesSelected,
    selectCities,
    selectLots,
    reset,
    store,
  }) => {
    const selectorIndicator = () => {
      const allLotsCountOfSelectedCities = citiesSelected.reduce(
        (acc, city) => {
          return acc + lots[city]?.length;
        },
        0
      );
      if (citiesSelectedName.length > 0) {
        if (allLotsCountOfSelectedCities === lotsSelected.length) {
          return _.startCase(formatCityName(citiesSelectedName));
        } else {
          return formatLotName(lotsSelectedName);
        }
      } else {
        return formatLotName(lotsSelectedName);
      }
    };
    return (
      <div className={styles.dropdownContainer}>
        <div className="navbar-link">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            style={{ fontSize: "x-large" }}
            onClick={toggleOpen}
          />
          <div>{selectorIndicator() || "ALL"}</div>
        </div>

        {open && (
          <div>
            <div className={styles.triangle} />
            <LocationSelectDropdown
              lots={lots}
              lotsSelected={lotsSelected}
              selectCities={selectCities}
              selectLots={selectLots}
              reset={reset}
              store={store}
              toggleOpen={toggleOpen}
            />
          </div>
        )}
      </div>
    );
  }
);

export default LocationSelectContent;
