import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPlus, faThumbtack } from "@fortawesome/pro-solid-svg-icons";
// import NewNote from "../../../Mutations/NewNote";
import moment from "moment";
import styles from "./notes.module.scss";
import Button from "../../../../../artzu/src/Button";
import { useMutation } from "@apollo/client";
import { NEW_DAMAGE_NOTE } from "./queries";

const NewNotePanel = ({
  newNote,
  newReminder,
  newReminderDate,
  toggleRemindersModal,
  refetch,
  onChange,
  closeNote,
  resetNoteState,
  pinNewNote,
  vehicleId,
  damageId,
  selectPin,
}) => {
  const pinnedStyle = { backgroundColor: "#414141", marginRight: "10px" };
  const unPinnedStyle = { backgroundColor: "#FFFFFF", marginRight: "10px" };
  const newNoteParams = {
    details: newNote,
    vehicleId,
    pinned: pinNewNote,
    reminderDate: newReminder ? newReminderDate : null,
    taggedUsers: [],
  };
  const addable = newNoteParams.details;

  const [addNoteMutation, { loading, error }] = useMutation(NEW_DAMAGE_NOTE);
  const handleNewNote = () => {
    {
      addNoteMutation({
        variables: {
          params: newNoteParams,
          damageId: damageId,
        },
      }).then(({ data: { addDamageNote } }) => {
        if (addDamageNote.success) {
          refetch();
          onChange("");
          resetNoteState();
          closeNote();
          console.log("Create Note Succeed!");
        } else {
          console.log("Create note error!");
        }
      });
    }
  };
  return (
    <div className={styles.newNoteHeader}>
      <div className={styles.addNoteTitle}>New note</div>
      <textarea
        className={styles.textboxInput}
        placeholder="Enter note here..."
        value={newNote}
        rows="4"
        cols="50"
        onChange={(evt) => {
          onChange(evt.target.value);
        }}
      />
      <div
        className={styles.underTextContainer}
        style={{ justifyContent: "flex-end" }}
      >
        <div className={styles.noteButtons}>
          <div
            className={styles.iconButtons}
            style={pinNewNote === true ? pinnedStyle : unPinnedStyle}
          >
            <FontAwesomeIcon
              className={styles.pinNote}
              icon={faThumbtack}
              style={
                pinNewNote === true
                  ? { color: "#FFFFFF" }
                  : { color: "#414141" }
              }
              onClick={() => {
                selectPin();
              }}
            />
          </div>
          <div className={styles.addNote}>
            <Button
              label={
                <div className={styles.addButton}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginTop: "1.5" }} />
                  Add
                </div>
              }
              fullWidth="80px"
              height="34px"
              color="#FFFFFF"
              backColor={addable ? "#128DFF" : "#87CEFA"}
              border="none"
              radius="17px"
              padding="0 15px 0 15px"
              onClick={() => {
                handleNewNote();
              }}
              size="16px"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNotePanel;
