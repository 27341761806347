import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./vehicle.scss";
import { m } from "../../utils";
import Button from "../../artzu/src/Button";
import NewNote from "../../Mutations/NewNote";
import NotesModal from "../Misc/NotesModal";
import DeleteNote from "../../Mutations/DeleteNote";
import ReminderNote from "../../Mutations/ReminderNote";
import DeleteReminderNote from "../../Mutations/DeleteReminderNote";
import CircleImage from "../MapView/CircleImage";
import PinNote from "../../Mutations/PinNote";
import EditNote from "../../Mutations/EditNote";
import DatePicker from "react-datepicker";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import {
  faThumbtack,
  faEdit,
  faPencil,
  faCalendar,
} from "@fortawesome/pro-solid-svg-icons";
import { faAlarmClock, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reorderNotes } from "../../utils";
import _ from "lodash";

class VehicleNotes extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };
  constructor(props) {
    super(props);
    this.state = {
      all: false,
      text: "Show more...",
      pin: {},
      pinNewNote: false,
      editNote: false,
      selectedNote: null,
      selectedNoteDetails: null,
      reminderModal: false,
      newReminder: false,
      newReminderDate: null,
      reminderDate: null,
      taggedUsers: [],
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      modifiedNote: "",
    };
  }

  showAll = () => this.setState({ all: true, text: "Show less" });
  showLess = () => this.setState({ all: false, text: "Show more..." });
  selectPin = () => this.setState({ pinNewNote: !this.state.pinNewNote });
  updateNote = (note) =>
    this.setState({
      editNote: !this.state.editNote,
      selectedNote: note,
      selectedNoteDetails: note.details,
    });

  selectNote = (note) => {
    this.setState({
      selectedNote: note,
      selectedNoteDetails: note.details,
    });
    if (note.reminderDate) {
      this.selectReminderDate(note.reminderDate);
    }
  };
  toggleEditNote = () => {
    this.setState({
      editNote: !this.state.editNote,
    });
    this.props.editChange(null);
  };
  resetNoteState = () => {
    this.setState({
      pinNewNote: false,
      selectedNote: null,
      selectedNoteDetails: "",
    });
    this.props.editChange(null);
    this.resetReminder();
  };

  resetReminder = () => {
    this.setState({
      newReminder: false,
      newReminderDate: false,
    });
  };

  toggleRemindersModal = () => {
    this.setState({
      reminderModal: !this.state.reminderModal,
    });
  };

  selectReminderDate = (val) => {
    this.setState({ reminderDate: val });
  };

  addNewReminderDate = (val) => {
    this.setState({ newReminderDate: val });
  };
  addNewReminder = (val) => {
    this.setState({ newReminder: val });
  };

  addTaggedUsers = (evt) => {
    let note = evt.currentTarget.value;
    let noteArr = note.replace(/(\r\n|\n|\r)/gm, " ").split(" ");

    let tagged = _.filter(noteArr, (val, index) => {
      return val[0] === "@" && noteArr.length > 1;
    });

    let taggedFullName = [];
    noteArr.forEach((element, index) => {
      if (index > 0 && noteArr[index - 1].includes("@")) {
        taggedFullName.push(noteArr[index - 1] + " " + element);
      }
    });

    this.setState({
      taggedUsers: taggedFullName,
    });
  };

  modifyTaggedUsers = (tags) => {
    const { suggestions, userEmails } = this.props;

    let modifiedTags = [];
    let modifiedEmailTags = [];
    tags.forEach((tag) => {
      modifiedTags.push(tag.slice(1));
    });
    modifiedTags.forEach((tag) => {
      let index = suggestions.indexOf(tag);
      let email = userEmails[index];
      let username = email && email.substring(0, email.indexOf("@"));
      modifiedEmailTags.push(username);
    });

    return modifiedEmailTags;
  };

  onChangeTag = (e) => {
    const { suggestions, newNote } = this.props;
    let note = e.currentTarget.value;
    let splitNote = note.split(" ");

    const userInput = note.includes("@")
      ? note.substring(note.lastIndexOf("@") + 1)
      : "";

    if (
      splitNote[splitNote.length - 1].includes("@") ||
      (splitNote.length >= 2 && splitNote[splitNote.length - 2].includes("@"))
    ) {
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: userInput,
      });
    } else {
      this.setState({
        activeSuggestion: 0,

        showSuggestions: false,
      });
    }
  };

  onClickTag = (e) => {
    const { newNote, editedNote, editChange, onChange } = this.props;
    const { editNote } = this.state;
    const userInput = e.currentTarget.innerText;
    let splitNote = editNote ? editedNote.split(" ") : newNote.split(" ");
    if (
      splitNote[splitNote.length - 1].includes("@") ||
      (splitNote.length >= 2 && splitNote[splitNote.length - 2].includes("@"))
    ) {
      _.remove(splitNote, (element, index) => {
        return (
          index === splitNote.length - 1 ||
          (splitNote.length >= 2 && index === splitNote.length - 2)
        );
      });
      splitNote.push("@" + userInput);
    }
    const modifiedNote = splitNote.join(" ");
    console.log(modifiedNote);
    if (editNote) {
      editChange(modifiedNote);
    } else {
      onChange(modifiedNote);
    }

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: userInput,
    });
  };

  onEnterTag = (input) => {
    const { newNote, editedNote, editChange, onChange } = this.props;
    const { editNote } = this.state;
    const userInput = input;
    let splitNote = editNote ? editedNote.split(" ") : newNote.split(" ");
    if (
      splitNote[splitNote.length - 1].includes("@") ||
      (splitNote.length >= 2 && splitNote[splitNote.length - 2].includes("@"))
    ) {
      _.remove(splitNote, (element, index) => {
        return (
          index === splitNote.length - 1 ||
          (splitNote.length >= 2 && index === splitNote.length - 2)
        );
      });
      splitNote.push("@" + userInput);
    }
    const modifiedNote = splitNote.join(" ");
    console.log(modifiedNote);

    if (editNote) {
      editChange(modifiedNote);
    } else {
      onChange(modifiedNote);
    }

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: input,
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions

    if (e.keyCode === 13) {
      this.onEnterTag(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  // pinNote = note => {
  //  const pinned = this.state.pin;
  //  if (note.pinned) {
  //    pinned[note.id] = !note.pinned;
  //  } else {
  //    pinned[note.id] = true;
  //  }

  //  this.setState({ pin: pinned });
  // };

  render() {
    const {
      notes = [],
      onChange,
      newNote,
      vehicleId,
      refetch,
      addNote,
      closeNote,
      map,
      currentUser,
      editChange,
      editedNote,
      classes,
    } = this.props;
    const {
      all,
      text,
      close,
      pin,
      pinNewNote,
      editNote,
      selectedNote,
      selectedNoteDetails,
      reminderModal,
      newReminderDate,
      newReminder,
      reminderDate,
      taggedUsers,
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput,
      modifiedNote,
    } = this.state;
    const now = m();
    const remindInDays = (endDate) => {
      let today = new Date();
      let end = new Date(endDate);

      let remind = Number(m(end - today).format("x"));
      let durationDays = Math.floor(remind / (1000 * 60 * 60 * 24));

      let durationHours = Math.floor(
        (remind - durationDays * 1000 * 60 * 60 * 24) / (1000 * 60 * 60)
      );

      let durationMins = Math.floor(
        (remind -
          durationDays * 1000 * 60 * 60 * 24 -
          durationHours * (1000 * 60 * 60)) /
          (1000 * 60)
      );

      if (durationDays === 1) {
        return (
          "Remind in " +
          durationDays +
          "d " +
          durationHours +
          "hr " +
          durationMins +
          "min "
        );
      } else {
        if (durationDays < 1) {
          return "Remind in " + durationHours + "hr " + durationMins + "min ";
        } else {
          return (
            "Remind in " +
            durationDays +
            "d " +
            durationHours +
            "hr " +
            durationMins +
            "min "
          );
        }
      }
    };

    const sortedNotes = reorderNotes(notes);
    const pinnedNotes = _.filter(sortedNotes, (note) => {
      return note.pinned === true;
    });

    const otherNotes = _.filter(sortedNotes, (note) => {
      return note.pinned !== true;
    });

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul
            class="suggestions"
            style={editNote ? { position: "relative", top: "-143px" } : null}
          >
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={this.onClickTag}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No users found</em>
          </div>
        );
      }
    }

    return (
      <div className="vehicleContainerDetailsSmall">
        <NotesModal
          open={editNote}
          closeModal={this.toggleEditNote}
          title="Edit Note"
          map={map}
        >
          <textarea
            className="edit-textbox-input"
            placeholder="Enter note here..."
            value={
              editedNote || editedNote === ""
                ? editedNote
                : selectedNote
                ? selectedNote.details
                : null
            }
            rows="4"
            cols="50"
            onChange={(evt) => {
              editChange(evt.target.value);
              this.onChangeTag(evt);
              this.addTaggedUsers(evt);
            }}
            onKeyDown={this.onKeyDown}
          />
          <div style={{ height: "12px" }}>
            {editNote ? suggestionsListComponent : null}
          </div>
          <div className="edit-note-buttons">
            <div className="cancel-note">
              <Button
                label="Cancel"
                fullWidth="154px"
                height="39px"
                color="#414141"
                border="none"
                onClick={() => {
                  this.toggleEditNote();
                }}
                weight="bold"
                size="17px"
              ></Button>
            </div>
            <div className="edit-note">
              <EditNote
                editNoteParams={{
                  vehicleId: vehicleId,
                  userId: selectedNote && selectedNote.userId,
                  reminderDate: selectedNote && selectedNote.reminderDate,
                  details: editedNote
                    ? editedNote
                    : selectedNote && selectedNote.details,
                  noteId: selectedNote && selectedNote.id,
                  taggedUsers: this.modifyTaggedUsers(taggedUsers),
                }}
                refetch={refetch}
                onChange={editChange}
                closeNote={this.toggleEditNote}
                resetNote={this.resetNoteState}
                edited={editedNote ? true : false}
              />
            </div>
          </div>
        </NotesModal>

        <NotesModal
          open={reminderModal}
          closeModal={this.toggleRemindersModal}
          title="Edit Reminder"
          map={map}
        >
          <div className="date-input">
            <div>Remind on:</div>
            <DatePicker
              customInput={
                newReminderDate ? (
                  <div style={{ color: "#414141" }}>
                    {m(newReminderDate).format("MM/DD/YYYY hh:mm A")}
                  </div>
                ) : selectedNote && reminderDate ? (
                  <div style={{ color: "#414141" }}>
                    {m(reminderDate).format("MM/DD/YYYY hh:mm A")}
                  </div>
                ) : (
                  <div>
                    <div>Time and Date</div>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                )
              }
              className={classNames(classes.dateInput)}
              onChange={(val) => {
                if (selectedNote) {
                  this.selectReminderDate(val);
                } else {
                  this.addNewReminderDate(val);
                }
              }}
              showTimeSelect
              selected={
                selectedNote && reminderDate
                  ? m(reminderDate)
                  : newReminderDate
                  ? m(newReminderDate)
                  : m()
              }
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="Time"
              todayButton={"Today"}
            />
          </div>
          <div className="edit-note-buttons">
            <div className="cancel-note">
              <Button
                label="Cancel"
                fullWidth="154px"
                height="39px"
                color="#414141"
                border="none"
                onClick={() => {
                  this.toggleRemindersModal();
                }}
                weight="bold"
                size="17px"
              ></Button>
            </div>
            <div className="edit-note">
              {selectedNote ? (
                <ReminderNote
                  reminderNoteParams={{
                    noteId: selectedNote.id,
                    details: selectedNote.details,
                    vehicleId: vehicleId,
                    userName: selectedNote.user,
                    userId: selectedNote.userId,

                    reminderDate: reminderDate,
                  }}
                  refetch={refetch}
                  closeNote={this.toggleRemindersModal}
                />
              ) : (
                <Button
                  label="Set Reminder"
                  fullWidth="154px"
                  height="39px"
                  color="#128DFF"
                  border="none"
                  disabled={newReminderDate && m(newReminderDate) < now}
                  onClick={() => {
                    this.addNewReminder(true);
                    this.toggleRemindersModal();
                  }}
                  weight="bold"
                  size="17px"
                ></Button>
              )}
            </div>
          </div>
        </NotesModal>

        {map ? null : (
          <div className="add-note-header">
            <div className="add-note-title">New note</div>
            <textarea
              className="textbox-input"
              placeholder="Enter note here..."
              value={newNote}
              rows="4"
              cols="50"
              onChange={(evt) => {
                onChange(evt.target.value);
                this.onChangeTag(evt);
                this.addTaggedUsers(evt);
              }}
              onKeyDown={this.onKeyDown}
            />
            <div
              className="under-text-container"
              style={
                !showSuggestions ||
                (userInput && userInput === "") ||
                !userInput
                  ? { justifyContent: "flex-end" }
                  : null
              }
            >
              {editNote ? null : suggestionsListComponent}
              <div className="note-buttons">
                {
                  // <div className="cancel-note">
                  //   <Button
                  //     label="Cancel"
                  //     fullWidth="154px"
                  //     height="39px"
                  //     color="#414141"
                  //     border="none"
                  //     onClick={() => {
                  //       closeNote();
                  //     }}
                  //     weight="bold"
                  //     size="17px"
                  //   ></Button>
                  // </div>
                }
                {newReminder ? (
                  <div className="reminder-box">
                    <div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          this.resetReminder();
                        }}
                        style={{ margin: "0 8px 0 8px", cursor: "pointer" }}
                      />
                    </div>
                    <div style={{ margin: "0 8px 0 0px" }}>
                      {remindInDays(newReminderDate)}
                    </div>
                    <div
                      className="icon-buttons"
                      style={{
                        backgroundColor: "#414141",
                      }}
                    >
                      <FontAwesomeIcon
                        className="pin-note"
                        icon={faAlarmClock}
                        style={{
                          color: "#FFFFFF",
                        }}
                        onClick={() => {
                          this.toggleRemindersModal();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="icon-buttons"
                    style={{
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <FontAwesomeIcon
                      className="pin-note"
                      icon={faAlarmClock}
                      style={{
                        color: "#414141",
                      }}
                      onClick={() => {
                        this.toggleRemindersModal();
                      }}
                    />
                  </div>
                )}

                <div
                  className="icon-buttons"
                  style={
                    pinNewNote === true
                      ? {
                          backgroundColor: "#414141",
                          marginRight: "16px",
                        }
                      : {
                          backgroundColor: "#FFFFFF",
                          marginRight: "16px",
                        }
                  }
                >
                  <FontAwesomeIcon
                    className="pin-note"
                    icon={faThumbtack}
                    style={
                      pinNewNote === true
                        ? {
                            color: "#FFFFFF",
                          }
                        : {
                            color: "#414141",
                          }
                    }
                    onClick={() => {
                      this.selectPin();
                    }}
                  />
                </div>
                <div className="add-note">
                  <NewNote
                    newNoteParams={{
                      details: newNote,
                      vehicleId,
                      pinned: pinNewNote,
                      reminderDate: newReminder ? newReminderDate : null,
                      taggedUsers: this.modifyTaggedUsers(taggedUsers),
                    }}
                    refetch={refetch}
                    onChange={onChange}
                    closeNote={closeNote}
                    resetNote={this.resetNoteState}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="add-note-title" style={map ? { margin: "0" } : null}>
          Pinned notes
        </div>
        {sortedNotes.length > 0 ? (
          <div
            className="notes-box"
            style={
              map
                ? { margin: "0", minHeight: "304px" }
                : { minHeight: "304px", paddingTop: "32px" }
            }
          >
            {pinnedNotes.map((note, index) => {
              if (all || index < 5) {
                return (
                  <div key={index} className="notes-container">
                    <div className="note">
                      <div className="note-header">
                        <div className="notes-user-image">
                          <CircleImage
                            imageLink={note.userImage}
                            width="24px"
                            height="24px"
                            isPrev={false}
                          />
                          <p className="note-user">{note.user}: </p>
                        </div>
                        <div className="note-date-container">
                          <div className="note-date">
                            {m(note.date).format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div
                        className="note-details-container"
                        style={map ? { display: "block" } : null}
                      >
                        <div
                          className="note-details"
                          style={
                            note.pinned === true ? { color: "#ff0000" } : null
                          }
                        >
                          {note.details
                            .replace(/(\r\n|\n|\r)/gm, " ")
                            .split(" ")
                            .map((word, index) => {
                              if (
                                word[0] === "@" ||
                                (index > 0 &&
                                  note.details
                                    .replace(/(\r\n|\n|\r)/gm, " ")
                                    .split(" ")[index - 1][0] === "@")
                              ) {
                                return (
                                  <span
                                    style={{ color: "#128DFF" }}
                                  >{`${word} `}</span>
                                );
                              } else {
                                return <span>{`${word} `}</span>;
                              }
                            })}
                        </div>
                        <div>
                          <div className="pin-delete-note">
                            {note.reminderDate && now < m(note.reminderDate) ? (
                              <div className="reminder-box">
                                <div>
                                  <DeleteReminderNote
                                    reminderNoteParams={{
                                      noteId: note.id,
                                      details: note.details,
                                      vehicleId: vehicleId,
                                      userName: note.user,
                                      userId: note.userId,

                                      reminderDate: note.reminderDate,
                                    }}
                                    refetch={refetch}
                                    resetNote={this.resetNoteState}
                                  />
                                </div>
                                <div style={{ margin: "0 8px 0 0px" }}>
                                  {remindInDays(note.reminderDate)}
                                </div>
                                <div
                                  className="icon-buttons"
                                  style={
                                    note.reminderDate &&
                                    now < m(note.reminderDate)
                                      ? {
                                          backgroundColor: "#414141",
                                          marginLeft: "0",
                                        }
                                      : {
                                          backgroundColor: "#FFFFFF",
                                          marginLeft: "0",
                                        }
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="pin-note"
                                    icon={faAlarmClock}
                                    style={
                                      note.reminderDate &&
                                      now < m(note.reminderDate)
                                        ? {
                                            color: "#FFFFFF",
                                          }
                                        : {
                                            color: "#414141",
                                          }
                                    }
                                    onClick={() => {
                                      this.selectNote(note);
                                      this.toggleRemindersModal();
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="icon-buttons"
                                style={
                                  note.reminderDate &&
                                  now < m(note.reminderDate)
                                    ? {
                                        backgroundColor: "#414141",
                                        marginLeft: "0",
                                      }
                                    : {
                                        backgroundColor: "#FFFFFF",
                                        marginLeft: "0",
                                      }
                                }
                              >
                                <FontAwesomeIcon
                                  className="pin-note"
                                  icon={faAlarmClock}
                                  style={
                                    note.reminderDate &&
                                    now < m(note.reminderDate)
                                      ? {
                                          color: "#FFFFFF",
                                        }
                                      : {
                                          color: "#414141",
                                        }
                                  }
                                  onClick={() => {
                                    this.selectNote(note);
                                    this.toggleRemindersModal();
                                  }}
                                />
                              </div>
                            )}
                            {currentUser.id === note.userId ? (
                              <div
                                className="icon-buttons"
                                style={
                                  editNote === true &&
                                  selectedNote &&
                                  note.id === selectedNote.id
                                    ? {
                                        backgroundColor: "#414141",
                                      }
                                    : {
                                        backgroundColor: "#FFFFFF",
                                      }
                                }
                              >
                                <FontAwesomeIcon
                                  className="pin-note"
                                  icon={faPencil}
                                  style={
                                    editNote === true &&
                                    selectedNote &&
                                    note.id === selectedNote.id
                                      ? {
                                          color: "#FFFFFF",
                                        }
                                      : {
                                          color: "#414141",
                                        }
                                  }
                                  onClick={() => {
                                    this.updateNote(note);
                                  }}
                                />
                              </div>
                            ) : null}
                            <div className="pin-note">
                              <PinNote
                                refetch={refetch}
                                pinNoteParams={{
                                  pinned: note.pinned || false,
                                  noteId: parseInt(note.id),
                                }}
                                pinCheck={this.pinNote}
                                noteId={note.id}
                                note={note}
                              />
                            </div>
                            <div className="delete-note">
                              <DeleteNote noteId={note.id} refetch={refetch} />
                            </div>
                          </div>
                          {
                            // {map ? (
                            //   note.reminderDate && now < m(note.reminderDate) ? (
                            //     <div
                            //       className="reminder-box"
                            //       style={{ marginTop: "8px" }}
                            //     >
                            //       <div>
                            //         <DeleteReminderNote
                            //           reminderNoteParams={{
                            //             noteId: note.id,
                            //             details: note.details,
                            //             vehicleId: vehicleId,
                            //             userName: note.user,
                            //             userId: note.userId,
                            //             reminderDate: note.reminderDate,
                            //           }}
                            //           refetch={refetch}
                            //           resetNote={this.resetNoteState}
                            //         />
                            //       </div>
                            //       <div style={{ margin: "0 8px 0 8px" }}>
                            //         {remindInDays(note.reminderDate)}
                            //       </div>
                            //     </div>
                            //   ) : null
                            // ) : null}
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else return null;
            })}

            <div
              className="add-note-title"
              style={{ marginLeft: "0", marginBottom: "32px" }}
            >
              All notes
            </div>
            {otherNotes.map((note, index) => {
              if (all || index < 5) {
                return (
                  <div key={index} className="notes-container">
                    <div className="note">
                      <div className="note-header">
                        <div className="notes-user-image">
                          <CircleImage
                            imageLink={note.userImage}
                            width="24px"
                            height="24px"
                            isPrev={false}
                          />
                          <p className="note-user">{note.user}: </p>
                        </div>
                        <div className="note-date-container">
                          <div className="note-date">
                            {m(note.date).format("MM/DD/YYYY hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div
                        className="note-details-container"
                        style={map ? { display: "block" } : null}
                      >
                        <div
                          className="note-details"
                          style={
                            note.pinned === true ? { color: "#ff0000" } : null
                          }
                        >
                          {note.details
                            .replace(/(\r\n|\n|\r)/gm, " ")
                            .split(" ")
                            .map((word, index) => {
                              if (
                                word[0] === "@" ||
                                (index > 0 &&
                                  note.details
                                    .replace(/(\r\n|\n|\r)/gm, " ")
                                    .split(" ")[index - 1][0] === "@")
                              ) {
                                return (
                                  <span
                                    style={{ color: "#128DFF" }}
                                  >{`${word} `}</span>
                                );
                              } else {
                                return <span>{`${word} `}</span>;
                              }
                            })}
                        </div>
                        <div>
                          <div className="pin-delete-note">
                            {note.reminderDate && now < m(note.reminderDate) ? (
                              <div className="reminder-box">
                                <div>
                                  <DeleteReminderNote
                                    reminderNoteParams={{
                                      noteId: note.id,
                                      details: note.details,
                                      vehicleId: vehicleId,
                                      userName: note.user,
                                      userId: note.userId,

                                      reminderDate: note.reminderDate,
                                    }}
                                    refetch={refetch}
                                    resetNote={this.resetNoteState}
                                  />
                                </div>
                                <div style={{ margin: "0 8px 0 0px" }}>
                                  {remindInDays(note.reminderDate)}
                                </div>
                                <div
                                  className="icon-buttons"
                                  style={
                                    note.reminderDate &&
                                    now < m(note.reminderDate)
                                      ? {
                                          backgroundColor: "#414141",
                                          marginLeft: "0",
                                        }
                                      : {
                                          backgroundColor: "#FFFFFF",
                                          marginLeft: "0",
                                        }
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="pin-note"
                                    icon={faAlarmClock}
                                    style={
                                      note.reminderDate &&
                                      now < m(note.reminderDate)
                                        ? {
                                            color: "#FFFFFF",
                                          }
                                        : {
                                            color: "#414141",
                                          }
                                    }
                                    onClick={() => {
                                      this.selectNote(note);
                                      this.toggleRemindersModal();
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="icon-buttons"
                                style={
                                  note.reminderDate &&
                                  now < m(note.reminderDate)
                                    ? {
                                        backgroundColor: "#414141",
                                        marginLeft: "0",
                                      }
                                    : {
                                        backgroundColor: "#FFFFFF",
                                        marginLeft: "0",
                                      }
                                }
                              >
                                <FontAwesomeIcon
                                  className="pin-note"
                                  icon={faAlarmClock}
                                  style={
                                    note.reminderDate &&
                                    now < m(note.reminderDate)
                                      ? {
                                          color: "#FFFFFF",
                                        }
                                      : {
                                          color: "#414141",
                                        }
                                  }
                                  onClick={() => {
                                    this.selectNote(note);
                                    this.toggleRemindersModal();
                                  }}
                                />
                              </div>
                            )}

                            {currentUser.id === note.userId ? (
                              <div
                                className="icon-buttons"
                                style={
                                  editNote === true &&
                                  selectedNote &&
                                  note.id === selectedNote.id
                                    ? {
                                        backgroundColor: "#414141",
                                      }
                                    : {
                                        backgroundColor: "#FFFFFF",
                                      }
                                }
                              >
                                <FontAwesomeIcon
                                  className="pin-note"
                                  icon={faPencil}
                                  style={
                                    editNote === true &&
                                    selectedNote &&
                                    note.id === selectedNote.id
                                      ? {
                                          color: "#FFFFFF",
                                        }
                                      : {
                                          color: "#414141",
                                        }
                                  }
                                  onClick={() => {
                                    this.updateNote(note);
                                  }}
                                />
                              </div>
                            ) : null}
                            <div className="pin-note">
                              <PinNote
                                refetch={refetch}
                                pinNoteParams={{
                                  pinned: note.pinned || false,
                                  noteId: parseInt(note.id),
                                }}
                                pinCheck={this.pinNote}
                                noteId={note.id}
                                note={note}
                              />
                            </div>
                            <div className="delete-note">
                              <DeleteNote noteId={note.id} refetch={refetch} />
                            </div>
                          </div>
                          {
                            // {map ? (
                            //   note.reminderDate && now < m(note.reminderDate) ? (
                            //     <div className="reminder-box">
                            //       <div>
                            //         <DeleteReminderNote
                            //           reminderNoteParams={{
                            //             noteId: note.id,
                            //             details: note.details,
                            //             vehicleId: vehicleId,
                            //             userName: note.user,
                            //             userId: note.userId,
                            //             reminderDate: note.reminderDate,
                            //           }}
                            //           refetch={refetch}
                            //           resetNote={this.resetNoteState}
                            //         />
                            //       </div>
                            //       <div style={{ padding: "0 0px 0 8px" }}>
                            //         {remindInDays(note.reminderDate)}
                            //       </div>
                            //     </div>
                            //   ) : null
                            // ) : null}
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else return null;
            })}
          </div>
        ) : (
          <div className="unavailable-info">Not Available</div>
        )}
        {notes.length > 5 ? (
          <div
            onClick={() => (all ? this.showLess() : this.showAll())}
            className="end"
          >
            <p className="show-more grey-text small-text">{text}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  dateInput: {
    color: "grey",
    display: "flex",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    borderRadius: "25px",
    fontSize: "16px",

    alignItems: "center",
    padding: "0 12px 0 12px",
    margin: "0 0 0 12px",
    width: "180px",
    height: "34px",
    backgroundColor: "#FFFFFF",
  },
});

export default withStyles(styles)(VehicleNotes);
