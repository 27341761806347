import React, { useState } from "react";
import styles from "../../../batchActions.module.scss";

const ManageAllTagsSearch = ({ triggerNew, searchTag }) => {
  return (
    <div className={styles.searchContainer}>
      <div className={styles.manageSearchBox}>
        <input
          className={styles.inputBoxLong}
          id="input-field"
          placeholder="Search tag"
          onChange={triggerNew}
          value={searchTag}
        />
      </div>
    </div>
  );
};

export default ManageAllTagsSearch;
