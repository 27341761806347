import React, { useState } from "react";
import styles from "./listPopup.module.scss";
import classNames from "classnames";
import ActionModal from "../../InformationTable/BatchActions/ActionModal";
import {
  faCarAlt,
  faCommentAlt,
  faMapMarkedAlt,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { Fragment } from "react";
import CommandConfirm from "../../VehicleNew/ActionButtons/CommandConfirm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import MessageBox from "../../VehicleNew/ActionButtons/MessageBox";

const ListBox = ({ currentValue, listItems, vehicle, closeList }) => {
  const [selectedAction, setSelectedAction] = useState();
  const [changeStatusOpen, setChangeStatusOpen] = useState();
  const [changeHubOpen, setChangeHubOpen] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [msgOpen, setMsgOpen] = useState();
  const [errorOpen, setErrorOpen] = useState();
  const [vehicleUUID, setVehicleUUID] = useState();
  const [vehicleStatus, setVehicleStatus] = useState();

  const AVC_ACTION = gql`
    mutation avcEvent($vehicleId: ID!, $action: String!) {
      avcEvent(vehicleId: $vehicleId, action: $action) {
        command {
          uuid
          status
        }
      }
    }
  `;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleMsgModal = () => {
    setMsgOpen(!msgOpen);
  };

  const toggleErrorModal = () => {
    setMsgOpen(!errorOpen);
  };

  const [AvcActionMutation, { loading, error }] = useMutation(AVC_ACTION);

  const sendCommand = () => {
    console.log(selectedAction);
    AvcActionMutation({
      variables: {
        vehicleId: vehicle.id,
        action: selectedAction
      }
    }).then(({ data: { avcEvent } }) => {
      if (avcEvent.command) {
        setVehicleUUID(avcEvent.command.uuid);
        setVehicleStatus(avcEvent.command.status);
        toggleMsgModal();
        console.log("Succeed!");
      } else {
        toggleErrorModal();
        console.log("error!");
      }
    });
    toggleModal();
  };

  const onItemClick = value => {
    if (value === "Change status") {
      setSelectedAction("changeStatus");
      setChangeStatusOpen(true);
    } else if (value === "Change hub") {
      setSelectedAction("changeHub");
      setChangeHubOpen(true);
    } else if (value === "Start Vehicle") {
      setSelectedAction("startVehicle");
      setChangeHubOpen(false);
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Immobilize") {
      setSelectedAction("immobilize");
      setChangeHubOpen(false);
      setChangeStatusOpen(false);
      toggleModal();
    } else {
      setSelectedAction("free");
      setChangeHubOpen(false);
      setChangeStatusOpen(false);
      toggleModal();
    }
  };

  const icons = [
    {
      value: "changeHub",
      text: "Change Hub",
      icon: faMapMarkedAlt
    },
    {
      value: "changeStatus",
      text: "Change Status",
      icon: faCarAlt
    }
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  let vehicleList = [];
  vehicleList.push(vehicle);

  const close = () => {
    setSelectedAction(null);
  };

  return (
    <Fragment>
      <div className={styles.options}>
        <div className={styles.optionsBox}>
          <div className={styles.optionsContainer}>
            {listItems.map(item => {
              const isSelectedStatus = currentValue === item.name;
              return (
                <div
                  className={styles.optionsItem}
                  onClick={() => {
                    !isSelectedStatus && onItemClick(item.value);
                  }}
                >
                  <div
                    className={classNames(isSelectedStatus && styles.selected)}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {changeHubOpen && (
        <ActionModal
          isOpen={selectedAction}
          selectedBatchAction={selectedAction}
          batchOptionsHash={batchOptionsHash}
          selections={vehicleList}
          closeModal={close}
        />
      )}
      {changeStatusOpen && (
        <ActionModal
          isOpen={selectedAction}
          selectedBatchAction={selectedAction}
          batchOptionsHash={batchOptionsHash}
          selections={vehicleList}
          closeModal={close}
        />
      )}
      <CommandConfirm
        open={modalOpen}
        close={toggleModal}
        confirm={sendCommand}
        action={selectedAction}
      />
      <MessageBox
        open={msgOpen}
        close={toggleMsgModal}
        action={selectedAction}
        vehicleUUID={vehicleUUID}
        vehicleStatus={vehicleStatus}
        sendCommand={sendCommand}
      />
    </Fragment>
  );
};

export default ListBox;
