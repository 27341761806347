import React, { useState } from "react";
import styles from "../damages.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";

const TagInput = ({ tags, triggerAddTag, triggerRemoveTag }) => {
  const [curTag, setCurTag] = useState();

  const triggerTag = (e) => {
    setCurTag(e.target.value);
  };

  const clearInput = () => {
    setCurTag("");
  };

  const addable = curTag !== undefined && curTag !== "";

  return (
    <div className={styles.field} style={{ margin: "12px 0" }}>
      <div className={styles.subscript}>Tags</div>
      <div className={styles.tagInputContainer}>
        <input
          className={styles.tagInput}
          onChange={triggerTag}
          value={curTag}
        />
        <div
          className={styles.addTagButton}
          style={{ opacity: addable ? 1 : 0.5 }}
          onClick={() => {
            addable && triggerAddTag(curTag);
            addable && clearInput();
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add
        </div>
      </div>
      <div className={styles.tagsBox}>
        {tags &&
          tags.map((tag) => (
            <div className={styles.tempTagContainer}>
              {tag}
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                className={styles.tagTimes}
                onClick={() => {
                  triggerRemoveTag(tag);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TagInput;
