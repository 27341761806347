import React from "react";
import classNames from "classnames";
import styles from "./checkBox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

const CheckBox = ({ onClick, isSelected }) => (
  <FontAwesomeIcon
    icon={isSelected ? faCheckSquare : faSquareFull}
    className={classNames(
      styles.checkBox,
      isSelected && styles.checkBoxSelected
    )}
    onClick={evt => {
      onClick();
      evt.preventDefault();
    }}
  />
);

export default CheckBox;
