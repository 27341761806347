import React, { Component } from "react";
import { observer } from "mobx-react";
import Vehicle from "./Vehicle";
import VehicleList from "./VehicleList";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { sortVehicles, formatNavTitle } from "../../../utils";

const VehicleData = observer(
  class VehicleData extends Component {
    constructor() {
      super();
      this.state = {
        type: "vehiclePriority",
        descending: false,
        display: "notes",
      };
    }

    componentDidMount() {
      const { store, history, resetHistoryMode } = this.props;
      store.changeNavigatedPage(
        formatNavTitle(
          store.selectedVehicle !== "none" ? store.selectedVehicle : null,
          history.location.pathname,
          null
        )
      );
    }

    reorder = (newType) => {
      const { type, descending } = this.state;
      if (newType === type) {
        this.setState({ descending: !descending });
      } else {
        this.setState({ type: newType, descending: true });
      }
    };

    toggleDisplay = (display) => this.setState({ display });

    render() {
      const { type, descending, display, hideLocation } = this.state;
      const {
        store,
        filteredCars,
        open,
        data,
        classes,
        openDrawer,
        closeDrawer,
        openFilter,
        resetHistoryMode,
        tierList,
        carStatusList,
        locationList,
        hideLoc,
        toggleLoc,
        history,
      } = this.props;

      const vehicles = filteredCars ? filteredCars : data.findCars;

      return (
        <div
          className={
            store.selectedVehicle === "none" && hideLoc
              ? classes.rootHideLoc
              : store.selectedVehicle === "none"
              ? classes.root
              : store.selectedLocation
              ? classes.rootSelectLoc
              : classes.rootVehicle
          }
        >
          {store.selectedVehicle === "none" ? (
            <VehicleList
              findCars={sortVehicles(vehicles, descending, type)}
              store={store}
              bStore={store}
              open={open}
              openDrawer={openDrawer}
              closeDrawer={closeDrawer}
              type={type}
              descending={descending}
              reorder={this.reorder}
              openFilter={openFilter}
              tierList={tierList}
              carStatusList={carStatusList}
              locationList={locationList}
              hideLoc={hideLoc}
              history={history}
            />
          ) : (
            <div className={classes.open}>
              {
                // <IconButton
                //   onClick={() => {
                //     store.changeMode("current");
                //     store.changeSelectedVehicle("none");
                //     resetHistoryMode();
                //   }}
                //   className={classNames("close-list", !open && classes.hide)}
                // >
                //  <ArrowBack />
                // </IconButton>
              }

              <Vehicle
                vehicleId={store.selectedVehicle.id}
                store={store}
                bStore={store}
                toggleDisplay={this.toggleDisplay}
                display={display}
              />
            </div>
          )}
        </div>
      );
    }
  }
);

const styles = (theme) => {
  return {
    hide: { display: "none" },
    root: {
      position: "absolute",
      top: "110px",
      height: "calc(100vh - 110px)",
      width: "444px",
      backgroundColor: "#f2f2f2",
    },
    rootVehicle: {
      position: "absolute",
      top: "36px",
      height: "calc(100vh - 36px)",
      width: "444px",
      backgroundColor: "#f2f2f2",
    },
    rootSelectLoc: {
      position: "absolute",
      top: "172px",
      height: "calc(100vh - 172px)",
      width: "444px",
      backgroundColor: "#f2f2f2",
    },
    open: {
      width: "444px",
      backgroundColor: "#f2f2f2",
    },
    rootHideLoc: {
      position: "absolute",
      top: "110px",
      height: "calc(100vh - 110px)",
      width: "344px",
      backgroundColor: "#f2f2f2",
    },
  };
};

export default withStyles(styles)(VehicleData);
