import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";

import "../../Vehicle/vehicle.scss";

class ParkingPassInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkingPass: this.props.parkingPass
    };
  }

  render() {
    const { parkingPass } = this.state;
    const {
      vehicleId,
      refetch,
      toggleEdit,
      classes,
      plate,
      type,
      updateEdit,
      editParams,
      details
    } = this.props;

    return (
      <div className="lockbox" style={details ? { textAlign: "left" } : null}>
        <Input
          className={
            parkingPass !== this.props.parkingPass
              ? classes.parkingPassEdit
              : classes.parkingPassInput
          }
          classes={{ underline: classes.underline }}
          type="text"
          autoFocus={details ? false : true}
          value={parkingPass}
          onChange={e => {
            this.setState({ parkingPass: e.target.value });
          }}
          onBlur={evt => {
            if (parkingPass !== this.props.parkingPass) {
              editParams(Number(vehicleId), type, parkingPass);
              updateEdit(plate, type, parkingPass);
            }
          }}
          style={details ? { width: "200px" } : null}
        />
      </div>
    );
  }
}

const styles = theme => ({
  parkingPassInput: {
    width: "100px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  parkingPassEdit: {
    width: "100px",
    color: "#128DFF",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  parkingPassError: {
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  }
  // underline: {
  //   "&:after": {
  //     borderBottom: `2px solid ${theme.palette.blue}`,
  //   },
  // },
});

export default withStyles(styles)(ParkingPassInput);
