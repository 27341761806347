import React, { Fragment } from "react";
import classNames from "classnames";
import EditBox from "./EditBox";

const ParkingSpot = ({
  cellData,
  classes,
  isEditModeActivated,
  modifyEdits,
  rowId,
  columnName,
  editedValue
}) => {
  const text = cellData.text;
  const onChange = newValue =>
    modifyEdits(rowId, {
      [columnName]: { newValue: newValue, oldValue: text }
    });

  return (
    <div
      className={classNames(classes)}
      onClick={event => event.preventDefault()}
    >
      {isEditModeActivated ? (
        <EditBox onChange={onChange} value={text} editedValue={editedValue} />
      ) : (
        <div>{text || "--"}</div>
      )}
    </div>
  );
};

export default ParkingSpot;
