import React, { useState, Fragment } from "react";
import styles from "../../../batchActions.module.scss";
import Button from "../../../../../../artzu/src/Button";
import ManageAllTagsModal from "./ManageAll";

const ManageTags = ({
  selected,
  toggleManageTags,
  close,
  manage,
  refetchAddTag,
}) => {
  const [enterFromAddTag, setEnterFromAddTag] = useState(false);

  const toggleEntry = () => {
    setEnterFromAddTag(true);
  };

  return (
    <div className={styles.darkText}>
      {selected.text}
      <Button
        fullWidth="140px"
        height="34px"
        radius="17px"
        border="none"
        color="#414141"
        backColor="#FFFFFF"
        padding="0 13px 0 13px"
        label={<div className={styles.manageAllButton}>Manage all tags</div>}
        onClick={() => {
          toggleManageTags();
          toggleEntry();
        }}
      />
      <ManageAllTagsModal
        open={manage}
        close={close}
        parentCallbackClose={toggleManageTags}
        refetchAddTag={refetchAddTag}
        entry={enterFromAddTag}
      />
    </div>
  );
};

export default ManageTags;
