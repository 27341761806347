import React from "react";
import styles from "../../InformationTable/ActionBar/MiscSettings/Menu/menu.module.scss";
import classesNames from "classnames";

const OptionMenu = ({
  isOpen,
  editTask,
  deleteTask,
  setCurAction,
  backlogTask,
  resolveTask,
  taskData,
}) => {
  const taskOptions = [
    taskData &&
      taskData.status === "in_progress" && {
        onClick: backlogTask,
        name: "Move to Backlog",
      },
    taskData &&
      taskData.status === "backlog" && {
        onClick: resolveTask,
        name: "Mark as resolved",
      },
    {
      onClick: editTask,
      name: "Edit",
    },
    taskData &&
      taskData.status !== "deleted" && {
        onClick: deleteTask,
        name: "Delete",
      },
  ];

  const options = taskOptions;

  return (
    <div
      className={classesNames(
        styles.absoluteContainer,
        !isOpen && styles.hidden
      )}
    >
      <div className={styles.menuUp}>
        {options.map((option) => (
          option?
          <div
            onClick={() => {
              option.onClick();
              option && setCurAction(option.name);
            }}
            className={styles.menuOption}
          >
            {option && option.name}
          </div>
        :null
        ))}
      </div>
    </div>
  );
};

export default OptionMenu;
