import React, { useState } from "react";
import { Fragment } from "react";
import CurrentDTCModal from "../DTC/CurrentDTCModal";
import PastDTCModal from "../DTC/PastDTCModal";

const HeaderTop = ({ vehicle }) => {
  const [isCurOpen, setIsCurOpen] = useState();
  const [isPastOpen, setIsPastOpen] = useState();

  const toggleCurModal = () => {
    setIsCurOpen(!isCurOpen);
  };

  const closeCurModal = () => {
    setIsCurOpen(false);
  };

  const togglePastModal = () => {
    setIsPastOpen(!isPastOpen);
  };

  const closePastModal = () => {
    setIsPastOpen(false);
  };

  const currentDTCInfo = vehicle.currentDiagnosticTroubleCodes;
  const pastDTCInfo = vehicle.pastDiagnosticTroubleCodes;

  return (
    <Fragment>
      <div className="dtc-car">
        <div className="dtc-info">
          Current DTCs
          <span className="not-applicable"> {currentDTCInfo.length} </span>
          <span className="viewDetails" onClick={toggleCurModal}>
            View details
          </span>
          <br />
          Past DTCs
          <span className="not-applicable">
            {" "}
            {vehicle.pastDiagnosticTroubleCodes.length}{" "}
          </span>
          <span className="viewDetails" onClick={togglePastModal}>
            View details
          </span>
        </div>
        <img
          className="display-car-photo"
          src={vehicle.imageLink}
          alt="Vehicle"
        />
      </div>
      <CurrentDTCModal
        open={isCurOpen}
        close={closeCurModal}
        currentDTCInfo={currentDTCInfo}
      />
      <PastDTCModal
        open={isPastOpen}
        close={closePastModal}
        pastDTCInfo={pastDTCInfo}
      />
    </Fragment>
  );
};

export default HeaderTop;
