import React, { useState } from "react";
import styles from "./optionsBody.module.scss";
import classNames from "classnames";
import TextField from "../../../../../../artzu/src/TextField";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBox from "../../../../Shared/CheckBox";

const Presets = ({
  presets,
  selectedPreset,
  selectPreset,
  presetMode,
  beginEditPreset,
  beginCreatePreset,
  setTempPresetValue,
  tempPresetValue,
  deletePreset
}) => {
  return (
    <div className={classNames(styles.column, styles.marginRight)}>
      <div className={styles.columnHeader}>
        <div className={styles.columnHeaderText}>Presets</div>
        {presetMode === "default" && selectedPreset && (
          <div className={styles.editButton} onClick={beginEditPreset}>
            Edit
          </div>
        )}
      </div>
      <div className={styles.columnBody}>
        {presets.map(preset => {
          const isSelected =
            selectedPreset && selectedPreset.uuid === preset.uuid;
          const isDisabled = presetMode !== "default";
          return (
            <div className={classNames(styles.presetOption)}>
              <CheckBox
                onClick={() => !isDisabled && selectPreset(preset)}
                isSelected={isSelected}
              />
              {presetMode === "edit" && isSelected ? (
                <div className={styles.name}>
                  <TextField
                    onChange={({ target: { value } }) =>
                      setTempPresetValue(value)
                    }
                    width={"100%"}
                    value={tempPresetValue}
                  />
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={styles.deleteButton}
                    onClick={() => deletePreset(preset)}
                  />
                </div>
              ) : (
                <div className={styles.name}>{preset.name}</div>
              )}
            </div>
          );
        })}
        {presetMode === "create" && (
          <div className={classNames(styles.presetOption)}>
            <CheckBox onClick={() => {}} isSelected />
            <div className={styles.name}>
              <TextField
                onChange={({ target: { value } }) => setTempPresetValue(value)}
              />
            </div>
          </div>
        )}
        {presetMode === "default" && !selectedPreset && (
          <div className={styles.saveNewPreset} onClick={beginCreatePreset}>
            Add Preset
          </div>
        )}
      </div>
    </div>
  );
};

export default Presets;
