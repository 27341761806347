import React from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  ListItem,
  Input,
  ListItemText,
  FormControl,
  InputAdornment,
  FormHelperText
} from "@material-ui/core/";

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%"
  },
  treadForm: {
    width: "3em"
  },
  tireDepth: {
    display: "flex",
    flexDirection: "column"
  },
  treadDepthTitle: {
    padding: "1em 0px"
  }
});

const TireCheckupList = ({ classes, data, onEdit }) => (
  <ListItem className={classes.tireDepth}>
    <ListItemText className={classes.treadDepthTitle} primary={"Tread Depth"} />
    <div className={classes.container}>
      <div className={classes.column}>
        <FormControl className={""} aria-describedby="weight-helper-text">
          <Input
            className={classes.treadForm}
            value={data.treadDepth[0]}
            onChange={event => onEdit(0, event.target.value, true)}
            endAdornment={<InputAdornment position="end">/32</InputAdornment>}
            inputProps={{
              "aria-label": "Tread Depth"
            }}
          />
          <FormHelperText id="weight-helper-text">Front Left</FormHelperText>
        </FormControl>
        <FormControl className={""} aria-describedby="weight-helper-text">
          <Input
            className={classes.treadForm}
            value={data.treadDepth[3]}
            onChange={event => onEdit(3, event.target.value, true)}
            endAdornment={<InputAdornment position="end">/32</InputAdornment>}
            inputProps={{
              "aria-label": "Tread Depth"
            }}
          />
          <FormHelperText id="weight-helper-text">Back Left</FormHelperText>
        </FormControl>
      </div>
      <img
        className="vehicle-top-view-image"
        src="https://s3.us-east-2.amazonaws.com/autzu-team/cars/photos/topView/top-view-vehicle.png"
        alt="top-view"
      />
      <div className={classes.column}>
        <FormControl className={""} aria-describedby="weight-helper-text">
          <Input
            className={classes.treadForm}
            value={data.treadDepth[1]}
            onChange={event => onEdit(1, event.target.value, true)}
            endAdornment={<InputAdornment position="end">/32</InputAdornment>}
            inputProps={{
              "aria-label": "Tread Depth"
            }}
          />
          <FormHelperText id="weight-helper-text">Front Right</FormHelperText>
        </FormControl>
        <FormControl className={""} aria-describedby="weight-helper-text">
          <Input
            className={classes.treadForm}
            value={data.treadDepth[2]}
            onChange={event => onEdit(2, event.target.value, true)}
            endAdornment={<InputAdornment position="end">/32</InputAdornment>}
            inputProps={{
              "aria-label": "Tread Depth"
            }}
          />
          <FormHelperText id="weight-helper-text">Back Right</FormHelperText>
        </FormControl>
      </div>
    </div>
  </ListItem>
);

export default withStyles(styles)(TireCheckupList);
