import React, { useState } from "react";
import styles from "./batchActions.module.scss";
import {
  faCarAlt,
  faCommentAlt,
  faMapMarkedAlt,
  faTag,
} from "@fortawesome/pro-light-svg-icons";
import ActionButton from "./ActionButton";
import ActionModal from "./ActionModal";
import _ from "lodash";

const BatchActions = ({ isOpen, selections, removeSelection, refetch }) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState();
  const icons = [
    {
      value: "addNote",
      text: "Add Note",
      icon: faCommentAlt,
    },
    {
      value: "changeHub",
      text: "Change Hub",
      icon: faMapMarkedAlt,
    },
    {
      value: "addTag",
      text: "Add Tag",
      icon: faTag,
    },
    // {
    //   value: "changeStatus",
    //   text: "Change Status",
    //   icon: faCarAlt,
    // },
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const closeModal = () => setSelectedBatchAction(null);

  return (
    <>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {icons.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                // onClick={() => {}}
                onClick={() => setSelectedBatchAction(icon.value)}
              />
            ))}
          </div>
          <ActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selections={selections}
            removeSelection={removeSelection}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            item="Vehicles"
          />
        </div>
      )}
    </>
  );
};

export default BatchActions;
