import { AvcDevice, LockboxCode, ParkingSpot } from "./CellValues";

import _ from "lodash";
import { m } from "../../utils/";
import moment from "moment";
import TextValue from "./CellValues/Base/TextValue";
import ImageValue from "./CellValues/Base/ImageValue";
import StatusValue from "./CellValues/Base/StatusValue";
import ExpandableList from "./CellValues/Base/ExpandableList";
import BubbleTextValue from "./CellValues/Base/BubbleTextValue";
import BooleanSentryModeValue from "./CellValues/Base/BooleanSentryModeValue";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor].value,
    };
  };
};

const baseBooleanProcessor = (accessor) => {
  return (data) => {
    console.log(data, accessor);
    const value = data[accessor].value;

    const color = value == "true" ? "#3dc700" : "red";
    const text = value == "true" ? "Yes" : "No";

    return {
      color,
      text,
      isSolid: true,
    };
  };
};

const basePercentageProcessor = (accessor) => {
  return (data) => {
    return {
      text: `${data[accessor].value}%`,
    };
  };
};

const baseDateProcessor = (accessor, timeZone) => {
  return (data) => {
    const value = data[accessor].value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("MMM D, YYYY")
        : "N/A",
      subtext: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("h:mm:ss A z")
        : "N/A",
    };
  };
};

const serviceEventToStatus = (serviceEvent) => {
  const { date, daysUntil, status, mileageUntil } = serviceEvent;
  const prefix = daysUntil < 0 ? "Overdue by" : "Due in";
  const absDaysUntil = Math.abs(daysUntil);
  switch (status) {
    case 1:
      return {
        text: "Good",
        // color: "#63ca9c",
        color: "green",
        subtext: `${prefix} ${absDaysUntil} days`,
        isSolid: true,
      };
    case 2:
      return {
        text: "Warning",
        // color: "#f5a522",
        color: "orange",
        subtext: `${prefix} ${absDaysUntil} days`,
        isSolid: true,
      };
    case 3:
      return {
        text: "Overdue",
        // color: "#fb7272",
        color: "red",
        subtext: `${prefix} ${absDaysUntil} days`,
        isSolid: true,
      };
  }
};

const pendingServiceToText = (pendingService) => {
  const { name, daysUntil, mileageUntil } = pendingService;
  const prefix = daysUntil < 0 ? "Overdue by" : "In";
  return {
    text: name,
    subtext: `${prefix} ${Math.abs(daysUntil)} days / ${Math.abs(
      mileageUntil
    )} kms`,
  };
};

const operationStatusToStyles = (operationStatus) => {
  switch (operationStatus) {
    case "service":
      return {
        isSolid: true,
        color: "#414141",
      };
    case "available":
      return {
        isSolid: false,
        color: "#128DFF",
      };
    case "deactivated":
      return {
        color: "#FF0000",
        isSolid: false,
      };
    case "unauthorized":
    case "late_driver":
      return {
        color: "#FF0000",
        isSolid: true,
      };
    case "with_driver":
      return {
        isSolid: true,
        color: "#128DFF",
      };
    case "offline":
      return {
        isSolid: false,
        color: "#414141",
      };
    default:
      return {};
  }
};

const rowComponents = (timeZone) => ({
  uberActivePercentage: {
    component: TextValue,
    dataProcessor: basePercentageProcessor("uberActivePercentage"),
  },
  lyftActivePercentage: {
    component: TextValue,
    dataProcessor: basePercentageProcessor("lyftActivePercentage"),
  },
  status: {
    component: StatusValue,
    dataProcessor: (data) => {
      const value = data["operationStatus"].value;
      return {
        text: _.startCase(value),
        ...operationStatusToStyles(value),
      };
    },
  },
  model: {
    component: ImageValue,
    dataProcessor: (data) => {
      const imageLink = data["imageLink"] && data["imageLink"].value;
      const make = data["make"] && data["make"].value;
      const model = data["model"] && data["model"].value;

      return {
        imageLink: imageLink,
        subtext: `${make} ${model}`,
      };
    },
  },
  plate: {
    component: TextValue,
    dataProcessor: baseTextProcessor("plate"),
  },
  year: {
    component: TextValue,
    dataProcessor: baseTextProcessor("year"),
  },
  parkingSpot: {
    component: ParkingSpot,
    dataProcessor: baseTextProcessor("parkingSpotIdentifier"),
  },
  odometer: {
    component: TextValue,
    dataProcessor: baseTextProcessor("odometerReading"),
  },
  vin: {
    component: TextValue,
    dataProcessor: baseTextProcessor("vin"),
  },
  hasInstalledAvcDevice: {
    component: StatusValue,
    dataProcessor: (data) => {
      const hasDeviceInstalled =
        data["hasAvcDeviceInstalled"] && data["hasAvcDeviceInstalled"].value;

      const color = hasDeviceInstalled == "true" ? "#3dc700" : "red";
      const text = hasDeviceInstalled == "true" ? "Yes" : "No";

      return {
        color,
        text,
        isSolid: true,
      };
    },
  },
  gloveboxCode: {
    component: LockboxCode,
    dataProcessor: baseTextProcessor("gloveboxCode"),
  },
  tier: {
    component: TextValue,
    dataProcessor: (data) => {
      const value = data["fleetName"].value;
      return {
        text: _.upperCase(value),
      };
    },
  },
  parkingLot: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingLotName"),
  },
  lastMoved: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastMoved", timeZone),
  },
  statusStart: {
    component: TextValue,
    dataProcessor: baseDateProcessor("statusStart", timeZone),
  },
  statusEnd: {
    component: TextValue,
    dataProcessor: baseDateProcessor("statusEnd", timeZone),
  },
  daysInStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("daysInStatus"),
  },
  vehicleAssigned: {
    component: StatusValue,
    dataProcessor: baseBooleanProcessor("assigned"),
  },
  lastInspected: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastInspected", timeZone),
  },
  serviceStatus: {
    component: StatusValue,
    dataProcessor: (data) => {
      const value = data["mostPressingMaintenancePrediction"].value;
      if (value) {
        return {
          ...serviceEventToStatus(value),
        };
      }
    },
  },
  nextServiceEvent: {
    component: TextValue,
    dataProcessor: baseTextProcessor("nextServiceEventName"),
  },
  maintenancePredictions: {
    component: ExpandableList,
    dataProcessor: (data) => {
      const listData = data["maintenancePredictions"].value;
      const mappedListData = (listData || []).map((data) =>
        pendingServiceToText(data)
      );
      return {
        listComponent: TextValue,
        listData: mappedListData,
      };
    },
  },
  diagnosticTroubleCodes: {
    component: ExpandableList,
    dataProcessor: (data) => {
      const listData = data["diagnosticTroubleCodes"].value;
      if (listData) {
        const mappedListData = listData.map((data) => ({
          text: data.code,
          subtext: data.meaning,
        }));
        return {
          listComponent: TextValue,
          listData: mappedListData,
        };
      } else {
        return {
          listComponent: TextValue,
          listData: [],
        };
      }
    },
  },
  tags: {
    component: ExpandableList,
    dataProcessor: (data) => {
      const listData = data["tags"].value;
      if (listData) {
        const mappedListData = listData.map((data) => ({
          text: data.name,
        }));
        return {
          listComponent: BubbleTextValue,
          listData: mappedListData,
        };
      } else {
        return {
          listComponent: BubbleTextValue,
          listData: [],
        };
      }
    },
  },
  isImmobilized: {
    component: StatusValue,
    dataProcessor: (data) => {
      const isImmobilized =
        data["isImmobilized"] && data["isImmobilized"].value;

      const color = isImmobilized == "true" ? "#3dc700" : "red";
      const text = isImmobilized == "true" ? "Yes" : "No";

      return {
        color,
        text,
        isSolid: true,
      };
    },
  },
  cleanliness: {
    component: TextValue,
    dataProcessor: baseTextProcessor("lastCleanlinessRating"),
  },
  battery: {
    component: TextValue,
    dataProcessor: baseTextProcessor("gasReading"),
  },
  lastCleaned: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastCleaned", timeZone),
  },
  sentryMode: {
    component: BooleanSentryModeValue,
    dataProcessor: baseTextProcessor("sentryMode"),
  },
  apiVersion: {
    component: TextValue,
    dataProcessor: baseTextProcessor("apiVersion"),
  },
});

export { rowComponents };
