import React, { Fragment } from "react";
import Report from "./Report";
import "./damage.scss";

const ReportList = ({
	reports,
	addToResolve,
	alterDamageState,
	removeFromResolve,
	exterior
}) => (
	<Fragment>
		{reports.length ? (
			reports.map((report, index) => (
				<Report
					alterDamageState={alterDamageState}
					exterior={exterior}
					key={report.id}
					id={report.id}
					index={index + 1}
					color="#2190fb"
					location={report.location}
					description={report.description}
					createdAt={report.createdAt}
					addToResolve={addToResolve}
					removeFromResolve={removeFromResolve}
				/>
			))
		) : (
			<h4>{`No ${
				exterior ? "exterior" : "interior"
			} damages reported for this vehicle.`}</h4>
		)}
	</Fragment>
);

export default ReportList;
