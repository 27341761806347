import React from "react";
import Linkify from "react-linkify";
import styles from "../../task.module.scss";

const NormalMessage = ({ msg, setCurUrl, toggleImg, currentAdmin }) => {
  const isCurrentAdmin = msg.senderName === currentAdmin.fullName;
  const sentBy = isCurrentAdmin ? "You" : msg.senderName;

  return msg.label === "image" ? (
    <div
      className={
        msg.direction === "outgoing"
          ? styles.messageTagContainer
          : styles.messageTagContainerLeft
      }
    >
      <img
        src={msg.text}
        className={styles.imageMsg}
        onClick={() => {
          setCurUrl(msg.text);
          toggleImg();
        }}
      ></img>
      <span className={styles.bySender}>
        <span style={{ fontWeight: 700 }}>By:</span>{" "}
        {isCurrentAdmin ? "You" : msg.senderName}
      </span>
    </div>
  ) : msg.label === "normal" ? (
    <div
      className={
        msg.direction === "outgoing"
          ? styles.messageTagContainer
          : styles.messageTagContainerLeft
      }
    >
      <div
        className={
          msg.direction === "outgoing"
            ? styles.messageTagOutgoing
            : styles.messageTag
        }
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <span style={{ whiteSpace: "pre-line" }}>{msg.text}</span>
        </Linkify>
      </div>
      <span className={styles.bySender}>
        <span style={{ fontWeight: 700 }}>By:</span>{" "}
        {sentBy ? sentBy : "Autzu Robot"}
      </span>
    </div>
  ) : null;
};

export default NormalMessage;
