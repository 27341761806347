import React from 'react'
import styled, { keyframes } from 'styled-components'
import { errorAnimation } from './textFieldHelpers.js'

const error = () => keyframes`${errorAnimation()}`

const StyledErrors = styled.p`
	font-size: 85%;
	color: red;
	opacity: 1;
	margin: 0.5em auto;
	animation: ${error} 0.5s;
`

const ErrorText = ({ error, ...props }) => <StyledErrors>{error}</StyledErrors>

export default ErrorText
