import React from "react";
import styles from "./options.module.scss";
import ButtonGroup from "../../../Shared/ButtonGroup";

const Footer = ({
  leftButtonText,
  leftButtonFunction,
  rightButtonText,
  rightButtonFunction
}) => (
  <div className={styles.footerContainer}>
    <ButtonGroup
      leftButtonText={leftButtonText}
      leftButtonFunction={leftButtonFunction}
      rightButtonText={rightButtonText}
      rightButtonFunction={rightButtonFunction}
    />
  </div>
);

export default Footer;
