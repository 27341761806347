import React from "react";
import HardwareInfo from "./HardwareInfo";
import TagsInfo from "./TagsInfo";
import VehicleInfo from "./VehicleInfo";

const VehicleInfoContent = ({
  isEdit,
  toggleEdit,
  formatOdometer,
  city,
  refetch,
  redirectAddTag,
  vehicle,
  toggleAddTag,
}) => {
  return (
    <div className="vehicleContainerDetails">
      <div className="details-upper">
        <VehicleInfo
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          formatOdometer={formatOdometer}
          city={city}
          refetch={refetch}
          vehicle={vehicle}
        />
        <TagsInfo
          redirectAddTag={redirectAddTag}
          vehicle={vehicle}
          toggleAddTag={toggleAddTag}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default VehicleInfoContent;
