import React from "react";
import PinNote from "../../../../../Mutations/PinNote";

const NotePin = ({ note, refetch }) => {
  return (
    <div className="pin-note">
      <PinNote
        refetch={refetch}
        pinNoteParams={{
          pinned: note.pinned || false,
          noteId: parseInt(note.id),
        }}
        noteId={note.id}
        note={note}
      />
    </div>
  );
};

export default NotePin;
