import React, { useEffect } from "react";
import Modal from "../../../artzu/src/Modal";
import MessageBoxContent from "./MessageBoxCotent";

const MessageBox = ({
  open,
  close,
  action,
  vehicleUUID,
  vehicleStatus,
  sendCommand,
  toggleRefresh,
  toggleModal,
}) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <MessageBoxContent
        close={close}
        action={action}
        vehicleUUID={vehicleUUID}
        vehicleStatus={vehicleStatus}
        sendCommand={sendCommand}
        toggleRefresh={toggleRefresh}
        toggleModal={toggleModal}
      />
    </Modal>
  );
};

export default MessageBox;
