import { normalizeCoords } from "../helperFunctions.js";
import {
	rearBumper,
	rearTrunk,
	rearDriverTaillight,
	rearPassengerTaillight,
	rearWindshield,
	rearWipers,
	rearPlate,
	rearDriverSideMirror,
	rearPassengerSideMirror
} from "../../CoordArrays/Dodge/rearView";

const rearAreas = (width, height) => {
	return [
		{
			name: "Rear Bumper",
			shape: "poly",
			coords: normalizeCoords(rearBumper, width, height)
		},
		{
			name: "Rear License Plate",
			shape: "poly",
			coords: normalizeCoords(rearPlate, width, height)
		},
		{
			name: "Driver Side Taillight",
			shape: "poly",
			coords: normalizeCoords(rearDriverTaillight, width, height)
		},
		{
			name: "Passenger Side Taillight",
			shape: "poly",
			coords: normalizeCoords(rearPassengerTaillight, width, height)
		},
		{
			name: "Rear Wipers",
			shape: "poly",
			coords: normalizeCoords(rearWipers, width, height)
		},
		{
			name: "Rear Window",
			shape: "poly",
			coords: normalizeCoords(rearWindshield, width, height)
		},
		{
			name: "Trunk",
			shape: "poly",
			coords: normalizeCoords(rearTrunk, width, height)
		},
		{
			name: "Driver Side Mirror",
			shape: "poly",
			coords: normalizeCoords(rearDriverSideMirror, width, height)
		},
		{
			name: "Passenger Side Mirror",
			shape: "poly",
			coords: normalizeCoords(rearPassengerSideMirror, width, height)
		}
	];
};

export { rearAreas };
