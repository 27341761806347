import React, { useEffect, useState } from "react";
import Dropdown from "../../../InformationTable/Shared/Dropdown";

const EditableSpot = ({
  parkingSpot,
  handleSpotChange,
  availableParkingSpots,
}) => {
  const [curSpot, setCurSpot] = useState(parkingSpot);

  const onSpotChange = (e) => {
    setCurSpot(e);
    handleSpotChange(e);
  };

  return availableParkingSpots && availableParkingSpots.length !== 0 ? (
    <div className="parking-input-box">
      <Dropdown
        initialValue={curSpot}
        items={availableParkingSpots}
        onChange={onSpotChange}
      />
    </div>
  ) : (
    <div>No spots available at this hub location</div>
  );
};

export default EditableSpot;
