import React from "react";
import { useState } from "react";
import templateUpload from "../../../images/icons/template.svg";
import { Fragment } from "react";
import TemplateModal from "./Templates/TemplateModal";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import styles from "../task.module.scss";
import { GET_ALL_TEMPLATE, HANDLE_SEND_IMAGE } from "../queries";
import { directUploadFile } from "../../../utils/FileUploads";
import Loading from "../../InformationTable/Loading";
import UploadImage from "./UploadImage";

const InputContent = ({
  handleSend,
  tab,
  taskData,
  refreshTaskCallback,
  assignTask,
}) => {
  const [data, setData] = useState("");
  const [imgName, setImgName] = useState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [file, setFile] = useState(null);
  const [isSendingImage, setIsSendingImage] = useState();

  const handleChange = (e) => {
    setData(e.target.value);
  };
  const clearInput = (e) => {
    // e.preventDefault();
    setData("");
  };

  const onKeyPress = (evt) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      evt.preventDefault();
      ableToSend && clearInput();
      ableToSend && handleSend(data);
      taskData?.status === "unassigned" && assignTask();
      refreshTaskCallback({ taskId: taskData.id });
    }
  };

  const client = useApolloClient();
  const [
    sendImageMutation,
    { loading: isSendingImageLoading, error },
  ] = useMutation(HANDLE_SEND_IMAGE);
  const handleImageSending = () => {
    setIsSendingImage(true);
    file.map((file) =>
      directUploadFile(file, client).then((result) => {
        console.log(result);
        sendImageMutation({
          variables: {
            taskId: taskData?.id,
            fileBlob: result,
          },
        }).then(({ data: { sendImageMessage } }) => {
          if (sendImageMessage.success) {
            handleCancel();
            setIsSendingImage(false);
            console.log("Send Image Succeed!");
          } else {
            setIsSendingImage(false);
            console.log("Send image error!");
          }
        });
      })
    );
  };

  const { data: templateData, loading, refetch } = useQuery(GET_ALL_TEMPLATE);

  const toggleTemplate = () => {
    setIsTemplate(!isTemplate);
  };

  const ableToSend = data || file;

  const handleCancel = () => {
    setFile(null);
    setImgName(null);
  };

  const isInputBoxDisabled = () => {
    if (taskData?.status === "deleted" || taskData?.status === "resolved") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className={styles.contentContainer}>
        <textarea
          className={styles.inputBox}
          style={{ backgroundColor: tab === "internal" ? "#fceea7" : null }}
          onChange={handleChange}
          placeholder={"Text message here..."}
          disabled={isInputBoxDisabled()}
          value={data}
          onKeyDown={onKeyPress}
        />

        <div className={styles.buttonsRow}>
          <UploadImage setFile={setFile} setImgName={setImgName} />
          <div>
            <img
              src={templateUpload}
              className={styles.templateIcon}
              onClick={!isInputBoxDisabled() && toggleTemplate}
            ></img>
          </div>

          <button
            className={
              ableToSend ? styles.sendMessageButton : styles.sendMessageInvalid
            }
            onClick={() => {
              ableToSend && clearInput();
              file ? handleImageSending() : ableToSend && handleSend(data);
              refreshTaskCallback({ taskId: taskData.id });
            }}
          >
            {isSendingImage ? <Loading size="1x" color="#ffffff" /> : "Send"}
          </button>
        </div>
        <div style={{ marginTop: "-5px" }}>{imgName}</div>
      </div>
      <TemplateModal
        open={isTemplate}
        toggleTemplate={toggleTemplate}
        setData={setData}
        allTemplates={templateData && templateData.getAllTemplates}
        refetch={refetch}
      />
    </Fragment>
  );
};

export default InputContent;
