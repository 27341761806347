import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

import MapIcon from "./MapIcon";
import Toggle from "./Toggle";
import TargetedTime from "./TargetedTime";
import Loading from "./Loading";
import BookingCard from "./BookingCard";
import ModeSelect from "./ModeSelect";

import { withStyles } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import { observer } from "mobx-react";
import classNames from "classnames";
import { formatNavTitle } from "../../utils";
import _ from "lodash";

import "./map.scss";

import { drawPolyLine, getMarkerDetails } from "../../utils/mapFuncs";

const styles = (theme) => ({
  hide: { display: "none" },
  "googleMap-fleet": { height: "100vh" },
  "googleMap-history": { height: "100vh" },
  thumb: { backgroundColor: theme.palette.blue },
  track: { backgroundColor: theme.palette.blue, opacity: "0.5" },
  trackBefore: { background: "none" },
  root: { padding: "16px 16px" },
  targetTime: {
    position: "absolute",
    top: "10px",
    width: "12em",
  },
});

let polylines = [];

let firstLoad = true;

let historyRangeCentered = false;

const GoogleMap = observer(
  class GoogleMap extends Component {
    constructor(props) {
      super(props);
      this.state = {
        center: { lat: 43.6529, lng: -79.3849 },
        polylines: [],
        parking: true,
        centering: false,
        map: null,
        maps: null,
        firstLoad: true,
        markerDetails: [],
      };
    }

    componentWillUnmount() {
      firstLoad = true;
    }

    componentDidMount() {
      this.updateMarkerDetails();
    }

    componentDidUpdate(prevProps) {
      const totalTime = 5;
      const freq = 24;

      if (prevProps !== this.props) {
        const newVehicles = this.props.vehicles;
        this.updateMarkerDetails();
      }
    }

    handleClick = (name) => {
      this.setState({ [name]: !this.state[name] });
    };

    updateMarkerDetails = () => {
      const { vehicles, store, parkingLots, serviceLocations } = this.props;
      const { parking } = this.state;
      const { loaded } = this.props;
      const lots = parking ? parkingLots : [];

      this.setState({
        markerDetails: getMarkerDetails(
          vehicles,
          lots,
          serviceLocations,
          store,
          loaded
        ),
      });
    };
    config = () => {
      const { vehicles, loaded, polylineCoords, store } = this.props;

      store.configureMap(
        vehicles,
        loaded,
        !historyRangeCentered && loaded ? polylineCoords : []
      );

      if (
        store.mode === "history" &&
        store.history.mode === "range" &&
        loaded
      ) {
        historyRangeCentered = true;
      }
    };

    render() {
      const {
        store,
        bStore,
        vehicles,
        polylineCoords,
        classes,
        loaded,
        parkingLots,
        updateSlider,
        sliderValue,
        currentTime,
        updateTime,
        startLoad,
        startTime,
        endTime,
        history,
      } = this.props;

      const { centering, parking, maps, map, markerDetails } = this.state;

      const vehicleSelected = store.selectedVehicle !== "none";

      if (
        store.mode === "history" &&
        store.history.mode === "range" &&
        loaded
      ) {
        polylines.push(drawPolyLine(map, maps, polylineCoords.historyGps));
      } else {
        historyRangeCentered = false;
        polylines.forEach((polyline) => polyline.setMap(null));
        polylines = [];
      }

      // const mapZoom = determineZoom(store, vehicles, loaded);
      // const centralPoint = findCentralPoint(store, vehicles, loaded);

      if (
        map &&
        (centering || store.mode === "history" || firstLoad === true)
      ) {
        this.config();
        if (
          store.history.mode === "single" &&
          store.mode === "history" &&
          firstLoad === true
        ) {
          map.setZoom(17);
        }
        firstLoad = false;
      }

      return (
        <div
          className={classNames(
            "google-map",
            classes[`googleMap-${vehicleSelected ? "history" : "fleet"}`]
          )}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_G_MAPS_KEY }}
            center={{ lat: -79, lng: 43 }}
            zoom={15}
            onClick={() => store.closeDriverDetail()}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              store.updateMapObjs(map, maps);

              this.setState({ map: map, maps: maps });

              map.addListener("zoom_changed", () => {
                store.changeZoom(map.getZoom());
              });

              map.addListener("click", () => {
                store.changeSelectedVehicle("none");
                store.changeSelectedLocation();
                store.changeNavigatedPage(
                  formatNavTitle(null, history.location.pathname, null)
                );
                // store.filterVehicles();
                this.updateMarkerDetails();
              });

              map.addListener("drag", () => {
                this.setState({ centering: false });
              });
            }}
          >
            {map
              ? markerDetails.map((marker) => {
                  if (
                    marker.type === "parking" &&
                    marker.parkingLot?.name === "621 King St. W (B)"
                  ) {
                    return null;
                  } else {
                    return (
                      <MapIcon
                        className="HIIIII"
                        zoom={map.getZoom()}
                        type={marker.type}
                        key={marker.key}
                        id={`icon-${marker.key}`}
                        lat={marker.lat}
                        lng={marker.lng}
                        details={marker.details}
                        icon={marker.icon}
                        heading={marker.heading}
                        store={store}
                        parkingLot={marker.parkingLot}
                        serviceLocation={marker.serviceLocation}
                        vehicle={_.find(
                          vehicles,
                          (vehicle) => vehicle.plate === marker.plate
                        )}
                        op={marker.op}
                        maps={maps}
                        updateMarkerDetails={this.updateMarkerDetails}
                        history={history}
                      />
                    );
                  }
                })
              : null}
          </GoogleMapReact>
          {store.mode === "history" && !loaded ? <Loading /> : null}
          {!(store.mode === "history" && loaded) ? (
            <div className="centering toggle-box">
              <Toggle
                value="centering"
                title="Center"
                handleClick={this.handleClick}
                checked={centering}
              />
            </div>
          ) : null}
          {store.selectedVehicle.plate &&
          store.mode === "current" &&
          ["With Driver", "Service", "User", "Late Driver"].includes(
            store.selectedVehicle.status
          ) ? (
            <BookingCard
              vehicle={_.find(
                vehicles,
                (vehicle) => vehicle.plate === store.selectedVehicle.plate
              )}
              selectedVehicle={store.selectedVehicle}
            />
          ) : null}
          <div className="bottom-box">
            <ModeSelect
              store={store}
              bStore={bStore}
              updateTime={updateTime}
              startLoad={startLoad}
              startTime={startTime}
              endTime={endTime}
              loaded={loaded}
              updateSlider={updateSlider}
              sliderValue={sliderValue}
              currentTime={currentTime}
            />
          </div>
        </div>
      );
    }
  }
);

export default withStyles(styles)(GoogleMap);
