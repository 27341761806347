import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import FleetView from "../Components/MapView/FleetView";

import Loading from "../Components/Fleet/Loading";
// import { merge } from "../utils";
import { spreadMapData } from "../utils";
import { observer } from "mobx-react";
import { coordinatesString } from "./queryStrings";

const Coordinates = observer(
  class Coordinates extends Component {
    componentDidMount() {
      console.log(this.props.history);
    }
    render() {
      const { store, bStore, bookings } = this.props;
      return (
        <Query
          query={gql`
            ${coordinatesString}
          `}
          pollInterval={5000}
        >
          {({ loading, error, data, client }) => {
            const { history } = this.props;
            if (error) {
              console.log(error);
              return null;
            }
            if (loading) {
              return <Loading size="3x" />;
            }
            if (data.getVehiclesGps) {
              store.loadVehicles(spreadMapData(data.getVehiclesGps, bookings));
              return (
                <FleetView
                  bookings={bookings}
                  parkingLots={data.getParkingLots}
                  serviceLocations={data.getServiceLocations}
                  store={store}
                  bStore={bStore}
                  client={client}
                  history={history}
                />
              );
            } else {
              return null;
            }
          }}
        </Query>
      );
    }
  }
);

export default Coordinates;
