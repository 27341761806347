import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Dropdown from "../../../Shared/Dropdown";
import styles from "../../batchActions.module.scss";
import { BULK_ASSIGN_ADMIN, BULK_CHANGE_TASK_STATUS } from "../../queries";

const ChangeStatus = ({
  selections,
  close,
  clearAllSelections,
  adminData,
  reAssign,
}) => {
  const [newStatus, setNewStatus] = useState();
  const [comment, setComment] = useState();

  const triggerStatus = (childData) => {
    setNewStatus(childData);
  };

  const changeComment = (e) => {
    setComment(e.target.value);
  };

  const allStatuses = [
    { name: "In progress", id: "in_progress" },
    { name: "Resolved", id: "resolved" },
    { name: "Backlog", id: "backlog" },
    { name: "Deleted", id: "deleted" },
  ];

  const [changeTaskStatusMutation] = useMutation(BULK_CHANGE_TASK_STATUS);
  const handleStatusChange = () => {
    changeTaskStatusMutation({
      variables: {
        taskIds: selections,
        status: newStatus,
        comment: comment ? comment : null,
      },
    }).then(({ data: { changeTaskStatus } }) => {
      if (changeTaskStatus.success) {
        clearAllSelections();
        close();
        console.log("Assign to Agent Succeed!");
      } else {
        console.log("Assign to Agent error!");
      }
    });
  };

  const applyable = newStatus !== undefined && newStatus !== null;

  return (
    <Fragment>
      <div className={styles.actionHeader}>Change task status</div>
      <span className={styles.subscript}>Status</span>
      <div>
        <div className={styles.searchAddContainer}>
          <Dropdown
            initialValue={newStatus}
            items={allStatuses}
            onChange={triggerStatus}
            width="436px"
            height="30px"
          />
        </div>
      </div>
      <span className={styles.subscript} style={{ marginTop: 24 }}>
        Comment
      </span>
      <textarea
        className={styles.taskTextBox}
        style={{ height: 102 }}
        onChange={changeComment}
      ></textarea>
      <div className={styles.taskModalButtons} style={{ marginTop: "300px" }}>
        <div>
          <button
            className={styles.taskModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.taskModalApplyButton}
            style={{ color: applyable ? "#128dff" : "#87caef" }}
            onClick={() => {
              applyable && handleStatusChange();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangeStatus;
