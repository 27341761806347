import React, { useState } from "react";
import styles from "../../../batchActions.module.scss";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

const AllTagsPanel = ({
  searchTag,
  allVehicleTags,
  parentCallbackEditing,
  toggleDeleteWindow,
  selectItem,
  itemSelected,
}) => {
  const isIncluded = (tag) => {
    return tag.name.toLowerCase().includes(searchTag.toLowerCase());
  };
  return (
    <div className={styles.alltags}>
      {allVehicleTags.map((tag, index) => (
        <div
          className={
            itemSelected.some((a) => a === tag.name)
              ? styles.tagContainerSelected
              : styles.tagContainer
          }
          onClick={() => {
            selectItem(tag.name, tag.attachedToCount);
          }}
        >
          <div
            className={
              itemSelected.includes(tag.name)
                ? styles.checkBoxSelected
                : styles.checkBox
            }
          >
            <FontAwesomeIcon icon={faCheckSquare} size="sm" />
          </div>

          {searchTag ? (
            isIncluded(tag) && (
              <div
                className={
                  itemSelected.includes(tag.name)
                    ? styles.taglist
                    : styles.taglistNoCheckBox
                }
              >
                {tag.name} ({tag.attachedToCount})
              </div>
            )
          ) : (
            <div
              className={
                itemSelected.includes(tag.name)
                  ? styles.taglist
                  : styles.taglistNoCheckBox
              }
            >
              {tag.name} ({tag.attachedToCount})
            </div>
          )}

          <div
            className={
              itemSelected.includes(tag.name)
                ? styles.editButtonSelected
                : styles.editButton
            }
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                icon={faCircle}
                className={styles.editCircle}
                size="lg"
              />
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={styles.editDelete}
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  parentCallbackEditing(tag.name);
                  toggleDeleteWindow();
                }}
              />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AllTagsPanel;
