import React, { Fragment, useState } from "react";
import gql from "graphql-tag";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowFromBottom, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useDropzone } from "react-dropzone";

import Modal from "../../../artzu/src/Modal";
import Button from "../../../artzu/src/Button";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";
import TextField from "../../../artzu/src/TextField";

import Loading from "../UploadDocuments/Loading";
import { useApolloClient } from "@apollo/client";

const UploadTicketModal = ({
  vehicleId,
  toggleAddTickets,
  addTicket,
  refetch
}) => {
  const [title, setTitle] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [otherInput, setOtherInput] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const client = useApolloClient();

  const handleInputChange = val => {
    switch (val) {
      case "other":
        setOtherInput(true);
        setTitle(null);
        break;
      default:
        setOtherInput(false);
        setTitle(val);
    }
  };

  const handleCancel = () => {
    toggleAddTickets();
    setErrors([]);
    setTitle("");
    setExpiryDate(null);
    setFile(null);
    setFilePreview(null);
    setOtherInput(false);
    setStatus("");
    setLoading(false);
  };

  const formValidation = (file, title, status) => {
    const errors = [];
    if (!file) {
      errors.push("File required.");
    }
    if (!title) {
      errors.push("Title can't be blank.");
    }
    if (!status) {
      errors.push("Status can't be blank.");
    }

    return errors;
  };

  const ADD_TICKET_DOCUMENT = gql`
    mutation newTicketDocument($ticket: DocumentAttributes!) {
      newDocument(params: $ticket) {
        success
        errors
      }
    }
  `;

  const handleSubmit = () => {
    const ticket = {
      name: "ticket",
      description: title,
      file,
      vehicleId,
      status,
      expiryDate
    };
    console.log({ ticket });
    const errors = formValidation(file, title, status);
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      setLoading(true);
      client
        .mutate({
          mutation: ADD_TICKET_DOCUMENT,
          context: { hasUpload: true },
          variables: {
            ticket
          }
        })
        .then(res => {
          console.log(res);
          setLoading(false);
          if (res.data.newDocument.success) {
            refetch();
            handleCancel();
          } else {
            setErrors(res.data.newDocument.errors);
          }
        });
    }
  };

  const FileDiv = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        setFile(acceptedFiles[0]);
        setFilePreview(URL.createObjectURL(acceptedFiles[0]));
      }
    });

    return (
      <>
        <div
          className="upload__modal__preview"
          style={
            file
              ? { "background-image": `url(${filePreview})` }
              : { "background-color": "grey", opacity: "0.9" }
          }
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps({ id: "image-upload" })} />
          </div>
        </div>
        <div
          className="upload__modal__preview__icon"
          onClick={() => {
            document.getElementById("image-upload").click();
          }}
        >
          <FontAwesomeIcon
            icon={faArrowFromBottom}
            style={{ color: "##FFFFFF", "margin-left": "14px" }}
            size="2x"
          />
          <div>{file ? "Update" : "Upload"}</div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <Modal open={addTicket} id="upload-modal" width="85vw">
        <div className="upload__modal">
          <FileDiv />
          {loading ? (
            <Loading />
          ) : (
            <div className="upload__modal__inputs">
              <Fragment>
                <div className="upload__modal__inputs--close">
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={handleCancel}
                  />
                </div>
                <div className="upload__modal__inputs__title">
                  <div className="upload__modal__inputs__title--name">
                    Title:
                  </div>
                  <SelectField
                    prompt="Select One"
                    onChange={val => handleInputChange(val)}
                    primaryColor="#414141"
                    className="upload__modal__inputs__title--options"
                    width="17em"
                  >
                    <Option label="Parking" value="parking" />
                    <Option label="Red Light" value="red_light" />
                    <Option label="Speeding" value="speeding" />
                    <Option label="Stop Sign" value="stop_sign" />
                    <Option label="Other" value="other" />
                  </SelectField>
                </div>
                {otherInput ? (
                  <div className="upload__modal__inputs__title--other">
                    <TextField
                      placeholder="Other"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>
                ) : null}
                <div className="upload__modal__inputs__expDate">
                  <div className="upload__modal__inputs__expDate--name">
                    Expiry Date:
                  </div>
                  <DatePicker
                    selected={expiryDate}
                    onChange={e => setExpiryDate(e)}
                    placeholderText="Date"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    className="upload__modal__inputs__expDate--input"
                  />
                </div>
                <div className="upload__modal__inputs__title">
                  <div className="upload__modal__inputs__title--name">
                    Status
                  </div>
                  <SelectField
                    prompt="Status"
                    onChange={val => setStatus(val)}
                    primaryColor="#414141"
                    className="upload__modal__inputs__title--options"
                    width="14.9em"
                  >
                    <Option label="Resolved" value="resolved" />
                    <Option label="Unresolved" value="unresolved" />
                  </SelectField>
                </div>
                {errors.length > 0 ? (
                  <div>
                    {errors.map(error => (
                      <div className="upload__modal__error"> {error}</div>
                    ))}
                  </div>
                ) : null}
              </Fragment>
              <div className="upload__modal__inputs__buttons">
                <Button color="#414141" label="Cancel" onClick={handleCancel} />
                <Button color="#128DFF" label="Add" onClick={handleSubmit} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default UploadTicketModal;
