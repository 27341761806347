import React from "react";
import { capitalize } from "../../utils";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveChanges from "./SaveChanges";
import ServiceEventInputs from "./ServiceEventInputs";

import "./service.css";
import "../Fleet/fleet.css";

import styles from "./service.module.scss";

const ServiceEventModal = ({
  action,
  eventData,
  onChange,
  params,
  refetch,
  clear,
  serviceCatsNames,
  endDateSelected,
  toggleEndDate,
  addErrors,
  errors,
  openConfirmModal
}) => (
  <div className="modal event-modal">
    <div className="modal-top">
      <h2>{capitalize(action)} Service Event</h2>
      <FontAwesomeIcon
        className="blue"
        icon={faTimes}
        size="2x"
        onClick={() => clear()}
      />
    </div>
    <ServiceEventInputs
      data={eventData}
      onEdit={onChange}
      serviceCatsNames={serviceCatsNames}
      action={action}
      endDateSelected={endDateSelected}
      toggleEndDate={toggleEndDate}
    />
    {errors.length > 0 ? (
      <div className={styles.errorContainer}>
        <div className={styles.errorTitle}>
          <u>Errors</u>
        </div>
        <ul className={styles.errors}>
          {errors.map(e => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
    ) : null}
    <SaveChanges
      openConfirmModal={openConfirmModal}
      addErrors={addErrors}
      action={action}
      params={params}
      refetch={refetch}
      clear={clear}
    />
  </div>
);

export default ServiceEventModal;
