import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const rearBumper = [
	0.0418,
	0.6613,
	0.0316,
	0.6844,
	0.0565,
	0.6968,
	0.0633,
	0.6968,
	0.0701,
	0.7004,
	0.0825,
	0.7004,
	0.0983,
	0.7004,
	0.1028,
	0.7004,
	0.1096,
	0.695,
	0.1232,
	0.6844,
	0.1277,
	0.6808,
	0.1288,
	0.6773,
	0.1141,
	0.6755,
	0.1107,
	0.6755,
	0.1096,
	0.6719,
	0.1073,
	0.6328,
	0.0983,
	0.6275,
	0.0915,
	0.6186,
	0.0904,
	0.6133,
	0.0893,
	0.4,
	0.0904,
	0.3875,
	0.0927,
	0.3804,
	0.0949,
	0.3769,
	0.1085,
	0.3715,
	0.1096,
	0.336,
	0.1107,
	0.3306,
	0.113,
	0.3289,
	0.1186,
	0.3271,
	0.1266,
	0.3289,
	0.1277,
	0.3271,
	0.1232,
	0.32,
	0.1175,
	0.3146,
	0.1119,
	0.3093,
	0.1062,
	0.3057,
	0.104,
	0.304,
	0.1006,
	0.3022,
	0.0927,
	0.3022,
	0.0814,
	0.304,
	0.0678,
	0.304,
	0.061,
	0.3075,
	0.0328,
	0.32,
	0.0395,
	0.3289,
	0.0429,
	0.3395,
	0.0463,
	0.3431,
	0.0497,
	0.3431,
	0.0531,
	0.3431,
	0.0542,
	0.3431,
	0.0531,
	0.3733,
	0.0531,
	0.4124,
	0.052,
	0.4764,
	0.0531,
	0.5155,
	0.0508,
	0.5813,
	0.052,
	0.5937,
	0.0542,
	0.6559,
	0.0542,
	0.6595,
	0.0407,
	0.6648,
	0.0384,
	0.6702
];

const rearTrunk = [
	0.1079,
	0.373,
	0.0985,
	0.3763,
	0.0932,
	0.3813,
	0.088,
	0.3978,
	0.089,
	0.4078,
	0.0901,
	0.6064,
	0.0901,
	0.6163,
	0.0932,
	0.6213,
	0.1079,
	0.6329,
	0.1079,
	0.5981,
	0.109,
	0.5948,
	0.1111,
	0.5882,
	0.1164,
	0.5832,
	0.1195,
	0.5832,
	0.1227,
	0.5849,
	0.1269,
	0.5882,
	0.1311,
	0.6395,
	0.1374,
	0.6428,
	0.1385,
	0.6478,
	0.1374,
	0.6561,
	0.1353,
	0.6643,
	0.1332,
	0.6709,
	0.1416,
	0.666,
	0.1458,
	0.661,
	0.1511,
	0.6494,
	0.1532,
	0.6395,
	0.1532,
	0.6312,
	0.1553,
	0.5352,
	0.1542,
	0.4425,
	0.1542,
	0.3929,
	0.1542,
	0.3697,
	0.1521,
	0.3598,
	0.149,
	0.3515,
	0.1406,
	0.3415,
	0.1332,
	0.3333,
	0.1353,
	0.3382,
	0.1353,
	0.3415,
	0.1364,
	0.3465,
	0.1374,
	0.3531,
	0.1385,
	0.3631,
	0.129,
	0.3647,
	0.13,
	0.3796,
	0.1269,
	0.4078,
	0.1258,
	0.4144,
	0.1227,
	0.416,
	0.1185,
	0.4193,
	0.1122,
	0.416,
	0.1079,
	0.4078
];

const rearDriverTaillight = [
	0.1078,
	0.3718,
	0.1068,
	0.393,
	0.1078,
	0.406,
	0.1078,
	0.4109,
	0.1099,
	0.4158,
	0.114,
	0.4191,
	0.1171,
	0.4207,
	0.1213,
	0.4191,
	0.1265,
	0.4158,
	0.1285,
	0.393,
	0.1306,
	0.3701,
	0.1316,
	0.3636,
	0.1368,
	0.362,
	0.1368,
	0.3473,
	0.1358,
	0.3392,
	0.1337,
	0.3359,
	0.1306,
	0.3294,
	0.1254,
	0.3261,
	0.1202,
	0.3245,
	0.1161,
	0.3245,
	0.113,
	0.3245,
	0.1099,
	0.3261,
	0.1078,
	0.3294
];

const rearPassengerTaillight = getMirrorImage(rearDriverTaillight);

const rearWindshield = [
	0.1534,
	0.6294,
	0.1534,
	0.6359,
	0.1565,
	0.6392,
	0.1627,
	0.6376,
	0.1824,
	0.6229,
	0.1845,
	0.6196,
	0.1855,
	0.6098,
	0.1876,
	0.543,
	0.1876,
	0.4647,
	0.1855,
	0.4028,
	0.1845,
	0.3864,
	0.1845,
	0.3832,
	0.1824,
	0.3799,
	0.17,
	0.3685,
	0.1596,
	0.362,
	0.1555,
	0.3636,
	0.1534,
	0.3685
];

const rearWipers = [
	0.1524,
	0.631,
	0.1534,
	0.6359,
	0.1555,
	0.6392,
	0.1596,
	0.6376,
	0.1596,
	0.5136,
	0.1586,
	0.4615,
	0.1586,
	0.3636,
	0.1565,
	0.362,
	0.1524,
	0.3685,
	0.1534,
	0.45,
	0.1544,
	0.5609
];

const rearPlate = [0.1254, 0.45, 0.1036, 0.45, 0.1036, 0.55, 0.1254, 0.55];

const rearDriverSideMirror = [
	0.1296,
	0.3261,
	0.1327,
	0.3294,
	0.1358,
	0.3359,
	0.1368,
	0.3392,
	0.1461,
	0.3408,
	0.1461,
	0.3163,
	0.1441,
	0.3131,
	0.141,
	0.3082,
	0.1368,
	0.3033,
	0.1327,
	0.3033,
	0.1296,
	0.3131
];

const rearPassengerSideMirror = getMirrorImage(rearDriverSideMirror);

export {
	rearBumper,
	rearTrunk,
	rearDriverTaillight,
	rearPassengerTaillight,
	rearWindshield,
	rearWipers,
	rearPlate,
	rearDriverSideMirror,
	rearPassengerSideMirror
};
