import React, { Component } from "react";

import { observer, inject } from "mobx-react";

import {
  faMapMarkerAlt,
  faParkingCircle
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classNames from "classnames";
import styles from "./locationSelect.module.scss";

import _ from "lodash";

const LocationSelect = inject("store")(
  observer(
    class LocationSelect extends Component {
      constructor(props) {
        super(props);
        this.state = {
          open: false,
          selected: false,
          selectedCity:
            JSON.parse(localStorage.getItem("parkingLotName")) || "",
          selectedLots: JSON.parse(localStorage.getItem("parkingLots")) || [],
          formattedLots: null,
          selectedLotName: "",
          testState: null,
          parkingLotSchema: []
        };
      }

      componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.setState({
          formattedLots: _.groupBy(
            this.props.currentUser.parkingLots,
            lot => lot.city.name
          ),
          parkingLotSchema: [...this.props.currentUser.parkingLots]
        });

        this.props.store.changeSelectedLots(this.state.selectedLots);
      }

      myRef = React.createRef();

      handleClickOutside = e => {
        if (!this.myRef.current.contains(e.target)) {
          this.setState({ open: false });
        }
      };

      componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }

      componentDidUpdate(prevProps, prevState) {
        const { selectedLots } = this.state;
        if (prevState.selectedLots !== selectedLots) {
          localStorage.setItem("parkingLots", JSON.stringify(selectedLots));
          this.props.store.changeSelectedLots(selectedLots);
        }
      }

      toggleOpen = () => this.setState({ open: !this.state.open });
      toggleSelected = () => this.setState({ selected: !this.state.selected });

      selectCity = city => {
        this.resetFilter();
        this.toggleSelected();
        const selectedLots = this.state.formattedLots[city].map(lot => lot.id);
        localStorage.setItem("parkingLots", JSON.stringify(selectedLots));
        localStorage.setItem("parkingLotName", JSON.stringify(city));
        this.setState({
          selectedCity: city,
          selectedLots,
          selectedLotName: ""
        });
      };

      resetFilter = () => {
        let store = this.props.store;
        store.resetSelectFilter();
        store.changeSelectAll("statusSelection", true);
        store.changeSelectAll("fleetSelection", true);
        store.changeSelectAll("carStatusSelection", true);
        store.changeSelectAll("modelSelection", true);
        store.changeSelectAll("locationSelection", true);
        store.changeSelectAll("upcomingServiceSelection", true);
        store.changeSelectAll("homeLotSelection", true);
        store.changeSelectAll("pendingServiceSelection", true);
        store.changeSelectAll("yearSelection", true);
      };

      reset = () => {
        console.log(this.props.store);
        localStorage.removeItem("parkingLots");
        localStorage.removeItem("parkingLotName");
        this.setState({
          selectedCity: "",
          selectedLots: [],
          selectedLotName: ""
        });
      };

      selectLot = lotId => {
        let newLots = [...this.state.selectedLots];
        const includes = _.includes(newLots, lotId);
        this.resetFilter();
        includes
          ? _.remove(newLots, element => element === lotId)
          : newLots.push(lotId);

        let newLotName = "";
        switch (newLots.length) {
          case 0:
            break;
          case 1:
            newLotName = this.state.parkingLotSchema.filter(
              e => e.id === newLots[0]
            )[0].name;
            break;
          default:
          case 2:
            newLotName = "Multiple Lots";
            break;
        }

        localStorage.setItem("parkingLots", JSON.stringify(newLots));
        localStorage.setItem("parkingLotName", JSON.stringify(newLotName));
        this.setState({
          selectedLots: newLots,
          selectedCity: "",
          selectedLotName: newLotName
        });
      };

      formatCityName = city => {
        switch (city) {
          case "new_york":
            return "NYC";
          case "toronto":
            return "TOR";
          case "Downtown":
            return "DT";
          case "Square One":
            return "SQO";
          case "Langhorne, PA":
            return "LPA";
          case "Weekly Lease":
            return "WL";
          case "Secaucus, NJ":
            return "SNJ";
          case "LIC Centre Blvd":
            return "LIC";
          case "Skyport":
            return "SKY";
          case "Skyport (Outdoor)":
            return "SKYO";
          case "Multiple Lots":
            return "MULT";
        }
      };

      formatLotName = lot => {
        switch (lot) {
          case "Downtown":
            return "DT";
          case "Square One":
            return "SQO";
          case "Langhorne, PA":
            return "LPA";
          case "Weekly Lease":
            return "WL";
          case "Secaucus, NJ":
            return "SNJ";
          case "LIC Centre Blvd":
            return "LIC";
          case "Skyport":
            return "SKY";
          case "Skyport (Outdoor)":
            return "SKYO";
          case "Multiple Lots":
            return "MULT";
        }
      };

      render() {
        const {
          selected,
          selectedCity,
          selectedLots,
          formattedLots,
          selectedLotName
        } = this.state;
        console.log(selectedCity);

        return (
          <div ref={this.myRef} className={styles.dropdownContainer}>
            <div className="navbar-link" onClick={this.toggleOpen}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ fontSize: "x-large" }}
              />{" "}
              <div>
                {_.startCase(
                  this.formatCityName(selectedCity) ||
                    this.formatLotName(selectedLotName) ||
                    "ALL"
                )}
              </div>
            </div>
            <div
              className={classNames(
                styles.triangle,
                !this.state.open && styles.closed
              )}
            />
            <div
              className={classNames(
                styles.dropdown,
                !this.state.open && styles.closed
              )}
            >
              {_.map(formattedLots, (element, key) => {
                return (
                  <div className={styles.textContainer} key={key}>
                    <div
                      className={classNames(
                        styles.city,
                        styles.hover,
                        selectedCity === key && selected && styles.selected
                      )}
                      onClick={() => this.selectCity(key)}
                    >
                      {" "}
                      {_.startCase(key)}
                    </div>
                    <div>
                      {" "}
                      {element.map(lot => (
                        <div
                          className={classNames(
                            styles.parkingLot,
                            styles.hover,
                            selectedLots.includes(lot.id) && styles.selected
                          )}
                          key={lot.id}
                          onClick={() => this.selectLot(lot.id)}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faParkingCircle} /> {lot.name}{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
              <div className={styles.reset} onClick={this.reset}>
                {" "}
                Reset{" "}
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default LocationSelect;
