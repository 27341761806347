import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import TextInput from "./TextInput";
import SignInMutation from "../../Mutations/SignInMutation";
import AutzuLogoLayout from "./AutzuLogoLayout";
import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: "",
      loggedIn: false
    };
  }

  displayError = error => {
    this.setState({ error });
  };

  handleChange = ({ target }) => {
    const name = target.name;
    const val = target.value;

    this.setState({ [name]: val });
  };

  render() {
    const { email, password, error } = this.state;
    return (
      <AutzuLogoLayout>
        <ValidatorForm instantValidate={false} onSubmit={() => console.log("")}>
          <div className="sign-in-field">
            <TextInput
              name="email"
              value={email}
              handleChange={this.handleChange}
            />
            <TextInput
              name="password"
              type="password"
              value={password}
              handleChange={this.handleChange}
            />
            <p className="login-error">{error}</p>
            <SignInMutation
              email={email}
              password={password}
              displayError={this.displayError}
            />
          </div>
        </ValidatorForm>
      </AutzuLogoLayout>
    );
  }
}
export default Login;
