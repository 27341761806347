import React from "react";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ParkingSpotHeader = ({ parkingSpotData }) => {
  const parkingSpotIdentifier = parkingSpotData?.identifier;

  return (
    <div className="path-head unselectable">
      <Link
        to="/spots"
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >
        Parking
      </Link>
      <FontAwesomeIcon
        icon={faChevronRight}
        size="sm"
        style={{ margin: "0 0.5em" }}
      />

      <Link
        to={`/spots/${parkingSpotIdentifier}`}
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "700",
        }}
      >
        {parkingSpotIdentifier}
      </Link>
    </div>
  );
};

export default ParkingSpotHeader;
