import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { m } from "../../../utils";

const DateInput = ({
  classes,
  selectReminderDate,
  addNewReminderDate,
  newReminderDate,
  reminderDate,
  selectedNote,
}) => {
  return (
    <div className="date-input">
      <div>Remind on:</div>
      <DatePicker
        customInput={
          newReminderDate ? (
            <div style={{ color: "#414141" }}>
              {m(newReminderDate).format("MM/DD/YYYY hh:mm A")}
            </div>
          ) : selectedNote && reminderDate ? (
            <div style={{ color: "#414141" }}>
              {m(reminderDate).format("MM/DD/YYYY hh:mm A")}
            </div>
          ) : (
            <div>
              <div>Time and Date</div>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
          )
        }
        className={classNames(classes.dateInput)}
        onChange={(val) => {
          if (selectedNote) {
            selectReminderDate(val);
          } else {
            addNewReminderDate(val);
          }
        }}
        showTimeSelect
        selected={
          selectedNote && reminderDate
            ? m(reminderDate)
            : newReminderDate
            ? m(newReminderDate)
            : m()
        }
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="LLL"
        timeCaption="Time"
        todayButton={"Today"}
      />
    </div>
  );
};

export default DateInput;
