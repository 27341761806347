import React, { useState } from "react";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";
import { availableVehicles } from "../../Queries/queryStrings";
import Loading from "../../Containers/Loading";
import { Fragment } from "react";
import CellInfoModal from "./CellInfoModal";
import styles from "./vehicles_available.module.scss";
import { times } from "./utils";
import TimeSlots from "./TimeSlots";
import TableContent from "./TableContent";

const AvailabilityTable = ({ criteria, parkingLotId, fleetId, startDate, timeZone }) => {
  const [isCell, setIsCell] = useState();
  const [chosenCell, setChosenCell] = useState();
  const [chosenDay, setChosenDay] = useState();

  const toggleCell = (day, idx) => {
    setIsCell(!isCell);
    setChosenCell(idx);
    setChosenDay(day);
  };

  const closeCell = () => {
    setIsCell(false);
  };

  const { loading, data } = useQuery(availableVehicles, {
    fetchPolicy: "no-cache",
    variables: {
      fleetId: fleetId,
      parkingLotId: parkingLotId,
      startDate: startDate,
      stretch: 7,
    },
  });

  const formattedTimeZone = moment(startDate).tz(timeZone).format("z");

  if (loading) {
    return (
      <div className={styles.loadingScreen}>
        <Loading size="5x" />
      </div>
    );
  }
  if (data) {
    return (
      <Fragment>
        <div className={styles.rowFlex}>
          <TimeSlots times={times} timeZone={formattedTimeZone}/>
          <div className={styles.dayContainer}>
            {data.vehiclesAvailable.map((day, i) => (
              <TableContent
                day={day}
                index={i}
                toggleCell={toggleCell}
                criteria={criteria}
              />
            ))}
          </div>
        </div>
        <CellInfoModal
          open={isCell}
          chosenCell={chosenCell}
          chosenTime={times[chosenCell]}
          chosenDay={chosenDay}
          closeCell={closeCell}
        />
      </Fragment>
    );
  }
};

export default AvailabilityTable;
