import { useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
// import ChatBox from "./ChatBox";
import { GET_USER_PROFILE } from "../queries";
import styles from "../task.module.scss";
import StatusAction from "./StatusAction";
import { types } from "./options";
import AssignAdminModal from "./AssignAdminModal";
import ChatBox from "./ChatBox";
import CancelConfirmModal from "./ConcalConfirmModal";

const TaskLeft = ({
  params,
  taskData,
  refreshTaskCallback,
  isEdit,
  toggleEdit,
  dueDate,
  vehicleId,
  cancelEditCallback,
  currentAdmin,
  setDueDate,
}) => {
  const [curType, setCurType] = useState();
  const [curDescription, setCurDescription] = useState();
  const [adminId, setAdminId] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isCancel, setIsCancel] = useState();

  const changeType = (e) => {
    setCurType(e);
  };

  const changeDescription = (e) => {
    setCurDescription(e);
  };

  const toggleAdmin = () => {
    setIsAdmin(!isAdmin);
  };

  const adminIdCallback = (childData) => {
    setAdminId(childData);
  };

  const cancelEdit = () => {
    setIsCancel(!isCancel);
  };

  const resetUpdate = () => {
    setCurType(undefined);
    setCurDescription(undefined);
    setDueDate(undefined);
  };

  const { data, loading } = useQuery(GET_USER_PROFILE, {
    variables: { id: adminId ? adminId : null, name: "n/a" },
  });

  const chosenAdminData = data?.getUserProfile;
  const chosenAgentName = chosenAdminData?.fullName;
  const ableToCreate = curType && curDescription && vehicleId;

  const assignedAdminId = adminId
    ? adminId
    : taskData?.user
    ? taskData.user.id
    : null;

  useEffect(() => {
    setCurType(null);
  }, [isEdit]);

  return (
    <Fragment>
      <div className={styles.leftContainer}>
        <StatusAction
          taskData={taskData}
          isEdit={isEdit}
          curType={curType}
          curDescription={curDescription}
          dueDate={dueDate}
          vehicleId={vehicleId}
          changeDescription={changeDescription}
          types={types}
          changeType={changeType}
          chosenAgentName={chosenAgentName}
          toggleAdmin={toggleAdmin}
          refreshTaskCallback={refreshTaskCallback}
          ableToCreate={ableToCreate}
          assignedAdminId={assignedAdminId}
          adminIdCallback={adminIdCallback}
          toggleEdit={toggleEdit}
          currentAdmin={currentAdmin}
          cancelEdit={cancelEdit}
          resetUpdate={resetUpdate}
        />
        <ChatBox
          params={params}
          taskData={taskData}
          refreshTaskCallback={refreshTaskCallback}
          currentAdmin={currentAdmin}
        />
      </div>
      <AssignAdminModal
        open={isAdmin}
        toggleAdmin={toggleAdmin}
        adminIdCallback={adminIdCallback}
      />
      <CancelConfirmModal
        open={isCancel}
        toggleCancel={cancelEdit}
        setCurType={setCurType}
        setCurDescription={setCurDescription}
        setAdminId={setAdminId}
        taskData={taskData}
        cancelEditCallback={cancelEditCallback}
        toggleEdit={toggleEdit}
      />
    </Fragment>
  );
};

export default TaskLeft;
