import React, { Component } from "react";
import VehicleDetails from "./VehicleDetails";
import VehicleHeader from "./VehicleHeader";
import GetNotes from "../../../Queries/GetNotes";
import BookingSandwich from "../../Vehicle/Booking/BookingSandwich";
import StatusLog from "../../Vehicle/StatusLog";
import VehicleTab from "./VehicleTab";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { getVehicleDrawerString } from "../../../Queries/queryStrings";
import { status, capitalize } from "../../../utils";

class Vehicle extends Component {
  constructor() {
    super();
    this.state = {
      addNote: false,
    };
  }

  toggleAddNote = () => this.setState({ addNote: !this.state.addNote });

  render() {
    const { vehicleId, store, toggleDisplay, display } = this.props;
    const { addNote } = this.state;
    return (
      <Query
        query={gql`
          ${getVehicleDrawerString}
        `}
        variables={{
          vehicleId: vehicleId,
        }}
        pollInterval={600000}
      >
        {({ loading, error, data, refetch }) => {
          if (loading || error) {
            console.log(error);
            return null;
          }

          if (data.getVehicle) {
            const vehicle = data.getVehicle;
            const currentUser = data.currentUser;
            const { operationStatus } = vehicle.statuses;
            return (
              <div style={{ minHeight: "100vh", paddingTop: "16px" }}>
                <div className="vehicle-details-container">
                  <VehicleHeader
                    year={vehicle.year}
                    plate={vehicle.plate}
                    make={vehicle.make}
                    model={vehicle.model}
                    imageLink={vehicle.imageLink}
                    id={vehicle.id}
                    store={store}
                    status={
                      vehicle.currentBooking &&
                      vehicle.currentBooking.bookingType
                        ? capitalize(vehicle.currentBooking.bookingType)
                        : status(vehicle.currentBooking)
                    }
                  />
                  <VehicleDetails
                    id={vehicle.id}
                    store={store}
                    color={vehicle.color}
                    odometer={vehicle.odometer}
                    vin={vehicle.vin}
                    gasLevel={vehicle.gasLevel}
                    coolantTemp={
                      vehicle.coolantTemp ? vehicle.coolantTemp.reading : null
                    }
                    serialNumber={vehicle.piloDevice}
                    lastGpsTime={vehicle.lastGps.capturedAt}
                    gpsStatus={vehicle.gpsStatus}
                    plate={vehicle.plate}
                    city={vehicle.parkingLot.city.name}
                    status={operationStatus}
                    booking={vehicle.currentBooking}
                    parkingHub={vehicle.parkingLot?.name}
                  />
                </div>
                <div
                  className="vehicle-details-map-tab"
                  style={{ marginLeft: "32px" }}
                >
                  <div className="vehicle-col ">
                    <div className="vehicle-details-map-tab">
                      <div className="vehicle-details-map-tab">
                        <VehicleTab
                          name="Log"
                          type="bookings"
                          toggle={toggleDisplay}
                          display={display}
                        />

                        <VehicleTab
                          name="Notes"
                          type="notes"
                          toggle={toggleDisplay}
                          display={display}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-tabs-container">
                  {display === "notes" ? (
                    <div>
                      {
                        // <div
                        //   className="map-add-note"
                        //   onClick={() => {
                        //     this.toggleAddNote();
                        //   }}
                        // >
                        //   Add Note
                        // </div>
                      }
                      <GetNotes
                        vehicleId={vehicle.id}
                        mapView={true}
                        addNote={addNote}
                        closeNote={this.toggleAddNote}
                        currentUser={currentUser}
                      />
                    </div>
                  ) : null}
                  {display === "bookings" ? (
                    <div style={{ marginTop: "32px" }}>
                      <StatusLog
                        statusLog={vehicle.statusLog}
                        mapView={true}
                        timezone={vehicle?.city?.timeZone}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

export default Vehicle;
