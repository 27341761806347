import React, { useState } from "react";
import styles from "../statusInfo.module.scss";
import Button from "../../../../../artzu/src/Button";
import Modal from "../../../../../artzu/src/Modal";
import { Fragment } from "react";
import PrebookingInspection from "./PrebookingInspection";
import { GET_INSPECTION } from "./queries";
import { useQuery } from "@apollo/client";

const ResourceDetail = ({ content }) => {
  const titleText = content.title;
  const buttonLabel = content.buttonTitle;
  const resourceId = content.resourceId;
  const resourceType = content.resourceType;
  const [isInspection, setIsInspection] = useState(false);
  const inspectionId =
    resourceType === "prebookingInspection" ? resourceId : null;

  const toggleInspection = () => {
    setIsInspection(!isInspection);
  };

  // console.log(content);
  const { loading, error, data, refetch } = useQuery(GET_INSPECTION, {
    variables: { prebookingInspectionId: inspectionId },
  });

  return (
    <Fragment>
      <div className={styles.comments}>
        <div className={styles.textInfo}>
          <div className={styles.coreText}>
            <div>{titleText}</div>
          </div>
        </div>
        <Button
          label={buttonLabel}
          height="29px"
          fullWidth="155px"
          color="#ffffff"
          backColor="#128dff"
          radius="14.5px"
          size="16px"
          weight="400"
          border="1px solid #128dff"
          padding="0"
          onClick={toggleInspection}
        />
      </div>
      {!loading && (
        <PrebookingInspection
          isInspection={isInspection}
          toggleInspection={toggleInspection}
          inspectionData={data ? data.getPrebookingInspection : null}
        />
      )}
    </Fragment>
  );
};

export default ResourceDetail;
