import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import GasLevelInput from "./GasLevelInput";

import "../service.css";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  Input
} from "@material-ui/core/";

import {
  faLightbulbOn,
  faTachometerAlt
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CleaningCheckupList extends Component {
  constructor() {
    super();
    this.state = {
      gasLevel: false
    };
  }

  render() {
    const { data, onEdit, classes } = this.props;
    return (
      <List
        subheader={
          <ListSubheader className={classes.subheader} disableSticky>
            {" "}
            Dashboard{" "}
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLightbulbOn} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={"Dash Lights"} />
          <ListItemSecondaryAction>
            <Switch
              onChange={event => onEdit("dashLights", event.target.checked)}
              checked={data.dashLights}
              classes={{
                switchBase: classes.switch,
                checked: classes.colorChecked,
                track: classes.colorBar
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <GasLevelInput
          onEdit={onEdit}
          modifyState={state => this.setState(state)}
          gasLevel={this.state.gasLevel}
          data={data}
        />
      </List>
    );
  }
}

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  },
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.blue
    }
  },
  inputRoot: {
    width: "6em"
  },
  inputInput: {
    textAlign: "center"
  }
});

export default withStyles(styles)(CleaningCheckupList);
