import React from "react";
import CheckBox from "../../Shared/CheckBox";
import styles from "./rowSelect.module.scss";
import classsNames from "classnames";

const RowSelect = ({ rowId, onClick, isSelected }) => {
  return (
    <div className={styles.rowSelect}>
      <CheckBox
        onClick={evt => {
          // evt.preventDefault();
          onClick();
        }}
        isSelected={isSelected}
      />
    </div>
  );
};

export default RowSelect;
