import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const BooleanSentryModeValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;

  const getText = () => {
    switch (text) {
      case "true":
        return "On";
      case "false":
        return "Off";
      default:
        break;
    }
  };
  const getColor = (text) => {
    switch (text) {
      case "true":
        return "#3dc700";
      case "false":
        return "#414141";
      default:
        break;
    }
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={styles.boldText}
        style={{
          color: `${getColor(text)}`,
        }}
      >
        {getText()}
      </div>
    </div>
  );
};

export default BooleanSentryModeValue;
