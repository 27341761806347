import React, { Component } from "react";
import { Mutation } from "@apollo/client/react/components";
import styles from "./makeAvailable.module.scss";

import { MAKE_AVAILABLE } from "../../gqlStrings";
import Button from "../../../../../artzu/src/Button";

const MakeAvailableMutation = ({ vehicleId, setLoading, closeModal }) => {
  return (
    <Mutation
      mutation={MAKE_AVAILABLE}
      variables={{ vehicleId }}
      refetchQueries={[`getVehicle`]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        closeModal();
        setLoading(false);
      }}
    >
      {makeVehicleAvailable => (
        <div className={styles.buttonContainer}>
          <Button
            label="Cancel"
            className={styles.cancelButton}
            onClick={closeModal}
          />
          <Button
            className={styles.makeAvailableButton}
            label="Make Available"
            onClick={() => {
              makeVehicleAvailable();
              setLoading(true);
            }}
          />
        </div>
      )}
    </Mutation>
  );
};

export default MakeAvailableMutation;
