import React, { Fragment } from "react";
import CircleImage from "./CircleImage";
import { m, capitalize, status } from "../../utils";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./booking.scss";

const showBooking = (vehicle) => {
  // only show booking if a. exists and b. vehicle is not currently available (i.e. booking is not prev booking)
  if (vehicle.booking && vehicle.vehiclePriority !== 1) return true;
  else return false;
};

const BookingCard = ({ vehicle, selectedVehicle }) => (
  <Fragment>
    {showBooking(selectedVehicle) ? (
      <div className="booking-container">
        <div className="booking-left">
          <a
            href={vehicle.booking.profile}
            target="_blank"
            rel="noopener noreferrer"
            className="no-decoration"
          >
            <CircleImage
              imageLink={vehicle.booking.driverImage}
              size="small-pic"
              isPrev={vehicle.booking.isPrev}
            />
          </a>
        </div>
        <div className="booking-right">
          <a
            href={vehicle.booking.profile}
            target="_blank"
            rel="noopener noreferrer"
            className="no-decoration"
          >
            <h4 className="booking-info__name">
              {["Service", "With Driver", "User"].includes(vehicle.status)
                ? vehicle.booking.driverName
                : vehicle.status}
            </h4>
          </a>
          {["With Driver", "User"].includes(vehicle.status) ? (
            <div>
              <div>
                <div className="booking-info__time">
                  {m(vehicle.booking.startTime).format("hA")} to{" "}
                  {m(vehicle.booking.endTime).format("hA")}
                </div>
              </div>
              <div className="booking-info__small">
                {m(vehicle.booking.startTime).format("ll")}
              </div>
            </div>
          ) : (
            <div>
              <div className="booking-info__time">
                <div className="booking-info__dates">
                  Start: {m(vehicle.booking.startTime).format("hA")}
                  {", "}
                  {m(vehicle.booking.startTime).format("ll")}
                </div>

                <div className="booking-info__dates">
                  Finish: {m(vehicle.booking.endTime).format("hA")}
                  {", "}
                  {m(vehicle.booking.endTime).format("ll")}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="booking-mobile">
          <FontAwesomeIcon
            icon={faPhoneAlt}
            style={{ color: "#128DFF", fontSize: "20px" }}
          />
          <div className="booking-info__smallNumber">
            {vehicle.booking.mobile}
          </div>
        </div>
      </div>
    ) : null}
  </Fragment>
);

export default BookingCard;
