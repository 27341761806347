import React from "react";
import styles from "../../../../VehicleNew/VehicleDamages/damages.module.scss";
import Modal from "../../../../../artzu/src/Modal";
import { useMutation } from "@apollo/client";
import { DELETE_DAMAGE } from "../../../../VehicleNew/VehicleDamages/queries";

const DeleteDamages = ({ open, selectedDamages, close }) => {
  const [deleteDamageMutation, { loading, error }] = useMutation(DELETE_DAMAGE);
  const handleDelete = () => {
    {
      deleteDamageMutation({
        variables: {
          damageIds: selectedDamages,
        },
      }).then(({ data: { deleteDamage } }) => {
        if (deleteDamage.success) {
          window.location.reload();
          close();
          console.log("Create Damage Succeed!");
        } else {
          console.log("Create damage error!");
        }
      });
    }
  };
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.changeStatusContainer}>
        <div className={styles.title} style={{ paddingTop: 0 }}>
          Delete
        </div>
        <div>
          Are you sure you want to delete{" "}
          {selectedDamages?.length > 1 ? "these" : "this"} damage{" "}
          {selectedDamages?.length > 1 ? "reports" : "report"}?
        </div>
        <div className={styles.buttons} style={{ marginTop: "24px" }}>
          <button className={styles.cancelButton} onClick={close}>
            Cancel
          </button>
          <button className={styles.applyButton} onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDamages;
