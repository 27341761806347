import React from "react";
import Modal from "../../../artzu/src/Modal";
import styles from "./notes.module.scss";

const ReminderModal = ({ open, children, title }) => (
  <Modal open={open} backgroundColor="none" padding="0">
    <div className={styles.reminderContainer}>
      {" "}
      <div className={styles.notesHeader}>
        {" "}
        <div className={styles.notesTitle}> {title} </div>
      </div>
      <div className={styles.children}> {children} </div>
    </div>
  </Modal>
);

export default ReminderModal;
