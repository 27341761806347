import React from "react";
import InformationTable from "../InformationTable";
import withAuth from "../../Containers/withAuth";

const TaskIndex = () => {
  return (
    <InformationTable
      tableName={"tasks"}
      navbar={"Tasks"}
      hasTabs={true}
      background={"grey"}
      rowComponentType={"task"}
      startTerms={"Search by Plate"}
      item={"tasks"}
    />
  );
};

export default withAuth(TaskIndex);
