import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import "../service.css";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  Collapse
} from "@material-ui/core/";

import {
  faTint,
  faBolt,
  faCarCrash,
  faEye
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  },
  damageReport: {
    textAlign: "center"
  }
});

class PrimaryCheckupList extends Component {
  constructor() {
    super();
    this.state = {
      damage: false
    };
  }

  render() {
    const { data, onEdit, classes, vehicleId } = this.props;
    return (
      <List
        subheader={
          <ListSubheader className={classes.subheader} disableSticky>
            {" "}
            General{" "}
          </ListSubheader>
        }
      >
        {primaryCheckupList.map((key, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>
                <FontAwesomeIcon
                  className="inspection-icon"
                  icon={key.icon}
                  fixedWidth
                />
              </ListItemIcon>
              <ListItemText primary={key.name} />
              <ListItemSecondaryAction>
                <Switch
                  onChange={event => onEdit(key.accessor, event.target.checked)}
                  checked={data[key.accessor]}
                  classes={{
                    switchBase: classes.switch,
                    checked: classes.colorChecked,
                    track: classes.colorBar
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCarCrash} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={"Damage Check"} />
          <ListItemSecondaryAction>
            <Switch
              onChange={event =>
                this.setState({ damage: event.target.checked })
              }
              checked={this.state.damage}
              classes={{
                switchBase: classes.switch,
                checked: classes.colorChecked,
                track: classes.colorBar
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={this.state.damage}>
          <div className={classes.damageReport}>
            {" "}
            <p>If there is new damage, fill out a damage form </p>
            <a
              href={`/vehicles/${vehicleId}/damage`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Create Damage Report
            </a>
          </div>
        </Collapse>
      </List>
    );
  }
}

const primaryCheckupList = [
  { accessor: "washerFluid", icon: faTint, name: "Washer Fluid" },
  { accessor: "socket", icon: faBolt, name: "12V Socket" },
  { accessor: "vinyl", icon: faEye, name: "Autzu Vinyl" }
];

export default withStyles(styles)(PrimaryCheckupList);
