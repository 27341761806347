import React, { useState } from "react";
import _ from "lodash";
import VehicleInfoTitle from "./VehicleInfoTitle";
import VehicleInfoLeft from "./VehicleInfoLeft";
import VehicleInfoRight from "./VehicleInfoRight";
import { useMutation } from "@apollo/client";
import { UPDATE_VEHICLE_DETAILS } from "./query";
import { UPDATE_LOCKBOX_CODE } from "./query";

const VehicleInfo = ({
  isEdit,
  toggleEdit,
  formatOdometer,
  city,
  refetch,
  vehicle,
}) => {
  const parkingLot = vehicle.parkingLot;
  const parkingSpot = vehicle.parkingSpot && vehicle.parkingSpot.identifier;
  const lockboxCode = vehicle.lockboxCode;
  const [currentLot, setCurrentLot] = useState(parkingLot && parkingLot.id);
  const [currentSpot, setCurrentSpot] = useState(parkingSpot);
  const [curBoxCode, setCurBoxCode] = useState(lockboxCode);

  const handleLotChange = (childData) => {
    setCurrentLot(childData);
  };

  const handleSpotChange = (childData) => {
    setCurrentSpot(childData);
  };

  const toggleRefresh = () => {
    refetch();
  };

  const handleCodeChange = (e) => {
    setCurBoxCode(e.target.value);
  };

  const [lockboxCodeMutation, { boxLoading, boxError }] = useMutation(
    UPDATE_LOCKBOX_CODE
  );

  const updateLockboxCode = () => {
    lockboxCodeMutation({
      variables: {
        vehicleId: vehicle.id,
        lockboxCode: curBoxCode,
      },
    }).then(({ data: { updateLockboxCode } }) => {
      if (updateLockboxCode.success) {
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  const [vehicleDetailsMutation, { loading, error }] = useMutation(
    UPDATE_VEHICLE_DETAILS
  );

  const updateVehicleInfo = () => {
    vehicleDetailsMutation({
      variables: {
        vehicleId: vehicle.id,
        parkingSpotIdentifier: currentSpot,
        parkingLotId: currentLot,
      },
    }).then(({ data: { updateVehicleDetails } }) => {
      if (updateVehicleDetails.success) {
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  return (
    <div className="details-upper-left">
      <VehicleInfoTitle
        isEdit={isEdit}
        toggleEdit={toggleEdit}
        updateVehicleInfo={updateVehicleInfo}
        updateLockboxCode={updateLockboxCode}
        toggleRefresh={toggleRefresh}
      />
      <div className="details-upper-left-content">
        <VehicleInfoLeft
          isEdit={isEdit}
          formatOdometer={formatOdometer}
          handleLotChange={handleLotChange}
          handleSpotChange={handleSpotChange}
          vehicle={vehicle}
          parkingSpot={currentSpot}
          city={city}
        />
        <VehicleInfoRight
          isEdit={isEdit}
          city={city}
          vehicle={vehicle}
          lockboxCode={curBoxCode}
          handleCodeChange={handleCodeChange}
        />
      </div>
    </div>
  );
};

export default VehicleInfo;
