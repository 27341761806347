import React from "react";
import { Motion, spring } from "react-motion";
import "../Vehicle/vehicle.scss";
import "./service.css";

const ServiceHeader = ({ year, make, model, plate }) => (
	<Motion
		defaultStyle={{ x: -200, opacity: 0 }}
		style={{
			x: spring(0, { stiffness: 200, damping: 25 }),
			opacity: spring(1)
		}}
	>
		{style => (
			<div
				className="column header-margin"
				style={{
					transform: `translateX(${style.x}px)`,
					opacity: style.opacity
				}}
			>
				<p className="blue small details tight-margin">
					{year} {make} {model} - {plate}
				</p>
				<h1 className="vehicle-margin">Maintenance</h1>
			</div>
		)}
	</Motion>
);

export default ServiceHeader;
