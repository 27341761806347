import React from "react";
import styles from "../damages.module.scss";
import Dropdown from "../../../Shared/Dropdown";

const DropdownInput = ({ text, value, options, triggerFieldChange }) => {
  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <Dropdown
        initialValue={value}
        items={options}
        onChange={triggerFieldChange}
        width="416px"
        height="32px"
      />
    </div>
  );
};

export default DropdownInput;
