import React, { useState } from "react";
import { TableCell, TableRow } from "../../../../../../artzu/src/Table";
import ChangeModifications from "./ChangeModifications";
import EditBox from "../../../../CellValues/EditBox";
import _ from "lodash";
import styles from "./changesTable.module.scss";

const ChangeRow = ({ rowData, deleteEdit, modifyEdits }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [tempEdits, setTempEdits] = useState({});

  const modifyTempEdits = newEdits => {
    const editsCopy = _.cloneDeep(tempEdits);
    setTempEdits({ ...editsCopy, ...newEdits });
  };

  const startEdit = () => setIsEditing(true);

  const confirmEdit = () => {
    modifyEdits(rowData.rowId, tempEdits);
    setTempEdits({});
    setIsEditing(false);
  };

  const resetEdit = () => setTempEdits({});

  const deleteRow = () => deleteEdit(rowData.rowId);

  return (
    <div key={rowData.rowId}>
      <TableRow
        borderRadius="8px"
        padding="12px 12px 12px 0em"
        align="center"
        minHeight="56px"
        minWidth={"100%"}
        color="#FFFFFF"
        justifyIpad="space-between"
        justify="space-evenly"
        margin="0 0 12px 0"
        hoverColor="#dedede"
        hover
      >
        <TableCell width="140px">
          {" "}
          <div className={styles.cellData}>{rowData.identifier}</div>
        </TableCell>
        <TableCell width="140px">
          <div className={styles.cellData}>
            {rowData.changes.map((change, index) => (
              <div className={index > 0 && styles.changeMargin}>
                {" "}
                {_.startCase(change.columnName)}{" "}
              </div>
            ))}
          </div>
        </TableCell>
        <TableCell width="140px">
          <div className={styles.cellData}>
            {rowData.changes.map((change, index) => (
              <div className={index > 0 && styles.changeMargin}>
                {" "}
                {change.oldValue || "--"}
              </div>
            ))}
          </div>
        </TableCell>
        <TableCell width="140px">
          <div className={styles.cellData}>
            {rowData.changes.map((change, index) => {
              const editedValue =
                tempEdits[change.columnName] &&
                tempEdits[change.columnName].newValue;
              return (
                <div className={index > 0 && styles.changeMargin}>
                  {isEditing ? (
                    <EditBox
                      value={change.newValue}
                      editedValue={editedValue}
                      onChange={value =>
                        modifyTempEdits({
                          [change.columnName]: {
                            newValue: value
                          }
                        })
                      }
                    />
                  ) : (
                    <div>{change.newValue || "--"}</div>
                  )}{" "}
                </div>
              );
            })}
          </div>
        </TableCell>
        <TableCell width="140px">
          <ChangeModifications
            deleteEdit={deleteEdit}
            modifyEdits={modifyEdits}
            startEdit={startEdit}
            confirmEdit={confirmEdit}
            deleteRow={deleteRow}
            isEditing={isEditing}
            resetEdit={resetEdit}
          />
        </TableCell>
      </TableRow>
    </div>
  );
};

export default ChangeRow;
