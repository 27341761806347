import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const frontBumper = [
	0.9729,
	0.679,
	0.9774,
	0.6719,
	0.9785,
	0.6097,
	0.974,
	0.6044,
	0.974,
	0.5919,
	0.9763,
	0.5813,
	0.9785,
	0.5066,
	0.9774,
	0.4266,
	0.9751,
	0.4195,
	0.974,
	0.4071,
	0.9785,
	0.3929,
	0.9774,
	0.336,
	0.974,
	0.3289,
	0.9107,
	0.3217,
	0.9085,
	0.3324,
	0.913,
	0.3306,
	0.9175,
	0.3324,
	0.922,
	0.3395,
	0.9254,
	0.3786,
	0.9254,
	0.3929,
	0.9232,
	0.3982,
	0.9198,
	0.4017,
	0.9198,
	0.4195,
	0.9243,
	0.4284,
	0.9288,
	0.4337,
	0.9322,
	0.4497,
	0.9322,
	0.464,
	0.9322,
	0.4853,
	0.9322,
	0.4906,
	0.9299,
	0.496,
	0.9266,
	0.4995,
	0.9266,
	0.5048,
	0.9299,
	0.5102,
	0.9311,
	0.512,
	0.9311,
	0.5262,
	0.9311,
	0.5493,
	0.9299,
	0.5653,
	0.9277,
	0.5706,
	0.9243,
	0.5742,
	0.9209,
	0.5795,
	0.9209,
	0.6026,
	0.9243,
	0.6133,
	0.9243,
	0.6186,
	0.9243,
	0.6293,
	0.9232,
	0.6453,
	0.9232,
	0.6524,
	0.9209,
	0.6648,
	0.9175,
	0.6702,
	0.9073,
	0.6719,
	0.9119,
	0.6808
];

const frontDriverHeadlight = [
	0.9049,
	0.3326,
	0.9017,
	0.3441,
	0.9007,
	0.3506,
	0.9017,
	0.3604,
	0.9049,
	0.3767,
	0.908,
	0.3897,
	0.909,
	0.3979,
	0.9111,
	0.4256,
	0.9152,
	0.424,
	0.9194,
	0.424,
	0.9173,
	0.4011,
	0.9204,
	0.3962,
	0.9235,
	0.3913,
	0.9235,
	0.3653,
	0.9225,
	0.3473,
	0.9194,
	0.3392,
	0.9173,
	0.3326,
	0.909,
	0.3294
];

const frontPassengerHeadlight = getMirrorImage(frontDriverHeadlight);

const frontGrill = [
	0.9129,
	0.5783,
	0.9192,
	0.5766,
	0.9276,
	0.5716,
	0.9308,
	0.56,
	0.9318,
	0.5402,
	0.9318,
	0.5253,
	0.9318,
	0.5137,
	0.9308,
	0.5087,
	0.9255,
	0.5021,
	0.9308,
	0.4905,
	0.9308,
	0.4822,
	0.9318,
	0.464,
	0.9318,
	0.4475,
	0.9297,
	0.4359,
	0.9265,
	0.4293,
	0.9192,
	0.4243,
	0.9139,
	0.426,
	0.9097,
	0.4293,
	0.9087,
	0.4409,
	0.9087,
	0.4723,
	0.9087,
	0.5385,
	0.9087,
	0.5584,
	0.9087,
	0.5667,
	0.9097,
	0.5749
];

const frontBonnet = [
	0.909,
	0.322,
	0.9069,
	0.3294,
	0.9049,
	0.331,
	0.9028,
	0.3392,
	0.9007,
	0.3506,
	0.9017,
	0.362,
	0.9038,
	0.3734,
	0.9069,
	0.3832,
	0.909,
	0.3962,
	0.909,
	0.3995,
	0.9111,
	0.4256,
	0.909,
	0.4288,
	0.908,
	0.4354,
	0.908,
	0.4761,
	0.909,
	0.4908,
	0.91,
	0.4924,
	0.9111,
	0.4957,
	0.9142,
	0.499,
	0.9142,
	0.5039,
	0.9121,
	0.5055,
	0.91,
	0.5104,
	0.909,
	0.5136,
	0.909,
	0.525,
	0.908,
	0.5365,
	0.908,
	0.5462,
	0.908,
	0.5528,
	0.908,
	0.5626,
	0.908,
	0.5674,
	0.909,
	0.5707,
	0.91,
	0.574,
	0.9111,
	0.5756,
	0.909,
	0.6001,
	0.9069,
	0.6098,
	0.9038,
	0.6229,
	0.9017,
	0.6343,
	0.9007,
	0.6441,
	0.9007,
	0.6539,
	0.9028,
	0.6636,
	0.9049,
	0.6685,
	0.908,
	0.6767,
	0.9038,
	0.6783,
	0.8997,
	0.6767,
	0.8955,
	0.6751,
	0.8893,
	0.6702,
	0.8862,
	0.6669,
	0.882,
	0.6588,
	0.88,
	0.6555,
	0.8738,
	0.6539,
	0.8717,
	0.6522,
	0.8696,
	0.6473,
	0.8686,
	0.6376,
	0.8686,
	0.5772,
	0.8686,
	0.4941,
	0.8696,
	0.4288,
	0.8696,
	0.393,
	0.8686,
	0.3653,
	0.8717,
	0.3522,
	0.881,
	0.3408,
	0.8893,
	0.331,
	0.8976,
	0.3229
];

const frontPlate = [
	0.9473,
	0.5479,
	0.9681,
	0.5479,
	0.9681,
	0.4521,
	0.9473,
	0.4521
];

const frontDriverSideMirror = [
	0.8706,
	0.3506,
	0.8779,
	0.3457,
	0.8758,
	0.3424,
	0.8758,
	0.3277,
	0.8738,
	0.3131,
	0.8727,
	0.3065,
	0.8706,
	0.3049,
	0.8644,
	0.3065,
	0.8603,
	0.3131,
	0.8582,
	0.3229,
	0.8572,
	0.3375,
	0.8572,
	0.3441,
	0.8603,
	0.3489
];

const frontPassengerSideMirror = getMirrorImage(frontDriverSideMirror);

const frontWipers = [
	0.8696,
	0.6376,
	0.8686,
	0.5218,
	0.8686,
	0.437,
	0.8696,
	0.3701,
	0.8696,
	0.3653,
	0.8675,
	0.3636,
	0.8644,
	0.3653,
	0.8624,
	0.4663,
	0.8624,
	0.5381,
	0.8644,
	0.6033,
	0.8634,
	0.6359,
	0.8644,
	0.6392
];

const frontWindshield = [
	0.8686,
	0.6392,
	0.8686,
	0.5104,
	0.8686,
	0.4272,
	0.8696,
	0.3669,
	0.8696,
	0.3636,
	0.8675,
	0.3587,
	0.8644,
	0.3587,
	0.851,
	0.3669,
	0.8344,
	0.3799,
	0.8302,
	0.3832,
	0.8292,
	0.3897,
	0.8271,
	0.424,
	0.8271,
	0.4712,
	0.8271,
	0.5348,
	0.8271,
	0.5691,
	0.8282,
	0.5952,
	0.8282,
	0.6066,
	0.8282,
	0.6131,
	0.8292,
	0.618,
	0.8437,
	0.6294,
	0.8592,
	0.6392,
	0.8665,
	0.6424
];

export {
	frontBumper,
	frontPassengerHeadlight,
	frontDriverHeadlight,
	frontGrill,
	frontBonnet,
	frontPlate,
	frontDriverSideMirror,
	frontPassengerSideMirror,
	frontWipers,
	frontWindshield
};
