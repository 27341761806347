import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import { CREATE_NEW_TAG } from "../../queries";
import CreateTagModal from "./CreateTagModal";

const CreateNewTag = ({
  open,
  toggleCreate,
  allTags,
  refetch,
  refetchAddTag,
  entry,
}) => {
  const [newTag, setNewTag] = useState();

  const triggerCreate = (e) => {
    setNewTag(e.target.value);
  };

  const IsExist = allTags.some(
    (tag) => newTag && tag.name.toLowerCase() === newTag.toLowerCase()
  );

  const [createTagMutation, { loading, error }] = useMutation(CREATE_NEW_TAG);
  const createTag = () => {
    createTagMutation({
      variables: {
        tagName: newTag,
      },
    }).then(({ data: { createTag } }) => {
      if (createTag.success) {
        refetch();
        entry && refetchAddTag();
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  return (
    <CreateTagModal
      open={open}
      toggleCreate={toggleCreate}
      newTag={newTag}
      triggerCreate={triggerCreate}
      IsExist={IsExist}
      setNewTag={setNewTag}
      createTag={createTag}
    />
  );
};

export default CreateNewTag;
