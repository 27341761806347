import React, { useState } from "react";
import classsNames from "classnames";
import styles from "../../../../InformationTable/BatchActions/batchActions.module.scss";
import { Fragment } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_VEHICLE_STATUS } from "../../../queries";
import Dropdown from "../../../../InformationTable/Shared/Dropdown";
import MessageBox from "../../../../VehicleNew/ActionButtons/MessageBox";
import Modal from "../../../../../artzu/src/Modal";
import ChangeStatusDatePicker from "./ChangeStatusDatePicker";
import ChangeStatusModalButtons from "./ChangeStatusModalButtons";

const ChangeStatusModal = ({
  isChangeStatus,
  vehicle,
  toggleRefresh,
  close,
  toggleOpenState,
}) => {
  const availableStatus = vehicle?.availableStatusChanges;

  const statuses = [];
  availableStatus &&
    availableStatus.map((status) =>
      statuses.push({ name: status.name, id: status.name })
    );

  const [selected, setSelected] = useState("");
  const [statusAction, setStatusAction] = useState("now");
  const [startDate, setStartDate] = useState();
  const [isMessage, setIsMessage] = useState(false);
  const [comment, setComment] = useState("");
  const [errorMsg, setErrorMsg] = useState();

  const trigger = (e) => {
    setSelected(e);
  };
  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };
  const handleAction = (e) => {
    setStatusAction(e.target.value);
  };
  const toggleMessage = () => {
    setIsMessage(!isMessage);
  };
  const closeMsg = () => {
    setIsMessage(false);
  };

  const statusSelected = selected !== "";
  const addable = statusSelected && statusAction && comment;

  const [changeStatusMutation, { loading, error }] = useMutation(
    CHANGE_VEHICLE_STATUS
  );
  const changeVehicleStatus = () => {
    changeStatusMutation({
      variables: {
        vehicleId: vehicle.id,
        startTime: startDate && startDate.toDate(),
        statusType: selected.toLowerCase(),
        comments: comment,
      },
    }).then(({ data: { scheduleVehicleStatusChange } }) => {
      if (scheduleVehicleStatusChange.success) {
        close();
        console.log("Succeed!");
        toggleOpenState();
      } else {
        toggleMessage();
        setErrorMsg(scheduleVehicleStatusChange.errors);
      }
    });
  };

  return (
    <Fragment>
      <Modal open={isChangeStatus} padding="none" backgroundColor={"none"}>
        <div className={styles.actionContainer}>
          <div className={styles.darkText}>Change status</div>
          <div
            className={classsNames(
              styles.contentContainer,
              styles.contentContainer__changeStatus
            )}
          >
            <div className={styles.optionContainer}>
              <div className={styles.optionBox}>
                Change status to
                <Dropdown initialValue="" items={statuses} onChange={trigger} />
              </div>
            </div>
            <div className={styles.actionList} onChange={handleAction}>
              <div className={styles.choice}>
                <input type="radio" name="tag" value="now" defaultChecked />{" "}
                Apply change immediately
              </div>
              <ChangeStatusDatePicker
                startDate={startDate}
                setStartDate={setStartDate}
              />
            </div>
            <div className={styles.comment}>
              <h4>Comment</h4>
              <textarea
                className={classsNames(styles.textBox, styles.commentBox)}
                onChange={handleCommentInput}
              ></textarea>
            </div>
            <ChangeStatusModalButtons
              close={close}
              toggleOpenState={toggleOpenState}
              loading={loading}
              addable={addable}
              changeVehicleStatus={changeVehicleStatus}
              toggleRefresh={toggleRefresh}
            />
          </div>
        </div>
      </Modal>

      {isMessage && (
        <MessageBox open={isMessage} close={closeMsg} errorMsg={errorMsg} />
      )}
    </Fragment>
  );
};

export default ChangeStatusModal;
