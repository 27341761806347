import React, { useState } from "react";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./table.module.scss";

const chooseIcon = descending => (descending ? faAngleDown : faAngleUp);

const Sortable = ({
  name,
  tableWidth,
  reorder,
  myType,
  currentType,
  descending,
  className,
  currentSort = () => {},
  category = ""
}) => (
  <div
    className={classNames(
      "vehicle-data",
      "sortable",
      className,
      styles.breakWord
    )}
    onClick={() => {
      reorder(myType);
    }}
    style={currentType === myType ? { color: "#128DFF" } : null}
  >
    {name}
    {currentType === myType ? (
      <FontAwesomeIcon
        className="sortable-icon"
        icon={chooseIcon(descending)}
      />
    ) : null}
  </div>
);

export default Sortable;
