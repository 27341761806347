import React from "react";
import { render } from "react-dom";
import store from "./utils/gpsStore";
import bookingStore from "./utils/bookingStore";
import "./index.scss";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Root from "./Containers/Root";
import { Provider } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { setContext} from "@apollo/client/link/context";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { createUploadLink } from "apollo-upload-client";

const options = {
  uri: `${process.env.REACT_APP_SERVER_IP}`
};

const uploadLink = createUploadLink(options);

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  uploadLink,
  new BatchHttpLink(options)
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (localStorage.state) {
    const localState = JSON.parse(localStorage.state);
    return {
      headers: {
        ...headers,
        "X-User-Email": localState.auth.email,
        "X-User-Token": localState.auth.token,
      },
    };
  }
  // return the headers to the context so httpLink can read them
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const artzuUiTheme = {
  main: "#128DFF",
  lightGrey: "#DEDEDE",
  darkGrey: "#414141",
};

render(
  <ApolloProvider client={client}>
    <Provider store={store} bStore={bookingStore}>
      <ThemeProvider theme={artzuUiTheme}>
        <MuiThemeProvider theme={theme}>
          <Root />
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
