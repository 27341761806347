const frontPassengerWindow = [
	0.5295,
	0.8026,
	0.6538,
	0.811,
	0.6377,
	0.7908,
	0.6131,
	0.7638,
	0.5906,
	0.7436,
	0.5745,
	0.7352,
	0.5584,
	0.7284,
	0.538,
	0.7234,
	0.5263,
	0.7234
];

const rearPassengerWindow = [
	0.3923,
	0.7992,
	0.2905,
	0.7959,
	0.2883,
	0.7942,
	0.2894,
	0.7858,
	0.2958,
	0.7621,
	0.3044,
	0.7402,
	0.3119,
	0.7284,
	0.3226,
	0.7234,
	0.3355,
	0.7183,
	0.3591,
	0.7149,
	0.3816,
	0.7149,
	0.3966,
	0.7132
];

const centerPassengerWindow = [
	0.4105,
	0.7149,
	0.4116,
	0.7588,
	0.4126,
	0.7992,
	0.5113,
	0.8043,
	0.5102,
	0.7638,
	0.5091,
	0.7149,
	0.4716,
	0.7132,
	0.4319,
	0.7132
];

const frontPassengerDoor = [
	0.5209,
	0.9442,
	0.6302,
	0.9426,
	0.6356,
	0.9072,
	0.6388,
	0.8616,
	0.6377,
	0.8228,
	0.6367,
	0.811,
	0.6356,
	0.7824,
	0.6356,
	0.7706,
	0.6152,
	0.747,
	0.5927,
	0.7284,
	0.5756,
	0.7183,
	0.5584,
	0.7116,
	0.537,
	0.7082,
	0.5166,
	0.7065,
	0.5188,
	0.7706,
	0.5198,
	0.8009
];

const rearPassengerDoor = [
	0.3976,
	0.7014,
	0.3966,
	0.7099,
	0.3955,
	0.7402,
	0.3934,
	0.7756,
	0.3934,
	0.7959,
	0.3923,
	0.8043,
	0.3912,
	0.8228,
	0.3912,
	0.838,
	0.3923,
	0.8498,
	0.3955,
	0.8616,
	0.4009,
	0.8734,
	0.4062,
	0.8886,
	0.4094,
	0.8987,
	0.4126,
	0.9122,
	0.4137,
	0.9257,
	0.4148,
	0.9426,
	0.5198,
	0.9426,
	0.5198,
	0.8026,
	0.5166,
	0.7065,
	0.477,
	0.7031,
	0.4298,
	0.7014
];

const passengerBottomTrim = [
	0.3987,
	0.9544,
	0.6452,
	0.9561,
	0.6452,
	0.9442,
	0.3987,
	0.9426
];

const rearPassengerQuarterPanel = [
	0.3258,
	0.8751,
	0.3333,
	0.8701,
	0.3451,
	0.865,
	0.358,
	0.865,
	0.3698,
	0.8684,
	0.3794,
	0.8768,
	0.3859,
	0.8852,
	0.3912,
	0.897,
	0.3966,
	0.9173,
	0.3976,
	0.9426,
	0.4148,
	0.9426,
	0.4137,
	0.9223,
	0.4137,
	0.9139,
	0.4105,
	0.9021,
	0.4062,
	0.8852,
	0.4009,
	0.8734,
	0.3966,
	0.8633,
	0.3934,
	0.8549,
	0.3912,
	0.8414,
	0.3912,
	0.8127,
	0.3923,
	0.7992,
	0.2905,
	0.7959,
	0.2883,
	0.7942,
	0.2894,
	0.7874,
	0.2947,
	0.7655,
	0.3012,
	0.7487,
	0.3076,
	0.7352,
	0.313,
	0.7301,
	0.3237,
	0.7234,
	0.3408,
	0.7183,
	0.3623,
	0.7166,
	0.3966,
	0.7149,
	0.3966,
	0.7065,
	0.3976,
	0.7031,
	0.3601,
	0.7048,
	0.3323,
	0.7065,
	0.3194,
	0.7065,
	0.3076,
	0.7099,
	0.3023,
	0.7116,
	0.2958,
	0.7166,
	0.2915,
	0.7217,
	0.284,
	0.7335,
	0.2733,
	0.7571,
	0.2647,
	0.7824,
	0.2605,
	0.7976,
	0.2562,
	0.8043,
	0.2626,
	0.8026,
	0.2787,
	0.8043,
	0.2755,
	0.8549,
	0.2744,
	0.8633,
	0.2744,
	0.8717,
	0.2755,
	0.8734
];

const frontPassengerQuarterPanel = [
	0.6452,
	0.9426,
	0.6474,
	0.9156,
	0.6527,
	0.892,
	0.6624,
	0.8751,
	0.6774,
	0.8633,
	0.6945,
	0.8599,
	0.7095,
	0.8633,
	0.7181,
	0.8717,
	0.7256,
	0.8819,
	0.7481,
	0.8819,
	0.7503,
	0.8819,
	0.7513,
	0.8768,
	0.746,
	0.8583,
	0.7395,
	0.8363,
	0.7481,
	0.838,
	0.7535,
	0.838,
	0.7524,
	0.8346,
	0.7395,
	0.8245,
	0.7192,
	0.8161,
	0.6924,
	0.8077,
	0.6827,
	0.8043,
	0.6763,
	0.7992,
	0.6667,
	0.7992,
	0.6624,
	0.7976,
	0.6367,
	0.7699,
	0.6367,
	0.779,
	0.6581,
	0.806,
	0.6613,
	0.811,
	0.6613,
	0.8144,
	0.657,
	0.8161,
	0.6463,
	0.8161,
	0.6367,
	0.8144,
	0.6388,
	0.8414,
	0.6388,
	0.8684,
	0.6367,
	0.897,
	0.6345,
	0.9156,
	0.6324,
	0.9308,
	0.6302,
	0.9426
];

const frontPassengerBumper = [
	0.7256,
	0.8835,
	0.731,
	0.8987,
	0.7353,
	0.919,
	0.7363,
	0.9426,
	0.7363,
	0.9493,
	0.7385,
	0.951,
	0.7642,
	0.951,
	0.7781,
	0.9476,
	0.7878,
	0.9459,
	0.7889,
	0.9426,
	0.7899,
	0.9392,
	0.7889,
	0.9375,
	0.7867,
	0.9341,
	0.7856,
	0.9324,
	0.7856,
	0.9173,
	0.7889,
	0.9139,
	0.791,
	0.9139,
	0.791,
	0.9105,
	0.7899,
	0.892,
	0.7899,
	0.8869,
	0.7856,
	0.8819,
	0.7814,
	0.865,
	0.7781,
	0.8515,
	0.7749,
	0.8414,
	0.7728,
	0.8414,
	0.7674,
	0.8414,
	0.7738,
	0.8633,
	0.7696,
	0.8667,
	0.7653,
	0.8701,
	0.7599,
	0.8701,
	0.7535,
	0.8667,
	0.7481,
	0.865,
	0.7503,
	0.8717,
	0.7513,
	0.8785,
	0.7503,
	0.8802,
	0.7256,
	0.8819
];

const rearPassengerBumper = [
	0.3044,
	0.951,
	0.3065,
	0.951,
	0.3065,
	0.9392,
	0.3087,
	0.9139,
	0.314,
	0.8954,
	0.3194,
	0.8835,
	0.3248,
	0.8768,
	0.2947,
	0.8751,
	0.2787,
	0.8751,
	0.2755,
	0.8751,
	0.2755,
	0.8633,
	0.2744,
	0.8549,
	0.2722,
	0.8549,
	0.2594,
	0.865,
	0.2529,
	0.8667,
	0.254,
	0.8532,
	0.2551,
	0.8346,
	0.254,
	0.8296,
	0.2529,
	0.8296,
	0.2497,
	0.8768,
	0.2465,
	0.8802,
	0.2444,
	0.8852,
	0.2412,
	0.8954,
	0.2401,
	0.9038,
	0.239,
	0.9072,
	0.2369,
	0.9105,
	0.239,
	0.9206,
	0.2412,
	0.9274,
	0.2412,
	0.9308,
	0.2422,
	0.9358,
	0.2476,
	0.9426
];

const frontPassengerWheel = [0.6913, 0.9369];

const rearPassengerWheel = [0.3516, 0.9375];

const sidePassengerHeadlight = [
	0.7395,
	0.8363,
	0.7481,
	0.865,
	0.7567,
	0.8684,
	0.7631,
	0.8701,
	0.7663,
	0.8701,
	0.7685,
	0.8667,
	0.7706,
	0.8667,
	0.7749,
	0.865,
	0.7685,
	0.8414,
	0.7567,
	0.838,
	0.7471,
	0.8363
];

const sidePassengerTaillight = [
	0.2529,
	0.8667,
	0.2594,
	0.865,
	0.269,
	0.8583,
	0.2722,
	0.8566,
	0.2755,
	0.8566,
	0.2797,
	0.8043,
	0.2594,
	0.8043,
	0.2572,
	0.8077,
	0.2562,
	0.8262,
	0.2551,
	0.8279
];

const passengerSideMirror = [
	0.6141,
	0.7992,
	0.6152,
	0.8009,
	0.6184,
	0.806,
	0.6217,
	0.8077,
	0.6259,
	0.8077,
	0.6292,
	0.8077,
	0.6313,
	0.8043,
	0.6324,
	0.7976,
	0.6313,
	0.7824,
	0.6292,
	0.779,
	0.6206,
	0.7739,
	0.6174,
	0.7739,
	0.6141,
	0.779
];

const passengerBonnet = [
	0.6774,
	0.8009,
	0.686,
	0.806,
	0.6988,
	0.8094,
	0.717,
	0.8161,
	0.731,
	0.8228,
	0.7438,
	0.8279,
	0.7503,
	0.833,
	0.7524,
	0.8363,
	0.7578,
	0.8397,
	0.7663,
	0.8414,
	0.7706,
	0.8414,
	0.7738,
	0.8414,
	0.7749,
	0.8414,
	0.7706,
	0.833,
	0.7663,
	0.8279,
	0.7556,
	0.8212,
	0.7438,
	0.8161,
	0.7288,
	0.8094,
	0.7031,
	0.8043,
	0.6924,
	0.8009,
	0.687,
	0.7992
];

export {
	frontPassengerWindow,
	rearPassengerWindow,
	centerPassengerWindow,
	frontPassengerDoor,
	rearPassengerDoor,
	passengerBottomTrim,
	rearPassengerQuarterPanel,
	frontPassengerQuarterPanel,
	frontPassengerBumper,
	rearPassengerBumper,
	frontPassengerWheel,
	rearPassengerWheel,
	sidePassengerHeadlight,
	sidePassengerTaillight,
	passengerSideMirror,
	passengerBonnet
};
