import React from "react";
import Button from "../../../artzu/src/Button";
import PlateStatus from "../../Fleet/PlateStatus";
import classNames from "classnames";

import { Link } from "react-router-dom";
import { formatOdometer } from "../../../utils";
import styles from "./vehicleDrawer.module.scss";

import { faTachometerAlt, faGasPump } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getColor = (status) => {
  switch (status) {
    case "good":
      return "green";
    case "warning":
      return "orange";
    case "bad":
      return "red";
    case "no_device":
      return "grey";
    default:
      return "grey";
  }
};
const VehicleDetails = ({
  gpsStatus,
  id,
  store,
  vin,
  odometer,
  city,
  gasLevel,
  coolantTemp,
  status,
  booking,
  parkingHub,
}) => {
  console.log(status);
  return (
    <div className="vehicle-details-map">
      <div className="vehicle-information-map-container">
        <div>
          <div className="small-info">Operation Status:</div>
          <PlateStatus operationStatus={status} />
        </div>
        <div className="details-button-map-container">
          <div className="readings-map-container">
            <div className="fuel-map-container">
              <div className="odometer-map-icons">
                <FontAwesomeIcon
                  style={{ fontSize: "17px" }}
                  icon={faTachometerAlt}
                />
              </div>
              <div className="odometer-map-icons">
                {formatOdometer(odometer.reading) +
                  (city === "toronto" ? " km" : " mi")}
              </div>
            </div>
            <div className="fuel-map-container">
              <div className="fuel-map-icons">
                <FontAwesomeIcon
                  style={{ fontSize: "17px" }}
                  icon={faGasPump}
                />
              </div>
              <div className="fuel-map-bar">
                {gasLevel ? (
                  <div
                    className="fuel-map-fill"
                    style={{ width: `${gasLevel.reading}%` }}
                  ></div>
                ) : null}
              </div>
              <div className="odometer-map-icons">
                {gasLevel ? `${gasLevel.reading}%` : "--"}
              </div>
            </div>
          </div>
          <Link
            className="link"
            to={{
              pathname: `/vehicles/${id}`,
              state: { id: id, fromMap: true },
            }}
          >
            <Button
              onClick={() => store.changeMode("current")}
              fullWidth="154px"
              height="39px"
              color="#414141"
              weight="600"
              label="Vehicle Details"
            />
          </Link>
        </div>
        {coolantTemp ? (
          <div className="vehicle-map-information">
            <div className="small-info">Coolant Temperature</div>
            <div className="large-info">{coolantTemp} &#176;C</div>
          </div>
        ) : null}

        <div className="vehicle-map-information">
          <p className="small-info">Vehicle Identification Number (VIN)</p>
          <p className="large-info">{vin || "--"}</p>
        </div>
        <div className="vehicle-map-information">
          <div className="small-info">Parking Hub</div>
          <div className="large-info">{parkingHub ? parkingHub : "--"}</div>
        </div>
        <div className="vehicle-info-map-type tight-margin" />
      </div>
    </div>
  );
};

export default VehicleDetails;
