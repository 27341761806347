import React from "react";
import styles from "./parkingSpot.module.scss";
import { TableRow } from "../../artzu/src/Table";
import BasicFieldCellValue from "./BasicFieldCellValue";

const ParkingSpotBasics = ({ parkingSpotData }) => {
  const status = parkingSpotData?.status;
  const plate = parkingSpotData?.licensePlate;
  const updated = parkingSpotData?.updatedAt;
  const timeInStatus = parkingSpotData?.timeInStatus;
  const booking = parkingSpotData?.bookingReference;
  const charger = parkingSpotData?.hasCharger;
  console.log(parkingSpotData);
  return (
    <TableRow
      padding="0.8em 0.8em 24px 0"
      align="center"
      width="100%"
      minHeight="64px"
      color="#FFFFFF"
      justifyIpad="space-between"
      borderB="1px solid #dedede"
    >
      <BasicFieldCellValue text={"Status"} value={status} />
      <BasicFieldCellValue text={"License plate"} value={plate} />
      <BasicFieldCellValue text={"Last updated"} value={updated} />
      <BasicFieldCellValue text={"Time in spot"} value={timeInStatus} />
      <BasicFieldCellValue text={"Charger"} value={charger} />
      <BasicFieldCellValue text={"Related booking"} value={booking} />
    </TableRow>
  );
};

export default ParkingSpotBasics;
