import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_VEHICLE_TAGS } from "./queries";
import _ from "lodash";
import AddTagContent from "./AddTagModal/AddTagContent";

const AddTag = ({ selectedOption, selections, close, toggleRefresh }) => {
  console.log(selections);
  const selectedVehicles = [];
  selections.map((v) => selectedVehicles.push(v));

  const { loading, error, data, refetch } = useQuery(GET_VEHICLE_TAGS, {
    variables: {
      vehicleIds: selectedVehicles,
    },
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading...</p>;

  let allTags = data.getAllVehicleTags.allTags;
  const selectedTags = [];
  let vehicleList = data.getAllVehicleTags.vehicles;
  vehicleList.map((vehicle) =>
    vehicle.tags.map((item) => selectedTags.push(item.name))
  );
  const sortedSelectedTags = [...selectedTags];
  sortedSelectedTags.sort();
  //get an object which is a tally of tags
  var tagTally = [...sortedSelectedTags].reduce((tally, tag) => {
    tally[tag] = (tally[tag] || 0) + 1;
    return tally;
  }, {});
  const entryArray = Object.entries(tagTally);
  //filter the tally
  const countMatchPairs = entryArray.filter(
    ([k, v]) => v === vehicleList.length
  );
  //get the commontags from filtered entries array
  const commonTags = Object.keys(Object.fromEntries(countMatchPairs));

  const tagsToRemove = [];
  const deleteCommonTags = (childData) => {
    tagsToRemove.push(childData);
  };

  return (
    <AddTagContent
      selectedOption={selectedOption}
      close={close}
      vehicles={vehicleList}
      alltags={allTags}
      commonTags={commonTags}
      callback={deleteCommonTags}
      toRemove={tagsToRemove}
      refetch={refetch}
      toggleRefresh={toggleRefresh}
    />
  );
};

export default AddTag;
