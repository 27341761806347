import React from "react";
import classNames from "classnames";

import DateSelection from "./DateSelection";
import TextBox from "./TextBox";
import ServiceSelection from "./ServiceSelection";
import styles from "./service.module.scss";

const ServiceEventInputs = ({
  data,
  onEdit,
  serviceCatsNames,
  action,
  endDateSelected,
  toggleEndDate
}) => (
  <div className="modal-top">
    <div className="column">
      <ServiceSelection
        prompt={action === "new" ? "Select" : data.name}
        title="Name"
        accessor="name"
        onEdit={onEdit}
        serviceCatsNames={serviceCatsNames}
        action={action}
      />
      <TextBox
        val={data.vendor}
        label="Vendor"
        accessor="vendor"
        onEdit={onEdit}
      />
      <TextBox val={data.cost} label="Cost" accessor="cost" onEdit={onEdit} />
      <TextBox
        val={data.odometerReading}
        label="Odometer"
        accessor="odometerReading"
        onEdit={onEdit}
      />
      <DateSelection
        scheduled={data.scheduled}
        scheduledDate={data.scheduledDate}
        scheduledEndDate={data.scheduledEndDate}
        complete={data.complete}
        completedDate={data.completedDate}
        onChange={onEdit}
        endDateSelected={endDateSelected}
        toggleEndDate={toggleEndDate}
      />
    </div>
    <div className={classNames("column", styles.description)}>
      <h4 className="details tight-margin">Description</h4>
      <textarea
        className="textbox-input description"
        placeholder="Write a description..."
        value={data.description}
        rows="25"
        cols="25"
        onChange={evt => onEdit("description", evt.target.value)}
      />
    </div>
  </div>
);

export default ServiceEventInputs;
