import React, { useState } from "react";
import { TableRow } from "../../../artzu/src/Table";
import { rowComponents } from "../rowData";
import { rowComponentsTimeline } from "../rowDataTimeline";
import { rowComponentsTask } from "../rowDataTask";
import { processTableData } from "../misc/helperFuncs";
import styles from "../informationTable.module.scss";
import RowSelect from "../CellValues/RowSelect";
import { Link } from "react-router-dom";
import TableCell from "./TableCell";
import { rowComponentsSpot } from "../rowDataSpot";
import { rowComponentsDamage } from "../rowDataDamage";
const Body = ({
  rows,
  selectedRows,
  selectRow,
  isEditModeActivated,
  modifyEdits,
  edits,
  tableName,
  rowComponentType,
  popupSpecific,
  popupFunc,
  vehicleTimeZone,
}) => {
  const parsedTableData = processTableData(rows);
  const [expandedColumn, setExpandedColumn] = useState(null);
  const [selectedRow, setSelectedRow] = useState();

  const isTimeLine = tableName === "timeline_entries";

  const selectable = popupSpecific
    ? false
    : tableName === "timeline_entries"
    ? false
    : true;

  const getComponentType = (component, timeZone) => {
    switch (component) {
      case "fleet":
        return rowComponents(timeZone);
      case "timeline":
        return rowComponentsTimeline(vehicleTimeZone);
      case "task":
        return rowComponentsTask(timeZone);
      case "parking_spot":
        return rowComponentsSpot(timeZone);
      case "damage":
        return rowComponentsDamage(timeZone);
      default:
        return null;
    }
  };

  const setMargin = (column) => {
    // console.log(column);
    if (column === "date") {
      return "0 0 0 59px";
    } else if (column === "user") {
      return "0 0 0 58px";
    } else if (column === "category") {
      return "0 0 0 102px";
    } else if (column === "details") {
      return "0 0 0 59px";
    } else {
      return null;
    }
  };

  const setWidth = (column) => {
    if (column === "date") {
      return "130px";
    } else if (column === "user") {
      return "130px";
    } else if (column === "category") {
      return "130px";
    } else if (column === "details") {
      return "fit-content";
    } else {
      return null;
    }
  };

  return (
    <div>
      {parsedTableData.map(({ rowData, rowId, rowLink, rowTimeZone }) => {
        const isRowExpanded = expandedColumn && expandedColumn.rowId == rowId;
        return !isTimeLine && !popupSpecific ? (
          <Link
            key={rowId}
            className={styles.link}
            to={{
              pathname: `${rowLink}`,
              state: { id: rowId },
            }}
          >
            <TableRow
              padding="0.8em 0.8em 0.8em 0em"
              align="center"
              height={isRowExpanded ? "" : "fit-content"}
              minWidth={"100%"}
              color={
                popupSpecific
                  ? selectedRow === rowId
                    ? "rgba(0, 0, 0, 0.1)"
                    : "#FFFFFF"
                  : "#FFFFFF"
              }
              justifyIpad="space-between"
              justify={!isTimeLine ? "space-between" : "flex-start"}
              margin="0 0 12px 0"
              hoverColor="#dedede"
              hover
            >
              {selectable && (
                <TableCell width="64px" onClick={(evt) => evt.preventDefault()}>
                  <RowSelect
                    rowId={rowId}
                    onClick={() => selectRow(rowId)}
                    isSelected={selectedRows[rowId]}
                  />
                </TableCell>
              )}
              {rowData.map((column) => {
                let cellData;
                const rowInfo = getComponentType(rowComponentType, rowTimeZone)[
                  column.columnName
                ];
                // console.log(rowInfo);
                if (rowInfo) {
                  const ColumnComponent = rowInfo.component;
                  // console.log(ColumnComponent);
                  if (rowInfo.dataProcessor) {
                    cellData = rowInfo.dataProcessor(column.columnData);
                  }

                  const rowExpansionHandlers = {
                    onMouseEnter: () =>
                      setExpandedColumn({
                        rowId,
                        columnName: column.columnName,
                      }),
                    onMouseLeave: () => setExpandedColumn(null),
                  };

                  const editedRowValues = edits[rowId];
                  const editedValue =
                    editedRowValues &&
                    editedRowValues[column.columnName] &&
                    editedRowValues[column.columnName].newValue;

                  const isColumnExtended =
                    isRowExpanded &&
                    expandedColumn &&
                    expandedColumn.columnName == column.columnName;
                  return (
                    <TableCell
                      width="140px"
                      display={"flex"}
                      margin={setMargin(column.columnName)}
                      justify={column.columnName === "category" && "flex-start"}
                    >
                      <ColumnComponent
                        isExpanded={isColumnExtended}
                        rowExpansionHandlers={rowExpansionHandlers}
                        classes={
                          !isTimeLine
                            ? [styles.tableCell]
                            : [styles.tableCellFlex]
                        }
                        cellData={cellData}
                        columnName={column.columnName}
                        rowId={rowId}
                        isEditModeActivated={isEditModeActivated}
                        modifyEdits={modifyEdits}
                        editedValue={editedValue}
                      />
                    </TableCell>
                  );
                } else {
                  return <TableCell width={"140px"}>NULL</TableCell>;
                }
              })}
            </TableRow>
          </Link>
        ) : (
          <TableRow
            padding="0.8em 0.8em 0.8em 0em"
            align="center"
            height={isRowExpanded ? "" : "fit-content"}
            minWidth={"100%"}
            color={selectedRow === rowId ? "rgba(0, 0, 0, 0.1)" : "#FFFFFF"}
            justifyIpad="space-between"
            justify={!isTimeLine ? "space-evenly" : "flex-start"}
            margin="0 0 12px 0"
            hoverColor="#dedede"
            hover
            key={rowId}
            onClick={() => {
              setSelectedRow(rowId);
              popupSpecific && popupFunc(rowId);
            }}
          >
            {selectable && (
              <TableCell width="64px" onClick={(evt) => evt.preventDefault()}>
                <RowSelect
                  rowId={rowId}
                  onClick={() => selectRow(rowId)}
                  isSelected={selectedRows[rowId]}
                />
              </TableCell>
            )}
            {rowData.map((column, index) => {
              // console.log(column);
              let cellData;
              const rowInfo = getComponentType(rowComponentType, rowTimeZone)[
                column.columnName
              ];
              // console.log(rowInfo);
              if (rowInfo) {
                const ColumnComponent = rowInfo.component;
                // console.log(ColumnComponent);
                if (rowInfo.dataProcessor) {
                  cellData = rowInfo.dataProcessor(column.columnData);
                  // console.log(cellData);
                }

                const rowExpansionHandlers = {
                  onMouseEnter: () =>
                    setExpandedColumn({
                      rowId,
                      columnName: column.columnName,
                    }),
                  onMouseLeave: () => setExpandedColumn(null),
                };

                const editedRowValues = edits[rowId];
                const editedValue =
                  editedRowValues &&
                  editedRowValues[column.columnName] &&
                  editedRowValues[column.columnName].newValue;

                const isColumnExtended =
                  isRowExpanded &&
                  expandedColumn &&
                  expandedColumn.columnName == column.columnName;
                return (
                  <TableCell
                    width={setWidth(column.columnName)}
                    display={"flex"}
                    margin={setMargin(column.columnName)}
                    justify={column.columnName === "category" && "flex-start"}
                    key={index}
                  >
                    <ColumnComponent
                      isExpanded={isColumnExtended}
                      rowExpansionHandlers={rowExpansionHandlers}
                      classes={
                        !isTimeLine
                          ? [styles.tableCell]
                          : [styles.tableCellFlex]
                      }
                      cellData={cellData}
                      columnName={column.columnName}
                      rowId={rowId}
                      isEditModeActivated={isEditModeActivated}
                      modifyEdits={modifyEdits}
                      editedValue={editedValue}
                    />
                  </TableCell>
                );
              } else {
                return <TableCell width={"140px"}>NULL</TableCell>;
              }
            })}
          </TableRow>
        );
      })}
    </div>
  );
};

export default Body;
