import React from "react";
import styles from "./statusInfo.module.scss";
import { vehicleStatusToStyle } from "../../../../utils";

const OperationStatusSymbol = ({ operationStatus }) => (
  <div
    className={styles.operationSymbol}
    style={
      ["Available", "Deactivated", "Offline"].includes(operationStatus)
        ? {
            border: vehicleStatusToStyle(operationStatus),
            background: "white"
          }
        : {
            background: vehicleStatusToStyle(operationStatus)
          }
    }
  />
);

export default OperationStatusSymbol;
