import React from "react";

import Option from "../../artzu/src/Option";
import SelectFieldTool from "./SelectFieldTool";

const FilterSelect = ({
  selectOptions = [],
  selectionType,
  val,
  onChange,
  interiorOnChange,
  name,
  all,
  classes,
}) => (
  <div className={`select-container ${all ? "select-title" : ""}`}>
    <p className="filter-title">{name}: </p>
    <SelectFieldTool
      prompt="Select"
      width="11em"
      onChange={(val, heading) => {
        if (all) {
          onChange(selectionType, val);
        } else {
          onChange({ [selectionType]: val });
        }
        if (interiorOnChange) {
          interiorOnChange(val);
        }
      }}
    >
      {selectOptions.map((option, index) => (
        <Option key={index + 1} label={option.name} value={option.id} />
      ))}
    </SelectFieldTool>
  </div>
);

export default FilterSelect;
