import React from "react";
import moment from "moment";

const GasLevel = ({ gasLevel }) => {
  return (
    gasLevel && (
      <div className="vehicle-information">
        <div className="tight-margin-details">Gas level</div>
        <div className="tight-margin-content">
          <div style={{ textAlign: "left", fontWeight: "bold" }}>
            {gasLevel.reading}%
          </div>
          <div style={{ fontSize: "70%", color: "grey" }}>
            Last updated{" "}
            {moment(gasLevel.capturedAt).format("MMM D, YYYY h:mm a")}
          </div>
        </div>
      </div>
    )
  );
};

export default GasLevel;
