import React from "react";

const VehicleTab = ({ name, type, display, toggle }) => (
  <h4
    className={`details tab-options-map top`}
    onClick={() => toggle(type)}
    style={
      display === type
        ? {
            color: "#414141",
            backgroundColor: "#FFFFFF",
            opacity: "1"
          }
        : null
    }
  >
    {name}
  </h4>
);

export default VehicleTab;
