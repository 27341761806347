import React, { Fragment, useState, useEffect } from "react";
import gql from "graphql-tag";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowFromBottom, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useDropzone } from "react-dropzone";

import Button from "../../../artzu/src/Button";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";
import TextField from "../../../artzu/src/TextField";

import { m } from "../../../utils";
import Loading from "./Loading";
import { directUploadFile } from "../../../utils/FileUploads";
import { useApolloClient } from "@apollo/client";

const OPTION_LIST = [
  "dmv_registration",
  "ownership",
  "safety",
  "service_invoice",
  "temporary_dmv_registration",
  "tlc",
  "tlc_inspection_appointment",
  "tlc_inspection_fail",
  "tlc_inspection_pass",
  "toll",
  "insurance"
];

const UPDATE_DOCUMENT = gql`
  mutation updateDocument($document: UpdateDocumentAttributes!) {
    updateDocument(params: $document) {
      success
      errors
    }
  }
`;

const EditDocumentModalChild = ({ doc, handleClose, refetch, client }) => {
  const { id, name: docName, imageLink, expiryDate: docExpiryDate } = doc;
  const [name, setName] = useState(docName || "");
  const [expiryDate, setExpiryDate] = useState(docExpiryDate || null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(imageLink || null);
  const [otherInput, setOtherInput] = useState(false);
  const [other, setOther] = useState(
    OPTION_LIST.includes(docName) ? false : true
  );
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (other) {
      setOtherInput(true);
    }
  }, [other]);

  const handleInputChange = val => {
    switch (val) {
      case "other":
        setOtherInput(true);
        setName(null);
        break;
      default:
        setOtherInput(false);
        setName(val);
    }
  };

  const formValidation = (file, name) => {
    const errors = [];
    if (!file) {
      errors.push("File required.");
    }
    if (!name) {
      errors.push("Title can't be blank.");
    }
    return errors;
  };

  const handleSubmit = () => {
    const document = {
      id,
      name,
      expiryDate
    };

    const errors = formValidation(filePreview, name);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    } else {
      setLoading(true);
      directUploadFile(file, client).then(fileBlob => {
        client
          .mutate({
            mutation: UPDATE_DOCUMENT,
            variables: {
              document: {
                ...document,
                fileBlob
              }
            }
          })
          .then(res => {
            setLoading(false);
            if (res.data.updateDocument.success) {
              refetch();
              handleClose();
            } else {
              setErrors(res.data.updateDocument.errors);
            }
          });
      });
    }
  };

  const FileDiv = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        setFile(acceptedFiles[0]);
        setFilePreview(URL.createObjectURL(acceptedFiles[0]));
      }
    });

    return (
      <>
        <div
          className="upload__modal__preview"
          style={
            file
              ? { "background-image": `url(${filePreview})` }
              : { "background-color": "#FFFFFF", opacity: "0.9" }
          }
        >
          <div className="upload-box">
            <div
              className="upload__modal__preview__icon"
              onClick={() => {
                document.getElementById("image-upload").click();
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "#414141", "margin-left": "14px" }}
                size="2x"
              />
              <div style={{ color: "#414141" }}>
                {file ? "Update" : "Upload"}
              </div>
            </div>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps({ id: "image-upload" })} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <div className="upload__modal">
        {loading ? (
          <Loading />
        ) : (
          <div className="upload__modal__inputs">
            <div style={{ marginRight: "79px" }}>
              <Fragment>
                <div className="upload__modal__inputs__title">
                  <div className="upload__modal__inputs__title--name">
                    Title:
                  </div>
                  <SelectField
                    prompt={other ? "Other" : docName}
                    onChange={val => handleInputChange(val)}
                    primaryColor="#414141"
                    className="upload__modal__inputs__title--options"
                    width="289px"
                    radius="0px"
                    height="24px"
                    text="17.5px"
                    color="#414141"
                    weight="0"
                    padding="0.7em"
                  >
                    <Option label="DMV Registration" value="dmv_registration" />
                    <Option label="Ownership" value="ownership" />
                    <Option label="Safety Inspection" value="safety" />
                    <Option label="Service Invoice" value="service_invoice" />
                    <Option
                      label="Temporary DMV Registration"
                      value="temporary_dmv_registration"
                    />
                    <Option label="TLC License" value="tlc" />
                    <Option
                      label="TLC Inspection Appointment"
                      value="tlc_inspection_appointment"
                    />
                    <Option
                      label="TLC Inspection FAIL"
                      value="tlc_inspection_fail"
                    />
                    <Option
                      label="TLC Inspection PASS"
                      value="tlc_inspection_pass"
                    />
                    <Option label="Toll Fee" value="toll" />
                    <Option label="Vehicle Insurance" value="insurance" />
                    <Option label="Other" value="other" />
                  </SelectField>
                </div>
                {otherInput ? (
                  <div className="upload__modal__inputs__title--other">
                    <TextField
                      placeholder="Other"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      width="15em"
                    />
                  </div>
                ) : null}
                <div className="upload__modal__inputs__expDate">
                  <div className="upload__modal__inputs__expDate--name">
                    Expiry Date:
                  </div>
                  <DatePicker
                    selected={expiryDate && m(expiryDate)}
                    onChange={e => setExpiryDate(e)}
                    placeholderText="Date"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    className="upload__modal__inputs__expDate--input"
                  />
                </div>
                <br />
                {errors.length > 0 ? (
                  <div>
                    {errors.map(error => (
                      <div className="upload__modal__error"> {error}</div>
                    ))}
                  </div>
                ) : null}
              </Fragment>
            </div>
            <div>
              <div style={{ fontWeight: "bold", fontSize: "17.5px" }}>
                Document Image/File
              </div>
              <FileDiv />
            </div>
          </div>
        )}
      </div>
      <div className="upload__modal__inputs">
        <div className="upload__modal__inputs__buttons">
          <Button
            color="#414141"
            label="Cancel"
            onClick={handleClose}
            border="none"
            width="154px"
            height="40px"
            weight="bold"
          />
          <Button
            color="#128DFF"
            label="Add"
            onClick={handleSubmit}
            border="none"
            width="154px"
            height="40px"
            weight="bold"
          />
        </div>
      </div>
    </Fragment>
  );
};

const EditDocumentModal = props => {
  const client = useApolloClient();
  return <EditDocumentModalChild client={client} {...props} />;
};

export default EditDocumentModal;
