import React from "react";
import ImageUpload from "./ImageUpload";
import NewDamageReport from "../../Mutations/NewDamageReport";
import "../Vehicle/vehicle.scss";
import "./damage.scss";

const DamageButtons = ({
  file,
  newDamageReportParams,
  alterDamageState,
  updateDamage,
  refetch
}) => (
  <div className="damage-buttons-container">
    <ImageUpload updateDamage={updateDamage} uploaded={file ? true : false} />
    {newDamageReportParams.damage.location &&
    newDamageReportParams.damage.location !== "Other" ? (
      <NewDamageReport
        file={file}
        alterDamageState={alterDamageState}
        refetch={refetch}
        newDamageReportParams={newDamageReportParams}
      />
    ) : null}
  </div>
);

export default DamageButtons;
