const formatLotName = (lot) => {
  switch (lot) {
    case "621 King St. W (A)":
      return "TOR/PT";
    case "621 King St. W (B)":
      return "TOR/PT";
    case "280 Bloor St. W":
      return "TOR/PT";
    case "Downtown":
      return "TOR/PT";
    case "Embarcadero Center":
      return "SF/PT";
    case "Union Street":
      return "SF/PT";
    case "Mission":
      return "SF/PT";
    case "Secaucus, NJ":
      return "NYC/PT";
    case "LIC Centre Blvd":
      return "NYC/PT";
    case "Skyport":
      return "NYC/PT";
    case "Skyport (Outdoor)":
      return "NYC/PT";
    case "Langhorne, PA":
      return "NYC/PT";
    case "Weekly Lease":
      return "NYC/PT";
    case "Multiple Lots":
      return "MULT";
  }
};

export default formatLotName;
