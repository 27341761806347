import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const rearBumper = [
	0.0343,
	0.6627,
	0.0343,
	0.6694,
	0.0407,
	0.6761,
	0.0461,
	0.6795,
	0.0568,
	0.6846,
	0.0686,
	0.6863,
	0.0836,
	0.6896,
	0.0954,
	0.6913,
	0.1008,
	0.6896,
	0.1018,
	0.6896,
	0.1072,
	0.6863,
	0.1125,
	0.6829,
	0.1136,
	0.6778,
	0.105,
	0.6778,
	0.104,
	0.6761,
	0.1018,
	0.6745,
	0.0975,
	0.6677,
	0.0954,
	0.661,
	0.0954,
	0.6492,
	0.0825,
	0.6475,
	0.0729,
	0.6441,
	0.0697,
	0.6391,
	0.0686,
	0.6289,
	0.0686,
	0.5311,
	0.0686,
	0.3844,
	0.0686,
	0.3659,
	0.0718,
	0.3608,
	0.0761,
	0.3558,
	0.0857,
	0.3524,
	0.0954,
	0.3507,
	0.0954,
	0.3423,
	0.0965,
	0.3355,
	0.1008,
	0.3288,
	0.104,
	0.3254,
	0.105,
	0.3221,
	0.1136,
	0.3237,
	0.1136,
	0.3187,
	0.1018,
	0.3103,
	0.089,
	0.3103,
	0.075,
	0.3119,
	0.0579,
	0.317,
	0.0482,
	0.3187,
	0.0418,
	0.3221,
	0.0343,
	0.3305,
	0.0343,
	0.3305,
	0.0343,
	0.3339,
	0.0332,
	0.3339,
	0.0343,
	0.6643
];

const rearTrunk = [
	0.0686,
	0.6222,
	0.0686,
	0.634,
	0.0718,
	0.6407,
	0.0793,
	0.6475,
	0.0932,
	0.6492,
	0.1436,
	0.6475,
	0.2069,
	0.6289,
	0.2111,
	0.6289,
	0.2133,
	0.6239,
	0.2154,
	0.5682,
	0.2165,
	0.494,
	0.2154,
	0.4249,
	0.2133,
	0.3811,
	0.2133,
	0.3743,
	0.2111,
	0.3693,
	0.2058,
	0.371,
	0.1758,
	0.3608,
	0.1436,
	0.3541,
	0.1093,
	0.3507,
	0.0922,
	0.3507,
	0.0793,
	0.3541,
	0.074,
	0.3558,
	0.0697,
	0.3625,
	0.0665,
	0.371,
	0.0675,
	0.5261,
	0.0675,
	0.6036,
	0.0675,
	0.6239
];

const rearDriverTaillight = [
	0.0932,
	0.3507,
	0.1125,
	0.3524,
	0.1297,
	0.3524,
	0.1415,
	0.3541,
	0.1415,
	0.3322,
	0.1404,
	0.3254,
	0.1393,
	0.3221,
	0.1286,
	0.3204,
	0.1158,
	0.3187,
	0.1093,
	0.3187,
	0.1093,
	0.3221,
	0.1029,
	0.3221,
	0.0975,
	0.3288,
	0.0943,
	0.3372
];

const rearPassengerTaillight = getMirrorImage(rearDriverTaillight);

const rearWindshield = [
	0.1468,
	0.3592,
	0.1468,
	0.4485,
	0.1468,
	0.5075,
	0.1468,
	0.5767,
	0.1479,
	0.6407,
	0.1683,
	0.6374,
	0.1983,
	0.6289,
	0.2047,
	0.6273,
	0.2058,
	0.5615,
	0.2058,
	0.4991,
	0.2058,
	0.4384,
	0.2047,
	0.3929,
	0.2036,
	0.3726,
	0.1736,
	0.3642
];

const rearWipers = [
	0.1468,
	0.3592,
	0.1479,
	0.4418,
	0.1479,
	0.5075,
	0.1479,
	0.5632,
	0.1479,
	0.6138,
	0.1468,
	0.6407,
	0.149,
	0.6407,
	0.1543,
	0.6391,
	0.1565,
	0.5632,
	0.1576,
	0.4991,
	0.1565,
	0.4435,
	0.1554,
	0.3811,
	0.1554,
	0.3608
];

const rearPlate = [0.1224, 0.45, 0.1006, 0.45, 0.1006, 0.55, 0.1224, 0.55];

const rearDriverSideMirror = [
	0.135,
	0.3221,
	0.1383,
	0.3221,
	0.1415,
	0.3254,
	0.1468,
	0.3288,
	0.1543,
	0.3322,
	0.1543,
	0.3153,
	0.1522,
	0.3086,
	0.149,
	0.3052,
	0.1404,
	0.3052,
	0.1383,
	0.3103,
	0.1361,
	0.3153
];

const rearPassengerSideMirror = getMirrorImage(rearDriverSideMirror);

export {
	rearBumper,
	rearTrunk,
	rearDriverTaillight,
	rearPassengerTaillight,
	rearWindshield,
	rearWipers,
	rearPlate,
	rearDriverSideMirror,
	rearPassengerSideMirror
};
