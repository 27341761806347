import React from "react";
import styles from "../statusInfo.module.scss";

const TagChangeDetail = ({ content }) => {
  return (
    <div className={styles.comments}>
      <div className={styles.tags}>
        {content.leadingText}
        <div className={styles.changedTag}>{content.tagName}</div>
      </div>
    </div>
  );
};

export default TagChangeDetail;
