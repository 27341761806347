import React, { useEffect, useState } from "react";
import Modal from "../../../../../artzu/src/Modal";
import Footer from "./Footer";
import Body from "./Body";
import _ from "lodash";
import styles from "./filterOptions.module.scss";
import { v4 } from "uuid";

const OptionsModal = ({
  isOpen,
  closeModal,
  availableFilters,
  savedFilters,
  changeFilters,
  saveFilterGroup,
  selectedFilterGroup,
  clearSelectedFilter,
}) => {
  const generateBaseFilterGroup = () => ({
    uuid: v4(),
    filters: [generateBaseFilter()],
  });

  const generateBaseFilter = () => ({
    keyUuid: v4(),
    isExcluded: null,
    categoryUuid: null,
    uuid: null,
    values: null,
    errors: {},
  });

  const [filterGroup, setFilterGroup] = useState({});
  const setToBase = () =>
    setFilterGroup(selectedFilterGroup || generateBaseFilterGroup());

  useEffect(() => {
    setToBase();
  }, [selectedFilterGroup]);

  const onFilterEdit = (index, changedValues) => {
    const filterGroupCopy = _.cloneDeep(filterGroup);
    filterGroupCopy.filters[index] = {
      ...filterGroupCopy.filters[index],
      ...changedValues,
    };
    setFilterGroup(filterGroupCopy);
  };

  const addBlankFilter = () => {
    const currentFilters = filterGroup.filters;

    setFilterGroup({
      ...filterGroup,
      filters: [...currentFilters, generateBaseFilter()],
    });
  };

  const removeFilter = (index) => {
    let filtersCopy = [...filterGroup.filters];
    delete filtersCopy[index];
    filtersCopy = filtersCopy.filter((el) => el);
    setFilterGroup({ ...filterGroup, filters: filtersCopy });
  };

  const checkForErrors = () => {
    let errorsArePresent = false;
    const newFilters = filterGroup.filters.map((filter) => {
      const errors = _.reduce(
        filter,
        (output, value, key) => {
          if (value === null) {
            errorsArePresent = true;
            output[key] = "No value present.";
          }
          return output;
        },
        {}
      );
      return {
        ...filter,
        errors: errors,
      };
    });

    const filterGroupCopy = _.cloneDeep(filterGroup);
    filterGroupCopy.filters = newFilters;
    setFilterGroup(filterGroupCopy);
    return errorsArePresent;
  };

  const rightButtonFunction = () => {
    const areAnyErrors = checkForErrors();
    if (!areAnyErrors) {
      saveFilterGroup(filterGroup);
      closeModal();
      clearSelectedFilter();
    }
  };
  const filterNumber = isNaN(filterGroup.selectedGroupIndex)
    ? ""
    : filterGroup.selectedGroupIndex + 1;

  return (
    <Modal padding="none" backgroundColor="none" open={isOpen}>
      <div className={styles.filterContainer}>
        <div className={styles.filterContainerTop}>
          <div className={styles.header}>Filter {filterNumber}</div>
          <Body
            availableFilters={availableFilters}
            savedFilters={savedFilters}
            changeFilters={changeFilters}
            removeFilter={removeFilter}
            addBlankFilter={addBlankFilter}
            onFilterEdit={onFilterEdit}
            filters={filterGroup.filters}
          />
        </div>
        <Footer
          leftButtonText="Cancel"
          rightButtonText="Apply"
          leftButtonFunction={() => {
            closeModal();
            setToBase();
            clearSelectedFilter();
          }}
          rightButtonFunction={
            filterGroup.filters &&
            filterGroup.filters.length > 0 &&
            rightButtonFunction
          }
        />
      </div>
    </Modal>
  );
};

export default OptionsModal;
