const colorAnimation = (fromColor, toColor) => `
	from {
		border-bottom: 2px solid ${fromColor};
	}

	to {
		border-bottom: 2px solid ${toColor};
	}
`

const errorAnimation = () => `
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

const extendAnimation = width => `
	from {
		width: 0;
	}
	to {
		width: ${width}
	}
`

export { colorAnimation, errorAnimation, extendAnimation }
