import React from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch
} from "@material-ui/core/";

import {
  faCertificate,
  faListAlt,
  faFileAlt
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  }
});

const insuranceCheckupList = [
  { accessor: "insuranceSlip", name: "Insurance Slip", icon: faFileAlt },
  { accessor: "vehicleOwnership", name: "Vehicle Ownership", icon: faListAlt },
  {
    accessor: "safetyCertificate",
    name: "Safety Certificate",
    icon: faCertificate
  }
];

const VehicleDocumentCheckupList = ({ classes, data, onEdit }) => (
  <List
    subheader={
      <ListSubheader className={classes.subheader} disableSticky>
        {" "}
        Vehicle Documents{" "}
      </ListSubheader>
    }
  >
    {insuranceCheckupList.map((item, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <FontAwesomeIcon icon={item.icon} fixedWidth />
        </ListItemIcon>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction>
          <Switch
            onChange={event => onEdit(item.accessor, event.target.checked)}
            checked={data[item.accessor]}
            classes={{
              switchBase: classes.switch,
              checked: classes.colorChecked,
              track: classes.colorBar
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);

export default withStyles(styles)(VehicleDocumentCheckupList);
