import React from "react";
import ButtonGroup from "../../Shared/ButtonGroup";
import styles from "./edits.module.scss";
import Button from "../../../../artzu/src/Button";

const EditsButtons = ({ reviewEdits, endEdits }) => {
  return (
    <div className={styles.buttonGroup}>
      <div className={styles.marginRight}>
        <Button
          label="Cancel"
          className={styles.button}
          color="#414141"
          weight="400"
          font-size="16px"
          fullWidth="80px"
          border="none"
          onClick={endEdits}
        />
      </div>
      <Button
        label="Save"
        className={styles.button}
        disabled={!reviewEdits}
        weight="400"
        fullWidth="80px"
        font-size="16px"
        color="#128DFF"
        border="none"
        onClick={reviewEdits}
      />
    </div>
  );
};

export default EditsButtons;
