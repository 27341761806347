import React, { Component, Fragment } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";

import "../Components/Vehicle/vehicle.scss";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../artzu/src/Button";
import { directUploadFile } from "../utils/FileUploads";
import { useApolloClient } from "@apollo/client";

class NewDamageReportChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  toggleLoading = () => this.setState({ loading: !this.state.loading });

  NEW_DAMAGE_REPORT = gql`
    mutation createDamage($damage: NewDamageReportAttributes!) {
      newDamageReport(params: $damage) {
        success
        errors
      }
    }
  `;

  newDamageReport = () => {
    const {
      newDamageReportParams,
      refetch,
      alterDamageState,
      file,
      client,
    } = this.props;

    directUploadFile(file, client).then((fileBlob) => {
      newDamageReportParams.damage.fileBlob = fileBlob;
      client
        .mutate({
          mutation: this.NEW_DAMAGE_REPORT,
          variables: {
            ...newDamageReportParams,
          },
        })
        .then((res) => {
          refetch();
          alterDamageState({
            mode: "overview",
            location: "",
            locationX: null,
            locationY: null,
          });
          this.toggleLoading();
        });
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <div className="view-buttons">
            <FontAwesomeIcon spin icon={faSpinner} />
          </div>
        ) : (
          <Button
            className="view-buttons"
            onClick={() => {
              this.newDamageReport();
              this.toggleLoading();
            }}
            label="Ok"
          />
        )}
      </Fragment>
    );
  }
}

const NewDamageReport = (props) => {
  const client = useApolloClient();
  return <NewDamageReportChild client={client} {...props} />;
};

export default NewDamageReport;
