import React from "react";
import styles from "../damages.module.scss";
import Dropdown from "../../../Shared/Dropdown";
import { locations } from "../utils";
import rightArrow from "../../../../images/damages/rightarrow.svg";
import leftArrow from "../../../../images/damages/leftarrow.svg";

const GalleryLeft = ({
  curDamages,
  curDamageIdx,
  curImageIdx,
  triggerLocation,
  handlePrev,
  handleNext,
  curMaxDmgIdx,
}) => {
  return (
    <div className={styles.galleryLeft}>
      {curDamages?.[curDamageIdx]?.imageLinks[curImageIdx] ? (
        <img
          className={styles.imageContainer}
          src={curDamages?.[curDamageIdx]?.imageLinks[curImageIdx]}
        ></img>
      ) : (
        <div className={styles.imageContainerNoImage}>No images</div>
      )}
      <div className={styles.imageFooter}>
        <Dropdown
          initialValue="all"
          items={locations}
          onChange={triggerLocation}
          width={"180px"}
        />
        <div className={styles.arrowButtons}>
          <img
            src={leftArrow}
            className={styles.pointer}
            onClick={handlePrev}
          />
          <img
            src={rightArrow}
            className={styles.pointer}
            onClick={handleNext}
          />
        </div>
        <div className={styles.footerText}>
          Damage {curMaxDmgIdx === 0 ? 0 : curDamageIdx + 1}/{curMaxDmgIdx}
        </div>
      </div>
    </div>
  );
};

export default GalleryLeft;
