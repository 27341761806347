import React from "react";
import VehicleInfoDetails from "./VehicleInfoDetails";
import OdometerContent from "./OdometerContent";
import EditableParkingContent from "./EditableParkingContent";

const VehicleInfoLeft = ({
  isEdit,
  formatOdometer,
  city,
  handleLotChange,
  handleSpotChange,
  parkingSpot,
  vehicle,
}) => {
  const vehicleId = vehicle.id;

  const {
    odometer,
    vin,
    rin,
    medallionNumber,
    parkingLot,
    availableParkingLots,
    color,
  } = vehicle;
  return (
    <div className="content-box">
      <VehicleInfoDetails details={"Color"} content={color} />
      <OdometerContent
        odometer={odometer}
        formatOdometer={formatOdometer}
        city={city}
      />
      <VehicleInfoDetails
        details={"Vehicle identification number (VIN)"}
        content={vin}
      />
      <VehicleInfoDetails
        details={"Medallion Number"}
        content={medallionNumber}
      />
      <VehicleInfoDetails
        details={" Registrant identification number (RIN)"}
        content={rin}
      />

      <EditableParkingContent
        isEdit={isEdit}
        parkingSpot={parkingSpot}
        parkingLot={parkingLot}
        vehicleId={vehicleId}
        handleLotChange={handleLotChange}
        handleSpotChange={handleSpotChange}
        availableParkingLots={availableParkingLots}
      />
    </div>
  );
};

export default VehicleInfoLeft;
