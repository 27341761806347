import React, { useState } from "react";
import { Fragment } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { useEffect } from "react";
import NormalMessage from "./NormalMessage";
import styles from "../../task.module.scss";
import AccidentReportMessage from "./AccidentReportMessage";
import FullImageMessage from "./FullImageMessage";
import AccidentReportModal from "../AccidentReportModal";
import DateAndNotificationBox from "./DateAndNotificationBox";
import DamageReportMessage from "./DamageReportMessage";

const Message = ({
  messages,
  historyMsg,
  refreshTaskCallback,
  taskId,
  taskData,
  currentAdmin,
  accidentReport,
}) => {
  const [isFull, setIsFull] = useState(false);
  const [curUrl, setCurUrl] = useState();
  const [isAccident, setIsAccident] = useState();
  const [curMsgId, setCurMsgId] = useState();
  let sortedHistoryMsg = {};
  let sortedHisMsg = [...historyMsg];
  sortedHisMsg = sortedHisMsg.sort(
    (a, b) => moment(a.sentAt).toDate() - moment(b.sentAt).toDate()
  );
  console.log(sortedHisMsg);
  sortedHisMsg.forEach((msgs) => {
    const date = moment(msgs.sentAt)
      .toDate()
      .toString()
      .slice(4, 15);
    if (sortedHistoryMsg[moment(date).format("MMM D, YYYY")]) {
      sortedHistoryMsg[moment(date).format("MMM D, YYYY")].push(msgs);
    } else {
      sortedHistoryMsg[moment(date).format("MMM D, YYYY")] = [msgs];
    }
  });

  useEffect(() => {
    refreshTaskCallback({ taskId: taskId });
  }, [messages]);

  useEffect(() => {
    sortedHisMsg &&
      sortedHisMsg.length > 0 &&
      setCurMsgId(sortedHisMsg[sortedHisMsg.length - 1].id);
  }, [historyMsg]);

  const toggleImg = () => {
    // e.preventDefault();
    setIsFull(!isFull);
    document.addEventListener("click", closeImg);
  };

  const closeImg = () => {
    setIsFull(false);
    document.removeEventListener("click", closeImg);
  };

  const toggleAccident = () => {
    setIsAccident(!isAccident);
  };

  return (
    <Fragment>
      <div className={styles.messageContainer}>
        {Object.keys(sortedHistoryMsg).map((date) => (
          <div>
            {sortedHistoryMsg[date].some(
              (message) => message.label === "normal"
            ) && (
              <div className={styles.dateLine}>
                <div className={styles.line}></div>
                <span>{moment(date).format("MMM D, YYYY")} </span>
                <div className={styles.line}></div>
              </div>
            )}
            {sortedHistoryMsg[date].map((msg) => (
              <div className="message-box">
                {(msg.label === "notification" ||
                  msg.label === "accident" ||
                  msg.label === "damage") && (
                  <DateAndNotificationBox msg={msg} taskData={taskData} />
                )}
                <div className={styles.messageContent}>
                  <AccidentReportMessage
                    msg={msg}
                    accidentReport={accidentReport}
                    toggleAccident={toggleAccident}
                  />
                  <DamageReportMessage
                    msg={msg}
                    vehicleId={taskData?.vehicle?.id}
                  />
                  {(msg.label === "normal" || msg.label === "image") &&
                    (msg.internal ||
                      msg.direction === "outgoing" ||
                      msg.fromAdmin) && (
                      <div>{moment(msg.sentAt).format("h:mmA")}</div>
                    )}
                  {(msg.label === "normal" || msg.label === "image") && (
                    <NormalMessage
                      msg={msg}
                      setCurUrl={setCurUrl}
                      toggleImg={toggleImg}
                      currentAdmin={currentAdmin}
                    />
                  )}

                  {((msg.label === "normal" && msg.direction === "incoming") ||
                    msg.status ||
                    msg.label === "accident") && (
                    <div>{moment(msg.sentAt).format("h:mmA")}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <FullImageMessage isFull={isFull} imgUrl={curUrl} />
      <AccidentReportModal
        open={isAccident}
        toggleAccident={toggleAccident}
        accidentReport={accidentReport}
      />
    </Fragment>
  );
};

export default Message;
