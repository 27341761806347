import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import missing from "../../../../images/AVC_files/missing.png";

const UserValue = ({ cellData, classes }) => {
  if (cellData) {
    const { user } = cellData;
    const userName = user && user.name;
    const userLink = user && user.link;
    const userAvatar = (user && user.imageLink) || missing;
    const goUrl = () => {
      window.open(userLink, "_blank");
    };

    return (
      <div className={classNames(classes)}>
        {user && (
          <div className={styles.userContainer}>
            {userName && (
              <div className={styles.userImageContainer}>
                <img className={styles.userImage} src={userAvatar} />
              </div>
            )}
            <a
              className={userLink ? styles.userNameLink : styles.userName}
              href={userLink}
              target="_blank"
              onClick={goUrl}
            >
              {userName}
            </a>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default UserValue;
