import React from "react";
import { m } from "../../utils";

import AddNote from "./AddNote";
import DeleteServiceEvent from "../../Mutations/DeleteServiceEvent";
import "./service.css";
import "../Fleet/fleet.css";

const UpcomingTable = ({ events, vehicleId, toggle, refetch }) => (
  <div>
    <div className="flex-container fleet-header extend-width table-top upcoming-translate">
      <div className="table-3 first-upcoming vehicle-data">Date</div>
      <div className="table-4 vehicle-data">Service event</div>
      <div className="table-2 vehicle-data">Est. cost</div>
      <div className="table-2 vehicle-data">Vendor</div>
      <div className="table-4 vehicle-data">Description</div>
      <div className="table-4 vehicle-data">Notes</div>
      <div className="table-2 vehicle-data" />
    </div>

    {events.map((event, index) => {
      return (
        <div
          className="flex-container vehicle-row extend-width upcoming-translate"
          key={index}
          onClick={() => toggle("edit", "service", event.id)}
        >
          <div className="table-3 first-upcoming vehicle-data">
            {event.scheduled
              ? m(event.scheduledDate).format("MMM DD YYYY")
              : "--"}
          </div>
          <div className="table-4 vehicle-data">{event.name}</div>
          <div className="table-2 vehicle-data">${event.cost}</div>
          <div className="table-2 vehicle-data">{event.vendor}</div>
          <div className="table-4 vehicle-data">{event.description}</div>
          <AddNote id={event.id} note={event.note} />
          <div className="table-2 vehicle-data">
            <DeleteServiceEvent
              deleteEvent={{ id: event.id, deleted: true }}
              refetch={refetch}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default UpcomingTable;
