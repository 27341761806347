import React from "react";
import { formatOdometer } from "../../utils";
import OdometerInput from "../Fleet/FieldInputs/OdometerInput";
import LockboxInput from "../Fleet/FieldInputs/LockboxInput";
import ParkingSpotInput from "../Fleet/FieldInputs/ParkingSpotInput";
import HomeLotInput from "../Fleet/FieldInputs/HomeLotInput";
import ParkingPassInput from "../Fleet/FieldInputs/ParkingPassInput";
import ParkingSelect from "./ParkingSelect";

import moment from "moment";

import _ from "lodash";
import "./vehicle.scss";

const VehicleDetails = ({
  color,
  vin,
  rin,
  transponder,
  lockboxCode,
  parkingSpot,
  serialNumber,
  odometer,
  vehicleId,
  plate,
  avgMileage,
  avgFleetMileage,
  gasLevel,
  edit,
  city,
  medallionNumber,
  parkingLots,
  parkingLot,
  serviceLocations,
  currentServiceLocation,
  currentLocation,
  parkingPassId,
  updateEditParams,
  updateEditedValues,
  store,
  availableParkingSpots,
  openHistoryModal
}) => (
  <div className="vehicle-container-details">
    <div
      className="vehicle-col full-width"
      style={{ width: "calc(50% - 32px)" }}
    >
      <div className="vehicle-information">
        <p className="tight-margin-details">Color</p>
        <p className="tight-margin-large">{color}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Odometer</p>
        <div>
          {odometer ? (
            <div className="tight-margin-large">
              {formatOdometer(odometer.reading) +
                (city.name === "toronto" ? "km" : " mi")}
              <div style={{ fontSize: "70%", color: "grey" }}>
                As of{" "}
                {moment(odometer.capturedAt).format("ddd, MMM Do YYYY, h:mm a")}
              </div>
            </div>
          ) : (
            <div className="tight-margin-large">"--"</div>
          )}
        </div>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">
          Vehicle identification number (VIN)
        </p>
        <p className="tight-margin-large">{vin || "--"}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Medallion Number</p>
        <p className="tight-margin-large">{medallionNumber || "--"}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">
          Registrant identification number (RIN)
        </p>
        <p className="tight-margin-large">{rin ? rin : "--"}</p>
      </div>

      <div className="vehicle-information">
        <p className="tight-margin-details">Parking Spot</p>
        {edit ? (
          <ParkingSpotInput
            availableParkingSpots={availableParkingSpots}
            parkingSpot={parkingSpot}
            vehicleId={vehicleId}
            editError={store.changeEditError}
            plate={plate}
            type="parking"
            updateEdit={updateEditedValues}
            editParams={updateEditParams}
            details={true}
          />
        ) : (
          <p className="tight-margin-large">{parkingSpot || "--"}</p>
        )}
      </div>

      {city.name === "new_york" ? (
        <div className="vehicle-information">
          <p className="tight-margin-details">Parking Pass Id</p>
          {edit ? (
            <ParkingPassInput
              parkingPassId={parkingPassId}
              vehicleId={vehicleId}
              editError={store.changeEditError}
              plate={plate}
              type="pass"
              updateEdit={updateEditedValues}
              editParams={updateEditParams}
              details={true}
            />
          ) : (
            <p className="tight-margin-large">{parkingPassId || "--"}</p>
          )}
        </div>
      ) : null}

      <div className="vehicle-information">
        <p className="tight-margin-details" style={{ width: "50%" }}>
          Current Location
        </p>
        {
          // <LocationSelect
          //   serviceLocations={serviceLocations}
          //   currentServiceLocation={currentServiceLocation}
          //   parkingLot={parkingLot}
          //   edit={edit}
          //   toggleEdit={toggleEdit}
          //   vehicleId={vehicleId}
          // />
        }
        <p className="tight-margin-large">
          {currentLocation ? currentLocation.name : "--"}
        </p>
      </div>
    </div>
    <div
      style={{
        width: "calc(50% - 32px)",
        paddingLeft: "32px",
        borderLeft: "1px solid #DEDEDE"
      }}
    >
      <div className="vehicle-information">
        <p className="tight-margin-details" style={{ width: "50%" }}>
          Home Parking Lot
        </p>

        {edit ? (
          <div className="tight-margin-large" style={{ fontWeight: "bold" }}>
            <HomeLotInput
              parkingLots={parkingLots}
              parkingLot={parkingLot}
              vehicleId={vehicleId}
              editError={store.changeEditError}
              plate={plate}
              type="home"
              updateEdit={updateEditedValues}
              editParams={updateEditParams}
              details={true}
            />
          </div>
        ) : (
          <div className="tight-margin-large" style={{ fontWeight: "bold" }}>
            {parkingLot.name}
          </div>
        )}
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">City</p>
        <p className="tight-margin-large">{_.startCase(city.name) || "--"}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Lockbox Code</p>
        <div className={"vehicle-information__lockbox-code"}>
          {edit ? (
            <LockboxInput
              lockboxCode={lockboxCode}
              vehicleId={vehicleId}
              editError={store.changeEditError}
              plate={plate}
              type="code"
              updateEdit={updateEditedValues}
              editParams={updateEditParams}
              details={true}
            />
          ) : (
            <p className="tight-margin-large">{lockboxCode || "--"}</p>
          )}
          <p
            onClick={openHistoryModal}
            className={"vehicle-information__lockbox-history"}
          >
            {" "}
            History
          </p>
        </div>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">407 Transponder</p>
        <p className="tight-margin-large">{transponder || "--"}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Pilo serial number</p>
        <p className="tight-margin-large">{serialNumber || "--"}</p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Average fleet usage</p>
        <p className="tight-margin-large">
          {avgFleetMileage} {city.name === "toronto" ? " km" : " mi"}/day
        </p>
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details">Average vehicle usage</p>
        <p className="tight-margin-large">
          {avgMileage} {city.name === "toronto" ? " km" : " mi"}/day
        </p>
      </div>

      {gasLevel ? (
        <div className="vehicle-information">
          <div className="tight-margin-details">Gas level</div>
          <div className="tight-margin-large">
            <div style={{ textAlign: "left", fontWeight: "bold" }}>
              {gasLevel.reading}%
            </div>
            <div style={{ fontSize: "70%", color: "grey" }}>
              As of{" "}
              {moment(gasLevel.capturedAt).format("ddd, MMM Do YYYY, h:mm a")}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export default VehicleDetails;
