import React, { useState } from "react";
import PropTypes from "prop-types";

import pdf from "../../images/pdf.png";

const RenderImage = ({ src, imageClass }) => {
  const [imageError, setImageError] = useState(true);
  return (
    <img
      src={src}
      className={imageClass}
      alt=""
      onError={e => {
        if (imageError) {
          setImageError(false);
        }
        e.target.src = pdf;
        e.target.style = "border-radius: 0";
      }}
    />
  );
};

export default RenderImage;

RenderImage.propTypes = {
  src: PropTypes.string,
  imageClass: PropTypes.string.isRequired
};
