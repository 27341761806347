import React, { Component } from "react";
import TimeSelect from "../MapView/ModeSelect/TimeSelect";
import { m } from "../../utils";
import "./service.css";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

class DateSelection extends Component {
  getDefaultDate() {
    const { completedDate, scheduledDate, complete, scheduled } = this.props;
    if (scheduled && scheduledDate) {
      return m(scheduledDate);
    } else if (complete && completedDate) {
      return m(completedDate);
    } else {
      return m().format();
    }
  }

  render() {
    const {
      complete,
      scheduled,
      onChange,
      completedDate,
      scheduledEndDate,
      scheduledDate,
      endDateSelected,
      toggleEndDate
    } = this.props;

    console.log(scheduledEndDate);
    return (
      <div>
        <div className="flex">
          <p
            className={`tab-option ${complete ? "selected" : "unselected"}`}
            onClick={() => {
              onChange("completedDate", complete ? "" : m().format());
              onChange("complete", !complete);
              onChange("scheduled", false);
            }}
          >
            Complete
          </p>
          <p
            className={`tab-option scheduled-tab ${
              scheduled ? "selected" : "unselected"
            }`}
            onClick={() => {
              if (scheduled) {
                onChange("scheduledDate", null);
              }
              onChange("scheduledDate", m().format()); // if user don't pick a scheduled date, the default will be now
              onChange(
                "scheduledEndDate",
                m()
                  .add(1, "days")
                  .format()
              ); // if user don't pick a scheduled end date, the default will be now + 1.day
              onChange("scheduled", !scheduled);
              onChange("complete", false);
              onChange("completedDate", null);
            }}
          >
            Scheduled
          </p>
        </div>
        {complete ? (
          <TimeSelect
            label={`${complete ? "Completed" : "Scheduled"}`}
            name={`${complete ? "completedDate" : "scheduledDate"}`}
            updateTime={onChange}
            serviceEvent={true}
            markedDate={completedDate ? m(completedDate) : m().format()}
            show={true}
          />
        ) : null}
        {scheduled ? (
          <div>
            <TimeSelect
              label={"Scheduled Start"}
              name={"scheduledDate"}
              updateTime={onChange}
              serviceEvent={true}
              markedDate={
                scheduledDate ? m(scheduledDate).format() : m().format()
              }
              show={true}
            />
            <div className="create-booking__check-box">
              End Date?
              <Checkbox
                className="sf-check"
                checked={endDateSelected}
                style={{
                  width: 12,
                  height: 12,
                  color: "#128DFF",
                  marginLeft: "12px"
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faSquareFull}
                    style={{
                      fontSize: "16px",
                      color: "#128DFF",
                      backgroundColor: "#FFFFFF"
                    }}
                  />
                }
                checkedIcon={
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    style={{
                      fontSize: "17.5px",
                      color: "#128DFF"
                    }}
                  />
                }
                onChange={toggleEndDate}
                inputProps={{
                  "aria-label": "secondary checkbox"
                }}
              />
            </div>
            {endDateSelected ? (
              <TimeSelect
                label={"Scheduled End"}
                name={"scheduledEndDate"}
                updateTime={onChange}
                serviceEvent={true}
                markedDate={
                  scheduledEndDate
                    ? m(scheduledEndDate).format()
                    : m()
                        .add(1, "days")
                        .format()
                }
                show={true}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default DateSelection;
