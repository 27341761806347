import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./toggleButton.module.scss";
import classNames from "classnames";

const ToggleButton = ({ isOpen, toggleOpenState, faIcon, background }) => {
  return (
    <div
      className={classNames(
        background === "white"
          ? styles.toggleButtonContainerWhiteBack
          : styles.toggleButtonContainer,
        isOpen && styles.darkBackground
      )}
      onClick={(evt) => toggleOpenState()}
    >
      <FontAwesomeIcon
        className={classNames(isOpen && styles.selected)}
        icon={faIcon}
      />
    </div>
  );
};

export default ToggleButton;
