import React from "react";
import styles from "../../../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classesNames from "classnames";
import {
  faLockAlt,
  faPowerOff,
  faLockOpenAlt,
  faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import flashLights from "../../../../../images/icons/flashlights.svg";
import honk from "../../../../../images/icons/honk.svg";

const OptionList = ({
  setCurAction,
  toggleModal,
  toggleChangeStatusModal,
  toggleOpenState,
  toggleEdit,
}) => {
  const toggleEditMode = () => {
    toggleOpenState();
    toggleEdit();
  };
  const vehicleOptions = [
    {
      onClick: toggleModal,
      name: "Unlock",
      icon: faLockOpenAlt,
    },
    {
      onClick: toggleModal,
      name: "Lock",
      icon: faLockAlt,
    },
    {
      onClick: toggleModal,
      name: "Start",
      icon: faPowerOff,
    },
    {
      onClick: toggleModal,
      name: "Flash lights",
      icon: flashLights,
    },
    {
      onClick: toggleModal,
      name: "Honk",
      icon: honk,
    },
    {
      onClick: toggleChangeStatusModal,
      name: "Change status",
      icon: faCircle,
    },
    {
      onClick: toggleEditMode,
      name: "Edit",
      icon: faEdit,
    },
  ];

  const actionButtonIcon = (option) => {
    if (option.name === "Flash lights") {
      return <img src={option.icon}></img>;
    } else if (option.name === "Honk") {
      return <img src={option.icon}></img>;
    } else {
      return <FontAwesomeIcon icon={option.icon} />;
    }
  };

  return (
    <div className={styles.menuUp}>
      {vehicleOptions.map((option) => (
        <div
          onClick={() => {
            option.onClick();
            option && setCurAction(option.name);
          }}
          className={styles.menuOption}
        >
          <div className={classesNames(styles.actionButton, styles.blue)}>
            {actionButtonIcon(option)}
          </div>
          {option.name}
        </div>
      ))}
    </div>
  );
};

export default OptionList;
