import React, { Component, Fragment } from "react";
import NotesModal from "../../Misc/NotesModal";
import Modal from "../../../artzu/src/Modal";
import DisplayDocumentModal from "./DisplayDocumentModal";
import EditDocumentModal from "./EditDocumentModal";

class DocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false
    };
  }

  handleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  render() {
    const { docModal, doc, handleClose, refetch } = this.props;
    const { edit } = this.state;
    return (
      <Fragment>
        <NotesModal
          open={edit}
          id="upload-modal"
          title="Edit Document"
          closeModal={handleClose}
        >
          {edit ? (
            <EditDocumentModal
              doc={doc}
              handleClose={handleClose}
              refetch={refetch}
            />
          ) : null}
        </NotesModal>
        <DisplayDocumentModal
          doc={doc}
          handleClose={handleClose}
          handleEdit={this.handleEdit}
          refetch={refetch}
        />
      </Fragment>
    );
  }
}

export default DocumentModal;
