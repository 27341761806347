import React from "react";
import BookingDetail from "./BookingDetail";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
const size = "2x";

const BookingSandwich = ({
  bookingSandwich,
  store,
  uuid,
  showLocation,
  vehicle,
  mapView,
  status,
  classes
}) => (
  <div style={{ marginBottom: "32px", minHeight: "304px" }}>
    <BookingDetail
      booking={bookingSandwich[2]}
      title="Current"
      showLocation={showLocation}
      classes={classes}
      vehicle={vehicle}
      mapView={mapView}
      operationStatus={status}
    />
    <BookingDetail
      booking={bookingSandwich[1]}
      title="Last"
      showLocation={showLocation}
      classes={classes}
      vehicle={vehicle}
      mapView={mapView}
      operationStatus={status}
    />
    <BookingDetail
      booking={bookingSandwich[0]}
      title="Second Last"
      showLocation={showLocation}
      classes={classes}
      vehicle={vehicle}
      mapView={mapView}
      operationStatus={status}
    />
  </div>
);
const styles = theme => ({
  root: {
    padding: "8px 8px"
  }
});
export default withStyles(styles)(BookingSandwich);
