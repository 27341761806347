import React, { useState } from "react";
import Button from "../../../../artzu/src/Button";
import Modal from "../../../../artzu/src/Modal";
import { Fragment } from "react";
import _ from "lodash";
import ChangeList from "./ChangeList";

const ScheduledStatusChange = ({
  open,
  close,
  toggleChangeStatus,
  scheduledChanges,
  toggleRefresh,
  isSolid,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelected = (changeTime) => {
    let newSelected = [...selected];
    const included = _.includes(
      JSON.stringify(newSelected),
      JSON.stringify(changeTime)
    );
    included
      ? _.remove(newSelected, (time) => time === changeTime)
      : newSelected.push(changeTime);
    setSelected(newSelected);
  };

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none" padding="0">
        <div className="scheduledList">
          <div className="listTitleButton">
            <div className="listTitle">Scheduled Status Change</div>
            <Button
              label={"Schedule Status Change"}
              height="28px"
              fullWidth="188px"
              color="#ffffff"
              backColor="#128dff"
              radius="17px"
              size="14px"
              border="1px solid #128dff"
              padding="0"
              onClick={() => {
                toggleChangeStatus();
                close();
              }}
            />
          </div>
          <ChangeList
            scheduledChanges={scheduledChanges}
            selected={selected}
            handleSelected={handleSelected}
            close={close}
            toggleRefresh={toggleRefresh}
            isSolid={isSolid}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default ScheduledStatusChange;
