import React from "react";
import styles from "../damages.module.scss";

const SmallTextInput = ({ text, triggerFieldChange }) => {
  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <input className={styles.textInput} onChange={triggerFieldChange}></input>
    </div>
  );
};

export default SmallTextInput;
