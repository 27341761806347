import React, { Component } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { date, onClick, value, classes } = this.props;
    return (
      <div className={classes.dateInput} onClick={onClick}>
        {`${moment(date).format("ddd, MMM D, YYYY")} ${moment(date).format(
          "h:mm A"
        )}`}
        <ExpandMore />
      </div>
    );
  }
}

DateInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

const style = theme => ({
  dateInput: {
    color: "#414141",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #414141",
    borderRadius: "12px",
    alignItems: "center",
    padding: "0 12px 0 12px",
    marginTop: "6px",
    height: "24px",
    backgroundColor: "#FFFFFF"
  }
});

export default withStyles(style)(DateInput);
