import React from "react";
import styles from "./footer.module.scss";
import classNames from "classnames";
import { faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LastPage = ({ currentPage, changePage, lastPage }) => {
  const isActive = currentPage < lastPage;
  return (
    <div
      className={classNames(styles.nextPage, !isActive && styles.disabled)}
      onClick={() => (isActive ? changePage(lastPage) : null)}
    >
      <FontAwesomeIcon
        className={styles.nextPageIcon}
        icon={faChevronDoubleRight}
        size="sm"
      />
    </div>
  );
};

export default LastPage;
