const centerItems = () =>
	`
		display: flex;
		justify-content: center;
		align-items: center;
	`

const getAnimation = opening =>
	`
		from {
	    opacity: ${opening ? 0 : 1};
	    transform: translateY(${opening ? -3 : 0}em)
	  }

	  to {
	    opacity: ${opening ? 1 : 0};
	    transform: translateY(${opening ? 0 : -15}em)
	  }
  `

const getFadeout = () =>
	`
		from {
			opacity: 0.55;
		}

		to {
			opacity: 0;
		}
	`

export { centerItems, getAnimation, getFadeout }
