import React, { Component, Fragment } from "react";
import DeleteDocument from "./DeleteDocument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../../artzu/src/Button";
import { m } from "../../../utils";
import RenderImage from "../RenderImage";

class DisplayDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { doc, handleClose, handleEdit, refetch } = this.props;
    const { id, name, imageLink, uploadedAt, updatedAt, expiryDate } = doc;

    return (
      <Fragment>
        <div className="doc__modal">
          <div className="doc__modal__preview doc__modal__preview--black">
            <div
              onClick={e => {
                e.stopPropagation();
                window.open(imageLink, "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              <RenderImage
                src={imageLink}
                imageClass="doc__modal__preview--img"
              />
            </div>
          </div>
          <div className="doc__modal__inputs">
            <Fragment>
              {
                //<div className="doc__modal__inputs--close">
                //   <FontAwesomeIcon
                //     icon={faTimes}
                //     size="2x"
                //     onClick={handleClose}
                //   />
                // </div>
              }
              <div className="doc__modal__inputs__displayName">{name}</div>
              <div className="doc__modal__inputs__margin">
                <span className="doc__modal__inputs--bold">
                  Date Uploaded: &nbsp;
                </span>
                {m(uploadedAt).format("MMMM Do YYYY")}
              </div>
              <div className="doc__modal__inputs__margin">
                <span className="doc__modal__inputs--bold">
                  Date Updated: &nbsp;
                </span>
                {m(updatedAt).format("MMMM Do YYYY")}
              </div>
              <div>
                <span className="doc__modal__inputs--bold">
                  Expire Date: &nbsp;
                </span>
                {expiryDate === null
                  ? "N/A"
                  : m(expiryDate).format("MMMM Do YYYY")}
              </div>
              <br />
            </Fragment>
          </div>
          <div
            className="doc__modal__inputs__buttons"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              fullWidth="140px"
              height="72px"
              label={
                <div
                  style={{
                    display: "block",
                    width: "min-content",
                    margin: "auto"
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                  <span style={{ padding: "12px", textAlign: "center" }}>
                    Edit
                  </span>
                </div>
              }
              color="#414141"
              border="none"
              onClick={() => handleEdit(id)}
            />

            <DeleteDocument
              docId={doc.id}
              refetch={refetch}
              handleClose={handleClose}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DisplayDocumentModal;
