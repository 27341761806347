import React from "react";
import Modal from "../../../../../../artzu/src/Modal";
import styles from "../../../batchActions.module.scss";
import Button from "../../../../../../artzu/src/Button";
import { useMutation } from "@apollo/client";
import { DELETE_TAG } from "../queries";

const DeleteTagModal = ({
  tag,
  allTags,
  isDelete,
  parentCallback,
  refetch,
  refetchAddTag,
  setItemSelected,
  entry,
}) => {
  let tagToDelete = allTags.find((anyTag) => anyTag.name === tag);
  const counter = tagToDelete && tagToDelete.attachedToCount;
  const foundIdx = allTags.findIndex((x) => x.name === tag);
  const tagId = allTags[foundIdx] && allTags[foundIdx].id;

  const [deleteTagMutation, { loading, error }] = useMutation(DELETE_TAG);
  const deleteTag = () => {
    deleteTagMutation({
      variables: {
        tagId: tagId,
      },
    }).then(({ data: { deleteTag } }) => {
      if (deleteTag.success) {
        refetch();
        entry && refetchAddTag();
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  return (
    <Modal open={isDelete} backgroundColor="none" padding="0">
      <div className={styles.deleteTag}>
        <h4>Delete Tag</h4>
        <div className={styles.message}>
          Are you sure you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>{tag}</span> ?
        </div>
        <div className={styles.message}>
          This tag is connected to{" "}
          <span style={{ fontWeight: "bold" }}>{counter} </span>
          vehicle(s)
        </div>

        <div className={styles.deleteCancelButton}>
          <Button
            label={"Cancel"}
            fullWidth="152px"
            height="40px"
            color="#414141"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              parentCallback();
            }}
          />
        </div>

        <div className={styles.deleteSaveButton}>
          <Button
            label={"Delete"}
            fullWidth="152px"
            height="40px"
            color="#FFFFFF"
            backColor="#ff0000"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              parentCallback();
              deleteTag();
              setItemSelected([]);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTagModal;
