import React, { useContext } from "react";

import gql from "graphql-tag";
import _ from "lodash";
import Button from "../../artzu/src/Button";

import { TaskContext } from "./TaskContext";
import styles from "./tasks.module.scss";
import { useApolloClient } from "@apollo/client";

const NEW_TASK = gql`
  mutation newTask($input: TaskAttributes!) {
    newTask(params: $input) {
      errors
      success
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($input: TaskAttributes!) {
    updateTask(params: $input) {
      errors
      success
    }
  }
`;

const ButtonsGroupChild = ({ client, refetch }) => {
  const [state, setState] = useContext(TaskContext);

  const handleTaskUpdate = () => {
    const {
      id,
      name,
      status,
      dueDate,
      user: { id: userId },
      comment
    } = state.modalData;
    const errors = [];
    if (!name) {
      errors.push("Task Name is required");
    }
    if (_.isEmpty(dueDate)) {
      errors.push("Deadline is required");
    }
    if (errors.length === 0) {
      setState(state => ({ ...state, loading: true, modalError: [] }));
      client
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            input: { id, name, status, dueDate, userId, comment }
          }
        })
        .then(res => {
          setState(state => ({ ...state, loading: false }));
          if (res.data.updateTask.success) {
            refetch();
            setState(state => ({ ...state, modal: false }));
          } else {
            setState(state => ({
              ...state,
              modalError: res.data.updateTask.errors
            }));
          }
        });
    } else {
      setState(state => ({ ...state, modalError: errors }));
    }
  };

  const handleTaskCreate = () => {
    const {
      name,
      status,
      dueDate,
      plate,
      user: { id: userId },
      comment
    } = state.modalData;
    const errors = [];
    if (!name) {
      errors.push("Task Name is required");
    }
    if (!plate) {
      errors.push("Plate is required");
    }
    if (_.isEmpty(dueDate)) {
      errors.push("Deadline is required");
    }
    if (!userId) {
      errors.push("Assigned User is required");
    }
    if (errors.length === 0) {
      setState(state => ({ ...state, loading: true, modalError: [] }));
      client
        .mutate({
          mutation: NEW_TASK,
          variables: {
            input: { name, status, dueDate, plate, userId, comment }
          }
        })
        .then(res => {
          setState(state => ({ ...state, loading: false }));
          if (res.data.newTask.success) {
            refetch();
            setState(state => ({ ...state, modal: false }));
          } else {
            setState(state => ({
              ...state,
              modalError: res.data.newTask.errors
            }));
          }
        });
    } else {
      setState(state => ({ ...state, modalError: errors }));
    }
  };

  const RenderButtons = () => {
    switch (state.mode) {
      case "view":
        return (
          <Button
            label="Edit"
            onClick={() => setState(state => ({ ...state, mode: "edit" }))}
          />
        );
      case "new":
        return <Button label="Add" onClick={() => handleTaskCreate()} />;
      case "edit":
        return <Button label="Save" onClick={() => handleTaskUpdate()} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        {state.modalError.length > 0
          ? state.modalError.map((error, index) => (
              <div className={styles.errors} key={index}>
                {error}
              </div>
            ))
          : null}
      </div>
      <div className={styles.buttonsGroup}>
        <Button
          color="#414141"
          label="Cancel"
          onClick={() => setState(state => ({ ...state, modal: !state.modal }))}
        />
        <RenderButtons />
      </div>
    </>
  );
};

const ButtonsGroup = props => {
  const client = useApolloClient();
  return <ButtonsGroupChild client={client} {...props} />;
};

export default ButtonsGroup;
