import gql from "graphql-tag";

const ALL_AVAILABLE_PLATES = gql`
  query getAllAvailablePlates {
    getAllAvailablePlates
  }
`;

const BATCH_EDIT_STATUS = gql`
  mutation editStatus(
    $parkingSpotIds: [ID!]!
    $status: String!
    $comment: String!
    $plate: String
  ) {
    editStatus(
      parkingSpotIds: $parkingSpotIds
      status: $status
      comment: $comment
      plate: $plate
    ) {
      success
      error
    }
  }
`;

const EDIT_STATUS = gql`
  mutation editParkingSpot(
    $parkingSpotId: ID!
    $status: String!
    $comment: String!
    $plate: String
  ) {
    editParkingSpot(
      parkingSpotId: $parkingSpotId
      status: $status
      comment: $comment
      plate: $plate
    ) {
      success
      error
    }
  }
`;

export { BATCH_EDIT_STATUS, EDIT_STATUS, ALL_AVAILABLE_PLATES };
