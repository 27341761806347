import React, { Component } from "react";
import { observer } from "mobx-react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import { Slider } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TimeContainer from "./TimeContainer";
import TargetedTime from "../TargetedTime";
import { m } from "../../../utils";
import "./modeSelect.css";
import "../map.scss";

const styles = theme => ({
  hide: { display: "none" },
  show: { display: "block" },
  optionsBar: { height: "auto", width: "410px", justifyContent: "center" },
  modeSelect: {
    zIndex: theme.zIndex.historyOptions,
    backgroundColor: theme.palette.canvasColor,
    borderBottom: "solid",
    borderBottomWidth: theme.borderWidth,
    borderBottomColor: theme.palette.grey.lighter,
    borderRadius: "20px"
  },
  datetimePicker: { margin: "3em", color: "#414141" },

  modeTabs: {
    borderColor: theme.palette.grey.lighter,
    color: theme.palette.black,
    height: theme.optionsHeight,
    width: "410px",
    height: "50px"
  },
  tabWidth: { minWidth: "fit-content" },
  tabText: { fontSize: "1.2em" },
  thumb: { backgroundColor: "#ffffff", border: "2px solid #414141" },
  track: { backgroundColor: "#414141", opacity: "0.5" },
  trackBefore: { background: "none" },
  sliderRoot: {
    margin: "0 16px 0 16px",
    width: "364px",
    padding: "16px 0 16px 0"
  }
});

const ModeSelect = observer(
  class ModeSelect extends Component {
    constructor() {
      super();
      this.state = {
        value: 0
      };
    }

    handleChange = (event, value) => {
      this.setState({ value });
    };

    render() {
      const {
        store,
        classes,
        updateTime,
        startTime,
        endTime,
        loaded,
        sliderValue,
        updateSlider,
        currentTime
      } = this.props;
      const vehicleSelected = store.selectedVehicle !== "none";
      return (
        <Paper
          className={classNames(
            classes.modeSelect,
            classes["optionsBar"],
            "mode-select-bar",
            !vehicleSelected ? classes.hide : classes.show
          )}
          elevation={0}
        >
          <div className={classNames("tab-container", classes.modeTabs)}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="#ffffff"
              textColor="#414141"
              classes={{
                flexContainer: classes.optionsBar
              }}
            >
              <Tab label="Current" classes={{ root: classes.tabWidth }} />
              <Tab label="History" classes={{ root: classes.tabWidth }} />
              <Tab label="Time Range" classes={{ root: classes.tabWidth }} />
            </Tabs>
          </div>
          <TimeContainer
            store={store}
            selectedMode={this.state.value}
            updateTime={updateTime}
            startLoad={this.props.startLoad}
            startTime={startTime}
            endTime={endTime}
          />
          {store.mode === "history" && loaded ? (
            <div className={classNames("targeted-time")}>
              <TargetedTime currentTime={currentTime} />
            </div>
          ) : null}
          <div
            className={classNames(
              "tuner-container",
              !(store.mode === "history" && loaded) && classes.hide
            )}
          >
            <Slider
              step={1}
              onChange={updateSlider}
              value={sliderValue}
              classes={{
                thumb: classes.thumb,
                track: classes.track,
                root: classes.sliderRoot
              }}
              className={classNames(classes.slider)}
            />
          </div>
        </Paper>
      );
    }
  }
);

export default withStyles(styles)(ModeSelect);
