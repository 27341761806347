import React, { useState } from "react";
import Button from "../../../../../artzu/src/Button";
import styles from "../statusInfo.module.scss";
import { Link } from "react-router-dom";

const TextDetail = ({ content }) => {
  const link = content.link;
  const leadingText = content.leadingText;
  const textLength = content.coreText.length;
  const MAX_LENGTH = 60;
  const [isExpand, setIsExpand] = useState(false);

  const toggleExpand = () => {
    setIsExpand(true);
  };

  const goUrl = () => {
    window.open(link.value, "_blank");
  };

  return (
    <div className={styles.comments}>
      <div className={styles.textInfo}>
        {leadingText && (
          <span style={{ fontWeight: "bold", marginRight: "6px" }}>
            {leadingText}
          </span>
        )}
        <div className={styles.coreText}>
          {textLength < MAX_LENGTH
            ? content.coreText
            : !isExpand && (
                <div>
                  {content.coreText.substring(0, MAX_LENGTH + 1)}...{" "}
                  <a className={styles.seeMore} onClick={toggleExpand}>
                    See more
                  </a>
                </div>
              )}
          {isExpand && content.coreText}
        </div>
      </div>

      {link && (
        <Button
          label={link.text}
          height="29px"
          fullWidth="155px"
          color="#ffffff"
          backColor="#128dff"
          radius="14.5px"
          size="16px"
          weight="400"
          border="1px solid #128dff"
          padding="0"
          onClick={goUrl}
        />
      )}
    </div>
  );
};

export default TextDetail;
