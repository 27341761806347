import React from "react";
import styled, { withTheme } from "styled-components";
import { getColor } from "./selectHelpers.js";
import { isDisabled } from "../../../artzu/src/Button/buttonHelpers.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

const StyledSelectHeader = styled.div`
  ${({ primaryColor, disabled }) =>
    disabled ? isDisabled(disabled) : getColor(false, primaryColor)}

  max-width: 100%;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin || "8px auto"};
  border: ${({ border, primaryColor }) =>
    border || `1px solid ${primaryColor}`};
  border-color: ${({ error, primaryColor }) => (error ? "red" : primaryColor)};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 0 8px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  height: ${({ height }) => height || "100%"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SelectHeader = ({
  heading,
  theme,
  primaryColor = theme.main,
  height,
  fontSize,
  fontWeight,
  overX,
  space,
  textOver,
  width,
  border,
  ...props
}) => (
  <StyledSelectHeader
    {...props}
    primaryColor={primaryColor}
    height={height}
    fontSize={fontSize}
    fontWeight={fontWeight}
    width={width}
    border={border}
  >
    <div
      style={{
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {heading}
    </div>
    <FontAwesomeIcon
      icon={faChevronDown}
      style={{
        fontSize: "12px",
      }}
    />
  </StyledSelectHeader>
);

export default withTheme(SelectHeader);
