import React from "react";
import Button from "../../../artzu/src/Button";
import EditNote from "../../../Mutations/EditNote";
import EditNoteModal from "./EditNoteModal";

const EditNoteModalPanel = ({
  editNote,
  editedNote,
  selectedNote,
  editChange,
  vehicleId,
  refetch,
  toggleEditNote,
  resetNoteState,
}) => {
  const noteValue =
    editedNote || editedNote === ""
      ? editedNote
      : selectedNote
      ? selectedNote.details
      : null;
  return (
    <EditNoteModal
      open={editNote}
      closeModal={toggleEditNote}
      title="Edit Note"
    >
      <textarea
        className="editTextboxInput"
        placeholder="Enter note here..."
        value={noteValue}
        rows="4"
        onChange={(evt) => {
          editChange(evt.target.value);
        }}
      />
      <div className="edit-note-buttons">
        <div className="cancel-note">
          <Button
            label="Cancel"
            fullWidth="154px"
            height="39px"
            color="#414141"
            border="none"
            onClick={() => {
              toggleEditNote();
            }}
            weight="bold"
            size="17px"
          />
        </div>
        <div className="edit-note">
          <EditNote
            editNoteParams={{
              vehicleId: vehicleId,
              userId: selectedNote && selectedNote.userId,
              reminderDate: selectedNote && selectedNote.reminderDate,
              details: editedNote
                ? editedNote
                : selectedNote && selectedNote.details,
              noteId: selectedNote && selectedNote.id,
              taggedUsers: [],
            }}
            refetch={refetch}
            onChange={editChange}
            closeNote={toggleEditNote}
            resetNote={resetNoteState}
            edited={editedNote}
          />
        </div>
      </div>
    </EditNoteModal>
  );
};

export default EditNoteModalPanel;
