import React, { useState } from "react";
import ActionButtons from "./ActionButtons";
import StatusDetails from "./StatusDetails";

const StatusChange = ({ operationStatus, vehicle }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState();

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const startStatusChange = newStatus => {
    setNewStatus(newStatus);
    openModal();
  };

  return (
    <>
      <ActionButtons
        operationStatus={operationStatus}
        startStatusChange={startStatusChange}
      />
      <StatusDetails
        open={isModalOpen}
        newStatus={newStatus}
        vehicle={vehicle}
        closeModal={closeModal}
      />
    </>
  );
};

export default StatusChange;
