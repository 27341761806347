import React from "react";
import Button from "../../../artzu/src/Button";
import Modal from "../../../artzu/src/Modal";
import styles from "./actionButtons.module.scss";
import _ from "lodash";

const CommandConfirm = ({ open, close, confirm, action }) => (
  <Modal open={open} backgroundColor="none" padding="0">
    <div className={styles.actionModal}>
      <div className={styles.message}>
        Are you sure you want to {_.startCase(action)} this vehicle?
      </div>
      <div className={styles.actionButtons}>
        <div>
          <Button
            label={"No"}
            fullWidth="152px"
            height="40px"
            color="#414141"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={close}
          />
        </div>
        <div>
          <Button
            label={"Yes"}
            fullWidth="152px"
            height="40px"
            color="#128DFF"
            backColor="#FFFFFF"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={confirm}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default CommandConfirm;
