import gql from "graphql-tag";
const CHANGE_STATUS = gql`
  mutation changeStatus(
    $vehicleId: ID!
    $startTime: DateTime
    $statusType: String!
    $comments: String
    $cancelUserReservations: Boolean
  ) {
    scheduleVehicleStatusChange(
      vehicleId: $vehicleId
      startTime: $startTime
      statusType: $statusType
      comments: $comments
      cancelUserReservations: $cancelUserReservations
    ) {
      success
      errors {
        errorType
        message
      }
    }
  }
`;

export default CHANGE_STATUS;
