import React from "react";
import styles from "./tabs.module.scss";
import _ from "lodash";
import classNames from "classnames";

const Tabs = ({ tabs, changeSelectedTab }) => {
  const { counts, currentTab } = tabs;
  return (
    <div className={styles.tabsContainer}>
      {counts.map((tab) => {
        const isSelected = tab.tabName === currentTab;
        return (
          <div
            className={classNames(styles.tab, isSelected && styles.selected)}
            onClick={() => !isSelected && changeSelectedTab(tab.tabName)}
          >
            {_.startCase(tab.tabName)} ({tab.count})
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
