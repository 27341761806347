import React, { useState, Fragment } from "react";
import styles from "../../batchActions.module.scss";
import classsNames from "classnames";
import Button from "../../../../../artzu/src/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Loading from "../../../../../Containers/Loading";
import ReminderPin from "./ReminderPin";

const AddNote = ({ selectedOption, selections, close }) => {
  const [reminder, setReminder] = useState(false);
  const [pin, setPin] = useState(false);
  const [startDate, setStartDate] = useState();
  const [noteText, setNoteText] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const triggerReminder = () => {
    setReminder(!reminder);
  };
  const triggerPin = () => {
    setPin(!pin);
  };
  const triggerTextChange = (e) => {
    setNoteText(e.target.value);
  };

  const GET_NEW_NOTES = gql`
    mutation NewNotes($params: MultipleNewNoteAttributes!) {
      multipleNewNote(params: $params) {
        success
        errors
      }
    }
  `;

  const [data, { loading, error }] = useMutation(GET_NEW_NOTES);
  const selectedVehicles = [];
  selections.map((v) => selectedVehicles.push(v));

  const updateNotes = () => {
    setIsSaving(true);
    if (noteText) {
      if (reminder) {
        if (startDate !== undefined) {
          data({
            variables: {
              params: {
                vehicleIds: selectedVehicles,
                details: noteText,
                pinned: pin,
                reminderDate: startDate,
              },
            },
          }).then(({ data: { multipleNewNote } }) => {
            if (multipleNewNote.success) {
              close();
              console.log("Succeed!");
            } else {
              console.log("error!");
            }
          });
        }
      } else {
        data({
          variables: {
            params: {
              vehicleIds: selectedVehicles,
              details: noteText,
              pinned: pin,
              reminderDate: undefined,
            },
          },
        }).then(({ data: { multipleNewNote } }) => {
          if (multipleNewNote.success) {
            close();
            console.log("Succeed!");
          } else {
            console.log("error!");
          }
        });
      }
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.darkText}>{selectedOption.text}</div>
          <div
            className={classsNames(
              styles.contentContainer,
              styles.contentContainer__addNote
            )}
          >
            <h4 style={{ marginBottom: 8 }}>Note</h4>
            <textarea
              className={
                isSaving && !noteText ? styles.textBoxRed : styles.textBox
              }
              onChange={triggerTextChange}
              placeholder="Enter note here..."
            />
            <ReminderPin
              reminder={reminder}
              pin={pin}
              isSaving={isSaving}
              startDate={startDate}
              setStartDate={setStartDate}
              triggerReminder={triggerReminder}
              triggerPin={triggerPin}
            />
            <div className={styles.notesSaveButton}>
              <Button
                label={"Save"}
                height="39px"
                fullWidth="150px"
                color={noteText ? "#128dff" : "#87CEFA"}
                backColor="#ffffff"
                radius="20px"
                size="16px"
                weight="700"
                border="1px solid #FFFFFF"
                onClick={() => noteText && updateNotes()}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddNote;
