import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const DueDateValue = ({ cellData, classes }) => {
  const { text, color } = cellData;
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.textValue} style={{ color: color }}>
        {text}
      </div>
    </div>
  );
};

export default DueDateValue;
