import React from "react";
import styles from "../changeStatus.module.scss";

const Details = ({ details, handleChange }) => (
  <div className={styles.details}>
    <div className={styles.fieldsComponentHeader}>Details: </div>
    <textarea
      className={styles.detailsBox}
      placeholder="Enter note here..."
      value={details}
      rows="4"
      cols="50"
      onChange={evt => handleChange(evt.target.value)}
    />
  </div>
);

export default Details;
