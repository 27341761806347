import React, { useState } from "react";
import styles from "../../../batchActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const CommonTags = ({ common, parentCallback, pcTrigger, newTag }) => {
  const [commonTags, setCommonTags] = useState(common);
  const deleteCommon = (tag) => {
    setCommonTags((arr) => arr.filter((items) => items !== tag));
    parentCallback(tag);
    pcTrigger();
  };
  return (
    <div className={styles.tagBoxUnder}>
      {commonTags.map((t) => (
        <div className={newTag === t ? styles.tagRed : styles.tag}>
          {t}{" "}
          <FontAwesomeIcon
            icon={faTimes}
            className={styles.times}
            onClick={() => deleteCommon(t)}
          />
        </div>
      ))}
    </div>
  );
};

export default CommonTags;
