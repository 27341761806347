import React from "react";
import ActionButtons from "./ActionButtons";
import {
  faLockAlt,
  faUnlockAlt,
  faMapMarkedAlt,
  faVolume,
  faTachometerAlt,
  faGasPump
} from "@fortawesome/pro-solid-svg-icons";
import mapIcon from "../../images/NavBar/Map.png";
import PlateStatus from "../Fleet/PlateStatus";
import VehicleStatus from "./VehicleStatus";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { formatOdometer, m, showLocation, spreadMapData } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../artzu/src/Button";
import stylesH from "../MapView/VehicleDrawer/vehicleDrawer.module.scss";
import classNames from "classnames";
import _ from "lodash";

import "./vehicle.scss";
import StatusChange from "./StatusChange";

const size = "2x";

const getColor = status => {
  switch (status) {
    case "good":
      return "green";
    case "warning":
      return "orange";
    case "bad":
      return "red";
    case "no_device":
      return "grey";
    default:
      return "grey";
  }
};

const VehicleHeader = ({
  vehicle,
  showLocation,
  store,
  uuid,
  classes,
  odometer,
  city,
  status,
  serviceStatus,
  units,
  mileageUntil,
  gasLevel,
  currentUser,
  serviceNames,
  serviceVendors,
  refetch
}) => (
  <div className="vehicle-container header">
    <div className="header-container">
      <img
        className="display-details-photo"
        src={vehicle.imageLink}
        alt="Vehicle"
      />
      <div className="vehicle-header-footer">
        <div className="header-icons">
          <ActionButtons vehicle={vehicle} showLocation={showLocation} />
          <div className="odometer-display">
            <div className="odometer-icons">
              <FontAwesomeIcon
                style={{ fontSize: "17px" }}
                icon={faTachometerAlt}
              />
            </div>
            <div className="odometer-icons">
              {formatOdometer(odometer.reading) +
                (city.name === "toronto" ? " km" : " mi")}
            </div>
          </div>
        </div>
        <div className="service-button">
          <div className="header-icons">
            <div className="service-button">
              <StatusChange
                vehicle={vehicle}
                vehicleId={vehicle.id}
                inParkingLot={vehicle.inParking}
                parkingLot={vehicle.parkingLot}
                store={store}
                currentUser={currentUser}
                serviceNames={serviceNames}
                serviceVendors={serviceVendors}
                refetch={refetch}
                operationStatus={status}
              />
            </div>

            <div
              className="odometer-display"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="fuel-icons">
                <FontAwesomeIcon
                  style={{ fontSize: "17px" }}
                  icon={faGasPump}
                />
              </div>
              <div className="fuel-bar">
                {gasLevel ? (
                  <div
                    className="fuel-fill"
                    style={{
                      width: `${gasLevel.reading}%`
                    }}
                  ></div>
                ) : null}
              </div>
              <div className="odometer-icons" style={{ paddingRight: "0" }}>
                {gasLevel ? `${gasLevel.reading}%` : "--"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="status-container">
      <div>
        <div className="operation-title">Service Status:</div>
        <VehicleStatus
          status={serviceStatus}
          mileage={mileageUntil}
          unit={units}
          display="details"
        />
      </div>
      <div>
        <div className="operation-title">Operation Status:</div>
        <PlateStatus
          status={status}
          display="details"
          endDate={vehicle.currentBooking && vehicle.currentBooking.endTime}
        />
      </div>
      <div>
        <div className="operation-title">Hardware Status:</div>
        <div
          className={stylesH.obdStatusContainer}
          style={{ alignItems: "baseline", paddingTop: "5px" }}
        >
          <div>
            <div
              className={classNames(
                stylesH.circle,
                stylesH[getColor(vehicle.gpsStatus.value)]
              )}
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div
            className={stylesH.obdStatus}
            style={{ fontSize: "16px", color: "#414141" }}
          >
            {vehicle.gpsStatus.name}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const styles = theme => ({
  root: {
    padding: "8px 8px"
  }
});

export default withStyles(styles)(VehicleHeader);
