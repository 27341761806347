import React from "react";
import styles from "../batchActions.module.scss";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TaskSelections = ({ selections, removeSelection }) => {

  return (
    <div className={styles.selections}>
      <div className={styles.header}>Tasks: {selections.length}</div>
      <div className={styles.selectionsBody}>
        {selections.map((selection) => (
          <div className={styles.individualSelection}>
            <div className={styles.identifier}>
              {selection}
            </div>
            <FontAwesomeIcon
              onClick={(evt) => {
                evt.stopPropagation();
                removeSelection(selection);
              }}
              icon={faTimes}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskSelections;
