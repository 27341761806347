import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import Button from "../../../../artzu/src/Button";
import { Fragment } from "react";
import DeleteMessage from "./DeleteMessage";

const ChangeList = ({
  scheduledChanges,
  selected,
  handleSelected,
  close,
  toggleRefresh,
}) => {
  const [toDelete, setToDelete] = useState();
  const [isDelete, setIsDelete] = useState();
  const toggleDelete = (change) => {
    setIsDelete(!isDelete);
    setToDelete(change);
  };

  const closeDelete = () => {
    setIsDelete(false);
  };

  return (
    <Fragment>
      <div className="changeList">
        <div className="changeTitle">
          <div className="title-status">Status</div>
          <div className="title-subStatus">Sub Status</div>
          <div className="title-td">Time & Duration</div>
          <div className="title-user">User</div>
          <div className="title-comments">Comments</div>
        </div>
        <div className="listBox">
          {scheduledChanges &&
            scheduledChanges.map((change) => (
              <div
                className={
                  selected.some((a) => a === change.startTime)
                    ? "rowChangeInfoSelected"
                    : "rowChangeInfo"
                }
                onClick={() => handleSelected(change.startTime)}
              >
                <div className="content-status">
                  <div
                    className="operationSymbolChangeList"
                    style={
                      change.status.styles.isSolid
                        ? { background: `${change.status.styles.color}` }
                        : {
                            background: "#ffffff",
                            border: `2px solid black`,
                          }
                    }
                  />
                  {change.status.name}
                </div>
                <div className="content-subStatus"></div>
                <div className="content-td">
                  {moment(change.startTime).format("MMM D, YYYY h:mm a")}
                </div>
                <div className="content-user">{change.lastChangedBy}</div>
                <div className="content-comments">{change.comments}</div>
                <div className="change-delete">
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="editCircle"
                      size="lg"
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="editDelete"
                      size="sm"
                      style={{ marginLeft: "4.5" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDelete(change);
                      }}
                    />
                  </span>
                </div>
              </div>
            ))}
        </div>
        <div className="changeListClose">
          <Button
            label={"Close"}
            fullWidth="152px"
            height="40px"
            color="#414141"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={close}
          />
        </div>
      </div>
      {isDelete && (
        <DeleteMessage
          open={isDelete}
          toDelete={toDelete}
          close={closeDelete}
          toggleRefresh={toggleRefresh}
        />
      )}
    </Fragment>
  );
};

export default ChangeList;
