import React, { Component, Fragment } from "react";
import SelectInput from "./SelectInput";
import Option from "../../../artzu/src/Option";

import _ from "lodash";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ParkingSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidUpdate(oldProps, oldState) {
  //   console.log(_.isEqual(this.props, oldProps));
  //   console.log(_.isEqual(this.state, oldState));
  // }

  render() {
    const { parkingLots, edit, parkingLot, toggleEdit, vehicleId } = this.props;
    return (
      <Fragment>
        <SelectInput
          vehicleId={vehicleId}
          isInput={edit}
          prompt={parkingLot.name}
          name={parkingLot.name}
          parkingLots={parkingLots}
          parkingLot={parkingLot}
          toggleEdit={toggleEdit}
        />
      </Fragment>
    );
  }
}

export default ParkingSelect;
