import React from "react";
import VehicleDetailsLower from "./VehicleDetailsLower";
import VehicleHeader from "./VehicleHeader";

const VehicleDetails = ({
  vehicleInfo,
  showLocation,
  city,
  operationStatus,
  teslaVehicleStatus,
  serviceStatus,
  inspectionStatus,
  sentryModeStatus,
  valetModeStatus,
  refetch,
  currentUser,
  vehicleTimeZone,
}) => {
  return (
    <div className="vehicleDetails">
      <VehicleHeader
        vehicle={vehicleInfo}
        showLocation={showLocation}
        city={city}
        operationStatus={operationStatus}
        teslaVehicleStatus={teslaVehicleStatus}
        serviceStatus={serviceStatus}
        inspectionStatus={inspectionStatus}
        sentryModeStatus={sentryModeStatus}
        valetModeStatus={valetModeStatus}
        refetch={refetch}
      />
      <VehicleDetailsLower
        vehicleInfo={vehicleInfo}
        currentUser={currentUser}
        teslaVehicleStatus={teslaVehicleStatus}
        refetch={refetch}
        city={city}
        vehicleTimeZone={vehicleTimeZone}
      />
    </div>
  );
};

export default VehicleDetails;
