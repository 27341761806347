import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import styles from "./actionButtons.module.scss";
import classNames from "classnames";

import gql from "graphql-tag";
import { historyGpsString } from "../../../Queries/queryStrings";
import Modal from "../../../artzu/src/Modal";
import Button from "../../../artzu/src/Button";
import CommandConfirm from "./CommandConfirm";
import { useApolloClient } from "@apollo/client";

class AvcActionChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "ready",
      modalOpen: false
    };
  }

  AVC_ACTION = gql`
    mutation avcEvent($vehicleId: ID!, $actionType: String!) {
      avcEvent(vehicleId: $vehicleId, action: $actionType) {
        success
        errors
      }
    }
  `;

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });
  sendCommand = () => {
    const { client, vehicleId, action } = this.props;
    client.mutate({
      mutation: this.AVC_ACTION,
      variables: {
        vehicleId: vehicleId,
        actionType: action
      }
    });
    this.toggleModal();
  };

  render() {
    const { icon, action } = this.props;
    const { modalOpen } = this.state;
    return (
      <Fragment>
        <CommandConfirm
          open={modalOpen}
          close={this.toggleModal}
          confirm={this.sendCommand}
          action={action}
        />
        <div
          className={classNames(styles.button, styles["blue"])}
          onClick={this.toggleModal}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      </Fragment>
    );
  }
}

const AvcAction = props => {
  const client = useApolloClient();
  return <AvcActionChild client={client} {...props} />;
};

export default AvcAction;
