import gql from "graphql-tag";

const GET_VEHICLE_TAGS = gql`
  query vehicleTags($vehicleIds: [ID!]!) {
    getAllVehicleTags(vehicleIds: $vehicleIds) {
      allTags {
        id
        name
        attachedToCount
      }
      vehicles {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

const GET_ALL_TAGS = gql`
  query getTags {
    getAllTags {
      id
      name
      attachedToCount
    }
  }
`;

const BULK_MODIFY_TAGS = gql`
  mutation bulkModifyVehicleTags(
    $newTags: [String!]
    $tagsToRemove: [String!]
    $vehicleIds: [ID!]!
  ) {
    bulkModifyVehicleTags(
      newTags: $newTags
      tagsToRemove: $tagsToRemove
      vehicleIds: $vehicleIds
    ) {
      success
      errors
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($tagId: ID!) {
    deleteTag(tagId: $tagId) {
      success
      errors
    }
  }
`;

const CREATE_NEW_TAG = gql`
  mutation createTags($tagName: String!) {
    createTag(tagName: $tagName) {
      success
      errors
    }
  }
`;

const MERGE_TAGS = gql`
  mutation mergeTags($tagIdToRetain: ID!, $tagIdsToMerge: [ID!]!) {
    mergeTags(tagIdToRetain: $tagIdToRetain, tagIdsToMerge: $tagIdsToMerge) {
      success
      errors
    }
  }
`;

const EDIT_TAG = gql`
  mutation editTag($tag: UpsertTagArguments!) {
    editTag(tag: $tag) {
      success
      errors
    }
  }
`;

export {
  GET_VEHICLE_TAGS,
  GET_ALL_TAGS,
  BULK_MODIFY_TAGS,
  DELETE_TAG,
  CREATE_NEW_TAG,
  MERGE_TAGS,
  EDIT_TAG,
};
