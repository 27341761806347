import React from "react";
import _ from "lodash";
import VehicleInfoDetails from "./VehicleInfoDetails";
import AverageUsage from "./AverageUsage";
import GasLevel from "./GasLevel";
import LockboxCodeSection from "./LockboxCodeSection";

const VehicleInfoRight = ({
  city,
  vehicle,
  isEdit,
  lockboxCode,
  handleCodeChange,
}) => {
  const transponder = vehicle.transponder;
  const avgFleetMileage = vehicle.avgFleetMileage;
  const avgMileage = vehicle.avgMileage;
  const gasLevel = vehicle.gasLevel;
  return (
    <div
      className="content-box"
      style={{
        paddingLeft: "160px",
      }}
    >
      <VehicleInfoDetails details={"City"} content={_.startCase(city.name)} />
      <LockboxCodeSection
        vehicle={vehicle}
        isEdit={isEdit}
        lockboxCode={lockboxCode}
        handleCodeChange={handleCodeChange}
      />
      <VehicleInfoDetails details={"407 Transponder"} content={transponder} />
      <AverageUsage
        avgFleetMileage={avgFleetMileage}
        avgMileage={avgMileage}
        city={city}
      />
      <GasLevel gasLevel={gasLevel} />
    </div>
  );
};

export default VehicleInfoRight;
