import React from "react";
import styles from "./batchActions.module.scss";
import Button from "../../../artzu/src/Button";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Selections = ({ selections, removeSelection, close, item }) => {
  return (
    <div className={styles.selections}>
      <div className={styles.header}>
        {item}: {selections.length}
      </div>
      <div className={styles.selectionsBody}>
        {selections.map((selection) => (
          <div className={styles.individualSelection}>
            <div className={styles.identifier}>{selection}</div>
            <FontAwesomeIcon
              onClick={(evt) => {
                evt.stopPropagation();
                removeSelection(selection.id);
              }}
              icon={faTimes}
            />
          </div>
        ))}
      </div>
      <div className={styles.selectionsFooter}>
        <Button
          label={"Cancel"}
          height="39px"
          fullWidth="150px"
          color="#414141"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #DEDEDE"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default Selections;
