import _ from "lodash";
import { m } from "../../utils/";
import moment from "moment";
import TextValue from "./CellValues/Base/TextValue";
import TimeInStatusValue from "./CellValues/Base/TimeInStatusValue";
import ParkingStatusValue from "./CellValues/Base/ParkingStatusValue";
import LicensePlateValue from "./CellValues/Base/LicensePlateValue";
import BooleanTagValue from "./CellValues/Base/BooleanTagvalue";
import LinkValue from "./CellValues/Base/LinkValue";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor]?.value,
    };
  };
};

const baseDateProcessor = (accessor, timeZone) => {
  return (data) => {
    const value = data[accessor]?.value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("MMM D, YYYY")
        : "",
      subtext: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("h:mm:ss A z")
        : "",
    };
  };
};

const rowComponentsSpot = (timeZone) => ({
  parkingSpotIdentifier: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingSpotIdentifier"),
  },

  parkingSpotStatus: {
    component: ParkingStatusValue,
    dataProcessor: baseTextProcessor("parkingSpotStatus"),
  },

  licensePlate: {
    component: LicensePlateValue,
    dataProcessor: (data) => {
      const plate = data["plate"] && data["plate"].value;
      const vehicleId = data["vehicleId"] && data["vehicleId"].value;

      return {
        plate: plate,
        link: vehicleId && `https://fleet.autzu.com/vehicles/${vehicleId}`,
      };
    },
  },

  lastUpdated: {
    component: TextValue,
    dataProcessor: baseDateProcessor("updatedAt", timeZone),
  },

  timeInStatus: {
    component: TimeInStatusValue,
    dataProcessor: baseTextProcessor("timeInStatus"),
  },
  hasCharger: {
    component: BooleanTagValue,
    dataProcessor: baseTextProcessor("hasCharger"),
  },

  relatedBooking: {
    component: LinkValue,
    dataProcessor: (data) => {
      const bookingId =
        data["bookingReference"] && data["bookingReference"].value;
      const driverId = data["driverReference"] && data["driverReference"].value;

      return {
        bookingId: bookingId,
        link:
          driverId &&
          bookingId &&
          `https://admin.autzu.com/users/${driverId}/bookings/${bookingId}`,
      };
    },
  },
  parkingLot: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingLotName"),
  },
});

export { rowComponentsSpot };
