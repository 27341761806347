import React, { Component, Fragment } from "react";
import { m, parsePredictionData } from "../../utils";
import ExtensionModal from "./ExtensionModal";
import UndoExtensionModal from "./UndoExtensionModal";
import { faPen, faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./vehicle.scss";

const formatScheduledDate = date =>
  date ? m(date).format("MMM DD YYYY") : "Not scheduled";

class VehicleService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extensionAmount: null,
      extensionId: null,
      note: "",
      extensionModal: false,
      undoExtension: false,
      selectedExtension: {}
    };
  }

  updateExtension = (extensionAmount, extensionId) => {
    this.setState({
      extensionAmount,
      extensionId
    });
  };

  toggleModal = () =>
    this.setState({ extensionModal: !this.state.extensionModal });

  toggleUndoExtension = (selectedExtension = {}) =>
    this.setState({
      undoExtension: !this.state.undoExtension,
      selectedExtension
    });

  render() {
    const {
      upcomingEvents = [],
      projectedRoutineServices = [],
      recentServiceHistory = [],
      city,
      odometer
    } = this.props;

    const distanceUnit = city === "toronto" ? " km" : " mi";

    const { extensionModal, undoExtension, selectedExtension } = this.state;

    return (
      <div
        className="vehicle-container"
        style={{ width: "calc(100vw - 60px)" }}
      >
        <ExtensionModal
          open={extensionModal}
          closeModal={this.toggleModal}
          title={"Extend Routine Service"}
          projectedRoutineServices={projectedRoutineServices}
          city={city}
          odometer={odometer}
        />
        <UndoExtensionModal
          open={undoExtension}
          closeModal={this.toggleUndoExtension}
          title={"Delete Extension"}
          selectedExtension={selectedExtension}
        />
        <div className="vehicle-service">
          <h4 className="service-text__large top">Next service dates</h4>
          <p className="blue small service-text__medium">SCHEDULED SERVICE</p>
          {upcomingEvents.length ? (
            upcomingEvents.map((event, index) => {
              return (
                <div key={index} className="vehicle-container">
                  <p className="service-text__small">{event.name}</p>
                  <p className="service-text__small">
                    {formatScheduledDate(event.scheduledDate)}
                  </p>
                </div>
              );
            })
          ) : (
            <p>--</p>
          )}
          <div className="extension__title">
            <div className="blue  small service-text__medium top">
              PREDICTED ROUTINE SERVICE
            </div>
            {projectedRoutineServices.length ? (
              <div
                className="blue small details service-text__medium top"
                onClick={this.toggleModal}
              >
                EXTEND
              </div>
            ) : null}
          </div>
          {projectedRoutineServices.length ? (
            projectedRoutineServices.map((event, index) => {
              const extended = !!event.totalExtension;
              const maintenanceExtensions = event.maintenanceExtensions;
              return (
                <div
                  key={index}
                  className="vehicle-container service-text__small"
                >
                  <div className="extension__container">
                    <div className="extension__text">
                      <div>{event.name}</div>
                      {extended ? (
                        <div className="extension__amount">
                          Extended: {event.totalExtension} km
                        </div>
                      ) : null}
                    </div>
                    {extended ? (
                      <Fragment>
                        <FontAwesomeIcon
                          className="extension__icon"
                          icon={faPen}
                        />
                        <FontAwesomeIcon
                          className="extension__icon"
                          icon={faRedoAlt}
                          onClick={() =>
                            this.toggleUndoExtension(
                              maintenanceExtensions[
                                maintenanceExtensions.length - 1
                              ]
                            )
                          }
                        />
                      </Fragment>
                    ) : null}
                  </div>
                  <div className="service-text__small">
                    {parsePredictionData(
                      event.daysUntil,
                      event.mileageUntil,
                      distanceUnit
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p>--</p>
          )}

          <p className="blue small details service-text__medium top">
            RECENT SERVICE HISTORY
          </p>
          {recentServiceHistory.length ? (
            recentServiceHistory.map((event, index) => {
              return (
                <div key={index} className="vehicle-container">
                  <p className="service-text__small">{event.name}</p>
                  <p className="service-text__small">
                    {m(event.completedDate).format("MMM DD YYYY")}
                  </p>
                </div>
              );
            })
          ) : (
            <p>--</p>
          )}
        </div>
      </div>
    );
  }
}

export default VehicleService;
