import React, { Component, Fragment } from "react";
import InspectionModal from "./Inspections/InspectionModal";
import ServiceEventModal from "./ServiceEventModal";
import _ from "lodash";
import { m } from "../../utils";
import "./service.css";
import ConfirmModal from "./ConfirmModal";

class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      confirmModalOpen: false,
      confirmType: ""
    };
  }

  componentDidMount() {
    const { odometerReading } = this.props;
    this.setState({ odometerReading });
  }

  componentDidUpdate(prevProps) {
    this.spreadData(prevProps);
  }

  openConfirmModal = (data, eventType) => {
    if (data.bookingRelevant) {
      const confirmType = `${eventType}${
        data.bookingSuccess ? "Success" : "Fail"
      }`;
      console.log(confirmType);
      this.setState({ confirmModalOpen: true, confirmType });
    }
  };

  setName = name => this.setState({ name: name });

  toggleEndDate = () => {
    this.setState({ endDateSelected: !this.state.endDateSelected });
  };

  spreadData = prevProps => {
    if (prevProps.type !== this.props.type) {
      // instead of spread operator, which includes Apollo Symbol(id) key
      this.setState(_.pick(this.props.data, Object.keys(this.state)));
    }
  };

  addErrors = errorType => {
    let errors = [];
    if (_.includes(errorType, "noDescription")) {
      errors.push(
        "Description required in cases where issues were discovered during the inspection!"
      );
    }
    if (_.includes(errorType, "descriptionRequired")) {
      errors.push("Description required!");
    }
    if (_.includes(errorType, "odometer")) {
      errors.push(
        "The input kilometer is more than 5000km of the previous one!"
      );
    }
    if (_.includes(errorType, "noName")) {
      errors.push("Please select a service type!");
    }
    if (_.includes(errorType, "noVendor")) {
      errors.push("Please enter a vendor!");
    }
    if (_.includes(errorType, "noOdometer")) {
      errors.push("Please enter an odometer reading!!");
    }
    this.setState({ errors });
  };

  onChange = (key, val, treadDepth = false) => {
    if (treadDepth) {
      //Change the tread depth to an integer before setting state
      let treadDepth = [...this.state.treadDepth];
      treadDepth[key] = Number(val);
      this.setState({ treadDepth: treadDepth });
    } else {
      this.setState({ [key]: val });
    }
  };

  clear = () => {
    const { toggle, odometerReading } = this.props;
    this.setState({
      ...initialState,
      odometerReading,
      confirmModalOpen: false
    });
    toggle("", null);
  };

  render() {
    const {
      open,
      type,
      action,
      vehicleId,
      refetch,
      fleetManager,
      prevOdometerReading,
      serviceCatsNames
    } = this.props;

    const { errors, confirmModalOpen, confirmType } = this.state;
    const { endDateSelected } = this.state;
    const eventData = _.pick(this.state, serviceEventDataKeys);
    const inspectionData = _.pick(this.state, inspectionDataKeys);
    const relevantState = _.omit(this.state, [
      "errors",
      "endDateSelected",
      "confirmModalOpen",
      "confirmType"
    ]);

    return (
      <div className={`modal-overlay ${open ? "visible" : "invisible"}`}>
        {confirmModalOpen ? (
          <ConfirmModal confirmType={confirmType} clear={this.clear} />
        ) : (
          <Fragment>
            {type === "service" ? (
              <ServiceEventModal
                endDateSelected={endDateSelected}
                toggleEndDate={this.toggleEndDate}
                params={{
                  ...relevantState,
                  id: this.state.id,
                  vehicleId,
                  scheduledEndDate: endDateSelected
                    ? relevantState.scheduledEndDate
                    : m()
                        .add(1, "years")
                        .format()
                }}
                action={action}
                eventData={eventData}
                onChange={this.onChange}
                refetch={refetch}
                clear={this.clear}
                serviceCatsNames={serviceCatsNames}
                addErrors={this.addErrors}
                errors={errors}
                openConfirmModal={this.openConfirmModal}
              />
            ) : (
              <InspectionModal
                action={action}
                inspectionData={inspectionData}
                onChange={this.onChange}
                fleetManager={fleetManager}
                errors={errors}
                relevantState={relevantState}
                vehicleId={vehicleId}
                refetch={refetch}
                clear={this.clear}
                addErrors={this.addErrors}
                prevOdometerReading={prevOdometerReading}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const initialState = {
  endDateSelected: true,
  id: "",
  name: "",
  description: "",
  vendor: "",
  complete: false,
  completedDate: "",
  scheduled: false,
  scheduledDate: "",
  scheduledEndDate: "",
  cost: 0.0,
  odometerReading: 0,
  link: "",
  exteriorWash: false,
  interiorWash: false,
  tirePressure: false,
  dashLights: false,
  washerFluid: false,
  socket: false,
  insuranceSlip: false,
  vehicleOwnership: false,
  safetyCertificate: false,
  damage: false,
  vinyl: false,
  gasLevel: 100,
  gasFilled: false,
  treadDepth: [0, 0, 0, 0],
  errors: []
};

const serviceEventDataKeys = [
  "id",
  "name",
  "description",
  "scheduled",
  "scheduledDate",
  "scheduledEndDate",
  "vendor",
  "cost",
  "complete",
  "completedDate",
  "odometerReading"
];

const inspectionDataKeys = [
  "exteriorWash",
  "interiorWash",
  "tirePressure",
  "dashLights",
  "washerFluid",
  "socket",
  "vinyl",
  "insuranceSlip",
  "vehicleOwnership",
  "safetyCertificate",
  "damage",
  "gasLevel",
  "gasFilled",
  "odometerReading",
  "description",
  "treadDepth",
  "completedDate"
];

export default EventModal;
