import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledTable = styled.div`
  /* display: flex;
  flex-direction: column; */
  width: ${({ width }) => width};
  background-color: ${({ color }) => color};
`;

export const _table = ({ width, color, children }) => (
  <StyledTable width={width} color={color}>
    {children}
  </StyledTable>
);

_table.defaultProps = {
  width: "100%",
  children: "Children required for <Table>"
};

_table.propTypes = {
  width: PropTypes.string,
  children: PropTypes.any.isRequired
};
