import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const LinkValue = ({ cellData, classes }) => {
  const { bookingId, link } = cellData;
  const isClickable = bookingId && link;
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {isClickable && (
        <a
          href={link}
          className={styles.licensePlateAutzu}
          onClick={(e) => e.stopPropagation()}
        >
          {bookingId}
        </a>
      )}
    </div>
  );
};

export default LinkValue;
