import { useMutation } from "@apollo/client";
import React from "react";
import Modal from "../../../../artzu/src/Modal";
import { REMOVE_TEMPLATE } from "../../queries";
import styles from "../../task.module.scss";

const DeleteModal = ({
  open,
  toggleDelete,
  toggleCur,
  templateId,
  refetch,
}) => {
  const [deleteTemplateMutation, { loading: isDeleteLoading }] = useMutation(
    REMOVE_TEMPLATE
  );
  const handleDelete = () => {
    deleteTemplateMutation({
      variables: {
        templateId: templateId,
      },
    }).then(({ data: { removeTemplate } }) => {
      if (removeTemplate.success) {
        refetch();
        toggleCur();
        console.log("Delete template Succeed!");
      } else {
        console.log("Delete template error!");
      }
    });
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.discardModal}>
        <div className={styles.templateTitle}>Delete template</div>
        <div>Are you sure you want to delete this template?</div>
        <div className={styles.templateButtons}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              toggleDelete();
            }}
          >
            No
          </button>
          <button
            className={styles.chooseButton}
            onClick={() => {
              handleDelete();
              toggleDelete();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
