import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import { ApolloConsumer } from "@apollo/client";
import { saveState } from "../utils/localStorage";
import "../Components/Login/login.css";

class SignInMutation extends Component {
  SIGN_IN = gql`
    mutation SignUserIn($credentials: SignInAttributes!) {
      signIn(credentials: $credentials) {
        user {
          email
          authenticationToken
        }
        error
      }
    }
  `;

  GET_CURRENT_USER = gql`
    {
      currentUser {
        email
      }
    }
  `;

  render() {
    const { email, password, displayError } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <Mutation
            mutation={this.SIGN_IN}
            update={(cache, { data }) => {
              const query = this.GET_CURRENT_USER;
              cache.writeQuery({
                query: query,
                data: { currentUser: data.signIn.user }
              });
            }}
            fetchOptions={{
              credentials: "include"
            }}
            variables={{
              credentials: { email: email.toLowerCase(), password }
            }}
            onCompleted={data => {
              if (data.signIn.user) {
                console.log(data.signIn.user);
                saveState({
                  auth: {
                    email: data.signIn.user.email,
                    token: data.signIn.user.authenticationToken,
                  },
                });
                this.props.history.push("/");
                window.location.reload();
              } else {
                displayError(data.signIn.error);
              }
            }}
          >
            {signIn => (
              <button
                className="login-button"
                type="submit"
                onClick={() => {
                  signIn();
                }}
              >
                LOG IN
              </button>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default withRouter(SignInMutation);
