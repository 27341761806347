import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const LicensePlateValue = ({ cellData, classes }) => {
  const { plate, link } = cellData;
  const isAutzuCar = plate?.includes("GV") && link;
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {isAutzuCar ? (
        <a
          href={link}
          className={styles.licensePlateAutzu}
          onClick={(e) => e.stopPropagation()}
        >
          {plate}
        </a>
      ) : (
        <div className={styles.licensePlatePersonal}>{plate}</div>
      )}
    </div>
  );
};

export default LicensePlateValue;
