import React, { useState } from "react";
import { Fragment } from "react";
import Button from "../../../../artzu/src/Button";
import ScheduledStatusChange from "./ScheduledStatusChange";
import moment from "moment";
import { capitalize } from "../../../../utils";

const InfoCard = ({ vehicle, toggleChangeStatus, toggleRefresh }) => {
  const [isScheduledList, setIsScheduledList] = useState();

  const toggleScheduled = () => {
    setIsScheduledList(!isScheduledList);
  };
  const close = () => {
    setIsScheduledList(false);
  };

  let scheduledChanges = vehicle.scheduledStatusChanges;
  scheduledChanges =
    scheduledChanges &&
    scheduledChanges
      .slice()
      .sort((change1, change2) =>
        change1.startTime > change2.startTime
          ? 1
          : change2.startTime > change1.startTime
          ? -1
          : 0
      );
  const count = scheduledChanges && scheduledChanges.length - 1;
  const statusColor =
    scheduledChanges && scheduledChanges[0].status.styles.color;
  const isSolid = scheduledChanges && scheduledChanges[0].status.styles.isSolid;

  return (
    <Fragment>
      <div className="info-card">
        <div style={{ opacity: "0.5" }}>Next status change scheduled to</div>
        <div className="info-card-scheduled">
          <div
            className="operationSymbolInfoCard"
            style={
              isSolid
                ? { background: `${statusColor}` }
                : {
                    background: "#ffffff",
                    border: `${statusColor}`,
                  }
            }
          />
          <div className="info-card-status">
            {capitalize(scheduledChanges && scheduledChanges[0].status.name)}
          </div>
          {moment(scheduledChanges && scheduledChanges[0].startTime).format(
            "MMM D, YYYY h:mm a"
          )}
        </div>
        <div className="info-card-user">
          by {scheduledChanges && scheduledChanges[0].lastChangedBy}
          {moment(scheduledChanges && scheduledChanges[0].lastChangedAt).format(
            " MMM D, YYYY h:mm a"
          )}
        </div>
        <div className="more-changes">
          +{count} more upcoming status changes
        </div>
        <div className="cardButton">
          <Button
            label={"View more"}
            height="15px"
            fullWidth="54px"
            color="#ffffff"
            backColor="#128dff"
            radius="7.5px"
            size="8px"
            border="1px solid #128dff"
            padding="0"
            onClick={toggleScheduled}
          />
        </div>
      </div>
      {isScheduledList && (
        <ScheduledStatusChange
          open={isScheduledList}
          close={close}
          vehicle={vehicle}
          toggleChangeStatus={toggleChangeStatus}
          scheduledChanges={scheduledChanges}
          toggleRefresh={toggleRefresh}
          statusColor={statusColor}
        />
      )}
    </Fragment>
  );
};

export default InfoCard;
