import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./layout.css";
import logo from "../../images/autzu.png";
import mapIcon from "../../images/NavBar/Map.png";
import vehiclesIcon from "../../images/NavBar/Vehicles.png";
import tasksIcon from "../../images/NavBar/Tasks.png";
import parkingIcon from "../../images/NavBar/Parking.png";
import vehiclesAvailableIcon from "../../images/NavBar/VehiclesAvailable.png";
import settingsIcon from "../../images/NavBar/Settings.png";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faCar,
  faClipboardList,
} from "@fortawesome/pro-solid-svg-icons";
import LocationSelect from "./LocationSelect";
// import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import UserNav from "./UserNav";
import _ from "lodash";

import { getProfileString } from "../../Queries/queryStrings";
import LocationSelectNew from "./LocationSelectOld";

const styles = (theme) => ({
  nav: { height: "-webkit-fill-available", zIndex: theme.zIndex.header },
  toggle: { color: theme.blue },
});

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidMount() {}

  selectPage = (name) => {
    this.setState({ selected: name });
  };

  render() {
    const { history, classes } = this.props;
    const { selected } = this.state;
    return (
      <Query
        query={gql`
          ${getProfileString}
        `}
      >
        {({ loading, error, data }) => {
          if (loading || error) {
            return null;
          }
          if (data.currentUser) {
            return (
              <div className={classNames("nav-banner", classes.nav)}>
                <div className="navbar-link-container">
                  <Link className="links" to={`/`}>
                    <div
                      className="icon-container"
                      onClick={() => {
                        this.selectPage("vehicles");
                        let formatPath = _.filter(
                          history.location.pathname.split("/"),
                          (name) => {
                            return name !== "";
                          }
                        );
                        console.log(formatPath);
                      }}
                      style={
                        history.location.pathname === "/" ||
                        history.location.pathname.includes("vehicle")
                          ? { backgroundColor: "#414141" }
                          : selected === "vehicles" &&
                            (history.location.pathname === "/" ||
                              history.location.pathname.includes("vehicle"))
                          ? { backgroundColor: "#414141" }
                          : null
                      }
                    >
                      <p
                        className={classNames(
                          {
                            [classes.toggle]: history.location.pathname === "/",
                          },
                          "navbar-link"
                        )}
                      >
                        <img
                          src={vehiclesIcon}
                          style={
                            history.location.pathname === "/" ||
                            history.location.pathname.includes("vehicle")
                              ? { color: "#FFFFFF" }
                              : selected === "vehicles" &&
                                (history.location.pathname === "/" ||
                                  history.location.pathname.includes("vehicle"))
                              ? { color: "#FFFFFF" }
                              : { color: "#FFFFFF", opacity: "0.5" }
                          }
                          width="29.92px"
                          height="21.8px"
                        />
                      </p>
                    </div>
                  </Link>
                  <Link className="links" to={`/tasks`}>
                    <div
                      className="icon-container"
                      onClick={() => {
                        this.selectPage("tasks");
                        let formatPath = _.filter(
                          history.location.pathname.split("/"),
                          (name) => {
                            return name !== "";
                          }
                        );
                        console.log(formatPath);
                      }}
                      style={
                        history.location.pathname === "/tasks" ||
                        history.location.pathname.includes("tasks")
                          ? { backgroundColor: "#414141" }
                          : selected === "tasks" &&
                            (history.location.pathname === "/tasks" ||
                              history.location.pathname.includes("task"))
                          ? { backgroundColor: "#414141" }
                          : null
                      }
                    >
                      <p
                        className={classNames(
                          {
                            [classes.toggle]:
                              history.location.pathname === "/tasks",
                          },
                          "navbar-link"
                        )}
                      >
                        <img
                          src={tasksIcon}
                          style={
                            history.location.pathname === "/tasks" ||
                            history.location.pathname.includes("task")
                              ? { color: "#FFFFFF" }
                              : selected === "tasks" &&
                                (history.location.pathname === "/tasks" ||
                                  history.location.pathname.includes("task"))
                              ? { color: "#FFFFFF" }
                              : { color: "#FFFFFF", opacity: "0.5" }
                          }
                          width="22.42px"
                          height="26.68px"
                        />
                      </p>
                    </div>
                  </Link>
                  <Link className="links" to={`/map`}>
                    <div
                      className="icon-container"
                      onClick={() => {
                        this.selectPage("map");
                        let formatPath = _.filter(
                          history.location.pathname.split("/"),
                          (name) => {
                            return name !== "";
                          }
                        );
                        console.log(formatPath);
                      }}
                      style={
                        history.location.pathname === "/map"
                          ? { backgroundColor: "#414141" }
                          : selected === "map" &&
                            history.location.pathname === "/map"
                          ? { backgroundColor: "#414141" }
                          : null
                      }
                    >
                      <p
                        className={classNames(
                          {
                            [classes.toggle]:
                              history.location.pathname === "/map",
                          },
                          "navbar-link"
                        )}
                      >
                        <img
                          src={mapIcon}
                          style={
                            history.location.pathname === "/map"
                              ? { color: "#FFFFFF" }
                              : selected === "map" &&
                                history.location.pathname === "/map"
                              ? { color: "#FFFFFF" }
                              : { color: "#FFFFFF", opacity: "0.5" }
                          }
                          width="29.75px"
                          height="21.92px"
                        />
                      </p>
                    </div>
                  </Link>
                  <Link className="links" to="/availability">
                    <div
                      className="icon-container"
                      onClick={() => {
                        this.selectPage("availability");
                        let formatPath = _.filter(
                          history.location.pathname.split("/"),
                          (name) => {
                            return name !== "";
                          }
                        );
                      }}
                      style={
                        history.location.pathname === "/availability"
                          ? { backgroundColor: "#414141" }
                          : selected === "tasks" &&
                            history.location.pathname === "/availability"
                          ? { backgroundColor: "#414141" }
                          : null
                      }
                    >
                      <p
                        className={classNames(
                          {
                            [classes.toggle]:
                              history.location.pathname === "/availability",
                          },
                          "navbar-link"
                        )}
                      >
                        <img
                          src={vehiclesAvailableIcon}
                          style={
                            history.location.pathname === "/availability"
                              ? { color: "#FFFFFF" }
                              : selected === "tasks" &&
                                history.location.pathname ===
                                  "/availableVehicles"
                              ? { color: "#FFFFFF" }
                              : { color: "#FFFFFF", opacity: "0.5" }
                          }
                          width="29.75px"
                        />
                      </p>
                    </div>
                  </Link>
                  <Link className="links" to="/spots">
                    <div
                      className="icon-container"
                      onClick={() => {
                        this.selectPage("spots");
                        let formatPath = _.filter(
                          history.location.pathname.split("/"),
                          (name) => {
                            return name !== "";
                          }
                        );
                      }}
                      style={
                        history.location.pathname === "/spots"
                          ? { backgroundColor: "#414141" }
                          : null
                      }
                    >
                      <p
                        className={classNames(
                          {
                            [classes.toggle]:
                              history.location.pathname === "/spots",
                          },
                          "navbar-link"
                        )}
                      >
                        <img
                          src={parkingIcon}
                          style={
                            history.location.pathname === "/spots"
                              ? { color: "#FFFFFF" }
                              : { color: "#FFFFFF", opacity: "0.5" }
                          }
                          width="29.75px"
                        />
                      </p>
                    </div>
                  </Link>

                  <div className="nav-footer">
                    <LocationSelect currentUser={data.currentUser} />
                    <UserNav
                      name={data.currentUser.firstName}
                      imageLink={data.currentUser.imageLink}
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles)(NavBar));
