import React from "react";
import styles from "./parkingSpot.module.scss";
import ParkingSpotBasics from "./ParkingSpotBasics";
import ParkingSpotTimeline from "./ParkingSpotTimeline";

const ParkingSpotPageContent = ({ parkingSpotData, timelineData }) => {
  return (
    <div className={styles.pageContentContainer}>
      <ParkingSpotBasics parkingSpotData={parkingSpotData} />
      <ParkingSpotTimeline timelineData={timelineData} />
    </div>
  );
};

export default ParkingSpotPageContent;
