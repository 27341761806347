import React from "react";
import Modal from "../../../../../artzu/src/Modal";
import styles from "./reviewChanges.module.scss";
import ButtonsGroup from "../../../../Tasks/ButtonsGroup";
import ButtonGroup from "../../../Shared/ButtonGroup";
import ChangesTable from "./ChangesTable";

const ReviewChanges = ({
  isOpen,
  toggleEditModalOpen,
  edits,
  modifyEdits,
  rowIdToIdentifierObject,
  deleteEdit,
  saveEdits
}) => {
  return (
    <Modal open={isOpen} padding="none" backgroundColor={"none"}>
      <div className={styles.reviewChanges}>
        <div className={styles.header}>
          <div className={styles.title}>Review Edits</div>
          <div className={styles.count}>
            Changes to {Object.keys(edits).length} vehicles
          </div>
        </div>
        <ChangesTable
          deleteEdit={deleteEdit}
          edits={edits}
          modifyEdits={modifyEdits}
          rowIdToIdentifierObject={rowIdToIdentifierObject}
        />
        <div className={styles.footer}>
          <ButtonGroup
            leftButtonText="Cancel"
            rightButtonText="Confirm"
            rightButtonFunction={saveEdits}
            leftButtonFunction={toggleEditModalOpen}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReviewChanges;
