import gql from "graphql-tag";
import { FileChecksum } from "@rails/activestorage/src/file_checksum";
import { BlobUpload } from "@rails/activestorage/src/blob_upload";

const queryString = gql`
  mutation createDirectUpload($input: CreateDirectUploadAttributes!) {
    createDirectUpload(input: $input) {
      directUpload {
        signedBlobId
        url
        headers
      }
    }
  }
`;

export const directUpload = (url, headers, file) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } });
  return new Promise((resolve, reject) => {
    upload.create(error => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

function calculateChecksum(file) {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
}

export const getFileMetadata = file => {
  return new Promise(resolve => {
    calculateChecksum(file).then(checksum => {
      resolve({
        checksum,
        filename: file.name,
        contentType: file.type,
        byteSize: file.size
      });
    });
  });
};

function directUploadFile(file, client) {
  return new Promise(resolve => {
    getFileMetadata(file).then(input => {
      console.log(input);
      return client
        .mutate({
          mutation: queryString,
          variables: {
            input: input
          }
        })
        .then(({ data: { createDirectUpload } }) => {
          console.log(createDirectUpload);
          const {
            directUpload: { url, headers, signedBlobId }
          } = createDirectUpload;
          return directUpload(url, JSON.parse(headers), file).then(response => {
            resolve(signedBlobId);
          });
        });
    });
  });
}

export { directUploadFile };
