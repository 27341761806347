import React from "react";
import { useState } from "react";
import { ADD_TASK_TAG, REMOVE_TASK_TAG } from "../../queries";
import { Fragment } from "react";
import { useMutation } from "@apollo/client";
import styles from "../../task.module.scss";
import TagsDropdownAdding from "./TagsDropdownAdding";
import TagList from "./TagList";

const TaskTags = ({ taskData, allTags, tagData, refreshTaskCallback }) => {
  const [newTag, setNewTag] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const taskTags = tagData && tagData.map((tag) => tag.name);
  let arrayToSort = [...allTags];
  const allTagList = arrayToSort.sort().map((tag) => {
    return {
      name: tag,
      value: tag,
    };
  });

  const [addMutation] = useMutation(ADD_TASK_TAG);
  const handleAddTag = () => {
    addMutation({
      variables: {
        taskId: taskData?.id,
        tagName: newTag,
      },
    }).then(({ data: { addTag } }) => {
      if (addTag.success) {
        setNewTag("");
        refreshTaskCallback(taskData?.id);
        console.log("add succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(addTag.error);
        console.log("add error!");
      }
    });
  };

  const [removeMutation] = useMutation(REMOVE_TASK_TAG);
  const handleRemoveTag = (tagToRemove) => {
    removeMutation({
      variables: {
        taskId: taskData?.id,
        tagName: tagToRemove,
      },
    }).then(({ data: { removeTag } }) => {
      if (removeTag.success) {
        refreshTaskCallback(taskData?.id);
        console.log("remove succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(removeTag.error);
        console.log("remove error!");
      }
    });
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const triggerTag = (childData) => {
    setNewTag(childData);
  };

  const isDuplicate = taskTags && taskTags.includes(newTag);

  const addable = newTag !== undefined && newTag !== "" && !isDuplicate;

  return (
    <Fragment>
      <div className={styles.tagContainer}>
        <div className={styles.tagHeader}>
          <h2>Tags</h2>
        </div>
        <div className={styles.subscript}>Add tag</div>
        <TagsDropdownAdding
          newTag={newTag}
          allTagList={allTagList}
          triggerTag={triggerTag}
          taskData={taskData}
          addable={addable}
          handleAddTag={handleAddTag}
        />
        <TagList tagData={tagData} handleRemoveTag={handleRemoveTag} />
      </div>
    </Fragment>
  );
};

export default TaskTags;
