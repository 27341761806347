import React, { Fragment } from "react";
import Button from "../../artzu/src/Button";

import "../Vehicle/vehicle.scss";
import "./damage.scss";

const ImageUpload = ({ updateDamage, uploaded }) => (
  <Fragment>
    <input
      id="image-upload"
      className="none"
      type="file"
      onChange={evt => {
        updateDamage("file", evt.target.files[0]);
        updateDamage("filePreview", URL.createObjectURL(evt.target.files[0]));
      }}
    />
    <Button
      className="view-buttons"
      onClick={() => {
        document.getElementById("image-upload").click();
      }}
      label={uploaded ? "Change image" : "Upload image"}
    />
  </Fragment>
);

export default ImageUpload;
