const validate = (args, setErrors) => {
  let errors = [];
  const { startTime, endTime, comments, endDateSelected } = args;
  if (!startTime) {
    errors.push("You need to enter a start time.");
  }
  if (endDateSelected && !endTime) {
    errors.push("You need to enter a end time.");
  }
  if (endDateSelected && endTime < startTime) {
    errors.push("End time must be greater than start time.");
  }
  if (!comments) {
    errors.push("You must enter a comment.");
  }

  setErrors(errors);
  if (errors.length > 0) {
    return false;
  }
  return true;
};

const getSaveButtonLabel = errors => {
  if (errors) {
    if (errors.errorType === "failed") {
      return null;
    } else if (errors.errorType === "warning") {
      return "Confirm";
    }
  } else {
    return "Save";
  }
};

export { validate, getSaveButtonLabel };
