import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_USER_INFO, GET_VEHICLE } from "../queries";
import styles from "../task.module.scss";
import RelatedVehicle from "./Vehicle/RelatedVehicle";
import TaskTags from "./Tag/TaskTags";
import TaskReminder from "./Reminder/TaskReminder";
import DueDate from "./DueDate";

const TaskRight = ({
  taskData,
  dueDate,
  dueDateCallback,
  refreshTaskCallback,
  vehicleIdCallback,
  isEdit,
  isCancel,
  currentAdmin,
  presetVehicleId,
}) => {
  const taskVehicle = taskData?.vehicle;
  const tagData = taskData?.tags;
  const taggedAdmins = taskData?.taggedUsers;
  const allTags = ["Test"];
  const [selectedVehicleId, setSelectedVehicleId] = useState();

  const chosenVehicle = (childData) => {
    setSelectedVehicleId(childData);
    vehicleIdCallback(childData);
  };

  const vehicleIdForQuery = presetVehicleId
    ? presetVehicleId
    : selectedVehicleId;

  const { data, loading } = useQuery(GET_VEHICLE, {
    variables: { params: { id: vehicleIdForQuery } },
  });

  const vehicleData = taskVehicle || data?.getVehicle;

  return (
    <div className={styles.rightContainer}>
      <DueDate
        taskData={taskData}
        dueDate={dueDate}
        isEdit={isEdit}
        isCancel={isCancel}
        dueDateCallback={dueDateCallback}
      />
      <RelatedVehicle
        vehicleData={vehicleData}
        taskData={taskData}
        vehicleIdCallback={vehicleIdCallback}
        refreshTaskCallback={refreshTaskCallback}
        chosenVehicle={chosenVehicle}
      />
      <TaskTags
        taskData={taskData}
        allTags={allTags}
        tagData={tagData}
        refreshTaskCallback={refreshTaskCallback}
      />
      <TaskReminder
        taskData={taskData}
        refreshTaskCallback={refreshTaskCallback}
        taggedAdmins={taggedAdmins}
        currentAdmin={currentAdmin}
      />
    </div>
  );
};

export default TaskRight;
