import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import styles from "../../task.module.scss";

const DamageReportMessage = ({ msg, vehicleId }) => {
  const damageId = msg.text.split(" ")[3];
  return (
    msg.label === "damage" && (
      <div className={styles.damageReportMessage}>
        <div className={styles.acInfo}>
          <div className={styles.acTitle}>
            <FontAwesomeIcon icon={faFile} size="2x" />
            Damage report {String(damageId).padStart(6, "0")}
          </div>
        </div>
        <a
          className={styles.buttonLink}
          href={`/vehicles/${vehicleId}/damages/${damageId}`}
          target="_blank"
        >
          <button className={styles.viewDamageReportButton}>View report</button>
        </a>
      </div>
    )
  );
};

export default DamageReportMessage;
