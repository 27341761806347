import React from "react";
import styles from "./options.module.scss";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ closeOptions }) => {
  return (
    <div className={styles.headerBox}>
      <div className={styles.header}>Column Options</div>
    </div>
  );
};

export default Header;
