import React, { useState } from "react";
import { ASSIGN_TASK, GET_ADMIN_NAME } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import Modal from "../../../artzu/src/Modal";
import styles from "../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "../../Shared/Dropdown";

const AssignAdminModal = ({
  taskData,
  open,
  toggleAdmin,
  adminIdCallback,
  directAssign,
  refreshTaskCallback,
  currentAdmin,
}) => {
  const [selectedAdminId, setSelectedAdminId] = useState(
    currentAdmin && currentAdmin.id
  );
  const [newAdmin, setNewAdmin] = useState(
    currentAdmin && currentAdmin.fullName
  );

  const { data, loading } = useQuery(GET_ADMIN_NAME);

  const allAdmins = data
    ? data.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          value: admin.firstName + " " + admin.lastName,
        };
      })
    : [];
  let sortedAllAdmins = allAdmins.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  useEffect(() => {
    data &&
      setSelectedAdminId(
        data.allAdminName.find(
          (item) => item.firstName + " " + item.lastName === newAdmin
        )
          ? data.allAdminName.find(
              (item) => item.firstName + " " + item.lastName === newAdmin
            ).id
          : null
      );
  }, [newAdmin]);

  const [assignTaskMutation, { loading: isAssignLoading }] = useMutation(
    ASSIGN_TASK
  );
  const assignTask = () => {
    assignTaskMutation({
      variables: {
        taskId: taskData?.id,
        adminUserId: selectedAdminId,
      },
    }).then(({ data: { assignTask } }) => {
      if (assignTask.task) {
        refreshTaskCallback({ taskId: assignTask.task.id });
        console.log("Assign Succeed!");
      } else {
        console.log("Assign error!");
      }
    });
  };

  const ableToApply = newAdmin;

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.relatedUserContainer}>
        <div className={styles.relateUserTitle}>Change agent</div>
        <div className="user-input">
          Assign to
          <div className={styles.searchAddContainer}>
            <Dropdown
              initialValue={newAdmin}
              items={sortedAllAdmins}
              onChange={triggerAdmin}
              width="412px"
              height="30px"
              promptColor="#dedede"
            />
          </div>
        </div>
        <div className={styles.relateUserButtons} style={{ marginTop: "36px" }}>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => {
                toggleAdmin();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                ableToApply ? styles.chooseButton : styles.chooseButtonInvalid
              }
              onClick={() => {
                ableToApply && adminIdCallback(selectedAdminId);
                ableToApply && directAssign && assignTask();
                ableToApply && toggleAdmin();
              }}
            >
              {isAssignLoading ? (
                <FontAwesomeIcon
                  style={{ color: "#128dff" }}
                  spin
                  size={"1x"}
                  icon={faSpinner}
                />
              ) : (
                "Apply"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignAdminModal;
