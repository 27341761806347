import React, { useState } from "react";
import DamageNotes from "./DamageNotes";

const CommentsBody = ({ id, damage, refetch }) => {
  const [newNote, setNewNote] = useState();
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(null);

  const notesOnChange = (text) => setNewNote(text);
  const toggleAddNote = () => setAddNote(!addNote);
  const editChange = (text) => setEditNote(text);
  return (
    <DamageNotes
      notes={damage?.notes}
      onChange={notesOnChange}
      newNote={newNote}
      addNote={addNote}
      closeNote={toggleAddNote}
      damageId={damage?.id}
      vehicleId={id}
      refetch={refetch}
      editChange={editChange}
      editedNote={editNote}
    />
  );
};

export default CommentsBody;
