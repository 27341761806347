import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import Vehicle from "../Components/Vehicle";
import VehicleNew from "../Components/VehicleNew";
import withAuth from "../Containers/withAuth";
import Loading from "../Containers/Loading";
import { useRouteMatch, useHistory } from "react-router";

import { vehicleString } from "./queryStrings";
import { Fragment } from "react";

const GetVehicle = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const vehicleId = match.params.id;
  const resourceId = match.params.resourceId && match.params.resourceId;
  const resource = match.params.resource && match.params.resource;

  return (
    <Query
      query={gql`
        ${vehicleString}
      `}
      variables={{
        vehicleId: vehicleId,
      }}
      pollInterval={600000}
    >
      {({ loading, error, data, refetch }) => {
        if (error) {
          return null;
        }
        if (loading) {
          return (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading size="5x" />
            </div>
          );
        }
        if (data.getVehicle) {
          return (
            <VehicleNew
              data={data}
              refetch={refetch}
              history={history}
              resource={resource}
              resourceId={resourceId}
            />
          );
        } else {
          return <p style={{ marginTop: "6em" }}>Not available</p>;
        }
      }}
    </Query>
  );
};

export default withAuth(GetVehicle);
