import React from "react";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ size = "4x" }) => (
  <div className="history-loading-frame">
    <div className="history-loading-div">
      <FontAwesomeIcon
        className="loading-icon"
        spin
        size={size}
        icon={faSpinner}
      />
    </div>
  </div>
);

export default Loading;
