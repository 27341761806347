import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Dropdown from "../../../Shared/Dropdown";
import styles from "../../batchActions.module.scss";
import { BULK_ASSIGN_ADMIN } from "../../queries";

const AssignAgent = ({
  selections,
  close,
  clearAllSelections,
  adminData,
  reAssign,
}) => {
  const [newAdmin, setNewAdmin] = useState();
  const [comment, setComment] = useState();

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  const changeComment = (e) => {
    setComment(e.target.value);
  };

  const allAdmins = adminData
    ? adminData.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          id: admin.firstName + " " + admin.lastName,
        };
      })
    : [];

  const [assignAgentMutation] = useMutation(BULK_ASSIGN_ADMIN);
  const handleAssignAgent = () => {
    assignAgentMutation({
      variables: {
        taskIds: selections,
        adminUserId: adminData.allAdminName.find(
          (item) => item.firstName + " " + item.lastName === newAdmin
        ).id,
        comment: comment ? comment : null,
      },
    }).then(({ data: { assignToAdmin } }) => {
      if (assignToAdmin.success) {
        clearAllSelections();
        close();
        console.log("Assign to Agent Succeed!");
      } else {
        console.log("Assign to Agent error!");
      }
    });
  };

  const applyable = newAdmin !== undefined && newAdmin !== null;

  return (
    <Fragment>
      <div className={styles.actionHeader}>Assign to</div>
      <span className={styles.subscript}>Choose who to assign to</span>
      <div>
        <div className={styles.searchAddContainer}>
          <Dropdown
            initialValue={newAdmin}
            items={allAdmins}
            onChange={triggerAdmin}
            width="436px"
            height="30px"
          />
        </div>
      </div>
      <span className={styles.subscript} style={{ marginTop: 24 }}>
        Comment
      </span>
      <textarea
        className={styles.taskTextBox}
        style={{ height: 102 }}
        onChange={changeComment}
      ></textarea>
      <div className={styles.taskModalButtons} style={{ marginTop: "300px" }}>
        <div>
          <button
            className={styles.taskModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.taskModalApplyButton}
            style={{ color: applyable ? "#128dff" : "#87caef" }}
            onClick={() => {
              applyable && handleAssignAgent();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AssignAgent;
