const getText = (text) => {
  switch (text) {
    case "occupied_by_autzu":
      return "Autzu";
    case "occupied_by_driver":
      return "Driver";
    case "available":
      return "Available";
    case "unauthorized":
      return "Unauthorized";
    default:
      break;
  }
};
const getColor = (text) => {
  switch (text) {
    case "available":
      return "#f2f2f2";
    case "occupied_by_autzu":
      return "#128dff";
    case "occupied_by_driver":
      return "orange";
    case "unauthorized":
      return "#ff0000";
    default:
      break;
  }
};

const getBackColor = (text) => {
  switch (text) {
    case "available":
      return "#414141";
    case "occupied_by_autzu":
      return "rgba(18, 140, 255, 0.3)";
    case "occupied_by_driver":
      return "#ffe2c1";
    case "unauthorized":
      return "#ffbaba";
    default:
      break;
  }
};

const getTimeInCorrectUnit = (time) => {
  if (!time && time !== 0) {
    return;
  } else if (time > 24) {
    if (Math.round(time / 24) === 1) {
      return "1 day";
    } else {
      return `${Math.round(time / 24)} days`;
    }
  } else if (time === 1) {
    return "1 hour";
  } else if (time === 0) {
    return "less than 1 hour";
  } else {
    return `${time} hours`;
  }
};

const getChargerText = (text) => {
  switch (text) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      break;
  }
};
const getChargerColor = (text) => {
  switch (text) {
    case true:
      return "#3dc700";
    case false:
      return "#414141";
    default:
      break;
  }
};

const getChargerBackColor = (text) => {
  switch (text) {
    case true:
      return "#bcff9f";
    case false:
      return "#dedede";
    default:
      break;
  }
};

export {
  getText,
  getColor,
  getBackColor,
  getTimeInCorrectUnit,
  getChargerText,
  getChargerColor,
  getChargerBackColor,
};
