import React, { useState } from "react";
import styles from "./parkingSpot.module.scss";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../artzu/src/Button";

const TimelineDateFilter = ({ setCurStartDate, setCurEndDate }) => {
  const [curStart, setCurStart] = useState();
  const [curEnd, setCurEnd] = useState();

  const parentsDateCallback = () => {
    setCurStartDate(curStart);
    setCurEndDate(curEnd);
  };

  const applyable = curStart && curEnd;
  return (
    <div className={styles.dateFilterContainer}>
      <div className={styles.timelineTitle}>Timeline</div>
      <div className={styles.datePickerBox} style={{ marginLeft: "32px" }}>
        <div>Start</div>
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={curStart}
            onChange={(e) => setCurStart(e)}
            dateFormat="MMM DD, YYYY h:mm A"
            todayButton={"Today"}
            placeholderText="Select date"
            popperPlacement="bottom"
            className={styles.dateInput}
            showYearDropdown
            scrollableYearDropdown
            showMonthDropdown
            showTimeSelect
            timeFormat="H:mm"
          />
          <FontAwesomeIcon
            icon={faCalendar}
            className={styles.pickDateCalendar}
            size="lg"
          />
        </div>
      </div>
      <div className={styles.datePickerBox}>
        <div>End</div>
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={curEnd}
            onChange={(e) => setCurEnd(e)}
            dateFormat="MMM DD, YYYY h:mm A"
            todayButton={"Today"}
            placeholderText="Select date"
            popperPlacement="bottom"
            className={styles.dateInput}
            showYearDropdown
            scrollableYearDropdown
            showMonthDropdown
            showTimeSelect
            timeFormat="H:mm"
          />
          <FontAwesomeIcon
            icon={faCalendar}
            className={styles.pickDateCalendar}
            size="lg"
          />
        </div>
      </div>
      <Button
        label={"Apply"}
        height="32px"
        fullWidth="80px"
        color="#ffffff"
        backColor={applyable ? "#128dff" : "#87CEFA"}
        radius="20px"
        size="16px"
        weight="700"
        border="1px solid #FFFFFF"
        onClick={() => {
          applyable && parentsDateCallback();
        }}
        style={{ alignSelf: "end" }}
      />
    </div>
  );
};

export default TimelineDateFilter;
