import React, { useState } from "react";
import { Fragment } from "react";
import PlateStatus from "../../../Fleet/PlateStatus";
import VehicleStatus from "./VehicleStatus";
import classNames from "classnames";
import "../../vehicle.scss";
import Button from "../../../../artzu/src/Button";
import { faAlarmClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoCard from "./InfoCard";
import _ from "lodash";
import ActionModal from "../../../InformationTable/BatchActions/ActionModal";
import ChargingStatus from "./ChargingStatus";

const StatusDisplayLeft = ({
  vehicle,
  operationStatus,
  toggleRefresh,
  sentryModeStatus,
}) => {
  const [isCard, setIsCard] = useState();
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [action, setAction] = useState();
  const scheduledChanges = vehicle.scheduledStatusChanges;
  const isScheduled = scheduledChanges && scheduledChanges.length !== 0;
  const { lockStatus, operationSubStatus } = vehicle.statuses;

  const toggleCard = () => {
    setIsCard(!isCard);
  };
  const close = () => {
    setIsCard(false);
  };
  const toggleChangeStatus = () => {
    setChangeStatusOpen(true);
    setAction("changeStatus");
  };

  const closeChangeStatus = () => {
    setAction(null);
  };

  const icons = [
    {
      value: "changeStatus",
      text: "Change Status",
    },
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  let vehicleList = [];
  vehicleList.push(vehicle);

  return (
    <Fragment>
      <div className="status-left">
        <div className="statusBox">
          <div className="operationTitleContainer">
            <div className="operationTitle">Operation Status:</div>
            {isScheduled && (
              <div className="opSchedule">
                <Button
                  label={
                    <div className="scheduleButton">
                      <FontAwesomeIcon
                        icon={faAlarmClock}
                        style={{ marginLeft: "3", fontSize: "9px" }}
                      />{" "}
                      <div className="scheduled">Scheduled</div>
                    </div>
                  }
                  height="15px"
                  fullWidth="63px"
                  color="#128dff"
                  backColor="#ffffff"
                  radius="8px"
                  size="10px"
                  border="1px solid #128dff"
                  padding="0"
                  onClick={toggleCard}
                />
              </div>
            )}
          </div>
          <PlateStatus operationStatus={operationStatus} />
        </div>

        <div className="statusBox">
          <div className="operationTitle">Operation sub status:</div>
          <ChargingStatus status={operationSubStatus} />
        </div>
        <div className="statusBox">
          <div className="operationTitle">Lock status:</div>
          <VehicleStatus status={lockStatus} />
        </div>
        <div className="statusBox">
          <div className="operationTitle">Sentry Mode:</div>
          <VehicleStatus status={sentryModeStatus} />
        </div>
      </div>
      {isCard && isScheduled && (
        <InfoCard
          open={isCard}
          close={close}
          vehicle={vehicle}
          toggleChangeStatus={toggleChangeStatus}
          toggleRefresh={toggleRefresh}
        />
      )}
      {changeStatusOpen && (
        <ActionModal
          isOpen={action}
          selectedBatchAction={action}
          batchOptionsHash={batchOptionsHash}
          selections={vehicleList}
          closeModal={closeChangeStatus}
          toggleRefresh={toggleRefresh}
        />
      )}
    </Fragment>
  );
};

export default StatusDisplayLeft;
