import React, { useState } from "react";
import styles from "../damages.module.scss";
import Modal from "../../../../artzu/src/Modal";
import DropdownInput from "../CreateDamage/DropdownInput";
import { getDropdownOptions, getStatusDropdownOptions } from "../utils";
import SmallTextInput from "./SmallTextInput";
import { CHANGE_DAMAGE_STATUS } from "../queries";
import { useMutation } from "@apollo/client";

const ChangeStatusModal = ({ open, toggleEdit, damageIds, refetch }) => {
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [severity, setSeverity] = useState();
  const [estimatedCost, setEstimatedCost] = useState();
  const [vendor, setVendor] = useState();
  const [repairCost, setRepairCost] = useState();

  const triggerStatus = (child) => {
    setStatus(child);
  };

  const triggerType = (child) => {
    setType(child);
  };

  const triggerSeverity = (child) => {
    setSeverity(child);
  };

  const triggerEstimatedCost = (e) => {
    setEstimatedCost(e.target.value);
  };

  const triggerVendor = (e) => {
    setVendor(e.target.value);
  };

  const triggerRepairCost = (e) => {
    setRepairCost(e.target.value);
  };
  console.log(status);

  const [changeStatusMutation] = useMutation(CHANGE_DAMAGE_STATUS);
  const handleChangeStatus = () => {
    changeStatusMutation({
      variables: {
        damageIds: damageIds,
        status: status,
        type: type || null,
        severity: severity || null,
        estimate: estimatedCost || null,
        vendor: vendor || null,
        repairCost: repairCost || null,
      },
    }).then(({ data: { changeDamageStatus } }) => {
      if (changeDamageStatus.success) {
        clearInput();
        toggleEdit();
        window.location.reload();
        refetch();
        console.log("change status succeed!");
      } else {
        console.log("change status error!");
      }
    });
  };

  const clearInput = () => {
    setStatus(undefined);
    setType(undefined);
    setSeverity(undefined);
    setEstimatedCost(undefined);
    setVendor(undefined);
    setRepairCost(undefined);
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.changeStatusContainer}>
        <div className={styles.title} style={{ paddingTop: 0 }}>
          Change status
        </div>
        <DropdownInput
          text={"Status"}
          value={status}
          options={getDropdownOptions("status")}
          triggerFieldChange={triggerStatus}
        />
        {status === "confirmed" && (
          <>
            <DropdownInput
              text={"Type"}
              value={type}
              options={getDropdownOptions("type")}
              triggerFieldChange={triggerType}
            />
            <DropdownInput
              text={"Severity"}
              value={severity}
              options={getDropdownOptions("severity")}
              triggerFieldChange={triggerSeverity}
            />
          </>
        )}
        {status === "in_repair" && (
          <>
            <SmallTextInput
              text={"Estimated cost"}
              triggerFieldChange={triggerEstimatedCost}
            />
            <SmallTextInput
              text={"Vendor"}
              triggerFieldChange={triggerVendor}
            />
          </>
        )}
        {status === "resolved" && (
          <>
            <SmallTextInput
              text={"Repair cost"}
              triggerFieldChange={triggerRepairCost}
            />
            <SmallTextInput
              text={"Vendor"}
              triggerFieldChange={triggerVendor}
            />
          </>
        )}
        <div className={styles.buttons} style={{ marginTop: "24px" }}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              clearInput();
              toggleEdit();
            }}
          >
            Cancel
          </button>
          <button
            className={styles.applyButton}
            onClick={handleChangeStatus}
            style={{ width: status === "in_repair" ? "126px" : "86px" }}
          >
            {status === "in_repair" ? "Create task" : "Apply"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeStatusModal;
