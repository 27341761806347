import moment from "moment-timezone";
import gql from "graphql-tag";
import { historyGpsString, parkingLotsString } from "../Queries/queryStrings";

const getTimeRange = (mode, timeInput, diff) => {
  return mode === "single"
    ? {
        startTime: moment(timeInput.startTime).subtract(diff, "hours"),
        endTime: moment(timeInput.startTime).add(diff, "hours")
      }
    : {
        startTime: moment(timeInput.startTime),
        endTime: moment(timeInput.endTime)
      };
};

export async function historyQuery(store, timeFrame, client) {
  const timeRange = getTimeRange(store.history.mode, timeFrame, 0.5);

  const { data } = await client.query({
    query: gql`
      ${historyGpsString}
    `,
    variables: {
      timeRange: timeRange,
      plate: store.selectedVehicle.plate
    }
  });

  return data;
}

export async function parkingLotsQuery(client) {
  const { data } = await client.query({
    query: gql`
      ${parkingLotsString}
    `
  });

  return data;
}
