import React, { useState } from "react";
import DetailsBody from "./DetailsBody";
import TasksBody from "./TasksBody";
import CommentsBody from "./CommentsBody";
import styles from "../damages.module.scss";
import classNames from "classnames";
import { GET_DAMAGE } from "../queries";
import { useQuery } from "@apollo/client";

const DamagePage = ({ vehicleId, damageId }) => {
  const [activeTab, setActiveTab] = useState("details");
  const { data, loading, refetch } = useQuery(GET_DAMAGE, {
    variables: {
      damageId: damageId,
    },
  });
  const damage = data?.getDamage;
  console.log(damage);
  const pageContent = () => {
    switch (activeTab) {
      case "details":
        return <DetailsBody id={vehicleId} damage={damage} refetch={refetch} />;
      case "tasks":
        return (
          <TasksBody id={vehicleId} damageId={damageId} refetch={refetch} />
        );
      case "comments":
        return (
          <CommentsBody id={vehicleId} damage={damage} refetch={refetch} />
        );
    }
  };
  return (
    <div className={styles.damagePage}>
      <div className={styles.tabContainer}>
        <div
          className={classNames(
            styles.unselectable,
            styles.detailTab,
            activeTab === "details" ? styles.selected : null
          )}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          className={classNames(
            styles.unselectable,
            styles.detailTab,
            activeTab === "tasks" ? styles.selected : null
          )}
          onClick={() => setActiveTab("tasks")}
        >
          Tasks
        </div>
        <div
          className={classNames(
            styles.unselectable,
            styles.detailTab,
            activeTab === "comments" ? styles.selected : null
          )}
          style={{ width: "34%" }}
          onClick={() => setActiveTab("comments")}
        >
          Comments
        </div>
      </div>
      {pageContent()}
    </div>
  );
};

export default DamagePage;
