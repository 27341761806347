import React from "react";
import styles from "./vehicles_available.module.scss";

const TimeSlots = ({ times, timeZone }) => {
  return (
    <div className={styles.timesContainer}>
      <div className={styles.timeZone}>
        {timeZone}
      </div>
      {times.map((ts, i) => (
        <div className={styles.timeSlot}>
          <div className={styles.smallFont}>{times[i]}</div>
        </div>
      ))}
    </div>
  );
};

export default TimeSlots;
