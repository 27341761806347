import React from "react";
import styles from "./statusInfo.module.scss";
import { TableCell } from "../../../../artzu/src/Table";
const User = ({ userName, userImageLink, userLink }) => (
  <TableCell width="100%">
    {userImageLink ? (
      <div className={styles.userContainer}>
        <div className={styles.userImageContainer}>
          <img className={styles.userImage} src={userImageLink} />
        </div>
        {userLink ? (
          <a className={styles.userNameNav} href={userLink} target="_blank">
            {userName}
          </a>
        ) : (
          <div className={styles.userName}>{userName}</div>
        )}
      </div>
    ) : null}
  </TableCell>
);

export default User;
