import React from "react";
import Modal from "../../artzu/src/Modal";
import moment from "moment";
import styles from "./vehicles_available.module.scss";

const CellInfoModal = ({
  open,
  chosenTime,
  chosenDay,
  chosenCell,
  closeCell,
}) => {
  const cellData = chosenDay && chosenDay.availability[chosenCell];
  // const cellIn = chosenDay && chosenDay.inOutCount[chosenCell].comingInCount;
  // const cellOut = chosenDay && chosenDay.inOutCount[chosenCell].goingOutCount;
  const cellAvailability = `${cellData &&
    cellData.availableCount} / ${cellData && cellData.totalOnlineCount}`;
  const cellUtilization = `${cellData && cellData.utilization * 100}%`;
  const cellInService = `${
    cellData && cellData.serviceCount ? cellData && cellData.serviceCount : 0
  }`;

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.cellInfoContainer}>
        <div className={styles.infocardTitle}>
          {chosenTime} {moment(chosenDay && chosenDay.date).format("MMM DD, Y")}
        </div>
        <div className={styles.infocardField}>
          <span className={styles.infocardText}>Availability</span>
          <span className={styles.boldText}>{cellAvailability}</span>
        </div>
        <div className={styles.infocardField}>
          <span className={styles.infocardText}>Utilization</span>
          <span className={styles.boldText}>{cellUtilization}</span>
        </div>
        {/* <div className={styles.infocardField}>
          <span style={{ fontSize: "14px", marginBottom: "4px" }}>In</span>
          <span className={styles.boldText}>{cellIn}</span>
        </div>
        <div className={styles.infocardField}>
          <span style={{ fontSize: "14px", marginBottom: "4px" }}>Out</span>
          <span className={styles.boldText}>{cellOut}</span>
        </div> */}
        <div className={styles.infocardField}>
          <span className={styles.infocardText}>In service</span>
          <span className={styles.boldText}>{cellInService}</span>
        </div>
        <button className={styles.cellButton} onClick={closeCell}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default CellInfoModal;
