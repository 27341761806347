import React from "react";
import styles from "../damages.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { titleize } from "../../../../utils";
import { getSeverityBackColor, getSeverityColor } from "../utils";
import _ from "lodash";
import { useLocation } from "react-router";

const GalleryRight = ({
  curDamages,
  curDamageIdx,
  toggleGallery,
  triggerLocation,
}) => {
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className={styles.galleryRight}>
      <div className={styles.rightUpper}>
        <div className={styles.infoTitle}>
          <div className={styles.title}>
            {curDamages && !_.isEmpty(curDamages)
              ? String(curDamages[curDamageIdx]?.id).padStart(6, "0")
              : "No damages"}
          </div>
          <FontAwesomeIcon
            className={styles.timesIcon}
            icon={faTimes}
            onClick={() => {
              toggleGallery();
              triggerLocation("all");
            }}
            size="1x"
          />
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Status</div>
          <div>{titleize(curDamages && curDamages[curDamageIdx]?.status)}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Location</div>
          <div>
            {titleize(curDamages && curDamages[curDamageIdx]?.location)}
          </div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Type</div>
          <div>
            {_.capitalize(curDamages && curDamages[curDamageIdx]?.type)}
          </div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Severity</div>
          <div
            className={styles.tag}
            style={{
              color: `${getSeverityColor(
                curDamages && curDamages[curDamageIdx]?.severity
              )}`,
              backgroundColor: `${getSeverityBackColor(
                curDamages && curDamages[curDamageIdx]?.severity
              )}`,
            }}
          >
            {_.capitalize(curDamages && curDamages[curDamageIdx]?.severity)}
          </div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Description</div>
          <div>{curDamages && curDamages[curDamageIdx]?.description}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Tags</div>
          <div className={styles.landscape} style={{ gap: "6px" }}>
            {curDamages &&
              curDamages[curDamageIdx]?.damageTags &&
              curDamages &&
              curDamages[curDamageIdx]?.damageTags.map((tag) => (
                <div
                  className={styles.tag}
                  style={{
                    backgroundColor: "#dedede",
                  }}
                >
                  {tag.name}
                </div>
              ))}
          </div>
        </div>
      </div>

      {!_.isEmpty(curDamages) && (
        <a
          className={styles.reportButton}
          href={`${pathName}/${curDamages && curDamages[curDamageIdx]?.id}`}
          target="_blank"
        >
          Go to report
        </a>
      )}
    </div>
  );
};

export default GalleryRight;
