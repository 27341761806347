import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { Redirect } from "react-router-dom";

const withAuth = ComponentToRender =>
  class WithAuth extends Component {
    render() {
      return (
        <Query
          query={gql`
            {
              currentUser {
                id
                email
                organization
                roles {
                  type
                  fleet {
                    id
                    vehicleIds
                  }
                }
                parkingLots {
                  id
                }
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (loading || error) return null;
            return data.currentUser ? (
              <ComponentToRender
                {...this.props}
                roles={data.currentUser.roles}
                organizationId={data.currentUser.organization}
              />
            ) : (
              <Redirect to="/login" />
            );
          }}
        </Query>
      );
    }
  };

export default withAuth;
