import React from "react";
import styles from "./loading.module.scss";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ size, color }) => (
  <div className={styles.loading}>
    <FontAwesomeIcon
      color={color || "#128dff"}
      spin
      size={size || "2x"}
      icon={faSpinner}
    />
  </div>
);

export default Loading;
