import React from "react";
import "./fleet.css";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ size = "2x" }) => (
	<div className="loading-div">
		<FontAwesomeIcon className="blue" spin size={size} icon={faSpinner} />
	</div>
);

export default Loading;
