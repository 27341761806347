import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const topRoof = [
	0.3638,
	0.6097,
	0.3944,
	0.6079,
	0.426,
	0.6062,
	0.4588,
	0.6079,
	0.4825,
	0.6079,
	0.504,
	0.6079,
	0.5175,
	0.6097,
	0.5345,
	0.6115,
	0.5412,
	0.6115,
	0.5469,
	0.5795,
	0.5492,
	0.5404,
	0.5503,
	0.512,
	0.5503,
	0.4764,
	0.548,
	0.4337,
	0.5446,
	0.4142,
	0.5412,
	0.3946,
	0.5232,
	0.3982,
	0.5017,
	0.4,
	0.4723,
	0.4,
	0.4418,
	0.4,
	0.4124,
	0.4,
	0.3887,
	0.3982,
	0.3638,
	0.3964,
	0.3593,
	0.4231,
	0.3559,
	0.4586,
	0.3548,
	0.4871,
	0.3548,
	0.5208,
	0.3559,
	0.5564,
	0.3605,
	0.5831
];

const topBonnet = [
	0.6045,
	0.6435,
	0.6,
	0.6524,
	0.609,
	0.6559,
	0.6136,
	0.6577,
	0.6158,
	0.6613,
	0.6237,
	0.6719,
	0.7333,
	0.6719,
	0.7492,
	0.6702,
	0.7605,
	0.6648,
	0.7718,
	0.6577,
	0.7763,
	0.6542,
	0.7842,
	0.6346,
	0.7921,
	0.6151,
	0.7966,
	0.5955,
	0.7977,
	0.5884,
	0.7977,
	0.5742,
	0.8,
	0.5671,
	0.8023,
	0.5457,
	0.8023,
	0.5191,
	0.8023,
	0.4977,
	0.8023,
	0.4728,
	0.8011,
	0.4586,
	0.8,
	0.4426,
	0.7977,
	0.4355,
	0.7977,
	0.4213,
	0.7955,
	0.4089,
	0.791,
	0.3893,
	0.7819,
	0.3662,
	0.774,
	0.352,
	0.7695,
	0.3466,
	0.7571,
	0.3395,
	0.7458,
	0.3342,
	0.739,
	0.3342,
	0.6237,
	0.336,
	0.6169,
	0.3449,
	0.6136,
	0.3466,
	0.6068,
	0.3502,
	0.6034,
	0.3537,
	0.5989,
	0.3555,
	0.6056,
	0.3626,
	0.6113,
	0.3626,
	0.6215,
	0.3662,
	0.6271,
	0.3697,
	0.6328,
	0.3946,
	0.6384,
	0.4124,
	0.6441,
	0.4408,
	0.6463,
	0.4728,
	0.6475,
	0.5048,
	0.6463,
	0.5368,
	0.6418,
	0.5724,
	0.635,
	0.6026,
	0.6294,
	0.6222,
	0.6226,
	0.6328
];

const frontWindshield = [
	0.5401,
	0.6133,
	0.5401,
	0.6186,
	0.5446,
	0.6222,
	0.5559,
	0.6275,
	0.5729,
	0.6328,
	0.5898,
	0.6382,
	0.6011,
	0.6435,
	0.6079,
	0.6435,
	0.6192,
	0.6382,
	0.6249,
	0.6364,
	0.6362,
	0.6044,
	0.6441,
	0.5706,
	0.6475,
	0.5368,
	0.6486,
	0.496,
	0.6463,
	0.4604,
	0.6429,
	0.432,
	0.6362,
	0.4017,
	0.6282,
	0.3786,
	0.6249,
	0.3715,
	0.6079,
	0.3626,
	0.6,
	0.3626,
	0.5842,
	0.368,
	0.5672,
	0.3751,
	0.5503,
	0.3804,
	0.5446,
	0.3822,
	0.5401,
	0.3875,
	0.5446,
	0.4035,
	0.5469,
	0.4177,
	0.548,
	0.4391,
	0.5492,
	0.4604,
	0.5503,
	0.4835,
	0.5503,
	0.5066,
	0.5503,
	0.5244,
	0.5492,
	0.5475,
	0.548,
	0.5724
];

const rearWindshield = [
	0.365,
	0.6133,
	0.3537,
	0.6151,
	0.3412,
	0.6186,
	0.3277,
	0.6222,
	0.3186,
	0.6239,
	0.3062,
	0.6239,
	0.2972,
	0.6239,
	0.2938,
	0.6239,
	0.2881,
	0.6151,
	0.2836,
	0.5973,
	0.2791,
	0.5777,
	0.2757,
	0.5475,
	0.2757,
	0.5102,
	0.2757,
	0.4782,
	0.278,
	0.4515,
	0.2814,
	0.4248,
	0.2847,
	0.4035,
	0.2881,
	0.3911,
	0.2927,
	0.3822,
	0.2983,
	0.3804,
	0.3073,
	0.3804,
	0.3232,
	0.3822,
	0.3379,
	0.3857,
	0.3514,
	0.3893,
	0.3616,
	0.3911,
	0.365,
	0.3929,
	0.3616,
	0.4106,
	0.3571,
	0.4373,
	0.3548,
	0.4764,
	0.3559,
	0.5208,
	0.3571,
	0.5493,
	0.3605,
	0.5866
];

const topTrunk = [
	0.2881,
	0.3864,
	0.283,
	0.3767,
	0.2778,
	0.3734,
	0.2664,
	0.3734,
	0.256,
	0.375,
	0.2477,
	0.3767,
	0.2394,
	0.3816,
	0.2446,
	0.3718,
	0.2508,
	0.3636,
	0.2602,
	0.3555,
	0.2653,
	0.3489,
	0.256,
	0.3489,
	0.2508,
	0.3506,
	0.2436,
	0.3506,
	0.2394,
	0.3538,
	0.2342,
	0.362,
	0.2291,
	0.3718,
	0.2239,
	0.3864,
	0.2218,
	0.3995,
	0.2197,
	0.4223,
	0.2187,
	0.4517,
	0.2177,
	0.4924,
	0.2177,
	0.5299,
	0.2187,
	0.5626,
	0.2208,
	0.5903,
	0.2228,
	0.6066,
	0.226,
	0.6196,
	0.2291,
	0.631,
	0.2332,
	0.6392,
	0.2384,
	0.6473,
	0.2415,
	0.6506,
	0.2508,
	0.6522,
	0.2664,
	0.6522,
	0.2591,
	0.6457,
	0.2529,
	0.6376,
	0.2477,
	0.6327,
	0.2446,
	0.6261,
	0.2415,
	0.6213,
	0.2467,
	0.6213,
	0.2602,
	0.6229,
	0.2705,
	0.6229,
	0.2757,
	0.6213,
	0.283,
	0.618,
	0.2881,
	0.6147,
	0.2819,
	0.5886,
	0.2778,
	0.5609,
	0.2747,
	0.5283,
	0.2747,
	0.4859,
	0.2757,
	0.4533,
	0.2809,
	0.4174,
	0.283,
	0.3995
];

const topPassengerSideMirror = [
	0.5732,
	0.6506,
	0.5638,
	0.6946,
	0.5649,
	0.6963,
	0.569,
	0.6963,
	0.5742,
	0.693,
	0.5784,
	0.6881,
	0.5825,
	0.6783,
	0.5846,
	0.6685,
	0.5856,
	0.662,
	0.5867,
	0.6571,
	0.5918,
	0.6539,
	0.5981,
	0.6539,
	0.6063,
	0.6539,
	0.597,
	0.649,
	0.5856,
	0.6441,
	0.5752,
	0.6408,
	0.5701,
	0.6392,
	0.5742,
	0.6473
];

const topDriverSideMirror = getMirrorImage(topPassengerSideMirror);

const topDriverFrontWindow = [
	0.4871,
	0.3506,
	0.4737,
	0.3816,
	0.5027,
	0.3832,
	0.5203,
	0.3799,
	0.5359,
	0.3767,
	0.5493,
	0.3718,
	0.5638,
	0.3669,
	0.5711,
	0.3604,
	0.5742,
	0.3506
];

const topPassengerFrontWindow = getMirrorImage(topDriverFrontWindow);

const topDriverRearWindow = [
	0.4581,
	0.3816,
	0.4654,
	0.3506,
	0.3493,
	0.3555,
	0.3441,
	0.3604,
	0.3431,
	0.3636,
	0.3451,
	0.3669,
	0.3825,
	0.3767,
	0.4094,
	0.3799,
	0.4436,
	0.3816
];

const topPassengerRearWindow = getMirrorImage(topDriverRearWindow);

const topDriverRearQuarter = [
	0.2892,
	0.3864,
	0.2933,
	0.3816,
	0.2995,
	0.3783,
	0.3099,
	0.3783,
	0.3244,
	0.3799,
	0.3441,
	0.3864,
	0.3586,
	0.3897,
	0.3783,
	0.3913,
	0.4001,
	0.393,
	0.4198,
	0.3946,
	0.4415,
	0.3946,
	0.4592,
	0.3946,
	0.4768,
	0.3946,
	0.4913,
	0.3946,
	0.5037,
	0.393,
	0.5172,
	0.3913,
	0.5307,
	0.3897,
	0.5379,
	0.3881,
	0.541,
	0.3832,
	0.5462,
	0.3799,
	0.5638,
	0.3718,
	0.5784,
	0.3685,
	0.5877,
	0.3653,
	0.597,
	0.3636,
	0.6001,
	0.362,
	0.6012,
	0.3522,
	0.5856,
	0.3555,
	0.5752,
	0.3604,
	0.5628,
	0.3636,
	0.5514,
	0.3685,
	0.5379,
	0.375,
	0.5307,
	0.3767,
	0.51,
	0.3799,
	0.4809,
	0.3816,
	0.4571,
	0.3816,
	0.4208,
	0.3816,
	0.3959,
	0.3783,
	0.3731,
	0.3767,
	0.3534,
	0.3701,
	0.342,
	0.3685,
	0.3389,
	0.3653,
	0.3369,
	0.362,
	0.341,
	0.3538,
	0.3441,
	0.3522,
	0.3503,
	0.3489,
	0.3576,
	0.3261,
	0.342,
	0.3261,
	0.3275,
	0.3261,
	0.312,
	0.3277,
	0.2995,
	0.3277,
	0.283,
	0.3326,
	0.2643,
	0.3408,
	0.2539,
	0.3457,
	0.2436,
	0.3506,
	0.2653,
	0.3457,
	0.2674,
	0.3506,
	0.2622,
	0.3538,
	0.255,
	0.3587,
	0.2467,
	0.3685,
	0.2394,
	0.3816,
	0.2695,
	0.3783,
	0.2788,
	0.3783
];

const topPassengerRearQuarter = getMirrorImage(topDriverRearQuarter);

const topDriverFrontDoor = [
	0.4664,
	0.3832,
	0.4861,
	0.3832,
	0.5048,
	0.3816,
	0.5193,
	0.3799,
	0.5338,
	0.3767,
	0.5514,
	0.3718,
	0.568,
	0.3653,
	0.5856,
	0.3587,
	0.596,
	0.3522,
	0.6095,
	0.3457,
	0.6157,
	0.3424,
	0.6209,
	0.3326,
	0.5182,
	0.3294,
	0.5151,
	0.3277,
	0.4913,
	0.3261,
	0.4871,
	0.3294,
	0.482,
	0.3294
];

const topPassengerFrontDoor = getMirrorImage(topDriverFrontDoor);

const topDriverRearDoor = [
	0.4654,
	0.3832,
	0.4343,
	0.3816,
	0.4042,
	0.3799,
	0.3835,
	0.3767,
	0.369,
	0.375,
	0.3514,
	0.3718,
	0.341,
	0.3669,
	0.3369,
	0.3636,
	0.3379,
	0.3587,
	0.3431,
	0.3522,
	0.3503,
	0.3457,
	0.3586,
	0.3359,
	0.3617,
	0.3245,
	0.3804,
	0.3261,
	0.3856,
	0.3277,
	0.4333,
	0.3277,
	0.4571,
	0.3277,
	0.484,
	0.3294
];

const topPassengerRearDoor = getMirrorImage(topDriverRearDoor);

const topFrontWipers = [
	0.6022,
	0.6392,
	0.6095,
	0.6392,
	0.6167,
	0.6376,
	0.624,
	0.6327,
	0.6374,
	0.5935,
	0.6437,
	0.5528,
	0.6457,
	0.525,
	0.6457,
	0.4712,
	0.6426,
	0.4419,
	0.6374,
	0.4125,
	0.6302,
	0.3864,
	0.624,
	0.3701,
	0.6219,
	0.3685,
	0.6105,
	0.3636,
	0.6043,
	0.362,
	0.5991,
	0.3636,
	0.6063,
	0.3734,
	0.6146,
	0.3979,
	0.6198,
	0.4174,
	0.6219,
	0.4337,
	0.625,
	0.4566,
	0.626,
	0.4827,
	0.626,
	0.5006,
	0.625,
	0.5299,
	0.6229,
	0.5626,
	0.6188,
	0.587,
	0.6136,
	0.6082
];

const topRearWipers = [
	0.2954,
	0.6229,
	0.2975,
	0.6147,
	0.2944,
	0.6115,
	0.2892,
	0.6017,
	0.285,
	0.5805,
	0.2819,
	0.5593,
	0.2799,
	0.5332,
	0.2788,
	0.5071,
	0.2788,
	0.4859,
	0.2799,
	0.468,
	0.2809,
	0.4468,
	0.284,
	0.4256,
	0.2871,
	0.406,
	0.2913,
	0.3946,
	0.2944,
	0.3897,
	0.2964,
	0.3832,
	0.2964,
	0.3799,
	0.2933,
	0.3816,
	0.2861,
	0.3897,
	0.2809,
	0.4076,
	0.2778,
	0.4288,
	0.2757,
	0.45,
	0.2736,
	0.4745,
	0.2736,
	0.5039,
	0.2736,
	0.5202,
	0.2747,
	0.5462,
	0.2767,
	0.5626,
	0.2788,
	0.5789,
	0.2819,
	0.5984,
	0.284,
	0.6082,
	0.2902,
	0.6196
];

export {
	topRoof,
	topBonnet,
	frontWindshield,
	rearWindshield,
	topTrunk,
	topPassengerSideMirror,
	topDriverSideMirror,
	topDriverFrontWindow,
	topPassengerFrontWindow,
	topDriverRearWindow,
	topPassengerRearWindow,
	topDriverRearQuarter,
	topPassengerRearQuarter,
	topDriverFrontDoor,
	topPassengerFrontDoor,
	topDriverRearDoor,
	topPassengerRearDoor,
	topFrontWipers,
	topRearWipers
};
