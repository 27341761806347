import React, { Fragment } from "react";
import NoteHeader from "./NoteContent/NoteHeader";
import NoteDetails from "./NoteContent/NoteDetails";
import NotePin from "./NoteContent/NoteContentActions/NotePin";
import NoteEdit from "./NoteContent/NoteContentActions/NoteEdit";
import NoteDelete from "./NoteContent/NoteContentActions/NoteDelete";
import styles from "./notes.module.scss";

const NotesDisplay = ({
  pinnedNotes,
  otherNotes,
  all,
  now,
  vehicleId,
  refetch,
  editNote,
  selectedNote,
  resetNoteState,
  selectNote,
  toggleRemindersModal,
  updateNote,
}) => {
  const displayNotes = otherNotes ? otherNotes : pinnedNotes;
  return displayNotes.map((note, index) => {
    if (all || index < 5) {
      return (
        <div
          key={index}
          className={
            otherNotes
              ? styles.damageNotesContainer
              : styles.pinnedDamageNotesContainer
          }
        >
          <div className="note">
            <NoteHeader note={note} />

            <div className={styles.noteDetailsContainer}>
              <NoteDetails note={note} />

              <div className={styles.pinDeleteNote}>
                <NotePin note={note} refetch={refetch} />

                <NoteEdit
                  editNote={editNote}
                  selectedNote={selectedNote}
                  note={note}
                  updateNote={updateNote}
                />
                <NoteDelete note={note} refetch={refetch} />
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  });
};

export default NotesDisplay;
