import Button from "../../../../../../artzu/src/Button";
import React from "react";

const ActionButtons = ({ closeModal, changeStatus, saveButtonName }) => {
  return (
    <div className="create-booking__buttons">
      <Button
        label="Cancel"
        fullWidth="154px"
        height="40px"
        color="#414141"
        weight="bold"
        size="16px"
        border="none"
        onClick={closeModal}
      />
      <Button
        label={saveButtonName}
        fullWidth="154px"
        height="40px"
        color="#128DFF"
        weight="bold"
        size="16px"
        disabled={!saveButtonName}
        border="none"
        onClick={changeStatus}
      />
    </div>
  );
};

export default ActionButtons;
