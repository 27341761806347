import React, { Component, Fragment, useState } from "react";
import { Button } from "@material-ui/core/";
import _ from "lodash";
import { editBookingSandwich, m, filterLogData } from "../../../utils";
import "../../Management/search.css";
import Filters from "./Filters";
import StatusHeader from "./StatusHeader";
import LogTable from "./LogTable";
import ShowMore from "./ShowMore";
import { Table } from "../../../artzu/src/Table";

import styles from "./statusLog.module.scss";
import { useApolloClient } from "@apollo/client";

const injectAvailableIntervals = (statusLog) => {
  const logWithAvailable = [];
  const lengthOfLog = statusLog.length;
  console.log(statusLog);
  statusLog.forEach((entry, index) => {
    const previousEntry = statusLog[index - 1];
    console.log(entry, index, previousEntry);
    if (index === 0 && m(entry.endTime) < m()) {
      // If first entry, there has to be an available slot at the start
      logWithAvailable.push({
        operationStatus: "available",
        startTime: entry.endTime,
        endTime: null,
      });
    } else if (previousEntry && previousEntry.startTime !== entry.endTime) {
      // Fill gaps between scheduled intervals
      logWithAvailable.push({
        operationStatus: "available",
        startTime: entry.endTime,
        endTime: previousEntry.startTime,
      });
    }
    // Add scheduled interval
    logWithAvailable.push(entry);
    if (lengthOfLog - 1 === index) {
      // Add available slot at the end
      logWithAvailable.push({
        operationStatus: "available",
        endTime: entry.startTime,
      });
    }
  });
  return logWithAvailable;
};

const StatusLog = ({ statusLog, fullSize = false, timezone }) => {
  const [searchVal, updateSearchVal] = useState(null);
  const [date, updateDate] = useState(null);
  const [options, updateOptions] = useState(null);
  const [logNum, updateLogNum] = useState(null);
  const [isShowingAllLogs, updateIsShowingAllLogs] = useState(false);

  const showAll = () => updateIsShowingAllLogs(true);
  const showLess = () => updateIsShowingAllLogs(false);

  const openModal = (index) => {
    this.setState({ options: !this.state.options, logNum: index });
  };

  const orderedStatusLog = _.sortBy(
    statusLog,
    (entry) => entry.startTime
  ).reverse();
  const logWithAvailable = injectAvailableIntervals(orderedStatusLog);

  const modifiedBookings = filterLogData(logWithAvailable, searchVal, date);

  return (
    <Fragment>
      {logWithAvailable ? (
        <div className={styles["container"]}>
          {fullSize && (
            <Filters
              handleSearch={updateSearchVal}
              handleDateChange={updateDate}
              date={date}
            />
          )}
          <Table>
            {fullSize && <StatusHeader />}
            <LogTable
              statusLogData={modifiedBookings}
              isShowingAll={isShowingAllLogs}
              fullSize={fullSize}
              timezone={timezone}
            />
          </Table>
          <ShowMore
            isAllShown={isShowingAllLogs}
            showAll={showAll}
            showLess={showLess}
          />
        </div>
      ) : (
        <div className="unavailable-info">Not Available</div>
      )}
    </Fragment>
  );
};

export default StatusLog;
