import React from "react";
import styles from "../../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "react-datepicker";

const DueDateDatePicker = ({ curDue, setCurDue, dueDateCallback }) => {
  return (
    <div className={styles.datepickerContainer}>
      <DatePicker
        selected={curDue}
        onChange={(date) => {
          setCurDue(date);
          dueDateCallback(date);
        }}
        dateFormat="MMM DD, YYYY h:mm A"
        todayButton={"Today"}
        placeholderText="Select date"
        popperPlacement="bottom"
        className={styles.dateInput}
        showTimeSelect
        timeFormat="H:mm"
      />
      <FontAwesomeIcon
        icon={faCalendar}
        className={styles.dueDateCalendar}
        size="lg"
      />
    </div>
  );
};

export default DueDateDatePicker;
