import React, { useState, useEffect } from "react";
import Modal from "../../../../artzu/src/Modal";
import styles from "../../task.module.scss";

const FullImageMessage = ({ isFull, imgUrl }) => {
  return (
    <Modal open={isFull} backgroundColor="none">
      <div className={styles.fullImageContainer}>
        <img src={imgUrl} className={styles.fullDisplay}></img>
      </div>
    </Modal>
  );
};

export default FullImageMessage;
