import React, { useState, useEffect, useRef } from "react";
import styles from "./listPopup.module.scss";
import ListBox from "./ListBox";

const ListPopup = ({
  currentValue,
  listItems,
  buttonComponent,
  selectedButtonComponent,
  vehicle,
}) => {
  const [isListOpen, setListOpen] = useState(false);

  const toggleListOpenState = () => {
    setListOpen(!isListOpen);
  };

  const closeList = () => {
    setListOpen(false);
  };

  const ref = useRef();

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setListOpen(false);
    }
  };

  const useEvent = (event, handler, passive = false) => {
    useEffect(() => {
      // initiate the event handler
      window.addEventListener(event, handler, passive);

      // this will clean up the event every time the component is re-rendered
      return function cleanup() {
        window.removeEventListener(event, handler);
      };
    });
  };

  const ButtonComponent = isListOpen
    ? selectedButtonComponent
    : buttonComponent;

  useEvent("mousedown", handleClickOutside);

  return (
    <div>
      <div ref={ref} className={styles.listContainer}>
        {isListOpen && (
          <ListBox
            listItems={listItems}
            currentValue={currentValue}
            vehicle={vehicle}
            closeList={closeList}
          />
        )}
      </div>
      <div
        className={
          isListOpen ? styles.buttonContainerOpen : styles.buttonContainer
        }
      >
        <ButtonComponent onClick={toggleListOpenState} />
      </div>
    </div>
  );
};

export default ListPopup;
