import React from "react";
import { Switch, Route } from "react-router-dom";
import NavBar from "./NavBar";
import GetVehicle from "../../Queries/GetVehicle";
import VehiclesIndex from "../../Components/VehiclesIndex";
import GetServiceEvents from "../../Queries/GetServiceEvents";
import GetDamageReport from "../../Queries/GetDamageReport";
import DamageRedirect from "../../Components/DamageReport/DamageRedirect";
import VehiclesAvailable from "../Availability/index";
import MapBody from "../Layout/MapBody";
import DocumentDetails from "../Vehicle/UploadDocuments/DocumentDetails";
import UploadVehicles from "../UploadVehicles";
import "./layout.css";
import GetVehicleFromAdmin from "../../Queries/GetVehicleFromAdmin";
import GetTask from "../TasksNew/GetTask";
import TaskIndex from "../TaskIndex";
import ParkingSpotIndex from "../ParkingSpotIndex";
import GetParkingSpot from "../ParkingSpot/GetParkingSpot";
import DamageIndex from "../DamageIndex";

const Layout = () => (
  <div className="flex-col">
    <div className="nav-container">
      <NavBar />
    </div>
    <div
      className="container"
      style={
        window.innerWidth <= 1024 ? { height: "-webkit-fill-available" } : null
      }
    >
      <Switch>
        <Route path="/map" exact component={MapBody} />
        <Route path="/" exact component={VehiclesIndex} />
        <Route
          path="/vehicles/:id/service-events"
          exact
          component={GetServiceEvents}
        />
        <Route path="/availability" exact component={VehiclesAvailable} />
        <Route path="/vehicles/:id" exact component={GetVehicle} />
        <Route path="/vehicles/:id/:resource" exact component={GetVehicle} />
        <Route
          path="/vehicles/:id/:resource/:resourceId"
          exact
          component={GetVehicle}
        />
        <Route path="/vehicles/:id/damage" exact component={GetDamageReport} />
        <Route
          path="/resource/vehicle/:uuid"
          exact
          component={GetVehicleFromAdmin}
        />
        <Route path="/damagereport" exact component={DamageRedirect} />
        <Route path="/upload/vehicles" exact component={UploadVehicles} />
        <Route path="/documents/:id" exact component={DocumentDetails} />
        <Route path="/tasks" exact component={TaskIndex} />
        <Route path="/tasks/new" exact component={GetTask} />
        <Route path="/tasks/:id" exact component={GetTask} />
        <Route path="/spots" exact component={ParkingSpotIndex} />
        <Route path="/spots/:id" exact component={GetParkingSpot} />
      </Switch>
    </div>
  </div>
);

export default Layout;
