import gql from "graphql-tag";

const getProfileString = `
  {
    currentUser {
      id
      firstName
      imageLink
      parkingLots{
        id
        name
        city{
          id
          name
        }
      }
    }
  }
`;

const avcDeviceString = `
  {
    getAvcDevices{
      id
      uuid
      lastConnected
      lastMessage
      lastMessageReceivedAt
      createdAt
      status
      mqttUptime
      firmwareVersion
      mqttSession {
        id
        startTime
        endTime
      }
      simCard{
        id
        iccid
        sid
      }
      vehicle {
        id
        plate
        vin
        operationStatus
        diagnosticTroubleCodes{
          id
          status
          code
        }
        dtcCount
      }
    }
}`;

const usersString = `
{
  getAllUsers{
    id
    fullName
    imageLink
    role
    city
  }
}`;

const bookingString = `
  {
    getCurrentVehicleBookings{
      vehicleId
      driverName
      driverImage
      mobile
      profile
      startTime
      endTime
      isService
      isPrev
      bookingType
    }
  }
`;

const tasksString = `
  query getTasks($cursor: String!) {
    currentUser {
      id
      role
      availableUsers {
        id
        fullName
        role
      }
    }
    tasksConnection(first: 30, after: $cursor) {
      edges{
        cursor
        node{
          id
          name
          deleted
          createdAt
          updatedAt
          comment
          document {
            id
            expiryDate
            name
          }
          dueDate
          serviceEvent {
            id
            name
          }
          status
          user {
            id
            firstName
            fullName
          }
          vehicle {
            id
            plate
            odometer {
              id
              reading
            }
            parkingLot {
              id
              city{
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }`;
const coordinatesString = `
  {
    getVehiclesGps {
      id
      plate
      make
      model
      imageLink
      currentLocation {
        resourceId
        name
        resourceType
      }
      operationStatus
      tags {
        id
        name
        vehicleId
      }
      fleet{
        id
        name
      }
      lastGps {
        id
        lat
        lng
        heading
        capturedAt
        inParking
        inServiceLocation
      }
      diagnosticTroubleCodes{
        id
        status
        code
      }
      parkingLot {
        id
        latBounds
        lngBounds
        city{
          id
          name
        }
      }
    }
    getParkingLots{
      id
      name
      lat
      lng
      address
      vehiclesAtLocation
      city{
        id
        name
      }
    }
    getServiceLocations{
      lat
      lng
      address
      id
      name
      vehiclesAtLocation
      city{
        id
        name
        parkingLots{
          id
        }
      }
    }
  }
`;

const damageRedirectString = `
  query getVehicle($vehiclePlate: String!) {
    getVehicle(params: { plate: $vehiclePlate }) {
      id
      plate
    }
  }
`;

const parkingLotsString = `
{
  getParkingLots{
    id
    lat
    lng
    address
  }
}`;

const fleetString = `
{
  getManagement {
    id
    managementColumns
    columnSort{
      column
      descending
    }
    columnPresets{
      name
      value
      selectedColumns
      selected
    }
  }
   getEveryUser{
      id
      fullName
      email
    }

   getAllUsers{
    id
    fullName
    email
  }

  currentUser{
      id
      firstName
      lastName
      slackId
      imageLink
      level
    }

   
  getAllVehicles {
    id
    plate
    year
    make
    model
    imageLink
    hasAvcDeviceInstalled
    currentLocation{
      resourceId
      name
      resourceType
    }
    diagnosticTroubleCodes{
      id
      status
      code
    }
    lastGps {
      id
      lat
      lng
      heading
      capturedAt
      inParking
    }
    gpsStatus{
      value
      name
    }
    fleet {
      name
      id
    }
    tags{
      id
      name
      vehicleId
    }
    availableParkingLots{
      id
      name
      city{
        id
        name
      }
    }
    currentBooking{
      bookingType
      isService
      startTime
      endTime
    }
    medallionNumber
    vin
    rin
    serviceGroup {
      id
      name
    }
    parkingSpot {
      id
      identifier
    }
    parkingPassId
    lockboxCode
    transponder
    operationStatus
    odometer {
      id
      reading
      capturedAt
    }
   recentProjectedServices(dayRange: 100){
      id
      vehicleId
      name
      date
      status
      daysUntil
      mileageUntil
    }
    nextEvent {
      id
      name
      scheduledDate
    }
    lastInspected{
      id
      completedDate
      inspector
    }
    parkingLot {
      id
      name
      latBounds
      lngBounds
      city{
        id
        name
      }
    }
    availableParkingSpots{
      id
      identifier
    }
    avgMileage
    avgFleetMileage
     inParking
  }
}
`;

const getVehicleDrawerString = `
  query getVehicle($vehicleId: ID!) {
    currentUser{
      id
      level
    }
    getVehicle(params: { id: $vehicleId }) {
      id
      plate
      make
      model
      year
      vin
      imageLink
      city {
        id
        name
        timeZone
      }
      gpsStatus{
        value
        name
      }
      fleet{
        id
        name
      }
      odometer {
        id
        reading
        capturedAt
      }
      gasLevel{
        id
        reading
        capturedAt
      }
      coolantTemp{
        id
        reading
      }
      lastGps {
        id
        lat
        lng
        heading
        capturedAt
      }
      parkingLot {
        id
        latBounds
        lngBounds
        city{
          id
          name
        }
        active
        name
      }
      notes {
        id
        details
        user
        date
      }
      statusLog{
        id
        startTime
        endTime
        intervalType
        operationStatus
        comments
        userName
        userImageLink
        identificationKey
      }
      statuses{
        operationStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
      }
      currentBooking{
        startTime
        endTime
        isService
        bookingType
    }
      operationStatus
    }
  }
`;

const sourceString = `
  query getVehicle($vehicleUuid: String!) {
    getVehicle(params: {uuid: $vehicleUuid}) {
      id
    }
  }
`;

const vehicleString = `
  query getVehicle($vehicleId: ID!) {
    currentUser{
      id
      firstName
      lastName
      slackId
      imageLink
      level
    }

    getEveryUser{
      id
      fullName
      email
    }
    
    getVehicle(params: { id: $vehicleId }) {
      id
      plate
      year
      city {
        id
        name
        timeZone
      }
      tags {
        id
        name
        vehicleId
      }
      availableStatusChanges{
        name
        value
      }
      scheduledStatusChanges{
        uuid
        status {
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        startTime
        comments
        lastChangedBy
        lastChangedAt
      }
      imageLink
      avgMileage
      avgFleetMileage
      color
      vin
      rin
      medallionNumber
      lockboxCode
      parkingSpot {
        id
        identifier
        lockboxHistory{
          id
          code
          createdAt
        }
      }
      availableParkingSpots{
        id
        identifier
      }
      transponder
      parkingPassId
      make
      model
      currentServiceLocation
      damageReportView
      operationStatus
      currentLocation {
        resourceId
        name
        resourceType
      }
      avcDevice{
        id
        uuid
        lastConnected
        firmwareVersion
        createdAt
        mqttUptime
        mqttSession {
          id
          startTime
          endTime
        }
        status
        lastInboundMessage{
          id
          createdAt
          message
        }
        simCard{
          id
          iccid
          sid
        }
      }
      currentDiagnosticTroubleCodes{
        id
        startTime
        endTime
        status
        code
        description
      }
      pastDiagnosticTroubleCodes{
        id
        startTime
        endTime
        status
        code
        description
      }
      statuses{
        lockStatus {
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        operationStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        operationSubStatus{
          name
          styles{
            color
            isSolid
          }
        }
        hardwareStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        teslaVehicleStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        sentryModeStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        valetModeStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        inspectionStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
        serviceStatus{
          name
          subtext
          styles{
            color
            isSolid
          }
        }
      }
      piloDevice {
        id
        uuid
        lastAnnounce {
          id
          gasLevel
        }
      }

      fleet{
        id
        name
      }
      serviceLocations{
        id
        name
      }
      availableParkingLots{
        id
        name
      }
       parkingLot{
        id
        city{
          id
          name
        }
      }
      documents{
        id
        imageLink
        name
        expiryDate
        description
        uploadedAt
        updatedAt
        status
      }
      exteriorDamageReports {
        id
        location
        locationX
        locationY
        description
        imageLink
        resolved
        resolvedAt
        createdAt
        user {
          id
          fullName
        }
      }
      interiorDamageReports {
        id
        location
        locationX
        locationY
        description
        imageLink
        resolved
        resolvedAt
        createdAt
        user {
          fullName
        }
      }
      tickets{
        id
        imageLink
        name
        expiryDate
        description
        uploadedAt
        updatedAt
        status
      }
      odometer {
        id
        reading
        capturedAt
      }
      gasLevel{
        id
        reading
        capturedAt
      }
      lastGps{
        id
        lat
        lng
        capturedAt
      }
      gpsStatus{
        value
        name
      }
      notes {
        id
        details
        user
        userId
        userImage
        date
        pinned
        reminderDate
      }
      serviceGroup {
        name
        id
      }
      upcomingEvents {
        id
        name
        description
        vendor
        complete
        completedDate
        scheduled
        scheduledDate
        cost
        odometerReading
        link
        note
      }
      serviceCatsNames
      pastServiceEvents {
        id
        name
        description
        vendor
        complete
        completedDate
        scheduled
        scheduledDate
        cost
        odometerReading
        link
        note
      }

      inspections {
        id
        name
        description
        odometerReading
        inspector
        vehicle {
          id
        }
        exteriorWash
        interiorWash
        tirePressure
        dashLights
        washerFluid
        socket
        insuranceSlip
        vehicleOwnership
        safetyCertificate
        damage
        vinyl
        treadDepth
        gasLevel
        completedDate
        gasFilled
      }
      recentProjectedServices(dayRange: 100){
        id
        vehicleId
        name
        date
        status
        daysUntil
        mileageUntil
      }
      projectedRoutineServices {
        id
        date
        name
        totalExtension
        maintenanceExtensions{
          id
          extensionAmount
          note
        }
        daysUntil
        mileageUntil
      }

      recentServiceHistory {
        id
        name
        completedDate
      }
      statusLog{
        id
        startTime
        endTime
        intervalType
        operationStatus
        comments
        userName
        userImageLink
        identificationKey
        userLink
      }
      currentBooking{
        bookingType
        isService
        endTime
      }
      parkingLot{
        id
        city{
          id
          name
        }
        name
        active
      }
      inParking
    }
  }
`;

const noteString = `
  query getVehicle($vehicleId: ID!) {
     getEveryUser{
      id
      fullName
      email
    }
    getVehicle(params: { id: $vehicleId }) {
      id
      notes {
        id
        details
        user
        userImage
        userId
        date
        pinned
        reminderDate
      }
    }
  }
`;

const historyGpsString = `
  query historyGps($timeRange: HistoryGpsAttributes!, $plate: String!) {
    getVehicle(params: { plate: $plate }) {
      id
      plate
      lastGps {
        id
        lat
        lng
        capturedAt
      }
      historyGps(timeRange: $timeRange){
        interpolatedGps{
          lat
          lng
          index
        }
        capturedGps{
          lat
          lng
          capturedAt
          heading
        }
      }
    }
  }
`;

const managementString = `
  {
    getManagement {
      fleets {
        id
        name
      }
      serviceGroups {
        id
        name
      }

    }
  }
`;

const newManagementString = `
  {
    getManagement {
    id
    managementColumns
    columnSort{
      column
      descending
    }
    columnPresets{
      name
      value
      selectedColumns
      selected
    }
  }
  }
`;

const serviceEventsString = `
  query getServiceEvents($vehicleId: ID!) {
    getVehicle(params: { id: $vehicleId }) {
      id
      year
      make
      model
      plate
      odometer {
        id
        reading
      }
      parkingLot{
        id
        city{
          id
          name
        }
      }
      upcomingEvents {
        id
        name
        description
        vendor
        complete
        completedDate
        scheduled
        scheduledDate
        scheduledEndDate
        cost
        odometerReading
        link
        note
      }
      serviceCatsNames
      pastServiceEvents {
        id
        name
        description
        vendor
        complete
        completedDate
        scheduled
        scheduledDate
        cost
        odometerReading
        link
        note
      }
      inspections {
        id
        name
        description
        odometerReading
        inspector
        vehicle {
          id
        }
        exteriorWash
        interiorWash
        tirePressure
        dashLights
        washerFluid
        socket
        insuranceSlip
        vehicleOwnership
        safetyCertificate
        damage
        vinyl
        treadDepth
        gasLevel
        completedDate
        gasFilled
      }
    }
  }
`;

const damageString = `
  query getVehicle($vehicleId: ID!) {
    getVehicle(params: { id: $vehicleId }) {
      id
      plate
      make
      damageReportView
      exteriorDamageReports {
        id
        location
        locationX
        locationY
        description
        imageLink
        resolved
        resolvedAt
        createdAt
        user {
          id
          fullName
        }
      }
      interiorDamageReports {
        id
        location
        locationX
        locationY
        description
        imageLink
        resolved
        resolvedAt
        createdAt
        user {
          fullName
        }
      }
    }
  }
`;

const activeParkingLots = gql`
  {
    getActiveParkingLots {
      id
      name
      timeZone
      fleetsAtParkingLot {
        id
        name
      }
      city {
        id
        name
      }
    }
  }
`;

const availableVehicles = gql`
  query vehiclesAvailable(
    $startDate: ISO8601Date!
    $stretch: Int!
    $parkingLotId: ID!
    $fleetId: ID!
  ) {
    vehiclesAvailable(
      startDate: $startDate
      stretch: $stretch
      parkingLotId: $parkingLotId
      fleetId: $fleetId
    ) {
      date
      availability {
        plates
        availableCount
        totalOnlineCount
        totalCount
        serviceCount
        utilization
      }
      inOutCount {
        goingOutCount
        comingInCount
      }
    }
  }
`;

export {
  tasksString,
  getVehicleDrawerString,
  getProfileString,
  coordinatesString,
  usersString,
  bookingString,
  fleetString,
  vehicleString,
  noteString,
  historyGpsString,
  managementString,
  newManagementString,
  parkingLotsString,
  serviceEventsString,
  damageString,
  damageRedirectString,
  avcDeviceString,
  sourceString,
  availableVehicles,
  activeParkingLots,
};
