import React, { useState } from "react";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import OptionsModal from "./OptionsModal";
import ToggleButton from "../../Shared/ToggleButton";
import _ from "lodash";

const Filters = ({
  availableFilters,
  selectedFilterGroup,
  savedFilterGroups,
  changeFilters,
  isOpen,
  setIsOpen,
  clearSelectedFilter,
  background,
}) => {
  const toggleOpenState = () => setIsOpen(!isOpen);

  const saveFilterGroup = (modifiedFilterGroup) => {
    const newGroupUuid = modifiedFilterGroup.uuid;

    const existingGroupIndex = savedFilterGroups.findIndex(
      (group) => group.uuid === newGroupUuid
    );

    let allGroups;
    if (existingGroupIndex !== -1) {
      const savedFilterGroupsCopy = _.cloneDeep(savedFilterGroups);

      savedFilterGroupsCopy[existingGroupIndex] = modifiedFilterGroup;
      allGroups = savedFilterGroupsCopy;
    } else {
      allGroups = [...savedFilterGroups, modifiedFilterGroup];
    }

    const newFilters = allGroups.map((group) =>
      group.filters.map(({ categoryUuid, uuid, isExcluded, values }) => ({
        categoryUuid,
        uuid,
        isExcluded,
        values,
      }))
    );
    changeFilters(newFilters);
  };

  return (
    <div>
      <ToggleButton
        isOpen={isOpen}
        faIcon={faFilter}
        toggleOpenState={toggleOpenState}
        background={background}
      />
      <OptionsModal
        clearSelectedFilter={clearSelectedFilter}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        availableFilters={availableFilters}
        selectedFilterGroup={selectedFilterGroup}
        changeFilters={changeFilters}
        saveFilterGroup={saveFilterGroup}
        background={background}
      />
    </div>
  );
};

export default Filters;
