import React, { Fragment, useState } from "react";
import styles from "./damages.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import DamageDiagram from "./DamageDiagram";
import moment from "moment";
import CreateDamage from "./CreateDamage";
import { annotations } from "./utils";

const VehicleDamageTop = ({
  setAdditionalFilters,
  vehicleDamages,
  toggleGallery,
  vehicleId,
  refetch,
}) => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const toggleCreate = () => {
    setIsCreateOpen(!isCreateOpen);
  };

  const lifetimeCount = vehicleDamages?.lifetimeDamagesCount;
  const currentCount = vehicleDamages?.currentDamagesCount;
  const lastConfirmed = vehicleDamages?.lastConfirmedDamage;

  return (
    <Fragment>
      <div className={styles.damageTopContainer}>
        <div className={styles.topTitle}>
          <div className={styles.bold}>Vehicle damages</div>
          <div className={styles.addButton} onClick={toggleCreate}>
            <FontAwesomeIcon icon={faPlus} /> Add damage
          </div>
        </div>
        <div className={styles.damageBasicAndDiagram}>
          <div className={styles.damageTopLeft}>
            <div className={styles.damageOverview}>
              <div className={styles.overviewData}>
                <div className={styles.subTitle}>Lifetime damages</div>
                <div>{lifetimeCount}</div>
              </div>
              <div className={styles.overviewData}>
                <div className={styles.subTitle}>Current total damages</div>
                <div className={styles.landscape}>
                  {currentCount}{" "}
                  {currentCount !== 0 && (
                    <div className={styles.clickable} onClick={toggleGallery}>
                      View images
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.overviewData}>
                <div className={styles.subTitle}>Last confirmed damage</div>
                <div>{moment(lastConfirmed).format("MMM D, YYYY")}</div>
              </div>
            </div>
            <div className={styles.diagramAnnotation}>
              {annotations.map((severity) => (
                <div className={styles.annotationItem}>
                  <img
                    src={severity.value}
                    className={styles.marginRight}
                  ></img>
                  <div className={styles.bold}>{severity.name}</div>
                </div>
              ))}
            </div>
          </div>
          <DamageDiagram
            vehicleDamages={vehicleDamages}
            setAdditionalFilters={setAdditionalFilters}
          />
        </div>
      </div>
      <CreateDamage
        open={isCreateOpen}
        toggleCreate={toggleCreate}
        vehicleId={vehicleId}
        refetch={refetch}
      />
    </Fragment>
  );
};

export default VehicleDamageTop;
