import React from "react";
import classNames from "classnames";
import "./booking.scss";
import styles from "./circleImage.module.scss";

const getClassName = (status, width, height) => {
  switch (status) {
    case "Available":
      return "available";
    case "User":
      return "user-booking";
    case "Service":
    case "Offline":
    case "Deactivated":
      return "service-booking";
    case "Unauthorized":
      return "unauthorized";
    case "Late Driver":
      return "late-booking";
    default:
      return "";
  }
};

// border: "2px solid #128DFF"

const CircleImage = ({ imageLink, size, status, width, height }) => (
  <div
    style={{ width: width, height: height }}
    className={classNames(styles["booking-image-container"], styles[size])}
  >
    <div
      className={classNames(
        styles[getClassName(status, width, height)],
        styles["circle"]
      )}
    >
      {imageLink ? (
        <img className={styles["booking-image"]} src={imageLink} />
      ) : (
        <div className={styles["booking-image"]} />
      )}
    </div>
  </div>
);

export default CircleImage;
