import React, { useState } from "react";
import styles from "./optionsBody.module.scss";
import classNames from "classnames";
import Presets from "./Presets";
import Available from "./Available";
import _ from "lodash";

const Body = ({
  presets,
  selectedPreset,
  selectPreset,
  columnList,
  columnListNames,
  setSelectedColumnNames,
  saveNewPreset,
  updatePreset,
  presetMode,
  beginEditPreset,
  beginCreatePreset,
  setTempPresetValue,
  tempPresetValue,
  deletePreset
}) => {
  return (
    <div className={styles.optionsBody}>
      <Presets
        presets={presets}
        selectedPreset={selectedPreset}
        selectPreset={selectPreset}
        updatePreset={updatePreset}
        saveNewPreset={saveNewPreset}
        beginCreatePreset={beginCreatePreset}
        beginEditPreset={beginEditPreset}
        presetMode={presetMode}
        setTempPresetValue={setTempPresetValue}
        tempPresetValue={tempPresetValue}
        deletePreset={deletePreset}
      />
      <Available
        columnList={columnList}
        columnListNames={columnListNames}
        setSelectedColumnNames={setSelectedColumnNames}
      />
    </div>
  );
};

export default Body;
