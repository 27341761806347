import React from "react";
import CircleImage from "../../../../../MapView/CircleImage";
import { m } from "../../../../../../utils";

const NoteHeader = ({ note }) => {
  return (
    <div className="note-header">
      <div className="notes-user-image">
        <CircleImage
          imageLink={note.userImage}
          width="24px"
          height="24px"
          isPrev={false}
        />
        <p className="note-user">{note.user}: </p>
      </div>
      <div className="note-date-container">
        <div className="note-date">
          {m(note.date).format("MMM D, YYYY hh:mm A")}
        </div>
      </div>
    </div>
  );
};

export default NoteHeader;
