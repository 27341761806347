import React from "react";
import styles from "./footer.module.scss";
import classNames from "classnames";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NextPage = ({ currentPage, changePage, lastPage }) => {
  const isActive = currentPage < lastPage;
  return (
    <div
      className={classNames(styles.nextPage, !isActive && styles.disabled)}
      onClick={() => (isActive ? changePage(currentPage + 1) : null)}
    >
      <FontAwesomeIcon
        className={styles.nextPageIcon}
        icon={faChevronRight}
        size="sm"
      />
    </div>
  );
};

export default NextPage;
