import React from "react";
import styles from "./filterRow.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Actions from "./Actions";
import classNames from "classnames";

const Filter = ({
  index,
  filter,
  availableFilters,
  onEdit,
  removeFilter,
  lineHasAndInIt
}) => (
  <div>
    <div className={styles.filterRow}>
      <Actions
        filter={filter}
        availableFilters={availableFilters}
        onEdit={onEdit}
      />
      <div className={styles.actionButton} onClick={() => removeFilter(index)}>
        <FontAwesomeIcon
          className={classNames(styles.red, styles.icon)}
          icon={faTimes}
          size="s"
        />
      </div>
    </div>
    <div className={styles.lineContainer}>
      {lineHasAndInIt ? (
        <>
          <div className={styles.line} />
          <div className={styles.middleText}> AND</div>
          <div className={styles.line} />
        </>
      ) : (
        <div className={styles.line} />
      )}
    </div>
  </div>
);

export default Filter;
