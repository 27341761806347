import React, { useState } from "react";
import styles from "../filterRow.module.scss";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "react-datepicker";
import InputBox from "./InputBox";
import moment from "moment";

const DateInput = ({ onChange, initialValue, numberOfInputValues }) => {
  const [values, setValues] = useState(initialValue || []);
  const generateInputTitles = () => {
    if (numberOfInputValues == 1) {
      return ["Date"];
    } else if (numberOfInputValues == 2) {
      return ["Start", "End"];
    } else {
      return _.time(numberOfInputValues, Number).map(
        (index) => `Value ${index}`
      );
    }
  };

  const onInputChange = (index, value) => {
    const valuesCopy = [...values];
    valuesCopy[index] = value;
    setValues(valuesCopy);
    onChange(valuesCopy);
  };

  const inputTitles = generateInputTitles();
  return _.times(numberOfInputValues, Number).map((index) => {
    return (
      <div className={styles.dropdown}>
        <label className={styles.dropdownLabel}>
          {inputTitles[index]}{" "}
          <FontAwesomeIcon className={styles.marginLeft} icon={faCalendar} />{" "}
        </label>
        <DatePicker
          customInput={<InputBox date={values[index]} />}
          selected={moment(values[index])}
          onChange={(val) => onInputChange(index, val)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="LLL"
          timeCaption="time"
          todayButton={"Today"}
        />
      </div>
    );
  });
};

export default DateInput;
