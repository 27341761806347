import React, { Fragment, useState } from "react";
import styles from "../damages.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import { titleize } from "../../../../utils";
import { getSeverityBackColor, getSeverityColor } from "../utils";
import driverFront from "../../../../images/damages/driver-front-preview.svg";
import driverRear from "../../../../images/damages/driver-rear-preview.svg";
import passengerFront from "../../../../images/damages/passenger-front-preview.svg";
import passengerRear from "../../../../images/damages/passenger-rear-preview.svg";
import ChangeStatusModal from "./ChangeStatusModal";

const DetailLeft = ({ damage, refetch }) => {
  const [isEdit, setIsEdit] = useState(false);

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const status = damage?.status;
  const location = damage?.location;
  const type = damage?.type;
  const severity = damage?.severity;
  const description = damage?.description;
  const tags = damage?.damageTags;

  const chooseIndicatorImage = (location) => {
    switch (location) {
      case "driver_front":
        return driverFront;
      case "passenger_front":
        return passengerFront;
      case "driver_rear":
        return driverRear;
      case "passenger_rear":
        return passengerRear;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className={styles.detailBasics}>
        <div className={styles.infoTitle}>
          <div className={styles.title}>
            {String(damage?.id).padStart(6, "0")}
          </div>
          <div className={styles.editButton} onClick={toggleEdit}>
            <FontAwesomeIcon icon={faPencil} className={styles.editIcon} /> Edit
          </div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Status</div>
          <div>{titleize(status)}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Location</div>
          <div>{titleize(location)}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Type</div>
          <div>{_.capitalize(type)}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Severity</div>
          <div
            className={styles.tag}
            style={{
              color: `${getSeverityColor(severity)}`,
              backgroundColor: `${getSeverityBackColor(severity)}`,
            }}
          >
            {_.capitalize(severity)}
          </div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Description</div>
          <div>{description}</div>
        </div>
        <div className={styles.infoData}>
          <div className={styles.subTitle}>Tags</div>
          <div className={styles.landscape} style={{ gap: "6px" }}>
            {tags &&
              tags.map((tag) => (
                <div
                  className={styles.tag}
                  style={{
                    backgroundColor: "#dedede",
                  }}
                >
                  {tag.name}
                </div>
              ))}
          </div>
        </div>
        <img
          src={chooseIndicatorImage(location && location)}
          className={styles.indicatorImage}
        ></img>
      </div>
      <ChangeStatusModal
        open={isEdit}
        toggleEdit={toggleEdit}
        damageIds={[damage?.id]}
        refetch={refetch}
      />
    </Fragment>
  );
};

export default DetailLeft;
