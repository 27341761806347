import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import ChangeStatusModal from "../../../VehicleNew/VehicleDamages/DamagePage/ChangeStatusModal";
import { useQuery } from "@apollo/client";
import { GET_DAMAGE } from "../../../VehicleNew/VehicleDamages/queries";
import EditDamage from "./Edit/EditDamage";
import DeleteDamages from "./Delete/DeleteDamages";

const DamageActionContainer = ({
  open,
  selectedOption,
  selectedDamages,
  close,
  toggleRefresh,
}) => {
  const { data, loading, refetch } = useQuery(GET_DAMAGE, {
    variables: {
      damageId: selectedDamages?.[0],
    },
  });

  const damageData = data?.getDamage;
  console.log(damageData);
  return (
    <div className={styles.actionContainer}>
      {selectedOption.value === "changeStatus" && (
        <ChangeStatusModal
          open={open}
          toggleEdit={close}
          damageIds={selectedDamages}
          refetch={toggleRefresh}
        />
      )}
      {selectedOption.value === "edit" && (
        <EditDamage
          open={open}
          toggleEdit={close}
          vehicleId={selectedDamages?.[0]}
          refetch={toggleRefresh}
          damageRefetch={refetch}
          damageData={damageData && damageData}
        />
      )}
      {selectedOption.value === "delete" && (
        <DeleteDamages
          open={open}
          selectedDamages={selectedDamages}
          close={close}
        />
      )}
    </div>
  );
};

export default DamageActionContainer;
