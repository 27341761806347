import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { titleize } from "../../../../utils";
import _ from "lodash";

const SeverityValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;
  const getColor = (text) => {
    switch (text) {
      case "0":
        return "#414141";
      case "1":
        return "#128dff";
      case "2":
        return "orange";
      case "3":
        return "#ff0000";
      default:
        break;
    }
  };

  const getBackColor = (text) => {
    switch (text) {
      case "0":
        return "#f2f2f2";
      case "1":
        return "#b6dcff";
      case "2":
        return "#ffd8ab";
      case "3":
        return "#ffb5b5";
      default:
        break;
    }
  };

  const getText = (text) => {
    switch (text) {
      case "0":
        return "Unconfirmed";
      case "1":
        return "Minor";
      case "2":
        return "Medium";
      case "3":
        return "Severe";
      default:
        break;
    }
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={styles.taskTag}
        style={{
          color: `${getColor(text ? text : "new")}`,
          backgroundColor: `${getBackColor(text ? text : "new")}`,
          border: `1px ${getBackColor(text ? text : "new")} solid;`,
        }}
      >
        {getText(text)}
      </div>
    </div>
  );
};

export default SeverityValue;
