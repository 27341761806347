import React from "react";
import { useState } from "react";
import Dropdown from "../../Shared/Dropdown";
import styles from "../task.module.scss";

const TaskBodyHeader = ({
  taskData,
  isEdit,
  curType,
  curDescription,
  types,
  changeType,
  changeDescription,
  chosenAgentName,
  toggleAdmin,
}) => {
  const [curLength, setCurLength] = useState(0);

  const triggerDescription = (e) => {
    setCurLength(e.target.value.length);
    changeDescription(e.target.value);
  };

  const assignToAgent = () => {
    if (taskData) {
      if (isEdit) {
        return chosenAgentName || taskData.user?.fullName;
      } else {
        return taskData.user?.fullName;
      }
    } else {
      return chosenAgentName;
    }
  };

  return (
    <div className={styles.assignContainer}>
      <div className={styles.assignAgent}>
        Type
        {taskData ? (
          isEdit ? (
            <Dropdown
              initialValue={taskData.taskType}
              width="160px"
              height="30px"
              items={types}
              onChange={changeType}
              promptColor={"#dedede"}
            />
          ) : (
            <div className={styles.headerText}>{taskData.taskType}</div>
          )
        ) : (
          <Dropdown
            initialValue={curType || taskData?.taskType}
            width="160px"
            height="30px"
            items={types}
            onChange={changeType}
            promptColor={"#dedede"}
          />
        )}
      </div>
      <div className={styles.assignAgent} style={{ width: "400px" }}>
        Description {!taskData && `(${curLength}/50)`}
        {taskData ? (
          isEdit ? (
            <div className={styles.subjectInput}>
              <input
                className={styles.subjectInputStyle}
                maxLength={50}
                onChange={triggerDescription}
                value={curDescription || taskData.description}
              ></input>
            </div>
          ) : (
            <div className={styles.headerText}>{taskData.description}</div>
          )
        ) : (
          <div className={styles.subjectInput}>
            <input
              className={styles.subjectInputStyle}
              maxLength={50}
              onChange={triggerDescription}
            ></input>
          </div>
        )}
      </div>
      <div className={styles.assignAgent}>
        Assigned to
        <div className={styles.assignedAgent}>{assignToAgent()}</div>
        {(!taskData || isEdit) && (
          <div className={styles.changeAgentButton} onClick={toggleAdmin}>
            Change agent
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskBodyHeader;
