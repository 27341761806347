import React from "react";

const ShowMore = ({ isAllShown, showLess, showAll }) => (
  <div
    onClick={() => (isAllShown ? showLess() : showAll())}
    className="end"
    style={{ marginRight: "32px" }}
  >
    <p className="show-more grey-text small-text">
      {isAllShown ? "Show Less" : "Show All..."}
    </p>
  </div>
);

export default ShowMore;
