import React, { useState, useEffect } from "react";
import "./service.css";

import SelectField from "../../artzu/src/SelectField";
import Option from "../../artzu/src/Option";
import TextField from "../../artzu/src/TextField";
import { StylesContext } from "@material-ui/styles/StylesProvider";
import styles from "./service.module.scss";

const ServiceSelection = ({
  title,
  accessor,
  onEdit,
  serviceCatsNames = ["Error"],
  prompt,
  action
}) => {
  const [other, setOther] = useState(false);
  const [otherInput, setOtherInput] = useState("");

  useEffect(() => {
    if (action === "edit" && !serviceCatsNames.includes(prompt)) {
      setOther(true);
      setOtherInput(prompt);
    } else {
      setOther(false);
    }
  }, [prompt]);

  const handleChange = val => {
    switch (val) {
      case "Other":
        setOther(true);
        break;
      default:
        onEdit(accessor, val);
        setOther(false);
        break;
    }
  };

  const handleOtherInputChange = e => {
    setOtherInput(e.target.value);
    onEdit(accessor, e.target.value);
  };

  let serviecCatsList = serviceCatsNames.map((e, index) => (
    <Option key={index} label={e} value={e} />
  ));
  serviecCatsList.push(
    <Option key={100} label={"Bodyshop"} value={"Bodyshop"} />
  );
  serviecCatsList.push(<Option key={101} label={"Other"} value={"Other"} />);

  return (
    <div className="text-input">
      <h3>{title}</h3>
      <div style={{ marginLeft: "6em" }} className={styles.name}>
        <SelectField
          prompt={other ? "Other" : prompt}
          onChange={(val, label) => handleChange(val)}
          width="14em"
        >
          {serviecCatsList}
        </SelectField>
      </div>
      {other ? (
        <div className={styles.other}>
          <TextField
            placeholder="Other"
            value={otherInput}
            onChange={handleOtherInputChange}
            width="15em"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ServiceSelection;
