import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_SELECTED_TASKS } from "../../../../TasksNew/queries";
import _ from "lodash";
import AddTagContent from "./AddTagContent";

const AddTag = ({ selectedOption, selections, close, clearAllSelections }) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_SELECTED_TASKS, {
    variables: {
      taskIds: selections,
    },
    fetchPolicy: "no-cache",
  });
  if (loading) return <p>Loading...</p>;

  console.log(data);

  // let allTags = data.getAllVehicleTags.allTags;
  const selectedTags = [];
  let taskList = data.getAllTasks.tasks;
  taskList.map((vehicle) =>
    vehicle.tags.map((item) => selectedTags.push(item.name))
  );
  const sortedSelectedTags = [...selectedTags];
  sortedSelectedTags.sort();
  console.log(sortedSelectedTags);
  //get an object which is a tally of tags
  var tagTally = [...sortedSelectedTags].reduce((tally, tag) => {
    tally[tag] = (tally[tag] || 0) + 1;
    return tally;
  }, {});
  const entryArray = Object.entries(tagTally);
  //filter the tally
  const countMatchPairs = entryArray.filter(([k, v]) => v === taskList.length);
  //get the commontags from filtered entries array
  const commonTags = Object.keys(Object.fromEntries(countMatchPairs));
  console.log(commonTags);

  const tagsToRemove = [];
  const deleteCommonTags = (childData) => {
    tagsToRemove.push(childData);
  };

  return (
    <AddTagContent
      selectedOption={selectedOption}
      close={close}
      tasks={taskList}
      // alltags={allTags}
      commonTags={commonTags}
      callback={deleteCommonTags}
      toRemove={tagsToRemove}
      refetch={refetch}
      clearAllSelections={clearAllSelections}
    />
  );
};

export default AddTag;
