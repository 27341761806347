import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import "../../Vehicle/vehicle.scss";
import { withStyles } from "@material-ui/core/styles";
import style from "./odometerModal.module.scss";
import Modal from "../../../artzu/src/Modal";
import Button from "../../../artzu/src/Button";
import classNames from "classnames";

class OdometerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reading: this.props.odometer,
      modal: false,
      confirm: {}
    };
  }

  verifyInput = () => {
    if (this.state.reading === "") {
      return false;
    }
    if (Number(this.state.reading) > 0) return true;
    return false;
  };

  setOdometer = reading => {
    this.setState({ reading });
  };

  setModal = value => {
    this.setState({ modal: value });
  };

  setConfirm = (plate, value) => {
    let confirm = this.state.confirm;
    confirm[plate] = value;

    this.setState({ confirm: confirm });
  };

  render() {
    const {
      vehicleId,
      refetch,
      toggleEdit,
      classes,
      prevOdometer,
      type,
      plate,
      updateEdit,
      management,
      editParams,
      editError,
      details
    } = this.props;
    const { reading, modal, confirm } = this.state;

    return (
      <div style={details ? { textAlign: "left" } : { textAlign: "center" }}>
        <Modal open={modal}>
          <div className={style.container}>
            <div className={classNames(style.text, style.marginBottom)}>
              Your input kilometer is more than 5000km of the previous one, Are
              you sure?
            </div>
            <div className={style.buttons}>
              <Button
                label="No"
                onClick={event => {
                  this.setModal(false);
                  this.setConfirm(plate, false);
                  this.setOdometer("");
                  editError(type, plate, true);
                }}
                color="#414141"
              />
              <Button
                label="Yes"
                onClick={event => {
                  this.setModal(false);
                  this.setConfirm(plate, true);
                  editParams(Number(vehicleId), type, reading);
                  updateEdit(plate, type, reading);
                }}
              />
            </div>
          </div>
        </Modal>
        <Input
          className={
            this.verifyInput() === false
              ? classes.odometerError
              : Number(reading) !== prevOdometer
              ? classes.odometerEdit
              : classes.odometerInput
          }
          classes={{ underline: classes.underline }}
          type="number"
          autoFocus={details ? false : true}
          value={reading}
          onChange={evt => {
            this.setOdometer(evt.target.value);
          }}
          onBlur={evt => {
            if (this.verifyInput()) {
              editError(type, plate, false);
              if (Number(reading) !== prevOdometer) {
                if (Number(reading) - Number(prevOdometer) >= 5000) {
                  if (!confirm[plate]) {
                    this.setModal(true);
                  }
                } else {
                  editParams(Number(vehicleId), type, reading);
                  updateEdit(plate, type, reading);
                }
              }
            } else {
              editError(type, plate, true);
            }
          }}
          style={details ? { width: "200px" } : null}
        />
      </div>
    );
  }
}

const styles = theme => ({
  odometerInput: {
    width: "100px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  odometerEdit: {
    width: "100px",
    color: "#128DFF",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  odometerError: {
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  }

  // underline: {
  //   "&:after": {
  //     borderBottom: `2px solid ${theme.palette.blue}`,
  //   },
  // },
});

export default withStyles(styles)(OdometerInput);
