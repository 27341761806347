import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../../artzu/src/Button";
import "../vehicle.scss";

class DeleteDocument extends Component {
  DELETE_DOCUMENT = gql`
    mutation deleteDocument($docId: ID!) {
      deleteDocument(id: $docId) {
        success
        errors
      }
    }
  `;

  render() {
    const { docId, refetch, handleClose } = this.props;
    return (
      <Mutation
        mutation={this.DELETE_DOCUMENT}
        variables={{ docId }}
        onError={data => {
          console.log("error", data);
        }}
        onCompleted={() => refetch()}
      >
        {deleteDocument => (
          <div>
            <Button
              fullWidth="140px"
              height="72px"
              label={
                <div
                  style={{
                    color: "#FF0000",
                    display: "block",
                    width: "min-content",
                    margin: "auto"
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <span style={{ padding: "12px" }}>Delete</span>
                </div>
              }
              radius="0"
              color="#414141"
              border="none"
              onClick={() => {
                deleteDocument();
                handleClose();
              }}
            />
          </div>
        )}
      </Mutation>
    );
  }
}

export default DeleteDocument;
