import React, { Component } from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../Containers/Loading";
import styles from "./parkingSpot.module.scss";
import ParkingSpotPage from "./ParkingSpotPage";
import { GET_CURRENT_ADMIN } from "./queries";

const GetParkingSpot = (props) => {
  const { data, loading } = useQuery(GET_CURRENT_ADMIN);

  if (loading) {
    return (
      <div className={styles.spotLoading}>
        <Loading />
      </div>
    );
  }
  if (data) {
    return <ParkingSpotPage currentAdmin={data.currentUser} />;
  }
};

export default GetParkingSpot;
