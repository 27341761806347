import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router";
import styles from "./parkingSpot.module.scss";
import Loading from "../../Containers/Loading";
import ParkingSpotHeader from "./ParkingSpotHeader";
import { GET_PARKING_SPOT } from "./queries";
import ParkingSpotPageContent from "./ParkingSpotPageContent";
import ParkingSpotTitle from "./ParkingSpotTitle";

const ParkingSpotPage = ({ currentAdmin }) => {
  const match = useRouteMatch();
  const params = match.params;

  const { loading, error, data, refetch } = useQuery(GET_PARKING_SPOT, {
    variables: { parkingSpotId: params.id },
    fetchPolicy: "no-cache",
  });
  console.log(data);
  const parkingSpotData = data?.getParkingSpot;
  const timelineData = data?.getSpotTimelineEvents;

  return (
    <Fragment>
      <Helmet>
        <title>Tasks | Autzu Fleet</title>
      </Helmet>
      <div className={styles.parkingSpotPage}>
        <ParkingSpotHeader parkingSpotData={parkingSpotData} />
        <ParkingSpotTitle
          spotId={parkingSpotData?.id}
          identifier={parkingSpotData?.identifier}
          refetch={refetch}
        />
        {loading ? (
          <Loading />
        ) : (
          <ParkingSpotPageContent
            parkingSpotData={parkingSpotData}
            timelineData={timelineData}
            params={params}
            refetch={refetch}
            currentAdmin={currentAdmin}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ParkingSpotPage;
