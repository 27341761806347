import React from "react";
import { useState } from "react";
import { GET_ADMIN_NAME, REMOVE_REMINDER, TASK_REMINDER } from "../../queries";
import { Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import styles from "../../task.module.scss";
import ReminderDatePicker from "./ReminderDatePicker";
import AddAdmin from "./AddAdmin";
import RemindedAdmins from "./RemindedAdmins";

const TaskReminder = ({
  taskData,
  refreshTaskCallback,
  taggedAdmins,
  currentAdmin,
}) => {
  const defaultReminderDate =
    taskData?.reminderDate && moment(taskData.reminderDate);

  const [reminderDate, setReminderDate] = useState(defaultReminderDate);
  const [newAdmin, setNewAdmin] = useState();

  const { data, loading } = useQuery(GET_ADMIN_NAME);

  const allAdmins =
    data?.allAdminName.map((admin) => {
      return {
        name: admin.firstName + " " + admin.lastName,
        value: admin.id,
      };
    }) || [];

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  const [reminderMutation] = useMutation(TASK_REMINDER);
  const handleRemind = (givenId, date) => {
    reminderMutation({
      variables: {
        taskId: taskData?.id,
        adminId:
          givenId ||
          data.allAdminName.find((admin) => admin.id === newAdmin)?.id,
        reminderDate: date || reminderDate,
      },
    }).then(({ data: { remindAdmin } }) => {
      if (remindAdmin.success) {
        setNewAdmin(undefined);
        refreshTaskCallback(taskData?.id);
        console.log("add reminder succeed!");
      } else {
        console.log("add reminder error!");
      }
    });
  };

  const [removeReminderMutation] = useMutation(REMOVE_REMINDER);
  const handleRemove = (admin) => {
    removeReminderMutation({
      variables: {
        taskId: taskData?.id,
        adminId:
          data.allAdminName.find(
            (item) => item.firstName + " " + item.lastName === admin
          )?.id || currentAdmin.id,
        adminName: admin,
      },
    }).then(({ data: { removeReminder } }) => {
      if (removeReminder.success) {
        refreshTaskCallback(taskData?.id);
        console.log("remove reminder succeed!");
      } else {
        console.log("remove reminder error!");
      }
    });
  };

  const addable = newAdmin !== undefined && reminderDate;

  return (
    <Fragment>
      <div className={styles.reminderContainer}>
        <div className={styles.reminderHeader}>
          <h2>Reminder</h2>
        </div>
        <div className={styles.subscript}>Date and time</div>
        <ReminderDatePicker
          reminderDate={reminderDate}
          taggedAdmins={taggedAdmins}
          currentAdmin={currentAdmin}
          taskData={taskData}
          setReminderDate={setReminderDate}
          handleRemind={handleRemind}
        />
        <div className={styles.addReminderUser}>Users:</div>
        <div className={styles.subscript}>Add user</div>
        <AddAdmin
          newAdmin={newAdmin}
          allAdmins={allAdmins}
          triggerAdmin={triggerAdmin}
          taskData={taskData}
          addable={addable}
          handleRemind={handleRemind}
        />
        <RemindedAdmins
          taggedAdmins={taggedAdmins}
          handleRemove={handleRemove}
        />
      </div>
    </Fragment>
  );
};

export default TaskReminder;
