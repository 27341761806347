import React from "react";
import styles from "../../task.module.scss";

const EmptyRelatedVehicle = ({ toggleVehicle }) => {
  return (
    <div className={styles.vehicleInfoContainer}>
      <div className={styles.vehicleInfoHeader}>
        <h2>Vehicle</h2>
        <div
          className={styles.chooseAVehicle}
          onClick={() => toggleVehicle()}
          style={{ cursor: "pointer" }}
        >
          Choose a vehicle
        </div>
      </div>
      <div className={styles.noVehicle}>No vehicle selected</div>
    </div>
  );
};

export default EmptyRelatedVehicle;
