import React, { useState } from "react";
import closeButton from "../../../../images/buttons/close-button.svg";
import Pen from "../../../../images/icons/pen.svg";
import TrashCan from "../../../../images/icons/trash-can.svg";
import NewTemplateModal from "./NewTemplateModal";
import DeleteModal from "./DeleteModal";
import { Fragment } from "react";
import styles from "../../task.module.scss";
import Modal from "../../../../artzu/src/Modal";

const CurrentTemplateModal = ({
  open,
  curTemplate,
  toggleCur,
  toggleTemplate,
  toggleNew,
  closeNew,
  refetch,
  isNew,
  clearCurrentTemplate,
  setData,
}) => {
  const [isDelete, setIsDelete] = useState(false);

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.templateContainer}>
          <div className={styles.templateTitle}>
            {curTemplate && curTemplate.name}
            <img
              src={closeButton}
              onClick={() => {
                toggleCur();
                toggleTemplate();
              }}
              style={{ cursor: "pointer" }}
            ></img>
          </div>
          <div className={styles.subscript}>Text</div>
          <div
            className={styles.templateTextBox}
            style={{ whiteSpace: "pre-line" }}
          >
            {curTemplate && curTemplate.text}
          </div>
          <div
            className={styles.templateButtons}
            style={{ justifyContent: "flex-end", marginTop: "24px" }}
          >
            <div>
              <button
                className={styles.editDeleteButton}
                onClick={() => {
                  toggleNew();
                  toggleCur();
                }}
                style={{ marginRight: "12px" }}
              >
                <img src={Pen} style={{ marginRight: "16px" }}></img>
                Edit template
              </button>
            </div>
            <div>
              <button
                className={styles.editDeleteButton}
                onClick={() => {
                  toggleDelete();
                }}
                style={{ color: "#ff0000" }}
              >
                <img src={TrashCan} style={{ marginRight: "6px" }}></img>
                Delete template
              </button>
            </div>
          </div>
          <div className={styles.templateButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  clearCurrentTemplate();
                  toggleCur();
                }}
              >
                Back
              </button>
            </div>
            <div>
              <button
                className={styles.chooseButton}
                onClick={() => {
                  curTemplate && setData(curTemplate.text);
                  clearCurrentTemplate();
                  toggleCur();
                  toggleTemplate();
                }}
              >
                Use
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <NewTemplateModal
        open={isNew}
        toggleNew={toggleNew}
        toggleTemplate={toggleTemplate}
        refetch={refetch}
        closeNew={closeNew}
        curTemplate={curTemplate}
        clearCurrentTemplate={clearCurrentTemplate}
      />
      <DeleteModal
        open={isDelete}
        toggleDelete={toggleDelete}
        toggleCur={toggleCur}
        clearCurrentTemplate={clearCurrentTemplate}
        templateId={curTemplate && curTemplate.id}
        refetch={refetch}
      />
    </Fragment>
  );
};

export default CurrentTemplateModal;
