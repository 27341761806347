import gql from "graphql-tag";

const MAKE_AVAILABLE = gql`
  mutation MakeAvailable($vehicleId: ID!) {
    makeVehicleAvailable(vehicleId: $vehicleId) {
      success
      errors
    }
  }
`;

const CHANGE_STATUS = gql`
  mutation changeStatus(
    $vehicleId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $cancelUserReservations: Boolean
    $comments: String
    $statusType: String!
  ) {
    changeStatusInterval(
      vehicleId: $vehicleId
      startTime: $startTime
      endTime: $endTime
      cancelUserReservations: $cancelUserReservations
      comments: $comments
      statusType: $statusType
    ) {
      success
      bookingErrors {
        message
        errorType
      }
    }
  }
`;

export { MAKE_AVAILABLE, CHANGE_STATUS };
