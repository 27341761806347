import React, { Component, Fragment } from "react";
// import Input from "@material-ui/core/Input";
// import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../artzu/src/Button";
import SelectFilter from "./SelectFilter";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSquareFull } from "@fortawesome/pro-regular-svg-icons";
import { faLineColumns } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./selectfilter.css";

class NestedSelectFilter extends Component {
  constructor() {
    super();
    this.state = {
      searchValues: {},
      selected: [],
      preset: {},
      all: null,
      toggle: {},
      filterBy: [],
      listSelect: {},
      subList: {}
    };
  }
  componentDidMount() {
    const preset = this.props.preset;
    let type = this.props.selectionType;
    const checkList = this.props.checkList;
    let list = this.state.listSelect;
    let subList = this.state.subList;

    checkList.forEach(item => {
      if (!preset[item.name]) {
        preset[item.name] = {
          name: item.name,
          checked: item.checked,
          list: {}
        };
        if (item.listItem && item.listItem.length > 0) {
          item.listItem.forEach((sub, index) => {
            preset[item.name].list[item.listItem[index].name] =
              item.listItem[index];
          });
        }
      }
    });

    for (let selection in preset) {
      if (preset[selection].list) {
        for (let item in preset[selection].list) {
          if (preset[selection].list[item].checked === true) {
            list[selection] = true;
          }
        }
      }
    }

    this.props.changePreset(type, preset);
    this.setState({ selected: this.props.selection, listSelect: list });
  }

  chooseIcon = name => {
    if (this.state.toggle[name]) {
      return faAngleUp;
    } else {
      return faAngleDown;
    }
  };

  recordSelection = e => {
    let selection = this.state.selected;
    const preset = this.props.preset;
    let type = this.props.selectionType;
    let all = this.props.all;
    let filterBy = this.props.searchOptions;
    let list = this.state.listSelect;
    let checkList = this.props.checkList;
    let subList = this.state.subList;

    checkList.forEach(item => {
      if (!preset[item.name]) {
        preset[item.name] = {
          name: item.name,
          checked: item.checked,
          list: {}
        };
        if (item.listItem && item.listItem.length > 0) {
          item.listItem.forEach((sub, index) => {
            preset[item.name].list[item.listItem[index].name] =
              item.listItem[index];
          });
        }
      }
    });
    this.props.changePreset(type, preset);

    if (e.target.checked === true) {
      if (e.target.name === "All") {
        this.props.onFilter(type, 0);
        for (let selection in preset) {
          preset[selection].checked = false;
          if (preset[selection].list) {
            for (let item in preset[selection].list) {
              preset[selection].list[item].checked = false;
            }
          }
        }
        preset[e.target.name].checked = true;
        all = true;
        selection = [];
        filterBy = [];
        list = {};
      } else {
        // selecting anything other than all
        if (preset["All"].checked === true) {
          //reset
          selection = [];
          filterBy = [];
          list = {};
          for (let selection in preset) {
            preset[selection].checked = false;
            if (preset[selection].list) {
              for (let item in preset[selection].list) {
                preset[selection].list[item].checked = false;
              }
            }
          }
        }

        if (e.target.id) {
          preset[e.target.id].list[e.target.name].checked = true;

          if (subList[e.target.id]) {
            subList[e.target.id].push(e.target.name);
          } else {
            subList[e.target.id] = [];
            subList[e.target.id].push(e.target.name);
          }

          if (preset[e.target.id].list[e.target.name].checked === true) {
            list[e.target.id] = true;
          }
        } else if (preset[e.target.name].list !== {}) {
          preset[e.target.name].checked = true;

          for (let item in preset[e.target.name].list) {
            preset[e.target.name].list[item].checked = true;
            if (
              selection &&
              (filterBy.includes(item) || selection.includes(item))
            ) {
              let index = selection.indexOf(item);
              selection.splice(index, 1);
              let indexF = filterBy.indexOf(item);
              filterBy.splice(indexF, 1);
            }
          }
        } else {
          preset[e.target.name].checked = true;
        }
        //add selections to filter by if not already in the array
        if (
          selection &&
          !selection.includes(e.target.name) &&
          !filterBy.includes(e.target.name)
        ) {
          selection.push(e.target.name);
          filterBy.push(e.target.name);
        }

        preset["All"].checked = false;
        this.props.onFilter(type, selection);
        this.props.changeOptions(type, filterBy);
        // add selections to filter by
        // defines what is checked
        all = false;
      }
    } else {
      // unselecting an option
      if (selection.length > 0) {
        //removes selection from being filtered
        if (
          selection.includes(e.target.name) &&
          filterBy.includes(e.target.name)
        ) {
          let index = selection.indexOf(e.target.name);
          selection.splice(index, 1);
          let indexF = filterBy.indexOf(e.target.name);
          filterBy.splice(indexF, 1);
        }
        this.setState({ selected: selection, filterBy: filterBy });
        this.props.onFilter(type, selection);
        this.props.changeOptions(type, filterBy);
      }

      //subList checks if anything in a category is unselected
      if (e.target.id) {
        if (preset[e.target.id].checked === true) {
          preset[e.target.id].checked = false;
          preset[e.target.id].list[e.target.name].checked = false;

          for (let item in preset[e.target.id].list) {
            if (preset[e.target.id].list[item].checked === true && selection) {
              selection.push(preset[e.target.id].list[item].name);
              filterBy.push(preset[e.target.id].list[item].name);
              if (subList[e.target.id]) {
                subList[e.target.id].push(item);
              } else {
                subList[e.target.id] = [];
                subList[e.target.id].push(item);
              }
            }
          }

          if (subList[e.target.id] && subList[e.target.id].length > 0) {
            list[e.target.id] = true;
          } else {
            list[e.target.id] = false;
          }

          let index = selection.indexOf(e.target.id);
          selection.splice(index, 1);
          let indexF = filterBy.indexOf(e.target.id);
          filterBy.splice(indexF, 1);

          this.props.onFilter(type, selection);
        } else {
          preset[e.target.id].list[e.target.name].checked = false;
          if (subList[e.target.id]) {
            let indexS = subList[e.target.id].indexOf(e.target.name);
            subList[e.target.id].splice(indexS, 1);
          }
          if (subList[e.target.id] && subList[e.target.id].length < 1) {
            list[e.target.id] = false;
          }
        }
      } else if (preset[e.target.name].list !== {}) {
        preset[e.target.name].checked = false;
        for (let item in preset[e.target.name].list) {
          preset[e.target.name].list[item].checked = false;
          if (subList[e.target.id]) {
            let indexS = subList[e.target.id].indexOf(item);
            subList[e.target.id].splice(indexS, 1);
          }
          if (subList[e.target.id] && subList[e.target.id].length < 1) {
            list[e.target.id] = false;
          }
        }
      } else {
        preset[e.target.name].checked = false;
      }

      //when nothing is selected
      if (
        preset["All"].checked === false &&
        selection.length < 1 &&
        filterBy.length < 1
      ) {
        all = true;
      } else {
        all = false;
      }
      //resets filter selections
      if (all === true) {
        this.props.onFilter(type, 0);
        filterBy = [];
        list = {};
        for (let selection in preset) {
          preset[selection].checked = false;
          if (preset[selection].list) {
            for (let item in preset[selection].list) {
              preset[selection].list[item].checked = false;
            }
          }
        }
        preset["All"].checked = true;
      }
    }

    this.setState({
      selected: selection,
      preset: preset,
      all: all,
      filterBy: filterBy,
      listSelect: list,
      subList: subList
    });

    this.props.changePreset(type, preset);
    this.props.allCheck(type, all);
    this.props.changeOptions(type, filterBy);
  };

  toggleChecklist = e => {
    const toggle = this.state.toggle;
    if (toggle[e.target.id]) {
      toggle[e.target.id] = !toggle[e.target.id];
    } else {
      for (let list in toggle) {
        toggle[list] = false;
      }
      toggle[e.target.id] = true;
    }
    this.setState({
      toggle: toggle
    });
  };

  render() {
    const { checkList, selectionType, onFilter, preset, all } = this.props;
    const { toggle, listSelect } = this.state;
    const classes = this.useStyles;
    return (
      <div className="sf-wrapper">
        <div className="sf-list">
          <ul className="sf-ul">
            {checkList.map((item, index) => (
              <ul className="sf-inner-ul">
                <li className="sf-list-item" key={index}>
                  <Checkbox
                    name={item.name}
                    value={index}
                    dataList={item.listItem}
                    disabled={all === true && index === 0 ? true : false}
                    checked={
                      all === false && index === 0
                        ? false
                        : all === true && index !== 0
                        ? false
                        : all === true && index === 0
                        ? true
                        : preset[item.name] &&
                          preset[item.name].checked === true
                        ? true
                        : false
                    }
                    style={{ width: 12, height: 12, color: "#128DFF" }}
                    icon={
                      <FontAwesomeIcon
                        icon={faSquareFull}
                        style={{
                          fontSize: "12px",
                          color: "#128DFF",
                          backgroundColor: "#FFFFFF"
                        }}
                      />
                    }
                    checkedIcon={
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        style={{
                          fontSize: "14px",
                          color: "#128DFF"
                        }}
                      />
                    }
                    onChange={this.recordSelection}
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                  />
                  <div
                    className="sf-item-name"
                    id={item.name}
                    name={item.name}
                    style={
                      (preset[item.name] &&
                        preset[item.name].checked === true) ||
                      (listSelect[item.name] === true &&
                        preset["All"].checked === false) ||
                      (all === true && index === 0)
                        ? { fontWeight: 700 }
                        : null
                    }
                    onClick={this.toggleChecklist}
                  >
                    {item.name}
                    {item.qty ? (
                      <span
                        className="sf-item-number"
                        style={{ color: "#128DFF", padding: "0 0 0 2px" }}
                      >
                        ({item.qty})
                      </span>
                    ) : null}
                  </div>
                  {item.unique ? null : (
                    <FontAwesomeIcon
                      id={item.name}
                      name={item.name}
                      icon={this.chooseIcon(item.name)}
                      className="sf-toggle"
                      name={item.name}
                      style={{ width: "12px", padding: "0 0 0 0.25em" }}
                      onClick={this.toggleChecklist}
                    />
                  )}
                </li>

                {toggle[item.name]
                  ? item.listItem.map(listItem => (
                      <li className="sf-nested-item">
                        <Checkbox
                          className="sf-check"
                          name={listItem.name}
                          id={item.name}
                          value={index}
                          checked={
                            all === false && index === 0
                              ? false
                              : all === true && index !== 0
                              ? false
                              : preset[item.name].list[listItem.name]
                                  .checked === true
                              ? true
                              : false
                          }
                          style={{ width: 12, height: 12, color: "#128DFF" }}
                          icon={
                            <FontAwesomeIcon
                              icon={faSquareFull}
                              style={{
                                fontSize: "12px",
                                color: "#128DFF",
                                backgroundColor: "#FFFFFF"
                              }}
                            />
                          }
                          checkedIcon={
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              style={{
                                fontSize: "14px",
                                color: "#128DFF"
                              }}
                            />
                          }
                          onChange={this.recordSelection}
                          inputProps={{
                            "aria-label": "secondary checkbox"
                          }}
                        />
                        <div
                          className="sf-item-name"
                          style={
                            preset[item.name] &&
                            preset[item.name].list[listItem.name].checked ===
                              true &&
                            all === false
                              ? { fontWeight: 700 }
                              : null
                          }
                        >
                          {listItem.name}
                          {listItem.qty ? (
                            <span
                              className="sf-item-number"
                              style={{
                                color: "#128DFF",
                                padding: "0 0 0 2px"
                              }}
                            >
                              ({listItem.qty})
                            </span>
                          ) : null}
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default NestedSelectFilter;
