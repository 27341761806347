import React from "react";
import _ from "lodash";
import "../vehicle.scss";
import HeaderTop from "./Header/HeaderTop";
import HeaderBot from "./Header/HeaderBot";
import StatusDisplayRight from "./StatusDisplay/StatusDisplayRight";
import StatusDisplayLeft from "./StatusDisplay/StatusDisplayLeft";

const VehicleHeader = ({
  vehicle,
  showLocation,
  city,
  status,
  operationStatus,
  teslaVehicleStatus,
  serviceStatus,
  inspectionStatus,
  sentryModeStatus,
  valetModeStatus,
  refetch
}) => {
  const toggleRefresh = () => {
    refetch();
  };

  return (
    <div className="vehicleDetailsContainer">
      <div className="headerContainer">
        <div className="headerPanel">
          <HeaderTop vehicle={vehicle} />
          <HeaderBot
            vehicle={vehicle}
            city={city}
            showLocation={showLocation}
            status={status}
            toggleRefresh={toggleRefresh}
          />
        </div>
        <div className="statusPanel">
          <StatusDisplayLeft
            vehicle={vehicle}
            operationStatus={operationStatus}
            toggleRefresh={toggleRefresh}
            sentryModeStatus={sentryModeStatus}
          />
          <StatusDisplayRight
            serviceStatus={serviceStatus}
            teslaVehicleStatus={teslaVehicleStatus}
            inspectionStatus={inspectionStatus}
            valetModeStatus={valetModeStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleHeader;
