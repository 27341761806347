import unconfirmed from "../../../images/damages/unconfirmed.svg";
import minor from "../../../images/damages/minor.svg";
import medium from "../../../images/damages/medium.svg";
import severe from "../../../images/damages/severe.svg";

const countGenerator = (locationDamages) => {
  let locationCounts = [];
  const confirmedLocationDamages = locationDamages?.filter(
    (damage) => damage.status === "confirmed" || damage.status === "in_repair"
  );

  const unconfirmed = confirmedLocationDamages?.filter(
    (damage) => damage.severity === "unconfirmed"
  ).length;
  const minor = confirmedLocationDamages?.filter(
    (damage) => damage.severity === "minor"
  ).length;
  const medium = confirmedLocationDamages?.filter(
    (damage) => damage.severity === "medium"
  ).length;
  const severe = confirmedLocationDamages?.filter(
    (damage) => damage.severity === "severe"
  ).length;
  locationCounts.push(unconfirmed, minor, medium, severe);

  return locationCounts;
};

const getLocationIndex = (location) => {
  switch (location) {
    case "Driver front":
      return "1";
    case "Passenger front":
      return "2";
    case "Driver rear":
      return "3";
    case "Passenger rear":
      return "4";
    default:
      break;
  }
};

const getDropdownOptions = (field) => {
  switch (field) {
    case "status":
      return statuses;
    case "location":
      return locations.slice(1);
    case "type":
      return types;
    case "severity":
      return severities;

    default:
      break;
  }
};

const locations = [
  { name: "All", value: "all" },
  { name: "Driver front", value: "driver_front" },
  { name: "Driver rear", value: "driver_rear" },
  { name: "Passenger front", value: "passenger_front" },
  { name: "Passenger rear", value: "passenger_rear" },
];

const statuses = [
  { name: "Reported", value: "reported" },
  { name: "Confirmed", value: "confirmed" },
  { name: "In repair", value: "in_repair" },
  { name: "Resolved", value: "resolved" },
  { name: "Deleted", value: "deleted" },
];

const types = [
  { name: "Scratch", value: "scratch" },
  { name: "Dent", value: "dent" },
  { name: "Crack", value: "crack" },
];

const severities = [
  { name: "Unconfirmed", value: "unconfirmed" },
  { name: "Minor", value: "minor" },
  { name: "Medium", value: "medium" },
  { name: "Severe", value: "severe" },
];

const getSeverityColor = (text) => {
  switch (text) {
    case "unconfirmed":
      return "#414141";
    case "minor":
      return "#128dff";
    case "medium":
      return "orange";
    case "severe":
      return "#ff0000";
    default:
      break;
  }
};

const getSeverityBackColor = (text) => {
  switch (text) {
    case "unconfirmed":
      return "#dedede";
    case "minor":
      return "#b6dcff";
    case "medium":
      return "#ffd8ab";
    case "severe":
      return "#ffb5b5";
    default:
      return "#f2f2f2";
  }
};

const getTaskTagColor = (text) => {
  switch (text) {
    case "in_progress":
      return "orange";
    case "resolved":
      return "#3dc700";
    case "deleted":
      return "#414141";
    case "unassigned":
      return "#ff0000";
    case "new":
      return "#128dff";
    case "backlog":
      return "rgba(122, 18, 255, 1)";
    default:
      break;
  }
};

const getTaskTagBackColor = (text) => {
  switch (text) {
    case "in_progress":
      return "#ffe2c1";
    case "resolved":
      return "#cbf0bb";
    case "deleted":
      return "#dedede";
    case "unassigned":
      return "#ffbaba";
    case "new":
      return "#daedff";
    case "backlog":
      return "rgba(243, 218, 255, 1)";
    default:
      break;
  }
};

const annotations = [
  { name: "Unconfirmed", value: unconfirmed },
  { name: "Minor", value: minor },
  { name: "Medium", value: medium },
  { name: "Severe", value: severe },
];

export {
  countGenerator,
  getLocationIndex,
  locations,
  getSeverityColor,
  getSeverityBackColor,
  statuses,
  types,
  severities,
  getDropdownOptions,
  getTaskTagColor,
  getTaskTagBackColor,
  annotations,
};
