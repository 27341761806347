import React, { useState } from "react";
import EditableLockboxCode from "./EditableLockboxCode";

const LockboxCodeSection = ({ isEdit, lockboxCode, handleCodeChange }) => {
  return (
    <div className="vehicle-information">
      <p className="tight-margin-details">Glovebox Code</p>
      <EditableLockboxCode
        isEdit={isEdit}
        lockboxCode={lockboxCode}
        handleCodeChange={handleCodeChange}
      />
    </div>
  );
};

export default LockboxCodeSection;
