import React from "react";

import styles from "../statusLog.module.scss";

import SearchBar from "./SearchBar";
import DateFilter from "./DateFilter";

const Filters = ({ date, handleSearch, handleDateChange }) => {
  return (
    <div className={styles.filters}>
      <SearchBar handleSearch={handleSearch} />
      <div className={styles.orDivider}>or</div>
      <DateFilter handleDateChange={handleDateChange} date={date} />
    </div>
  );
};

export default Filters;
