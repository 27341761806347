import React from "react";
import styles from "../../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const TagList = ({ tagData, handleRemoveTag }) => {
  return (
    <div className={styles.taskTagsContainer}>
      {tagData &&
        tagData.map((tag) => (
          <div className={styles.relatedTagContainer}>
            {tag.name}
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className={styles.tagTimes}
              onClick={() => {
                handleRemoveTag(tag.name);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default TagList;
