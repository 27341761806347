import React from "react";
import styled, { keyframes } from "styled-components";
import { colorAnimation, extendAnimation } from "./textFieldHelpers.js";

import ErrorText from "./ErrorText";

const focus = (fromColor, toColor) =>
  keyframes`${colorAnimation(fromColor, toColor)}`;

const extend = width => keyframes`${extendAnimation(width)}`;

const StyledDiv = styled.div`
  animation: ${({ width }) => extend(width)} 0.5s;
  width: ${({ width }) => width};
`;

const StyledTextField = styled.input`
  border: none;
  border-bottom: 1.5px solid
    ${({ noBorder, error, inverted }) =>
      noBorder ? "none" : error ? "red" : inverted ? "white" : "#a2a2a2"};
  border-radius: 0px;
  font-size: 115%;
  outline: none;
  cursor: text;
  background: none;
  width: 100%;
  color: ${({ theme, inverted, textColor = theme.darkGrey }) =>
    inverted ? "white" : textColor};
  transition: all 0.5s;

  &:focus {
    animation: ${({
        theme,
        error,
        highlightColor = error ? "red" : theme.main
      }) => focus("#dedede", highlightColor)}
      0.5s;
    border-bottom: 2px solid
      ${({ theme, error, highlightColor = error ? "red" : theme.main }) =>
        highlightColor};
  }
`;

const StyledTextHeading = styled.p`
  font-weight: 600;
  margin: 0 auto;
  text-align: left;
  font-size: 115%;
  color: ${({
    theme,
    inverted,
    headingColor = inverted ? "white" : theme.darkGrey
  }) => headingColor};
`;

const TextField = ({
  width = "12em",
  disabled,
  password,
  placeholder,
  heading,
  onChange,
  error,
  value,
  name = "",
  noBorder = false,
  ...props
}) => (
  <div style={{ width: "100%" }}>
    <StyledTextHeading {...props}>{heading}</StyledTextHeading>
    <StyledDiv width={width}>
      {value ? (
        <StyledTextField
          {...props}
          name={name}
          value={value}
          disabled={disabled}
          type={password ? "password" : "text"}
          autoComplete="new-password"
          placeholder={placeholder}
          onChange={evt => onChange(evt)}
          error={error}
          noBorder={noBorder}
        />
      ) : (
        <StyledTextField
          {...props}
          name={name}
          disabled={disabled}
          autoComplete="new-password"
          type={password ? "password" : "text"}
          placeholder={placeholder}
          onChange={evt => onChange(evt)}
          error={error}
          noBorder={noBorder}
        />
      )}
    </StyledDiv>
    {error && <ErrorText error={error} />}
  </div>
);

export default TextField;
