import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../../artzu/src/Table";
import Sortable from "./Sortable";
import styles from "./header.module.scss";
import RowSelect from "../../CellValues/RowSelect";
import _ from "lodash";

const Header = ({
  headers,
  changeSort,
  currentSort,
  tabs,
  changeSelectedTab,
  selectedRows,
  selectAllRows,
  isAllSelected,
  popupSpecific,
  tableName,
  hasTabs,
}) => {
  const selectable = !popupSpecific;

  const setMargin = (header) => {
    if (header === "user") {
      return "0 0 0 30px";
    } else if (header === "category" || header === "details") {
      return "0 0 0 60px";
    } else {
      return "0";
    }
  };

  const dividerBorder = (hasTabs) =>  {
    if (hasTabs) {
      return ""
    }
    else {
      return "1px solid #414141"
    }
  }

  return (
    <div>
      <div className={styles.header}>
        <TableRow
          padding="0.8em 0.8em 0.8em 0.5em"
          align="center"
          minHeight="64px"
          minWidth={"100%"}
          color="#FFFFFF"
          justifyIpad="space-between"
          borderB={dividerBorder(hasTabs)}
          justify={
            tableName !== "timeline_entries" ? "space-between" : "flex-start"
          }
        >
          {selectable && (
            <TableCell width="64px">
              {/* <RowSelect onClick={selectAllRows} isSelected={isAllSelected} /> */}
            </TableCell>
          )}
          {headers.map((header, index) => {
            return (
              <TableCell
                width="140px"
                margin={setMargin(header.name)}
                key={index}
              >
                <Sortable
                  displayedName={header.displayedName}
                  changeSort={changeSort}
                  name={header.name}
                  currentSortColumn={currentSort.column}
                  currentSortDirection={currentSort.direction}
                />
              </TableCell>
            );
          })}
        </TableRow>
      </div>
    </div>
  );
};

export default Header;
