import _ from "lodash";
import snakeToCamelCase from "../../../utils/DataManipulation/snakeToCamelCase";

const processTableData = (tableData) => {
  const columnReducer = (acc, data) => {
    const value =
      data.dataType === "json"
        ? snakeToCamelCase(JSON.parse(data.value))
        : data.value;

    const individualData = {
      value: value,
      dataType: data.dataType,
    };
    const accessor = _.camelCase(data.dataName);
    acc[accessor] = individualData;
    return acc;
  };

  return tableData.map((row) => ({
    rowData: row.rowData.map((column) => ({
      columnName: _.camelCase(column.columnName),
      columnData: column.columnData.reduce(columnReducer, {}),
    })),
    rowId: row.rowId,
    rowLink: row.rowLink,
    rowTimeZone: row.rowTimeZone,
  }));
};

export { processTableData };
