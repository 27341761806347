import React from "react";
import DatePicker from "react-datepicker";
import DateInput from "../../../../../MapView/ModeSelect/DateInput";
import { m } from "../../../../../../utils";
import styles from "../changeStatus.module.scss";

const SelectDateTime = ({ dateTime, name, handleChange }) => {
  return (
    <div className={styles.dateSelection}>
      <div className={styles.fieldsComponentHeader}>{name}:</div>
      <DatePicker
        customInput={
          <DateInput className={"custom-time-select"} date={dateTime || m()} />
        }
        selected={dateTime && m(dateTime)}
        onChange={handleChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="LLL"
        timeCaption="time"
        todayButton={"Today"}
      />
    </div>
  );
};
export default SelectDateTime;
