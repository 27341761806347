import React from "react";
import NewServiceEvent from "../../Mutations/NewServiceEvent";
import UpdateServiceEvent from "../../Mutations/UpdateServiceEvent";
import "./service.css";

const SaveChanges = ({
  action,
  params,
  refetch,
  clear,
  addErrors,
  prevOdometerReading,
  openConfirmModal
}) => (
  <div>
    {action === "new" ? (
      <NewServiceEvent
        addErrors={addErrors}
        params={params}
        clear={clear}
        refetch={refetch}
        prevOdometerReading={prevOdometerReading}
        openConfirmModal={openConfirmModal}
      />
    ) : (
      <UpdateServiceEvent
        addErrors={addErrors}
        params={params}
        clear={clear}
        refetch={refetch}
        prevOdometerReading={prevOdometerReading}
        openConfirmModal={openConfirmModal}
      />
    )}
  </div>
);

export default SaveChanges;
