import React from "react";
import { TableCell, TableRow } from "../../../../../../artzu/src/Table";

import styles from "./changesTable.module.scss";

const Header = () => {
  const headers = ["Vehicle", "Category", "Before", "After", ""];
  return (
    <>
      <TableRow
        padding="12px 22px 12px 0em"
        align="center"
        minHeight="38px"
        minWidth={"100%"}
        color="none"
        justifyIpad="space-between"
        justify="space-evenly"
      >
        {headers.map(header => (
          <TableCell width="140px">
            <div className={styles.headerData}>{header}</div>
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default Header;
