import React from "react";
import Button from "../../../../artzu/src/Button";

const VehicleInfoTitle = ({
  isEdit,
  toggleEdit,
  updateVehicleInfo,
  updateLockboxCode,
  toggleRefresh,
}) => {
  return (
    <div className="details-upper-left-title">
      Vehicle info
      {isEdit ? (
        <div className="edit-mode-buttons">
          {" "}
          <div className="cancel-info-edit">
            <Button
              fullWidth="82px"
              height="33px"
              radius="17px"
              border="1px solid #414141"
              color="#414141"
              padding="7px 16px 7px 16px"
              label="Cancel"
              onClick={toggleEdit}
            />
          </div>
          <Button
            fullWidth="82px"
            height="33px"
            radius="17px"
            border="1px solid #128DFF"
            color="#128DFF"
            padding="7px 16px 7px 16px"
            label="Save"
            onClick={() => {
              toggleEdit();
              updateVehicleInfo();
              updateLockboxCode();
              toggleRefresh();
            }}
          />
        </div>
      ) : (
        <Button
          fullWidth="91px"
          height="33px"
          radius="17px"
          border="1px solid #128DFF"
          color="#128DFF"
          padding="7px 16px 7px 16px"
          label="Edit info"
          onClick={toggleEdit}
        />
      )}
    </div>
  );
};

export default VehicleInfoTitle;
