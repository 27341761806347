import React from "react";
import styles from "./filterRow.module.scss";
import Dropdown from "./Dropdown";
import ValuesInput from "./ValuesInput";
import _ from "lodash";

const Actions = ({ filter, availableFilters, onEdit }) => {
  const actionElements = transformDataToActions(
    filter,
    availableFilters,
    onEdit
  );

  const elements = actionElements.map((value) => {
    const Component = value.component;

    return <Component {...value.props} />;
  });

  return <div className={styles.actions}>{elements}</div>;
};

const transformDataToActions = (filter, availableFilters, onEdit) => {
  const filterErrors = filter.errors || {};
  const availableFilterObject = availableFilters.reduce(
    (output, value) => ({
      ...output,
      [value.categoryUuid]: value,
    }),
    {}
  );

  const categoryItems = availableFilters.map((f) => ({
    value: f.categoryUuid,
    name: f.categoryName,
  }));

  const ruleItems = [
    {
      value: true,
      name: "Exclude",
    },
    {
      value: false,
      name: "Include",
    },
  ];

  const possibleValuesForFilter = availableFilterObject[filter.categoryUuid];

  const operatorItems =
    possibleValuesForFilter &&
    possibleValuesForFilter.operators.map(({ displayedName, uuid }) => ({
      value: uuid,
      name: displayedName,
    }));

  const dataType = possibleValuesForFilter && possibleValuesForFilter.dataType;

  const selectedOperator =
    filter.uuid &&
    _.find(
      possibleValuesForFilter.operators,
      (value) => filter.uuid == value.uuid
    );

  const actionItems = [
    {
      component: Dropdown,
      props: {
        initialValue: filter.isExcluded,
        items: ruleItems,
        onChange: (value) => onEdit({ isExcluded: value }),
        error: filterErrors["isExcluded"],
        label: "Rule",
      },
      isShown: true,
    },
    {
      component: Dropdown,
      props: {
        initialValue: filter.categoryUuid,
        items: categoryItems,
        onChange: (value) =>
          onEdit({ values: null, uuid: null, categoryUuid: value }),
        error: filterErrors["categoryUuid"],
        label: "Column",
      },
      isShown: true,
    },
    {
      component: Dropdown,
      props: {
        initialValue: filter.uuid,
        items: operatorItems,
        onChange: (value) => onEdit({ values: null, uuid: value }),
        error: filterErrors["uuid"],
        label: "Value",
      },
      isShown: operatorItems,
    },
    {
      component: ValuesInput,
      props: {
        filter: filter,
        onEdit: (values) => onEdit({ values }),
        selectedOperator: selectedOperator,
        error: filterErrors["values"],
        dataType: dataType,
      },
      isShown: selectedOperator,
    },
  ];

  return actionItems.filter((action) => action.isShown);
};

export default Actions;
