import React, { useState, useEffect } from "react";
import { createConsumer } from "@rails/actioncable";
import { useRef } from "react";
import { Fragment } from "react";
import { useMutation } from "@apollo/client";
import Message from "./Message";
import styles from "../task.module.scss";
import InputContainer from "./InputContainer";
import { ASSIGN_TASK } from "../queries";

const ChatBox = ({ params, taskData, refreshTaskCallback, currentAdmin }) => {
  const [messages, setMessages] = useState([]);
  const [curSubs, setCurSubs] = useState();

  const cable = useRef();
  useEffect(() => {
    if (!cable.current) {
      cable.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}`);
    }
    const paramsToSend = {
      channel: "TaskChatChannel",
      taskId: params.id,
      userEmail: currentAdmin && currentAdmin.email,
    };
    const handlers = {
      connected: () => {
        console.log("connected");
      },
      disconnected: () => {
        console.log("disconnected");
        cable.current = null;
      },
      received: (data) => {
        console.log("data from backend: " + data.message);
        setMessages([...messages, data]);
      },
    };

    const subscription = cable.current.subscriptions.create(
      paramsToSend,
      handlers
    );

    setCurSubs(subscription);

    return function cleanup() {
      cable.current = null;
      subscription.unsubscribe();
    };
  }, [params.id, messages]);

  const handleSend = (childData) => {
    curSubs.send({
      identifier: curSubs.identifier,
      message: childData,
    });
    refreshTaskCallback({ taskId: params.id });
  };
  const historyMessages = taskData ? taskData.messages : [];

  useEffect(() => {
    const objDiv = document.getElementById("chat-box");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [historyMessages, messages]);

  const accidentReport = taskData?.accidentReport;

  const [assignTaskMutation, { loading: isAssignLoading }] = useMutation(
    ASSIGN_TASK
  );
  const assignTask = () => {
    assignTaskMutation({
      variables: {
        taskId: taskData?.id,
        adminUserId: currentAdmin.id,
      },
    }).then(({ data: { assignTask } }) => {
      if (assignTask.task) {
        refreshTaskCallback({ taskId: assignTask.task.id });
        console.log("Assign Succeed!");
      } else {
        console.log("Assign error!");
      }
    });
  };

  return (
    <Fragment>
      <div id="chat-box" className={styles.chatContainer}>
        <Message
          messages={messages}
          historyMsg={historyMessages}
          refreshTaskCallback={refreshTaskCallback}
          taskId={params.id}
          taskData={taskData}
          currentAdmin={currentAdmin}
          accidentReport={accidentReport}
        />
      </div>
      <InputContainer
        handleSend={handleSend}
        taskData={taskData}
        refreshTaskCallback={refreshTaskCallback}
        assignTask={assignTask}
      />
    </Fragment>
  );
};

export default ChatBox;
