import React, { useState } from "react";
import { GET_TIMELINE_EVENTS } from "./queries";
import { useQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../artzu/src/Table";
import TimelineFieldCellValue from "./TimelineFieldCellValue";
import moment from "moment-timezone";
import TimelineDateFilter from "./TimelineDateFilter";
import { useEffect } from "react";

const ParkingSpotTimeline = ({ timelineData }) => {
  const [timelineItems, setTimlineItems] = useState(timelineData);
  const [startDate, setCurStartDate] = useState();
  const [endDate, setCurEndDate] = useState();

  useEffect(() => {
    timelineData && setTimlineItems(timelineData);
  }, [timelineData]);

  useEffect(() => {
    if (startDate && endDate) {
      let filteredData = timelineData ? [...timelineData] : [];
      filteredData = filteredData.filter(
        (item) =>
          moment(item.capturedAt) > startDate &&
          moment(item.capturedAt) < endDate
      );
      setTimlineItems(filteredData);
    }
  }, [startDate, endDate]);
  return (
    <div>
      <TimelineDateFilter
        curStart={startDate}
        curEnd={endDate}
        setCurStartDate={setCurStartDate}
        setCurEndDate={setCurEndDate}
      />
      <Table>
        <TableHead>
          <TableRow
            padding="0.8em"
            align="center"
            width="100%"
            minHeight="64px"
            color="#FFFFFF"
            justifyIpad="space-between"
            borderB="1px solid #414141"
          >
            <TableCell width={"20%"}>Time stamp</TableCell>
            <TableCell width={"20%"}>Status</TableCell>
            <TableCell width={"20%"}>License plate</TableCell>
            <TableCell width={"20%"}>Related booking</TableCell>
            <TableCell width={"20%"}>By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timelineItems &&
            timelineItems.map((row) => (
              <TableRow
                padding="0.8em"
                align="center"
                width="100%"
                minHeight="58px"
                color="#FFFFFF"
                justifyIpad="space-between"
                borderT="1px solid #dedede"
              >
                <TimelineFieldCellValue
                  text={"Time stamp"}
                  value={row.capturedAt}
                />
                <TimelineFieldCellValue text={"Status"} value={row.status} />
                <TimelineFieldCellValue
                  text={"License plate"}
                  value={row.licensePlate}
                  link={row.plateLink}
                />
                <TimelineFieldCellValue
                  text={"Related booking"}
                  value={row.relatedBooking}
                  link={row.bookingLink}
                />
                <TimelineFieldCellValue
                  text={"By"}
                  value={row.origin}
                  link={row.originLink}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ParkingSpotTimeline;
