import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { m } from "../../../utils";

import TimeSelect from "../../MapView/ModeSelect/TimeSelect";
import PrimaryCheckupList from "./PrimaryCheckupList";
import CleaningCheckupList from "./CleaningCheckupList";
import DashboardCheckupList from "./DashboardCheckupList";
import VehicleDocumentCheckupList from "./VehicleDocumentCheckupList";
import TireCheckupList from "./TireCheckupList";
import InspectionNotes from "./InspectionNotes";

import classNames from "classnames";

import "../service.css";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: "solid 1px",
    borderRadius: "5px",
    borderColor: theme.palette.grey.light
  }
});

const ServiceEventInputs = ({
  data,
  onEdit,
  classes,
  fleetManager,
  vehicleId
}) => (
  <div className={classNames(classes.root, "inspection-input")}>
    <div className={`inspection-completed ${!fleetManager ? "invisible" : ""}`}>
      <TimeSelect
        label="Completed"
        name="completedDate"
        updateTime={onEdit}
        markedDate={data.completedDate || m()}
        serviceEvent={true}
        show={true}
      />
    </div>
    <PrimaryCheckupList data={data} onEdit={onEdit} vehicleId={vehicleId} />
    <CleaningCheckupList data={data} onEdit={onEdit} />
    <DashboardCheckupList data={data} onEdit={onEdit} />
    <VehicleDocumentCheckupList data={data} onEdit={onEdit} />
    <TireCheckupList data={data} onEdit={onEdit} />
    <InspectionNotes data={data} onEdit={onEdit} />
  </div>
);

export default withStyles(styles)(ServiceEventInputs);
