import React from "react";
import CircleImage from "../../MapView/CircleImage";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import { m, status, capitalize, spreadMapData } from "../../../utils";
import "./../vehicle.scss";
import "../../MapView/booking.scss";
import { Link } from "react-router-dom";
import Button from "../../../artzu/src/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../Fleet/table.module.scss";
import stylesB from "./bookingSandwich.module.scss";
import classNames from "classnames";
import offline from "../../../images/Offline.png";

const size = "2x";
const vehicleStatus = status => {
  switch (status) {
    case "service":
      return "black";
    case "available":
      return "2px solid #128DFF";
    case "deactivated":
      return "2px solid #FF0000";
    case "offline":
      return "2px solid #414141";
    case "user":
      return "#128DFF";
    default:
      return "";
  }
};

const BookingDetail = ({
  booking,
  title,
  showLocation,
  vehicle,
  classes,
  mapView,
  operationStatus,
  currentUser,
  openModal,
  options,
  index,
  logNum
}) => (
  <div
    className="booking-table-container"
    style={mapView ? { margin: "0" } : null}
  >
    {booking && booking.bookingType ? (
      <TableRow
        justify="space-between"
        align="center"
        // hover={true}
        // hoverColor="#DEDEDE"
        margin="0"
        padding="0"
      >
        <TableCell width={mapView ? "100%" : `${100 / 3}%`}>
          <div
            className={classNames(styles.statusLegend)}
            style={{
              paddingTop: "0",
              alignItems: "flex-start"
            }}
          >
            <div
              style={{
                display: "block",
                height: "100%",
                width: "fit-content",
                textAlign: "center"
              }}
            >
              <div
                className="operation-symbol"
                style={
                  ["available", "deactivated", "offline"].includes(
                    booking.bookingType
                  )
                    ? {
                        border: vehicleStatus(booking.bookingType),
                        margin: "0"
                      }
                    : {
                        background: vehicleStatus(booking.bookingType),
                        margin: "0"
                      }
                }
              />
              <div
                style={{
                  width: "1px",
                  height: "65px",
                  backgroundColor: "#DEDEDE",
                  margin: "auto"
                }}
              />
            </div>
            <div
              style={{
                height: "100%",
                marginLeft: "6px",
                width: "-webkit-fill-available"
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className={classNames("operation-status")}
                    style={{ marginTop: "0" }}
                  >
                    {booking.bookingType === "user"
                      ? "With Driver"
                      : capitalize(booking.bookingType)}
                  </div>
                  {mapView ? (
                    <div
                      style={{
                        margin: "0 0 0 0"
                      }}
                    >
                      -{" "}
                      <a
                        href={booking.profile}
                        style={{
                          textDecoration: "none",
                          color: "#414141",
                          fontSize: "16px"
                        }}
                        target="_blank"
                      >
                        {["user"].includes(booking.bookingType)
                          ? booking.driverName
                          : booking.user
                          ? booking.user.firstName + " " + booking.user.lastName
                          : booking.driverName}
                      </a>
                    </div>
                  ) : null}

                  {booking.bookingType !== "available" ? (
                    <div>
                      {options && logNum === index ? (
                        <div style={{ height: "0", width: "0" }}>
                          <div
                            className={stylesB.serviceOptionsBox}
                            style={{
                              height: "117px",
                              paddingTop: "18px",
                              paddingBottom: "18px",
                              left: "-110px",
                              top: "-120px"
                            }}
                          >
                            <div className={stylesB.serviceOptions}>
                              {vehicle.lastGps ? (
                                <Link
                                  className="map-link"
                                  style={{
                                    textDecoration: "none",
                                    color: "#414141",
                                    fontSize: "16px"
                                  }}
                                  to={`/map`}
                                >
                                  <div
                                    onClick={() =>
                                      showLocation(spreadMapData([vehicle])[0])
                                    }
                                  >
                                    View Map
                                  </div>
                                </Link>
                              ) : null}
                            </div>
                            <div className={stylesB.serviceOptions}>
                              <a
                                href={booking.profile}
                                style={{
                                  textDecoration: "none",
                                  color: "#414141",
                                  fontSize: "16px"
                                }}
                                target="_blank"
                              >
                                {booking.bookingType === "user"
                                  ? "View Booking"
                                  : "View Service"}
                              </a>
                            </div>
                            <div className={stylesB.serviceOptions}>
                              <a
                                href={booking.profile}
                                style={{
                                  textDecoration: "none",
                                  color: "#414141",
                                  fontSize: "16px"
                                }}
                                target="_blank"
                              >
                                {["user"].includes(booking.bookingType)
                                  ? "Call Driver"
                                  : "Call Vendor"}
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <Button
                        fullWidth="15px"
                        height="15px"
                        backColor={
                          options && logNum === index ? "#414141" : null
                        }
                        color={
                          options && logNum === index ? "#FFFFFF" : "#414141"
                        }
                        border="1px solid #414141"
                        label={<div>&#8942;</div>}
                        size="12px"
                        weight="bold"
                        margin={mapView ? "0" : "0 26px 0 0px"}
                        padding="0"
                        radius="50%"
                        onClick={evt => {
                          evt.preventDefault();
                          openModal(index);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                <div>
                  {booking.endTime ? (
                    <div
                      className={styles.fleetGrey}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Exited: {m(booking.endTime).format("hA")}
                      {", "}
                      {m(booking.endTime).format("ll")}
                    </div>
                  ) : (
                    <div
                      className={styles.fleetGrey}
                      style={{ textAlign: "left" }}
                    >
                      Exited: N/A
                    </div>
                  )}
                  {booking.startTime ? (
                    <div
                      className={styles.fleetGrey}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Entered: {m(booking.startTime).format("hA")}
                      {", "}
                      {m(booking.startTime).format("ll")}
                    </div>
                  ) : (
                    <div
                      className={styles.fleetGrey}
                      style={{ textAlign: "left" }}
                    >
                      N/A
                    </div>
                  )}
                  {mapView ? (
                    <div
                      className={styles.fleetGrey}
                      style={{ textAlign: "left", color: "#414141" }}
                    >
                      {`Comments - ${booking.comments ? booking.comments : ""}`}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </TableCell>

        {mapView ? null : (
          <TableCell width={`${100 / 3}%`}>
            <div style={{ height: "80px", paddingRight: "12px" }}>
              {["available"].includes(booking.bookingType) ? null : (
                <div
                  className={styles.statusLegend}
                  style={{
                    paddingTop: "0 "
                  }}
                >
                  <div>
                    <a
                      href={booking.profile}
                      style={{
                        textDecoration: "none"
                      }}
                      target="_blank"
                    >
                      <CircleImage
                        imageLink={
                          ["user"].includes(booking.bookingType)
                            ? booking.driverImage
                            : ["service"].includes(booking.bookingType) &&
                              booking.user
                            ? booking.user.imageLink
                            : booking.driverImage
                        }
                        width="34px"
                        height="34px"
                        isPrev={false}
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      margin: "0 0 0 8px"
                    }}
                  >
                    <a
                      href={booking.profile}
                      style={{
                        textDecoration: "none",
                        color: "#414141",
                        fontSize: "16px"
                      }}
                      target="_blank"
                    >
                      {["user"].includes(booking.bookingType)
                        ? booking.driverName
                        : booking.user
                        ? booking.user.firstName + " " + booking.user.lastName
                        : booking.driverName}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </TableCell>
        )}

        {mapView ? null : (
          <TableCell width={`${100 / 3}%`}>
            <div style={{ height: "80px" }}>
              <div
                className={styles.statusLegend}
                style={{
                  paddingTop: "0",
                  alignItems: "center",
                  height: "34px"
                }}
              >
                <div style={{ color: "#414141", fontSize: "16px" }}>
                  {booking.comments}
                </div>
              </div>
            </div>
          </TableCell>
        )}
      </TableRow>
    ) : null}
  </div>
);

export default BookingDetail;
