import gql from "graphql-tag";
const UPDATE_VEHICLE_DETAILS = gql`
  mutation updateVehicleDetails(
    $vehicleId: ID!
    $parkingSpotIdentifier: String
    $parkingLotId: ID
  ) {
    updateVehicleDetails(
      vehicleId: $vehicleId
      newParkingSpotIdentifier: $parkingSpotIdentifier
      newParkingLotId: $parkingLotId
    ) {
      success
    }
  }
`;

const UPDATE_LOCKBOX_CODE = gql`
  mutation updateLockboxCode($lockboxCode: String!, $vehicleId: ID!) {
    updateLockboxCode(vehicleId: $vehicleId, lockboxCode: $lockboxCode) {
      success
      error
    }
  }
`;

const GET_AVAILABLE_SPOTS = gql`
  query getAllSpotsAtHub($parkingLotId: ID!) {
    getAllSpotsAtHub(parkingLotId: $parkingLotId) {
      id
      identifier
    }
  }
`;

export { UPDATE_VEHICLE_DETAILS, UPDATE_LOCKBOX_CODE, GET_AVAILABLE_SPOTS };
