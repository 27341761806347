import React, { Component } from "react";
import { observer } from "mobx-react";
import GoogleMap from "./GoogleMap";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import VehicleDrawer from "./VehicleDrawer";
import moment from "moment-timezone";
import { snapPoints, getSnappedGps, getGpsSet } from "../../utils/gpsFuncs.js";
import { historyQuery, parkingLotsQuery } from "../../utils/queryFuncs.js";
import { formatNavTitle } from "../../utils/index.js";
import _ from "lodash";
import "./coordinates.css";

const styles = (theme) => ({
  hide: { display: "none" },
  "navBuffer-fleet": {},
  "navBuffer-history": {},
  modeSelect: {
    zIndex: theme.zIndex.historyOptions,
  },
  slider: { height: theme.sliderHeight },
  mapOpen: {
    marginLeft: "444px",
  },
  mapClosed: {
    marginLeft: "444px",
  },
  mapLoc: {
    marginLeft: "344px",
  },
});

const FleetView = observer(
  class FleetView extends Component {
    constructor() {
      super();
      this.state = {
        open: true,
        value: 0,
        sliderValue: 50,
        timeFramePM: 1,
        historyTimeFrame: {
          startTime: moment(),
          endTime: moment(),
        },
        historyLoaded: false,
        snappedGps: null,
        getVehicle: null,
        parkingLots: [],
        hideLoc: false,
      };
    }

    handleChange = (event, value) => {
      this.setState({ value });
    };

    openDrawer = () => {
      this.setState({ open: true });
    };

    closeDrawer = () => {
      this.setState({ open: false });
    };

    updateTime = (name, value) => {
      this.setState((state) => {
        let newTimeFrame = state.historyTimeFrame;
        newTimeFrame[name] = value;
        return { newTimeFrame };
      });
    };

    toggleHideLoc = (value) => {
      this.setState({
        hideLoc: value,
      });
    };

    startLoad = () => {
      this.setState({ historyLoaded: false }, () => {
        console.log(this.state.historyTimeFrame);
        this.getHistoryCoords(this.props.store, this.state.historyTimeFrame);
      });
    };

    getHistoryCoords = async (store, timeFrame) => {
      const { getVehicle } = await historyQuery(
        store,
        timeFrame,
        this.props.client
      );

      console.log(getVehicle);
      const newState = {
        snappedGps: {
          historyGps: getVehicle.historyGps.interpolatedGps,
        },
        getVehicle: {
          historyGps: _.orderBy(
            getVehicle.historyGps.capturedGps,
            "capturedAt",
            "asc"
          ),
        },
        historyLoaded: true,
      };
      console.log("newState", newState);
      this.setState(newState);
    };

    //Updates slider value statex
    updateSlider = (event, value) => {
      this.setState({ sliderValue: value });
    };

    resetHistoryMode = () => {
      this.setState({
        historyTimeFrame: {
          startTime: moment(),
          endTime: moment(),
        },
      });
    };

    componentDidCatch(error, info) {
      console.log(error, info);
    }

    componentDidMount() {
      const { store, history } = this.props;
      store.changeMode("current");
    }

    render() {
      const {
        store,
        bStore,
        classes,
        serviceLocations,
        parkingLots,
        currentUser,
        history,
      } = this.props;

      const { historyTimeFrame, hideLoc } = this.state;
      const data = store.filteredVehicles;

      let vehicleDataSet, currentTime;

      if (this.state.historyLoaded) {
        const { store } = this.props;

        const {
          sliderValue,
          getVehicle: { historyGps },
        } = this.state;

        const { vehicleData, targetMoment } = getGpsSet(
          historyGps,
          sliderValue,
          store.selectedVehicle
        );

        vehicleDataSet = vehicleData;
        currentTime = targetMoment;
      } else {
        vehicleDataSet = null;
      }

      const vehicles =
        store.mode === "history" && this.state.historyLoaded
          ? vehicleDataSet
          : data.filter((c) => c.lastGps);

      const presetParkingLots = JSON.parse(localStorage.getItem("parkingLots"));
      const presetParkingCities = JSON.parse(
        localStorage.getItem("parkingCities")
      );
      const presetParkingLotIds = _.map(presetParkingLots, "id");

      const vehiclesAtPresetLocation = Array.isArray(vehicles)
        ? vehicles.filter(
            (vehicle) =>
              presetParkingLotIds &&
              presetParkingLotIds.includes(vehicle.parkingLot.id)
          )
        : vehicles;

      const filteredServiceLocationsInStore = store.filterServiceLocations(
        serviceLocations
      );

      const parkingLotsAtPresetLocation = parkingLots.filter((lot) =>
        presetParkingLotIds.includes(lot.id)
      );

      const serviceLocationsAtPresetLocation = filteredServiceLocationsInStore.filter(
        (location) => presetParkingCities.includes(location.city.name)
      );

      return (
        <div
          className="getCoordinatesContainer"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className={classes["navBuffer-fleet"]} />
          <div className="car-detail-container">
            <VehicleDrawer
              store={store}
              bStore={bStore}
              open={this.state.open || store.selectedVehicle !== "none"}
              data={data}
              openDrawer={this.openDrawer}
              closeDrawer={this.closeDrawer}
              resetHistoryMode={this.resetHistoryMode}
              toggleHide={this.toggleHideLoc}
              history={history}
            />
            <main
              className={classNames(
                "mapContainer",
                (this.state.open || store.selectedVehicle !== "none") &&
                  hideLoc === false
                  ? classes.mapOpen
                  : store.selectedVehicle === "none" && hideLoc
                  ? classes.mapLoc
                  : classes.mapClosed
              )}
            >
              <GoogleMap
                store={store}
                bStore={bStore}
                vehicles={
                  store.mode === "history" && !this.state.historyLoaded
                    ? []
                    : vehiclesAtPresetLocation
                }
                polylineCoords={this.state.snappedGps}
                loaded={this.state.historyLoaded}
                parkingLots={parkingLotsAtPresetLocation}
                serviceLocations={serviceLocationsAtPresetLocation}
                sliderValue={this.state.sliderValue}
                updateSlider={this.updateSlider}
                currentTime={currentTime}
                updateTime={this.updateTime}
                startLoad={this.startLoad}
                startTime={historyTimeFrame.startTime}
                endTime={historyTimeFrame.endTime}
                history={history}
              />
            </main>
          </div>
        </div>
      );
    }
  }
);

export default withStyles(styles)(FleetView);
