import React, { useState } from "react";
import moment from "moment";
import { useMutation } from "@apollo/client";
import Loading from "../../../../Fleet/Loading";
import Content from "./Content";
import { CHANGE_STATUS } from "../../gqlStrings";

const ChangeStatus = ({ newStatus, vehicle, closeModal }) => {
  const [endDateSelected, toggleEndDateSelected] = useState(false);
  const defaultEndTime = moment().add(1, "hour");

  const [mutationErrors, setMutationErrors] = useState(null);

  const [statusArgs, setStatusArgs] = useState({
    startTime: moment(),
    endTime: defaultEndTime,
    comments: "",
    cancelUserReservations: false,
    statusType: newStatus,
    vehicleId: vehicle.id
  });

  const changeStatusArgs = (key, value) => {
    console.log("HERE");
    const copyOfStatus = { ...statusArgs };
    copyOfStatus[key] = value;
    setStatusArgs(copyOfStatus);
  };

  const [changeStatusMutation, { loading: mutationLoading }] = useMutation(
    CHANGE_STATUS
  );

  const changeStatus = (cancelUserReservations = false) => {
    const statusArgsCopy = { ...statusArgs, cancelUserReservations };
    if (!endDateSelected) {
      statusArgsCopy["endTime"] = moment().add(1, "year");
    }
    changeStatusMutation({
      variables: statusArgsCopy,
      refetchQueries: [`getVehicle`]
    }).then(({ data: { changeStatusInterval } }) => {
      if (changeStatusInterval.success) {
        closeModal();
      } else {
        setMutationErrors(changeStatusInterval.bookingErrors);
      }
    });
  };

  return (
    <div>
      {mutationLoading ? (
        <Loading />
      ) : (
        <Content
          mutationErrors={mutationErrors}
          changeStatus={changeStatus}
          statusArgs={statusArgs}
          changeStatusArgs={changeStatusArgs}
          closeModal={closeModal}
          toggleEndDateSelected={toggleEndDateSelected}
          endDateSelected={endDateSelected}
        />
      )}
    </div>
  );
};

export default ChangeStatus;
