import styles from "../changeStatus.module.scss";
import ToggleEndDate from "./ToggleEndDate";
import Details from "./Details";
import Errors from "./Errors";
import React from "react";
import TimeBounds from "./TimeBounds";

const Fields = ({
  changeStatusArgs,
  statusArgs: { startTime, endTime, comments },
  fieldErrors,
  toggleEndDateSelected,
  endDateSelected
}) => (
  <>
    <div className={styles.dates}>
      <TimeBounds
        startTime={startTime}
        endTime={endTime}
        changeStatusArgs={changeStatusArgs}
        showEndTime={endDateSelected}
      />
      <ToggleEndDate
        endDateSelected={endDateSelected}
        toggleEndDateSelected={() => toggleEndDateSelected(!endDateSelected)}
      />
    </div>
    <Details
      details={comments}
      handleChange={value => {
        console.log("HIER");
        changeStatusArgs("comments", value);
      }}
    />
    <Errors errors={fieldErrors} />
  </>
);

export default Fields;
