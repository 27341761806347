import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLockAlt,
  faMapMarkedAlt,
  faUnlockAlt,
  faLightbulbOn,
  faVolumeUp,
  faRedo,
  faPlay,
  faStop
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { spreadMapData } from "../../../utils";
import Action from "./Action";
import AvcAction from "./AvcAction";
import Modal from "../../../artzu/src/Modal";

const ActionButtons = ({ vehicle, showLocation }) => (
  <Fragment>
    <div className="header-buttons">
        <Fragment>

            <Fragment>
              <AvcAction
                icon={faLockAlt}
                action={"lock"}
                vehicleId={vehicle.id}
              />
              <AvcAction
                icon={faUnlockAlt}
                action={"unlock"}
                vehicleId={vehicle.id}
              />
              <AvcAction
                icon={faStop}
                action={"immobilize"}
                vehicleId={vehicle.id}
              />
              <AvcAction icon={faPlay} action={"free"} vehicleId={vehicle.id} />
            </Fragment>

        </Fragment>
      <div className="map-link-button">
        {vehicle.lastGps ? (
          <Link className="map-link" to={`/map`}>
            <div onClick={() => showLocation(spreadMapData([vehicle])[0])}>
              <FontAwesomeIcon icon={faMapMarkedAlt} color="#128DFF" />
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  </Fragment>
);

export default ActionButtons;
