const times = [
  "1AM - 3AM",
  "3AM - 5AM",
  "5AM - 7AM",
  "7AM - 9AM",
  "9AM - 11AM",
  "11AM - 1PM",
  "1PM - 3PM",
  "3PM - 5PM",
  "5PM - 7PM",
  "7PM - 9PM",
  "9PM - 11PM",
  "11PM - 1AM",
];

const getAvailabilityTextColor = (percentage) => {
  if (isNaN(percentage) || percentage <= 5) {
    return "#ff0000";
  }
  if (percentage <= 20) {
    return "#ff8900";
  }
  return "#ffffff";
};

const getUtilizationTextColor = (percentage) => {
  if (percentage <= 20) {
    return "#128dff";
  }
  return "#ffffff";
};

const getServiceTextColor = (percentage) => {
  if (percentage <= 20) {
    return "#414141";
  }
  return "#ffffff";
};

const getAvailabilityBackColor = (percentage) => {
  if (percentage <= 5) {
    return "rgba(255, 0, 0, 0.1)";
  }
  if (percentage <= 20) {
    return "rgba(255, 137, 0, 0.1)";
  }
  if (percentage <= 40) {
    return "rgba(18, 141, 255, 0.4)";
  }
  if (percentage <= 60) {
    return "rgba(18, 141, 255, 0.6)";
  }
  if (percentage <= 80) {
    return "rgba(18, 141, 255, 0.8)";
  }
  if (percentage <= 100) {
    return "rgba(18, 141, 255)";
  }
  return "rgba(255, 0, 0, 0.1)";
};

const getUtilizationBackColor = (percentage) => {
  if (percentage === 0) {
    return "rgba(18, 141, 255, 0.05)";
  }
  if (percentage <= 20) {
    return "rgba(18, 141, 255, 0.2)";
  }
  if (percentage <= 40) {
    return "rgba(18, 141, 255, 0.4)";
  }
  if (percentage <= 60) {
    return "rgba(18, 141, 255, 0.6)";
  }
  if (percentage <= 80) {
    return "rgba(18, 141, 255, 0.8)";
  }
  if (percentage <= 100) {
    return "rgba(18, 141, 255)";
  }
  return;
};

const getServiceBackColor = (percentage) => {
  if (percentage === 0) {
    return "rgba(65, 65, 65, 0.05)";
  }
  if (percentage <= 20) {
    return "rgba(65, 65, 65, 0.2)";
  }
  if (percentage <= 40) {
    return "rgba(65, 65, 65, 0.4)";
  }
  if (percentage <= 60) {
    return "rgba(65, 65, 65, 0.6)";
  }
  if (percentage <= 80) {
    return "rgba(65, 65, 65, 0.8)";
  }
  if (percentage <= 100) {
    return "rgba(65, 65, 65)";
  }
  return;
};

export {
  times,
  getAvailabilityTextColor,
  getAvailabilityBackColor,
  getServiceTextColor,
  getServiceBackColor,
  getUtilizationTextColor,
  getUtilizationBackColor,
};
