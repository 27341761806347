const formatCityName = (city) => {
  switch (city) {
    case "new_york":
      return "NYC";
    case "toronto":
      return "TOR";
    case "san_francisco":
      return "SF";
    case "Multiple Cities":
      return "MULT";
    case "All Cities":
      return "ALL";
  }
};

export default formatCityName;
