import React, { Fragment } from "react";
import styles from "./changeStatus.module.scss";
const ConfirmChange = ({ isWarning, errors }) => {
  return (
    <Fragment>
      {isWarning && <div className={styles.confirmTitle}>WARNING</div>}
      <div className={styles.confirmText}>
        {errors.split("\r").map(e => (
          <div>{e}</div>
        ))}
      </div>
    </Fragment>
  );
};

export default ConfirmChange;
