import { observable, decorate } from "mobx";
import _ from "lodash";

class bookingStore {
	bookings = [
		{
			plate: "BYLE660",
			booked: true,
			driver: {
				first_name: "Brandon",
				last_name: "Day",
				mobile: "123-456-7890",
				img: ""
			},
			parkingLotID: 1
		},
		{
			plate: "CAHR302",
			booked: true,
			driver: {
				first_name: "Jasper",
				last_name: "Ong",
				mobile: "098-765-4321",
				img: ""
			},
			parkingLotID: 1
		},
		{
			plate: "CEHN846",
			booked: false,
			driver: {
				first_name: "",
				last_name: "",
				mobile: "",
				img: ""
			},
			parkingLotID: 2
		},
		{
			plate: "CEHN849",
			booked: true,
			driver: {
				first_name: "Carlos",
				last_name: "Ferreira",
				mobile: "123-456-7890",
				img: ""
			},
			parkingLotID: 2
		}
	];

	currentDivIndex = -1; // clicked table div index

	searchResults = [];

	changeCurrentDivIndex = num => (this.currentDivIndex = num);

	searchFilter = searchVal => {
		const options = searchVal
			? _.filter(this.bookings, val =>
					_.includes(val.plate, searchVal.toUpperCase())
			  )
			: [];
		this.updateResults([...options]);
	};

	refreshResults = () => (this.searchResults = []);

	updateResults = resultsArray => {
		this.searchResults = [];
		resultsArray.forEach(result => {
			const name = result.booked
				? `${result.driver.first_name} ${result.driver.last_name}`
				: "Available";
			this.searchResults.push({
				details: `${result.plate} - ${name}`,
				plate: result.plate
			});
		});
	};
}

decorate(bookingStore, {
	bookings: observable,
	currentDivIndex: observable,
	searchResults: observable
});

const s = (window.bookingStore = new bookingStore());

export default s;
