import React from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import CommandStatus from "../Components/Vehicle/CommandStatus";

const resetIcons = refresh => setTimeout(() => refresh(), 2000);

const PendingCommand = ({ pendingId, refresh, icon, size }) => (
  <div>
    {pendingId ? (
      <Query
        query={gql`
      {getPendingCommand(id: ${Number(pendingId)})}
    `}
        pollInterval={300}
      >
        {({ loading, error, data }) => {
          if (loading || error) {
            return null;
          }
          if (data) {
            switch (data.getPendingCommand.split(" ")[1]) {
              case "SUCCESS":
                resetIcons(refresh);
                return <CommandStatus icon={icon} size={size} color="green" />;
              case "FAIL":
                resetIcons(refresh);
                return <CommandStatus icon={icon} size={size} color="red" />;
              case "TIMEDOUT":
                resetIcons(refresh);
                return <CommandStatus icon={icon} size={size} color="yellow" />;
              default:
                return (
                  <CommandStatus
                    icon={icon}
                    size={size}
                    color="blue"
                    waiting={true}
                  />
                );
            }
          } else {
            return null;
          }
        }}
      </Query>
    ) : null}
  </div>
);

export default PendingCommand;
