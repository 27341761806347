import React, { Fragment } from "react";
import styled from "styled-components";

import ErrorText from "./ErrorText";

const StyledTextHeading = styled.p`
  font-weight: 600;
  margin: 0.5em auto;
  font-size: 115%;
  color: ${({ theme, headingColor = theme.darkGrey }) => headingColor};
`;

const StyledTextBox = styled.textarea`
  min-width: 5em;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  color: ${({ theme, textColor = theme.darkGrey }) => textColor};
  outline: none;
  border: 1px solid ${({ disabled }) => (disabled ? "#dedede" : "#707070")};
  border-radius: 20px;
  font-size: 115%;
  padding: 0.5em;
`;

const TextBox = ({
  width = "100%",
  height = "5em",
  disabled,
  placeholder,
  heading,
  onChange,
  error,
  ...props
}) => (
  <Fragment>
    <StyledTextHeading {...props}>{heading}</StyledTextHeading>
    <StyledTextBox
      {...props}
      disabled={disabled}
      width={width}
      height={height}
      placeholder={placeholder}
      onChange={evt => onChange(evt)}
    />
    {error && <ErrorText error={error} />}
  </Fragment>
);

export default TextBox;
