import React, { Component, Fragment } from "react";
import ReportList from "./ReportList";
import ResolveDamage from "../../Mutations/ResolveDamage";
import DamageHeading from "./DamageHeading";

import "./damage.scss";

class DamageOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolveIds: []
    };
  }

  addToResolve = id => {
    let newIds = this.state.resolveIds;
    newIds.push(Number(id));
    this.setState({ resolveIds: newIds });
  };

  removeFromResolve = id => {
    let newArr = this.state.resolveIds.filter(
      reportId => reportId !== Number(id)
    );
    this.setState({ resolveIds: newArr });
  };

  clearIds = () => this.setState({ resolveIds: [] });

  render() {
    const {
      scope,
      interiorDamage,
      exteriorDamage,
      refetch,
      alterDamageState
    } = this.props;
    const { resolveIds } = this.state;

    return (
      <Fragment>
        <DamageHeading
          scope={scope}
          alterDamageState={alterDamageState}
          text="New"
        />
        <ReportList
          type={scope}
          exterior={scope === "exterior" ? true : false}
          reports={scope === "exterior" ? exteriorDamage : interiorDamage}
          alterDamageState={alterDamageState}
          addToResolve={this.addToResolve}
          removeFromResolve={this.removeFromResolve}
        />

        {resolveIds.length ? (
          <div className="damage-buttons-container margin-space">
            <ResolveDamage
              resolveIds={resolveIds}
              refetch={refetch}
              clear={this.clearIds}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default DamageOverview;
