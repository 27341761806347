import React, { useState } from "react";
import Button from "../../../../../../artzu/src/Button";
import CreateNewTag from "../ManageTagActions/CreateNewTag";
import MergeTag from "../ManageTagActions/MergeTags";
import RenameTag from "../ManageTagActions/RenameTag";

const MutableButton = ({
  triggerNew,
  allTags,
  count,
  itemSelected,
  countSelected,
  upsertTags,
  refetch,
  setItemSelected,
  refetchAddTag,
  entry,
}) => {
  const [isCreate, setIsCreate] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [isMerge, setIsMerge] = useState(false);

  const toggleCreate = () => {
    setIsCreate(!isCreate);
  };
  const toggleRename = () => {
    setIsRename(!isRename);
  };
  const toggleMerge = () => {
    setIsMerge(!isMerge);
  };

  let label = "";
  let color = "#FFFFFF";
  let colorBack = "";
  let onClick;
  if (count === 0) {
    label = "Create";
    colorBack = "#128DFF";
    onClick = toggleCreate;
  } else if (count === 1) {
    label = "Rename";
    colorBack = "#424242";
    onClick = toggleRename;
  } else {
    label = "Merge";
    colorBack = "#FFFFFF";
    color = "#128DFF";
    onClick = toggleMerge;
  }
  return (
    <div>
      <Button
        fullWidth="110px"
        height="34px"
        radius="17px"
        size="16px"
        weight="700"
        border="none"
        color={color}
        backColor={colorBack}
        padding="0 15px 0 15px"
        label={label}
        onClick={onClick}
      />
      <CreateNewTag
        open={isCreate}
        toggleCreate={toggleCreate}
        allTags={allTags}
        upsertTags={upsertTags}
        refetch={refetch}
        refetchAddTag={refetchAddTag}
        entry={entry}
      />
      <RenameTag
        open={isRename}
        toggleRename={toggleRename}
        triggerNew={triggerNew}
        allTags={allTags}
        itemSelected={itemSelected}
        countSelected={countSelected}
        refetch={refetch}
        refetchAddTag={refetchAddTag}
        setItemSelected={setItemSelected}
        entry={entry}
      />
      <MergeTag
        open={isMerge}
        toggleMerge={toggleMerge}
        triggerNew={triggerNew}
        allTags={allTags}
        itemSelected={itemSelected}
        refetch={refetch}
        refetchAddTag={refetchAddTag}
        setItemSelected={setItemSelected}
        entry={entry}
      />
    </div>
  );
};

export default MutableButton;
