import React from "react";
import { TableCell } from "../../../../artzu/src/Table";
import styles from "./statusInfo.module.scss";

const Comments = ({ comments }) => (
  <TableCell width={"100%"}>
    <div className={styles.comments}>{comments}</div>
  </TableCell>
);

export default Comments;
