import React, { useState } from "react";
import styles from "./listPopup.module.scss";
import classNames from "classnames";
import ActionModal from "../../../../../InformationTable/BatchActions/ActionModal";
import {
  faCarAlt,
  faCommentAlt,
  faMapMarkedAlt,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { Fragment } from "react";
import CommandConfirm from "../../../../ActionButtons/CommandConfirm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import MessageBox from "../../../../ActionButtons/MessageBox";

const ListBox = ({
  currentValue,
  listItems,
  vehicle,
  closeList,
  toggleRefresh
}) => {
  const [selectedAction, setSelectedAction] = useState();
  const [changeStatusOpen, setChangeStatusOpen] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [msgOpen, setMsgOpen] = useState();
  const [vehicleUUID, setVehicleUUID] = useState();
  const [vehicleStatus, setVehicleStatus] = useState();

  const AVC_ACTION = gql`
    mutation avcEvent($vehicleId: ID!, $action: String!) {
      avcEvent(vehicleId: $vehicleId, action: $action) {
        command {
          uuid
          status
        }
      }
    }
  `;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleMsgModal = () => {
    setMsgOpen(!msgOpen);
  };

  const close = () => {
    setSelectedAction(null);
  };

  let vehicleList = [];
  vehicleList.push(vehicle);

  const [AvcActionMutation, { loading, error }] = useMutation(AVC_ACTION);

  const sendCommand = () => {
    AvcActionMutation({
      variables: {
        vehicleId: vehicle.id,
        action: selectedAction
      }
    }).then(({ data: { avcEvent } }) => {
      if (avcEvent.command) {
        setVehicleUUID(avcEvent.command.uuid);
        setVehicleStatus(avcEvent.command.status);
        toggleMsgModal();

        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
    toggleModal();
  };

  const onItemClick = value => {
    if (value === "Change status") {
      setSelectedAction("changeStatus");
      setChangeStatusOpen(true);
    } else if (value === "Honk horn") {
      setSelectedAction("honkHorn");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Flash lights") {
      setSelectedAction("flashLights");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Start Vehicle") {
      setSelectedAction("startVehicle");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Wake Up") {
      setSelectedAction("wake_up");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Sentry Mode On") {
      setSelectedAction("sentry_mode_on");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Sentry Mode Off") {
      setSelectedAction("sentry_mode_off");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Valet Mode On") {
      setSelectedAction("valetModeOn");
      setChangeStatusOpen(false);
      toggleModal();
    } else if (value === "Valet Mode Off") {
      setSelectedAction("valetModeOff");
      setChangeStatusOpen(false);
      toggleModal();
    } else {
      setSelectedAction(value.toLowerCase());
      setChangeStatusOpen(false);
      toggleModal();
    }
  };

  const icons = [
    {
      value: "changeStatus",
      text: "Change Status",
      icon: faCarAlt
    }
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  return (
    <Fragment>
      <div className={styles.options}>
        <div className={styles.optionsBox}>
          <div className={styles.optionsContainer}>
            {listItems.map(item => {
              return (
                <div
                  className={styles.optionsItem}
                  onClick={() => onItemClick(item.value)}
                >
                  <div>{item.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {changeStatusOpen && (
        <ActionModal
          isOpen={selectedAction}
          selectedBatchAction={selectedAction}
          batchOptionsHash={batchOptionsHash}
          selections={vehicleList}
          closeModal={close}
          toggleRefresh={toggleRefresh}
          item={"vehicles"}
          vehicleDetail={true}
        />
      )}
      <CommandConfirm
        open={modalOpen}
        close={toggleModal}
        confirm={sendCommand}
        action={selectedAction}
      />
      <MessageBox
        open={msgOpen}
        close={toggleMsgModal}
        action={selectedAction}
        vehicleUUID={vehicleUUID}
        vehicleStatus={vehicleStatus}
        sendCommand={sendCommand}
        toggleRefresh={toggleRefresh}
        toggleModal={toggleModal}
      />
    </Fragment>
  );
};

export default ListBox;
