import React from "react";
import styles from "./actionButtons.module.scss";
import Button from "../../../../artzu/src/Button";

const calculateButtonDetails = operationStatus => {
  switch (operationStatus) {
    case "Offline":
    case "Deactivated":
    case "Service":
      return {
        label: "Make Available",
        newStatus: "available",
        isDisabled: false
      };
    case "Available":
    case "Unauthorized":
      return {
        label: "Put In Service",
        newStatus: "service",
        isDisabled: false
      };
    default:
      return {
        label: operationStatus,
        isDisabled: true
      };
  }
};

const MainActionButton = ({ operationStatus, startStatusChange }) => {
  const buttonDetails = calculateButtonDetails(operationStatus);

  return (
    <button
      className={styles.mainButton}
      disabled={buttonDetails.isDisabled}
      onClick={() => startStatusChange(buttonDetails.newStatus)}
    >
      {buttonDetails.label}
    </button>
  );
};

export default MainActionButton;
