import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import styles from "../../task.module.scss";
import ChooseVehicleModal from "./ChooseVehicleModal";
import VehicleActions from "./Actions/VehicleActions";
import VehicleDetailsField from "./VehicleDetailsField";
import EmptyRelatedVehicle from "./EmptyRelatedVehicle";
import UpdateVehicle from "./UpdateVehicle";
import { GET_AVAILABLE_SPOTS } from "../../queries";
import { useQuery } from "@apollo/client";

const RelatedVehicle = ({
  vehicleData,
  taskData,
  refreshTaskCallback,
  chosenVehicle,
}) => {
  const [isVehicle, setIsVehicle] = useState();
  const [isEdit, setIsEdit] = useState();
  const [curBoxCode, setCurBoxCode] = useState(vehicleData?.lockboxCode);
  const [curSpot, setCurSpot] = useState(vehicleData?.parkingSpot?.identifier);

  const vehiclePlate = vehicleData?.plate;
  const vehicleStatus = vehicleData?.statuses.operationStatus.name;
  const statusColor = vehicleData?.statuses.operationStatus.styles.color;
  const isSolid = vehicleData?.statuses.operationStatus.styles.isSolid;
  const vehicleModel = vehicleData?.model;
  const vehicleMileage = vehicleData?.odometer?.reading;
  const vehicleBoxCode = vehicleData?.lockboxCode;
  const vehicleHub = vehicleData?.parkingLot?.name;
  const vehicleVin = vehicleData?.vin;
  const vehicleSpot = vehicleData?.parkingSpot?.identifier;
  const vehicleImage = vehicleData?.imageLink;
  const lockStatus = vehicleData?.statuses.lockStatus.name;
  const lockColor = vehicleData?.statuses.lockStatus.styles.color;
  const lockIsSolid = vehicleData?.statuses.lockStatus.styles.isSolid;
  const operationStatusStyle = isSolid
    ? { background: `${statusColor}` }
    : {
        background: "#ffffff",
        border: `${statusColor}`,
      };
  const lockStatusSytle = lockIsSolid
    ? { background: `${lockColor}` }
    : {
        background: "#ffffff",
        border: `${lockColor}`,
      };

  const toggleVehicle = () => {
    setIsVehicle(!isVehicle);
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const { loading, error, data, refetch } = useQuery(GET_AVAILABLE_SPOTS, {
    variables: {
      parkingLotId: vehicleData?.parkingLot?.id,
    },
  });

  const allAvailableSpots = data?.getAllSpotsAtHub.map((spot) => {
    return { name: spot.identifier, value: spot.identifier };
  });

  return (
    <Fragment>
      {vehicleData ? (
        <div className={styles.vehicleInfoContainer}>
          <div className={styles.vehicleInfoHeader}>
            <h2>Vehicle</h2>
            {isEdit ? (
              <UpdateVehicle
                taskData={taskData}
                refreshTaskCallback={refreshTaskCallback}
                toggleEdit={toggleEdit}
                curBoxCode={curBoxCode}
                curSpot={curSpot}
              />
            ) : (
              <VehicleActions
                taskData={taskData}
                refreshTaskCallback={refreshTaskCallback}
                toggleEdit={toggleEdit}
              />
            )}
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.vehicleImageContainer}>
              <img src={vehicleImage} className={styles.vehicleImage}></img>
            </div>
            <VehicleDetailsField
              text={"License plate"}
              fieldValue={vehiclePlate}
              vehicleData={vehicleData}
            />
            <VehicleDetailsField
              text={"Operation status"}
              fieldValue={vehicleStatus}
              statusStyle={operationStatusStyle}
            />
            <VehicleDetailsField
              text={"Lock status"}
              fieldValue={lockStatus}
              statusStyle={lockStatusSytle}
            />
            <VehicleDetailsField text={"Model"} fieldValue={vehicleModel} />
            <VehicleDetailsField text={"Mileage"} fieldValue={vehicleMileage} />
            <VehicleDetailsField
              text={"Glovebox code"}
              fieldValue={vehicleBoxCode}
            />
            <VehicleDetailsField text={"Hub"} fieldValue={vehicleHub} />
            <VehicleDetailsField text={"VIN"} fieldValue={vehicleVin} />
            <VehicleDetailsField
              text={"Spot"}
              fieldValue={vehicleSpot}
              isEdit={isEdit}
              curBoxCode={curBoxCode}
              curSpot={curSpot}
              setCurBoxCode={setCurBoxCode}
              setCurSpot={setCurSpot}
              allAvailableSpots={allAvailableSpots}
            />
          </div>
        </div>
      ) : (
        <EmptyRelatedVehicle toggleVehicle={toggleVehicle} />
      )}

      <ChooseVehicleModal
        open={isVehicle}
        toggleVehicle={toggleVehicle}
        chosenVehicle={chosenVehicle}
        taskData={taskData}
      />
    </Fragment>
  );
};

export default RelatedVehicle;
