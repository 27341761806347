import React from "react";
import { Link } from "react-router-dom";
import styles from "../../task.module.scss";

const LicensePlateDetailsField = ({ vehicleData, fieldValue }) => {
  return (
    <Link
      to={`/vehicles/${vehicleData.id}`}
      target="_blank"
      style={{ textDecoration: "none" }}
    >
      <div className={styles.detailField} style={{ color: "#128dff" }}>
        {fieldValue}
      </div>
    </Link>
  );
};

export default LicensePlateDetailsField;
