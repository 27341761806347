import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const ExpandableList = ({
  cellData,
  classes,
  isExpanded,
  rowExpansionHandlers
}) => {
  const { listComponent: ListComponent, listData } = cellData;

  const countOnLine = 1;

  // const overflowItemsCount = listData.length - countOnLine;

  const itemsToList =
    (isExpanded ? listData : listData.splice(0, countOnLine)) || [];

  const overflowItemsCount = listData.length;

  return (
    <div className={classNames(classes)}>
      <div className={styles.expandableList} {...rowExpansionHandlers}>
        {itemsToList.map(item => (
          <div className={styles.expandableListItem}>
            <ListComponent cellData={item} />
            {overflowItemsCount >= 1 && !isExpanded && (
              <div className={styles.extraBubbleCount}>
                +{overflowItemsCount}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpandableList;
