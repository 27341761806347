import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import NewNote from "../../../Mutations/NewNote";
import moment from "moment";

const NewNotePanel = ({
  newNote,
  newReminder,
  newReminderDate,
  toggleRemindersModal,
  refetch,
  onChange,
  closeNote,
  resetNoteState,
  pinNewNote,
  vehicleId,
  selectPin,
}) => {
  const pinnedStyle = { backgroundColor: "#414141", marginRight: "10px" };
  const unPinnedStyle = { backgroundColor: "#FFFFFF", marginRight: "10px" };
  return (
    <div className="add-note-header">
      <div className="addNoteTitle">New note</div>
      <textarea
        className="textboxInput"
        placeholder="Enter note here..."
        value={newNote}
        rows="4"
        cols="50"
        onChange={(evt) => {
          onChange(evt.target.value);
        }}
      />
      <div
        className="under-text-container"
        style={{ justifyContent: "flex-end" }}
      >
        <div className="noteButtons">
          {newReminder ? (
            <Fragment>
              <div className="remind-on-text">Remind on</div>
              <div className="reminderBox">
                <div className="reminder-dates">
                  {moment(newReminderDate).format("MMM D, YYYY h:mm a")}
                </div>
                <div
                  className="iconButtons"
                  style={{ backgroundColor: "#414141", marginLeft: "0" }}
                >
                  <FontAwesomeIcon
                    className="pin-note"
                    icon={faAlarmClock}
                    style={{ color: "#FFFFFF", marginLeft: "1" }}
                    onClick={() => {
                      toggleRemindersModal();
                    }}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="iconButtons" style={{ backgroundColor: "#FFFFFF" }}>
              <FontAwesomeIcon
                className="pin-note"
                icon={faAlarmClock}
                style={{ color: "#414141", marginLeft: "1" }}
                onClick={() => {
                  toggleRemindersModal();
                }}
              />
            </div>
          )}

          <div
            className="iconButtons"
            style={pinNewNote === true ? pinnedStyle : unPinnedStyle}
          >
            <FontAwesomeIcon
              className="pin-note"
              icon={faThumbtack}
              style={
                pinNewNote === true
                  ? { color: "#FFFFFF" }
                  : { color: "#414141" }
              }
              onClick={() => {
                selectPin();
              }}
            />
          </div>
          <div className="add-note">
            <NewNote
              newNoteParams={{
                details: newNote,
                vehicleId,
                pinned: pinNewNote,
                reminderDate: newReminder ? newReminderDate : null,
                taggedUsers: [],
              }}
              refetch={refetch}
              onChange={onChange}
              closeNote={closeNote}
              resetNote={resetNoteState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNotePanel;
