import React from "react";
import styles from "./batchActions.module.scss";
import Modal from "../../../artzu/src/Modal";
import Selections from "./Selections";
import ActionContainer from "./ActionContainer";
import _ from "lodash";

const ActionModal = ({
  isOpen,
  selectedBatchAction,
  selections,
  removeSelection,
  batchOptionsHash,
  closeModal,
  toggleRefresh,
  item,
  vehicleDetail,
}) => {
  const selectedOption = batchOptionsHash[selectedBatchAction];
  const isStatus = selectedOption && selectedOption.value === "changeStatus";
  const selectedItems = vehicleDetail
    ? selections.map((selection) => selection.id)
    : Object.keys(_.pickBy(selections));
  return (
    <div className={styles.batchActionModal}>
      <Modal open={isOpen} padding="none" backgroundColor={"none"}>
        <div
          className={isStatus ? styles.actionModalLarge : styles.actionModal}
        >
          <Selections
            selections={selectedItems}
            removeSelection={removeSelection}
            close={closeModal}
            isStatus={isStatus}
            item={item}
          />
          {selectedOption && (
            <ActionContainer
              selectedOption={selectedOption}
              selections={isStatus ? selections : selectedItems}
              close={closeModal}
              open={isOpen}
              toggleRefresh={toggleRefresh}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ActionModal;
