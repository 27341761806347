import React from "react";
import styles from "../damages.module.scss";

const TextInput = ({ text, triggerFieldChange }) => {
  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <textarea
        className={styles.description}
        onChange={triggerFieldChange}
      ></textarea>
    </div>
  );
};

export default TextInput;
