import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import DamageReport from "../Components/DamageReport";

import { damageString } from "./queryStrings";

class GetDamageReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
    };
  }

  render() {
    return (
      <Query
        query={gql`
          ${damageString}
        `}
        variables={{
          vehicleId: this.state.id
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading || error) {
            return null;
          }
          if (data.getVehicle) {
            return (
              <DamageReport
                id={data.getVehicle.id}
                plate={data.getVehicle.plate}
                make={data.getVehicle.make}
                damageReportView={data.getVehicle.damageReportView}
                history={this.props.history}
                interiorDamage={data.getVehicle.interiorDamageReports}
                exteriorDamage={data.getVehicle.exteriorDamageReports}
                refetch={refetch}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default GetDamageReport;
