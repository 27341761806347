import React, { useState, Fragment } from "react";
import styles from "../../../batchActions.module.scss";
import Button from "../../../../../../artzu/src/Button";
import Autocomplete from "./Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const AddNewTags = ({
  allTagList,
  newTag,
  triggerTag,
  parentCallback,
  added,
  commonTags,
  pc,
  duplicate,
}) => {
  let addable = allTagList.includes(newTag);
  return (
    <Fragment>
      <div className={styles.addTagContainer}>
        <Autocomplete
          options={allTagList}
          parentCallback={triggerTag}
          handleAdd={parentCallback}
          newTag={newTag}
          addable={addable}
        />
        <div className={styles.addtagAddButton}>
          <Button
            fullWidth="80px"
            height="34px"
            radius="17px"
            border="none"
            color="#FFFFFF"
            backColor={addable ? "#128DFF" : "#87CEFA"}
            padding="0 15px 0 15px"
            label={
              <div className={styles.addButton}>
                <FontAwesomeIcon icon={faPlus} class={styles.faplusIcon} />
                <div style={{ fontSize: "16px", fontWeight: "700" }}>Add</div>
              </div>
            }
            onClick={() => {
              if (newTag) {
                parentCallback(newTag);
              }
            }}
          />
        </div>
      </div>
      <div className={styles.tagBox}>
        {added.map((t) => (
          <div className={commonTags.includes(t) ? styles.tagRed : styles.tag}>
            {t}{" "}
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.times}
              onClick={() => pc(t)}
            />
          </div>
        ))}
      </div>
      <h4 style={{ marginTop: 32 }}>Common tags on selected vehicles</h4>
      {duplicate ? (
        <span className={styles.already}>Already exists*</span>
      ) : null}
    </Fragment>
  );
};

export default AddNewTags;
