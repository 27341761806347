import React, { Fragment } from "react";
import DeleteReminderNote from "../../../../../Mutations/DeleteReminderNote";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock } from "@fortawesome/pro-regular-svg-icons";
import { m } from "../../../../../utils";

const NoteReminder = ({
  note,
  now,
  vehicleId,
  selectNote,
  toggleRemindersModal,
  refetch,
  resetNoteState,
}) => {
  const isValid = note.reminderDate && now < m(note.reminderDate);
  const buttonClicked = { backgroundColor: "#414141", marginLeft: "0" };
  const buttonUnclicked = { backgroundColor: "#FFFFFF", marginLeft: "0" };
  const reminderSet = { color: "#FFFFFF", marginLeft: "1" };
  const reminderUnset = { color: "#414141", marginLeft: "1" };

  return isValid ? (
    <Fragment>
      <div className="remind-on-text">Remind on: </div>
      <div className="reminderBox">
        <div>
          <DeleteReminderNote
            reminderNoteParams={{
              noteId: note.id,
              details: note.details,
              vehicleId: vehicleId,
              userName: note.user,
              userId: note.userId,

              reminderDate: note.reminderDate,
            }}
            refetch={refetch}
            resetNote={resetNoteState}
          />
        </div>
        <div className="reminder-dates">
          {moment(note.reminderDate).format("MMM D, YYYY h:mm a")}
        </div>
        <div
          className="iconButtons"
          style={isValid ? buttonClicked : buttonUnclicked}
        >
          <FontAwesomeIcon
            className="pin-note"
            icon={faAlarmClock}
            style={isValid ? reminderSet : reminderUnset}
            onClick={() => {
              selectNote(note);
              toggleRemindersModal();
            }}
          />
        </div>
      </div>
    </Fragment>
  ) : (
    <div
      className="iconButtons"
      style={isValid ? buttonClicked : buttonUnclicked}
    >
      <FontAwesomeIcon
        className="pin-note"
        icon={faAlarmClock}
        style={isValid ? reminderSet : reminderUnset}
        onClick={() => {
          selectNote(note);
          toggleRemindersModal();
        }}
      />
    </div>
  );
};

export default NoteReminder;
