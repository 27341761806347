import React from "react";
import Modal from "../../../../../../../artzu/src/Modal";
import styles from "../../../../batchActions.module.scss";
import Button from "../../../../../../../artzu/src/Button";

const RenameTagModal = ({
  open,
  toggleRename,
  countSelected,
  setItemSelected,
  itemSelected,
  triggerRename,
  IsExist,
  setNewName,
  newName,
  renameTag,
}) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.renameTag}>
        <h4>Rename</h4>
        <div className={styles.renameSubTitle}>
          Please enter new name for{" "}
          <span style={{ fontWeight: "bold" }}>
            {itemSelected}({countSelected})
          </span>
        </div>
        <div className={styles.createInputBox}>
          <input
            className={styles.inputBoxCreate}
            id="input-field"
            onChange={triggerRename}
            value={newName}
          />
        </div>
        {IsExist && (
          <div className={styles.tagAlreadyExists}>This tag exists</div>
        )}
        <div
          className={
            IsExist ? styles.renameButtonsExists : styles.renameButtons
          }
        >
          <div className={styles.renameCancelButton}>
            <Button
              label={"Cancel"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                toggleRename();
                setNewName("");
              }}
            />
          </div>
          <div className={styles.renameSaveButton}>
            <Button
              label={"Save"}
              fullWidth="152px"
              height="40px"
              color="#FFFFFF"
              backColor={IsExist ? "#87CEFA" : "#128DFF"}
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                newName && !IsExist && renameTag();
                newName && !IsExist && toggleRename();
                newName && !IsExist && setItemSelected([]);
                newName && !IsExist && setNewName("");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RenameTagModal;
