import React, { Component } from "react";
import Modal from "../Misc/Modal";
// import SelectField from "../../artzu/src/SelectField";
import Button from "../../artzu/src/Button";
// import Option from "../../artzu/src/Option";
// import TextField from "../../artzu/src/TextField";
// import TextBox from "../../artzu/src/TextBox";
import styles from "./extensionModal.module.scss";

import gql from "graphql-tag";

import { useApolloClient } from "@apollo/client";

class UndoExtensionModalChild extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteMaintenanceExtension = () => {
    const { client, selectedExtension, closeModal } = this.props;
    client
      .mutate({
        mutation: this.DELETE_MAINTENANCE_EXTENSION,
        variables: {
          extensionParams: Number(selectedExtension.id)
        },
        refetchQueries: [`getVehicle`]
      })
      .then(data => {
        closeModal();
      });
  };

  DELETE_MAINTENANCE_EXTENSION = gql`
    mutation deleteMaintenanceExtension($extensionParams: ID!) {
      deleteMaintenanceExtension(extensionId: $extensionParams) {
        success
        errors
      }
    }
  `;

  onServiceChange = (value, label) => {
    const selectedMileage = this.props.projectedRoutineServices.find(
      service => service.id === value
    ).scheduled;
    this.setState({ predictionId: value, selectedMileage });
  };

  onExtensionChange = value =>
    this.setState({ extensionAmount: Number(value) });

  onTextChange = value => this.setState({ note: value });

  render() {
    // const { selectedMileage } = this.state;
    const { selectedExtension } = this.props;
    return (
      <Modal {...this.props}>
        <div className={styles.text}>
          {" "}
          Are you sure you want to delete a maintenance extension of{" "}
          {selectedExtension.extensionAmount}km?
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Delete"
            onClick={() => this.deleteMaintenanceExtension()}
          />
        </div>
      </Modal>
    );
  }
}

const UndoExtensionModal = props => {
  const client = useApolloClient();
  return <UndoExtensionModalChild client={client} {...props} />;
};

export default UndoExtensionModal;
