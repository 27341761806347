import React, { Component } from "react";
import "./service.css";
import Input from "@material-ui/core/Input";
import UpdateServiceEvent from "../../Mutations/UpdateServiceEvent";
import { withStyles } from "@material-ui/core/styles";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AddNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newNote: this.props.note
    };
  }

  setNote = newNote => this.setState({ newNote });
  toggle = () => this.setState({ open: !this.state.open });

  noteReturn = () => {
    if (this.props.note) {
      return (
        <div className="flex center-align">
          <div>{this.props.note}</div>
          <FontAwesomeIcon className="blue left-gap" icon={faEdit} />
        </div>
      );
    } else {
      return <p className="inspection">Add note</p>;
    }
  };

  render() {
    const { classes, id, note } = this.props;
    const { open, newNote } = this.state;

    return (
      <div
        className={`table-4 vehicle-data ${note ? "" : "blue"}`}
        onClick={evt => {
          evt.stopPropagation();
          this.toggle();
        }}
      >
        {!open ? (
          this.noteReturn()
        ) : (
          <div className="flex center-align">
            <Input
              className={classes.text}
              classes={{ underline: classes.underline }}
              value={newNote}
              type="text"
              autoFocus={true}
              onChange={evt => this.setNote(evt.target.value)}
            />
            <UpdateServiceEvent
              clear={this.toggle}
              params={{ id, note: newNote }}
              check={true}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  underline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.blue}`
    }
  },
  text: {
    width: "60%"
  }
});

export default withStyles(styles)(AddNote);
