import React from "react";
import Button from "../../../../artzu/src/Button";

const TagsInfo = ({ redirectAddTag, vehicle, toggleAddTag }) => {
  return (
    <div className="details-upper-right">
      <div className="details-upper-right-title">
        Tags
        <Button
          fullWidth="92px"
          height="33px"
          radius="17px"
          border="1px solid #128DFF"
          color="#128DFF"
          padding="7px 16px 7px 16px"
          label="Edit tags"
          onClick={() => {
            redirectAddTag();
            toggleAddTag();
          }}
        />
      </div>
      <div>
        {vehicle.tags.map((tag) => (
          <div className="tag">{tag.name}</div>
        ))}
      </div>
    </div>
  );
};

export default TagsInfo;
