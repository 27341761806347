import React, { Component, Fragment, useEffect, useState } from "react";
import moment from "moment";
import styles from "./vehicles_available.module.scss";
import _, { get } from "lodash";
import { useQuery } from "@apollo/client";
import withAuth from "../../Containers/withAuth";
import {
  activeParkingLots,
  availableVehicles,
} from "../../Queries/queryStrings";
import Loading from "../../Containers/Loading";
import Navigation from "./Navigation";
import NavBar from "../InformationTable/NavBar";
import AvailabilityTable from "./AvailabilityTable";
import { inject, observer } from "mobx-react";

const Availability = inject("store")(
  observer(({ store }) => {
    const [criteria, setCriteria] = useState("availability");
    const [parkingLotId, setParkingLotId] = useState();
    const [fleetId, setFleetId] = useState();
    const [startDate, setStartDate] = useState(
      moment()
        .startOf("isoWeek")
        .format("YYYY-MM-DD")
    );
    const [presetParkingLots, setPresetParkingLots] = useState(
      JSON.parse(localStorage.getItem("parkingLots")) || []
    );

    const { loading, error, data, refetch } = useQuery(activeParkingLots, {
      fetchPolicy: "no-cache",
    });

    useEffect(() => {
      const currentParkingLots = JSON.parse(
        localStorage.getItem("parkingLots")
      );
      if (currentParkingLots !== presetParkingLots) {
        setPresetParkingLots(currentParkingLots);
      }
    }, [store.selectedLots]);

    const getPrevWeek = () => {
      setStartDate(
        moment(startDate)
          .subtract(1, "week")
          .format("YYYY-MM-DD")
      );
    };
    const getNextWeek = () => {
      setStartDate(
        moment(startDate)
          .add(1, "week")
          .format("YYYY-MM-DD")
      );
    };

    const onCriteriaChange = (e) => {
      setCriteria(e);
    };

    const onParkingLotChange = (e) => {
      setParkingLotId(e);
    };

    const onFleetChange = (e) => {
      console.log(e);
      setFleetId(e);
    };

    if (loading) {
      return (
        <div className={styles.loadingScreen}>
          <Loading size="5x" />
        </div>
      );
    }

    const getPresetParkingLots = () => {
      if (presetParkingLots.length > 0) {
        return presetParkingLots;
      } else {
        return data?.getActiveParkingLots;
      }
    };

    if (data) {
      const getActiveParkingLots = data.getActiveParkingLots;
      const presetParkingLots = getPresetParkingLots();
      const presetParkingLotsIds = _.map(presetParkingLots, "id");
      const parkingLotsFilteredByCity = _.filter(getActiveParkingLots, (lot) =>
        presetParkingLotsIds.includes(lot.id)
      );

      if (parkingLotsFilteredByCity.length > 0) {
        const chosenParkingLot =
          _.find(parkingLotsFilteredByCity, (lot) => lot.id == parkingLotId) ||
          parkingLotsFilteredByCity[0];
        const chosenFleetId =
          fleetId || chosenParkingLot.fleetsAtParkingLot[0].id;
        const choseParkingLotId = chosenParkingLot.id;

        return (
          <div className={styles.avaPage}>
            <NavBar navbar={"Availability"} />
            <div className={styles.avaCanvas}>
              <Navigation
                parkingLots={parkingLotsFilteredByCity}
                fleetsAtSelectedLot={chosenParkingLot.fleetsAtParkingLot}
                getNextWeek={getNextWeek}
                getPrevWeek={getPrevWeek}
                onParkingLotChange={onParkingLotChange}
                onFleetChange={onFleetChange}
                onCriteriaChange={onCriteriaChange}
                startDate={startDate}
              />
              <AvailabilityTable
                criteria={criteria}
                fleetId={chosenFleetId}
                parkingLotId={choseParkingLotId}
                startDate={startDate}
                timeZone={chosenParkingLot.timeZone}
              />
            </div>
          </div>
        );
      } else {
        return <div> No active parking lots for this user.</div>;
      }
    }
  })
);

export default withAuth(Availability);
