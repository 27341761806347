import React from "react";

import { withStyles } from "@material-ui/core/styles";

import "../service.css";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch
} from "@material-ui/core/";

import { faCarWash, faBroom } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  }
});

const CleaningCheckupList = ({ data, onEdit, classes }) => (
  <List
    subheader={
      <ListSubheader className={classes.subheader} disableSticky>
        {" "}
        Cleaning{" "}
      </ListSubheader>
    }
  >
    {cleaningCheckupList.map((key, index) => {
      return (
        <ListItem key={index}>
          <ListItemIcon>
            <FontAwesomeIcon icon={key.icon} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={key.name} />
          <ListItemSecondaryAction>
            <Switch
              onChange={event => onEdit(key.accessor, event.target.checked)}
              checked={data[key.accessor]}
              classes={{
                switchBase: classes.switch,
                checked: classes.colorChecked,
                track: classes.colorBar
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
    })}
  </List>
);

const cleaningCheckupList = [
  { accessor: "exteriorWash", icon: faCarWash, name: "Exterior Wash" },
  { accessor: "interiorWash", icon: faBroom, name: "Interior Wash" }
];
export default withStyles(styles)(CleaningCheckupList);
