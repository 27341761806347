import React from "react";
import Modal from "../../../../artzu/src/Modal";
import styles from "./selections.module.scss";
import ButtonGroup from "../../Shared/ButtonGroup";

const ConfirmModal = ({ isOpen, closeModal, confirmChanges, text }) => {
  return (
    <Modal padding="none" backgroundColor="none" open={isOpen}>
      <div className={styles.confirmModal}>
        <div className={styles.text}> {text}</div>
        <div className={styles.confirmFooter}>
          <ButtonGroup
            leftButtonFunction={closeModal}
            rightButtonFunction={confirmChanges}
            rightButtonText={"Yes"}
            leftButtonText={"No"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
