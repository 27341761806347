import React from "react";
import { m, formatOdometer } from "../../utils";
import AddNote from "./AddNote";
import DeleteServiceEvent from "../../Mutations/DeleteServiceEvent";

import "./service.css";
import "../Fleet/fleet.css";

const HistoryTable = ({ events, vehicleId, toggle, refetch, units }) => (
  <div>
    <div className="flex-container fleet-header extend-width table-top history-translate">
      <div className="table-3 inspection first-history vehicle-data">
        Scheduled
      </div>
      <div className="table-3 inspection vehicle-data">Completed</div>
      <div className="table-4 inspection vehicle-data">Service event</div>
      <div className="table-2 inspection vehicle-data">Cost</div>
      <div className="table-2 inspection vehicle-data">Vendor</div>
      <div className="table-3 inspection vehicle-data">Odometer</div>
      <div className="table-2 inspection vehicle-data">File</div>
      <div className="table-4 inspection vehicle-data">Description</div>
      <div className="table-4 inspection vehicle-data">Notes</div>
      <div className="table-2 inspection vehicle-data" />
    </div>
    {events.map((event, index) => {
      return (
        <div
          className="flex-container vehicle-row extend-width history-translate"
          key={index}
          onClick={() => toggle("edit", "service", event.id)}
        >
          <div className="table-3 inspection first-history vehicle-data">
            {event.scheduledDate
              ? m(event.scheduledDate).format("MMM DD YYYY")
              : "--"}
          </div>
          <div className="table-3 inspection vehicle-data">
            {m(event.completedDate).format("MMM DD YYYY")}
          </div>
          <div className="table-4 inspection vehicle-data">{event.name}</div>
          <div className="table-2 inspection vehicle-data">${event.cost}</div>
          <div className="table-2 inspection vehicle-data">{event.vendor}</div>
          <div className="table-3 inspection vehicle-data">
            {event.odometerReading
              ? formatOdometer(event.odometerReading) + " " + units
              : "--"}
          </div>
          <div
            className="table-2 inspection blue link vehicle-data"
            onClick={evt => evt.stopPropagation()}
          >
            {event.link ? (
              <a
                className="link blue"
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fiix Receipt
              </a>
            ) : (
              <p className="no-link">--</p>
            )}
          </div>
          <div className="table-4 inspection vehicle-data">
            {event.description}
          </div>
          <AddNote id={event.id} note={event.note} />
          <div className="table-2 inspection vehicle-data">
            <DeleteServiceEvent
              deleteEvent={{ id: event.id, deleted: true }}
              refetch={refetch}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default HistoryTable;
