import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Drawer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../artzu/src/Table";
import NavDisplay from "../../Shareable/NavDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { withStyles } from "@material-ui/core/styles";
import VehicleData from "./VehicleData";
import SearchBar from "./SearchBar";
import ButtonContainer from "./ButtonContainer";
import _ from "lodash";
import "./vehicleDrawer.scss";
import { status, formatNavTitle } from "../../../utils";

import {
  makeTierList,
  makeCarStatusList,
  makeLocationList,
  carStatusId,
  selectFilterLocation,
} from "../../../utils/filterFuncs";

const styles = (theme) => {
  return {
    hide: { display: "none" },
    showList: { marginLeft: "2em" },
    hideList: { justifyContent: "flex-end" },
    parkingName: {
      width: "100%",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "left",
      margin: "0 32px 0 32px",
      backgroundColor: "#f2f2f2",
    },
    "navBuffer-fleet": { height: theme.navHeight },
    "navBuffer-history": {
      height: theme.optionsHeight,
      backgroundColour: "#f2f2f2",
    },
    zeroHeight: { height: "0px" },
    drawer: {
      zIndex: theme.zIndex.drawer,
      borderRight: "none",
      boxShadow: "7px 7px 10px rgba(0, 0, 0, 0.15)",
      width: "444px",
      backgroundColour: "#f2f2f2",
      marginLeft: "60px",
    },
    vehicleOpen: { width: "444px", backgroundColour: "#f2f2f2" },
    open: { width: "444px", backgroundColour: "#f2f2f2" },
    openLoc: { width: "344px", backgroundColour: "#f2f2f2" },

    // closed: { width: "444px", overflowX: "hidden", backgroundColour: "#f2f2f2" }
  };
};

const VehicleDrawer = observer(
  class VehicleDrawer extends Component {
    constructor() {
      super();
      this.state = {
        filteredCars: null,
        searchValue: null,
        openFilter: false,
        hideLocation: false,
      };
    }

    componentDidMount() {
      const { store, history } = this.props;
      console.log(history.location.pathname);

      store.changeNavigatedPage(
        formatNavTitle(
          store.selectedVehicle !== "none" ? store.selectedVehicle : null,
          history.location.pathname,
          null
        )
      );
    }

    updateList = (filteredArray) => {
      this.setState({ filteredCars: filteredArray });
    };

    updateSearch = (val) => this.setState({ searchValue: val });

    setOpenFilter = () => {
      this.setState((prevState) => ({
        openFilter: !prevState.openFilter,
      }));
    };

    // chooseIcon = toggle => {
    //   if (toggle) {
    //     return faAngleUp;
    //   } else {
    //     return faAngleDown;
    //   }
    // };

    deselectVehicle = (pathName) => {
      const { store, resetHistoryMode, history } = this.props;
      if (pathName === "Map" && store.selectedVehicle !== "none") {
        store.changeMode("current");
        store.changeSelectedVehicle("none");
        store.changeNavigatedPage(
          formatNavTitle(
            store.selectedVehicle !== "none" ? store.selectedVehicle : null,
            history.location.pathname,
            null
          )
        );
        resetHistoryMode();
      }
      if (pathName === "Map" && store.selectedVehicle === "none") {
        store.changeSelectedLocation();
        store.changeNavigatedPage(
          formatNavTitle(
            store.selectedVehicle !== "none" ? store.selectedVehicle : null,
            history.location.pathname,
            null
          )
        );
      }
    };

    toggleLocation = () => {
      let hideLoc = this.state.hideLocation;
      this.setState(
        {
          hideLocation: !hideLoc,
        },
        () => {
          this.props.toggleHide(this.state.hideLocation);
        }
      );
    };

    render() {
      const {
        data,
        store,
        classes,
        open,
        openDrawer,
        closeDrawer,
        resetHistoryMode,
        toggleHide,
        history,
      } = this.props;

      const {
        filteredCars,
        searchValue,
        openFilter,
        hideLocation,
      } = this.state;
      const vehicleSelected = store.selectedVehicle !== "none";
      const presetParkingLots = JSON.parse(localStorage.getItem("parkingLots"));
      const presetParkingLotIds = _.map(presetParkingLots, "id");

      const vehiclesAtPresetLocation = data.filter(
        (vehicle) =>
          presetParkingLotIds &&
          presetParkingLotIds.includes(vehicle.parkingLot.id)
      );

      const renderingVehicles =
        vehiclesAtPresetLocation.length === 0 ? data : vehiclesAtPresetLocation;

      const locationList = makeLocationList(renderingVehicles);
      const tierList = makeTierList(renderingVehicles);
      const carStatusList = makeCarStatusList(renderingVehicles);

      let findCars = store.selectedLocation
        ? _.filter(vehiclesAtPresetLocation, (vehicle) =>
            store.selectedLocation.vehiclesAtLocation.includes(vehicle.plate)
          )
        : store.search(searchValue, vehiclesAtPresetLocation);

      if (store.managementFilter.fleetSelection) {
        findCars = _.filter(findCars, (vehicle) =>
          store.managementFilter.fleetSelection.includes(vehicle.fleet.id)
        );
      }

      if (store.managementFilter.carStatusSelection) {
        findCars = _.filter(findCars, (vehicle) =>
          store.managementFilter.carStatusSelection.includes(
            carStatusId(vehicle.status)
          )
        );
      }

      if (store.managementFilter.locationSelection) {
        findCars = selectFilterLocation(
          findCars,
          store.managementFilter.locationSelection
        );
      }

      if (localStorage.getItem("parkingLots")) {
        findCars = _.filter(findCars, (vehicle) =>
          presetParkingLotIds.includes(vehicle.parkingLot.id)
        );
      }

      return (
        <Drawer
          classes={{
            paper: classNames(
              classes.drawer,
              "drawer-container",
              store.selectedVehicle === "none" && hideLocation === false
                ? classes.open
                : store.selectedVehicle !== "none"
                ? classes.vehicleOpen
                : classes.openLoc
            ),
          }}
          variant="permanent"
          open={open}
          style={{ backgroundColour: "#f2f2f2" }}
        >
          <div className="map-nav-header">
            <NavDisplay
              navPath={store.navigatedPage}
              history={history}
              navFunction={this.deselectVehicle}
              mapView={true}
            />
          </div>

          <div>
            {open ? (
              store.selectedLocation ? (
                <div
                  className={classNames(
                    "list-header-title",
                    store.selectedVehicle !== "none" && classes.hide
                  )}
                  style={hideLocation ? { width: "344px" } : null}
                >
                  <div className={classes.parkingName}>
                    {" "}
                    {`${store.selectedLocation.name} (${_.startCase(
                      store.selectedLocation.city.name
                    )})`}
                  </div>
                </div>
              ) : null
            ) : null}
            <div
              className={classNames(
                store.selectedLocation ? "list-header-search" : "list-header",
                store.selectedVehicle !== "none" && classes.hide
              )}
              style={hideLocation ? { width: "344px" } : null}
            >
              <TableRow
                color="#f2f2f2"
                align="center"
                width={hideLocation ? "345px" : "380px"}
                justify="left"
                height="64px"
              >
                <TableCell margin="0 16px 0 0">
                  <div className="select-filter-icon">
                    <FontAwesomeIcon
                      icon={faSlidersH}
                      style={openFilter ? { color: "#128DFF" } : null}
                      onClick={() => {
                        this.setOpenFilter();
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell margin="0">
                  <SearchBar
                    store={store}
                    updateSearch={this.updateSearch}
                    width="100%"
                  />
                </TableCell>
                <TableCell
                  width="32px"
                  size="12px"
                  color="#128DFF"
                  margin="0 0 0 12px"
                >
                  <FontAwesomeIcon
                    icon={hideLocation ? faChevronRight : faChevronLeft}
                    onClick={() => {
                      this.toggleLocation();
                    }}
                  />
                  <div
                    onClick={() => {
                      this.toggleLocation();
                    }}
                  >
                    {hideLocation ? "Show Loc." : "Hide Location"}
                  </div>
                </TableCell>
              </TableRow>
            </div>
          </div>
          <VehicleData
            resetHistoryMode={resetHistoryMode}
            store={store}
            filteredCars={filteredCars}
            data={{
              findCars: findCars,
            }}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
            hideLoc={hideLocation}
            toggleLoc={this.toggleLocation}
            open={open}
            openFilter={openFilter}
            tierList={tierList}
            carStatusList={carStatusList}
            locationList={locationList}
            history={history}
          />
        </Drawer>
      );
    }
  }
);

export default withStyles(styles)(VehicleDrawer);
