import ConfirmChange from "./ConfirmChange";
import ActionButtons from "./ActionButtons";
import React, { useState } from "react";

import { validate, getSaveButtonLabel } from "../../helperFuncs";
import Fields from "./Fields";

const Content = ({
  mutationErrors,
  statusArgs,
  changeStatusArgs,
  changeStatus,
  closeModal,
  toggleEndDateSelected,
  endDateSelected
}) => {
  const [fieldErrors, setFieldErrors] = useState([]);

  const cancelUserBookings =
    mutationErrors && mutationErrors.errorType == "warning";
  console.log("sSTATUS ARGS", statusArgs);
  return (
    <>
      {mutationErrors ? (
        <ConfirmChange errors={mutationErrors.message} />
      ) : (
        <Fields
          {...{
            statusArgs,
            changeStatus,
            changeStatusArgs,
            toggleEndDateSelected,
            endDateSelected,
            fieldErrors
          }}
        />
      )}
      <ActionButtons
        saveButtonName={getSaveButtonLabel(mutationErrors)}
        closeModal={closeModal}
        changeStatus={() => {
          if (validate({ ...statusArgs, endDateSelected }, setFieldErrors)) {
            changeStatus(cancelUserBookings);
          }
        }}
      />
    </>
  );
};

export default Content;
