import React from "react";
import styles from "../task.module.scss";
import { titleize } from "../../../utils";
import { getTopTextBackColor, getTopTextColor } from "../utils";

const TaskTop = ({ taskData, refreshTicketCallback }) => {
  return (
    <div className={styles.taskTitle}>
      <div className={styles.taskStatus}>
        {taskData ? (
          String(taskData.id).padStart(6, "0")
        ) : (
          <div style={{ opacity: "0.5", fontWeight: "700" }}>New task</div>
        )}
        <div
          className={styles.taskTag}
          style={{
            color: `${getTopTextColor(taskData ? taskData.status : "new")}`,
            backgroundColor: `${getTopTextBackColor(
              taskData ? taskData.status : "new"
            )}`,
          }}
        >
          {taskData ? titleize(taskData.status) : "New task"}
        </div>
      </div>
    </div>
  );
};

export default TaskTop;
