import React from "react";

const ShowMoreNote = ({ notes, all, text, showAll, showLess }) => {
  return (
    notes.length > 5 && (
      <div onClick={() => (all ? showLess() : showAll())} className="end">
        <p className="show-more grey-text small-text">{text}</p>
      </div>
    )
  );
};

export default ShowMoreNote;
