import React from "react";
import styles from "./editBox.module.scss";
import classNames from "classnames";

const EditBox = ({ value, editedValue, onChange }) => {
  return (
    <input
      onChange={evt => onChange(evt.target.value)}
      className={classNames(styles.editBox, editedValue && styles.markEdited)}
      value={editedValue || value}
    />
  );
};

export default EditBox;
