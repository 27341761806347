import React from "react";
import InformationTable from "../InformationTable";
import withAuth from "../../Containers/withAuth";

const VehicleIndex = () => {
  return (
    <InformationTable
      tableName={"vehicles"}
      navbar={"Vehicles"}
      hasTabs={true}
      editable={true}
      background={"grey"}
      rowComponentType={"fleet"}
      startTerms={"Search by VIN, Plate and TLC"}
      item={"vehicles"}
    />
  );
};

export default withAuth(VehicleIndex);
