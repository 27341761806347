import React from "react";
import LockEvent from "../../Mutations/LockEvent";
import { Button } from "@material-ui/core/";
import {
  faLockAlt,
  faUnlockAlt,
  faMapMarkedAlt,
  faVolume
} from "@fortawesome/pro-solid-svg-icons";

import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./vehicle.scss";

const size = "2x";

const VehicleObd = ({ vehicle, showLocation, store, uuid, classes }) => (
  <div className="vehicle-buttons">
    <LockEvent
      className="vehicle-button-container"
      uuid={uuid}
      action="LOCK"
      icon={faLockAlt}
      size={size}
    />
    <LockEvent
      className="vehicle-button-container"
      uuid={uuid}
      action="UNLOCK"
      icon={faUnlockAlt}
      size={size}
    />
    <LockEvent
      className="vehicle-button-container"
      uuid={uuid}
      action="HONK"
      icon={faVolume}
      size={size}
    />
    {vehicle.lastGps ? (
      <Link className="map-link" to={`/map`}>
        <Button
          classes={{ text: classes.root }}
          className="vehicle-button-container"
          onClick={() => showLocation(vehicle)}
        >
          <FontAwesomeIcon
            className="vehicle-action-button"
            icon={faMapMarkedAlt}
            size={size}
          />
        </Button>
      </Link>
    ) : null}
  </div>
);

const styles = theme => ({
  root: {
    padding: "8px 8px"
  }
});

export default withStyles(styles)(VehicleObd);
