import React from "react";
import { useState } from "react";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import OptionMenu from "./OptionMenu";
import { Fragment } from "react";
import { useMutation } from "@apollo/client";
import TaskActionPopup from "./TaskActionPopup";
import { BACKLOG_TASK, DELETE_TASK, RESOLVE_TASK } from "../queries";
import styles from "../task.module.scss";
import ToggleButton from "../../InformationTable/Shared/ToggleButton";

const ActionButtons = ({ editTask, taskData, refreshTaskCallback }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setIsPopup] = useState();
  const [curAction, setCurAction] = useState();

  const toggleOpenState = () => {
    taskData && setIsOpen(!isOpen);
  };
  const togglePopup = () => {
    setIsPopup(!isPopup);
  };

  const [backlogTaskMutation, { loading: isBacklogLoading }] = useMutation(
    BACKLOG_TASK
  );
  const handleBacklog = () => {
    backlogTaskMutation({
      variables: {
        taskId: taskData?.id,
      },
    }).then(({ data: { backlogTask } }) => {
      if (backlogTask.success) {
        refreshTaskCallback({ taskId: taskData?.id });
        console.log("Backlog Task Succeed!");
      } else {
        console.log("Backlog Task error!");
      }
    });
  };

  const [deleteTaskMutation, { loading: isDeleteLoading }] = useMutation(
    DELETE_TASK
  );
  const handleDeleteTask = () => {
    deleteTaskMutation({
      variables: {
        taskId: taskData?.id,
      },
    }).then(({ data: { deleteTask } }) => {
      if (deleteTask.success) {
        refreshTaskCallback({ taskId: taskData.id });
        console.log("Delete Task Succeed!");
      } else {
        console.log("Delete Task error!");
      }
    });
  };

  const [resolveTaskMutation, { loading: isResolveLoading }] = useMutation(
    RESOLVE_TASK
  );
  const handleMarkResolve = () => {
    resolveTaskMutation({
      variables: {
        taskId: taskData?.id,
      },
    }).then(({ data: { resolveTask } }) => {
      if (resolveTask.success) {
        refreshTaskCallback({ taskId: taskData?.id });
        console.log("Mark Task as Resolved Succeed!");
      } else {
        console.log("Mark Task as Resolved error!");
      }
    });
  };

  return (
    <Fragment>
      <div className={styles.actionButtonsContainer}>
        <ToggleButton
          isOpen={isOpen}
          toggleOpenState={toggleOpenState}
          faIcon={faEllipsisH}
          taskData={taskData}
        />
        <OptionMenu
          isOpen={isOpen}
          editTask={() => {
            editTask();
            toggleOpenState();
          }}
          deleteTask={() => {
            handleDeleteTask();
            toggleOpenState();
          }}
          backlogTask={() => {
            handleBacklog();
            toggleOpenState();
          }}
          resolveTask={() => {
            handleMarkResolve();
            toggleOpenState();
          }}
          setCurAction={setCurAction}
          taskData={taskData}
        />
      </div>
    </Fragment>
  );
};

export default ActionButtons;
