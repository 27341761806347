import React, { useState } from "react";
import styles from "./batchActions.module.scss";
import ChangeStatus from "./Actions/ChangeStatus/ChangeStatus";
import AddNote from "./Actions/AddNote/AddNote";
import ChangeHub from "./Actions/ChangeHub/ChangeHub";
import AddTag from "./Actions/AddTag";
import EditStatus from "./ParkingBatchActions/EditStatus";

const ActionContainer = ({
  selectedOption,
  selections,
  close,
  toggleRefresh,
}) => {
  return (
    <div className={styles.actionContainer}>
      <div className={styles.rightHeader}>
        {selectedOption.value === "addNote" && (
          <AddNote
            selectedOption={selectedOption}
            selections={selections}
            close={close}
          />
        )}
        {selectedOption.value === "changeHub" && (
          <ChangeHub
            selectedOption={selectedOption}
            selections={selections}
            close={close}
          />
        )}
        {selectedOption.value === "changeStatus" && (
          <ChangeStatus
            selectedOption={selectedOption}
            selections={selections}
            close={close}
            toggleRefresh={toggleRefresh}
          />
        )}
        {selectedOption.value === "addTag" && (
          <AddTag
            selectedOption={selectedOption}
            selections={selections}
            close={close}
            toggleRefresh={toggleRefresh}
          />
        )}
        {selectedOption.value === "edit" && (
          <EditStatus
            selectedOption={selectedOption}
            selections={selections}
            close={close}
            toggleRefresh={toggleRefresh}
          />
        )}
      </div>
    </div>
  );
};

export default ActionContainer;
