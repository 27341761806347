import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../locationSelect.module.scss";
import classNames from "classnames";
import { faParkingCircle } from "@fortawesome/pro-solid-svg-icons";

const ParkingLot = ({ element, lotsSelected, selectLots }) => {
  return (
    <div>
      {" "}
      {element.map(lot => (
        <div
          className={classNames(
            styles.parkingLot,
            styles.hover,
            lotsSelected.some(parkinglot => parkinglot.name === lot.name) &&
              styles.selected
          )}
          key={lot.id}
          onClick={() => selectLots(lot)}
        >
          {" "}
          <FontAwesomeIcon icon={faParkingCircle} /> {lot.name}{" "}
        </div>
      ))}
    </div>
  );
};

export default ParkingLot;
