import React, { Component, Fragment } from "react";

import TextField from "../../../artzu/src/TextField";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";

class InputChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkingLotId: null
    };
  }

  CHANGE_LOT = gql`
    mutation ChangeVehicleLocation($vehicleId: ID!, $parkingLotId: ID!) {
      changeParkingLot(id: $vehicleId, newParkingLotId: $parkingLotId) {
        success
        errors
      }
    }
  `;

  componentDidMount() {
    this.setState({ parkingLotId: this.props.parkingLot.id });
  }

  changeLot = (value, name) => this.setState({ parkingLotId: value });

  updateParking = () => {
    const { client, vehicleId } = this.props;

    client
      .mutate({
        mutation: this.CHANGE_LOT,
        variables: {
          vehicleId: vehicleId,
          parkingLotId: this.state.parkingLotId
        },
        refetchQueries: [`getVehicle`]
      })
      .then(response => {
        if (!response.data.changeParkingLot.errors.length > 0) {
          this.props.toggleEdit();
        }
      });
  };

  render() {
    const {
      heading,
      isInput,
      value,
      onChange,
      children,
      name,
      prompt,
      parkingLots,
      parkingLot,
      toggleEdit
    } = this.props;
    return (
      <Fragment>
        {isInput ? (
          <Fragment>
            <div
              style={{ width: "75%", display: "flex", alignItems: "center" }}
            >
              <SelectField
                prompt={prompt}
                width="100%"
                onChange={(value, heading) => this.changeLot(value, heading)}
              >
                {parkingLots.map(lot => (
                  <Option label={lot.name} value={lot.id} />
                ))}
              </SelectField>
              <FontAwesomeIcon
                className="check"
                icon={faCheck}
                onClick={() => this.updateParking()}
              />
            </div>
          </Fragment>
        ) : (
          <div style={{ fontWeight: "bold" }}> {name} </div>
        )}
      </Fragment>
    );
  }
}

const Input = props => {
  const client = useApolloClient();
  return <InputChild client={client} {...props} />;
};

export default Input;
