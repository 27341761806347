const normalizeCoords = (array, width, height) => {
	return array.map((element, index) => {
		if (index % 2 === 0) {
			return element * width;
		} else {
			return element * height;
		}
	});
};

const normalizeCircle = (x, y, width, height) => [
	x * width,
	y * height,
	0.039 * width // ensures same ratio for all screen sizes
];

const getMirrorImage = array =>
	array.map(
		(coordinate, index) => (index % 2 === 0 ? coordinate : 1 - coordinate)
	);

export { normalizeCoords, normalizeCircle, getMirrorImage };
