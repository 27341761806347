const getLabel = (status) => {
  switch (status) {
    case "unassigned":
      return "Assign task";
    case "call_requested":
      return "Assign task";
    case "in_progress":
      return "Resolve task";
    case "resolved":
      return "Unresolve task";
    case "deleted":
      return "Restore task";
    case "new":
      return "Create task";
    case "save":
      return "Save";
    case "backlog":
      return "Remove from backlog";
    default:
      break;
  }
};

const getAction = (curAction) => {
  switch (curAction) {
    case "Unlock":
      return "unlock";
    case "Lock":
      return "lock";
    case "Start":
      return "startVehicle";
    case "Change status":
      return "changeStatus";
    case "Honk horn":
      return "honkHorn";
    case "Flash lights":
      return "flashLights";
    default:
      return "";
  }
};

const getTopTextColor = (text) => {
  switch (text) {
    case "in_progress":
      return "orange";
    case "resolved":
      return "#3dc700";
    case "deleted":
      return "#414141";
    case "unassigned":
      return "#ff0000";
    case "new":
      return "#128dff";
    case "backlog":
      return "rgba(122, 18, 255, 1)";
    default:
      break;
  }
};

const getTopTextBackColor = (text) => {
  switch (text) {
    case "in_progress":
      return "#ffe2c1";
    case "resolved":
      return "#cbf0bb";
    case "deleted":
      return "#dedede";
    case "unassigned":
      return "#ffbaba";
    case "new":
      return "#daedff";
    case "backlog":
      return "rgba(243, 218, 255, 1)";
    default:
      break;
  }
};

export { getLabel, getAction, getTopTextColor, getTopTextBackColor };
