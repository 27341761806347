import { createMuiTheme } from "@material-ui/core/styles";
import {
  grey800, // "#424242"
  white // "#ffffff"
} from "material-ui/styles/colors";

const theme = createMuiTheme({
  fontFamily: "Montserrat",
  palette: {
    primary1Color: grey800,
    primary2Color: grey800,
    accent1Color: "#00b9bc",
    canvasColor: white,
    grey: { light: "#999", lighter: "#9999", medium: "#666", dark: "#333" },
    blue: "#128DFF"
  },
  datePicker: {
    color: "grey800",
    textColor: white,
    calendarTextColor: white,
    selectColor: grey800,
    selectTextColor: white,
    headerColor: grey800
  },
  timePicker: {
    color: "grey800",
    textColor: white,
    calendarTextColor: white,
    selectColor: grey800,
    selectTextColor: white,
    headerColor: grey800
  },
  zIndex: {
    header: 2500,
    drawer: 2300,
    footer: 2500,
    historyOptions: 2400
  },
  checkBox: {
    blue: "#03708F"
  },
  navHeight: "4em",
  navHeightHistory: "20vh",
  historyTabHeight: "10vh",
  "googleMapHeight-fleet": "calc(100vh - 4em)",
  "googleMapHeight-history": `calc(100vh - 8em)`,
  "googleMapHeight-history-slider": `calc(90vh - 6em)`,
  sliderHeight: "3em",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
  openWidth: "45em",
  closedWidth: "7em",
  optionsHeight: "4em",
  borderWidth: "1px",
  primaryFont: "1.2em",
  blue: "#128DFF"
});

export default theme;
