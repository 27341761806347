import React, { Component } from "react";
import Input from "@material-ui/core/Input";

import { withStyles } from "@material-ui/core/styles";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import classNames from "classnames";
import styles from "./sidebar.module.scss";
import MenuItemComponent from "./MenuItemComponent";
import VehicleTitle from "./VehicleTitle";
import { formatNavTitle } from "../../../utils";

class NavigationSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: {}
    };
  }

  onItemClicked = item => {
    let selectedItem = this.state.selectedItem;
    let newState = { ...selectedItem, name: item };
    const { history, currentTab, store, vehicle, currentTabNav } = this.props;

    if (!["Camera", "AVC", "Tickets"].includes(item)) {
      this.setState({ selectedItem: newState });
      currentTabNav(item);
      store.changeNavigatedPage(
        formatNavTitle(
          vehicle,
          history.location.pathname + `/${item}`,
          currentTabNav
        )
      );
    }
  };

  render() {
    const {
      make,
      model,
      year,
      plate,
      tabs,
      store,
      history,
      selectedTab,
      status
    } = this.props;
    const { selectedItem } = this.state;
    console.log(window.innerWidth);
    return (
      <div
        style={
          window.innerWidth <= 1024
            ? {
                position: "fixed",
                top: "0",
                marginRight: "0.8em",
                height: "-webkit-fill-available",
                overflow: "auto",
                backgroundColor: "#414141"
              }
            : {
                position: "fixed",
                top: "0",
                marginRight: "0.8em",
                height: "100vh",
                backgroundColor: "#414141"
              }
        }
      >
        <TableRow color="#414141" width="197px" padding="0.5em 2em 25.5px 2em">
          <div>
            <VehicleTitle
              make={make}
              model={model}
              year={year}
              plate={plate}
              status={status}
            />
          </div>
        </TableRow>
        {tabs.map((tab, index) => (
          <TableRow
            color={
              selectedItem.name === tab.name || selectedTab === tab.name
                ? "#F2F2F2"
                : "#414141"
            }
            width="197px"
            padding="18.5px 2em 18.5px 2em"
            justify={"left"}
          >
            <TableCell>
              <MenuItemComponent
                title={tab.name}
                click={this.onItemClicked}
                active={
                  !["Camera", "AVC", "Tickets"].includes(tab.name) &&
                  (selectedItem.name === tab.name || selectedTab === tab.name)
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </div>
    );
  }
}

export default NavigationSidebar;
