import React, { useEffect, useState } from "react";
import InformationTable from "../InformationTable";
import withAuth from "../../Containers/withAuth";
import { ALL_PARKING_LOTS } from "./queries";
import { useQuery } from "@apollo/client";

const ParkingSpotIndex = () => {
  const { loading, error, data, refetch } = useQuery(ALL_PARKING_LOTS);
  const [currentLot, setCurrentLot] = useState();
  useEffect(() => {
    setCurrentLot(JSON.parse(window.localStorage.getItem("currentParkingLot")));
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "currentParkingLot",
      JSON.stringify(currentLot)
    );
  }, [currentLot]);

  const changeLot = (lotId) => {
    setCurrentLot(lotId);
  };

  return (
    <InformationTable
      tableName={"parking_spots"}
      navbar={"Parking"}
      hasTabs={true}
      background={"grey"}
      rowComponentType={"parking_spot"}
      startTerms={"Search by Spot, plate and Booking"}
      item={"spots"}
      parkingLots={data?.getParkingLots}
      changeLot={changeLot}
      id={currentLot}
    />
  );
};

export default withAuth(ParkingSpotIndex);
