import React from "react";
import TaskBodyHeader from "./TaskBodyHeader";
import styles from "../task.module.scss";
import ChangeStatusButton from "./ChangeStatusButton";
import ActionButtons from "./ActionButtons";

const StatusAction = ({
  taskData,
  isEdit,
  curType,
  curDescription,
  dueDate,
  vehicleId,
  types,
  changeType,
  changeDescription,
  chosenAgentName,
  toggleAdmin,
  refreshTaskCallback,
  ableToCreate,
  assignedAdminId,
  adminIdCallback,
  toggleEdit,
  cancelEdit,
  currentAdmin,
  resetUpdate,
}) => {
  return (
    <div className={styles.statusAction}>
      <TaskBodyHeader
        taskData={taskData}
        isEdit={isEdit}
        curType={curType}
        curDescription={curDescription}
        changeDescription={changeDescription}
        types={types}
        changeType={changeType}
        chosenAgentName={chosenAgentName}
        toggleAdmin={toggleAdmin}
        currentAdmin={currentAdmin}
      />
      <ChangeStatusButton
        taskData={taskData}
        refreshTaskCallback={refreshTaskCallback}
        ableToCreate={ableToCreate}
        curType={curType}
        curDescription={curDescription}
        dueDate={dueDate}
        vehicleId={vehicleId}
        adminId={assignedAdminId}
        adminIdCallback={adminIdCallback}
        isEdit={isEdit}
        toggleEdit={toggleEdit}
        currentAdmin={currentAdmin}
        cancelEdit={cancelEdit}
        resetUpdate={resetUpdate}
      />
      {!isEdit && (
        <ActionButtons
          taskData={taskData}
          editTask={toggleEdit}
          refreshTaskCallback={refreshTaskCallback}
        />
      )}
    </div>
  );
};

export default StatusAction;
