import React, { Component } from "react";
import { Link } from "react-router-dom";
import autzuLogo from "../../images/autzu.png";
import xcarshareLogo from "../../images/xcarshare.png";

import "./autzuLogoLayout.css";

class AutzuLogoLayout extends Component {
  render() {
    const logo = process.env.REACT_APP_COMPANY_CONFIG === "autzu" ? autzuLogo : xcarshareLogo;
    const { children } = this.props;
    return (
      <section className="layout-section">
        <div
          className="container layout-background"
          style={{ backgroundColor: "#000000", left: "0px", width: "100%" }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <img className="white-logo" src={logo} alt="Autzu Logo" />
          </Link>
          <div className="layout-form">{children}</div>
        </div>
      </section>
    );
  }
}

export default AutzuLogoLayout;
