import React from "react";
import SingleOverview from "./SingleOverview";
import NewDamageForm from "./NewDamageForm";
import DamageOverview from "./DamageOverview";
import "./damage.scss";

const ViewType = ({
  scope,
  vehicleId,
  make,
  alterDamageState,
  exteriorDamage,
  interiorDamage,
  mode,
  refetch,
  location,
  locationX,
  locationY
}) => {
  switch (mode) {
    case "overview":
      return (
        <DamageOverview
          scope={scope}
          alterDamageState={alterDamageState}
          interiorDamage={interiorDamage}
          exteriorDamage={exteriorDamage}
          refetch={refetch}
        />
      );
    case "new":
      return (
        <NewDamageForm
          scope={scope}
          vehicleId={vehicleId}
          make={make}
          location={location}
          locationX={locationX}
          locationY={locationY}
          alterDamageState={alterDamageState}
          refetch={refetch}
        />
      );
    default:
      return (
        <SingleOverview
          damage={
            [...exteriorDamage, ...interiorDamage].filter(
              report => report.id === mode
            )[0]
          }
          refetch={refetch}
          alterDamageState={alterDamageState}
        />
      );
  }
};

export default ViewType;
