import React from "react";
import _ from "lodash";
import Header from "./Header";
import ChangeRow from "./ChangeRow";
import styles from "./changesTable.module.scss";
const ChangesTable = ({
  edits,
  modifyEdits,
  rowIdToIdentifierObject,
  deleteEdit
}) => {
  const rows = _.reduce(
    edits,
    (result, changes, rowId) => {
      const row = {
        rowId
      };
      row.changes = _.reduce(
        changes,
        (row, value, columnName) => {
          const changeType = {
            columnName: columnName,
            oldValue: value.oldValue,
            newValue: value.newValue
          };
          row.push(changeType);
          return row;
        },
        []
      );
      row.identifier = rowIdToIdentifierObject[rowId].identifier;

      result.push(row);
      return result;
    },
    []
  );

  return (
    <div>
      <Header />
      <div className={styles.table}>
        {rows.map(row => (
          <ChangeRow
            key={row.rowId}
            rowData={row}
            deleteEdit={deleteEdit}
            modifyEdits={modifyEdits}
          />
        ))}
      </div>
    </div>
  );
};

export default ChangesTable;
