import React from "react";
import { Motion, spring } from "react-motion";
import "./service.css";

const EventTabs = ({ mode, changeMode }) => (
  <Motion
    defaultStyle={{ x: -200, opacity: 0 }}
    style={{
      x: spring(0, { stiffness: 200, damping: 25 }),
      opacity: spring(1)
    }}
  >
    {style => (
      <div
        className="tabs"
        style={{
          transform: `translateX(${style.x}px)`,
          opacity: style.opacity
        }}
      >
        <p
          className={`${
            mode === "upcoming" ? "selected" : "unselected"
          } tab-option`}
          onClick={() => changeMode("upcoming")}
        >
          Upcoming
        </p>
        <p
          className={`${
            mode === "history" ? "selected" : "unselected"
          } tab-option`}
          onClick={() => changeMode("history")}
        >
          History
        </p>
        <p
          className={`${
            mode === "inspection" ? "selected" : "unselected"
          } tab-option`}
          onClick={() => changeMode("inspection")}
        >
          Inspection
        </p>
      </div>
    )}
  </Motion>
);

export default EventTabs;
