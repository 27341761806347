import _ from "lodash";
import moment from "moment";
import TextValue from "./CellValues/Base/TextValue";
import ExpandableList from "./CellValues/Base/ExpandableList";
import BubbleTextValue from "./CellValues/Base/BubbleTextValue";
import LocationValue from "./CellValues/Base/LocationValue";
import SeverityValue from "./CellValues/Base/SeverityValue";
import DamageStatusValue from "./CellValues/Base/DamageStatusValue";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor]?.value,
    };
  };
};

const baseDateProcessor = (accessor, timeZone) => {
  return (data) => {
    const value = data[accessor]?.value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("MMM D, YYYY")
        : "",
      subtext: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("h:mm:ss A z")
        : "",
    };
  };
};

const rowComponentsDamage = (timeZone) => ({
  damageStatus: {
    component: DamageStatusValue,
    dataProcessor: baseTextProcessor("damageStatus"),
  },

  damageLocation: {
    component: LocationValue,
    dataProcessor: baseTextProcessor("damageLocation"),
  },

  damageType: {
    component: TextValue,
    dataProcessor: baseTextProcessor("damageType"),
  },
  damageDescription: {
    component: TextValue,
    dataProcessor: baseTextProcessor("damageDescription"),
  },
  damageSeverity: {
    component: SeverityValue,
    dataProcessor: baseTextProcessor("damageSeverity"),
  },
  damageOrigin: {
    component: TextValue,
    dataProcessor: baseTextProcessor("damageOrigin"),
  },
  lastUpdated: {
    component: TextValue,
    dataProcessor: baseDateProcessor("updatedAt", timeZone),
  },
  tags: {
    component: ExpandableList,
    dataProcessor: (data) => {
      const listData = data["tags"].value;
      if (listData) {
        const mappedListData = listData.map((data) => ({
          text: data.name,
        }));
        return {
          listComponent: BubbleTextValue,
          listData: mappedListData,
        };
      } else {
        return {
          listComponent: BubbleTextValue,
          listData: [],
        };
      }
    },
  },
});

export { rowComponentsDamage };
