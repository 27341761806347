import React from "react";
import fileUpload from "../../../images/icons/file.svg";
import { useDropzone } from "react-dropzone";

const UploadImage = ({ setFile, setImgName }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles);
      setImgName(
        acceptedFiles.length == 1
          ? acceptedFiles[0].name
          : `${acceptedFiles.length} files selected`
      );
    },
  });

  return (
    <div>
      <label for="file-input">
        <img src={fileUpload} style={{ cursor: "pointer" }}></img>
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps({ id: "file-input" })} />
      </div>
    </div>
  );
};

export default UploadImage;
