import React, { useState } from "react";
import "../vehicle.scss";
import { m } from "../../../utils";
import { withStyles } from "@material-ui/core/styles";
import { reorderNotes } from "../../../utils";
import _ from "lodash";
import NewNotePanel from "./NewNotePanel";
import ShowMoreNote from "./ShowMoreNote";
import NotePopup from "./NotePopup";
import NotesDisplay from "./NotesDisplay";

const VehicleNotes = ({
  notes = [],
  onChange,
  newNote,
  vehicleId,
  refetch,
  closeNote,
  editChange,
  editedNote,
  classes,
}) => {
  const [all, setAll] = useState(false);
  const [text, setText] = useState("Show more...");
  const [pinNewNote, setPinNewNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteDetails, setSelectedNoteDetails] = useState(null);
  const [reminderModal, setReminderModal] = useState(false);
  const [newReminder, setNewReminder] = useState(false);
  const [newReminderDate, setNewReminderDate] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);

  const showAll = () => {
    setAll(true);
    setText("Show less");
  };
  const showLess = () => {
    setAll(false);
    setText("Show more...");
  };
  const selectPin = () => {
    setPinNewNote(!pinNewNote);
  };
  const updateNote = (note) => {
    setEditNote(!editNote);
    setSelectedNote(note);
    setSelectedNoteDetails(note.details);
  };

  const selectNote = (note) => {
    setSelectedNote(note);
    setSelectedNoteDetails(note.details);
    if (note.reminderDate) {
      selectReminderDate(note.reminderDate);
    }
  };
  const toggleEditNote = () => {
    setEditNote(!editNote);
    editChange(null);
  };
  const resetNoteState = () => {
    setPinNewNote(false);
    setSelectedNote(null);
    setSelectedNoteDetails("");
    editChange(null);
    resetReminder();
  };

  const resetReminder = () => {
    setNewReminder(false);
    setNewReminderDate(false);
  };

  const toggleRemindersModal = () => {
    setReminderModal(!reminderModal);
  };

  const selectReminderDate = (val) => {
    setReminderDate(val);
  };

  const addNewReminderDate = (val) => {
    setNewReminderDate(val);
  };
  const addNewReminder = (val) => {
    setNewReminder(val);
  };

  const now = m();

  const sortedNotes = reorderNotes(notes);
  const pinnedNotes = _.filter(sortedNotes, (note) => {
    return note.pinned === true;
  });
  const otherNotes = _.filter(sortedNotes, (note) => {
    return note.pinned !== true;
  });

  return (
    <div className="vehicleContainerDetails">
      <NotePopup
        editNote={editNote}
        editedNote={editedNote}
        selectedNote={selectedNote}
        editChange={editChange}
        vehicleId={vehicleId}
        refetch={refetch}
        toggleEditNote={toggleEditNote}
        resetNoteState={resetNoteState}
        reminderModal={reminderModal}
        toggleRemindersModal={toggleRemindersModal}
        newReminderDate={newReminderDate}
        reminderDate={reminderDate}
        now={now}
        classes={classes}
        selectReminderDate={selectReminderDate}
        addNewReminderDate={addNewReminderDate}
        addNewReminder={addNewReminder}
      />

      <NewNotePanel
        newNote={newNote}
        newReminder={newReminder}
        newReminderDate={newReminderDate}
        toggleRemindersModal={toggleRemindersModal}
        refetch={refetch}
        onChange={onChange}
        closeNote={closeNote}
        resetNoteState={resetNoteState}
        pinNewNote={pinNewNote}
        vehicleId={vehicleId}
        selectPin={selectPin}
      />
      <div
        className={
          pinnedNotes.length === 0 ? "addNoteTitleLine" : "addNoteTitle"
        }
      >
        Pinned notes
      </div>
      {sortedNotes.length > 0 ? (
        <div className="notesBox" style={{ minHeight: "304px" }}>
          <NotesDisplay
            pinnedNotes={pinnedNotes}
            all={all}
            now={now}
            vehicleId={vehicleId}
            refetch={refetch}
            editNote={editNote}
            selectedNote={selectedNote}
            resetNoteState={resetNoteState}
            selectNote={selectNote}
            toggleRemindersModal={toggleRemindersModal}
            updateNote={updateNote}
          />
          <div
            className="addNoteTitle"
            style={{ marginLeft: "0", marginBottom: "24px" }}
          >
            All notes
          </div>
          <NotesDisplay
            otherNotes={otherNotes}
            all={all}
            now={now}
            vehicleId={vehicleId}
            refetch={refetch}
            editNote={editNote}
            selectedNote={selectedNote}
            resetNoteState={resetNoteState}
            selectNote={selectNote}
            toggleRemindersModal={toggleRemindersModal}
            updateNote={updateNote}
          />
        </div>
      ) : (
        <div className="unavailable-info">Not Available</div>
      )}
      <ShowMoreNote
        notes={notes}
        all={all}
        text={text}
        showAll={showAll}
        showLess={showLess}
      />
    </div>
  );
};

const styles = (theme) => ({
  dateInput: {
    color: "grey",
    display: "flex",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    borderRadius: "25px",
    fontSize: "16px",

    alignItems: "center",
    padding: "0 12px 0 12px",
    margin: "0 0 0 12px",
    width: "180px",
    height: "34px",
    backgroundColor: "#FFFFFF",
  },
});

export default withStyles(styles)(VehicleNotes);
