import React, { useState } from "react";
import Option from "../../../../../../../artzu/src/Option";
import SelectField from "../../../../../../../artzu/src/SelectField";
import styles from "./filterRow.module.scss";

const Dropdown = ({ items, onChange, label, initialValue, error }) => {
  const initialItem = items.find((item) => item.value == initialValue);
  const prompt = (initialItem && initialItem.name) || "Select one";
  return (
    <div className={styles.dropdown}>
      <SelectField
        initialValue={initialValue}
        borderRadius="12px"
        prompt={prompt}
        width="124px"
        height="24px"
        containerHeight={"52px"}
        fontSize="16px"
        fontWeight="400"
        primaryColor={"#414141"}
        margin={"8px auto 0"}
        title={label}
        onChange={(value, _) => onChange(value)}
        error={error}
      >
        {items.map((item) => (
          <Option
            width="124px"
            height="20px"
            fontSize="16px"
            fontWeight="400"
            label={item.name}
            value={item.value}
          />
        ))}
      </SelectField>
    </div>
  );
};

export default Dropdown;
