import { decorate, observable } from "mobx";
import { m, getVehicleTags } from "../utils";
import _ from "lodash";

class gpsStore {
  /*===================================*/
  /*           State Variables         */
  /*===================================*/
  cars = [];

  drawerList = [];

  filter = "";

  mode = "current";

  showParking = false;

  selectedVehicle = "none";

  selectedCarStatus;

  selectedColumns = null;

  columnCount = 0;

  showFilter = false;
  searchValues = {};
  mapZoom = 12;

  mapObjs = {
    map: null,
    maps: null,
  };

  checkedVehicles = [];

  selectedLots = null;

  selectedLocation = null;

  navigatedPage = [];

  selectedSortable = {
    column: null,
    descending: null,
  };

  history = {
    mode: "single",
    open: false,
    option: null,
    datetime: {
      start: "",
      end: "",
    },
    // default values are Autzu office, used as backdrop for history dialog
    coords: [
      {
        lat: 43.6442916,
        lng: -79.39875,
      },
    ],
  };

  drawerOpen = false;

  driverDetail = {
    open: false,
    plate: "",
  };

  managementFilter = {
    statusSelection: 0,
    fleetSelection: 0,
    serviceGroupSelection: 0,
    homeLotSelection: 0,
    yearSelection: "",
    searchVal: "",
    carStatusSelection: "",
    modelSelection: "",
    locationSelection: "",
    upcomingServiceSelection: "",
    pendingServiceSelection: "",
    hardwareSelection: "",
    avcDevice: "",
    dtc: "",
  };

  presetSelectFilter = {
    statusSelection: { All: true },
    fleetSelection: { All: true },
    serviceGroupSelection: { All: true },
    homeLotSelection: { All: true },
    yearSelection: { All: true },
    searchVal: { All: true },
    carStatusSelection: { All: true },
    hardwareSelection: { All: true },
    modelSelection: { All: { checked: true, list: {} } },
    locationSelection: { All: { checked: true, list: {} } },
    upcomingServiceSelection: { All: { checked: true, list: {} } },
    pendingServiceSelection: { All: { checked: true, list: {} } },
    avcDevice: { All: true },
    dtc: { All: true },
  };

  selectFilterOptions = {
    statusSelection: [],
    fleetSelection: [],
    serviceGroupSelection: [],
    searchVal: [],
    carStatusSelection: [],
    modelSelection: [],
    locationSelection: [],
    upcomingServiceSelection: [],
    homeLotSelection: [],
    pendingServiceSelection: [],
    yearSelection: [],
    hardwareSelection: [],
    avcDevice: [],
    dtc: [],
  };

  // editedFields = [];
  saveEditError = {};

  reorderPending = null;

  selectFilterAll = {};

  errors = "";

  vehicles = [];

  filteredVehicles = [];
  /*===================================*/
  /*           State Modifiers         */
  /*===================================*/

  changeFilter = (input) => {
    if (["Pro", "Plus"].includes(input)) {
      this.mode = "current";
    } else {
      // upon selecting a single car, driver details get displayed
      this.toggleDriverDetail(input);
      this.getDriverDetails(input);
    }
    //If the same button is clicked, deselect it
    if (input === this.filter) {
      this.filter = "";
    } else {
      this.filter = input;
    }
  };

  changeShowFilter = (open) => {
    this.showFilter = open;
  };

  changeSelectedLots = (lots) => {
    this.selectedLots = lots;
    console.log(lots);
    this.filterVehicles();
  };

  changeSelectedLocation = (location) => {
    if (location) {
      this.changeSelectedVehicle("none");
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  };

  changeSelectedColumns = (columns) => {
    this.selectedColumns = columns;
  };

  changeColumnCount = (count) => {
    this.columnCount = count;
  };

  filterVehicles = () => {
    let lotIds = this.selectedLots && this.selectedLots.map((lot) => lot.id);
    this.filteredVehicles =
      this.selectedLots && this.selectedLots.length
        ? _.filter(this.vehicles, (vehicle) =>
            lotIds.includes(vehicle.parkingLot.id)
          )
        : this.vehicles;
  };

  filterParkingLots = (parkingLots) =>
    this.selectedLots && this.selectedLots.length
      ? _.filter(parkingLots, (lot) => this.selectedLots.includes(lot.id))
      : parkingLots;

  filterServiceLocations = (serviceLocations) => serviceLocations;

  loadVehicles = (vehicles) => {
    this.vehicles = vehicles;
    this.filterVehicles();
  };

  search = (searchVal, vehicleArray) => {
    const searchTag = searchVal ? searchVal.replace("#", "") : null;
    const value =
      searchVal && !searchVal.includes("#")
        ? _.filter(
            vehicleArray,
            (val) =>
              _.includes(
                val.plate && val.plate.toUpperCase(),
                searchVal.toUpperCase()
              ) ||
              _.includes(
                val.medallionNumber && val.medallionNumber.toUpperCase(),
                searchVal.toUpperCase()
              ) ||
              _.includes(
                val.vin && val.vin.toUpperCase(),
                searchVal.toUpperCase()
              ) ||
              getVehicleTags(val.tags).some((tag) => {
                return _.includes(tag && tag.toUpperCase(), searchVal);
              })
          )
        : searchTag && searchVal.includes("#")
        ? _.filter(vehicleArray, (val) => {
            console.log(getVehicleTags(val.tags));
            return val.tags.some((tag) => {
              return _.includes(tag.name.toUpperCase(), searchTag);
            });
          })
        : vehicleArray;

    return value;
  };

  selectCheckedVehicles = (selectedVehicles) => {
    this.checkedVehicles = selectedVehicles;
  };

  changeEditedValues = (vehicleId, type, value) => {
    let params = this.editedFields;
    const values =
      type === "odometer"
        ? {
            vehicleId,
            value: value,
            type: type,
          }
        : type === "code"
        ? {
            vehicleId,
            value: value,
            type: type,
          }
        : type === "parking"
        ? {
            vehicleId,
            value: value,
            type: type,
          }
        : null;

    if (params.length > 0) {
      _.remove(params, (param) => {
        return param.vehicleId === vehicleId && param.type === type;
      });
      params.push(values);
    } else {
      params.push(values);
    }

    this.editedFields = params;
  };

  changeEditError = (type, plate, value) => {
    if (this.saveEditError[plate]) {
      this.saveEditError[plate][type] = value;
    } else {
      this.saveEditError[plate] = {};
      this.saveEditError[plate][type] = value;
    }
  };

  resetEditedValues = () => {
    this.editedFields = [];
  };

  updateDrawerSearch = (searchVal) => {
    this.drawerList = this.search(searchVal, this.filteredVehicles);
  };

  updateMapObjs = (map, maps) => {
    this.mapObjs.map = map;
    this.mapObjs.maps = maps;
  };

  changeZoom = (zoom) => {
    this.mapZoom = zoom;
  };

  changeSelectedVehicle = (input) => {
    this.selectedVehicle = input;
  };

  changeNavigatedPage = (pathArr) => {
    this.navigatedPage = pathArr;
    console.log(pathArr);
  };

  changeMode = (newMode) => (this.mode = newMode);

  changeHistoryMode = (newMode) => {
    this.history.mode = newMode;
  };

  changeParkingView = () => (this.showParking = !this.showParking);

  changeDatetime = (selection) => {
    switch (selection) {
      case 0:
        this.setDatetime(1);
        break;
      case 1:
        this.setDatetime(4);
        break;
      case 2:
        this.setDatetime(8);
        break;
      case 3:
        this.setDatetime(12);
        break;
      case 4:
        this.setDatetime(24);
        break;
      case 5:
        this.history.datetime = { start: "", end: "" };
        break;
      default:
        break;
    }
    this.history.option = selection;
  };

  setDatetime = (hours) => {
    const newDatetime = {
      start: m()
        .subtract(hours, "hours")
        .format(),
      end: m().format(),
    };
    this.history.datetime = newDatetime;
  };

  setCustomTime = (datetime, type) => {
    this.history.datetime[type] = datetime ? m(datetime).format() : "";
    this.errors = "";
  };

  resetOptions = () => {
    this.changeMode("current");
    this.changeFilter("");
    /* eslint-disable no-unused-expressions */
    this.showParking ? this.changeParkingView() : null;
    this.history = {
      mode: "single",
      open: false,
      option: null,
      datetime: { start: "", end: "" },
    };
    this.driverDetail = {
      open: false,
      plate: "",
    };
    this.errors = "";
  };

  cancelHistory = () => {
    this.history = {
      mode: "single",
      open: false,
      option: null,
      datetime: { start: "", end: "" },
    };
    this.errors = "";
  };

  changeDriverDetailPlate = (plate) => {
    this.driverDetail.plate = plate;
  };

  changeManagementFilter = (type, value) => {
    this.managementFilter[type] = value;
  };

  changeSelectOptions = (type, value) => {
    this.selectFilterOptions[type] = value;
  };

  changeSelectFilter = (type, value) => {
    this.presetSelectFilter[type] = value;
  };

  changeSelectAll = (type, value) => {
    this.selectFilterAll[type] = value;
  };

  changeListSelected = (type, value) => {
    this.presetNestedFilter[type] = value;
  };

  resetSelectFilter = () => {
    this.managementFilter = {
      statusSelection: 0,
      fleetSelection: 0,
      serviceGroupSelection: 0,
      homeLotSelection: 0,
      yearSelection: "",
      searchVal: "",
      carStatusSelection: "",
      modelSelection: "",
      locationSelection: "",
      upcomingServiceSelection: "",
      pendingServiceSelection: "",
      hardwareSelection: "",
      avcDevice: "",
      dtc: "",
    };

    this.presetSelectFilter = {
      statusSelection: { All: true },
      fleetSelection: { All: true },
      serviceGroupSelection: { All: true },
      homeLotSelection: { All: true },
      yearSelection: { All: true },
      searchVal: { All: true },
      carStatusSelection: { All: true },
      hardwareSelection: { All: true },
      modelSelection: { All: { checked: true, list: {} } },
      locationSelection: { All: { checked: true, list: {} } },
      upcomingServiceSelection: { All: { checked: true, list: {} } },
      pendingServiceSelection: { All: { checked: true, list: {} } },
      avcDevice: { All: true },
      dtc: { All: true },
    };

    this.selectFilterOptions = {
      statusSelection: [],
      fleetSelection: [],
      serviceGroupSelection: [],
      searchVal: [],
      carStatusSelection: [],
      modelSelection: [],
      locationSelection: [],
      upcomingServiceSelection: [],
      homeLotSelection: [],
      pendingServiceSelection: [],
      yearSelection: [],
      hardwareSelection: [],
      avcDevice: [],
      dtc: [],
    };
  };

  changeReorderPending = (selection) => {
    this.reorderPending = selection;
  };

  changeSortable = (column, descending) => {
    this.selectedSortable.column = column;
    this.selectedSortable.descending = descending;
  };

  /*               DATA                */

  getFilteredCars = (c) => {
    if (this.selectedVehicle === "none" || this.mode === "history") {
      return c;
    } else {
      return c.filter((car) => car.id.includes(this.selectedVehicle.id));
    }
  };

  getDriverDetails = (plate) => {
    // make fetch call for driver details by plate
    const details = {
      plate: plate,
      booked: true,
      first_name: "Jonathan",
      last_name: "Weir",
      mobile: "123-456-7890",
    };

    this.driverDetail = { ...this.driverDetail, ...details };
  };

  /*            VALIDATORS             */

  validateSelections = () => {
    // no car selected
    if (this.filter.length < 7) {
      this.errors = "A car must be selected";
      return false;
    }
    // no option selected
    else if (this.history.option == null) {
      this.errors = "A option must be selected";
      return false;
    }
    // custom single, but no start time
    else if (
      this.history.option === 5 &&
      this.history.mode === "single" &&
      !this.history.datetime.start
    ) {
      this.errors = "A time must be selected";
      return false;
    }
    // custom range, but no start time or end time
    else if (
      this.history.option === 5 &&
      this.history.mode === "range" &&
      (!this.history.datetime.start || !this.history.datetime.end)
    ) {
      this.errors = "A time must be selected";
      return false;
    }
    // end before start
    else if (
      m(this.history.datetime.end).isBefore(m(this.history.datetime.start))
    ) {
      this.errors = "End time must be after start time";
      return false;
    }
    // dates in the future
    else if (
      m(this.history.datetime.start).isAfter(m()) ||
      m(this.history.datetime.end).isAfter(m())
    ) {
      this.errors = "Please select a past date and time";
      return false;
    }
    // pass validation
    else {
      this.errors = "";
      return true;
    }
  };

  configureMap = (vehicles, loaded = true, polylineCoords = []) => {
    if (this.mapObjs.map && vehicles.length) {
      const filteredVehicles = this.getFilteredCars(vehicles);
      const { map, maps } = this.mapObjs;
      //In current mode, with no vehicle selected set the bounds of the map to all the vehicles.
      if (filteredVehicles.length > 1) {
        let bounds = new maps.LatLngBounds();
        filteredVehicles.forEach((vehicle) => {
          bounds.extend({
            lat: vehicle.lastGps.lat,
            lng: vehicle.lastGps.lng,
          });
        });
        map.fitBounds(bounds);
        this.changeZoom(map.getZoom());

        //In current mode, with a vehicle selected
      } else if (this.mode === "history" && this.history.mode === "range") {
        if (loaded && polylineCoords.historyGps) {
          const samplingFreq = Math.ceil(polylineCoords.historyGps.length / 15);
          let bounds = new maps.LatLngBounds();

          for (
            let i = 0;
            i < polylineCoords.historyGps.length;
            i = i + samplingFreq
          ) {
            let { lat, lng } = polylineCoords.historyGps[i];
            bounds.extend({ lat, lng });
          }
          map.fitBounds(bounds);
        }
        //If in history mode and single mode, return the filtered car
      } else if (
        this.mode === "history" &&
        this.history.mode === "single" &&
        loaded
      ) {
        map.setCenter({
          lat: filteredVehicles.historyGps[0].lat,
          lng: filteredVehicles.historyGps[0].lng,
        });
        // map.setZoom(14);
      } else if ((filteredVehicles[0] && loaded) || this.mode !== "history") {
        map.setCenter({
          lat: filteredVehicles[0].lastGps.lat,
          lng: filteredVehicles[0].lastGps.lng,
        });
        // map.setZoom(14);
      }
    }
  };

  /*             UI FUNCS              */

  openDialog = () => (this.history.open = true);

  closeDialog = () => (this.history.open = false);

  toggleDrawer = () => (this.drawerOpen = !this.drawerOpen);

  toggleDriverDetail = (newPlate) => {
    if (newPlate === this.driverDetail.plate) {
      this.closeDriverDetail();
    } else {
      this.changeDriverDetailPlate(newPlate);
      this.driverDetail.open = true;
    }
  };

  closeDriverDetail = () => (this.driverDetail = { open: false, plate: "" });

  /*                SEARCH             */
}

decorate(gpsStore, {
  cars: observable,
  filter: observable,
  vehicle: observable,
  filteredVehicles: observable,
  selectedLocation: observable,
  mode: observable,
  showParking: observable,
  selectedVehicle: observable,
  mapZoom: observable,
  mapObjs: observable,
  history: observable,
  drawerList: observable,
  drawerOpen: observable,
  driverDetail: observable,
  managementFilter: observable,
  errors: observable,
  selectedLots: observable,
  selectedColumns: observable,
  showFilter: observable,
  presetSelectFilter: observable,
  selectFilterAll: observable,
  selectFilterOptions: observable,
  columnCount: observable,
  reorderPending: observable,
  selectedSortable: observable,
  editedFields: observable,
  saveEditError: observable,
  navigatedPage: observable,
  checkedVehicles: observable,
});

const store = (window.store = new gpsStore());

export default store;
