import React from "react";
import _ from "lodash";
import styles from "./footer.module.scss";
import classNames from "classnames";

const getPageNumbers = (totalRowCount, page, itemsPerPage) => {
  const firstIndex = Math.max(1, page - 5);
  const lastIndex = totalRowCount / itemsPerPage + 1;
  return _.range(firstIndex, Math.min(firstIndex + 10, lastIndex));
};

const PageNumbers = ({
  itemsPerPage,
  currentPage,
  totalRowCount,
  changePage
}) => {
  const pageNumbers = getPageNumbers(totalRowCount, currentPage, itemsPerPage);
  return (
    <>
      {pageNumbers.map((pageNumber, index) => {
        const isSelected = currentPage == pageNumber;
        return (
          <div
            key={index}
            className={classNames(
              styles.pageNumber,
              isSelected && styles.selected
            )}
            onClick={() => changePage(pageNumber)}
          >
            {pageNumber}
          </div>
        );
      })}
    </>
  );
};

export default PageNumbers;
