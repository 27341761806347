import React, { Component } from "react";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_ADMIN } from "./queries";
import TaskPage from "./TaskPage";
import Loading from "../../Containers/Loading";
import { useLocation } from "react-router";
import styles from "./task.module.scss";

const GetTask = (props) => {
  const { data, loading } = useQuery(GET_CURRENT_ADMIN);
  const location = useLocation();
  const vehicleId = location?.vehicleId;
  console.log(data);

  if (loading) {
    return (
      <div className={styles.taskLoading}>
        <Loading />
      </div>
    );
  }
  if (data) {
    return <TaskPage currentAdmin={data.currentUser} vehicleId={vehicleId} />;
  }
};

export default GetTask;
