import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { EDIT_TAG } from "../../queries";
import RenameTagModal from "./RenameTagModal";

const RenameTag = ({
  open,
  toggleRename,
  allTags,
  itemSelected,
  countSelected,
  setItemSelected,
  refetch,
  refetchAddTag,
  entry,
}) => {
  const [newName, setNewName] = useState();
  const IsExist = allTags.some(
    (tag) => newName && tag.name.toLowerCase() === newName.toLowerCase()
  );
  const tagName = itemSelected[0];
  const tagSelected = allTags.find((anyTag) => anyTag.name === tagName);
  const tagId = tagSelected && tagSelected.id;

  const triggerRename = (e) => {
    setNewName(e.target.value);
  };

  const [renameTagMutation, { loading, error }] = useMutation(EDIT_TAG);
  const renameTag = () => {
    renameTagMutation({
      variables: {
        tag: {
          id: tagId,
          name: newName,
        },
      },
    }).then(({ data: { editTag } }) => {
      if (editTag.success) {
        refetch();
        entry && refetchAddTag();
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };
  return (
    <RenameTagModal
      open={open}
      toggleRename={toggleRename}
      countSelected={countSelected}
      setItemSelected={setItemSelected}
      itemSelected={itemSelected}
      triggerRename={triggerRename}
      IsExist={IsExist}
      setNewName={setNewName}
      newName={newName}
      renameTag={renameTag}
    />
  );
};

export default RenameTag;
