import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_ADMIN_NAME } from "../../../TasksNew/queries";
import styles from "../batchActions.module.scss";
import AddComment from "./AddComment/index";
import AddTag from "./AddTag";
import AssignAgent from "./AssignAgent";
import ChangeStatus from "./ChangeStatus";

const TaskActionContainer = ({
  selectedOption,
  selectedTasks,
  close,
  toggleRefresh,
  clearAllSelections,
}) => {
  const { data, loading } = useQuery(GET_ADMIN_NAME);
  return (
    <div className={styles.actionContainer}>
      {selectedOption.value === "addComment" && (
        <AddComment
          selections={selectedTasks}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
      {selectedOption.value === "addTag" && (
        <AddTag
          selectedOption={selectedOption}
          selections={selectedTasks}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
      {selectedOption.value === "assignTo" && (
        <AssignAgent
          selectedOption={selectedOption}
          selections={selectedTasks}
          close={close}
          clearAllSelections={clearAllSelections}
          adminData={data}
        />
      )}
      {selectedOption.value === "changeStatus" && (
        <ChangeStatus
          selectedOption={selectedOption}
          selections={selectedTasks}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
    </div>
  );
};

export default TaskActionContainer;
