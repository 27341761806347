import React, { useState, Fragment } from "react";
import styles from "../../batchActions.module.scss";
import Dropdown from "../../../Shared/Dropdown/index";
import classsNames from "classnames";
import Button from "../../../../../artzu/src/Button";

const ChangeHub = ({ selectedOption }) => {
  const items = [
    { name: "Downtown", value: "Downtown" },
    { name: "Langhorne, PA", value: "Langhorne, PA" },
    { name: "Weekly Lease", value: "Weekly Lease" },
    { name: "LIC Temp Lot", value: "LIC Temp Lot" },
    { name: "Secaucus, NJ", value: "Secaucus, NJ" },
    { name: "Skyport", value: "Skyport" },
    { name: "Skyport (Outdoor)", value: "Skyport (Outdoor)" },
  ];

  return (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div
        className={classsNames(
          styles.contentContainer,
          styles.contentContainer__addNote
        )}
      >
        <div className={styles.optionBox}>
          Choose new hub
          <Dropdown initialValue="" items={items} onChange={() => {}} />
        </div>
        <h4>Comment</h4>
        <textarea
          className={classsNames(styles.textBox, styles.commentBox)}
        ></textarea>
        <div className={styles.notesSaveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={"#128dff"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {}}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ChangeHub;
