import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import styles from "../../batchActions.module.scss";
import { BULK_ADD_COMMENT } from "../../queries";

const AddComment = ({ selections, close, clearAllSelections }) => {
  const [curComment, setCurComment] = useState();

  const changeComment = (e) => {
    setCurComment(e.target.value);
  };
  const [addCommentMutation] = useMutation(BULK_ADD_COMMENT);
  const handelAddComment = () => {
    addCommentMutation({
      variables: {
        taskIds: selections,
        comment: curComment,
      },
    }).then(({ data: { addComment } }) => {
      if (addComment.success) {
        clearAllSelections();
        close();
        console.log("Add Comment Succeed!");
      } else {
        console.log("Add Comment error!");
      }
    });
  };

  const applyable = curComment;
  return (
    <Fragment>
      <div className={styles.actionHeader}>Add comment</div>
      <span className={styles.subscript}>Comment</span>
      <textarea
        className={styles.taskTextBox}
        onChange={changeComment}
      ></textarea>
      <div className={styles.taskModalButtons} style={{ marginTop: "308px" }}>
        <div>
          <button
            className={styles.taskModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={
              applyable
                ? styles.taskModalApplyButton
                : styles.taskModalApplyButtonInvalid
            }
            onClick={() => {
              applyable && handelAddComment();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AddComment;
