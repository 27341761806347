import React, { Component, Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import Button from "../../../artzu/src/Button";
import { m } from "../../../utils";
import RenderImage from "../RenderImage";

class DisplayTicketModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ticket, handleClose, handleEdit } = this.props;
    const {
      id,
      imageLink,
      uploadedAt,
      updatedAt,
      expiryDate,
      status,
      description
    } = ticket;

    return (
      <Fragment>
        <div className="upload__modal">
          <div className="upload__modal__preview upload__modal__preview--black">
            <div
              onClick={e => {
                e.stopPropagation();
                window.open(imageLink, "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              <RenderImage
                src={imageLink}
                imageClass="upload__modal__preview--img"
              />
            </div>
          </div>
          <div className="upload__modal__inputs">
            <Fragment>
              <div className="upload__modal__inputs--close">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="2x"
                  onClick={handleClose}
                />
              </div>
              <div className="upload__modal__inputs__displayName">
                {description}
              </div>
              <div className="upload__modal__inputs__margin">
                <span className="upload__modal__inputs--bold">
                  Date Uploaded: &nbsp;
                </span>
                {m(uploadedAt).format("MMMM Do YYYY")}
              </div>
              <div className="upload__modal__inputs__margin">
                <span className="upload__modal__inputs--bold">
                  Date Updated: &nbsp;
                </span>
                {m(updatedAt).format("MMMM Do YYYY")}
              </div>
              <div className="upload__modal__inputs__margin">
                <span className="upload__modal__inputs--bold">
                  Expire Date: &nbsp;
                </span>
                {expiryDate === null
                  ? "N/A"
                  : m(expiryDate).format("MMMM Do YYYY")}
              </div>
              <div className="upload__modal__inputs__margin">
                <span className="upload__modal__inputs--bold">
                  Status: &nbsp;
                </span>
                {status}
              </div>
              <br />
              <div className="upload__modal__inputs__buttons">
                <Button
                  color="#414141"
                  label="Edit"
                  onClick={() => handleEdit(id)}
                />
                <a href={imageLink} rel="noopener noreferrer" target="_blank">
                  <Button color="#128DFF" label="Download" />
                </a>
              </div>
            </Fragment>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DisplayTicketModal;
