import React from "react";
import styles from "../actionBar.module.scss";
import { useState } from "react";
import toggleOn from "../../../../images/buttons/toggle-on.svg";
import toggleOff from "../../../../images/buttons/toggle-off.svg";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_ADMIN } from "../../../TasksNew/queries";

const TaskToggleButton = ({ changeAdminUserOnly }) => {
  const [isMy, setIsMy] = useState(false);

  const toggleMyTask = () => {
    setIsMy(!isMy);
  };

  return (
    <div className={styles.toggleTasksButtonContainer}>
      <div>All tasks</div>
      <img
        src={isMy ? toggleOn : toggleOff}
        onClick={() => {
          toggleMyTask();
          changeAdminUserOnly(!isMy);
        }}
        style={{ cursor: "pointer", marginLeft: "8px", marginRight: "8px" }}
      ></img>
      <div>My tasks</div>
    </div>
  );
};

export default TaskToggleButton;
