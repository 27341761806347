import React from "react";
import Modal from "../../../../../../../artzu/src/Modal";
import styles from "../../../../batchActions.module.scss";
import Button from "../../../../../../../artzu/src/Button";

const CreateTagModal = ({
  open,
  toggleCreate,
  newTag,
  triggerCreate,
  IsExist,
  setNewTag,
  createTag,
}) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.createNewTags}>
        <h4>Create New Tag</h4>
        <div className={styles.createInputBox}>
          <input
            className={styles.inputBoxCreate}
            id="input-field"
            onChange={triggerCreate}
            value={newTag}
          />
        </div>
        {IsExist && (
          <div className={styles.tagAlreadyExists}>This tag exists</div>
        )}
        <div
          className={
            IsExist ? styles.createButtonsExists : styles.createButtons
          }
        >
          <div className={styles.createCancelButton}>
            <Button
              label={"Cancel"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                toggleCreate();
                setNewTag("");
              }}
            />
          </div>
          <div className={styles.createSaveButton}>
            <Button
              label={"Create"}
              fullWidth="152px"
              height="40px"
              color="#FFFFFF"
              backColor={IsExist ? "#87CEFA" : "#128DFF"}
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                newTag && !IsExist && toggleCreate();
                newTag && !IsExist && createTag();
                newTag && !IsExist && setNewTag("");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTagModal;
