import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import Button from "../artzu/src/Button";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Components/Vehicle/vehicle.scss";

class DeleteReminderNote extends Component {
  DELETE_REMINDER_NOTE = gql`
    mutation DeleteReminderNote($reminderNoteParams: ReminderNoteAttributes!) {
      deleteReminderNote(params: $reminderNoteParams) {
        success
        errors
      }
    }
  `;

  render() {
    const {
      reminderNoteParams,
      refetch,
      onChange,
      closeNote,
      resetNote,
    } = this.props;

    return (
      <Mutation
        mutation={this.DELETE_REMINDER_NOTE}
        variables={{ reminderNoteParams }}
        onCompleted={() => {
          refetch();

          resetNote();
        }}
        onError={(data) => {
          console.log("error", data);
        }}
      >
        {/* {deleteReminderNote => (
          <FontAwesomeIcon
            icon={faTimes}
            style={{ margin: "0 8px 0 8px", cursor: "pointer" }}
            onClick={() => {
              deleteReminderNote();
            }}
          />
        )} */}
      </Mutation>
    );
  }
}

export default DeleteReminderNote;
