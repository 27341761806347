import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const LocationValue = ({ cellData, classes }) => {
  const { text } = cellData;
  const textConverter = () => {
    switch (text) {
      case "0":
        return "Glass";
      case "1":
        return "Driver front";
      case "2":
        return "Passenger front";
      case "3":
        return "Driver rear";
      case "4":
        return "Passenger rear";
      case "5":
        return "Interior";
      default:
        break;
    }
  };
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.textValue}>{textConverter(text)}</div>
    </div>
  );
};

export default LocationValue;
