import React from "react";
import Modal from "../../artzu/src/Modal";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import styles from "./modal.module.scss";

const FleetModal = ({ open, children, closeModal, title }) => (
  <Modal open={open}>
    <div className={styles.container}>
      {" "}
      <div className={styles.header}>
        {" "}
        <div className={styles.title}> {title} </div>
        <FontAwesomeIcon
          className={classNames(styles.cross, styles.icon)}
          icon={faTimes}
          onClick={closeModal}
        />
      </div>
      <div className={styles.children}> {children} </div>
    </div>
  </Modal>
);

export default FleetModal;
