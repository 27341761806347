import React from "react";
import Button from "../../../../artzu/src/Button";
import Modal from "../../../../artzu/src/Modal";
import moment from "moment";
import CANCEL_STATUS_CHANGE from "./query";
import { useMutation } from "@apollo/client";

const DeleteMessage = ({ open, close, toDelete, toggleRefresh }) => {
  const isSolid = toDelete && toDelete.status.styles.isSolid;
  const statusColor = toDelete && toDelete.status.styles.color;
  const [cancelStatusChangeMutation, { loading, error }] = useMutation(
    CANCEL_STATUS_CHANGE
  );
  const deleteStatusChange = () => {
    cancelStatusChangeMutation({
      variables: {
        reservationUuid: toDelete.uuid,
      },
    }).then(({ data: { cancelStatusChange } }) => {
      if (cancelStatusChange.success) {
        console.log("Succeed!");
      } else {
        console.log(cancelStatusChange.errors);
      }
    });
  };
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className="statusChangeDelete">
        <div className="deleteText">
          Are you sure you want to delete this status change?
        </div>
        <div className="deleteInfo">
          <div
            className="operationSymbol"
            style={
              isSolid
                ? { background: `${statusColor}` }
                : {
                    background: "#ffffff",
                    border: `${statusColor}`,
                  }
            }
          />
          {toDelete.status.name} () on{" "}
          {moment(toDelete.startTime).format("MMM D, YYYY h:mm a")} by{" "}
          {toDelete.lastChangedBy}
        </div>
        <div className="deleteButtons">
          <Button
            label={"Cancel"}
            fullWidth="152px"
            height="40px"
            color="#414141"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              close();
            }}
          />
          <Button
            label={"Delete"}
            fullWidth="152px"
            height="40px"
            color="#FFFFFF"
            backColor="#ff0000"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              deleteStatusChange();
              close();
              toggleRefresh();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteMessage;
