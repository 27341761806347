import React from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import styles from "../../task.module.scss";

const ReminderDatePicker = ({
  reminderDate,
  taggedAdmins,
  currentAdmin,
  taskData,
  setReminderDate,
  handleRemind,
}) => {
  return (
    <div className={styles.datepickerContainer}>
      <DatePicker
        selected={reminderDate}
        onChange={(date) => {
          setReminderDate(date);
          taggedAdmins?.length !== 0 && handleRemind(currentAdmin.id, date);
        }}
        dateFormat="MMM D, YYYY h:mm a"
        todayButton={"Today"}
        placeholderText="Select date"
        popperPlacement="bottom"
        className={styles.dateInput}
        showTimeSelect
        timeFormat="hh:mm"
        disabled={!taskData}
      />
      <FontAwesomeIcon
        icon={faCalendar}
        className={styles.reminderCalendar}
        size="lg"
      />
    </div>
  );
};

export default ReminderDatePicker;
