import React from "react";
import { m } from "../../utils";
import "./service.css";
import "../Fleet/fleet.css";
import DeleteServiceEvent from "../../Mutations/DeleteServiceEvent";

import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatOdometer } from "../../utils";
import _ from "lodash";

const returnIcon = value => {
  return value ? (
    <FontAwesomeIcon className="true-icon" icon={faCheck} />
  ) : (
    <FontAwesomeIcon className="false-icon" icon={faTimes} />
  );
};

const InspectionsTable = ({ events, vehicleId, toggle, units }) => (
  <div>
    <div
      className="flex-container service-row table-top extend-width upcoming-translate"
      style={{ paddingRight: "16px" }}
    >
      <div className="table-1 first-inspection vehicle-data inspection vehicle-data-center" />
      <div className="table-3 vehicle-data inspection">Inspector</div>
      <div className="table-3 vehicle-data inspection">Completed</div>
      <div className="table-3 vehicle-data inspection">Odometer</div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Exterior Wash
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Interior Wash
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Tire Pressure
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Dash Lights
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Washer Fluid
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        12V Socket
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Insurance Slip
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Vehicle Ownership
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Safety Certificate
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Autzu Vinyl
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Tread Depth
      </div>
      <div className="table-3 vehicle-data inspection vehicle-data-center">
        Gas Filled
      </div>
      <div className="table-3 vehicle-data inspection last-insp vehicle-data-center">
        Gas Level
      </div>
      <div className="table-1 vehicle-data" />
    </div>

    {events.map((event, index) => {
      return (
        <div
          className="flex-container service-row upcoming-translate extend-width"
          key={index}
          onClick={() => toggle("edit", "inspection", event.id)}
        >
          <div className="table-1 first-inspection vehicle-data inspection vehicle-data-center" />
          <div className="table-3 vehicle-data inspection">
            {event.inspector}
          </div>
          <div className="table-3 vehicle-data inspection">
            {event.completedDate
              ? m(event.completedDate).format("MMM DD YYYY")
              : "--"}
          </div>
          <div className="table-3 vehicle-data inspection">
            {formatOdometer(event.odometerReading)} {units}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.exteriorWash)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.interiorWash)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.tirePressure)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.dashLights)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.washerFluid)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.socket)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.insuranceSlip)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.vehicleOwnership)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.safetyCertificate)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.vinyl)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {_.every(event.treadDepth) && event.treadDepth.length === 4
              ? _.min(event.treadDepth) + "/32"
              : returnIcon(false)}
          </div>
          <div className="table-3 vehicle-data inspection vehicle-data-center">
            {returnIcon(event.gasFilled)}
          </div>
          <div className="table-3 vehicle-data inspection last-insp vehicle-data-center">
            {event.gasLevel ? event.gasLevel + "%" : returnIcon(false)}
          </div>
          <div className="table-1 vehicle-data">
            <DeleteServiceEvent deleteEvent={{ id: event.id, deleted: true }} />
          </div>
        </div>
      );
    })}
  </div>
);

export default InspectionsTable;
