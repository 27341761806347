import React from "react";

const NoteDetails = ({ note }) => {
  return (
    <div
      className="note-details"
      style={note.pinned === true ? { color: "#ff0000" } : null}
    >
      {note.details
        .replace(/(\r\n|\n|\r)/gm, " ")
        .split(" ")
        .map((word, index) => {
          if (
            word[0] === "@" ||
            (index > 0 &&
              note.details.replace(/(\r\n|\n|\r)/gm, " ").split(" ")[
                index - 1
              ][0] === "@")
          ) {
            return <span style={{ color: "#128DFF" }}>{`${word} `}</span>;
          } else {
            return <span>{`${word} `}</span>;
          }
        })}
    </div>
  );
};

export default NoteDetails;
