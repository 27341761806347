import React, { useEffect } from "react";
import Loading from "../../../Containers/Loading";
import styles from "./actionButtons.module.scss";
import Button from "../../../artzu/src/Button";
import gql from "graphql-tag";
import { capitalize } from "../../../utils";
import { useQuery } from "@apollo/client";
import _ from "lodash";

const MessageBoxContent = ({
  close,
  action,
  vehicleUUID,
  vehicleStatus,
  sendCommand,
  toggleRefresh,
  toggleModal,
}) => {
  const GET_COMMAND_STATUS = gql`
    query getCommandStatus($uuid: String!) {
      getCommandStatus(uuid: $uuid)
    }
  `;
  const { loading, error, data, startPolling, stopPolling } = useQuery(
    GET_COMMAND_STATUS,
    {
      variables: {
        uuid: vehicleUUID,
      },
      fetchPolicy: "network-only",
      // pollInterval: 5000,
    }
  );
  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);
  const feedback = data && data.getCommandStatus;

  return (
    <div
      className={
        feedback === "pending" ? styles.actionModalLoading : styles.actionModal
      }
    >
      {feedback === "pending" ? (
        <Loading size="2x" />
      ) : feedback === "success" ? (
        <div className={styles.message}>{_.startCase(action)} successful.</div>
      ) : feedback === "failed" ? (
        <div className={styles.message}>{_.startCase(action)} failed.</div>
      ) : feedback === "timeout" ? (
        <div className={styles.message}>
          {_.startCase(action)} failed (time-out).
        </div>
      ) : null}
      {feedback === "pending" || feedback === "success" ? (
        <div className={styles.actionMessageButtons}>
          <div>
            <Button
              label={"Close"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                close();
                toggleRefresh();
              }}
            />
          </div>
        </div>
      ) : feedback === "failed" || feedback === "timeout" ? (
        <div className={styles.actionButtons}>
          <div>
            <Button
              label={"Close"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={close}
            />
          </div>
          <div>
            <Button
              label={"Retry"}
              fullWidth="152px"
              height="40px"
              color="#128DFF"
              backColor="#FFFFFF"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                toggleModal();
                close();
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MessageBoxContent;
