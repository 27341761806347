import React from "react";
import styles from "./vehicles_available.module.scss";
import {
  getAvailabilityTextColor,
  getAvailabilityBackColor,
  getServiceBackColor,
  getServiceTextColor,
  getUtilizationBackColor,
  getUtilizationTextColor,
} from "./utils";

const SingleCell = ({ tier, criteria }) => {
  const availabilityCount = (tier.availableCount / tier.totalOnlineCount) * 100;
  const inServicePercentage = tier.serviceCount / tier.totalCount;
  const inServiceCount = tier.serviceCount ? inServicePercentage * 100 : 0;
  const utilizationCount = tier.utilization * 100;

  const getCellColor = () => {
    if (criteria === "availability") {
      return getAvailabilityTextColor(availabilityCount);
    } else if (criteria === "in_service") {
      return getServiceTextColor(inServiceCount);
    } else if (criteria === "utilization") {
      return getUtilizationTextColor(utilizationCount);
    } else {
      return null;
    }
  };

  const getCellBackgroundColor = () => {
    if (criteria === "availability") {
      return getAvailabilityBackColor(availabilityCount);
    } else if (criteria === "in_service") {
      return getServiceBackColor(inServiceCount);
    } else if (criteria === "utilization") {
      return getUtilizationBackColor(utilizationCount);
    } else {
      return null;
    }
  };
  return (
    <div
      className={styles.carCount}
      style={{
        color: getCellColor(),
        backgroundColor: getCellBackgroundColor(),
      }}
    >
      {criteria === "availability" ? (
        <div>
          <span className={styles.singleCellText}>{tier.availableCount}</span>/
          {tier.totalOnlineCount}
        </div>
      ) : criteria === "utilization" ? (
        <div>
          <span className={styles.singleCellText}>
            {utilizationCount.toFixed(1)}
            <span className={styles.smallFont}>%</span>
          </span>
        </div>
      ) : criteria === "in_service" ? (
        <div>
          <span className={styles.singleCellText}>
            {tier.serviceCount ? tier.serviceCount : 0}
          </span>
          /{tier.totalCount}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleCell;
