import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const BooleanTagValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;

  const getText = () => {
    switch (text) {
      case "true":
        return "Yes";
      case "false":
        return "No";
      default:
        break;
    }
  };
  const getColor = (text) => {
    switch (text) {
      case "true":
        return "#3dc700";
      case "false":
        return "#414141";
      default:
        break;
    }
  };

  const getBackColor = (text) => {
    switch (text) {
      case "true":
        return "#bcff9f";
      case "false":
        return "#dedede";
      default:
        break;
    }
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={styles.parkingTag}
        style={{
          color: `${getColor(text)}`,
          backgroundColor: `${getBackColor(text)}`,
          border: `1px ${getBackColor(text)} solid;`,
        }}
      >
        {getText()}
      </div>
    </div>
  );
};

export default BooleanTagValue;
