import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { centerItems, getAnimation, getFadeout } from "./modalHelpers";

const dropIn = keyframes`${getAnimation(true)}`;
const liftUp = keyframes`${getAnimation(false)}`;
const fadeout = keyframes`${getFadeout()}`;

const StyledOverlayContainer = styled.div`
  position: fixed;
  z-index: 300;
  left: 60px;
  top: 0;
  width: calc(100% - 60px);
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.55);
  ${() => centerItems()}
  animation: ${({ exiting }) => exiting && fadeout} 0.3s;
`;

const StyledModalBox = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || "2em"};
  border: ${({ border }) => border || "none"}
  ${() => centerItems()}
  animation: ${({ exiting }) => (exiting ? liftUp : dropIn)} 0.3s;
`;

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exiting: false
    };
  }

  closeModal = () => this.setState({ exiting: false });
  wait = () => setTimeout(() => this.closeModal(), 300);

  componentDidUpdate(prevProps) {
    if (!this.props.open && prevProps.open) {
      this.setState({ exiting: true }, this.wait);
    }
  }

  render() {
    const {
      children,
      width,
      height,
      backgroundColor,
      padding,
      margin,
      open = false,
      ...props
    } = this.props;
    const { exiting } = this.state;
    return (
      (open || exiting) && (
        <StyledOverlayContainer exiting={exiting} {...props}>
          <StyledModalBox
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            padding={padding}
            margin={margin}
            exiting={exiting}
          >
            {children}
          </StyledModalBox>
        </StyledOverlayContainer>
      )
    );
  }
}

export default Modal;
