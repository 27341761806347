import React, { useState } from "react";
import SearchBar from "./Search";
import ColumnSettings from "./ColumnSettings";
import MiscSettings from "./MiscSettings";
import Filters from "./Filters";
import styles from "./actionBar.module.scss";
import Selections from "./Selections";
import _ from "lodash";
import Edits from "./Edits";
import TaskToggleButton from "./TaskToggleButton";
import CreateTask from "./CreateTask";
import Dropdown from "../Shared/Dropdown";

const ActionBar = ({
  searchValue,
  columns,
  changeSearchValue,
  changeColumnsValue,
  filters,
  changeFilters,
  selectedRowsCount,
  clearAllSelections,
  isEditModalOpen,
  toggleEditModalOpen,
  startEdit,
  endEdits,
  isEditModeActivated,
  saveEdits,
  edits,
  modifyEdits,
  rowIdToIdentifierObject,
  deleteEdit,
  reviewEdits,
  startManageTag,
  editable,
  background,
  startTerms,
  popupSpecific,
  changeAdminUserOnly,
  tableName,
  vehicleId,
  parkingLots,
  changeLot,
  currentParkingLotId,
}) => {
  const {
    available: availableFilters,
    savedGroups: savedFilterGroups,
  } = filters;

  const [selectedFilterGroup, setSelectedFilterGroup] = useState();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const parkingLotList =
    parkingLots &&
    parkingLots.map((lot) => {
      return { name: lot.name, id: lot.id };
    });

  const selectFilterGroup = (filterGroupUuid) => {
    const selectedGroupIndex = savedFilterGroups.findIndex(
      (group) => filterGroupUuid === group.uuid
    );

    const selectedGroup = savedFilterGroups[selectedGroupIndex];
    let selectedGroupCopy = _.cloneDeep(selectedGroup);

    selectedGroupCopy = {
      ...selectedGroup,
      selectedGroupIndex: selectedGroupIndex,
    };
    setSelectedFilterGroup(selectedGroupCopy);
    setIsFiltersOpen(true);
  };

  const deleteFilterGroup = (groupUuid) => {
    const newFilterGroups = _.reject(
      savedFilterGroups,
      (group) => group.uuid == groupUuid
    ).map((group) =>
      group.filters.map((filter) => ({
        categoryUuid: filter.categoryUuid,
        uuid: filter.uuid,
        isExcluded: filter.isExcluded,
        values: filter.values,
      }))
    );
    changeFilters(newFilterGroups);
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.leftGroup}>
        {!popupSpecific && tableName !== "damages" && (
          <Filters
            availableFilters={availableFilters}
            changeFilters={changeFilters}
            selectedFilterGroup={selectedFilterGroup}
            savedFilterGroups={savedFilterGroups}
            isOpen={isFiltersOpen}
            setIsOpen={setIsFiltersOpen}
            clearSelectedFilter={() => setSelectedFilterGroup(null)}
            background={background}
          />
        )}
        {tableName !== "damages" && (
          <SearchBar
            changeSearchValue={changeSearchValue}
            searchValue={searchValue}
            background={background}
            startTerms={startTerms}
            popupSpecific={popupSpecific}
          />
        )}
        <Selections
          savedFilterGroups={savedFilterGroups}
          selectFilterGroup={selectFilterGroup}
          setIsFiltersOpen={setIsFiltersOpen}
          deleteFilterGroup={deleteFilterGroup}
          selectedRowsCount={selectedRowsCount}
          clearAllSelections={clearAllSelections}
          tableName={tableName}
        />
      </div>

      <div className={styles.rightGroup}>
        {editable && (
          <Edits
            deleteEdit={deleteEdit}
            rowIdToIdentifierObject={rowIdToIdentifierObject}
            modifyEdits={modifyEdits}
            edits={edits}
            areButtonsShowing={isEditModeActivated}
            isEditModalOpen={isEditModalOpen}
            toggleEditModalOpen={toggleEditModalOpen}
            startEdit={startEdit}
            endEdits={endEdits}
            saveEdits={saveEdits}
            reviewEdits={reviewEdits}
          />
        )}
        {tableName === "tasks" && (
          <div className={styles.taskButtons}>
            <TaskToggleButton changeAdminUserOnly={changeAdminUserOnly} />
            <CreateTask vehicleId={vehicleId} />
          </div>
        )}
        {tableName === "vehicles" && (
          <ColumnSettings
            columns={columns}
            changeColumnsValue={changeColumnsValue}
          />
        )}
        {tableName === "parking_spots" && (
          <Dropdown
            width="300px"
            height="30px"
            items={parkingLotList}
            onChange={changeLot}
            promptColor={"#dedede"}
            parkingSpecific
            initialValue={currentParkingLotId}
          />
        )}
        {!popupSpecific && tableName === "vehicles" && (
          <MiscSettings startEdit={startEdit} startManageTag={startManageTag} />
        )}
      </div>
    </div>
  );
};

export default ActionBar;
