import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../artzu/src/Table";
import Sortable from "../../Fleet/Sortable";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { getSteeringColor, m, status } from "../../../utils";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import CircleImage from "../CircleImage";
import styles from "./search.module.scss";
import SelectFilter from "../../Shareable/SelectFilter";
import NestedSelectFilter from "../../Shareable/NestedSelectFilter";
import ButtonContainer from "./ButtonContainer";
import { formatNavTitle, capitalize } from "../../../utils";

class VehicleList extends Component {
  handleClick = (event, index) => {
    const { store, findCars, history } = this.props;
    store.changeSelectedVehicle(findCars[index]);
    store.changeNavigatedPage(
      formatNavTitle(
        store.selectedVehicle !== "none" ? store.selectedVehicle : null,
        history.location.pathname,
        null
      )
    );
    store.changeSelectedLocation();
    store.configureMap([findCars[index]]);
    store.mapObjs.map.setZoom(17);
  };

  modifyLocale = (time) => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "Just now",
        ss: "Just now",
        m: "a minute ago",
        mm: "%d minutes ago",
        h: "an hour ago",
        hh: "%d hours ago",
        d: "a day ago",
        dd: "%d days ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago",
      },
    });
    return moment(time);
  };

  render() {
    const {
      findCars,
      classes,
      open,
      type,
      descending,
      reorder,
      openFilter,
      tierList,
      carStatusList,
      locationList,
      openDrawer,
      closeDrawer,
      hideLoc,
      history,
      store,
    } = this.props;

    const hideCell = classNames(!open && classes.hide);
    const centerText = classNames(!open && classes.centerText);
    const ipadPlate = classNames(
      !open && window.innerWidth <= 1024 ? classes.ipadPlate : ""
    );
    return (
      <div
        className={styles.drawerContainer}
        style={hideLoc ? { width: "344px" } : null}
      >
        <TableRow
          padding="0.5em"
          color="#FFFFFF"
          align="center"
          width={hideLoc ? "254px" : "380px"}
          minHeight="60px"
          margin="0px auto 12px auto"
          justify="space-around"
          height="auto"
        >
          <TableCell>
            <Sortable
              name="Vehicle"
              tableWidth="1"
              reorder={reorder}
              myType="tier"
              currentType={type}
              descending={descending}
            />
            <div className="search-options-map">
              {store.selectFilterOptions.fleetSelection.join(", ")}
            </div>
          </TableCell>
          <TableCell>
            <Sortable
              name="Status"
              tableWidth="1"
              reorder={reorder}
              myType="vehiclePriority"
              currentType={type}
              descending={descending}
            />
            <div className="search-options-map">
              {store.selectFilterOptions.carStatusSelection.join(", ")}
            </div>
          </TableCell>

          {hideLoc ? null : (
            <TableCell>
              <Sortable
                name="Location"
                tableWidth="1"
                reorder={reorder}
                myType="locationName"
                currentType={type}
                descending={descending}
              />
              <div className="search-options-map">
                {store.selectFilterOptions.locationSelection.join(", ")}
              </div>
            </TableCell>
          )}
        </TableRow>
        {openFilter === true ? (
          <TableRow
            padding="0 0 0 0.5em"
            color="#FFFFFF"
            align="top"
            width={hideLoc ? "254px" : "380px"}
            margin="0px auto 12px auto"
            justify="space-around"
            justifyIpad="space-evenly"
          >
            <TableCell margin="0px 0.9em 0px 0">
              <SelectFilter
                onFilter={store.changeManagementFilter}
                checkList={tierList}
                selectionType="fleetSelection"
                changePreset={store.changeSelectFilter}
                preset={store.presetSelectFilter.fleetSelection}
                allCheck={store.changeSelectAll}
                all={store.selectFilterAll.fleetSelection}
                selection={store.managementFilter.fleetSelection}
                changeOptions={store.changeSelectOptions}
                searchOptions={store.selectFilterOptions.fleetSelection}
              />
            </TableCell>
            <TableCell margin="0px 0.9em 0px 0">
              <SelectFilter
                onFilter={store.changeManagementFilter}
                checkList={carStatusList}
                selectionType="carStatusSelection"
                changePreset={store.changeSelectFilter}
                preset={store.presetSelectFilter.carStatusSelection}
                allCheck={store.changeSelectAll}
                all={store.selectFilterAll.carStatusSelection}
                selection={store.managementFilter.carStatusSelection}
                changeOptions={store.changeSelectOptions}
                searchOptions={store.selectFilterOptions.carStatusSelection}
              />
            </TableCell>
            {hideLoc ? null : (
              <TableCell margin="0px 0.9em 0px 0">
                <NestedSelectFilter
                  onFilter={store.changeManagementFilter}
                  checkList={locationList}
                  selectionType="locationSelection"
                  changePreset={store.changeSelectFilter}
                  preset={store.presetSelectFilter.locationSelection}
                  allCheck={store.changeSelectAll}
                  all={store.selectFilterAll.locationSelection}
                  selection={store.managementFilter.locationSelection}
                  changeOptions={store.changeSelectOptions}
                  searchOptions={store.selectFilterOptions.locationSelection}
                />
              </TableCell>
            )}
          </TableRow>
        ) : null}

        {findCars.map((car, index) => {
          return (
            <TableRow
              hover
              key={index}
              onClick={(event) => this.handleClick(event, index)}
              padding="0.8em"
              color="#FFFFFF"
              align="center"
              width={hideLoc ? "254px" : "380px"}
              margin="12px auto 12px auto"
              justify="space-evenly"
              height="88px"
            >
              <TableCell>
                {" "}
                <div className={styles.carStatus}>
                  <img
                    className="vehicle-pic-drawer"
                    alt="Vehicle Pic"
                    src={car.imageLink}
                  />{" "}
                  <div className={styles.vehicleStatus__tier}>
                    {car.tier} - {car.plate}
                  </div>
                </div>
              </TableCell>

              <TableCell>
                <div className={styles.carStatus}>
                  <CircleImage
                    imageLink={car.booking && car.booking.driverImage}
                    size="small-pic"
                    status={car.status}
                  />
                  {car.status === "User" ? (
                    <div className={styles.vehicleStatus}>
                      {m(car.booking.startTime).format("hA")} -{" "}
                      {m(car.booking.endTime).format("hA")}
                    </div>
                  ) : (
                    <div className={styles.vehicleStatus}>{car.status}</div>
                  )}
                </div>
              </TableCell>
              {hideLoc ? null : (
                <TableCell>
                  <div className={styles.carStatus}>
                    <Link
                      className={classNames(styles.locationLink)}
                      to={`/map`}
                    >
                      <a onClick={() => store.changeSelectedVehicle(car)}>
                        {car.locationName}
                      </a>
                    </Link>
                  </div>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(VehicleList);
