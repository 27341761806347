import gql from "graphql-tag";

const ALL_PARKING_LOTS = gql`
  query currentUser {
    getParkingLots {
      id
      name
      address
      city {
        id
        name
      }
    }
  }
`;

export { ALL_PARKING_LOTS };
