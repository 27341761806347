import moment from "moment-timezone";
import _ from "lodash";

export function getGpsSet(historyGps, slider, plate) {
  const sliderValue = Math.round(slider); //Round values to avoid marker jumping

  let startTime = moment(historyGps[0].capturedAt).unix();
  let endTime = moment(historyGps[historyGps.length - 1].capturedAt).unix();

  let targetTime = ((endTime - startTime) / 100) * sliderValue;
  let targetMoment = moment.unix(startTime + targetTime);

  const gpsPointsArray = historyGps.map(point => {
    return {
      point: point,
      date: point.capturedAt
    };
  });

  const closestDate = findClosestToDate(gpsPointsArray, targetMoment);

  return {
    vehicleData: {
      plate: plate,
      historyGps: [closestDate.point]
    },
    targetMoment: moment(closestDate.date).format("MMM D, YYYY h:mm A")
  };
}

function findClosestToDate(gpsPointsArray, targetDate) {
  const arrayLength = gpsPointsArray.length;
  const firstGpsPoint = gpsPointsArray[0];
  const lastGpsPoint = gpsPointsArray[arrayLength - 1];

  const midPoint = Math.floor(arrayLength / 2);
  const midPointDate = moment(gpsPointsArray[midPoint].date);

  if (targetDate.isSameOrBefore(firstGpsPoint.date)) {
    return firstGpsPoint;
  }
  if (targetDate.isSameOrAfter(lastGpsPoint.date)) {
    return lastGpsPoint;
  }

  if (targetDate.isSameOrBefore(midPointDate)) {
    const previousByOnePoint = moment(gpsPointsArray[midPoint - 1].date);
    if (targetDate.isBetween(previousByOnePoint.date, midPointDate)) {
      return previousByOnePoint;
    }
    return findClosestToDate(gpsPointsArray.slice(0, midPoint), targetDate);
  } else {
    const nextByOnePoint = moment(gpsPointsArray[midPoint + 1].date);
    if (targetDate.isBetween(midPointDate, nextByOnePoint.date)) {
      return midPoint;
    }
    return findClosestToDate(
      gpsPointsArray.slice(midPoint, arrayLength),
      targetDate
    );
  }
}

export async function getSnappedGps(gpsSet, interpolate) {
  //Creating the paramaters string for the Google snapToRoads API
  let params;
  let snappedArray = [];
  const googleUrl = "https://roads.googleapis.com/v1/snapToRoads?";

  const length = gpsSet.length;

  for (let i = 0; i < Math.ceil(length / 100); i++) {
    params = `path=${gpsSet[i * 100].lat},${gpsSet[i * 100].lng}`;

    // eslint-disable-next-line
    gpsSet.slice(i * 100 + 1, (i + 1) * 100).forEach(coord => {
      params += `|${coord.lat},${coord.lng}`;
    });

    params += `&interpolate=${interpolate}&key=${process.env.REACT_APP_G_MAPS_KEY}`;

    const response = await fetch(googleUrl + params);
    // const response = await fetch(url);
    const json = await response.json();

    let snappedPoints = Array.from(json.snappedPoints, point => {
      return {
        lat: point.location.latitude,
        lng: point.location.longitude,
        originalIndex: point.originalIndex + i * 100
      };
    });

    snappedArray = snappedArray.concat(snappedPoints);
  }

  return snappedArray;
}

//Takes set of GPS coordinates, and relates them to the interpolatedGps from the same set.
//This saves an Google API call
export function snapPoints(interpolatedGps, historyGps) {
  const returnValue = interpolatedGps
    .filter(point => point.originalIndex + 1)
    .map(point => {
      let coord;
      if (historyGps[point.originalIndex]) {
        coord = {
          lat: point.lat,
          lng: point.lng,
          capturedAt: historyGps[point.originalIndex].capturedAt,
          heading: historyGps[point.originalIndex].heading
        };
      }
      return coord;
    })
    .filter(Boolean);
  return returnValue;
}
