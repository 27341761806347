import React, { Component, Fragment } from "react";
import UploadCsv from "../UploadCsv";
import gql from "graphql-tag";

import _ from "lodash";
import "./uploadVehicle.scss";
import { useApolloClient } from "@apollo/client";

class UploadVehiclesChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      processedData: null,
      selectedPlate: "",
      tempSelectedPlate: "",
      uploaded: false,
      responseData: null
    };
  }

  UPLOAD_VEHICLES = gql`
    mutation uploadVehicles($vehicleData: [NewVehicleDataAttributes!]!) {
      uploadVehicles(vehicleData: $vehicleData) {
        response {
          success
          plate
          messages
        }
        errors
      }
    }
  `;

  processData = data => {
    return data.slice(1).map(vehicle => {
      const params = {};
      params["vehicleInfo"] = _.reduce(
        vehicle.slice(0, vehicleHeaders.length),
        (result, value, index) => {
          result[vehicleHeaders[index]] = value;
          return result;
        },
        {}
      );
      params["vehicleService"] = _.reduce(
        vehicle.slice(vehicleHeaders.length),
        (result, value, index) => {
          result[serviceHeaders[index]] = value;
          return result;
        },
        {}
      );
      return params;
    });
  };

  reset = () => {
    this.setState({
      data: null,
      processedData: null,
      selectedPlate: "",
      uploaded: false,
      responseData: null
    });
  };

  submitVehicles = () => {
    this.props.client
      .mutate({
        mutation: this.UPLOAD_VEHICLES,
        variables: {
          vehicleData: this.state.processedData
        }
      })
      .then(response => {
        if (!response.data.uploadVehicles.errors) {
          this.setState({
            responseData: response.data.uploadVehicles.response,
            uploaded: true
          });
        }
      });
  };

  getData = data => {
    const processedData = this.processData(data);
    this.setState({ data, processedData });
  };

  render() {
    const {
      data,
      processedData,
      selectedPlate,
      tempSelectedPlate,
      uploaded,
      responseData
    } = this.state;

    const selectedVehicle = _.find(
      processedData,
      element =>
        element.vehicleInfo.plate === (tempSelectedPlate || selectedPlate)
    );

    return (
      <div className="upload__csv">
        {uploaded ? (
          <Fragment>
            <table className="upload__reponse--container">
              {" "}
              <tr>
                <th> Plate </th>
                <th> Message </th>
              </tr>
              {responseData.map(data => {
                return (
                  <tr
                    className={`upload__reponse--${
                      data.success ? "success" : "error"
                    }`}
                  >
                    <td className="upload__reponse--plate">{data.plate}</td>
                    <td className="upload__reponse--messages">
                      {!data.messages
                        ? "Success"
                        : data.messages.map(message => <div> {message} </div>)}
                    </td>
                  </tr>
                );
              })}{" "}
            </table>
            <div className="upload__csv--buttons">
              <button onClick={this.reset}> Upload more </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {data ? (
              <div className="upload__csv--container">
                <div className="upload__csv--data">
                  {" "}
                  <div className="upload__csv--plates">
                    Plate
                    {processedData.map(vehicle => {
                      const plate = vehicle.vehicleInfo.plate;
                      return (
                        <div
                          className={`upload__csv--plates--block${
                            plate === (tempSelectedPlate || selectedPlate)
                              ? "-selected"
                              : ""
                          }`}
                          onMouseEnter={evt =>
                            this.setState({
                              tempSelectedPlate: plate
                            })
                          }
                          onMouseLeave={evt =>
                            this.setState({
                              tempSelectedPlate: ""
                            })
                          }
                          onClick={evt =>
                            this.setState({
                              selectedPlate: plate
                            })
                          }
                        >
                          {" "}
                          {plate}
                        </div>
                      );
                    })}
                  </div>{" "}
                  <div className="upload__csv--review">
                    {tempSelectedPlate || selectedPlate ? (
                      <Fragment>
                        <div className="upload__csv--vehicle-details">
                          Vehicle Details
                          {_.map(selectedVehicle.vehicleInfo, (value, key) => (
                            <div className="upload__csv--block">
                              <div className="upload__csv--title">
                                {_.startCase(key)}
                              </div>
                              <div className="upload__csv--text">
                                {value || "--"}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="upload__csv--service-history">
                          Service History
                          {_.map(
                            selectedVehicle.vehicleService,
                            (value, key) => (
                              <div className="upload__csv--block">
                                <div className="upload__csv--title">
                                  {_.startCase(key)}
                                </div>
                                <div className="upload__csv--text">
                                  {(value || "0") +
                                    (key === "safetyInspection" ? "" : "km")}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <div className="upload__csv--message">
                        {" "}
                        Select a plate to preview vehicle data{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="upload__csv--buttons">
                  <button onClick={this.reset}> Cancel Upload </button>
                  <button onClick={this.submitVehicles}>
                    {" "}
                    Confirm Upload{" "}
                  </button>
                </div>
              </div>
            ) : (
              <Fragment>
                <a
                  href="https://s3.us-east-2.amazonaws.com/autzu-team/csv_templates/upload_vehicle_template.csv"
                  download
                >
                  {" "}
                  Download CSV template{" "}
                </a>
                <UploadCsv getData={this.getData} />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const vehicleHeaders = [
  "plate",
  "make",
  "model",
  "year",
  "color",
  "odometer",
  "parkingLot",
  "parkingSpot",
  "lockboxCode",
  "vin",
  "transponder",
  "serviceGroupId",
  "fleetId",
  "rin"
];

const serviceHeaders = [
  "oilChange",
  "frontBrake",
  "rearBrake",
  "sparkPlugs",
  "sBelt",
  "transmissionFluid",
  "brakeSystemFlush",
  "coolantSystemFlush",
  "engineAirFilter",
  "cabinAirFilter",
  "safetyInspection"
];

const UploadVehicles = props => {
  const client = useApolloClient();
  return <UploadVehiclesChild client={client} {...props} />;
};

export default UploadVehicles;
