import React from "react";
import StatusList from "./StatusList";
import MainActionButton from "./MainActionButton";
import styles from "./actionButtons.module.scss";

const ActionButtons = ({ startStatusChange, operationStatus }) => {
  return (
    <div className={styles.buttonContainer}>
      <MainActionButton
        operationStatus={operationStatus}
        startStatusChange={startStatusChange}
      />
      <StatusList
        operationStatus={operationStatus}
        startStatusChange={startStatusChange}
      />
    </div>
  );
};

export default ActionButtons;
