import React, { useState, Fragment } from "react";
import styles from "../../batchActions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock } from "@fortawesome/pro-regular-svg-icons";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import DatePicker from "react-datepicker";

const ReminderPin = ({
  reminder,
  pin,
  isSaving,
  startDate,
  setStartDate,
  triggerReminder,
  triggerPin
}) => {
  return (
    <div className={styles.miniActionContainer}>
      {reminder && (
        <div className={styles.reminderContainer}>
          Remind on:{" "}
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            className={
              isSaving && !startDate ? styles.dateInputRed : styles.dateInput
            }
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/DD/YYYY LT"
            showTimeSelect
            timeCaption="Time"
            todayButton={"Today"}
            popperPlacement="top"
            placeholderText="MM/DD/YYYY"
          />
        </div>
      )}
      {reminder ? (
        <div className={styles.iconButtonBlack}>
          <FontAwesomeIcon icon={faAlarmClock} onClick={triggerReminder} />
        </div>
      ) : (
        <div className={styles.iconButton}>
          <FontAwesomeIcon icon={faAlarmClock} onClick={triggerReminder} />
        </div>
      )}
      {pin ? (
        <div className={styles.iconButtonBlack}>
          <FontAwesomeIcon icon={faThumbtack} onClick={triggerPin} />
        </div>
      ) : (
        <div className={styles.iconButton}>
          <FontAwesomeIcon icon={faThumbtack} onClick={triggerPin} />
        </div>
      )}
    </div>
  );
};

export default ReminderPin;
