import React, { useState, Fragment } from "react";
import styles from "../../../task.module.scss";
import classesNames from "classnames";

import CommandConfirm from "../../../../VehicleNew/ActionButtons/CommandConfirm";
import MessageBox from "../../../../VehicleNew/ActionButtons/MessageBox";
import { VEHICLE_ACTION } from "../../../queries";
import { useMutation } from "@apollo/client";
import ChangeStatusModal from "./ChangeStatusModal";

import { getAction } from "../../../utils";
import OptionList from "./OptionList";

const VehicleActionOptions = ({
  isOpen,
  taskData,
  toggleOpenState,
  curAction,
  setCurAction,
  vehicleId,
  refreshTaskCallback,
  toggleEdit,
}) => {
  const [modalOpen, setModalOpen] = useState();
  const [isChangeStatus, setIsChangeStatus] = useState();
  const [msgOpen, setMsgOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [vehicleUUID, setVehicleUUID] = useState();
  const [vehicleStatus, setVehicleStatus] = useState();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleChangeStatusModal = () => {
    setIsChangeStatus(!isChangeStatus);
  };

  const toggleMsgModal = () => {
    setMsgOpen(!msgOpen);
  };

  const toggleErrorModal = () => {
    setErrorOpen(!errorOpen);
  };

  const [vehicleActionMutation, { loading, error }] = useMutation(
    VEHICLE_ACTION
  );

  const sendCommand = () => {
    vehicleActionMutation({
      variables: {
        vehicleId: vehicleId,
        action: getAction(curAction),
      },
    }).then(({ data: { avcEvent } }) => {
      if (avcEvent.command) {
        setVehicleUUID(avcEvent.command.uuid);
        setVehicleStatus(avcEvent.command.status);
        toggleMsgModal();
        console.log("Succeed!");
      } else {
        toggleErrorModal();
        console.log("error!");
      }
    });
    toggleModal();
  };

  return (
    <Fragment>
      <div
        className={classesNames(
          styles.vehicleAbsoluteContainer,
          !isOpen && styles.hidden
        )}
      >
        <OptionList
          toggleChangeStatusModal={toggleChangeStatusModal}
          toggleModal={toggleModal}
          setCurAction={setCurAction}
          toggleOpenState={toggleOpenState}
          toggleEdit={toggleEdit}
        />
      </div>
      <CommandConfirm
        open={modalOpen}
        close={toggleModal}
        confirm={sendCommand}
        action={curAction}
      />
      <ChangeStatusModal
        vehicle={taskData?.vehicle}
        toggleRefresh={refreshTaskCallback}
        close={toggleChangeStatusModal}
        isChangeStatus={isChangeStatus}
        toggleOpenState={toggleOpenState}
      />
      <MessageBox
        open={msgOpen}
        close={toggleMsgModal}
        action={curAction}
        vehicleUUID={vehicleUUID}
        vehicleStatus={vehicleStatus}
        sendCommand={sendCommand}
        toggleRefresh={refreshTaskCallback}
        toggleModal={toggleModal}
      />
    </Fragment>
  );
};

export default VehicleActionOptions;
