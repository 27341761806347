import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import Button from "../artzu/src/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Components/Vehicle/vehicle.scss";
import { m } from "../utils";

class ReminderNote extends Component {
  REMINDER_NOTE = gql`
    mutation ReminderNote($reminderNoteParams: ReminderNoteAttributes!) {
      reminderNote(params: $reminderNoteParams) {
        success
        errors
      }
    }
  `;

  render() {
    const {
      reminderNoteParams,
      refetch,
      onChange,
      closeNote,
      resetNote
    } = this.props;
    const now = m();
    return (
      <Mutation
        mutation={this.REMINDER_NOTE}
        variables={{ reminderNoteParams }}
        onCompleted={() => {
          refetch();

          closeNote();
        }}
        onError={data => {
          console.log("error", data);
        }}
      >
        {reminderNote => (
          <Button
            label="Set Reminder"
            fullWidth="154px"
            height="39px"
            color="#128DFF"
            border="none"
            disabled={
              !reminderNoteParams.reminderDate ||
              m(reminderNote.reminderDate) < now
            }
            onClick={() => {
              reminderNote();
            }}
            weight="bold"
            size="17px"
          ></Button>
        )}
      </Mutation>
    );
  }
}

export default ReminderNote;
