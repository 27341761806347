import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const _tableBody = ({ children }) => (
  <StyledBody onScroll={() => console.log("ERE")}>{children}</StyledBody>
);

_tableBody.defaultProps = {};
