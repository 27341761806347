import React from "react";
import "../Fleet/fleet.css";
import styles from "./table.module.scss";
import "../Vehicle/vehicle.scss";
import classNames from "classnames";

const color = status => {
  switch (status) {
    case 3:
      return "red";
    case 2:
      return "yellow";
    case 1:
      return "green";
    case 4:
      return "grey";
    default:
      return "grey";
  }
};

const service = status => {
  switch (status) {
    case 3:
      return "Overdue";
    case 2:
      return "Warning";
    case 1:
      return "Good";
    case 4:
      return "N/A";
    default:
      return "N/A";
  }
};

const due = (mileage, unit) => {
  if (mileage && mileage < 0) {
    return `By ${-mileage}${unit}`;
  } else if (mileage && mileage >= 0) {
    return `Due in ${mileage}${unit}`;
  } else {
    return "";
  }
};

const VehicleStatus = ({ status, mileage, overdue, unit, display }) => (
  <div
    className={
      display === "details" ? "operation-container" : "first-fleet status-div"
    }
    style={
      display === "details"
        ? { textAlign: "left", paddingTop: "5px" }
        : { textAlign: "left" }
    }
  >
    <div>
      <div
        className={
          display === "details"
            ? `status-${color(status)} operation-symbol`
            : `status-${color(status)} status-icon`
        }
        style={display === "details" ? { margin: "0" } : null}
      />
    </div>
    <div className="status-box">
      <p
        className={classNames(
          "service-status",
          overdue
            ? styles.vehicleData__overdue
            : display === "details"
            ? "operation-status"
            : styles.serviceContent
        )}
      >
        {service(status)}
      </p>

      <p
        className={classNames(
          overdue ? styles.fleetGreyService__overdue : styles.fleetGreyService,
          "service-mileage"
        )}
      >
        {due(mileage, unit)}
      </p>
    </div>
  </div>
);

export default VehicleStatus;
