import { gql } from "@apollo/client";

const GET_DATA = gql`
  query informationPages(
    $filters: [[FilterAttributes!]!]
    $columnNames: [String!]
    $tableName: String!
    $paginationArguments: PaginationAttributes
    $sortArguments: SortAttributes
    $searchValue: String
    $selectedTab: String
    $parkingLotNames: [String!]
    $idToFilter: ID
    $currentAdminUserOnly: Boolean
    $additionalFilters: [AdditionalFilterAttributes!]
  ) {
    getInformationPage(
      filterGroups: $filters
      columnNames: $columnNames
      tableName: $tableName
      paginationArguments: $paginationArguments
      sortArguments: $sortArguments
      searchValue: $searchValue
      selectedTab: $selectedTab
      parkingLotNames: $parkingLotNames
      idToFilter: $idToFilter
      currentAdminUserOnly: $currentAdminUserOnly
      additionalFilters: $additionalFilters
    ) {
      table {
        totalRowCount
        rows {
          rowData {
            columnName
            columnData {
              value
              dataName
              dataType
            }
          }
          rowId
          rowLink
          rowTimeZone
        }
        headers {
          name
          displayedName
        }
      }
      filters {
        available {
          categoryName
          categoryUuid
          dataType
          operators {
            displayedName
            uuid
            valueOptions {
              value
              name
            }
            numberOfInputValues
          }
        }
        savedGroups {
          uuid
          filters {
            categoryUuid
            uuid
            values
            isExcluded
          }
        }
        parkingLotNames
      }
      columns {
        available {
          name
          displayedName
        }
        presets {
          name
          columnNames
          uuid
        }
        current {
          name
          displayedName
        }
      }
      sort {
        column
        direction
      }
      tabs {
        currentTab
        counts {
          tabName
          count
        }
      }
    }
  }
`;

const UPSERT_PRESET = gql`
  mutation upsertPreset(
    $tableName: String!
    $columnPreset: ColumnPresetAttributes!
  ) {
    upsertPreset(tableName: $tableName, columnPreset: $columnPreset) {
      preset {
        id
        name
        uuid
        columnNames
      }
      errors
    }
  }
`;

const DELETE_PRESET = gql`
  mutation deletePreset($uuid: String!) {
    deletePreset(uuid: $uuid) {
      success
      errors
    }
  }
`;

const SAVE_EDITS = gql`
  mutation bulkEdits($tableName: String!, $edits: [BulkEditAttributes!]!) {
    bulkEdits(tableName: $tableName, bulkEditArguments: $edits) {
      success
      errors
    }
  }
`;

export { GET_DATA, UPSERT_PRESET, DELETE_PRESET, SAVE_EDITS };
