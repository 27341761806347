import React, { Component } from "react";
import "./damage.scss";

class DamageImage extends Component {
  translateMarker = (damage) => {
    console.log(damage);
    const { width, height } = this.props;
    const xCoord = width * damage.locationX;
    var yCoord;
    if (damage.location === "Front Bumper") {
      if (damage.locationX <= 0.8) {
        yCoord = height * 0.92;
      } else {
        yCoord = height * 0.58;
      }
    } else if (damage.location === "Passenger Side Front Quarter Panel") {
      yCoord = height * 0.85;
    } else if (damage.location === "Passenger Side Rear Quarter Panel") {
      yCoord = height * 0.83;
    } else if (damage.location === "Rear Bumper") {
      if (damage.locationX <= 0.1) {
        yCoord = height * 0.58;
      } else {
        yCoord = height * 0.9;
      }
    } else if (
      damage.location === "Passenger Side Front Door" ||
      damage.location === "Passenger Side Rear Door"
    ) {
      yCoord = height * 0.87;
    } else if (
      damage.location === "Passenger Side Front Window" ||
      damage.location === "Passenger Side Rear Window"
    ) {
      yCoord = height * 0.78;
    } else if (
      damage.location === "Passenger Side Front Wheel" ||
      damage.location === "Passenger Side Rear Wheel" ||
      damage.location === "Passenger Side Bottom Trim"
    ) {
      yCoord = height * 0.95;
    } else if (damage.location === "Passenger Side Headlight") {
      if (damage.locationX > 0.78) {
        yCoord = height * 0.66;
      } else {
        yCoord = height * 0.855;
      }
    } else if (damage.location === "Passenger Side Taillight") {
      if (damage.locationX > 0.15) {
        yCoord = height * 0.82;
      } else {
        yCoord = height * 0.66;
      }
    } else if (damage.location === "Passenger Side Mirror") {
      yCoord = height * 0.7;
    } else if (damage.location === "Rear License Plate") {
      yCoord = height * 0.5;
    } else if (
      damage.location === "Trunk" ||
      damage.location === "Front Wipers" ||
      damage.location === "Bonnet"
    ) {
      yCoord = height * 0.55;
    } else if (
      damage.location === "Rear Window" ||
      damage.location === "Windshield" ||
      damage.location === "Roof"
    ) {
      yCoord = height * 0.47;
    } else if (damage.location === "Driver Side Headlight") {
      if (damage.locationX > 0.78) {
        yCoord = height * 0.35;
      } else {
        yCoord = height * 0.14;
      }
    } else if (damage.location === "Driver Side Taillight") {
      if (damage.locationX > 0.15) {
        yCoord = height * 0.18;
      } else {
        yCoord = height * 0.35;
      }
    } else if (damage.location === "Driver Side Front Quarter Panel") {
      yCoord = height * 0.14;
    } else if (damage.location === "Driver Side Rear Quarter Panel") {
      yCoord = height * 0.17;
    } else if (
      damage.location === "Driver Side Front Door" ||
      damage.location === "Driver Side Rear Door"
    ) {
      yCoord = height * 0.13;
    } else if (
      damage.location === "Driver Side Front Window" ||
      damage.location === "Driver Side Rear Window"
    ) {
      yCoord = height * 0.23;
    } else if (
      damage.location === "Driver Side Front Wheel" ||
      damage.location === "Driver Side Rear Wheel" ||
      damage.location === "Driver Side Bottom Trim"
    ) {
      yCoord = height * 0.044;
    } else if (damage.location === "Driver Side Mirror") {
      yCoord = height * 0.3;
    } else {
      yCoord = height * damage.locationY;
    }
    // const yCoord = height * damage.locationY;
    return `translate(${xCoord}px, ${yCoord}px)`;
  };

  getOpacity = (id, mode) => {
    let opacity = "standard-opacity";
    if (mode.match(/\d+/)) {
      if (mode !== id) {
        opacity = "light-opacity";
      } else {
        opacity = "opaque";
      }
    }
    return opacity;
  };

  render() {
    const { src, data, alterDamageState, mode } = this.props;
    return (
      <div className="damage-image-container">
        <img src={src} alt="Unavailable" className="damage-overview-image" />
        <div className="marker-container">
          {data.map((damage, index) => {
            return (
              <div
                key={damage.id}
                onClick={() => alterDamageState({ mode: damage.id })}
                className={`marker marker-large blue ${this.getOpacity(
                  damage.id,
                  mode
                )}`}
                style={{
                  transform: this.translateMarker(damage),
                  backgroundColor: "#2190fb",
                }}
              >
                <p className="white">{index + 1}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DamageImage;
