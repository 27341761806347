import React, { Fragment, useState } from "react";
import styles from "./damages.module.scss";
import InformationTable from "../../InformationTable";
import VehicleDamageTop from "./VehicleDamageTop";
import GalleryModal from "./Gallery/GalleryModal";
import { useQuery } from "@apollo/client";
import { GET_DAMAGES } from "./queries";
import DamagePage from "./DamagePage";

const VehicleDamages = ({ vehicleId, resourceId }) => {
  const [additionalFilters, setAdditionalFilters] = useState();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_DAMAGES, {
    variables: {
      vehicleId: vehicleId,
    },
  });
  console.log(data);
  const vehicleDamages = data?.getVehicleDamages;

  const toggleGallery = () => {
    setIsGalleryOpen(!isGalleryOpen);
  };

  return resourceId ? (
    <DamagePage vehicleId={vehicleId} damageId={resourceId} />
  ) : (
    <Fragment>
      <div className={styles.damagePage}>
        <VehicleDamageTop
          vehicleDamages={vehicleDamages}
          setAdditionalFilters={setAdditionalFilters}
          toggleGallery={toggleGallery}
          vehicleId={vehicleId}
          refetch={refetch}
        />
      </div>
      <div className={styles.damageIndexContainer}>
        <InformationTable
          tableName={"damages"}
          navbar={"Damages"}
          hasTabs={true}
          background={"grey"}
          rowComponentType={"damage"}
          startTerms={"Search"}
          item={"damages"}
          id={vehicleId}
          vehicleSpecific={true}
          additionalFilters={additionalFilters}
        />
      </div>
      <GalleryModal
        open={isGalleryOpen}
        toggleGallery={toggleGallery}
        vehicleDamages={vehicleDamages}
      />
    </Fragment>
  );
};

export default VehicleDamages;
