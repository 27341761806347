import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../../artzu/src/Modal";

const FullImage = ({ isFull, imageList, toggleImg, curImgIndex }) => {
  const imgCount = imageList.length - 1;
  const [curIdx, setCurIdx] = useState(curImgIndex);
  useEffect(() => {
    setCurIdx(curImgIndex);
  }, [curImgIndex]);

  const nextImg = () => {
    if (curIdx !== imgCount) {
      setCurIdx(curIdx + 1);
    } else {
      setCurIdx(0);
    }
  };

  const prevImg = () => {
    if (curIdx !== 0) {
      setCurIdx(curIdx - 1);
    } else {
      setCurIdx(imgCount);
    }
  };

  return (
    <Modal open={isFull} backgroundColor="none">
      {imageList[curIdx] !== "" && imageList[curIdx] !== null && (
        <div className="full-image-container">
          <img src={imageList[curIdx]} className="full-image-display"></img>
          <div className="prev-button" onClick={prevImg}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
          </div>
          <div className="next-button" onClick={nextImg}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" />
          </div>
          <div className="close-img" onClick={toggleImg}>
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FullImage;
