import React, { Component } from "react";
import { calcIconSize } from "../../utils/mapFuncs";

import Bounce from "bounce.js";

import "./map.scss";
import styles from "./icon.module.scss";
import { formatNavTitle } from "../../utils";

class MapIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  attachLocationAnimation = (id, translation) => {
    let bounce = new Bounce();

    bounce
      .translate({
        from: { x: 0, y: translation },
        to: { x: 0, y: translation - 50 },
        duration: 1250,
        easing: "sway",
        stiffness: 2,
      })
      .scale({
        from: { x: 1, y: 1 },
        to: { x: 2, y: 2 },
        duration: 1250,
        easing: "sway",
        stiffness: 2,
      });

    const elements = document.querySelectorAll(`#${id}`);
    bounce.applyTo(elements, { remove: true });
  };

  attachVehicleAnimation = (id, translation, heading) => {
    let bounce = new Bounce();

    bounce
      .scale({
        from: { x: 1, y: 1 },
        to: { x: 2, y: 2 },
        duration: 1250,
        easing: "sway",
        stiffness: 2,
      })
      .translate({
        from: { x: 0, y: translation },
        to: { x: 0, y: translation },
      })
      .rotate({ from: heading, to: heading });

    const elements = document.querySelectorAll(`#${id}`);
    bounce.applyTo(elements, { remove: true });
  };

  render() {
    const {
      id,
      icon,
      op,
      heading,
      type,
      store,
      vehicle,
      maps,
      updateMarkerDetails,
      parkingLot,
      serviceLocation,
      history,
    } = this.props;

    const size = calcIconSize(store.mapZoom);

    const locationTranslation = (-1 * (size * 3)) / 4;
    return (
      <div
        className={styles.iconContainer}
        ref={(ref) => ref && maps.OverlayView.preventMapHitsFrom(ref)}
        onClick={(event) => {
          if (type === "parking" || type === "service") {
            store.changeSelectedLocation(parkingLot || serviceLocation);
            store.changeNavigatedPage(
              formatNavTitle(
                null,
                history.location.pathname +
                  `/${
                    parkingLot
                      ? parkingLot.name.split(" ").join("-")
                      : serviceLocation.name.split(" ").join("-")
                  }`,
                null
              )
            );
            updateMarkerDetails();
            const translation = (-1 * (size * 3)) / 4;
            this.attachLocationAnimation(id, translation);
          } else {
            if (vehicle) {
              this.attachVehicleAnimation(
                id,
                (-1 * size) / 2,
                ((heading ? heading : 0) + 180) % 360
              );
              store.changeSelectedVehicle(vehicle);
              store.changeSelectedLocation();
              store.changeNavigatedPage(
                formatNavTitle(
                  store.selectedVehicle,
                  history.location.pathname,
                  null
                )
              );
              updateMarkerDetails();
            }
            return false;
          }
        }}
      >
        {(() => {
          let rightFactor, topFactor, fontSizeFactor, translation;
          switch (type) {
            case "parking":
            case "service":
              const vehiclesAtLocationCount = Number(
                type === "parking"
                  ? parkingLot.vehiclesAtLocation.length
                  : serviceLocation.vehiclesAtLocation.length
              );

              rightFactor = vehiclesAtLocationCount >= 10 ? 0.24468 : 0.3;
              topFactor = 0.136;
              fontSizeFactor = vehiclesAtLocationCount >= 10 ? 0.19 : 0.1826;

              return (
                <div
                  id={id}
                  className={styles.locationContainer}
                  style={{
                    opacity: `${op}`,
                    transform: `translateY(${locationTranslation}px)`,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      fontSize: `${fontSizeFactor * size}px`,
                      fontWeight: "700",
                      color: "white",
                      top: `${topFactor * size}px`,
                      right: `${rightFactor * size}px`,
                    }}
                    className={styles.locationCounter}
                  >
                    {vehiclesAtLocationCount}
                  </div>
                  <img
                    className="parking-marker"
                    src={icon}
                    alt={type}
                    height={`${size}`}
                    width={`${size}`}
                  />
                </div>
              );
            case "vehicle":
              return (
                <img
                  id={id}
                  className="vehicle-marker"
                  src={icon}
                  alt={type}
                  height={`${size}`}
                  width={`${size}`}
                  style={{
                    opacity: `${op}`,
                    transform: `translateY(${(-1 * size) /
                      2}px) rotate(${((heading ? heading : 0) + 180) %
                      360}deg)`,
                  }}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

export default MapIcon;
