import React, { useState } from "react";

const TaskContext = React.createContext([{}, () => {}]);
const InitialModalData = {
  name: "",
  status: "Pending",
  dueDate: "",
  plate: "",
  document: {},
  serviceEvent: {},
  user: { fullName: "", id: "" },
  comment: ""
};

// state store for task components
const TaskProvider = props => {
  const [state, setState] = useState({
    modal: false, // modal close/open state
    mode: "new", // modal type: new, view, edit
    searchVal: "",
    loading: false,
    modalError: [], // task modal validation errors
    modalData: InitialModalData, // task data, default is InitialModalData
    current: true, // true: current tasks, false: history tasks
    actionModal: false, // confirm window modal
    actionModalText: "Are you sure?",
    currentUser: null
  });

  return (
    <TaskContext.Provider value={[state, setState]}>
      {props.children}
    </TaskContext.Provider>
  );
};

export { TaskContext, TaskProvider, InitialModalData };
