import React from "react";
import ReminderModal from "./ReminderModal";
import ReminderNote from "../../../Mutations/ReminderNote";
import Button from "../../../artzu/src/Button";
import { m } from "../../../utils";
import DateInput from "./DateInput";

const ReminderModalPanel = ({
  reminderModal,
  toggleRemindersModal,
  newReminderDate,
  selectedNote,
  reminderDate,
  vehicleId,
  refetch,
  now,
  classes,
  selectReminderDate,
  addNewReminderDate,
  addNewReminder,
}) => {
  return (
    <ReminderModal
      open={reminderModal}
      closeModal={toggleRemindersModal}
      title="Edit Reminder"
    >
      <DateInput
        classes={classes}
        newReminderDate={newReminderDate}
        reminderDate={reminderDate}
        selectedNote={selectedNote}
        selectReminderDate={selectReminderDate}
        addNewReminderDate={addNewReminderDate}
      />
      <div className="edit-note-buttons">
        <div className="cancel-note">
          <Button
            label="Cancel"
            fullWidth="154px"
            height="39px"
            color="#414141"
            border="none"
            onClick={() => {
              toggleRemindersModal();
            }}
            weight="bold"
            size="17px"
          ></Button>
        </div>
        <div className="edit-note">
          {selectedNote ? (
            <ReminderNote
              reminderNoteParams={{
                noteId: selectedNote.id,
                details: selectedNote.details,
                vehicleId: vehicleId,
                userName: selectedNote.user,
                userId: selectedNote.userId,

                reminderDate: reminderDate,
              }}
              refetch={refetch}
              closeNote={toggleRemindersModal}
            />
          ) : (
            <Button
              label="Set Reminder"
              fullWidth="154px"
              height="39px"
              color="#128DFF"
              border="none"
              disabled={newReminderDate && m(newReminderDate) < now}
              onClick={() => {
                addNewReminder(true);
                toggleRemindersModal();
              }}
              weight="bold"
              size="17px"
            ></Button>
          )}
        </div>
      </div>
    </ReminderModal>
  );
};

export default ReminderModalPanel;
