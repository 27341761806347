import React, { useEffect, useState } from "react";
import styles from "./damages.module.scss";
import { getLocationIndex } from "./utils";

const LocationBox = ({
  location,
  values,
  setAdditionalFilters,
  curLocation,
  curSeverity,
  setCurLocation,
  setCurSeverity,
  changeTeslaImage,
  index,
  setIndex,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
    setIndex(index);
    changeTeslaImage();
  };

  const handleMouseOut = () => {
    setIsHovered(false);
    changeTeslaImage();
    setIndex(null);
  };

  const handleClickOutside = (e) => {
    setIsHovered(false);
    changeTeslaImage();
    setIndex(null);
    setCurLocation(null);
    setCurSeverity(null);
    setAdditionalFilters([]);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const setBackColor = () => {
    if (curLocation === location && curSeverity === null) {
      return "#dedede";
    } else if (isHovered) {
      return "#dedede";
    } else {
      return "white";
    }
  };

  return (
    <div
      className={isHovered ? styles.locationBoxHover : styles.locationBox}
      style={{
        backgroundColor: setBackColor(),
        border:
          curLocation === location && curSeverity === null
            ? "1px solid #414141"
            : "1px solid #dedede",
      }}
      onClick={() => {
        setAdditionalFilters([
          { name: "location", value: getLocationIndex(location) },
        ]);
        setCurLocation(location);
        setCurSeverity(null);
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {location}
      <div className={styles.locationDamages}>
        <div
          className={styles.damageCircleUnconfirmed}
          style={{
            border:
              curLocation === location && curSeverity === "unconfirmed"
                ? "2px solid #414141"
                : "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAdditionalFilters([
              { name: "location", value: getLocationIndex(location) },
              { name: "severity", value: "0" },
            ]);
            setCurLocation(location);
            setCurSeverity("unconfirmed");
          }}
        >
          <div className={styles.textUnconfirmed}>{values && values[0]}</div>
        </div>
        <div
          className={styles.damageCircleMinor}
          style={{
            border:
              curLocation === location && curSeverity === "minor"
                ? "2px solid #128dff"
                : "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAdditionalFilters([
              { name: "location", value: getLocationIndex(location) },
              { name: "severity", value: "1" },
            ]);
            setCurLocation(location);
            setCurSeverity("minor");
          }}
        >
          <div className={styles.textMinor}>{values && values[1]}</div>
        </div>
        <div
          className={styles.damageCircleMedium}
          style={{
            border:
              curLocation === location && curSeverity === "medium"
                ? "2px solid #ff8900"
                : "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAdditionalFilters([
              { name: "location", value: getLocationIndex(location) },
              { name: "severity", value: "2" },
            ]);
            setCurLocation(location);
            setCurSeverity("medium");
          }}
        >
          <div className={styles.textMedium}>{values && values[2]}</div>
        </div>
        <div
          className={styles.damageCircleSevere}
          style={{
            border:
              curLocation === location && curSeverity === "severe"
                ? "2px solid #f00"
                : "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAdditionalFilters([
              { name: "location", value: getLocationIndex(location) },
              { name: "severity", value: "3" },
            ]);
            setCurLocation(location);
            setCurSeverity("severe");
          }}
        >
          <div className={styles.textSevere}>{values && values[3]}</div>
        </div>
      </div>
    </div>
  );
};

export default LocationBox;
