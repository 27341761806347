import React from "react";
import styles from "./menu.module.scss";
import classesNames from "classnames";

const Menu = ({ isOpen, startEdit, startManageTag }) => {
  const options = [
    {
      onClick: startEdit,
      name: "Edit",
    },
    {
      onClick: startManageTag,
      name: "Manage tags",
    },
  ];

  return (
    <div
      className={classesNames(
        styles.absoluteContainer,
        !isOpen && styles.hidden
      )}
    >
      <div className={styles.menu}>
        {options.map((option) => (
          <div onClick={option.onClick} className={styles.menuOption}>
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
