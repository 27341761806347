import React, { Component } from "react";
import classNames from "classnames";

import { IconButton } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { withStyles } from "@material-ui/core/styles";

class ButtonContainer extends Component {
  render() {
    const { open, openDrawer, closeDrawer, classes } = this.props;
    return (
      <div className={"button-container"}>
        <IconButton
          onClick={openDrawer}
          className={classNames("list-button", open && classes.hide)}
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={closeDrawer}
          className={classNames("list-button", !open && classes.hide)}
        >
          <ChevronLeft />
        </IconButton>
      </div>
    );
  }
}

const styles = theme => {
  return {
    hide: { display: "none" }
  };
};

export default withStyles(styles)(ButtonContainer);
