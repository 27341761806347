import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { GET_TASK } from "./queries";
import { useRouteMatch } from "react-router";
import TaskHeader from "./TaskHeader";
import styles from "./task.module.scss";
import Loading from "../../Containers/Loading";
import TaskPageContent from "./TaskPageContent";

const TaskPage = ({ currentAdmin, vehicleId }) => {
  const match = useRouteMatch();
  const params = match.params;

  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(
    GET_TASK,
    {
      variables: { taskId: params.id },
    }
  );

  const taskData = data && data.getTask;

  const refreshTaskCallback = (newVariables) => {
    refetch(newVariables);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Tasks | Autzu Fleet</title>
      </Helmet>
      <div className={styles.taskPage}>
        <TaskHeader taskData={taskData} />
        {loading ? (
          <Loading />
        ) : (
          <TaskPageContent
            taskData={taskData}
            params={params}
            refreshTaskCallback={refreshTaskCallback}
            currentAdmin={currentAdmin}
            presetVehicleId={vehicleId}
          />
        )}
      </div>
    </Fragment>
  );
};

export default TaskPage;
