const bmwParts = [
	{
		id: "Rear Bumper",
		name: "Rear Bumper"
	},
	{
		id: "Rear License Plate",
		name: "Rear License Plate"
	},
	{
		id: "Trunk",
		name: "Trunk"
	},
	{
		id: "Driver Side Taillight",
		name: "Driver Side Taillight"
	},
	{
		id: "Passenger Side Taillight",
		name: "Passenger Side Taillight"
	},
	{
		id: "Rear Window",
		name: "Rear Window"
	},
	{
		id: "Rear Wipers",
		name: "Rear Wipers"
	},
	{
		id: "Driver Side Rear Quarter Panel",
		name: "Driver Side Rear Quarter Panel"
	},
	{
		id: "Driver Side Bottom Trim",
		name: "Driver Side Bottom Trim"
	},
	{
		id: "Driver Side Rear Wheel",
		name: "Driver Side Rear Wheel"
	},

	{
		id: "Passenger Side Rear Quarter Panel",
		name: "Passenger Side Rear Quarter Panel"
	},
	{
		id: "Passenger Side Bottom Trim",
		name: "Passenger Side Bottom Trim"
	},
	{
		id: "Passenger Side Rear Wheel",
		name: "Passenger Side Rear Wheel"
	},
	{
		id: "Driver Side Rear Door",
		name: "Driver Side Rear Door"
	},
	{
		id: "Driver Side Rear Window",
		name: "Driver Side Rear Window"
	},
	{
		id: "Driver Side Front Door",
		name: "Driver Side Front Door"
	},
	{
		id: "Driver Side Front Window",
		name: "Driver Side Front Window"
	},
	{
		id: "Driver Side Front Wheel",
		name: "Driver Side Front Wheel"
	},
	{
		id: "Driver Side Front Quarter Panel",
		name: "Driver Side Front Quarter Panel"
	},
	{
		id: "Driver Side Mirror",
		name: "Driver Side Mirror"
	},
	{
		id: "Roof",
		name: "Roof"
	},
	{
		id: "Passenger Side Rear Door",
		name: "Passenger Side Rear Door"
	},
	{
		id: "Passenger Side Rear Window",
		name: "Passenger Side Rear Window"
	},
	{
		id: "Passenger Side Front Door",
		name: "Passenger Side Front Door"
	},
	{
		id: "Passenger Side Front Window",
		name: "Passenger Side Front Window"
	},
	{
		id: "Passenger Side Front Wheel",
		name: "Passenger Side Front Wheel"
	},
	{
		id: "Passenger Side Mirror",
		name: "Passenger Side Mirror"
	},
	{
		id: "Passenger Side Front Quarter Panel",
		name: "Passenger Side Front Quarter Panel"
	},
	{
		id: "Windshield",
		name: "Windshield"
	},
	{
		id: "Front Wipers",
		name: "Front Wipers"
	},
	{
		id: "Bonnet",
		name: "Bonnet"
	},
	{
		id: "Driver Side Headlight",
		name: "Driver Side Headlight"
	},
	{
		id: "Grill",
		name: "Grill"
	},
	{
		id: "Passenger Side Headlight",
		name: "Passenger Side Headlight"
	},
	{
		id: "Front Bumper",
		name: "Front Bumper"
	},
	{
		id: "Front License Plate",
		name: "Front License Plate"
	}
];

let dodgeParts = bmwParts;
dodgeParts.splice(14, 0, {
	id: "Driver Side Center Window",
	name: "Driver Side Center Window"
});

dodgeParts.splice(22, 0, {
	id: "Passenger Side Center Window",
	name: "Passenger Side Center Window"
});

const interiorParts = [
	{
		name: "Cigarette Charger Socket",
		id: "Cigarette Charger Socket"
	},
	{ name: "Other", id: "Other" }
];

export { bmwParts, dodgeParts, interiorParts };
