import React from "react";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./vehicle.scss";

const CommandStatus = ({ icon, size, color, waiting }) => (
	<div className="stack-icons">
		<FontAwesomeIcon
			className={`${color} fast-spin stacked-icon`}
			icon={waiting ? faSpinnerThird : faCircle}
			size="4x"
			spin={waiting ? true : false}
		/>
		<FontAwesomeIcon
			className={`${color} stacked-icon`}
			icon={icon}
			size={size}
		/>
	</div>
);

export default CommandStatus;
