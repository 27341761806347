import React, { Component } from "react";
// import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSquareFull } from "@fortawesome/pro-regular-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../artzu/src/Button";
import { faLineColumns } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./selectfilter.css";

class SelectFilter extends Component {
  constructor() {
    super();
    this.state = {
      searchValues: {},
      selected: [],
      preset: {},
      all: null,
      filterBy: []
    };
  }

  componentDidMount() {
    this.setState({ selected: this.props.selection });
  }

  recordSelection = e => {
    let selection = this.state.selected;
    const preset = this.props.preset;
    let type = this.props.selectionType;
    let all = this.props.all;
    let filterBy = this.props.searchOptions;

    console.log(e.target.checked);
    if (e.target.checked === true) {
      if (e.target.id === "0") {
        this.props.onFilter(this.props.selectionType, 0);
        for (let selection in preset) {
          preset[selection] = false;
        }
        preset[e.target.name] = true;
        all = true;
        selection = [];
        filterBy = [];
      } else {
        if (preset["All"] === true) {
          selection = [];
          filterBy = [];
          for (let selection in preset) {
            preset[selection] = false;
          }
        }
        console.log(selection);
        console.log(e.target.name);
        console.log(filterBy);
        if (
          selection &&
          !selection.includes(e.target.value) &&
          !filterBy.includes(e.target.name)
        ) {
          selection.push(e.target.value);
          filterBy.push(e.target.name);
        } else {
          if (selection) {
            let index = selection.indexOf(e.target.value);
            selection.splice(index, 1);
            let indexF = filterBy.indexOf(e.target.name);
            filterBy.splice(indexF, 1);
            selection.push(e.target.value);
            filterBy.push(e.target.name);
          }
        }
        preset["All"] = false;
        this.props.onFilter(type, selection);
        preset[e.target.name] = true;
        all = false;
      }
    } else {
      if (selection.length > 0) {
        let index = selection.indexOf(e.target.value);
        selection.splice(index, 1);
        let indexF = filterBy.indexOf(e.target.name);
        filterBy.splice(indexF, 1);
        this.setState({ selected: selection, filterBy: filterBy });
        this.props.onFilter(type, selection);
      }
      preset[e.target.name] = false;

      if (
        preset["All"] === false &&
        selection.length < 1 &&
        filterBy.length < 1
      ) {
        all = true;
      } else {
        all = false;
      }

      if (all === true) {
        this.props.onFilter(type, 0);
        filterBy = [];
        for (let selection in preset) {
          preset[selection] = false;
        }
        preset["All"] = true;
      }
    }

    this.setState({
      selected: selection,
      preset: preset,
      all: all,
      filterBy: filterBy
    });

    this.props.changePreset(type, preset);
    this.props.allCheck(type, all);
    this.props.changeOptions(type, filterBy);
  };

  render() {
    const { checkList, selectionType, preset, all } = this.props;

    return (
      <div className="sf-wrapper">
        <div className="sf-list">
          <ul className="sf-ul">
            {checkList.map((item, index) => (
              <li className="sf-list-item">
                <Checkbox
                  name={item.name}
                  value={item.id}
                  id={index}
                  disabled={all === true && index === 0 ? true : false}
                  checked={
                    all === false && index === 0
                      ? false
                      : all === true && index !== 0
                      ? false
                      : all === true && index === 0
                      ? true
                      : preset[item.name] === true
                      ? true
                      : false
                  }
                  // defaultChecked={preset[item] === true ? true : false}
                  style={{ width: 12, height: 12, color: "#128DFF" }}
                  icon={
                    <FontAwesomeIcon
                      icon={faSquareFull}
                      style={{
                        fontSize: "12px",
                        color: "#128DFF",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      style={{
                        fontSize: "14px",
                        color: "#128DFF"
                      }}
                    />
                  }
                  width="12px"
                  height="12px"
                  onChange={this.recordSelection}
                  inputProps={{
                    "aria-label": "secondary checkbox"
                  }}
                />
                <div
                  className="sf-item-name"
                  style={
                    preset[item.name] === true ? { fontWeight: 700 } : null
                  }
                >
                  {item.name}
                  {item.qty ? (
                    <span
                      className="sf-item-number"
                      style={{ color: "#128DFF", padding: "0 0 0 2px" }}
                    >
                      ({item.qty})
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default SelectFilter;
