import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledHead = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  font-size: 16px;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  top: ${({ top }) => top};
  position: ${({ position }) => position};
  z-index: ${({ index }) => index};
`;
// border-bottom: 1px solid rgb(211, 211, 211);
// background-color: rgb(248, 248, 248);
export const _tableHead = ({
  width,
  children,
  top,
  position,
  index,
  margin
}) => (
  <StyledHead
    width={width}
    top={top}
    position={position}
    index={index}
    margin={margin}
  >
    {children}
  </StyledHead>
);

_tableHead.defaultProps = {
  width: "100%",
  children: "Children required for <TableHead>"
};

_tableHead.propTypes = {
  width: PropTypes.string,
  children: PropTypes.any.isRequired
};
