import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import Button from "../artzu/src/Button";
import "../Components/Vehicle/vehicle.scss";

class ResolveDamage extends Component {
  RESOLVE_DAMAGE = gql`
    mutation resolveDamage($damageIds: ResolveDamageAttributes!) {
      resolveDamage(params: $damageIds) {
        success
        errors
      }
    }
  `;

  render() {
    const { refetch, resolveIds, clear } = this.props;
    return (
      <Mutation
        mutation={this.RESOLVE_DAMAGE}
        onCompleted={() => {
          refetch();
          clear();
        }}
        variables={{ damageIds: { resolveIds } }}
        onError={data => {
          console.log("error", data);
        }}
      >
        {resolveDamage => (
          <Button
            onClick={() => resolveDamage()}
            label={resolveIds.length === 1 ? "Resolve" : "Resolve all"}
          />
        )}
      </Mutation>
    );
  }
}

export default ResolveDamage;
