import React from "react";

import { List, ListSubheader, ListItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  textInput: {
    borderColor: theme.palette.grey.light
  }
});

const inspectionNotes = ({ data, classes, onEdit }) => (
  <List
    subheader={
      <ListSubheader className={classes.subheader} disableSticky>
        {" "}
        Notes{" "}
      </ListSubheader>
    }
  >
    <ListItem>
      <textarea
        className={classNames("inspection-description", classes.textInput)}
        placeholder="Other issues/concerns..."
        value={data.description}
        onChange={evt => onEdit("description", evt.target.value)}
      />
    </ListItem>
  </List>
);

export default withStyles(styles)(inspectionNotes);
