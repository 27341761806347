import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { MERGE_TAGS } from "../../queries";
import MergeTagModal from "./MergeTagModal";

const MergeTag = ({
  open,
  toggleMerge,
  allTags,
  itemSelected,
  refetch,
  setItemSelected,
  refetchAddTag,
  entry,
}) => {
  const [combinedTag, setCombinedTag] = useState();

  const handleSelection = (e) => {
    setCombinedTag(e.target.value);
  };

  const findCount = (tag) => {
    let tagSelected = allTags.find((anyTag) => anyTag.name === tag);
    return tagSelected && tagSelected.attachedToCount;
  };

  const foundIdx = allTags.findIndex((x) => x.name === combinedTag);
  const tagId = allTags[foundIdx] && allTags[foundIdx].id;

  const findMergeIds = () => {
    if (itemSelected.length > 1) {
      var tagsToMerge = [...itemSelected];
      tagsToMerge = tagsToMerge
        .filter((item) => item !== combinedTag)
        .map((tag) => allTags.find((anytag) => anytag.name === tag));
      return tagsToMerge.map((tag) => tag.id);
    }
  };

  const [mergeTagMutation, { loading, error }] = useMutation(MERGE_TAGS);
  const mergeTags = () => {
    mergeTagMutation({
      variables: {
        tagIdToRetain: tagId,
        tagIdsToMerge: findMergeIds(),
      },
    }).then(({ data: { mergeTags } }) => {
      if (mergeTags.success) {
        refetch();
        entry && refetchAddTag();
        console.log("Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  return (
    <MergeTagModal
      open={open}
      toggleMerge={toggleMerge}
      setItemSelected={setItemSelected}
      handleSelection={handleSelection}
      findCount={findCount}
      mergeTags={mergeTags}
      itemSelected={itemSelected}
      combinedTag={combinedTag}
    />
  );
};

export default MergeTag;
