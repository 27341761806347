import React from "react";

import ListPopup from "../../../../Shareable/ListPopup";
import styles from "./entryOptions.module.scss";
import Button from "../../../../../artzu/src/Button";
import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client";

const CANCEL_STATUS = gql`
  mutation cancelStatus($identificationKey: String!) {
    cancelStatusInterval(identificationKey: $identificationKey) {
      success
      error
    }
  }
`;

const generateListItems = operationStatus => {
  if (["deactivated", "offline", "service"].includes(operationStatus)) {
    return [{ name: "Cancel", value: "cancel" }];
  } else {
    return [];
  }
};

const SelectedButton = ({ onClick }) => (
  <button className={styles.selectedButton} onClick={onClick}>
    ⋮
  </button>
);

const DefaultButton = ({ onClick }) => (
  <button className={styles.defaultButton} onClick={onClick}>
    ⋮
  </button>
);

const EntryModificationOptions = ({ operationStatus, identificationKey }) => {
  const listItems = generateListItems(operationStatus);

  const [cancelStatus] = useMutation(CANCEL_STATUS);

  const handleAction = value => {
    if (value === "cancel") {
      cancelStatus({
        variables: { identificationKey },
        refetchQueries: ["getVehicle"]
      }).then(value => console.log("cancelled", value));
    }
  };

  return (
    listItems.length > 0 && (
      <div>
        <ListPopup
          onItemClick={handleAction}
          listItems={listItems}
          buttonComponent={DefaultButton}
          selectedButtonComponent={SelectedButton}
        />
      </div>
    )
  );
};
export default EntryModificationOptions;
