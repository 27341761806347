import React, { useState } from "react";
import styles from "./selections.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import ConfirmModal from "./ConfirmModal";

const Selections = ({
  savedFilterGroups,
  selectFilterGroup,
  deleteFilterGroup,
  selectedRowsCount,
  clearAllSelections,
  tableName,
}) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [itemUuidToConfirm, setItemUuidToConfirm] = useState();

  const filterStyle = savedFilterGroups.length > 0 ? styles.filtersSelectionGroup: styles.filtersSelectionGroupEmpty

  const itemName = (tableName) => {
    switch (tableName) {
      case "vehicles":
        return "Vehicle";
      case "timeline_entries":
        return "Timeline";
      case "tasks":
        return "Task";
      case "parking_spots":
        return "Parking Spot";
      case "damages":
        return "Damage";
      default:
        break;
    }
  };

  return (
    <>
      <ConfirmModal
        isOpen={confirmIsOpen}
        confirmChanges={() => {
          deleteFilterGroup(itemUuidToConfirm);
          setConfirmIsOpen(false);
        }}
        text={"Are you sure you want to discard this filter?"}
        closeModal={() => setConfirmIsOpen(false)}
      />
      <div className={styles.selections}>
        <div className={filterStyle}>
          {savedFilterGroups.map((item, index) => (
            <>
              {index > 0 && <div className={styles.orText}> OR </div>}
              <div
                onClick={() => selectFilterGroup(item.uuid)}
                className={styles.selectionItem}
              >
                <div className={styles.selectionText}>
                  {`Filter ${index + 1}`}
                </div>
                <FontAwesomeIcon
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setItemUuidToConfirm(item.uuid);
                    setConfirmIsOpen(true);
                  }}
                  icon={faTimes}
                />
              </div>
            </>
          ))}
        </div>
        {selectedRowsCount > 0 && (
          <div className={styles.selectionItem}>
            <div
              className={styles.selectionText}
            >{`Selected: ${selectedRowsCount} ${itemName(tableName)}${
              selectedRowsCount > 1 ? "s" : ""
            }`}</div>

            <FontAwesomeIcon
              onClick={(evt) => {
                evt.stopPropagation();
                clearAllSelections();
              }}
              icon={faTimes}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Selections;
