import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../artzu/src/Button";
import _ from "lodash";

class UpdateServiceEvent extends Component {
  UPDATE_SERVICE_EVENT = gql`
    mutation update($updateServiceParams: UpdateServiceEventAttributes!) {
      updateServiceEvent(params: $updateServiceParams) {
        success
        bookingRelevant
        bookingSuccess
        errors
      }
    }
  `;

  filterParams = params => {
    delete params.vehicleId;
    Object.keys(params).forEach(key => {
      if (params[key] === "") {
        delete params[key];
      }
      if (
        ["treadDepth"].includes(key) &&
        !_.every(params[key], tread => tread !== "")
      ) {
        delete params[key];
      }
      if (["odometerReading", "cost", "id"].includes(key)) {
        params[key] = Number(params[key]);
      }
    });
    return params;
  };

  validateParams = () => {
    const { params, addErrors, prevOdometerReading } = this.props;
    const errors = [];
    if (params.name === "inspection") {
      if (
        Number(params.odometerReading) - Number(prevOdometerReading) >=
        5000
      ) {
        errors.push("odometer");
        return false;
      } else {
        return true;
      }
    } else {
      if (!params.description) {
        errors.push("descriptionRequired");
      }
      if (!params.vendor) {
        errors.push("noVendor");
      }
      if (!params.name) {
        errors.push("noName");
      }
      if (!params.odometerReading) {
        errors.push("noOdometer");
      }
      if (errors.length > 0) {
        addErrors(errors);
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const { params, clear, refetch, check, openConfirmModal } = this.props;

    return (
      <Mutation
        mutation={this.UPDATE_SERVICE_EVENT}
        variables={{ updateServiceParams: this.filterParams(params) }}
        onCompleted={data => {
          refetch();
          if (data.updateServiceEvent.bookingRelevant) {
            openConfirmModal(data.updateServiceEvent, "update");
          } else {
            clear();
          }
        }}
        onError={data => {
          console.log("error", data);
        }}
        refetchQueries={["getServiceEvents", "getAllVehicles", "getVehicle"]}
        awaitRefetchQueries={true}
      >
        {(updateServiceEvent, { loading }) =>
          loading ? (
            <div className="buttons-container">
              <div className="service-action-buttons">
                <FontAwesomeIcon spin icon={faSpinner} />
              </div>
            </div>
          ) : (
            <div>
              {check ? (
                <FontAwesomeIcon
                  className="check"
                  icon={faCheck}
                  onClick={() => {
                    updateServiceEvent();
                  }}
                />
              ) : (
                <div className="buttons-container">
                  <Button
                    onClick={() => {
                      if (this.validateParams()) {
                        updateServiceEvent();
                      }
                    }}
                    className="service-action-buttons"
                    label="Save changes"
                  />
                </div>
              )}
            </div>
          )
        }
      </Mutation>
    );
  }
}

export default UpdateServiceEvent;
