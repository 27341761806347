import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import "../Components/Vehicle/vehicle.scss";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

class UpdateLockboxCode extends Component {
  UPDATE_LOCKBOXCODE = gql`
    mutation updateParkingSpot(
      $updateParkingSpotParams: UpdateParkingSpotAttributes!
    ) {
      updateParkingSpot(params: $updateParkingSpotParams) {
        errors
        success
      }
    }
  `;

  render() {
    const {
      refetch,
      vehicleId,
      parkingSpot,
      toggleEdit,
      plate,
      type
    } = this.props;

    return (
      <Mutation
        mutation={this.UPDATE_LOCKBOXCODE}
        variables={{ updateParkingSpotParams: { vehicleId, parkingSpot } }}
        onCompleted={() => {
          toggleEdit();
          if (refetch) {
            refetch();
          }
        }}
        onError={data => console.log("update lock box code error", data)}
      >
        {updateParkingSpot => (
          <FontAwesomeIcon
            className="check"
            icon={faCheck}
            onClick={() => {
              updateParkingSpot();
            }}
          />
        )}
      </Mutation>
    );
  }
}

export default UpdateLockboxCode;
