import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const TimeInStatusValue = ({ cellData, classes }) => {
  const { text } = cellData;

  const timeValue = () => {
    if (parseInt(text) > 24) {
      return Math.round(parseInt(text) / 24);
    } else {
      return text;
    }
  };
  const timeUnit = () => {
    if (parseInt(text) > 24) {
      if (Math.round(parseInt(text) / 24) === 1) {
        return "day";
      } else {
        return "days";
      }
    } else if (parseInt(text) === 1) {
      return "hour";
    } else {
      return "hours";
    }
  };
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.textValue}>
        {timeValue()} {text && timeUnit()}
      </div>
    </div>
  );
};

export default TimeInStatusValue;
