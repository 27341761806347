import React from "react";
import Button from "../../artzu/src/Button";
import "../Vehicle/vehicle.scss";
import "./damage.scss";

const DamageHeading = ({ scope, alterDamageState, text }) => (
	<div className="damage-heading">
		<div className="damage-tabs">
			<h3
				className={`${scope === "exterior" ? "selected" : ""} damage-scope`}
				onClick={() => {
					alterDamageState({
						scope: "exterior",
						location: "",
						locationX: null,
						locationY: null
					});
				}}
			>
				Exterior
			</h3>
			<h3
				className={`${scope === "interior" ? "selected" : ""} damage-scope`}
				onClick={() => {
					alterDamageState({
						scope: "interior",
						location: "",
						locationX: null,
						locationY: null
					});
				}}
			>
				Interior
			</h3>
		</div>
		<Button
			onClick={() =>
				alterDamageState({ mode: text === "New" ? "new" : "overview" })
			}
			label={text}
		/>
	</div>
);

export default DamageHeading;
