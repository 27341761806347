import React from "react";
import classNames from "classnames";
import styles from "../../table.module.scss";

const ImageValue = ({ cellData, classes }) => {
  const { imageLink, subtext } = cellData;
  return (
    <div className={classNames(classes)}>
      <div className={styles.imageValue}>
        <img className={styles.image} src={imageLink} />
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </div>
  );
};

export default ImageValue;
