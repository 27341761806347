import { normalizeCoords, normalizeCircle } from "../helperFunctions.js";
import {
	frontDriverWindow,
	rearDriverWindow,
	centerDriverWindow,
	frontDriverDoor,
	rearDriverDoor,
	driverBottomTrim,
	rearDriverQuarterPanel,
	frontDriverQuarterPanel,
	frontDriverWheel,
	rearDriverWheel,
	frontDriverBumper,
	rearDriverBumper,
	sideDriverHeadlight,
	sideDriverTaillight,
	driverSideMirror,
	driverBonnet
} from "../../CoordArrays/Dodge/driverView";

const driverAreas = (width, height) => {
	return [
		{
			name: "Driver Side Mirror",
			shape: "poly",
			coords: normalizeCoords(driverSideMirror, width, height)
		},
		{
			name: "Driver Side Front Window",
			shape: "poly",
			coords: normalizeCoords(frontDriverWindow, width, height)
		},
		{
			name: "Driver Side Rear Window",
			shape: "poly",
			coords: normalizeCoords(rearDriverWindow, width, height)
		},
		{
			name: "Driver Side Center Window",
			shape: "poly",
			coords: normalizeCoords(centerDriverWindow, width, height)
		},
		{
			name: "Driver Side Rear Door",
			shape: "poly",
			coords: normalizeCoords(rearDriverDoor, width, height)
		},
		{
			name: "Driver Side Bottom Trim",
			shape: "poly",
			coords: normalizeCoords(driverBottomTrim, width, height)
		},
		{
			name: "Driver Side Rear Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(rearDriverQuarterPanel, width, height)
		},
		{
			name: "Driver Side Front Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(frontDriverQuarterPanel, width, height)
		},
		{
			name: "Front Bumper",
			shape: "poly",
			coords: normalizeCoords(frontDriverBumper, width, height)
		},
		{
			name: "Rear Bumper",
			shape: "poly",
			coords: normalizeCoords(rearDriverBumper, width, height)
		},
		{
			name: "Driver Side Front Wheel",
			shape: "circle",
			coords: normalizeCircle(...frontDriverWheel, width, height)
		},
		{
			name: "Driver Side Rear Wheel",
			shape: "circle",
			coords: normalizeCircle(...rearDriverWheel, width, height)
		},
		{
			name: "Driver Side Headlight",
			shape: "poly",
			coords: normalizeCoords(sideDriverHeadlight, width, height)
		},
		{
			name: "Driver Side Taillight",
			shape: "poly",
			coords: normalizeCoords(sideDriverTaillight, width, height)
		},
		{
			name: "Driver Side Front Door",
			shape: "poly",
			coords: normalizeCoords(frontDriverDoor, width, height)
		},
		{
			name: "Bonnet",
			shape: "poly",
			coords: normalizeCoords(driverBonnet, width, height)
		}
	];
};

export { driverAreas };
