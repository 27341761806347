import React, { useState } from "react";
import styles from "./damages.module.scss";
import tesla from "../../../images/damages/original.svg";
import driverFront from "../../../images/damages/df.svg";
import driverRear from "../../../images/damages/dr.svg";
import passengerFront from "../../../images/damages/pf.svg";
import passengerRear from "../../../images/damages/pr.svg";
import vLine from "../../../images/damages/vertical-line.svg";
import lLine from "../../../images/damages/landscape-line.svg";
import LocationBox from "./LocationBox";

const Diagram = ({
  driverFrontCounts,
  driverRearCounts,
  passengerFrontCounts,
  passengerRearCounts,
  setAdditionalFilters,
}) => {
  const [curLocation, setCurLocation] = useState();
  const [curSeverity, setCurSeverity] = useState();
  const [index, setIndex] = useState();

  const changeTeslaImage = () => {
    switch (index) {
      case 1:
        return driverFront;
      case 2:
        return passengerFront;
      case 3:
        return driverRear;
      case 4:
        return passengerRear;
      default:
        if (curLocation === "Driver front") {
          return driverFront;
        }
        if (curLocation === "Passenger front") {
          return passengerFront;
        }
        if (curLocation === "Driver rear") {
          return driverRear;
        }
        if (curLocation === "Passenger rear") {
          return passengerRear;
        }
        return tesla;
    }
  };

  return (
    <div className={styles.diagram}>
      <div className={styles.diagramLeft}>
        <LocationBox
          location={"Driver front"}
          index={1}
          setIndex={setIndex}
          values={driverFrontCounts}
          setAdditionalFilters={setAdditionalFilters}
          curLocation={curLocation}
          curSeverity={curSeverity}
          setCurLocation={setCurLocation}
          setCurSeverity={setCurSeverity}
          changeTeslaImage={changeTeslaImage}
        />
        <LocationBox
          location={"Driver rear"}
          index={3}
          setIndex={setIndex}
          values={driverRearCounts}
          setAdditionalFilters={setAdditionalFilters}
          curLocation={curLocation}
          curSeverity={curSeverity}
          setCurLocation={setCurLocation}
          setCurSeverity={setCurSeverity}
          changeTeslaImage={changeTeslaImage}
        />
      </div>
      <img className={styles.teslaDiagram} src={changeTeslaImage()}></img>

      <div className={styles.diagramRight}>
        <LocationBox
          location={"Passenger front"}
          index={2}
          setIndex={setIndex}
          values={passengerFrontCounts}
          setAdditionalFilters={setAdditionalFilters}
          curLocation={curLocation}
          curSeverity={curSeverity}
          setCurLocation={setCurLocation}
          setCurSeverity={setCurSeverity}
          changeTeslaImage={changeTeslaImage}
        />
        <LocationBox
          location={"Passenger rear"}
          index={4}
          setIndex={setIndex}
          values={passengerRearCounts}
          setAdditionalFilters={setAdditionalFilters}
          curLocation={curLocation}
          curSeverity={curSeverity}
          setCurLocation={setCurLocation}
          setCurSeverity={setCurSeverity}
          changeTeslaImage={changeTeslaImage}
        />
      </div>
      <div className={styles.carAndLines}>
        <img className={styles.verticalLine} src={vLine}></img>
        <img className={styles.horizontalLine} src={lLine}></img>
      </div>
    </div>
  );
};
export default Diagram;
