import React from "react";
import _ from "lodash";
import DamageActionContainer from "./DamageActionContainer";

const DamageActionModal = ({
  isOpen,
  selectedDamages,
  selectedBatchAction,
  batchOptionsHash,
  closeModal,
  toggleRefresh,
  clearAllSelections,
  removeSelection,
}) => {
  const selectedOption = batchOptionsHash[selectedBatchAction];
  return (
    <div>
      {selectedOption && (
        <DamageActionContainer
          selectedOption={selectedOption}
          selectedDamages={selectedDamages}
          close={closeModal}
          open={isOpen}
          toggleRefresh={toggleRefresh}
          clearAllSelections={clearAllSelections}
        />
      )}
    </div>
  );
};

export default DamageActionModal;
