import React, { useState } from "react";
import AddCategory from "./AddCategory";
import Filter from "./Filter";
import { v4 } from "uuid";

const Body = ({
  availableFilters,
  filters,
  onFilterEdit,
  removeFilter,
  addBlankFilter
}) => (
  <div>
    {filters &&
      filters.map((filter, index) => (
        <Filter
          key={filter.keyUuid}
          index={index}
          filter={filter}
          availableFilters={availableFilters}
          onEdit={valuesToChange => onFilterEdit(index, valuesToChange)}
          removeFilter={removeFilter}
          lineHasAndInIt={filters.length > 1 && index < filters.length - 1}
        />
      ))}
    <AddCategory addBlankFilter={addBlankFilter} />
  </div>
);

export default Body;
