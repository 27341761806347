import React from "react";
import { m } from "../../utils";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import "./damage.scss";

const Report = ({
	id,
	index,
	color,
	location,
	description,
	user,
	createdAt,
	addToResolve,
	removeFromResolve,
	alterDamageState,
	exterior,
	classes
}) => (
	<div
		className="individual-report"
		onClick={() => alterDamageState({ mode: id })}
	>
		<div className="check-container">
			<Checkbox
				onClick={evt => evt.stopPropagation()}
				onChange={(evt, checked) =>
					checked ? addToResolve(id) : removeFromResolve(id)
				}
				classes={{
					root: classes.root,
					checked: classes.checked
				}}
			/>
			<h4 className="report-location-check">{location}</h4>
			{exterior ? (
				<div
					className="marker marker-small marker-margin"
					style={{
						backgroundColor: color
					}}
				>
					<p className="white small-text">{index}</p>
				</div>
			) : null}
		</div>
		<div>
			<h4 className="report-created">{m(createdAt).format("LL")}</h4>
			<p className="report-description">{description}</p>
		</div>
	</div>
);

const styles = {
	root: {
		"&$checked": {
			color: "#03708f"
		}
	},
	checked: {}
};

export default withStyles(styles)(Report);
