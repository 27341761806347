import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import Button from "../artzu/src/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Components/Vehicle/vehicle.scss";

class EditNote extends Component {
  EDIT_NOTE = gql`
    mutation EditNote($editNoteParams: EditNoteAttributes!) {
      editNote(params: $editNoteParams) {
        success
        errors
      }
    }
  `;

  render() {
    const {
      editNoteParams,
      refetch,
      onChange,
      closeNote,
      resetNote,
      edited,
    } = this.props;

    return (
      <Mutation
        mutation={this.EDIT_NOTE}
        variables={{ editNoteParams }}
        onCompleted={() => {
          refetch();
          onChange("");
          resetNote();
          closeNote();
        }}
        onError={(data) => {
          console.log("error", data);
        }}
      >
        {(editNote) => (
          <Button
            label="Save"
            fullWidth="154px"
            height="39px"
            color="#128DFF"
            border="none"
            onClick={() => {
              if (edited) {
                editNote();
              } else {
                closeNote();
              }
            }}
            weight="bold"
            size="17px"
          ></Button>
        )}
      </Mutation>
    );
  }
}

export default EditNote;
