import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

class TargetedTime extends Component {
  render() {
    const { currentTime } = this.props;
    return <div>{currentTime}</div>;
  }
}

const styles = theme => ({
  label: {
    fontWeight: "700"
  }
});

export default withStyles(styles)(TargetedTime);
