import React from "react";
import Dropdown from "./Dropdown";
import TextInputs from "./TextInputs";
import DateInput from "./DateInput";

const createValueOptionItems = (valueOptions) =>
  valueOptions.map(({ value, name }) => ({
    name: name,
    value: value,
  }));

const ValuesInput = ({
  filter,
  selectedOperator: { valueOptions, numberOfInputValues },
  onEdit,
  dataType,
  error,
}) => {
  return (
    <>
      {valueOptions ? (
        <Dropdown
          initialValue={filter.values}
          items={createValueOptionItems(valueOptions)}
          onChange={(value) => onEdit([value])}
          label="Options"
        />
      ) : dataType == "datetime" ? (
        <DateInput
          initialValue={filter.values}
          onChange={(values) => onEdit(values)}
          numberOfInputValues={numberOfInputValues}
        />
      ) : (
        <TextInputs
          error={error}
          initialValue={filter.values}
          onChange={(values) => onEdit(values)}
          numberOfInputValues={numberOfInputValues}
        />
      )}
    </>
  );
};

export default ValuesInput;
