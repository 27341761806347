import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import TextDetail from "../../../VehicleNew/StatusLog/StatusInfo/Details/TextDetail";
import StatusChangeDetail from "../../../VehicleNew/StatusLog/StatusInfo/Details/StatusChangeDetail";
import DataChangeDetail from "../../../VehicleNew/StatusLog/StatusInfo/Details/DataChangeDetail";
import TagChangeDetail from "../../../VehicleNew/StatusLog/StatusInfo/Details/TagChangeDetail";
import ResourceDetail from "../../../VehicleNew/StatusLog/StatusInfo/Details/ResourceDetail";

const DetailsValue = ({ cellData, classes }) => {
  if (cellData) {
    const { details } = cellData;
    const detailInfoType = details.detailType;
    const content = details.content;
    const firstStatus =
      detailInfoType === "statusChange" && details.content.firstStatus;
    const secondStatus =
      detailInfoType === "statusChange" && details.content.secondStatus;
    const link = details.link && details.link;

    return (
      <div className={classNames(classes)}>
        {detailInfoType === "text" && <TextDetail content={content} />}
        {detailInfoType === "statusChange" && (
          <StatusChangeDetail
            firstStatus={firstStatus}
            secondStatus={secondStatus}
          />
        )}
        {detailInfoType === "dataChange" && (
          <DataChangeDetail content={content} link={link} />
        )}
        {detailInfoType === "tagChange" && (
          <TagChangeDetail content={content} />
        )}
        {detailInfoType === "viewResource" && (
          <ResourceDetail content={content} />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default DetailsValue;
