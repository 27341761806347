import React from "react";
import styles from "../../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const RemindedAdmins = ({ taggedAdmins, handleRemove }) => {
  return (
    <div className={styles.taskTagsContainer}>
      {taggedAdmins &&
        taggedAdmins.map((admin) => (
          <div className={styles.relatedTagContainer}>
            {admin}
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className={styles.tagTimes}
              onClick={() => {
                handleRemove(admin);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default RemindedAdmins;
