import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";

const NoteEdit = ({ editNote, selectedNote, note, updateNote }) => {
  const isSelected =
    editNote === true && selectedNote && note.id === selectedNote.id;
  const buttonClicked = { backgroundColor: "#414141" };
  const buttonUnclicked = { backgroundColor: "#FFFFFF" };
  return (
    <div
      className="iconButtons"
      style={isSelected ? buttonClicked : buttonUnclicked}
    >
      <FontAwesomeIcon
        className="pin-note"
        icon={faPencil}
        style={isSelected ? { color: "#FFFFFF" } : { color: "#414141" }}
        onClick={() => {
          updateNote(note);
        }}
      />
    </div>
  );
};

export default NoteEdit;
