import React, { useState } from "react";
import styles from "../../notes.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useMutation } from "@apollo/client";
import Button from "../../../../../../../artzu/src/Button";
import Modal from "../../../../../../../artzu/src/Modal";
import { DELETE_DAMAGE_NOTE } from "../../queries";

const NoteDelete = ({ note, refetch }) => {
  const [deleteOpen, setDeleteOpen] = useState();

  const toggleDeleteModal = () => {
    setDeleteOpen(!deleteOpen);
  };

  const closeModal = () => {
    setDeleteOpen(false);
  };

  const [deleteNoteMutation, { loading, error }] = useMutation(
    DELETE_DAMAGE_NOTE
  );
  const handleDeleteNote = () => {
    deleteNoteMutation({
      variables: {
        noteId: note.id,
      },
    }).then(({ data: { deleteDamageNote } }) => {
      if (deleteDamageNote.success) {
        refetch();
        console.log("Delete Note Succeed!");
      } else {
        console.log("Delete note error!");
      }
    });
  };

  return (
    <div>
      <div className={styles.iconButtons}>
        <FontAwesomeIcon
          className={styles.deleteNote}
          icon={faTrashAlt}
          onClick={toggleDeleteModal}
        />
      </div>
      <Modal open={deleteOpen} backgroundColor="none" padding="0">
        <div className={styles.deleteContainer}>
          <div className={styles.message}>
            Are you sure you want to delete this note?
          </div>
          <div className={styles.actionButtons}>
            <div>
              <Button
                label={"No"}
                fullWidth="152px"
                height="40px"
                color="#414141"
                backColor="#ffffff"
                radius="20px"
                size="16px"
                weight="700"
                border="1px solid #FFFFFF"
                onClick={closeModal}
              />
            </div>
            <div>
              <Button
                label={"Yes"}
                fullWidth="152px"
                height="40px"
                color="#128DFF"
                backColor="#FFFFFF"
                radius="20px"
                size="16px"
                weight="700"
                border="1px solid #FFFFFF"
                onClick={() => {
                  handleDeleteNote();
                  toggleDeleteModal();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NoteDelete;
