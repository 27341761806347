import { normalizeCoords, normalizeCircle } from "../helperFunctions.js";
import {
	frontPassengerWindow,
	rearPassengerWindow,
	frontPassengerDoor,
	rearPassengerDoor,
	passengerBottomTrim,
	rearPassengerQuarterPanel,
	frontPassengerQuarterPanel,
	frontPassengerWheel,
	rearPassengerWheel,
	frontPassengerBumper,
	rearPassengerBumper,
	sidePassengerHeadlight,
	sidePassengerTaillight,
	passengerSideMirror
} from "../../CoordArrays/BMW/passengerView";

const passengerAreas = (width, height) => {
	return [
		{
			name: "Passenger Side Rear Window",
			shape: "poly",
			coords: normalizeCoords(rearPassengerWindow, width, height)
		},
		{
			name: "Passenger Side Rear Door",
			shape: "poly",
			coords: normalizeCoords(rearPassengerDoor, width, height)
		},
		{
			name: "Passenger Side Bottom Trim",
			shape: "poly",
			coords: normalizeCoords(passengerBottomTrim, width, height)
		},
		{
			name: "Passenger Side Rear Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(rearPassengerQuarterPanel, width, height)
		},
		{
			name: "Passenger Side Front Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(frontPassengerQuarterPanel, width, height)
		},
		{
			name: "Front Bumper",
			shape: "poly",
			coords: normalizeCoords(frontPassengerBumper, width, height)
		},
		{
			name: "Rear Bumper",
			shape: "poly",
			coords: normalizeCoords(rearPassengerBumper, width, height)
		},
		{
			name: "Passenger Side Front Wheel",
			shape: "circle",
			coords: normalizeCircle(...frontPassengerWheel, width, height)
		},
		{
			name: "Passenger Side Rear Wheel",
			shape: "circle",
			coords: normalizeCircle(...rearPassengerWheel, width, height)
		},
		{
			name: "Passenger Side Headlight",
			shape: "poly",
			coords: normalizeCoords(sidePassengerHeadlight, width, height)
		},
		{
			name: "Passenger Side Taillight",
			shape: "poly",
			coords: normalizeCoords(sidePassengerTaillight, width, height)
		},
		{
			name: "Passenger Side Front Window",
			shape: "poly",
			coords: normalizeCoords(frontPassengerWindow, width, height)
		},
		{
			name: "Passenger Side Mirror",
			shape: "poly",
			coords: normalizeCoords(passengerSideMirror, width, height)
		},
		{
			name: "Passenger Side Front Door",
			shape: "poly",
			coords: normalizeCoords(frontPassengerDoor, width, height)
		}
	];
};

export { passengerAreas };
