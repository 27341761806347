import React from "react";
import styles from "../../task.module.scss";
import LicensePlateDetailsField from "./LicensePlateDetailsField";
import StatusDetailsField from "./StatusDetailsField";
import Dropdown from "../../../Shared/Dropdown";

const VehicleDetailsField = ({
  text,
  fieldValue,
  vehicleData,
  statusStyle,
  isEdit,
  curSpot,
  setCurSpot,
  allAvailableSpots,
}) => {
  const detailFieldContent = (vehicleData, statusStyle, fieldValue) => {
    if (text === "License plate") {
      return (
        <LicensePlateDetailsField
          vehicleData={vehicleData}
          fieldValue={fieldValue}
        />
      );
    } else if (text === "Operation status" || text == "Lock status") {
      return (
        <StatusDetailsField statusStyle={statusStyle} fieldValue={fieldValue} />
      );
    } else if (text === "Spot" && isEdit) {
      return (
        <Dropdown
          initialValue={curSpot}
          items={allAvailableSpots}
          onChange={(e) => setCurSpot(e)}
        />
      );
    } else {
      return <div className={styles.detailField}>{fieldValue}</div>;
    }
  };

  return (
    <div className={styles.vehicleDetailsField}>
      <div className={styles.subscript}>{text}</div>
      {detailFieldContent(vehicleData, statusStyle, fieldValue)}
    </div>
  );
};

export default VehicleDetailsField;
