import React from "react";
import { Redirect, useParams } from "react-router-dom";
import gql from "graphql-tag";
import { damageRedirectString } from "../../Queries/queryStrings";
import { Query } from "@apollo/client/react/components";
import Loading from "../Fleet/Loading";

const DamageRedirect = ({ location }) => {
  const plate = location.search.split("=")[1];

  console.log(plate);
  return (
    <Query
      query={gql`
        ${damageRedirectString}
      `}
      variables={{
        vehiclePlate: plate
      }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <Loading />;
        } else if (data) {
          const id = data.getVehicle.id;
          return <Redirect to={`/vehicles/${id}/damage`} />;
        }
      }}
    </Query>
  );
};

export default DamageRedirect;
