import React from "react";
import moment from "moment";

const OdometerContent = ({ odometer, formatOdometer, city }) => {
  return (
    <div className="vehicle-information">
      <p className="tight-margin-details">Odometer</p>
      <div>
        {odometer ? (
          <div className="tight-margin-content">
            {formatOdometer(odometer.reading) +
              (city.name === "toronto" ? "km" : " mi")}
            <div style={{ fontSize: "70%", color: "grey" }}>
              Last updated{" "}
              {moment(odometer.capturedAt).format("MMM D, YYYY h:mm a")}
            </div>
          </div>
        ) : (
          <div className="tight-margin-content">"--"</div>
        )}
      </div>
    </div>
  );
};

export default OdometerContent;
