import React, { Fragment } from "react";
import { CREATE_DAMAGE_TASK, GET_DAMAGE_TASKS } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { TableCell, TableRow } from "../../../../artzu/src/Table";
import styles from "../damages.module.scss";
import { m, titleize } from "../../../../utils";
import { getTaskTagBackColor, getTaskTagColor } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const TasksBody = ({ damageId }) => {
  const { data, loading, refetch } = useQuery(GET_DAMAGE_TASKS, {
    variables: {
      damageId: damageId,
    },
  });
  console.log(data);
  const tasks = data?.getDamageTasks;

  const [createTaskMutation] = useMutation(CREATE_DAMAGE_TASK);
  const handleCreate = () => {
    createTaskMutation({
      variables: {
        damageId: damageId,
      },
    }).then(({ data: { createDamageTask } }) => {
      if (createDamageTask.success) {
        refetch();
        console.log("Create task succeed!");
      } else {
        console.log("create task error!");
      }
    });
  };
  return (
    <Fragment>
      <TableRow
        justify="space-between"
        align="center"
        padding="12px 32px"
        color="#fff"
      >
        <TableCell width="10%" align={"left"} bold>
          Status
        </TableCell>
        <TableCell width="12.5%" align={"left"} bold>
          Type
        </TableCell>
        <TableCell width="14%" align={"left"} bold>
          Description
        </TableCell>

        <TableCell width="8%" align={"left"} bold>
          Days in status
        </TableCell>
        <TableCell width="14%" align={"left"} bold>
          Due date
        </TableCell>
        <TableCell width="14%" align={"left"} bold>
          Date created
        </TableCell>
        <TableCell width="10%" align={"left"} bold>
          Origin
        </TableCell>
        <TableCell width="20%" align={"left"} bold>
          Tags
        </TableCell>
      </TableRow>
      {tasks &&
        tasks.map((task, i) => {
          return (
            <div>
              <a
                key={i}
                className={styles.link}
                href={`/tasks/${task.id}`}
                target="_blank"
              >
                <TableRow
                  justify="space-between"
                  align="center"
                  padding="12px 32px"
                  color="#fff"
                  margin="8px 0"
                  height="64px"
                >
                  <TableCell width="10%" align={"left"}>
                    <div
                      className={styles.taskTag}
                      style={{
                        color: `${getTaskTagColor(task.status)}`,
                        backgroundColor: `${getTaskTagBackColor(task.status)}`,
                        border: `1px ${getTaskTagBackColor(
                          task.status
                        )} solid;`,
                      }}
                    >
                      {titleize(task.status)}
                    </div>
                  </TableCell>
                  <TableCell width="12.5%" align={"left"}>
                    {task.taskType}
                  </TableCell>
                  <TableCell width="14%" align={"left"}>
                    {task.description}
                  </TableCell>

                  <TableCell width="8%" align={"left"}>
                    {task.daysInStatus}
                  </TableCell>
                  <TableCell width="14%" align={"left"}>
                    {task.dueDate
                      ? m(task.dueDate).format("hh:mm A MM/DD/YYYY z")
                      : "-"}
                  </TableCell>
                  <TableCell width="14%" align={"left"}>
                    {task.createdAt
                      ? m(task.createdAt).format("hh:mm A MM/DD/YYYY z")
                      : "-"}
                  </TableCell>
                  <TableCell width="10%" align={"left"}>
                    {task.origin}
                  </TableCell>
                  <TableCell width="20%" align={"left"}>
                    <div className={styles.taskTags}>
                      {task.tags.map((tag) => (
                        <div
                          className={styles.tag}
                          style={{ backgroundColor: "#dedede" }}
                        >
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              </a>
            </div>
          );
        })}
      <div className={styles.addTaskButtonContainer} onClick={handleCreate}>
        <button
          className={styles.addButton}
          style={{ backgroundColor: "#fff", fontSize: "16px", border: "none" }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add task
        </button>
      </div>
    </Fragment>
  );
};

export default TasksBody;
