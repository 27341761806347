import React, { Component } from "react";
import { observer } from "mobx-react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import GetCoordinates from "./GetCoordinates";
import Loading from "../Components/Fleet/Loading";

import { bookingString, coordinatesString } from "./queryStrings";
import { spreadMapData } from "../utils";
import FleetView from "../Components/MapView/FleetView";

class GetBookings extends Component {
  render() {
    const { store, bStore, history } = this.props;
    return (
      <Query
        query={gql`
          ${bookingString}
        `}
        pollInterval={60000}
      >
        {({ loading, error, data, client }) => {
          if (error) {
            console.log(error);
            return null;
          }
          if (loading) {
            return <Loading size="3x" />;
          }
          if (data.getCurrentVehicleBookings) {
            const bookings = data.getCurrentVehicleBookings;
            return (
              <GetCoordinates
                store={store}
                bStore={bStore}
                bookings={bookings}
                history={history}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default observer(GetBookings);
