import { normalizeCoords } from "../helperFunctions.js";
import {
  frontBumper,
  frontPassengerHeadlight,
  frontDriverHeadlight,
  frontGrill,
  frontBonnet,
  frontPlate,
  frontDriverSideMirror,
  frontPassengerSideMirror,
  frontWipers,
  frontWindshield,
} from "../../CoordArrays/BMW/frontView";

const frontAreas = (width, height) => {
  return [
    {
      name: "Passenger Side Headlight",
      shape: "poly",
      coords: normalizeCoords(frontPassengerHeadlight, width, height),
    },
    {
      name: "Driver Side Headlight",
      shape: "poly",
      coords: normalizeCoords(frontDriverHeadlight, width, height),
    },
    {
      name: "Driver Side Mirror",
      shape: "poly",
      coords: normalizeCoords(frontDriverSideMirror, width, height),
    },
    {
      name: "Passenger Side Mirror",
      shape: "poly",
      coords: normalizeCoords(frontPassengerSideMirror, width, height),
    },
    {
      name: "Grill",
      shape: "poly",
      coords: normalizeCoords(frontGrill, width, height),
    },
    {
      name: "Bonnet",
      shape: "poly",
      coords: normalizeCoords(frontBonnet, width, height),
    },
    {
      name: "Front Wipers",
      shape: "poly",
      coords: normalizeCoords(frontWipers, width, height),
    },
    {
      name: "Windshield",
      shape: "poly",
      coords: normalizeCoords(frontWindshield, width, height),
    },
    {
      name: "Front License Plate",
      shape: "poly",
      coords: normalizeCoords(frontPlate, width, height),
    },
    {
      name: "Front Bumper",
      shape: "poly",
      coords: normalizeCoords(frontBumper, width, height),
    },
  ];
};

export { frontAreas };
