import React from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch
} from "@material-ui/core/";

import { faTireFlat } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TreadDepthCheckup from "./TreadDepthCheckup";
const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  }
});

const TireCheckupList = ({ classes, data, onEdit }) => (
  <List
    subheader={
      <ListSubheader className={classes.subheader} disableSticky>
        {" "}
        Tires{" "}
      </ListSubheader>
    }
  >
    <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon
          className="inspection-icon"
          icon={faTireFlat}
          fixedWidth
        />
      </ListItemIcon>
      <ListItemText primary={"Tire Pressure"} />
      <ListItemSecondaryAction>
        <Switch
          onChange={event => onEdit("tirePressure", event.target.checked)}
          checked={data["tirePressure"]}
          classes={{
            switchBase: classes.switch,
            checked: classes.colorChecked,
            track: classes.colorBar
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
    <TreadDepthCheckup data={data} onEdit={onEdit} />
  </List>
);

export default withStyles(styles)(TireCheckupList);
