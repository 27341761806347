import React from "react";
import ListPopup from "./ListPopup";
import classNames from "classnames";
import styles from "../../../../Shareable/ListPopup/listPopup.module.scss";
import Button from "../../../../../artzu/src/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";

const ButtonComponent = ({ onClick }) => (
  <FontAwesomeIcon icon={faEllipsisH} onClick={onClick} />
);

const SelectedButtonComponent = ({ onClick }) => (
  <FontAwesomeIcon
    className={styles.selected}
    icon={faEllipsisH}
    onClick={onClick}
  />
);

const StatusList = ({ operationStatus, vehicle, toggleRefresh }) => {
  const statuses =
    vehicle.make === "Tesla"
      ? [
          { name: "Change status", value: "Change status" },
          { name: "Honk horn", value: "Honk horn" },
          { name: "Flash lights", value: "Flash lights" },
          { name: "Start Vehicle", value: "Start Vehicle" },
          { name: "Wake Up", value: "Wake Up" },
          { name: "Sentry Mode On", value: "Sentry Mode On" },
          { name: "Sentry Mode Off", value: "Sentry Mode Off" },
          { name: "Valet Mode On", value: "Valet Mode On" },
          { name: "Valet Mode Off", value: "Valet Mode Off" }
        ]
      : [{ name: "Change status", value: "Change status" }];

  return (
    <div>
      <ListPopup
        currentValue={operationStatus}
        listItems={statuses}
        buttonComponent={ButtonComponent}
        selectedButtonComponent={SelectedButtonComponent}
        vehicle={vehicle}
        toggleRefresh={toggleRefresh}
      />
    </div>
  );
};

export default StatusList;
