import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const TextValue = ({ cellData, classes }) => {
  const { text, subtext } = cellData;
  return (
    <div
      className={classNames(classes)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.textValue}>{text}</div>
      {subtext && <div className={styles.subtext}>{subtext}</div>}
    </div>
  );
};

export default TextValue;
