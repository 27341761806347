import React, { useState, Fragment } from "react";
import styles from "../../../batchActions.module.scss";
import Button from "../../../../../../artzu/src/Button";
import classsNames from "classnames";
import CommonTags from "./CommonTags";
import { useMutation } from "@apollo/client";
import ManageTags from "../ManageTags";
import AddNewTags from "./AddNewTags";
import { BULK_MODIFY_TAGS } from "../queries";

const AddTagContent = ({
  selectedOption,
  close,
  vehicles,
  alltags,
  commonTags,
  callback,
  toRemove,
  refetch,
  toggleRefresh,
}) => {
  const [manage, setManage] = useState(false);
  const [tag, setTag] = useState();
  const [added, setAdded] = useState([]);
  const [deleted, setDeleted] = useState(false);

  const allTagList = [];
  alltags.map((a) => allTagList.push(a.name));
  const triggerTag = (childData) => {
    setTag(childData);
  };

  const handleAdd = () => {
    if (tag !== undefined && allTagList.includes(tag)) {
      setAdded((arr) => [...arr, tag]);
    }
    allTagList.includes(tag) && !duplicate && setTag("");
  };

  const deleteAddedTag = (t) => {
    setAdded((arr) => arr.filter((items) => items !== t));
  };

  const toggleManageTags = () => {
    setManage(!manage);
  };

  const triggerDeleted = () => {
    setDeleted(true);
  };

  let duplicate = commonTags.includes(tag);

  const vehicleIDs = [];
  vehicles.map((vehicle) => vehicleIDs.push(vehicle.id));

  const [data, { loading, error }] = useMutation(BULK_MODIFY_TAGS);
  const updateTags = () => {
    {
      !duplicate &&
        data({
          variables: {
            vehicleIds: vehicleIDs,
            tagsToRemove: toRemove,
            newTags: added,
          },
        }).then(({ data: { bulkModifyVehicleTags } }) => {
          if (bulkModifyVehicleTags.success) {
            close();
            console.log("Succeed!");
          } else {
            console.log("error!");
          }
        });
    }
  };
  return (
    <Fragment>
      <ManageTags
        selected={selectedOption}
        toggleManageTags={toggleManageTags}
        close={close}
        manage={manage}
        alltags={alltags}
        refetchAddTag={refetch}
      />
      <div
        className={classsNames(
          styles.contentContainer,
          styles.contentContainer__addNote
        )}
      >
        <h4>New tag</h4>
        <AddNewTags
          allTagList={allTagList}
          newTag={tag}
          triggerTag={triggerTag}
          parentCallback={handleAdd}
          added={added}
          commonTags={commonTags}
          pc={deleteAddedTag}
          duplicate={duplicate}
        />
        <CommonTags
          common={commonTags}
          newTag={tag}
          parentCallback={callback}
          pcTrigger={triggerDeleted}
        />
        <div className={styles.saveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={added.length !== 0 || deleted ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              (added.length !== 0 || deleted) && updateTags();
              (added.length !== 0 || deleted) && toggleRefresh();
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AddTagContent;
