import React, { Component, Fragment } from "react";
import ImageMapper from "react-image-mapper";

import { topAreas as topAreasBMW } from "./AreaObjects/BMW/topAreas";
import { frontAreas as frontAreasBMW } from "./AreaObjects/BMW/frontAreas";
import { rearAreas as rearAreasBMW } from "./AreaObjects/BMW/rearAreas";
import { passengerAreas as passengerAreasBMW } from "./AreaObjects/BMW/passengerAreas";
import { driverAreas as driverAreasBMW } from "./AreaObjects/BMW/driverAreas";

import { topAreas as topAreasDodge } from "./AreaObjects/Dodge/topAreas";
import { frontAreas as frontAreasDodge } from "./AreaObjects/Dodge/frontAreas";
import { rearAreas as rearAreasDodge } from "./AreaObjects/Dodge/rearAreas";
import { passengerAreas as passengerAreasDodge } from "./AreaObjects/Dodge/passengerAreas";
import { driverAreas as driverAreasDodge } from "./AreaObjects/Dodge/driverAreas";

import "./damage.scss";

class NewDamageImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devMode: false,
      // newAreaCoords: [],
      mouseMove: {
        x: null,
        y: null,
      },
      imagePos: {
        x: null,
        y: null,
      },
    };
  }

  setRight = () => {
    const image = document.getElementById("image-map-container");
    const imageRect = image.getBoundingClientRect();
    this.setState({
      imagePos: { x: imageRect.left, y: imageRect.top },
    });
  };

  // pushNewAreaCoords = evt => {
  // 	const { devMode } = this.state;
  // 	if (devMode) {
  // 		this.setState({
  // 			newAreaCoords: [
  // 				...this.state.newAreaCoords,
  // 				...this.convertCoords(evt.clientX, evt.clientY)
  // 			]
  // 		});
  // 	}
  // };

  // removeLastCoord = () => {
  // 	let coords = this.state.newAreaCoords;
  // 	coords.splice(coords.length - 2, 2);
  // 	this.setState({ newAreaCoords: coords });
  // };

  convertCoords = (x, y) => {
    const { width, height } = this.props;
    const { imagePos } = this.state;
    const xRatio = Math.round(((x - imagePos.x) / width) * 10000) / 10000;
    const yRatio = Math.round(((y - imagePos.y) / height) * 10000) / 10000;
    return [xRatio, yRatio];
  };

  render() {
    const { src, alterDamageState, width, height, make } = this.props;
    const { devMode, mouseMove } = this.state;
    const imageMap = {
      name: "damageReport",
      areas:
        make === "Dodge" || make === "Mercedes Benz"
          ? [
              ...topAreasDodge(width, height),
              ...frontAreasDodge(width, height),
              ...rearAreasDodge(width, height),
              ...passengerAreasDodge(width, height),
              ...driverAreasDodge(width, height),
              // {
              // 	name: "inProgressDodge",
              // 	shape: "poly",
              // 	coords: normalizeCoords(newAreaCoords, width, height)
              // }
            ]
          : [
              ...topAreasBMW(width, height),
              ...frontAreasBMW(width, height),
              ...rearAreasBMW(width, height),
              ...passengerAreasBMW(width, height),
              ...driverAreasBMW(width, height),
              // {
              // 	name: "inProgressBMW",
              // 	shape: "poly",
              // 	coords: normalizeCoords(newAreaCoords, width, height)
              // }
            ],
    };

    return (
      <Fragment>
        <div
          id="image-map-container"
          style={devMode ? { backgroundColor: "blue" } : {}}
          className="damage-image-container"
          // onClick={evt => this.pushNewAreaCoords(evt)}
          // onMouseMove={evt =>
          // 	this.setState({ mouseMove: { x: evt.clientX, y: evt.clientY } })
          // }
        >
          <ImageMapper
            width={width}
            onLoad={() => this.setRight()}
            className="damage-overview-image"
            src={src}
            map={imageMap}
            strokeColor="#fb7272"
            fillColor="rgba(0,0,0, 0.3)"
            lineWidth={3}
            onClick={(area, index, evt) => {
              const coords = this.convertCoords(evt.clientX, evt.clientY);
              alterDamageState({
                location: area.name,
                locationX: coords[0],
                locationY: coords[1],
              });
            }}
          />
        </div>
        {devMode ? (
          <Fragment>
            <p
              className="remove-last-coord"
              onClick={() => this.removeLastCoord()}
            >
              Remove Last Coord
            </p>
            <p>
              {mouseMove.x} px, {mouseMove.y} px
            </p>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default NewDamageImage;
