import React from "react";
import TextField from "@material-ui/core/TextField";
import "./damage.scss";

const OtherLocation = ({ alterDamageState }) => (
  <div className="other-location">
    <p>Name: </p>
    <TextField
      onChange={evt => alterDamageState({ location: evt.target.value })}
      placeholder="Other location"
    />
  </div>
);

export default OtherLocation;
