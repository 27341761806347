import React from "react";
import _ from "lodash";
import { Fragment } from "react";
import Dropdown from "../InformationTable/Shared/Dropdown";
import Button from "../../artzu/src/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import styles from "./vehicles_available.module.scss";

const Navigation = ({
  fleetsAtSelectedLot,
  parkingLots,
  getNextWeek,
  getPrevWeek,
  onParkingLotChange,
  onFleetChange,
  onCriteriaChange,
  startDate,
}) => {
  const items = [
    { name: "Availability", id: "availability" },
    // { name: "In/Out", id: "in/out" },
    { name: "Utilization", id: "utilization" },
    { name: "In service", id: "in_service" },
  ];

  const tiers = fleetsAtSelectedLot.map((fleet) => {
    return { name: fleet.name.toUpperCase(), id: fleet.id };
  });

  const lots = parkingLots.map((lot) => {
    return { name: lot.name, id: lot.id };
  });

  return (
    <Fragment>
      {" "}
      <div className={styles.avaFilter}>
        <div className={styles.dropdowns}>
          <div className={styles.avaDropdown}>
            <div className={styles.dropdownTitle}>Criteria</div>
            <Dropdown
              initialValue={items[0].id}
              items={items}
              onChange={onCriteriaChange}
            />
          </div>
          <div className={styles.avaDropdown}>
            <div className={styles.dropdownTitle}>Parking Lot</div>
            <Dropdown
              initialValue={lots[0].id}
              items={lots}
              onChange={onParkingLotChange}
            />
          </div>
          <div className={styles.avaDropdown}>
            <div className={styles.dropdownTitle}>Tier</div>
            <Dropdown
              initialValue={tiers[0].id}
              items={tiers}
              onChange={onFleetChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.monthTitle}>
        {moment(startDate).format("MMMM Y")}
      </div>
      <div className={styles.carsContainer__top}>
        <div className={styles.weekSelectorLeft} onClick={getPrevWeek}>
          <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </div>
        <div className={styles.weekSelectorRight} onClick={getNextWeek}>
          <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </div>
      </div>
    </Fragment>
  );
};

export default Navigation;
