import React, { useState } from "react";
import styles from "../../task.module.scss";
import moment from "moment-timezone";
import DueDateDatePicker from "./DueDateDatePicker";
import TaskDueDate from "./TaskDueDate";

const DueDate = ({ taskData, dueDateCallback, isEdit, isCancel }) => {
  const defaultDuedate = taskData?.dueDate && moment(taskData.dueDate);
  const [curDue, setCurDue] = useState(defaultDuedate);

  const dueDateContent = () => {
    if (taskData) {
      if (isEdit) {
        return (
          <DueDateDatePicker
            curDue={curDue}
            setCurDue={setCurDue}
            dueDateCallback={dueDateCallback}
          />
        );
      } else if (taskData.dueDate) {
        return <TaskDueDate taskData={taskData} />;
      } else {
        return <div className={styles.noVehicle}>No due date</div>;
      }
    } else {
      return (
        <DueDateDatePicker
          curDue={curDue}
          setCurDue={setCurDue}
          dueDateCallback={dueDateCallback}
        />
      );
    }
  };

  const removeColor = taskData?.dueDate ? "#ff0000" : "#ffbaba";
  const removeCursor = taskData?.dueDate ? "pointer" : "default";

  return (
    <div className={styles.dueDateContainer}>
      <div className={styles.dueDateHeader}>
        <h2>Due date</h2>
        {isEdit && (
          <div
            style={{
              color: removeColor,
              cursor: removeCursor,
            }}
            onClick={() => {
              taskData?.dueDate && setCurDue(null);
              taskData?.dueDate && dueDateCallback(null);
            }}
          >
            Remove
          </div>
        )}
      </div>
      {dueDateContent()}
    </div>
  );
};

export default DueDate;
