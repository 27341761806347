import React from "react";
import DeleteNote from "../../../../../Mutations/DeleteNote";

const NoteDelete = ({ note, refetch }) => {
  return (
    <div className="deleteNote">
      <DeleteNote noteId={note.id} refetch={refetch} />
    </div>
  );
};

export default NoteDelete;
