import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import "../Components/Vehicle/vehicle.scss";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PinNote extends Component {
  PIN_NOTE = gql`
    mutation pinNote($pinNoteParams: PinNoteAttributes!) {
      pinNote(params: $pinNoteParams) {
        success
        errors
      }
    }
  `;

  render() {
    const { refetch, pinNoteParams, pinCheck, noteId, note } = this.props;

    return (
      <Mutation
        mutation={this.PIN_NOTE}
        onCompleted={() => refetch()}
        variables={{ pinNoteParams }}
        onError={(data) => {
          console.log("error", data);
        }}
      >
        {(pinNote) => (
          <div
            className="iconButtons"
            style={
              pinNoteParams.pinned === true
                ? {
                    backgroundColor: "#414141",
                  }
                : {
                    backgroundColor: "#FFFFFF",
                  }
            }
          >
            <FontAwesomeIcon
              className="pin-note"
              icon={faThumbtack}
              style={
                pinNoteParams.pinned === true
                  ? {
                      color: "#FFFFFF",
                    }
                  : {
                      color: "#414141",
                    }
              }
              onClick={() => {
                pinNote();
              }}
            />
          </div>
        )}
      </Mutation>
    );
  }
}

export default PinNote;
