import React, { useEffect, useState } from "react";
import styles from "../damages.module.scss";
import Modal from "../../../../artzu/src/Modal";

import _ from "lodash";
import GalleryRight from "./GalleryRight";
import GalleryLeft from "./GalleryLeft";

const GalleryModal = ({ open, toggleGallery, vehicleDamages }) => {
  const initial = vehicleDamages?.currentDamages[0]?.imageLinks?.length;

  const [location, setLocation] = useState();
  const [curDamages, setCurDamages] = useState();
  const [curDamageIdx, setCurDamageIdx] = useState(0);
  const [curMaxDmgIdx, setCurMaxDmgIdx] = useState();
  const [changePoint, setChangePoint] = useState(initial);
  const [curImageIdx, setCurImageIdx] = useState(0);
  const [curIdx, setCurIdx] = useState(0);

  useEffect(() => {
    setCurDamages(vehicleDamages?.currentDamages);
    setCurMaxDmgIdx(vehicleDamages?.currentDamages?.length);
    setChangePoint(vehicleDamages?.currentDamages[0]?.imageLinks?.length - 1);
  }, [vehicleDamages]);

  useEffect(() => {
    if (location === "all") {
      setCurDamages(vehicleDamages?.currentDamages);
      setCurMaxDmgIdx(vehicleDamages?.currentDamages?.length);
      setChangePoint(vehicleDamages?.currentDamages[0]?.imageLinks?.length - 1);
    } else {
      setCurDamages(
        vehicleDamages?.currentDamages.filter(
          (dmg) => dmg.location === location
        )
      );
      setCurMaxDmgIdx(
        vehicleDamages?.currentDamages.filter(
          (dmg) => dmg.location === location
        ).length
      );
      setChangePoint(
        vehicleDamages?.currentDamages.filter(
          (dmg) => dmg.location === location
        ) &&
          vehicleDamages?.currentDamages.filter(
            (dmg) => dmg.location === location
          )[0]?.imageLinks?.length - 1
      );
    }
  }, [location]);

  const triggerLocation = (e) => {
    setCurIdx(0);
    setCurDamageIdx(0);
    setCurImageIdx(0);
    setLocation(e);
  };

  const handlePrev = () => {
    if (curDamageIdx >= 0 && curIdx > 0) {
      setCurIdx(curIdx - 1);
      if (curImageIdx > 0) {
        setCurImageIdx(curImageIdx - 1);
      } else if (curDamages[curDamageIdx - 1]?.imageLinks.length > 0) {
        setCurImageIdx(curDamages[curDamageIdx - 1]?.imageLinks.length - 1);
      }
      if (
        curIdx <=
          changePoint - curDamages[curDamageIdx]?.imageLinks?.length + 1 &&
        curDamageIdx > 0
      ) {
        setCurDamageIdx(curDamageIdx - 1);
        if (curDamages[curDamageIdx]?.imageLinks.length > 0) {
          setChangePoint(
            changePoint - curDamages[curDamageIdx]?.imageLinks?.length
          );
        } else {
          setChangePoint(changePoint - 1);
        }
      }
    }
  };

  const handleNext = () => {
    if (
      curDamageIdx <= curMaxDmgIdx - 1 &&
      curIdx <
        curDamages
          .map((dmg) => dmg.imageLinks.length)
          .reduce((a, b) => a + b, 0) +
          curDamages.filter((dmg) => dmg.imageLinks?.length === 0)?.length -
          1
    ) {
      setCurIdx(curIdx + 1);
      if (curImageIdx < curDamages[curDamageIdx]?.imageLinks.length - 1) {
        setCurImageIdx(curImageIdx + 1);
      } else {
        setCurImageIdx(0);
      }

      if (curIdx > changePoint - 1 && curDamageIdx < curMaxDmgIdx - 1) {
        setCurDamageIdx(curDamageIdx + 1);
        if (curDamages[curDamageIdx + 1]?.imageLinks.length > 0) {
          setChangePoint(
            changePoint + curDamages[curDamageIdx + 1]?.imageLinks?.length
          );
        } else {
          setChangePoint(changePoint + 1);
        }
      }
    }
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.galleryContainer}>
        <GalleryLeft
          curDamages={curDamages}
          curDamageIdx={curDamageIdx}
          curImageIdx={curImageIdx}
          curMaxDmgIdx={curMaxDmgIdx}
          triggerLocation={triggerLocation}
          handlePrev={handlePrev}
          handleNext={handleNext}
        />
        <GalleryRight
          curDamages={curDamages}
          curDamageIdx={curDamageIdx}
          toggleGallery={toggleGallery}
          triggerLocation={triggerLocation}
        />
      </div>
    </Modal>
  );
};

export default GalleryModal;
