import React from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import styles from "../../../../InformationTable/BatchActions/batchActions.module.scss";
import { Fragment } from "react";

const ChangeStatusDatePicker = ({ startDate, setStartDate }) => {
  return (
    <Fragment>
      <div className={styles.choice}>
        <input type="radio" name="tag" value="future" /> Scheduled change for
        future
      </div>
      <div className={styles.datepicker}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className={styles.dateInput}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMM D, YYYY h:mm a"
          showTimeSelect
          timeCaption="Time"
          todayButton={"Today"}
          popperPlacement="top"
          placeholderText="Select date & time"
        />
        <div className={styles.chevronDown}>
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{
              fontSize: "12px",
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ChangeStatusDatePicker;
