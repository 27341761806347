import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "./sidebar.module.scss";

class NavigationSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active, title, click } = this.props;
    return (
      <div
        onClick={() => {
          click(title);
        }}
        className={styles.title}
        style={
          active
            ? { color: "#414141", opacity: "1" }
            : ["Camera", "AVC", "Tickets"].includes(title)
            ? { opacity: "0.20" }
            : null
        }
      >
        {title}
      </div>
    );
  }
}

export default NavigationSidebar;
