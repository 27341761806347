import React from "react";
import "./service.css";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";

const TextBox = ({ label, type, val, accessor, onEdit, classes }) => (
  <div className="text-input">
    <h3>{label}</h3>
    <Input
      classes={{ underline: classes.underline }}
      type={type ? type : "text"}
      placeholder={label}
      value={val}
      onChange={evt => onEdit(accessor, evt.target.value)}
    />
  </div>
);

const styles = theme => ({
  underline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.blue}`
    }
  }
});

export default withStyles(styles)(TextBox);
