import React from "react";
import { Link } from "react-router-dom";
import "./vehicle.scss";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VehicleRedirect = ({ plate, tab }) => {
  return (
    <div className="vehicle-path">
      <Link
        className="redirect-link"
        to={{
          pathname: `/`,
        }}
      >
        Vehicle
      </Link>
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{ fontSize: "17px", marginLeft: "10", marginTop: "2" }}
      />
      <div className="path-details">
        {plate} - {tab}
      </div>
    </div>
  );
};

export default VehicleRedirect;
