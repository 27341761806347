import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const BubbleTextValue = ({ cellData, classes }) => {
  const { text } = cellData;
  return (
    <div className={classNames(classes)}>
      <div className={styles.bubbleTextContainer}>
        <div className={styles.textBubble}>{text}</div>
      </div>
    </div>
  );
};

export default BubbleTextValue;
