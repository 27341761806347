import React, { Component } from "react";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./shareable.module.scss";
import _ from "lodash";

class NavDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPath: null
    };
  }

  selectPath = name => {
    this.setState({ selectedPath: name });
  };

  componentDidMount() {
    const { navPath, store } = this.props;
  }

  render() {
    const { navPath, history, navFunction, mapView, tab } = this.props;
    const { selectedPath } = this.state;

    return (
      <div
        className={styles.navPath}
        style={mapView ? { width: "100%", marginLeft: "0" } : null}
      >
        {navPath.map((path, index) =>
          index > 0 ? (
            <div
              className={styles.pathContainer}
              onClick={() => {
                if (navFunction) {
                  this.selectPath(path.title);
                  if (mapView) {
                    navFunction(path.title);
                  }
                } else if (path.navFunction) {
                  if (tab) {
                    path.navFunction(_.last(path.navPathName.split("/")));
                    console.log(_.last(path.navPathName.split("/")));
                    this.selectPath(path.title);
                  }
                } else {
                  if (history) {
                    if (path.navPathName === "/vehicles") {
                      history.push("/");
                    } else {
                      history.push(path.navPathName);
                    }

                    this.selectPath(path.title);
                  }
                }
              }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                color={
                  path.title === _.last(navPath).title ? "black" : "#414141"
                }
                style={{ fontSize: "17px" }}
              />
              <div
                className={
                  path.title === _.last(navPath).title
                    ? styles.navPathTitle__selected
                    : styles.navPathTitle
                }
              >
                {path.title}
              </div>
            </div>
          ) : (
            <div
              className={
                index === navPath.length - 1
                  ? styles.navTitleInitial__selected
                  : styles.navTitleInitial
              }
              onClick={() => {
                if (navFunction) {
                  this.selectPath(path.title);
                  navFunction(path.title);
                } else {
                  if (path.navPathName === "/vehicles") {
                    history.push("/");
                  } else {
                    history.push(path.navPathName);
                  }

                  this.selectPath(path.title);
                }
              }}
            >
              {path.title}
            </div>
          )
        )}
      </div>
    );
  }
}
export default NavDisplay;
