import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import styles from "../../task.module.scss";

const AccidentReportMessage = ({ msg, accidentReport, toggleAccident }) =>
  msg.label === "accident" &&
  accidentReport && (
    <div className={styles.accidentReportMessage}>
      <div className={styles.acInfo}>
        <div className={styles.acTitle}>
          <FontAwesomeIcon icon={faFile} onClick={toggleAccident} size="2x" />
          Accident report
        </div>
        <a
          className={styles.ticketUrl}
          href={accidentReport.ticketReferenceUrl}
          target="_blank"
        >
          Ticket reference
        </a>
      </div>
      <button className={styles.viewReportButton} onClick={toggleAccident}>
        View report
      </button>
    </div>
  );

export default AccidentReportMessage;
