import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";

import TicketModal from "./TicketModal";
import RenderImage from "../RenderImage";
import { m } from "../../../utils";

class TicketDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketModal: false,
      ticket: false
    };
  }

  handleClick = ticket => {
    this.setState({ ticketModal: true, ticket });
  };

  handleClose = () => {
    this.setState({ ticketModal: false, ticket: null });
  };

  render() {
    const { tickets, refetch } = this.props;
    const { ticketModal, ticket } = this.state;

    const orderedTickets = _.orderBy(
      tickets,
      ["expiryDate", "description", "status"],
      ["asc", "asc", "desc"]
    );

    if (orderedTickets.length < 1) {
      return <div>No records</div>;
    } else {
      return (
        <Fragment>
          {ticketModal ? (
            <TicketModal
              ticketModal={ticketModal}
              ticket={ticket}
              handleClose={this.handleClose}
              refetch={refetch}
            />
          ) : null}
          <table className="upload-table">
            <thead>
              <tr>
                <th />
                <th>Title</th>
                <th>Date Uploaded</th>
                <th>Date Updated</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orderedTickets.map(ticket => (
                <tr
                  key={ticket.uploadedAt}
                  onClick={() => this.handleClick(ticket)}
                >
                  <td>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        window.open(ticket.imageLink, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <RenderImage
                        src={ticket.imageLink}
                        imageClass="upload-image"
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      wordBreak: "break-all",
                      textTransform: "capitalize"
                    }}
                  >
                    {ticket.description}
                  </td>
                  <td>{m(ticket.uploadedAt).format("MMM D, YYYY")}</td>
                  <td>{m(ticket.updatedAt).format("MMM D, YYYY")}</td>
                  <td>
                    {ticket.expiryDate === null
                      ? "N/A"
                      : m(ticket.expiryDate).format("MMM D, YYYY")}
                  </td>
                  <td>{ticket.status}</td>
                  <td>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        window.open(ticket.imageLink, "_blank");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowToBottom}
                        style={{ color: "#128DFF" }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      );
    }
  }
}

export default TicketDetails;
