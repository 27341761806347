import React, { Component } from "react";
import { TableRow, TableCell } from "../../../artzu/src/Table";
import styles from "./sidebar.module.scss";
import TabItem from "./TabItem";
import VehicleTitle from "./VehicleTitle";
import { titleize } from "../../../utils";

const VehicleSidebar = ({
  vehicleInfo,
  operationStatus,
  tabs,
  selectedTab,
  triggerTab,
}) => {
  const { make, model, year, plate } = vehicleInfo;
  return (
    <div className={styles.sidebar}>
      <TableRow className={styles.sidebarInfo}>
        <div>
          <VehicleTitle
            make={make}
            model={model}
            year={year}
            plate={plate}
            operationStatus={operationStatus}
          />
        </div>
      </TableRow>
      {tabs.map((tab, index) => (
        <TableRow
          color={selectedTab === tab.name ? "#F2F2F2" : "#414141"}
          width="200px"
          padding="18px 24px"
          justify={"left"}
          cursor={"pointer"}
          onClick={() => {
            triggerTab(tab.name);
          }}
        >
          <TableCell>
            <TabItem
              title={tab.name}
              selectedTab={selectedTab}
            />
          </TableCell>
        </TableRow>
      ))}
    </div>
  );
};

export default VehicleSidebar;
