import styles from "../statusLog.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DatePicker from "react-datepicker";
import { m } from "../../../../utils";
import { faCalendar, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

const DateFilter = ({ date, handleDateChange, classes }) => (
  <div>
    <DatePicker
      customInput={
        date ? (
          <div>
            <div className={styles.dateSelected}>
              {m(date).format("MM/DD/YYYY")}
            </div>
            <FontAwesomeIcon
              className={styles.dateIcon}
              icon={faTimesCircle}
              className={styles.dateClear}
              onClick={e => {
                e.stopPropagation();
                handleDateChange(null);
              }}
            />
          </div>
        ) : (
          <div>
            <div className={styles.dateIcon}>Date</div>
            <FontAwesomeIcon className={styles.dateIcon} icon={faCalendar} />
          </div>
        )
      }
      className={classNames(classes.dateInput)}
      onChange={val => handleDateChange(val)}
      selected={date && m(date)}
      placeholderText="Date"
      dateFormat="MM/DD/YYYY"
      todayButton={"Today"}
    />
  </div>
);

const muiStyles = theme => ({
  dateInput: {
    color: "#414141",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid #dedede",
    borderRadius: "25px",
    fontSize: "17px",
    padding: "0 12px 0 12px",
    width: "180px",
    height: "34px",
    backgroundColor: "#FFFFFF"
  }
});

export default withStyles(muiStyles)(DateFilter);
