import { normalizeCoords } from "../helperFunctions.js";
import {
	topRoof,
	topBonnet,
	frontWindshield,
	rearWindshield,
	topTrunk,
	topPassengerSideMirror,
	topDriverSideMirror,
	topDriverFrontWindow,
	topPassengerFrontWindow,
	topDriverCenterWindow,
	topPassengerCenterWindow,
	topDriverRearWindow,
	topPassengerRearWindow,
	topDriverRearQuarter,
	topPassengerRearQuarter,
	topDriverFrontDoor,
	topPassengerFrontDoor,
	topDriverRearDoor,
	topPassengerRearDoor,
	topFrontWipers,
	topRearWipers,
	topDriverFrontQuarter,
	topPassengerFrontQuarter,
	topFrontBumper,
	topRearBumper
} from "../../CoordArrays/Dodge/topView";

const topAreas = (width, height) => {
	return [
		{
			name: "Front Wipers",
			shape: "poly",
			coords: normalizeCoords(topFrontWipers, width, height)
		},
		{
			name: "Rear Wipers",
			shape: "poly",
			coords: normalizeCoords(topRearWipers, width, height)
		},
		{
			name: "Roof",
			shape: "poly",
			coords: normalizeCoords(topRoof, width, height)
		},
		{
			name: "Bonnet",
			shape: "poly",
			coords: normalizeCoords(topBonnet, width, height)
		},
		{
			name: "Passenger Side Mirror",
			shape: "poly",
			coords: normalizeCoords(topPassengerSideMirror, width, height)
		},
		{
			name: "Driver Side Mirror",
			shape: "poly",
			coords: normalizeCoords(topDriverSideMirror, width, height)
		},
		{
			name: "Driver Side Front Window",
			shape: "poly",
			coords: normalizeCoords(topDriverFrontWindow, width, height)
		},
		{
			name: "Passenger Side Front Window",
			shape: "poly",
			coords: normalizeCoords(topPassengerFrontWindow, width, height)
		},
		{
			name: "Driver Side Center Window",
			shape: "poly",
			coords: normalizeCoords(topDriverCenterWindow, width, height)
		},
		{
			name: "Passenger Side Center Window",
			shape: "poly",
			coords: normalizeCoords(topPassengerCenterWindow, width, height)
		},
		{
			name: "Driver Side Rear Window",
			shape: "poly",
			coords: normalizeCoords(topDriverRearWindow, width, height)
		},
		{
			name: "Passenger Side Rear Window",
			shape: "poly",
			coords: normalizeCoords(topPassengerRearWindow, width, height)
		},
		{
			name: "Driver Side Rear Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(topDriverRearQuarter, width, height)
		},
		{
			name: "Passenger Side Rear Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(topPassengerRearQuarter, width, height)
		},
		{
			name: "Driver Side Front Door",
			shape: "poly",
			coords: normalizeCoords(topDriverFrontDoor, width, height)
		},
		{
			name: "Passenger Side Front Door",
			shape: "poly",
			coords: normalizeCoords(topPassengerFrontDoor, width, height)
		},
		{
			name: "Driver Side Rear Door",
			shape: "poly",
			coords: normalizeCoords(topDriverRearDoor, width, height)
		},
		{
			name: "Passenger Side Rear Door",
			shape: "poly",
			coords: normalizeCoords(topPassengerRearDoor, width, height)
		},
		{
			name: "Windshield",
			shape: "poly",
			coords: normalizeCoords(frontWindshield, width, height)
		},
		{
			name: "Rear Window",
			shape: "poly",
			coords: normalizeCoords(rearWindshield, width, height)
		},
		{
			name: "Driver Side Front Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(topDriverFrontQuarter, width, height)
		},
		{
			name: "Trunk",
			shape: "poly",
			coords: normalizeCoords(topTrunk, width, height)
		},
		{
			name: "Passenger Side Front Quarter Panel",
			shape: "poly",
			coords: normalizeCoords(topPassengerFrontQuarter, width, height)
		},
		{
			name: "Front Bumper",
			shape: "poly",
			coords: normalizeCoords(topFrontBumper, width, height)
		},
		{
			name: "Rear Bumper",
			shape: "poly",
			coords: normalizeCoords(topRearBumper, width, height)
		}
	];
};

export { topAreas };
