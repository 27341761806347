import React from "react";
import styles from "../../task.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import Dropdown from "../../../Shared/Dropdown";

const AddAdmin = ({
  newAdmin,
  allAdmins,
  triggerAdmin,
  taskData,
  addable,
  handleRemind,
}) => {
  return (
    <div className={styles.searchAddContainer}>
      <Dropdown
        initialValue={newAdmin}
        items={allAdmins}
        onChange={triggerAdmin}
        width="250px"
        height="30px"
        promptColor={"#dedede"}
        disabled={!taskData}
      />
      <button
        className={
          addable ? styles.addButtonContainer : styles.invalidAddButtonContainer
        }
      >
        <div
          className={addable ? styles.addTagButton : styles.invalidAddTagButton}
          onClick={() => {
            addable && handleRemind();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className={styles.plusIconNew} />
          <div style={{ fontWeight: "600" }}>Add</div>
        </div>
      </button>
    </div>
  );
};

export default AddAdmin;
