import React, { useState } from "react";
import Modal from "../../../../../artzu/src/Modal";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import StarRatings from "react-star-ratings";
import "./inspection.scss";
import FullImage from "./FullImage";

const PrebookingInspection = ({
  isInspection,
  toggleInspection,
  inspectionData,
}) => {
  const [isFull, setIsFull] = useState(false);
  const [isSafeFull, setIsSafeFull] = useState(false);
  const [isCleanFull, setIsCleanFull] = useState(false);
  const [curImg, setCurImg] = useState();
  console.log(inspectionData);

  const toggleImg = (child) => {
    setIsFull(!isFull);
    setCurImg(child);
  };

  const toggleSafeImg = (child) => {
    setIsSafeFull(!isSafeFull);
    setCurImg(child);
  };

  const toggleCleanImg = (child) => {
    setIsCleanFull(!isCleanFull);
    setCurImg(child);
  };

  const fCenter = inspectionData?.frontCenter;
  const fLeft = inspectionData?.frontLeft;
  const fRight = inspectionData?.frontRight;
  const rCenter = inspectionData?.rearCenter;
  const rLeft = inspectionData?.rearLeft;
  const rRight = inspectionData?.rearRight;
  const safety1 =
    inspectionData?.safetyImage1 !== "" ? inspectionData?.safetyImage1 : null;
  const safety2 =
    inspectionData?.safetyImage2 !== "" ? inspectionData?.safetyImage2 : null;
  const safety3 =
    inspectionData?.safetyImage3 !== "" ? inspectionData?.safetyImage3 : null;
  const safety4 =
    inspectionData?.safetyImage4 !== "" ? inspectionData?.safetyImage4 : null;
  const safety5 =
    inspectionData?.safetyImage5 !== "" ? inspectionData?.safetyImage5 : null;
  const safety6 =
    inspectionData?.safetyImage6 !== "" ? inspectionData?.safetyImage6 : null;
  const clean1 =
    inspectionData?.cleanlinessImage1 !== ""
      ? inspectionData?.cleanlinessImage1
      : null;
  const clean2 =
    inspectionData?.cleanlinessImage2 !== ""
      ? inspectionData?.cleanlinessImage2
      : null;
  const clean3 =
    inspectionData?.cleanlinessImage3 !== ""
      ? inspectionData?.cleanlinessImage3
      : null;
  const clean4 =
    inspectionData?.cleanlinessImage4 !== ""
      ? inspectionData?.cleanlinessImage4
      : null;
  const clean5 =
    inspectionData?.cleanlinessImage5 !== ""
      ? inspectionData?.cleanlinessImage5
      : null;
  const clean6 =
    inspectionData?.cleanlinessImage6 !== ""
      ? inspectionData?.cleanlinessImage6
      : null;

  var imageList = [fCenter, fLeft, fRight, rCenter, rLeft, rRight];
  imageList = imageList.filter((img) => img !== "");
  var safetyList = [safety1, safety2, safety3, safety4, safety5, safety6];
  safetyList = safetyList.filter((img) => img !== null);
  var cleanList = [clean1, clean2, clean3, clean4, clean5, clean6];
  cleanList = cleanList.filter((img) => img !== null);

  const curImgIndex = imageList.findIndex((ele) => ele === curImg);
  const curSafeIndex = safetyList.findIndex((ele) => ele === curImg);
  const curCleanIndex = cleanList.findIndex((ele) => ele === curImg);

  const getCleanRate = (rate) => {
    switch (rate) {
      case 1:
        return "Very dirty";
      case 2:
        return "Dirty";
      case 3:
        return "OK";
      case 4:
        return "Clean";
      case 5:
        return "Very clean";
    }
  };
  return (
    <Fragment>
      <Modal open={isInspection} backgroundColor="none">
        <div className="inspection-container">
          <h2>Pre-booking inspection</h2>
          <div className="exterior">
            <div>1. Exterior Inspection</div>{" "}
            <div className="img-container">
              {imageList.length === 6 &&
                imageList.map((img) => (
                  <div key={img}>
                    <img
                      className="exterior-img"
                      style={{ marginRight: "16px" }}
                      src={img}
                      onClick={() => toggleImg(img)}
                    ></img>
                  </div>
                ))}
            </div>
          </div>
          <div className="vehicle-safety">
            <div>2. Vehicle Safety</div>
            {inspectionData?.safeToDrive ? (
              <div className="safety-condition-good">
                Yes, safe to drive.{" "}
                <FontAwesomeIcon icon={faCheckCircle} size="1x" />
              </div>
            ) : inspectionData?.safeToDrive !== null ? (
              <div className="bad-safety">
                <div className="safety-condition-bad">
                  Not safe to drive.{" "}
                  <FontAwesomeIcon icon={faTimesCircle} size="1x" />
                  <div className="driver-action">
                    {inspectionData?.reasonForFailure === "safety" &&
                      inspectionData?.status === "swapped" && (
                        <div>(Requested another vehicle)</div>
                      )}
                  </div>
                </div>
                <div className="driver-words">
                  <div>Comment: {inspectionData?.issueDescription}</div>
                </div>
                <div className="img-container-safe">
                  {safetyList.length !== 0 &&
                    safetyList.map((img) => (
                      <div key={img}>
                        <img
                          className="exterior-img"
                          style={{ marginRight: "16px" }}
                          src={img}
                          onClick={() => toggleSafeImg(img)}
                        ></img>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div style={{ width: "70%", opacity: "0.5" }}>N/A</div>
            )}
          </div>
          <div className="vehicle-cleanliness">
            <div>3. Vehicle Cleanliness</div>
            {inspectionData?.cleanliness ? (
              <div className="clean-comment">
                <div className="rating-clean">
                  <StarRatings
                    rating={inspectionData?.cleanliness}
                    starDimension="24px"
                    starSpacing="5px"
                  />
                  {"  "}
                  <div style={{ marginLeft: "10px" }}>
                    {getCleanRate(inspectionData?.cleanliness)}
                  </div>
                  <div className="driver-action">
                    {inspectionData?.reasonForFailure === "cleanliness" &&
                      inspectionData?.status === "swapped" && (
                        <div>(Requested another vehicle)</div>
                      )}
                  </div>
                </div>
                <div className="driver-words">
                  <div>Comment: {inspectionData?.cleanlinessComment}</div>
                </div>
                <div className="img-container-safe">
                  {cleanList.length !== 0 &&
                    cleanList.map((img) => (
                      <div key={img}>
                        <img
                          className="exterior-img"
                          style={{ marginRight: "16px" }}
                          src={img}
                          onClick={() => toggleCleanImg(img)}
                        ></img>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div style={{ opacity: "0.5", width: "70%" }}>N/A</div>
            )}
          </div>
          <div className="inspection-button">
            <button className="close-button" onClick={toggleInspection}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <FullImage
        isFull={isFull}
        imageList={imageList}
        toggleImg={toggleImg}
        curImgIndex={curImgIndex}
      />
      <FullImage
        isFull={isSafeFull}
        imageList={safetyList}
        toggleImg={toggleSafeImg}
        curImgIndex={curSafeIndex}
      />
      <FullImage
        isFull={isCleanFull}
        imageList={cleanList}
        toggleImg={toggleCleanImg}
        curImgIndex={curCleanIndex}
      />
    </Fragment>
  );
};

export default PrebookingInspection;
