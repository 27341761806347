import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_SPOTS } from "./query";
import EditableSpot from "./EditableSpot";
import Dropdown from "../../../InformationTable/Shared/Dropdown";

const EditableParkingContent = ({
  isEdit,
  parkingSpot,
  parkingLot,
  vehicleId,
  handleLotChange,
  handleSpotChange,
  availableParkingLots,
}) => {
  const [curHub, setCurHub] = useState(parkingLot && parkingLot.id);

  const onHubChange = (e) => {
    setCurHub(e);
    handleLotChange(e);
  };
  const { loading, error, data, refetch } = useQuery(GET_AVAILABLE_SPOTS, {
    variables: {
      parkingLotId: curHub,
    },
  });

  useEffect(() => {
    refetch();
  }, [curHub]);

  const availableSpots = data?.getAllSpotsAtHub.map((spot) => {
    return { name: spot.identifier, id: spot.identifier };
  });

  return (
    <Fragment>
      <div className="vehicle-information">
        <p className="tight-margin-details">Parking Spot</p>
        {isEdit ? (
          <EditableSpot
            parkingSpot={parkingSpot}
            handleSpotChange={handleSpotChange}
            availableParkingSpots={availableSpots}
          />
        ) : (
          <p className="tight-margin-content">{parkingSpot || "--"}</p>
        )}
      </div>
      <div className="vehicle-information">
        <p className="tight-margin-details" style={{ width: "50%" }}>
          Parking Hub
        </p>

        {isEdit ? (
          <Dropdown
            initialValue={curHub}
            items={availableParkingLots}
            onChange={onHubChange}
          />
        ) : (
          <div className="tight-margin-content" style={{ fontWeight: "bold" }}>
            {parkingLot.name}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EditableParkingContent;
