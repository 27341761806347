import React, { useState, Fragment } from "react";
import styles from "../../batchActions.module.scss";
import classsNames from "classnames";
import CommonTags from "./CommonTags";
import { useMutation } from "@apollo/client";
import AddNewTags from "./AddNewTags";
import { BULK_UPDATE_TAGS } from "../../queries";
import Button from "../../../../../artzu/src/Button";

const AddTagContent = ({
  selectedOption,
  close,
  tasks,
  alltags,
  commonTags,
  callback,
  toRemove,
  refetch,
  clearAllSelections,
}) => {
  const [manage, setManage] = useState(false);
  const [tag, setTag] = useState();
  const [added, setAdded] = useState([]);
  const [deleted, setDeleted] = useState(false);

  // const allTagList = [];
  // alltags.map((a) => allTagList.push(a.name));
  const triggerTag = (childData) => {
    setTag(childData);
  };

  const handleAdd = () => {
    if (tag !== undefined) {
      setAdded((arr) => [...arr, tag]);
    }
    console.log(duplicate);
    !duplicate && setTag("");
  };

  const deleteAddedTag = (t) => {
    setAdded((arr) => arr.filter((items) => items !== t));
  };

  const toggleManageTags = () => {
    setManage(!manage);
  };

  const triggerDeleted = () => {
    setDeleted(true);
  };

  let duplicate = commonTags.includes(tag);

  const taskIds = [];
  tasks.map((vehicle) => taskIds.push(vehicle.id));

  const [data, { loading, error }] = useMutation(BULK_UPDATE_TAGS);
  const updateTags = () => {
    {
      !duplicate &&
        data({
          variables: {
            taskIds: taskIds,
            tagsToRemove: toRemove,
            newTags: added,
          },
        }).then(({ data: { modifyTag } }) => {
          if (modifyTag.success) {
            close();
            clearAllSelections();
            console.log("Succeed!");
          } else {
            console.log("error!");
          }
        });
    }
  };
  const applyable = added.length !== 0 || deleted;
  return (
    <Fragment>
      {/* <ManageTags
        selected={selectedOption}
        toggleManageTags={toggleManageTags}
        close={close}
        manage={manage}
        alltags={alltags}
        refetchAddTag={refetch}
      /> */}
      <div className={styles.actionHeader}>Add tag</div>

      <div className={styles.subscript} style={{ marginBottom: 4 }}>
        New tags
      </div>
      <AddNewTags
        // allTagList={allTagList}
        newTag={tag}
        triggerTag={triggerTag}
        parentCallback={handleAdd}
        added={added}
        commonTags={commonTags}
        pc={deleteAddedTag}
        duplicate={duplicate}
      />
      <CommonTags
        common={commonTags}
        newTag={tag}
        parentCallback={callback}
        pcTrigger={triggerDeleted}
      />
      <div className={styles.taskModalButtons} style={{ marginTop: "53px" }}>
        <div>
          <button
            className={styles.taskModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={
              applyable
                ? styles.taskModalApplyButton
                : styles.taskModalApplyButtonInvalid
            }
            onClick={() => {
              applyable && updateTags();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AddTagContent;
