import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Collapse,
  Checkbox
} from "@material-ui/core/";

import { faGasPump } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  subheader: {
    fontSize: "1em",
    fontWeight: "heavy"
  },
  sliderRoot: {
    margin: "0 16px 0 16px",
    padding: "16px 0px 16px 0px"
  },
  colorBar: {},
  colorChecked: {},
  switch: {
    "&$colorChecked": {
      color: theme.palette.blue,
      "& + $colorBar": {
        backgroundColor: theme.palette.blue
      }
    }
  },
  checkRoot: {
    "&$boxChecked": {
      color: theme.palette.blue
    }
  },
  boxChecked: {},
  thumb: { backgroundColor: theme.palette.blue },
  track: { backgroundColor: theme.palette.blue }
});

const GasLevelInput = ({
  data,
  gasLevel,
  gasFilled,
  onEdit,
  modifyState,
  classes
}) => (
  <Fragment>
    <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon icon={faGasPump} fixedWidth />
      </ListItemIcon>
      <ListItemText primary={"Gas Level"} />
      <ListItemSecondaryAction>
        <Switch
          onChange={event => modifyState({ gasLevel: event.target.checked })}
          checked={gasLevel}
          classes={{
            switchBase: classes.switch,
            checked: classes.colorChecked,
            track: classes.colorBar
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
    <Collapse in={gasLevel}>
      <div className="gas-slider">
        <Slider
          value={data.gasLevel}
          step={5}
          onChange={(event, value) => onEdit("gasLevel", value)}
          classes={{
            thumb: classes.thumb,
            track: classes.track,
            root: classes.sliderRoot
          }}
        />
        <div className="gas-percentage">{data.gasLevel}%</div>
      </div>
    </Collapse>
    <Collapse in={data.gasLevel < 100}>
      <div className="gas-filled-box-container">
        <div className="gas-filled-box">Did you fill the car with gas?</div>
        <Checkbox
          onChange={event => {
            console.log(event.target.checked);
            onEdit("gasFilled", event.target.checked);
          }}
          checked={gasFilled}
          classes={{
            checked: classes.boxChecked,
            root: classes.checkRoot
          }}
        />
      </div>
    </Collapse>
  </Fragment>
);

export default withStyles(styles)(GasLevelInput);
