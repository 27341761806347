import React, { useEffect, useState } from "react";
import styles from "../../../../VehicleNew/VehicleDamages/damages.module.scss";
import _times from "lodash/times";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ADD_NEW_DAMAGE,
  EDIT_DAMAGE,
} from "../../../../VehicleNew/VehicleDamages/queries";
import { directUploadFile } from "../../../../../utils/FileUploads";
import DropdownInput from "../../../../VehicleNew/VehicleDamages/CreateDamage/DropdownInput";
import TextInput from "../../../../VehicleNew/VehicleDamages/CreateDamage/TextInput";
import TagInput from "../../../../VehicleNew/VehicleDamages/CreateDamage/TagInput";
import ImageInput from "../../../../VehicleNew/VehicleDamages/CreateDamage/ImageInput";
import { getDropdownOptions } from "../../../../VehicleNew/VehicleDamages/utils";
import Modal from "../../../../../artzu/src/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

const EditDamage = ({
  open,
  toggleEdit,
  damageData,
  damageRefetch,
  refetch,
}) => {
  const damageInputsInitialStates = {
    status: damageData?.status,
    location: damageData?.location,
    severity: damageData?.severity,
    type: damageData?.type,
    description: damageData?.description,
    damageTags:
      (damageData?.damageTags &&
        damageData?.damageTags.map((tag) => tag.name)) ||
      [],
  };

  const [damageInputs, setDamageInputs] = useState(damageInputsInitialStates);
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [filePreviews, setFilePreviews] = useState(
    damageData?.imageLinks || []
  );
  const [fileIds, setFileIds] = useState(damageData?.attachedImageIds || []);
  const [newFiles, setNewFiles] = useState([]);
  const [newFilePreviews, setNewFilePreviews] = useState([]);
  const [imgCount, setImgCount] = useState(0);
  const [fileBlobs, setFileBlobs] = useState([]);

  useEffect(() => {
    damageRefetch();
  }, []);

  useEffect(() => {
    setDamageInputs(damageInputsInitialStates);
    setFileIds(damageData?.attachedImageIds || []);
    setFilePreviews(damageData?.imageLinks || []);
  }, [damageData]);

  const triggerStatusChange = (child) => {
    setDamageInputs({ ...damageInputs, status: child });
  };

  const triggerLocationChange = (child) => {
    setDamageInputs({ ...damageInputs, location: child });
  };
  const triggerTypeChange = (child) => {
    setDamageInputs({ ...damageInputs, type: child });
  };
  const triggerSeverityChange = (child) => {
    setDamageInputs({ ...damageInputs, severity: child });
  };
  const triggerDescriptionChange = (e) => {
    setDamageInputs({ ...damageInputs, description: e.target.value });
  };

  const triggerAddTag = (child) => {
    setDamageInputs({
      ...damageInputs,
      damageTags: [...damageInputs.damageTags, child],
    });
    setTagsToAdd([...tagsToAdd, child]);
  };

  const triggerRemoveTag = (child) => {
    setDamageInputs({
      ...damageInputs,
      damageTags: damageInputs.damageTags.filter((tag) => tag !== child),
    });
    setTagsToRemove([...tagsToRemove, child]);
  };

  const clearInput = () => {
    setDamageInputs(damageInputsInitialStates);
    setNewFiles([]);
    setNewFilePreviews([]);
    setImgCount(0);
  };

  const client = useApolloClient();

  useEffect(() => {
    if (newFiles.length > 0) {
      let tempBlobs = [];
      newFiles.map((file) => {
        directUploadFile(file, client).then((result) => {
          tempBlobs.push(result);
        });
      });
      setFileBlobs(tempBlobs);
    }
    if (newFiles.length == 0) {
      setFileBlobs([]);
    }
  }, [newFiles]);

  const [editDamageMutation, { loading, error }] = useMutation(EDIT_DAMAGE);
  const handleCreate = () => {
    {
      editDamageMutation({
        variables: {
          editDamageParams: {
            status: damageInputs.status,
            location: damageInputs.location,
            damageType: damageInputs.type,
            severity: damageInputs.severity,
            description: damageInputs.description,
            tagsToAdd: tagsToAdd,
            tagsToRemove: tagsToRemove,
          },
          newImages: fileBlobs,
          oldImages: fileIds,
          damageId: damageData?.id,
        },
      }).then(({ data: { editDamage } }) => {
        if (editDamage.success) {
          refetch();
          window.location.reload();
          toggleEdit();
          console.log("Create Damage Succeed!");
        } else {
          console.log("Create damage error!");
        }
      });
    }
  };
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.damageModalContainer}>
        <div className={styles.title}>Edit damage</div>
        <DropdownInput
          text={"Status"}
          value={damageInputs.status}
          options={getDropdownOptions("status")}
          triggerFieldChange={triggerStatusChange}
        />
        <DropdownInput
          text={"Location"}
          value={damageInputs.location}
          options={getDropdownOptions("location")}
          triggerFieldChange={triggerLocationChange}
        />
        <DropdownInput
          text={"Type"}
          value={damageInputs.type}
          options={getDropdownOptions("type")}
          triggerFieldChange={triggerTypeChange}
        />
        <DropdownInput
          text={"Severity"}
          value={damageInputs.severity}
          options={getDropdownOptions("severity")}
          triggerFieldChange={triggerSeverityChange}
        />
        <TextInput
          text={"Description"}
          triggerFieldChange={triggerDescriptionChange}
        />
        <TagInput
          tags={damageInputs.damageTags}
          triggerAddTag={triggerAddTag}
          triggerRemoveTag={triggerRemoveTag}
        />
        <div>
          <div className={styles.subscript} style={{ marginBottom: "8px" }}>
            {"Images"}
          </div>
          <div className={styles.subscript} style={{ marginBottom: "8px" }}>
            {"Existing Images:"}
          </div>
          <div className={styles.imageField}>
            {filePreviews.map((link, index) => (
              <div className={styles.imageUpload}>
                <img src={link} className={styles.imageUploader}></img>
                <div className={styles.imageTimesIcon}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="sm"
                    onClick={() => {
                      setFilePreviews(
                        filePreviews.filter((file, i) => i !== index)
                      );
                      setFileIds(fileIds.filter((file, i) => i !== index));
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.subscript} style={{ marginBottom: "8px" }}>
            {"New Images:"}
          </div>
          <div className={styles.imageField}>
            {_times(imgCount + 1, (index) => (
              <ImageInput
                index={index}
                file={newFiles[index]}
                files={newFiles}
                filePreviews={newFilePreviews}
                setFiles={setNewFiles}
                setFilePreviews={setNewFilePreviews}
                imgCount={imgCount}
                setImgCount={setImgCount}
              />
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              clearInput();
              toggleEdit();
            }}
          >
            Cancel
          </button>
          <button className={styles.applyButton} onClick={handleCreate}>
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditDamage;
