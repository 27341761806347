import React from "react";
import Button from "../../../../artzu/src/Button";
import styles from "./buttonGroup.module.scss";

const ButtonGroup = ({
  leftButtonText,
  leftButtonFunction,
  rightButtonText,
  rightButtonFunction
}) => {
  return (
    <>
      <Button
        label={leftButtonText}
        className={styles.button}
        disabled={!leftButtonFunction}
        color="#414141"
        border="1px solid #DEDEDE"
        onClick={leftButtonFunction || (() => {})}
      />
      <Button
        label={rightButtonText}
        className={styles.button}
        disabled={!rightButtonFunction}
        border="1px solid #DEDEDE"
        onClick={rightButtonFunction || (() => {})}
      />
    </>
  );
};

export default ButtonGroup;
