import React from "react";
import styles from "../../notes.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/pro-regular-svg-icons";
import { PIN_DAMAGE_NOTE } from "../../queries";
import { useMutation } from "@apollo/client";

const NotePin = ({ note, refetch }) => {
  const pinNoteParams = {
    pinned: note.pinned ? false : true,
    noteId: parseInt(note.id),
  };

  const [pinNoteMutation, { loading, error }] = useMutation(PIN_DAMAGE_NOTE);

  const handlePinNote = () => {
    pinNoteMutation({
      variables: {
        params: pinNoteParams,
      },
    }).then(({ data: { pinDamageNote } }) => {
      if (pinDamageNote.success) {
        refetch();
        console.log("Pin Note Succeed!");
      } else {
        console.log("Pin note error!");
      }
    });
  };

  return (
    <div className={styles.pinNote}>
      <div
        className={styles.iconButtons}
        style={
          note.pinned === true
            ? {
                backgroundColor: "#414141",
              }
            : {
                backgroundColor: "#FFFFFF",
              }
        }
      >
        <FontAwesomeIcon
          className={styles.pinNote}
          icon={faThumbtack}
          style={
            note.pinned === true
              ? {
                  color: "#FFFFFF",
                }
              : {
                  color: "#414141",
                }
          }
          onClick={handlePinNote}
        />
      </div>
    </div>
  );
};

export default NotePin;
