import React from "react";
import styles from "./footer.module.scss";

const ItemsViewing = ({ page, itemsPerPage, totalRowCount, item }) => {
  const firstViewableItem =
    totalRowCount > 0 ? (page - 1) * itemsPerPage + 1 : 0;
  const lastViewableItem = Math.min(page * itemsPerPage, totalRowCount);
  return (
    <div className={styles.pageDisplay}>
      Viewing {firstViewableItem} - {lastViewableItem} of {totalRowCount} {item}
    </div>
  );
};

export default ItemsViewing;
