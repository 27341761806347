import React, { Component, Fragment } from "react";

import NotesModal from "../../../artzu/src/Modal";
import DisplayTicketModal from "./DisplayTicketModal";
import EditTicketModal from "./EditTicketModal";

class TicketModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false
    };
  }

  handleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  render() {
    const { ticketModal, ticket, handleClose, refetch } = this.props;
    const { edit } = this.state;
    return (
      <Fragment>
        <NotesModal open={ticketModal} closeModal={handleClose}>
          {edit ? (
            <EditTicketModal
              ticket={ticket}
              handleClose={handleClose}
              refetch={refetch}
            />
          ) : (
            <DisplayTicketModal
              ticket={ticket}
              handleClose={handleClose}
              handleEdit={this.handleEdit}
            />
          )}
        </NotesModal>
      </Fragment>
    );
  }
}

export default TicketModal;
