import moment from "moment-timezone";
import { status } from "./index";
import _ from "lodash";

Array.prototype.unique = function() {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

const vehicleCounter = list => {
  const uniqueArr = list.reduce((accum, val) => {
    const dupeIndex = accum.findIndex(arrayItem => arrayItem.id === val.id);
    if (dupeIndex === -1) {
      // Not found, so initialize.
      accum.push({
        qty: 1,
        ...val
      });
    } else {
      // Found, so increment counter.
      accum[dupeIndex].qty++;
    }
    return accum;
  }, []);

  return uniqueArr;
};

const vehicleCounterName = list => {
  const uniqueArr = list.reduce((accum, val) => {
    const dupeIndex = accum.findIndex(arrayItem => arrayItem.name === val.name);
    if (dupeIndex === -1) {
      // Not found, so initialize.
      accum.push({
        qty: 1,
        ...val
      });
    } else {
      // Found, so increment counter.
      accum[dupeIndex].qty++;
    }
    return accum;
  }, []);

  return uniqueArr;
};

const carStatusId = status => {
  switch (status) {
    case "Service":
      return "3";
    case "Available":
      return "1";
    case "Deactivated":
      return "4";
    case "With Driver":
      return "2";
    case "Offline":
      return "5";
    case "Unauthorized":
      return "6";
    case "Late Driver":
      return "7";
    default:
      return 0;
  }
};

const makeTierList = vehicles => {
  if (vehicles.length > 0) {
    let tiers = [];
    vehicles.forEach(vehicle => {
      tiers.push({
        id: vehicle.fleet.id,
        name: vehicle.fleet.name.toUpperCase()
      });
    });
    const vehicleCount = vehicles.length;
    const tierList =
      tiers.length > 0
        ? [{ id: 0, name: "All", qty: vehicleCount }].concat(
            vehicleCounter(tiers)
          )
        : [{ id: 0, name: "All", qty: vehicleCount }];

    return tierList;
  }
};

const makeCarStatusList = vehicles => {
  if (vehicles.length > 0) {
    let statuses = [];
    vehicles.forEach(vehicle => {
      statuses.push({
        name: vehicle.status
      });
    });

    const vehicleCount = vehicles.length;
    const carStatusList =
      statuses.length > 0
        ? [{ id: 0, name: "All", qty: vehicleCount }].concat(
            vehicleCounterName(statuses)
          )
        : [{ id: 0, name: "All", qty: vehicleCount }];

    carStatusList.forEach((status, index) => {
      if (!status.id) {
        if (status.name === "Available") {
          status.id = 1;
        }
        if (status.name === "With Driver") {
          status.id = 2;
        }
        if (status.name === "Service") {
          status.id = 3;
        }
        if (status.name === "Deactivated") {
          status.id = 4;
        }
        if (status.name === "Offline") {
          status.id = 5;
        }
        if (status.name === "Unauthorized") {
          status.id = 6;
        }
        if (status.name === "Late Driver") {
          status.id = 7;
        }
      }
    });

    return carStatusList;
  }
};

const makeLocationList = vehicles => {
  if (vehicles.length > 0) {
    let locations = [];
    let lots = [];
    let serviceLocs = [];
    const vehicleCount = vehicles.length;

    vehicles.forEach(vehicle => {
      if (vehicle.currentLocation.resourceType === "parking") {
        locations.push({
          name: "Parking",
          listItem: [],
          checked: false
        });
        lots.push({
          name: vehicle.currentLocation.name,
          checked: false
        });
      }
      if (vehicle.currentLocation.resourceType === "service") {
        locations.push({
          name: "Service",
          listItem: [],
          checked: false
        });
        serviceLocs.push({
          name: vehicle.currentLocation.name,
          checked: false
        });
      }
      if (vehicle.currentLocation.resourceType === "other") {
        locations.push({
          name: "Map",
          listItem: [],
          checked: false,
          unique: true
        });
      }
    });

    let locationList =
      locations.length > 0
        ? [
            {
              name: "All",
              listItem: [],
              qty: vehicleCount,
              unique: true,
              checked: false
            }
          ].concat(vehicleCounterName(locations))
        : [
            {
              name: "All",
              listItem: [],
              qty: vehicleCount,
              unique: true,
              checked: false
            }
          ];

    locationList.forEach(locType => {
      if (locType.listItem) {
        if (locType.name === "Parking") {
          locType.listItem = vehicleCounterName(lots);
        }
        if (locType.name === "Service") {
          locType.listItem = vehicleCounterName(serviceLocs);
        }
      }
    });

    return locationList;
  }
};

const makeStatusList = vehicles => {
  if (vehicles.length > 0) {
    let serviceStasuses = [];
    vehicles.forEach(vehicle => {
      serviceStasuses.push({
        id: vehicle.serviceStatus
      });
    });
    const vehicleCount = vehicles.length;
    let statusList = [{ id: 0, name: "All", qty: vehicleCount }].concat(
      vehicleCounter(serviceStasuses)
    );

    statusList.forEach((status, index) => {
      if (!status.name) {
        if (status.id === 1) {
          status.name = "Good";
        }
        if (status.id === 2) {
          status.name = "Warning";
        }
        if (status.id === 3) {
          status.name = "Overdue";
        }
        if (status.id === 4) {
          status.name = "N/A";
        }
      }
    });

    return statusList;
  }
};

const makeModelList = vehicles => {
  if (vehicles.length > 0) {
    let makes = [];
    vehicles.forEach(vehicle => {
      makes.push({
        name: vehicle.make,
        listItem: [],
        checked: false
      });
      makes.forEach(make => {
        if (make.name === vehicle.make) {
          make.listItem.push({ name: vehicle.model, checked: false });
        }
      });
    });

    makes.forEach(make => {
      make.listItem = vehicleCounterName(make.listItem);
    });
    const vehicleCount = vehicles.length;
    let makeList = [
      {
        name: "All",
        listItem: [],
        qty: vehicleCount,
        unique: true,
        checked: false
      }
    ].concat(vehicleCounterName(makes));

    return makeList;
  }
};

const selectFilterLocation = (vehicles, locationSelection) => {
  let lots = [];
  let serviceLocs = [];

  vehicles.forEach(vehicle => {
    if (vehicle.currentLocation.resourceType === "parking") {
      lots.push({
        name: vehicle.currentLocation.name,
        checked: false
      });
    }
    if (vehicle.currentLocation.resourceType === "service") {
      serviceLocs.push({
        name: vehicle.currentLocation.name,
        checked: false
      });
    }
  });

  return vehicles.filter(vehicle => {
    return locationSelection.some(location => {
      if (location === "Parking") {
        return _.uniq(lots).some(lot => {
          return vehicle.currentLocation.name.includes(lot.name);
        });
      }

      if (location === "Service") {
        return _.uniq(serviceLocs).some(serv => {
          return vehicle.currentLocation.name === serv.name;
        });
      }

      return vehicle.currentLocation.name.includes(location);
    });
  });
};

export {
  makeTierList,
  makeCarStatusList,
  makeLocationList,
  selectFilterLocation,
  carStatusId
};
