import React from "react";
import { Fragment } from "react";
import EditNoteModalPanel from "./EditNoteModalPanel";
import ReminderModalPanel from "./ReminderModalPanel";

const NotePopup = ({
  editNote,
  editedNote,
  selectedNote,
  editChange,
  vehicleId,
  refetch,
  toggleEditNote,
  resetNoteState,
  reminderModal,
  toggleRemindersModal,
  newReminderDate,
  reminderDate,
  now,
  classes,
  selectReminderDate,
  addNewReminderDate,
  addNewReminder,
}) => {
  return (
    <Fragment>
      <EditNoteModalPanel
        editNote={editNote}
        editedNote={editedNote}
        selectedNote={selectedNote}
        editChange={editChange}
        vehicleId={vehicleId}
        refetch={refetch}
        toggleEditNote={toggleEditNote}
        resetNoteState={resetNoteState}
      />

      <ReminderModalPanel
        reminderModal={reminderModal}
        toggleRemindersModal={toggleRemindersModal}
        newReminderDate={newReminderDate}
        selectedNote={selectedNote}
        reminderDate={reminderDate}
        vehicleId={vehicleId}
        refetch={refetch}
        now={now}
        classes={classes}
        selectReminderDate={selectReminderDate}
        addNewReminderDate={addNewReminderDate}
        addNewReminder={addNewReminder}
      />
    </Fragment>
  );
};

export default NotePopup;
