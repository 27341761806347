import { AvcDevice, LockboxCode, ParkingSpot } from "./CellValues";

import _ from "lodash";
import { m } from "../../utils/";
import moment from "moment";
import TextValue from "./CellValues/Base/TextValue";
import ImageValue from "./CellValues/Base/ImageValue";
import StatusValue from "./CellValues/Base/StatusValue";
import ExpandableList from "./CellValues/Base/ExpandableList";
import BubbleTextValue from "./CellValues/Base/BubbleTextValue";
import TaskStatusValue from "./CellValues/Base/TaskStatusValue";
import DueDateValue from "./CellValues/Base/DueDateValue";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor].value,
    };
  };
};
const operationStatusToStyles = (operationStatus) => {
  switch (operationStatus) {
    case "service":
      return {
        isSolid: true,
        color: "#414141",
      };
    case "available":
      return {
        isSolid: false,
        color: "#128DFF",
      };
    case "deactivated":
      return {
        color: "#FF0000",
        isSolid: false,
      };
    case "unauthorized":
    case "late_driver":
      return {
        color: "#FF0000",
        isSolid: true,
      };
    case "with_driver":
      return {
        isSolid: true,
        color: "#128DFF",
      };
    case "offline":
      return {
        isSolid: false,
        color: "#414141",
      };
    default:
      return {};
  }
};

const baseBooleanProcessor = (accessor) => {
  return (data) => {
    console.log(data, accessor);
    const value = data[accessor].value;

    const color = value == "true" ? "#3dc700" : "red";
    const text = value == "true" ? "Yes" : "No";

    return {
      color,
      text,
      isSolid: true,
    };
  };
};

const baseDueDateProcessor = (accessor, timeZone) => {
  return (data) => {
    const value = data[accessor].value;
    var today = new Date();
    const isPastToday =
      moment.utc(value).tz(timeZone || "America/Toronto") < today
        ? "#ff0000"
        : "#414141";
    return {
      text: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("MMM D, YYYY")
        : "N/A",
      color: isPastToday,
    };
  };
};

const baseDateProcessor = (accessor, timeZone) => {
  return (data) => {
    const value = data[accessor].value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("MMM D, YYYY")
        : "",
      subtext: value
        ? moment
            .utc(value)
            .tz(timeZone || "America/Toronto")
            .format("h:mm:ss A z")
        : "",
    };
  };
};

const rowComponentsTask = (timeZone) => ({
  taskStatus: {
    component: TaskStatusValue,
    dataProcessor: baseTextProcessor("taskStatus"),
  },
  vehicle: {
    component: ImageValue,
    dataProcessor: (data) => {
      const imageLink = data["imageLink"] && data["imageLink"].value;
      const make = data["make"] && data["make"].value;
      const model = data["model"] && data["model"].value;
      const plate = data["plate"] && data["plate"].value;

      return {
        imageLink: imageLink,
        subtext: `${plate}`,
      };
    },
  },

  taskType: {
    component: TextValue,
    dataProcessor: baseTextProcessor("taskType"),
  },

  taskDescription: {
    component: TextValue,
    dataProcessor: baseTextProcessor("taskDescription"),
  },

  tags: {
    component: ExpandableList,
    dataProcessor: (data) => {
      const listData = data["tags"].value;
      if (listData) {
        const mappedListData = listData.map((data) => ({
          text: data.name,
        }));
        return {
          listComponent: BubbleTextValue,
          listData: mappedListData,
        };
      } else {
        return {
          listComponent: BubbleTextValue,
          listData: [],
        };
      }
    },
  },
  lastCleaned: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastCleaned", timeZone),
  },
  dateCreated: {
    component: TextValue,
    dataProcessor: baseDateProcessor("createdAt", timeZone),
  },
  dateCompleted: {
    component: TextValue,
    dataProcessor: baseDateProcessor("completedAt", timeZone),
  },
  dueDate: {
    component: DueDateValue,
    dataProcessor: baseDueDateProcessor("dueDate", timeZone),
  },
  daysInStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("daysInStatus"),
  },
  origin: {
    component: TextValue,
    dataProcessor: baseTextProcessor("origin"),
  },
  adminUserName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("assignedAdminUserFullName"),
  },
  operationStatus: {
    component: StatusValue,
    dataProcessor: (data) => {
      const value = data["operationStatus"].value;
      return {
        text: _.startCase(value),
        ...operationStatusToStyles(value),
      };
    },
  },
  parkingLot: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingLotName"),
  },
});

export { rowComponentsTask };
