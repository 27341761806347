import React from "react";
import Button from "../../../../../artzu/src/Button";
import styles from "../statusInfo.module.scss";
import { Link } from "react-router-dom";
const DataChangeDetail = ({ content, link }) => {
  const goUrl = () => {
    window.open(link.value, "_blank");
  };
  return (
    <div className={styles.comments}>
      <div className={styles.dataChangeTitle}>{content.title}</div>
      {link ? (
        <div className={styles.bookingFromTo}>
          <div className={styles.bookingInfo}>
            <span className={styles.to}>From:</span>
            <div style={{ marginLeft: "6px" }}>{content.from}</div>
          </div>
          <div className={styles.bookingInfo}>
            <span className={styles.to}>To:</span>
            <div style={{ marginLeft: "6px" }}>{content.to}</div>
          </div>
        </div>
      ) : (
        <div className={styles.vehicleFromTo}>
          <div className={styles.bookingInfo}>
            <span className={styles.to}>From:</span>
            <div style={{ marginLeft: "6px" }}>{content.from}</div>
          </div>
          <div className={styles.bookingInfo}>
            <span className={styles.to}>To:</span>
            <div style={{ marginLeft: "6px" }}>{content.to}</div>
          </div>
        </div>
      )}
      {link && (
        <Button
          label={link.text}
          height="29px"
          fullWidth="155px"
          color="#ffffff"
          backColor="#128dff"
          radius="14.5px"
          size="16px"
          weight="400"
          border="1px solid #128dff"
          padding="0"
          onClick={goUrl}
        />
      )}
    </div>
  );
};

export default DataChangeDetail;
