import React, { useState } from "react";
import { formatOdometer } from "../../../utils";
import _ from "lodash";
import "../vehicle.scss";
import { Fragment } from "react";
import ActionModal from "../../InformationTable/BatchActions/ActionModal";
import VehicleInfoContent from "./VehicleInfoContent";

const VehicleInformation = ({ vehicleInfo, city, refetch }) => {
  const [selectedAction, setSelectedAction] = useState();
  const [addTagOpen, setAddTagOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const avcInfo = vehicleInfo.avcDevice;

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const toggleRefresh = () => {
    refetch();
  };

  const toggleAddTag = () => {
    setAddTagOpen(true);
  };

  const redirectAddTag = () => {
    setSelectedAction("addTag");
  };

  const icons = [
    {
      value: "addTag",
      text: "Add Tag",
    },
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  let vehicleList = [];
  vehicleList.push(vehicleInfo);

  const close = () => {
    setSelectedAction(null);
  };

  return (
    <Fragment>
      <VehicleInfoContent
        isEdit={isEdit}
        toggleEdit={toggleEdit}
        formatOdometer={formatOdometer}
        city={city}
        refetch={refetch}
        redirectAddTag={redirectAddTag}
        vehicle={vehicleInfo}
        toggleAddTag={toggleAddTag}
        avcInfo={avcInfo}
      />
      {addTagOpen && (
        <ActionModal
          isOpen={selectedAction}
          selectedBatchAction={selectedAction}
          batchOptionsHash={batchOptionsHash}
          selections={vehicleList}
          closeModal={close}
          toggleRefresh={toggleRefresh}
          vehicleDetail={true}
          item={"vehicles"}
        />
      )}
    </Fragment>
  );
};

export default VehicleInformation;
