import gql from "graphql-tag";

const NEW_DAMAGE_NOTE = gql`
  mutation addDamageNote($params: NewNoteAttributes!, $damageId: ID!) {
    addDamageNote(params: $params, damageId: $damageId) {
      note {
        id
        user
        details
        pinned
      }
      success
      error
    }
  }
`;

const PIN_DAMAGE_NOTE = gql`
  mutation pinDamageNote($params: PinNoteAttributes!) {
    pinDamageNote(params: $params) {
      success
      error
    }
  }
`;

const EDIT_DAMAGE_NOTE = gql`
  mutation editDamageNote($params: EditNoteAttributes!) {
    editDamageNote(params: $params) {
      success
      error
    }
  }
`;

const DELETE_DAMAGE_NOTE = gql`
  mutation deleteDamageNote($noteId: ID!) {
    deleteDamageNote(noteId: $noteId) {
      success
      error
    }
  }
`;

export {
  NEW_DAMAGE_NOTE,
  PIN_DAMAGE_NOTE,
  EDIT_DAMAGE_NOTE,
  DELETE_DAMAGE_NOTE,
};
