import moment from "moment-timezone";
import { shiftTimes } from "./shiftTimes";
import _ from "lodash";

const m = (x) => moment(x).tz(process.env.REACT_APP_TZ);

const formatOdometer = (num) => Math.round(num).toLocaleString();

const capitalize = (string) => _.startCase(_.toLower(string));

const sortVehicles = (vehicles, descending, type) => {
  let sortedArr = _.sortBy(vehicles, (vehicle) => vehicle[type]);
  return descending ? sortedArr.reverse() : sortedArr;
};

const status = (booking) => {
  if (booking) {
    return booking.isService ? "Service" : "With Driver";
  } else {
    return "Available";
  }
};

const addCommas = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const parsePredictionData = (daysUntil, mileageUntil, unit) => {
  if (daysUntil >= 0) {
    return `In ${daysUntil} days ${
      mileageUntil ? `/ ${addCommas(mileageUntil)}${unit}` : ""
    }`;
  } else if (daysUntil === null) {
    return `${mileageUntil ? `/ ${addCommas(mileageUntil)}${unit}` : ""}`;
  } else {
    return `Overdue by ${daysUntil ? -1 * daysUntil : "--"} days ${
      mileageUntil ? `/ ${addCommas(-1 * mileageUntil)}${unit}` : ""
    }`;
  }
};

const parseUpcomingData = (daysUntil, mileageUntil, unit) => {
  if (daysUntil >= 0) {
    return `${
      mileageUntil && mileageUntil !== "null"
        ? `In ${addCommas(mileageUntil)}${unit}`
        : ""
    }`;
  } else {
    return `${
      mileageUntil && mileageUntil !== "null"
        ? `by ${addCommas(-1 * mileageUntil)}${unit}`
        : `${-1 * daysUntil} days ago`
    }`;
  }
};

const userStatusName = (status) => {
  if (status === "user") {
    return "with driver";
  } else {
    return status ? status : "";
  }
};

const filterLogData = (logs, searchVal, date) => {
  let filteredLogs = logs;
  console.log(filteredLogs);
  if (searchVal) {
    filteredLogs = _.filter(filteredLogs, (log) => {
      return (
        (log.userName &&
          log.userName.toLowerCase().includes(searchVal.toLowerCase())) ||
        _.lowerCase(_.startCase(log.operationStatus)).includes(
          _.lowerCase(searchVal)
        )
      );
    });
  }

  if (date) {
    filteredLogs = _.filter(filteredLogs, (log) => {
      const startTimeMoment = log.startTime && m(log.startTime);
      const endTimeMoment = log.endTime && m(log.endTime);
      if (!endTimeMoment) {
        return m(date).isAfter(startTimeMoment);
      } else if (!startTimeMoment) {
        return m(date).isBefore(endTimeMoment);
      } else {
        return m(date).isBetween(startTimeMoment, endTimeMoment, "day", "[]");
      }
    });
  }

  return filteredLogs;
};

const editBookingSandwich = (bookings) => {
  const bookingArray = bookings;
  let modifiedBookings = [];
  bookings.forEach((booking, index) => {
    if (booking) {
      if (index === 0) {
        if (m(booking.endTime) > m()) {
          let newBooking = { ...booking };
          newBooking.endTime = null;
          modifiedBookings.push(newBooking);
        } else {
          modifiedBookings.push({
            bookingType: "available",
            startTime: booking.endTime,
            endTime: null,
          });
          modifiedBookings.push(booking);
        }
      } else {
        if (bookingArray[index - 1].startTime === booking.endTime) {
          modifiedBookings.push(booking);
        } else {
          modifiedBookings.push({
            bookingType: "available",

            startTime:
              booking.endTime > bookingArray[index - 1].startTime
                ? booking.endTime
                : booking.endTime,
            endTime: bookingArray[index - 1].startTime,
          });
          modifiedBookings.push(booking);
        }
      }
    }
  });

  return modifiedBookings;
};

const cancelEdits = (type) => {};

const reorderAllProj = (allProj, order) => {
  let orderPending = _.filter(allProj, function(allProj) {
    return order.some((selection) => {
      return selection === allProj.name;
    });
  });

  return orderPending;
};

const reorderNotes = (notes) => {
  let partitionNotes = _.partition(notes, { pinned: true });
  return partitionNotes[0].concat(partitionNotes[1]);
};

const sortHardwareStatus = (status) => {
  switch (status) {
    case "good":
      return 1;
    case "warning":
      return 2;
    case "bad":
      return 3;
    case "no_device":
      return 4;
    default:
      return 4;
  }
};

const sortDocuments = (docs, params) => {
  if (docs.length > 0) {
    let sortedDocs = _.filter(docs, function(docs) {
      return params.some((param) => {
        return docs.name.includes(param);
      });
    });
    return sortedDocs;
  } else {
    return [];
  }
};

const vehicleStatusToStyle = (status) => {
  switch (status) {
    case "Service":
      return "black";
    case "Available":
      return "2px solid #128DFF";
    case "Deactivated":
      return "2px solid #FF0000";
    case "Unauthorized":
    case "Late Driver":
      return "#FF0000";
    case "With Driver":
    case "User":
      return "#128DFF";
    case "Offline":
      return "2px solid #414141";
    default:
      return "";
  }
};

const calcVehicleStatus = (vehicle, currentBooking) => {
  if (currentBooking) {
    if (moment(currentBooking.endTime).isBefore()) {
      return "Late Driver";
    } else {
      return capitalize(currentBooking.bookingType);
    }
  } else {
    if (!vehicle.lastGps || vehicle.lastGps.inParking) {
      return "Available";
    } else {
      return "Unauthorized";
    }
  }
};

const otherDocs = (docs, params) => {
  if (docs.length > 0) {
    let sortedDocs = _.filter(docs, function(docs) {
      return params.every((param) => {
        return !docs.name.includes(param);
      });
    });
    return sortedDocs;
  } else {
    return [];
  }
};

const overallStatus = (routineServices) => {
  if (routineServices && routineServices.length) {
    const statuses = routineServices.map((service) => service.status);
    return Number(_.max(statuses));
  } else {
    return 1; // default status is 4, then the service status drop could filter by the number
  }
};

const spreadFleetData = (vehicles, reorder) => {
  return vehicles.map((vehicle) => ({
    id: vehicle.id,
    status: _.startCase(vehicle.operationStatus),
    serviceStatus: vehicle.serviceStatus,
    plate: vehicle.plate,
    year: vehicle.year,
    imageLink: vehicle.imageLink,
    make: vehicle.make,
    model: vehicle.model,
    fleet: vehicle.fleet.name,
    serviceGroup: vehicle.serviceGroup.name,
    parkingSpot: vehicle.parkingSpot,
    parkingSpotNum:
      vehicle.parkingSpot && Number(vehicle.parkingSpot.identifier),
    parkingLocation: vehicle.parkingLot.name,
    lockboxCode: vehicle.lockboxCode,
    currentLocation: vehicle.currentLocation,
    currentLocationName: vehicle.currentLocation.name,
    odometer: vehicle.odometer && vehicle.odometer.reading,
    nextEvent: vehicle.nextEvent && vehicle.nextEvent.name,
    nextEventDate: vehicle.nextEvent && vehicle.nextEvent.scheduledDate,
    city: vehicle.parkingLot.city.name,
    medallionNumber: vehicle.medallionNumber,
    lastInspected: vehicle.lastInspected && vehicle.lastInspected.inspector,
    lastInspectedDate:
      vehicle.lastInspected && vehicle.lastInspected.completedDate,
    allProj: vehicle.recentProjectedServices,
    nextProj:
      vehicle.recentProjectedServices.length &&
      vehicle.recentProjectedServices[0],
    nextProjDays:
      reorder.length && vehicle.recentProjectedServices.length
        ? reorderAllProj(vehicle.recentProjectedServices, reorder)[0].daysUntil
        : vehicle.recentProjectedServices.length
        ? vehicle.recentProjectedServices[0].daysUntil
        : null,
    tags: vehicle.tags.length > 0 ? vehicle.tags : null,
    tagName: vehicle.tags.length > 0 ? vehicle.tags[0].name : null,
    serviceBooking: vehicle.currentBooking ? vehicle.currentBooking : null,
    serviceStart: vehicle.currentBooking
      ? vehicle.currentBooking.startTime
      : null,
    serviceEnd: vehicle.currentBooking ? vehicle.currentBooking.endTime : null,
    serviceTime:
      vehicle.currentBooking && status(vehicle.currentBooking) === "Service"
        ? vehicle.currentBooking.startTime
        : null,
    parkingPassId: vehicle.parkingPassId,
    parkingLot: vehicle.parkingLot,
    availableLots: vehicle.availableParkingLots,
    currentServiceLocation: vehicle.currentServiceLocation,
    hardwareStatus: vehicle.gpsStatus,
    hardwareStatusValue:
      vehicle.gpsStatus && sortHardwareStatus(vehicle.gpsStatus.value),
    vin: vehicle.vin,
    inParking: vehicle.inParking,
    availableParkingSpots: vehicle.availableParkingSpots,
    hasAvcDevice: vehicle.hasAvcDeviceInstalled,
    diagnosticTroubleCodes:
      vehicle.diagnosticTroubleCodes
        .map((code) => `${code.code} (${code.status})`)
        .join(", ") || "--",
  }));
};

const searchData = {
  service: [
    { name: "All", value: 0 },
    { name: "Good", value: 1 },
    { name: "Warning", value: 2 },
    { name: "Overdue", value: 3 },
    { name: "N/A", value: 4 },
  ],
  plate: [
    { name: "All", value: 0 },
    { name: "Available", value: 1 },
    { name: "With Driver", value: 2 },
    { name: "Service", value: 3 },
    { name: "Deactivated", value: 4 },
    { name: "Offline", value: 5 },
    { name: "Unauthorized", value: 6 },
    { name: "Late Driver", value: 7 },
  ],
};
//Spreads map data and merges in booking
const spreadMapData = (vehicles, bookings) =>
  vehicles
    .filter((v) => v.lastGps)
    .map((vehicle) => {
      const currentBooking = _.find(bookings, (b) => b.vehicleId == vehicle.id);
      return {
        ...vehicle,
        booking: currentBooking,
        vehiclePriority: getVehiclePriority(vehicle.operationStatus),
        model: vehicle.make + " " + vehicle.model,
        lastGpsTime: vehicle.lastGps.capturedAt,
        tier: vehicle.fleet.name,
        status: capitalize(vehicle.operationStatus),
        currentLocation: vehicle.currentLocation,
        locationName: vehicle.currentLocation.name,
        tags: vehicle.tags,
      };
    });

const getExistingTags = (vehicles) => {
  let existingTags = [];
  vehicles.forEach((vehicle) => {
    if (vehicle.tags) {
      existingTags.push(...vehicle.tags);
    }
  });

  return _.uniqBy(existingTags, "name");
};

const getVehicleTags = (tags) => {
  let tagNames = [];
  if (tags.length > 0) {
    tags.forEach((tag) => {
      tagNames.push(tag.name);
    });
  }
  return tagNames;
};

const editedValuesParams = (vehicleId, type, value) => {
  let params = this.state.editParams;
  let values =
    type === "odometer"
      ? {
          vehicleId,
          value: value,
          type: type,
        }
      : type === "code"
      ? {
          vehicleId,
          value: value,
          type: type,
        }
      : type === "parking"
      ? {
          vehicleId,
          value: value,
          type: type,
        }
      : null;

  if (params.length > 0) {
    _.remove(params, (param) => {
      return param.vehicleId === vehicleId && param.type === type;
    });
    params.push(values);
  } else {
    params.push(values);
  }

  return params;
};

// const pathFormat = path => {
//   let formattedPath = _.filter(path.split("/"), name => {
//     return name !== "";
//   });
//   if (formattedPath === []) {
//     return "Vehicles";
//   } else {
//     return formattedPath[0].charAt(0).toUpperCase() + formattedPath[0].slice(1);
//   }
// };

const formatNavTitle = (vehicle, pathName, handleNav) => {
  const navVehicleData = {};
  let navArr = [];

  let formattedPath = _.filter(pathName.split("/"), (name) => {
    return name !== "";
  });
  // console.log(pathName);

  if (formattedPath.length === 0) {
    formattedPath.push("vehicles");
  }
  // console.log(formattedPath);
  let definePath = "";

  formattedPath.forEach((path, index) => {
    definePath = definePath + `/${path}`;
    // console.log(definePath);
    let pathTitle = null;
    if (path.includes("-")) {
      let formatted = _.filter(path.split("-"), (name) => {
        return name !== "";
      });
      let dashFormat = [];
      formatted.forEach((word) => {
        dashFormat.push(word.charAt(0).toUpperCase() + word.slice(1));
      });
      pathTitle = dashFormat.join(" ");
    } else {
      pathTitle = path.charAt(0).toUpperCase() + path.slice(1);
    }

    if (vehicle && path === vehicle.id) {
      navArr.push({
        title: vehicle.plate,
        navPathName:
          formattedPath[index - 1] === ""
            ? "/vehicles" + definePath
            : definePath,
        navFunction: handleNav,
      });
    } else {
      if (index === 2 && vehicle && navArr[1].title === vehicle.plate) {
        navArr[1].title = `${vehicle.plate} - ${pathTitle}`;
        navArr[1].navPathName = definePath;
        navArr[1].navFunction = handleNav;
        //used for new Vehicle Details page
      } else {
        if (path === "map" && vehicle) {
          navArr[0] = {
            title: pathTitle,
            navPathName: definePath,
            navFunction: handleNav,
          };
          navArr[1] = {
            title: vehicle.plate,
            navPathName: definePath,
            navFunction: handleNav,
          };
        } else {
          navArr.push({
            title: pathTitle,
            navPathName: definePath,
            navFunction: handleNav,
          });
        }
      }
    }
  });

  return navArr;
};

const merge = (vehicles, bookings) => {
  return vehicles
    .filter((v) => v.lastGps)
    .map((vehicle) => {
      let booking = _.find(
        bookings,
        (booking) => booking.vehicleId === vehicle.id
      );
      return { ...vehicle, booking };
    });
};

const nextShift = () => {
  for (let i = 0; i < shiftTimes.length; i++) {
    const seconds = shiftTimes[i].diff(m(), "seconds");
    if (seconds > 0) {
      return seconds * 1000;
    }
  }
};

const outsideGeofence = ({ parkingLot, lastGps }) => {
  if (
    outsideRange(...parkingLot.latBounds, lastGps.lat) ||
    outsideRange(...parkingLot.lngBounds, lastGps.lng)
  ) {
    return true;
  }
  return false;
};

const outsideRange = (min, max, actual) => actual >= max || actual <= min;

const getVehiclePriority = (vehicleStatus) => {
  switch (vehicleStatus) {
    case "unauthorized":
      return 1;
    case "late_driver":
      return 2;
    case "with_driver":
      return 3;
    case "service":
      return 4;
    case "offline":
      return 5;
    case "deactivated":
      return 5;
    case "available":
      return 6;
    default:
      return 10;
  }
};

const getSteeringColor = (priority) => {
  switch (priority) {
    case 2:
      return "yellow";
    case 3:
      return "green";
    case 4:
      return "red";
    default:
      return "grey-steering";
  }
};

const titleize = (string) =>
  string &&
  string
    .split("_")
    .map((s) => (s.length > 0 ? s[0].toUpperCase() + s.slice(1) : ""))
    .join(" ");

export {
  parsePredictionData,
  parseUpcomingData,
  m,
  formatOdometer,
  capitalize,
  sortVehicles,
  spreadFleetData,
  spreadMapData,
  merge,
  nextShift,
  getSteeringColor,
  searchData,
  status,
  reorderAllProj,
  reorderNotes,
  getExistingTags,
  overallStatus,
  sortDocuments,
  otherDocs,
  getVehicleTags,
  editedValuesParams,
  formatNavTitle,
  editBookingSandwich,
  filterLogData,
  calcVehicleStatus,
  vehicleStatusToStyle,
  titleize,
};
