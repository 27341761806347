import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import UpdateParkingSpot from "../../../Mutations/UpdateParkingSpot";

import "../../Vehicle/vehicle.scss";
import Option from "../../../artzu/src/Option";
import SelectField from "../../../artzu/src/SelectField";

class ParkingSpotInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkingSpot: this.props.parkingSpot
    };
  }

  render() {
    const { parkingSpot } = this.state;
    const {
      availableParkingSpots,
      details,
      type,
      plate,
      vehicleId,
      editParams,
      updateEdit
    } = this.props;

    return (
      <div className="lockbox" style={details ? { textAlign: "left" } : null}>
        <SelectField
          prompt={parkingSpot ? parkingSpot.identifier : "--"}
          width={details ? "200px" : "100%"}
          height="24px"
          fontSize="14px"
          fontWeight="600"
          primaryColor="#414141"
          onChange={(value, heading) => {
            const editedParkingSpot = {
              identifier: heading,
              id: value
            };
            editParams(Number(vehicleId), type, String(value));
            updateEdit(plate, type, editedParkingSpot);
          }}
        >
          {availableParkingSpots.map(spot => (
            <Option label={spot.identifier} value={spot.id} />
          ))}
        </SelectField>
      </div>
    );
  }
}

const styles = theme => ({
  parkingSpotInput: {
    width: "100px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  parkingSpotEdit: {
    width: "100px",
    color: "#128DFF",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  },
  parkingSpotError: {
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    border: "1px solid #DEDEDE"
  }
  // underline: {
  //   "&:after": {
  //     borderBottom: `2px solid ${theme.palette.blue}`,
  //   },
  // },
});

export default withStyles(styles)(ParkingSpotInput);
