import React from "react";
import ConfirmationMessage from "./ConfirmationMessage";
import MakeAvailableMutation from "./MakeAvailableMutation";

const MakeAvailable = ({ vehicle, setLoading, closeModal }) => {
  const confirmationMessage = getConfirmationMessage(vehicle.inParking);
  return (
    <>
      <ConfirmationMessage
        isWarning={!vehicle.inParking}
        message={confirmationMessage}
      />
      <MakeAvailableMutation
        vehicleId={vehicle.id}
        setLoading={setLoading}
        closeModal={closeModal}
      />
    </>
  );
};

const getConfirmationMessage = isVehicleInLot => {
  if (isVehicleInLot) {
    return "Are you sure you want to make this vehicle available?";
  } else {
    return "Are you sure you want to make this vehicle available? The vehicle is not in the parking lot and would run the risk of being assigned.";
  }
};

export default MakeAvailable;
