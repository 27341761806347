import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import "../Components/Vehicle/vehicle.scss";

class DeleteServiceEvent extends Component {
  DELETE_SERVICE_EVENT = gql`
    mutation deleteServiceEvent($deleteEvent: UpdateServiceEventAttributes!) {
      updateServiceEvent(params: $deleteEvent) {
        success
        errors
      }
    }
  `;

  render() {
    const { deleteEvent } = this.props;
    return (
      <Mutation
        mutation={this.DELETE_SERVICE_EVENT}
        variables={{ deleteEvent }}
        onError={data => {
          console.log("error", data);
        }}
        refetchQueries={["getServiceEvents"]}
        awaitRefetchQueries={true}
      >
        {deleteServiceEvent => (
          <p
            className="delete-note no-margin small-font"
            onClick={evt => {
              if (
                window.confirm(
                  "Are you sure you would like to delete this event?"
                )
              ) {
                deleteServiceEvent();
              }
              evt.stopPropagation();
            }}
          >
            Delete
          </p>
        )}
      </Mutation>
    );
  }
}

export default DeleteServiceEvent;

// comment
