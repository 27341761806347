const types = [
  { name: "Accident", value: "Accident" },
  { name: "Damage", value: "Damage" },
  { name: "Clean", value: "Clean" },
  { name: "Fleeting", value: "Fleeting" },
  { name: "Hub", value: "Hub" },
  { name: "Maintenance", value: "Maintenance" },
  { name: "Tire", value: "Tire" },
  { name: "Accident Report", value: "Accident Report" },
];

export { types };
