import React, { useState } from "react";
import Modal from "../../../artzu/src/Modal";
import styles from "../task.module.scss";

const CancelConfirmModal = ({
  open,
  toggleCancel,
  setCurType,
  setCurDescription,
  setAdminId,
  taskData,
  cancelEditCallback,
  toggleEdit,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.cancelContainer}>
        <div className={styles.popupTitle}>Are you sure?</div>
        <div>
          Are you sure you want to cancel? cancelling will discard all changes.
        </div>
        <div className={styles.reasonButtons}>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => {
                toggleCancel();
              }}
            >
              No
            </button>
          </div>
          <div>
            <button
              className={styles.chooseButton}
              onClick={() => {
                cancelEditCallback();
                toggleEdit();
                toggleCancel();
                setCurType(taskData.taskType);
                setCurDescription(taskData.description);
                setAdminId(taskData.user && taskData.user.id);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelConfirmModal;
