import React, { useState, useEffect } from "react";
import Modal from "../../../../../../artzu/src/Modal";
import styles from "../../../batchActions.module.scss";
import Button from "../../../../../../artzu/src/Button";
import DeleteTagModal from "./DeleteTagModal";
import ManageAllTagsSearch from "./ManageAllTagsSearch";
import AllTagsPanel from "./AllTagsPanel";
import MutableButton from "./MutableButton";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_ALL_TAGS } from "../queries";

const ManageAllTagsModal = ({
  open,
  parentCallbackClose,
  refetchAddTag,
  entry,
}) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_TAGS, {
    fetchPolicy: "network-only",
  });
  if (data) {
    refetch();
  }
  const [searchTag, setSearchTag] = useState();
  const [allCurrentTags, setAllCurrenttags] = useState(data && data.getAllTags);
  const [editWindow, setEditWindow] = useState(false);
  const [editing, setEditing] = useState();
  const [itemSelected, setItemSelected] = useState([]);
  const [countSelected, setCountSelected] = useState();
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (data) {
      setAllCurrenttags(data.getAllTags);
    }
  }, [data && data.getAllTags]);

  const triggerNew = (e) => {
    setSearchTag(e.target.value);
  };

  const toggleEditWindow = () => {
    setEditWindow(!editWindow);
  };

  const toggleDeleteWindow = () => {
    setIsDelete(!isDelete);
  };

  const handleEditing = (tag) => {
    setEditing(tag);
  };

  const selectItem = (tagName, count) => {
    let newSelected = [...itemSelected];
    const included = _.includes(
      JSON.stringify(newSelected),
      JSON.stringify(tagName)
    );
    included
      ? _.remove(newSelected, (name) => name === tagName)
      : newSelected.push(tagName);
    setItemSelected(newSelected);
    setCountSelected(count);
  };
  const numOfSelected = itemSelected.length;

  if (allCurrentTags) {
    var allVehicleTags = [...allCurrentTags];
    allVehicleTags.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  }

  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.manageTags}>
        <div className={styles.manageContainer}>
          <div className={styles.titleButton}>
            <h4>Manage Tags</h4>
            <MutableButton
              triggerNew={triggerNew}
              searchTag={searchTag}
              allTags={allCurrentTags}
              count={numOfSelected}
              itemSelected={itemSelected}
              countSelected={countSelected}
              refetch={refetch}
              refetchAddTag={refetchAddTag}
              entry={entry}
              setItemSelected={setItemSelected}
            />
          </div>

          <div>
            <ManageAllTagsSearch
              triggerNew={triggerNew}
              searchTag={searchTag}
            />
          </div>
          <AllTagsPanel
            searchTag={searchTag}
            allVehicleTags={allVehicleTags}
            toggleEditWindow={toggleEditWindow}
            parentCallbackEditing={handleEditing}
            toggleDeleteWindow={toggleDeleteWindow}
            selectItem={selectItem}
            itemSelected={itemSelected}
          />
          <DeleteTagModal
            tag={editing}
            allTags={allCurrentTags}
            isDelete={isDelete}
            parentCallback={toggleDeleteWindow}
            refetch={refetch}
            refetchAddTag={refetchAddTag}
            entry={entry}
            setItemSelected={setItemSelected}
          />
          <div className={styles.closeTags}>
            <Button
              label={"Close"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                parentCallbackClose();
                setItemSelected([]);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManageAllTagsModal;
