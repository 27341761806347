import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faGasPump } from "@fortawesome/pro-solid-svg-icons";
import { formatOdometer } from "../../../../utils";

const OdoGasDisplay = ({ odometer, city, gasLevel }) => {
  return (
    <div className="botLeft">
      <div className="odometer-display">
        <div className="odometer-icons">
          <FontAwesomeIcon icon={faTachometerAlt} />
        </div>
        <div className="odometer-icons">
          {formatOdometer(odometer && odometer.reading) +
            (city.name === "toronto" ? " km" : " mi")}
        </div>
      </div>
      <div className="serviceButton">
        <div
          className="odometer-display"
          style={{ justifyContent: "flex-end" }}
        >
          <div className="fuel-icons">
            <FontAwesomeIcon icon={faGasPump} />
          </div>
          <div className="fuel-bar">
            {gasLevel ? (
              <div
                className="fuel-fill"
                style={{
                  width: `${gasLevel.reading}%`,
                }}
              ></div>
            ) : null}
          </div>
          <div className="odometer-icons">
            {gasLevel ? `${gasLevel.reading}%` : "--"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OdoGasDisplay;
