import React from "react";
import styles from "../locationSelect.module.scss";
import classNames from "classnames";
import _ from "lodash";
import ParkingLot from "./DropdownParkingLots";

const City = ({
  lots,
  selectCities,
  selectLots,
  isCitySelected,
  bothSelected,
  lotsSelected,
}) => {
  return (
    <div>
      {_.map(lots, (element, key) => {
        return (
          <div className={styles.textContainer} key={key}>
            <div
              className={classNames(
                styles.city,
                styles.hover,
                (isCitySelected(key) || bothSelected) && styles.selected
              )}
              onClick={() => selectCities(key)}
            >
              {" "}
              {_.startCase(key)}
            </div>
            <ParkingLot
              element={element}
              lotsSelected={lotsSelected}
              selectLots={selectLots}
            />
          </div>
        );
      })}
    </div>
  );
};

export default City;
