import React from "react";
import Modal from "../../../../../../../artzu/src/Modal";
import styles from "../../../../batchActions.module.scss";
import Button from "../../../../../../../artzu/src/Button";

const MergeTagModal = ({
  open,
  toggleMerge,
  setItemSelected,
  handleSelection,
  findCount,
  mergeTags,
  itemSelected,
  combinedTag,
}) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.mergeTag}>
        <h4>Merge Tags</h4>
        <div className={styles.mergeSubtitle}>
          Please select one of the following tags as the desinated tag
        </div>
        <div className={styles.mergeOptions} onChange={handleSelection}>
          {itemSelected.map((item) => (
            <div className={styles.choice}>
              <input type="radio" value={item} name="tag" /> {item} (
              {findCount(item)})
            </div>
          ))}
        </div>
        <div className={styles.mergeButtons}>
          <div className={styles.mergeCancelButton}>
            <Button
              label={"Cancel"}
              fullWidth="152px"
              height="40px"
              color="#414141"
              backColor="#ffffff"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={toggleMerge}
            />
          </div>
          <div className={styles.mergeSaveButton}>
            <Button
              label={"Merge"}
              fullWidth="152px"
              height="40px"
              color="#FFFFFF"
              backColor="#128DFF"
              radius="20px"
              size="16px"
              weight="700"
              border="1px solid #FFFFFF"
              onClick={() => {
                combinedTag && toggleMerge();
                combinedTag && mergeTags();
                combinedTag && setItemSelected([]);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MergeTagModal;
