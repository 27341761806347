import React from "react";
import "./fleet.css";
import styles from "./table.module.scss";
import "../VehicleNew/vehicle.scss";
import classNames from "classnames";
import Button from "../../artzu/src/Button";
import moment from "moment";

const PlateStatus = ({ operationStatus, title }) => {
  const statusColor = operationStatus && operationStatus.styles.color;
  const isSolid = operationStatus && operationStatus.styles.isSolid;

  return (
    <div>
      <div className="operation-container">
        <div className={title ? "status-line1-sidebar" : "status-line1"}>
          <div
            className={title ? "operationSymbolSidebar" : "operationSymbol"}
            style={
              isSolid
                ? { background: `${statusColor}` }
                : {
                    background: title ? "#414141" : "#ffffff",
                    border: `${statusColor}`,
                  }
            }
          />
          <div className={title ? "operationStatusSidebar" : "operationStatus"}>
            {operationStatus && operationStatus.name}
          </div>
        </div>

        {!title && (
          <div className="detailed-info">
            {operationStatus &&
              moment(Date.parse(operationStatus.subtext)).format(
                "hh:mm A MMM DD, YYYY "
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlateStatus;
