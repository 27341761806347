import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import styles from "../task.module.scss";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../artzu/src/Modal";
import { UNRESOLVE_TASK } from "../queries";

const TaskActionPopup = ({
  open,
  action,
  togglePopup,
  refreshTaskCallback,
  taskData,
}) => {
  const [reason, setReason] = useState();

  const changeReason = (e) => {
    setReason(e.target.value);
  };

  const getAction = (action) => {
    switch (action) {
      case "Unresolve task":
        unresolveTask();
        break;
      default:
        break;
    }
  };

  const [unresolveTaskMutation, { loading: isUnresolveLoading }] = useMutation(
    UNRESOLVE_TASK
  );
  const unresolveTask = () => {
    unresolveTaskMutation({
      variables: {
        taskId: taskData.id,
        reason: reason,
      },
    }).then(({ data: { unresolveTask } }) => {
      if (unresolveTask.task) {
        refreshTaskCallback({ taskId: taskData.id });
        togglePopup();
        console.log("Unresolve Succeed!");
      } else {
        console.log("Unresolve error!");
      }
    });
  };

  const isLoading = isUnresolveLoading;

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.popupContainer}>
        <div className={styles.taskPopupTitle}>Are you sure?</div>
        <div className={styles.reasonInput}>
          Reason to {action && action.toLowerCase()}
          <textarea
            className={styles.reasonBox}
            onChange={changeReason}
          ></textarea>
        </div>
        <div className={styles.reasonButtons}>
          <div>
            <button
              className={styles.noButton}
              onClick={() => {
                togglePopup();
              }}
            >
              No
            </button>
          </div>
          <div>
            <button
              className={reason ? styles.yesButton : styles.yesButtonInvalid}
              onClick={() => {
                !isLoading && reason && getAction(action);
              }}
            >
              {isLoading ? (
                <FontAwesomeIcon
                  style={{ color: "#128dff" }}
                  spin
                  size={"1x"}
                  icon={faSpinner}
                />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TaskActionPopup;
