import React, { Component } from "react";
import "./layout.css";
import { observer, inject } from "mobx-react";
import MapView from "../MapView";
import withAuth from "../../Containers/withAuth";

const MapBody = inject(
  "store",
  "bStore"
)(
  observer(
    class MapBody extends Component {
      // injects mobx store into this component from above
      mapBody = () => {
        const { store, bStore } = this.props;

        return (
          <div className="site-body">
            <MapView
              store={store}
              bStore={bStore}
              history={this.props.history}
            />
          </div>
        );
      };

      authorized = () =>
        this.props.roles.map((role) => role.type).includes("gps");

      render() {
        if (this.authorized()) {
          return this.mapBody();
        } else {
          // return <Redirect to="/fleets" />
          // replace this with above line, or similar link
          return <p style={{ marginTop: "6em" }}>Not available</p>;
        }
      }
    }
  )
);

export default withAuth(MapBody);
