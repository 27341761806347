import React from "react";
import Button from "../../../../artzu/src/Button";
import styles from "../../task.module.scss";
import {
  UPDATE_LOCKBOX_CODE,
  UPDATE_VEHICLE_DETAILS,
} from "../../../VehicleNew/VehicleInformation/VehicleInfo/query";
import { useMutation } from "@apollo/client";

const UpdateVehicle = ({
  taskData,
  toggleEdit,
  refreshTaskCallback,
  curBoxCode,
  curSpot,
}) => {
  const [lockboxCodeMutation, { loading: isLockBoxLoading }] = useMutation(
    UPDATE_LOCKBOX_CODE
  );
  const updateLockboxCode = () => {
    lockboxCodeMutation({
      variables: {
        vehicleId: taskData?.vehicle?.id,
        lockboxCode: curBoxCode,
      },
    }).then(({ data: { updateLockboxCode } }) => {
      if (updateLockboxCode.success) {
        console.log("Update Lockbox Code Succeed!");
      } else {
        console.log("Update error!");
      }
    });
  };

  const [vehicleDetailsMutation, { loading: isUpdateLoading }] = useMutation(
    UPDATE_VEHICLE_DETAILS
  );

  const updateVehicleParkingSpot = () => {
    vehicleDetailsMutation({
      variables: {
        vehicleId: taskData?.vehicle?.id,
        parkingSpotIdentifier: curSpot,
      },
    }).then(({ data: { updateVehicleDetails } }) => {
      if (updateVehicleDetails.success) {
        console.log("Update Parking Spot Succeed!");
      } else {
        console.log("error!");
      }
    });
  };

  const isCodeUpdateNeeded = taskData?.vehicle?.lockboxCode !== curBoxCode;
  const isSpotUpdateNeeded =
    taskData?.vehicle?.parkingSpot?.identifier !== curSpot;

  return (
    <div className={styles.editModeButtons}>
      <div className={styles.cancelInfoEdit}>
        <Button
          fullWidth="82px"
          height="33px"
          radius="17px"
          border="1px solid #414141"
          color="#414141"
          padding="7px 16px 7px 16px"
          label="Cancel"
          onClick={toggleEdit}
        />
      </div>
      <Button
        fullWidth="82px"
        height="33px"
        radius="17px"
        border="1px solid #128DFF"
        color="#128DFF"
        padding="7px 16px 7px 16px"
        label="Save"
        onClick={() => {
          toggleEdit();
          isCodeUpdateNeeded && updateLockboxCode();
          isSpotUpdateNeeded && updateVehicleParkingSpot();
          refreshTaskCallback({ taskId: taskData?.id });
        }}
      />
    </div>
  );
};

export default UpdateVehicle;
