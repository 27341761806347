import React from "react";
import SingleCell from "./SingleCell";
import classNames from "classnames";
import styles from "./vehicles_available.module.scss";
import moment from "moment";

const TableContent = ({ day, index, toggleCell, criteria }) => {
  const isToday = (date) => {
    return moment().isSame(date, "day");
  };
  return (
    <div key={`${day.date}-${index}`} className={styles.singleDay}>
      <div
        className={classNames(styles.date, isToday(day.date) && styles.today)}
      >
        <div className={styles.largeFont}>{moment(day.date).format("D")}</div>
        <div>{moment(day.date).format("ddd")}</div>
      </div>
      {day.availability.map((tier, j) => (
        <div
          key={j}
          className={styles.singleCell}
          onClick={() => toggleCell(day, j)}
        >
          <SingleCell tier={tier} criteria={criteria} />
        </div>
      ))}
    </div>
  );
};

export default TableContent;
