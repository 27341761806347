import { useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import TaskTop from "./TaskTopContent/TaskTop";
import styles from "./task.module.scss";
import TaskLeft from "./TaskLeftContent/TaskLeft";
import TaskRight from "./TaskRightContent/TaskRight";

const TaskPageContent = ({
  taskData,
  params,
  refreshTaskCallback,
  currentAdmin,
  presetVehicleId,
}) => {
  const [isEdit, setIsEdit] = useState();
  const [isCancel, setIsCancel] = useState();
  const [dueDate, setDueDate] = useState();
  const [vehicleId, setVehicleId] = useState(presetVehicleId);

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const cancelEditCallback = () => {
    setIsCancel(true);
  };

  const dueDateCallback = (childData) => {
    setDueDate(childData);
  };

  const vehicleIdCallback = (childData) => {
    setVehicleId(childData);
  };

  return (
    <Fragment>
      <TaskTop taskData={taskData} refreshTaskCallback={refreshTaskCallback} />
      <div className={styles.mainTask}>
        <TaskLeft
          params={params}
          taskData={taskData}
          dueDate={dueDate}
          vehicleId={presetVehicleId || vehicleId}
          refreshTaskCallback={refreshTaskCallback}
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          cancelEditCallback={cancelEditCallback}
          currentAdmin={currentAdmin}
          setDueDate={setDueDate}
        />
        <TaskRight
          params={params}
          taskData={taskData}
          dueDate={dueDate}
          dueDateCallback={dueDateCallback}
          vehicleIdCallback={vehicleIdCallback}
          refreshTaskCallback={refreshTaskCallback}
          isEdit={isEdit}
          isCancel={isCancel}
          currentAdmin={currentAdmin}
          presetVehicleId={presetVehicleId}
        />
      </div>
    </Fragment>
  );
};

export default TaskPageContent;
