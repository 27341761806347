import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "../Components/Login";
import Layout from "../Components/Layout";

const Root = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/" component={Layout} />
    </Switch>
  </BrowserRouter>
);

export default Root;
