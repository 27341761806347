import React from "react";

const VehicleInfoDetails = ({ details, content }) => {
  return (
    <div className="vehicle-information">
      <p className="tight-margin-details">{details}</p>
      <p className="tight-margin-content">{content || "--"}</p>
    </div>
  );
};

export default VehicleInfoDetails;
