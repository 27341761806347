import React from "react";
import Modal from "../../artzu/src/Modal";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import styles from "./modal.module.scss";

const NotesModal = ({
  open,
  children,
  closeModal,
  title,
  map,
  height,
  width,
}) => (
  <Modal open={open} backgroundColor="none" padding="0">
    <div className={styles.notesContainer}>
      {" "}
      <div className={styles.notesHeader}>
        {" "}
        <div className={styles.notesTitle}> {title} </div>
      </div>
      <div className={styles.children}> {children} </div>
    </div>
  </Modal>
);

export default NotesModal;
