import React, { Component } from "react";
import { observer } from "mobx-react";
import Button from "../../../artzu/src/Button";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TimeSelect from "./TimeSelect";
import { m } from "../../../utils";

import "./modeSelect.css";

const styles = theme => ({
  datetimePicker: { margin: "0px" },
  hide: { display: "none" },
  show: { display: "block" },
  applyButton: {
    fontSize: "20px"
  },
  applyCurrent: {
    margin: "0 auto 0 auto"
  },
  timeSelectContainer: {
    display: "flex"

    // flexDirection: window.innerWidth > 1024 ? "row" : "column"
  }
});

const TimeContainer = observer(
  class TimeContainer extends Component {
    constructor() {
      super();
      this.state = {};
    }

    handleClick = () => {
      const { store, selectedMode } = this.props;
      switch (selectedMode) {
        case 1:
          store.changeHistoryMode("single");
          store.changeMode("history");
          break;
        case 2:
          store.changeHistoryMode("range");
          store.changeMode("history");
          break;
        default:
          store.changeMode("current");
          break;
      }
      this.props.startLoad();
    };

    render() {
      const {
        store,
        classes,
        selectedMode,
        updateTime,
        startTime,
        endTime
      } = this.props;

      return (
        <div
          className={classNames("time-select-container")}
          style={
            selectedMode >= 1
              ? null
              : { justifyContent: "center", display: "none" }
          }
        >
          <div className={classes.timeSelectContainer}>
            <div>
              <TimeSelect
                label={selectedMode === 2 ? "Start:" : "Time:"}
                show={selectedMode >= 1}
                name={"startTime"}
                updateTime={updateTime}
                markedDate={startTime}
              />
              <TimeSelect
                label="End:"
                updateTime={updateTime}
                show={selectedMode >= 2}
                name={"endTime"}
                markedDate={endTime}
              />
            </div>
            <div
              style={{
                margin: "auto 0 auto 0",
                textAlign: "left",
                height: "auto"
              }}
            >
              <Button
                className={classNames(
                  classes.applyButton,
                  selectedMode >= 1 || store.mode === "history"
                    ? classes.show
                    : classes.hide
                )}
                fullWidth="90px"
                height="40px"
                border="1px solid #128DFF"
                radius="20px"
                label="Apply"
                onClick={this.handleClick}
              >
                {" "}
                Apply{" "}
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
);

export default withStyles(styles)(TimeContainer);
