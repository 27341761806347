import React from "react";
import Button from "../../../artzu/src/Button";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const VehicleHeader = ({
  year,
  plate,
  make,
  model,
  imageLink,
  classes,
  id,
  store
}) => (
  <div className="vehicle-header-container">
    <div>
      <div className={classes.header}>
        <div className="header-title">
          <div className="small">{year}</div>
          <div className="large">
            {make} {model}
          </div>
        </div>

        <div className="header-title">
          <div className="small">License Plate</div>
          <div className="large">{plate}</div>
        </div>
      </div>
    </div>
    <img className="display-photo" src={imageLink} alt="Vehicle" />
  </div>
);

const styles = theme => ({
  year: { color: "#414141" },
  header: { display: "flex", justifyContent: "space-between" }
});

export default withStyles(styles)(VehicleHeader);
