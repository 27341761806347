import React, { Component } from "react";
import Modal from "../Misc/Modal";
import SelectField from "../../artzu/src/SelectField";
import Button from "../../artzu/src/Button";
import Option from "../../artzu/src/Option";
import TextField from "../../artzu/src/TextField";
import TextBox from "../../artzu/src/TextBox";
import styles from "./extensionModal.module.scss";

import gql from "graphql-tag";

import { useApolloClient } from "@apollo/client";

class ExtensionModalChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predictionId: "",
      extensionTarget: "",
      note: "",
      selectedMileage: "",
      noteError: "",
      extensionTargetError: ""
    };
  }

  addMaintenanceExtension = () => {
    const { predictionId, extensionTarget, selectedMileage, note } = this.state;
    console.log(extensionTarget, selectedMileage);
    if (
      note &&
      extensionTarget &&
      predictionId &&
      extensionTarget > selectedMileage
    ) {
      const extensionAmount = extensionTarget - selectedMileage;

      this.props.client
        .mutate({
          mutation: this.MAINTENANCE_EXTENSION,
          variables: {
            extensionParams: {
              predictionId,
              extensionAmount,
              note
            }
          },
          refetchQueries: [`getVehicle`]
        })
        .then(data => {
          this.setState({
            predictionId: "",
            extensionTarget: "",
            note: "",
            selectedMileage: "",
            noteError: "",
            extensionTargetError: ""
          });
          this.props.closeModal();
        });
    } else if (extensionTarget && extensionTarget < selectedMileage) {
      console.log("Here");
      this.setState({
        extensionTargetError:
          "'Extend To' date must be greater than current odometer reading."
      });
    } else {
      console.log(extensionTarget - selectedMileage);
      this.setState({
        noteError: note ? "" : "'Notes' is a required field.",
        extensionTargetError: extensionTarget
          ? ""
          : "'Postpone' is a required field."
      });
    }
  };

  MAINTENANCE_EXTENSION = gql`
    mutation newMaintenanceExtension(
      $extensionParams: NewMaintenanceExtensionAttributes!
    ) {
      newMaintenanceExtension(extensionParams: $extensionParams) {
        success
        errors
      }
    }
  `;

  onServiceChange = (value, label) => {
    const { projectedRoutineServices, odometer } = this.props;
    const selectedMileage =
      projectedRoutineServices.find(service => service.id === value)
        .mileageUntil + odometer.reading;
    this.setState({ predictionId: value, selectedMileage });
  };

  onExtensionChange = value =>
    this.setState({ extensionTarget: Number(value) });

  onTextChange = value => this.setState({ note: value });

  render() {
    const { selectedMileage, noteError, extensionTargetError } = this.state;
    const { projectedRoutineServices, city, odometer } = this.props;
    return (
      <Modal {...this.props}>
        {" "}
        <div className={styles.selectField}>
          <div className={styles.title}> Service: </div>
          <SelectField
            prompt="Select a service"
            onChange={(value, label) => this.onServiceChange(value, label)}
          >
            {projectedRoutineServices.map(service => (
              <Option
                value={service.id}
                label={service.name}
                key={service.id}
              />
            ))}
          </SelectField>
        </div>
        <div className={styles.mileage}>
          <div className={styles.section}>
            <div className={styles.title}>Scheduled At:</div>
            <div className={styles.number}>
              {selectedMileage || "---"} {city === "toronto" ? " km" : " mi"}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Postpone to:</div>
            <TextField
              onChange={({ target }) => this.onExtensionChange(target.value)}
              width="100%"
              className={styles.input}
            />
            <div className={styles.title}>
              {city === "toronto" ? "km" : "mi"}
            </div>
          </div>
        </div>
        <div className={styles.textBox}>
          <TextBox
            heading="Notes"
            onChange={({ target }) => this.onTextChange(target.value)}
            error={noteError || extensionTargetError}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Postpone"
            onClick={() => this.addMaintenanceExtension()}
          />
        </div>
      </Modal>
    );
  }
}

const ExtensionModal = props => {
  const client = useApolloClient();
  return <ExtensionModalChild client={client} {...props} />;
};

export default ExtensionModal;
