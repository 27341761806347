import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import DateInput from "./DateInput";
import { m } from "../../../utils";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import "./modeSelect.css";
import "../../ServiceEvents/service.css";

const TimeSelect = observer(
  class TimeSelect extends Component {
    constructor(props) {
      super(props);
      this.state = {
        startDate: this.props.markedDate
      };
    }

    handleChange = date => {
      const { name, updateTime } = this.props;
      this.setState({
        startDate: date
      });
      updateTime(name, date);
    };

    render() {
      const {
        classes,
        label,
        show,
        serviceEvent = false,
        markedDate
      } = this.props;
      return (
        <div
          className={classNames(
            serviceEvent ? "service-datetime" : "time-box",
            serviceEvent ? null : classes.timeBox,
            show ? classes.show : classes.hide,
            "pointer"
          )}
        >
          <div style={{ fontSize: "12px" }}> {label} </div>
          <DatePicker
            customInput={
              <DateInput
                className={classNames("custom-time-select")}
                date={markedDate || this.state.startDate}
              />
            }
            selected={moment(markedDate || this.state.startDate)}
            onChange={this.handleChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="LLL"
            timeCaption="time"
            todayButton={"Today"}
            classes={{ root: classes.dateInput }}
          />
        </div>
      );
    }
  }
);

const styles = theme => ({
  hide: { display: "none" },
  show: { display: "block" },
  timeBox: {
    flexDirection: "row",
    marginLeft: window.innerWidth > 1024 ? "1.5em" : "1em",
    marginBottom: "16px"
  },
  dateInput: { color: "#414141", fontWeight: "bold" }
});

export default withStyles(styles)(TimeSelect);
