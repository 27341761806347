import React, { Fragment } from "react";

import OperationStatus from "./OperationStatus";
import User from "./User";
import Comments from "./Comments";

const StatusInfo = ({ statusInterval, showBar, fullSize, timezone }) => (
  <Fragment>
    <OperationStatus
      statusInterval={statusInterval}
      showBar={showBar}
      fullSize={fullSize}
      timezone={timezone}
    />
    {fullSize && (
      <>
        <User
          userName={statusInterval.userName}
          userImageLink={statusInterval.userImageLink}
          userLink={statusInterval.userLink}
        />
        <Comments comments={statusInterval.comments} />
      </>
    )}
  </Fragment>
);

export default StatusInfo;
