import React, { Component, Fragment } from "react";

import TextField from "../../../artzu/src/TextField";
import SelectField from "../../../artzu/src/SelectField";
import Option from "../../../artzu/src/Option";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";

class HomeLotInputChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkingLotId: null,
      parkingLotName: null
    };
  }

  componentDidMount() {
    this.setState({
      parkingLotId: this.props.parkingLot.id,
      parkingLotName: this.props.name
    });
  }

  changeLot = (value, name) => {
    const {
      type,
      plate,
      editParams,
      updateEdit,
      parkingLot,
      vehicleId
    } = this.props;
    this.setState({ parkingLotId: value });
    if (value !== parkingLot.id) {
      editParams(Number(vehicleId), type, value);
      updateEdit(plate, type, name);
    }
  };

  render() {
    const {
      vehicleId,
      heading,
      isInput,
      value,
      onChange,
      children,
      name,
      prompt,
      parkingLots,
      parkingLot,
      toggleEdit,
      type,
      plate,
      updateEdit,
      editParams,
      editError,
      details
    } = this.props;

    const { parkingLotName, parkingLotId } = this.state;
    return (
      <Fragment>
        <SelectField
          prompt={prompt}
          width={details ? "200px" : "100%"}
          height="24px"
          fontSize="14px"
          fontWeight="600"
          primaryColor="#414141"
          onChange={(value, heading) => this.changeLot(value, heading)}
        >
          {parkingLots.map(lot => (
            <Option label={lot.name} value={lot.id} />
          ))}
        </SelectField>
      </Fragment>
    );
  }
}

const HomeLotInput = props => {
  const client = useApolloClient();
  return <HomeLotInputChild client={client} {...props} />;
};

export default HomeLotInput;
