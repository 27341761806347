import React from "react";
import styles from "../statusInfo.module.scss";

const StatusChangeDetail = ({ firstStatus, secondStatus }) => {
  const isSolidFir = firstStatus.styles.isSolid;
  const isSolidSec = secondStatus.styles.isSolid;
  const colorFir = firstStatus.styles.colour;
  const colorSec = secondStatus.styles.colour;
  return (
    <div className={styles.comments}>
      <div className={styles.statusInfo}>
        <span className={styles.to}>From:</span>
        <div className={styles.circleInfo}>
          <div
            className={styles.statusSymbol}
            style={
              isSolidFir
                ? { background: `${colorFir}` }
                : {
                    background: "#ffffff",
                    border: `${colorFir}`,
                  }
            }
          />
          <div>{firstStatus.text}</div>
        </div>
      </div>
      <div className={styles.statusInfo}>
        <span className={styles.to}>To:</span>
        <div className={styles.circleInfo}>
          <div
            className={styles.statusSymbol}
            style={
              isSolidSec
                ? { background: `${colorSec}` }
                : {
                    background: "#ffffff",
                    border: `${colorSec}`,
                  }
            }
          />
          <div>{secondStatus.text}</div>
        </div>
      </div>
    </div>
  );
};

export default StatusChangeDetail;
