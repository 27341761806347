import parkingIcon from "../images/ParkingIcon.svg";
import serviceIcon from "../images/ServiceIcon2.svg";
import parkingIconBadge from "../images/ParkingIconBadge.svg";
import serviceIconBadge from "../images/ServiceIconBadge.svg";

import {
  bmwTopGreen,
  // dodgeTopGreen,
  bmwTopBlue,
  dodgeTopBlue
  // bmwTopOrange,
  // dodgeTopOrange,
  // bmwTopGrey,
  // dodgeTopGrey
} from "../images/topView";
import { m } from "./index.js";

//Draws the history polyline on the map
export const drawPolyLine = (map, maps, polylineCoords) => {
  let vehiclePath;
  if (!(vehiclePath && vehiclePath.map)) {
    vehiclePath = new maps.Polyline({
      path: polylineCoords,
      geodesic: true,
      strokeColor: "#128dff",
      strokeOpacity: 0.7,
      strokeWeight: 3
    });

    vehiclePath.setMap(map);
  }
  return vehiclePath;
};

//Returns an array of the makers to be rendered on the map. Inclues parking lots and vehicles
export const getMarkerDetails = (
  data,
  parkingLots,
  serviceLocations,
  store,
  loaded,
  caps
) => {
  let markerArray = [];

  parkingLots.forEach(parkingLot =>
    markerArray.push({
      type: "parking",
      key: markerArray.length,
      lat: parkingLot.lat,
      lng: parkingLot.lng,
      details: parkingLot.address,
      icon: parkingIconBadge,
      parkingLot: parkingLot
    })
  );

  serviceLocations.forEach(serviceLocation =>
    markerArray.push({
      type: "service",
      key: markerArray.length,
      lat: serviceLocation.lat,
      lng: serviceLocation.lng,
      details: serviceLocation.address,
      icon: serviceIconBadge,
      serviceLocation: serviceLocation
    })
  );

  if (store.mode === "history" && loaded) {
    data.historyGps.forEach(coord =>
      markerArray.push({
        type: "vehicle",
        key: markerArray.length,
        lat: coord.lat,
        lng: coord.lng,
        details: m(coord.capturedAt).format("MMMM Do, YYYY, h:mm:ss a"),
        icon: bmwTopGreen,
        heading: coord.heading
      })
    );
  } else {
    data.forEach(vehicle => {
      if (
        vehicle.lastGps &&
        !vehicle.lastGps.inParking &&
        !vehicle.lastGps.inServiceLocation
      ) {
        let vehicleDetails = {
          type: "vehicle",
          key: vehicle.plate,
          lat: vehicle.lastGps.lat,
          lng: vehicle.lastGps.lng,
          details: vehicle.plate,
          heading: vehicle.lastGps.heading,
          plate: vehicle.plate,
          inParking: vehicle.lastGps.inParking
        };

        let icon;

        if (vehicle.lastGps.simulated) {
          if (process.env.REACT_APP_SIMULATED_GPS === "false") {
            return;
          }
          icon = vehicle.make === "Dodge" ? dodgeTopBlue : bmwTopBlue;
        } else {
          icon = vehicle.make === "Dodge" ? dodgeTopBlue : bmwTopBlue;
        }

        vehicleDetails.op = 1;

        if (
          store.selectedVehicle !== "none" &&
          store.selectedVehicle.id !== vehicle.id
        ) {
          vehicleDetails.op = 0.5;
        }
        vehicleDetails.icon = icon;
        markerArray.push(vehicleDetails);
      }
    });
  }
  return markerArray;
};

export const configureMap = (
  map,
  maps,
  store,
  vehicles,
  loaded = true,
  polylineCoords = []
) => {
  const filteredVehicles = store.getFilteredCars(vehicles);
  //In current mode, with no vehicle selected set the bounds of the map to all the vehicles.
  if (filteredVehicles.length > 1) {
    let bounds = new maps.LatLngBounds();
    filteredVehicles.forEach(vehicle => {
      bounds.extend({ lat: vehicle.lastGps.lat, lng: vehicle.lastGps.lng });
    });
    map.fitBounds(bounds);

    //In current mode, with a vehicle selected
  } else if (store.mode === "history" && store.history.mode === "range") {
    if (loaded && polylineCoords.length) {
      const samplingFreq = Math.ceil(polylineCoords.historyGps.length / 30);
      let bounds = new maps.LatLngBounds();

      for (
        let i = 0;
        i < polylineCoords.historyGps.length;
        i = i + samplingFreq
      ) {
        let { lat, lng } = polylineCoords.historyGps[i];
        bounds.extend({ lat, lng });
      }
      map.fitBounds(bounds);
    }
  } else if (store.mode === "history" && store.history.mode === "single") {
    map.setCenter({
      lat: filteredVehicles.historyGps[0].lat,
      lng: filteredVehicles.historyGps[0].lng
    });
    map.setZoom(14);
  } else {
    map.setCenter({
      lat: filteredVehicles[0].lastGps.lat,
      lng: filteredVehicles[0].lastGps.lng
    });
    map.setZoom(14);
  }
};

export const calcIconSize = zoom => {
  let size;
  size = zoom * 8 - 50;

  return size > 10 ? size : 10;
};
