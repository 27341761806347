import { getMirrorImage } from "../../AreaObjects/helperFunctions";

const frontBumper = [
	0.8917,
	0.6509,
	0.8885,
	0.6559,
	0.8853,
	0.661,
	0.895,
	0.661,
	0.8982,
	0.6627,
	0.9057,
	0.6694,
	0.9389,
	0.6643,
	0.9614,
	0.6593,
	0.9657,
	0.6559,
	0.9678,
	0.6492,
	0.9678,
	0.6289,
	0.9689,
	0.5261,
	0.9678,
	0.4165,
	0.9689,
	0.3608,
	0.9678,
	0.3507,
	0.9668,
	0.344,
	0.9646,
	0.3406,
	0.9603,
	0.3372,
	0.9475,
	0.3339,
	0.9303,
	0.3322,
	0.9175,
	0.3288,
	0.9057,
	0.3288,
	0.9014,
	0.3322,
	0.896,
	0.3355,
	0.8853,
	0.3372,
	0.8928,
	0.3457,
	0.911,
	0.3457,
	0.9121,
	0.349,
	0.9143,
	0.3575,
	0.911,
	0.4064,
	0.911,
	0.4097,
	0.9078,
	0.4091,
	0.896,
	0.4047,
	0.8971,
	0.4131,
	0.9078,
	0.4182,
	0.9153,
	0.4249,
	0.9185,
	0.43,
	0.9196,
	0.4317,
	0.9218,
	0.4401,
	0.9228,
	0.4519,
	0.9239,
	0.4822,
	0.9239,
	0.5143,
	0.9239,
	0.5396,
	0.9228,
	0.5514,
	0.9218,
	0.5581,
	0.9207,
	0.5615,
	0.9185,
	0.5699,
	0.9153,
	0.575,
	0.9089,
	0.58,
	0.896,
	0.5851,
	0.896,
	0.5935,
	0.911,
	0.5885,
	0.911,
	0.6036,
	0.9121,
	0.6171,
	0.9143,
	0.6289,
	0.9153,
	0.6374,
	0.9143,
	0.6424,
	0.9121,
	0.6509
];

const frontDriverHeadlight = [
	0.8917,
	0.3473,
	0.8907,
	0.3558,
	0.896,
	0.4047,
	0.911,
	0.4097,
	0.911,
	0.3895,
	0.9121,
	0.3811,
	0.9132,
	0.371,
	0.9143,
	0.3608,
	0.9132,
	0.3541,
	0.911,
	0.3473
];

const frontPassengerHeadlight = getMirrorImage(frontDriverHeadlight);

const frontGrill = [
	0.896,
	0.4131,
	0.896,
	0.5851,
	0.9068,
	0.58,
	0.9143,
	0.575,
	0.9185,
	0.5682,
	0.9218,
	0.5581,
	0.9228,
	0.5463,
	0.9239,
	0.5075,
	0.9239,
	0.4671,
	0.9218,
	0.4435,
	0.9207,
	0.435,
	0.9164,
	0.4266,
	0.911,
	0.4199,
	0.9014,
	0.4148
];

const frontBonnet = [
	0.8692,
	0.6424,
	0.8692,
	0.6509,
	0.8757,
	0.6542,
	0.8789,
	0.6559,
	0.8821,
	0.661,
	0.8842,
	0.661,
	0.8864,
	0.6593,
	0.8917,
	0.6525,
	0.8917,
	0.6391,
	0.8928,
	0.6273,
	0.8939,
	0.6138,
	0.896,
	0.5986,
	0.896,
	0.5851,
	0.896,
	0.4131,
	0.895,
	0.3979,
	0.8939,
	0.3811,
	0.8928,
	0.3693,
	0.8917,
	0.3575,
	0.8917,
	0.3524,
	0.8917,
	0.3457,
	0.8853,
	0.3372,
	0.8832,
	0.3372,
	0.88,
	0.3406,
	0.8757,
	0.344,
	0.8703,
	0.3457,
	0.8703,
	0.3794,
	0.8682,
	0.4924,
	0.8692,
	0.6003
];

const frontPlate = [
	0.9243,
	0.5479,
	0.9451,
	0.5479,
	0.9451,
	0.4521,
	0.9243,
	0.4521
];

const frontDriverSideMirror = [
	0.8703,
	0.3473,
	0.8757,
	0.3457,
	0.8746,
	0.3288,
	0.8725,
	0.3153,
	0.8703,
	0.3069,
	0.8682,
	0.3052,
	0.865,
	0.3035,
	0.8585,
	0.3052,
	0.8564,
	0.3069,
	0.8553,
	0.3136,
	0.8542,
	0.3254,
	0.8542,
	0.3305,
	0.8553,
	0.3339,
	0.8585,
	0.3355,
	0.8692,
	0.3372,
	0.8703,
	0.3389
];

const frontPassengerSideMirror = getMirrorImage(frontDriverSideMirror);

const frontWipers = [
	0.8692,
	0.6391,
	0.8682,
	0.5784,
	0.8682,
	0.5244,
	0.8682,
	0.4755,
	0.8682,
	0.4317,
	0.8692,
	0.3861,
	0.8692,
	0.3625,
	0.8692,
	0.3558,
	0.8682,
	0.3541,
	0.8671,
	0.3524,
	0.8628,
	0.3558,
	0.8628,
	0.4215,
	0.8617,
	0.4755,
	0.8617,
	0.5716,
	0.8617,
	0.6424,
	0.865,
	0.6441,
	0.8671,
	0.6458,
	0.8692,
	0.6424
];

const frontWindshield = [
	0.8585,
	0.6407,
	0.865,
	0.6441,
	0.8692,
	0.6458,
	0.8703,
	0.6441,
	0.8714,
	0.6391,
	0.8714,
	0.5986,
	0.8703,
	0.5328,
	0.8703,
	0.4907,
	0.8703,
	0.4384,
	0.8714,
	0.3895,
	0.8714,
	0.3608,
	0.8714,
	0.3541,
	0.8692,
	0.3524,
	0.865,
	0.3541,
	0.8424,
	0.3642,
	0.8274,
	0.3726,
	0.8232,
	0.376,
	0.8221,
	0.3996,
	0.821,
	0.4435,
	0.821,
	0.4924,
	0.821,
	0.5328,
	0.8221,
	0.5632,
	0.8221,
	0.5885,
	0.8232,
	0.6053,
	0.8232,
	0.6171,
	0.8232,
	0.6222,
	0.8328,
	0.6289,
	0.8457,
	0.6357
];

export {
	frontBumper,
	frontPassengerHeadlight,
	frontDriverHeadlight,
	frontGrill,
	frontBonnet,
	frontPlate,
	frontDriverSideMirror,
	frontPassengerSideMirror,
	frontWipers,
	frontWindshield
};
