import React from "react";
import { TableCell } from "../../artzu/src/Table";
import styles from "./parkingSpot.module.scss";
import {
  getBackColor,
  getChargerBackColor,
  getChargerColor,
  getChargerText,
  getColor,
  getText,
  getTimeInCorrectUnit,
} from "./utils";
import moment from "moment-timezone";

const BasicFieldCellValue = ({ text, value }) => {
  const valueFormatter = () => {
    if (text === "Status") {
      return (
        <div
          className={styles.statusTag}
          style={{
            color: `${getColor(value)}`,
            backgroundColor: `${getBackColor(value)}`,
          }}
        >
          {getText(value)}
        </div>
      );
    } else if (text === "Last updated") {
      return <div>{moment(value).format("hh:mm A MMM D, YYYY")}</div>;
    } else if (text === "Time in spot") {
      return (
        <div style={{ height: "19px" }}>{getTimeInCorrectUnit(value)}</div>
      );
    } else if (text === "Charger") {
      return (
        <div
          className={styles.parkingTag}
          style={{
            color: `${getChargerColor(value)}`,
            backgroundColor: `${getChargerBackColor(value)}`,
            border: `1px ${getChargerBackColor(value)} solid;`,
          }}
        >
          {getChargerText(value)}
        </div>
      );
    } else {
      return value ? (
        <div>{value}</div>
      ) : (
        <div className={styles.notApplicable}>N/A</div>
      );
    }
  };
  return (
    <TableCell width={"20%"}>
      <div className={styles.basicField}>
        <div className={styles.basicFieldSubTitle}>{text}</div>
        {valueFormatter()}
      </div>
    </TableCell>
  );
};

export default BasicFieldCellValue;
