import React from "react";
import Button from "../../../../artzu/src/Button";
import Modal from "../../../../artzu/src/Modal";
import styles from "./header.module.scss";
import moment from "moment";

const CurrentDTCModal = ({ open, close, currentDTCInfo }) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.currentDTC}>
        <div className={styles.titles}>
          <div className={styles.titleDTC}>DTC</div>
          <div className={styles.titleDescription}>Description</div>
          <div className={styles.bold}>Start Time</div>
        </div>
        <div className={styles.recordBox}>
          {currentDTCInfo.map((info) => (
            <div className={styles.rowDTCInfo}>
              <div className={styles.titleDTCInner}>{info.code}</div>
              <div className={styles.titleDescriptionInner}>
                {info.description}
              </div>
              <div className={styles.titleEndInner}>
                {moment(info.startTime).format("MMM D, YYYY h:mm a")}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.curDTCCloseButton}>
          <Button
            label={"Close"}
            fullWidth="152px"
            height="40px"
            color="#128DFF"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={close}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CurrentDTCModal;
