import { transform, set } from "lodash";
import {
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  camelCase
} from "lodash/fp";

function createIteratee(converter, self) {
  return (result, value, key) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

export default function snakeToCamelCase(node) {
  if (isArray(node)) return map(snakeToCamelCase, node);
  if (isPlainObject(node))
    return transform(node, createIteratee(camelCase, snakeToCamelCase));
  return node;
}
