import React, { useState } from "react";
import Modal from "../../../../artzu/src/Modal";
import styles from "../../task.module.scss";
import InformationTable from "../../../InformationTable";

const ChooseVehicleModal = ({
  open,
  toggleVehicle,
  chosenVehicle,
  taskData,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState();

  const chooseVehicle = (childData) => {
    setSelectedVehicle(childData);
  };
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.relatedVehicleTable}>
        <div className={styles.relateVehicleTitle}>Choose a vehicle</div>
        <InformationTable
          tableName={"vehicles"}
          navbar={"Vehicles"}
          hasTabs={true}
          background={"grey"}
          rowComponentType={"fleet"}
          startTerms={"Search by VIN, TLC, Plate and #Tag"}
          item={"vehicles"}
          popupSpecific={true}
          popupFunc={chooseVehicle}
        />
        <div className={styles.relateVehicleButtons}>
          <div>
            <button
              className={styles.vehicleCancelButton}
              onClick={() => {
                toggleVehicle();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={styles.vehicleChooseButton}
              onClick={() => {
                chosenVehicle(selectedVehicle);
                toggleVehicle();
              }}
            >
              Choose
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseVehicleModal;
