//src/Components/InformationTable/BatchActions/Actions/ChangeStatus/MessageBox.js
import React from "react";
import Button from "../../../../../artzu/src/Button";
import Modal from "../../../../../artzu/src/Modal";
import styles from "../../batchActions.module.scss";
import Loading from "../../../Loading";

const MessageBox = ({
  open,
  close,
  loading = false,
  errorMsg,
  confirm = null
}) => {
  return (
    <Modal open={open} backgroundColor="none" padding="0">
      <div className={styles.statusMessage}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.messageLine}>
              This status change cannot be made due to the following:
            </div>
            <div>{errorMsg && errorMsg.message}</div>
            <div className={styles.messageButton}>
              <Button
                label={"Close"}
                fullWidth="152px"
                height="40px"
                color="#414141"
                backColor="#ffffff"
                radius="20px"
                size="16px"
                weight="700"
                border="1px solid #FFFFFF"
                onClick={close}
              />
              {confirm && errorMsg?.errorType === "warning" && (
                <Button
                  label={"Confirm"}
                  fullWidth="152px"
                  height="40px"
                  color="#128DFF"
                  backColor="#ffffff"
                  radius="20px"
                  size="16px"
                  weight="700"
                  border="1px solid #FFFFFF"
                  onClick={confirm}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default MessageBox;
