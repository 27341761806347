import React from "react";
import ResolveDamage from "../../Mutations/ResolveDamage";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { m } from "../../utils";
import "./damage.scss";

const SingleOverview = ({ damage, refetch, alterDamageState }) => (
  <div className="individual-report">
    <div
      className="back-button link three-percent"
      onClick={() => alterDamageState({ mode: "overview" })}
    >
      <FontAwesomeIcon className="blue" icon={faChevronLeft} />
      <p className="blue back-text">All</p>
    </div>
    <h3 className="report-location">{damage.location}</h3>
    <p className="report-created report-description">
      {m(damage.createdAt).format("LLL")}
    </p>
    <p>
      {damage.user.fullName}: {damage.description}
    </p>
    {damage.imageLink ? (
      <div className="damage-image-div">
        <img
          src={damage.imageLink}
          alt="Unavailable"
          className="damage-image"
        />
      </div>
    ) : null}
    <div className="damage-buttons-container">
      <ResolveDamage
        resolveIds={[damage.id]}
        refetch={refetch}
        clear={() => alterDamageState({ mode: "overview" })}
      />
    </div>
  </div>
);

export default SingleOverview;
