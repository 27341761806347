import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const generateStyles = (color = "#414141", isSolid) => {
  if (isSolid) {
    return {
      backgroundColor: color
    };
  } else {
    return {
      color: color,
      border: `2px solid ${color}`
    };
  }
};

const StatusValue = ({ cellData, classes }) => {
  if (cellData) {
    const { text, color, isSolid, subtext } = cellData;

    return (
      <div className={classNames(classes)}>
        {text && color ? (
          <div className={styles.statusValue}>
            <div className={styles.statusMain}>
              <div
                className={styles.statusCircle}
                style={generateStyles(color, isSolid)}
              />
              <div className={styles.statusText}> {text}</div>
            </div>
            {subtext && <div className={styles.subtext}>{subtext}</div>}
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default StatusValue;
