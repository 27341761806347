import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloConsumer } from "@apollo/client";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PendingCommand from "../Queries/PendingCommand";

class LockEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      pendingId: null
    };
  }

  LOCK_EVENT = gql`
    mutation LockEvent($lockEventParams: LockEventAttributes!) {
      lockEvent(params: $lockEventParams) {
        success
        id
      }
    }
  `;

  toggleWaiting = () => this.setState({ waiting: !this.state.waiting });
  refresh = () => this.setState({ waiting: false, pendingId: null });

  render() {
    const { uuid, action, icon, size } = this.props;
    const { waiting, pendingId } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <Mutation
            mutation={this.LOCK_EVENT}
            variables={{
              lockEventParams: {
                uuid,
                action
              }
            }}
            onCompleted={data => {
              if (data.lockEvent) {
                this.setState({ pendingId: data.lockEvent.id });
              }
            }}
          >
            {lockEvent => (
              <div>
                {waiting ? (
                  <Button className="lock-event-button">
                    <PendingCommand
                      pendingId={pendingId}
                      refresh={this.refresh}
                      icon={icon}
                      size={size}
                    />
                  </Button>
                ) : (
                  <Button
                    className="lock-event-button"
                    onClick={() => {
                      lockEvent();
                      this.toggleWaiting();
                    }}
                  >
                    <FontAwesomeIcon
                      className="vehicle-action-button stack-icon"
                      icon={icon}
                      size={size}
                    />
                  </Button>
                )}
              </div>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default LockEvent;
