import React from "react";
import moment from "moment";

const TaskDueDate = ({ taskData }) => {
  return (
    <div>
      <div style={{ fontSize: "12px" }}>Due date</div>
      <div style={{ fontWeight: "700" }}>
        {moment(taskData.dueDate).format("MMM DD, YYYY h:mm A")}
      </div>
    </div>
  );
};

export default TaskDueDate;
