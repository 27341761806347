import React, { useState, Fragment } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import FullImageMessage from "./Message/FullImageMessage";
import Modal from "../../../artzu/src/Modal";
import styles from "../task.module.scss";

const AccidentReportModal = ({ open, toggleAccident, accidentReport }) => {
  const [isFull, setIsFull] = useState(false);
  const [curUrl, setCurUrl] = useState();

  const vehicleTowed = accidentReport && accidentReport.towed ? "Yes" : "No";
  const isInjured =
    accidentReport && accidentReport.injuredPeople.length > 0 ? "Yes" : "No";

  const carInvolved =
    accidentReport && accidentReport.thirdPartyVehicles.length > 0;
  const peopleInvolved =
    accidentReport && accidentReport.thirdPartyIndividuals.length > 0;

  const toggleImg = () => {
    setIsFull(!isFull);
    document.addEventListener("click", closeImg);
  };

  const closeImg = () => {
    setIsFull(false);
    document.removeEventListener("click", closeImg);
  };

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.accidentReportContainer}>
          <div className={styles.mainTitle}>
            Accident report
            <div className={styles.closeReport}>
              <FontAwesomeIcon
                icon={faTimes}
                size="sm"
                onClick={toggleAccident}
              />
            </div>
          </div>
          <div className={styles.accidentDetailBlock}>
            <div className={styles.subTitle}>Questionnaire</div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Is anyone injured?</div>
              <div className={styles.detailField}>{isInjured}</div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Who is injured?</div>
              <div className={styles.detailField}>
                {isInjured === "Yes"
                  ? accidentReport &&
                    accidentReport.injuredPeople.map((ppl) => (
                      <li style={{ fontWeight: 500 }}>{ppl}</li>
                    ))
                  : "N/A"}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Were you online?</div>
              <div className={styles.detailField}>
                {accidentReport && accidentReport.statusDescription}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>
                Which rideshare platform were you online at the time of the
                accident?
              </div>
              <div className={styles.detailField}>
                {accidentReport && accidentReport.ridesharePlatform}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Was the vehicle towed?</div>
              <div className={styles.detailField}>{vehicleTowed}</div>
            </div>
            {accidentReport && accidentReport.towed && (
              <div className={styles.accidentDetailsField}>
                <div className={styles.subscript}>Who towed the vehicle?</div>
                <div className={styles.detailField}>
                  {accidentReport && accidentReport.towingCompany}
                </div>
              </div>
            )}
            {accidentReport && accidentReport.towed && (
              <div className={styles.accidentDetailsField}>
                <div className={styles.subscript}>Where was it towed?</div>
                <div className={styles.detailField}>
                  {accidentReport && accidentReport.towingAddress}
                </div>
              </div>
            )}
          </div>
          <div className={styles.accidentDetailBlock}>
            <div className={styles.subTitle}>Accident details</div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Date of accident</div>
              <div className={styles.detailField}>
                {accidentReport &&
                  accidentReport.accidentTime &&
                  moment(accidentReport.accidentTime).format("MMM D, YYYY")}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Time of accident</div>
              <div className={styles.detailField}>
                {accidentReport &&
                  accidentReport.accidentTime &&
                  moment(accidentReport.accidentTime).format("h:mm A")}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>Location of accident</div>
              <div className={styles.detailField}>
                {" "}
                {accidentReport && accidentReport.accidentLocation}
              </div>
            </div>
            <div className={styles.accidentDetailsField}>
              <div className={styles.subscript}>What happened</div>
              <div className={styles.detailField}>
                {" "}
                {accidentReport && accidentReport.accidentDescription}
              </div>
            </div>
          </div>
          <div
            className={styles.accidentDetailBlock}
            style={{ borderBottom: !carInvolved && !peopleInvolved && "none" }}
          >
            <div className={styles.subTitle}>Autzu vehicle damage</div>
            <div className={styles.damageImages}>
              {accidentReport &&
                accidentReport.autzuDamageImages.map((dmg) => (
                  <img
                    className={styles.accidentImageContainer}
                    src={dmg}
                    onClick={() => {
                      setCurUrl(dmg);
                      toggleImg();
                    }}
                  ></img>
                ))}
            </div>
            {accidentReport &&
              (accidentReport.frontImage ||
                accidentReport.backImage ||
                accidentReport.driverImage ||
                accidentReport.passengerImage) && (
                <div className={styles.damageImages}>
                  {accidentReport && accidentReport.frontImage && (
                    <div className={styles.angleImageContainer}>
                      <div className={styles.subscript}>Front side</div>
                      <img
                        className={styles.accidentImageContainer}
                        src={accidentReport.frontImage}
                        onClick={() => {
                          setCurUrl(accidentReport.frontImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.backImage && (
                    <div className={styles.angleImageContainer}>
                      <div className={styles.subscript}>Back side</div>
                      <img
                        className={styles.accidentImageContainer}
                        src={accidentReport.backImage}
                        onClick={() => {
                          setCurUrl(accidentReport.backImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.driverImage && (
                    <div className={styles.angleImageContainer}>
                      <div className={styles.subscript}>Driver side</div>
                      <img
                        className={styles.accidentImageContainer}
                        src={accidentReport.driverImage}
                        onClick={() => {
                          setCurUrl(accidentReport.driverImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.passengerImage && (
                    <div className={styles.angleImageContainer}>
                      <div className={styles.subscript}>Passenger side</div>
                      <img
                        className={styles.accidentImageContainer}
                        src={accidentReport.passengerImage}
                        onClick={() => {
                          setCurUrl(accidentReport.passengerImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                </div>
              )}
          </div>
          {peopleInvolved && (
            <div className={styles.accidentDetailBlock}>
              <div className={styles.subTitle}>
                Third party details (Individual)
              </div>
              {accidentReport &&
                accidentReport.thirdPartyIndividuals.map((person) => (
                  <div>
                    {person.name && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Name</div>
                        <div className={styles.detailField}>{person.name}</div>
                      </div>
                    )}
                    {person.phone && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Phone number</div>
                        <div className={styles.detailField}>{person.phone}</div>
                      </div>
                    )}
                    {person.email && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Email</div>
                        <div className={styles.detailField}>{person.email}</div>
                      </div>
                    )}
                    {person.individualImages && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Images</div>
                        <div className={styles.damageImages}>
                          {person.individualImages.map((img) => (
                            <img
                              className={styles.accidentImageContainer}
                              src={img}
                              onClick={() => {
                                setCurUrl(img);
                                toggleImg();
                              }}
                            ></img>
                          ))}
                        </div>
                      </div>
                    )}
                    {person.notes && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Notes</div>
                        <div className={styles.detailField}>{person.notes}</div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
          {carInvolved && (
            <div
              className={styles.accidentDetailBlock}
              style={{ borderBottom: "none" }}
            >
              <div className={styles.subTitle}>
                Third party details (Vehicle)
              </div>
              {accidentReport &&
                accidentReport.thirdPartyVehicles.map((car) => (
                  <div>
                    {car.year && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Year</div>
                        <div className={styles.detailField}>{car.year}</div>
                      </div>
                    )}
                    {car.make && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Make</div>
                        <div className={styles.detailField}>{car.make}</div>
                      </div>
                    )}
                    {car.model && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Model</div>
                        <div className={styles.detailField}>{car.model}</div>
                      </div>
                    )}
                    {car.color && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>Color</div>
                        <div className={styles.detailField}>{car.color}</div>
                      </div>
                    )}
                    {car.licensePlateImage && (
                      <div className={styles.damageImages}>
                        <div className={styles.angleImageContainer}>
                          <div
                            className={styles.subscript}
                            style={{ width: "100px" }}
                          >
                            License plate
                          </div>
                          <div>{car.licensePlate}</div>
                          <img
                            className={styles.accidentImageContainer}
                            src={car.licensePlateImage}
                            onClick={() => {
                              setCurUrl(car.licensePlateImage);
                              toggleImg();
                            }}
                          ></img>
                        </div>

                        {car.licenseImage && (
                          <div className={styles.angleImageContainer}>
                            <div
                              className={styles.subscript}
                              style={{ width: "100px" }}
                            >
                              Driver license
                            </div>
                            <img
                              className={styles.accidentImageContainer}
                              src={car.licenseImage}
                              onClick={() => {
                                setCurUrl(car.licenseImage);
                                toggleImg();
                              }}
                            ></img>
                          </div>
                        )}
                        {car.insuranceImage && (
                          <div className={styles.angleImageContainer}>
                            <div
                              className={styles.subscript}
                              style={{ width: "100px" }}
                            >
                              Insurance slip
                            </div>
                            <img
                              className={styles.accidentImageContainer}
                              src={car.insuranceImage}
                              onClick={() => {
                                setCurUrl(car.insuranceImage);
                                toggleImg();
                              }}
                            ></img>
                          </div>
                        )}
                      </div>
                    )}
                    {car.vehicleDamageImages && (
                      <div className={styles.accidentDetailsField}>
                        <div className={styles.subscript}>
                          Third party vehicle damage
                        </div>
                        <div className={styles.damageImages}>
                          {car.vehicleDamageImages.map((img) => (
                            <img
                              className={styles.accidentImageContainer}
                              src={img}
                              onClick={() => {
                                setCurUrl(img);
                                toggleImg();
                              }}
                            ></img>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </Modal>
      <FullImageMessage isFull={isFull} imgUrl={curUrl} />
    </Fragment>
  );
};

export default AccidentReportModal;
