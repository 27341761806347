import React, { Component, Fragment } from "react";
import FilterSelect from "./NewDamageFilterSelect";
import OtherLocation from "./OtherLocation";
import { bmwParts, dodgeParts, interiorParts } from "./relevantAreas";
import _ from "lodash";

import "./damage.scss";

const getNearbyParts = (location, make) => {
  const carParts = make === "Dodge" ? dodgeParts : bmwParts;
  // returns curernt element and the 4 closest elements in the carParts array
  const index = _.findIndex(carParts, (part) => part.name === location);
  const carPartsLength = carParts.length;
  if (index < 3) {
    return carParts.slice(0, 7);
  } else if (index > carPartsLength - 4) {
    return carParts.slice(carPartsLength - 7, carPartsLength);
  } else {
    return carParts.slice(index - 3, index + 4);
  }
};

class NewDamageSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interiorSelectVal: "",
    };
  }

  interiorOnChange = (val) => this.setState({ interiorSelectVal: val });

  render() {
    const { location, scope, make, alterDamageState } = this.props;
    const { interiorSelectVal } = this.state;
    console.log(location);
    return (
      <Fragment>
        {scope === "exterior" ? (
          <Fragment>
            {location ? (
              <FilterSelect
                name="Location"
                val={location}
                selectOptions={getNearbyParts(location, make)}
                onChange={alterDamageState}
                selectionType="location"
              />
            ) : (
              <p>Click the damage location on the vehicle: </p>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <FilterSelect
              name="Location"
              val={interiorSelectVal}
              selectOptions={interiorParts}
              onChange={alterDamageState}
              interiorOnChange={this.interiorOnChange}
              selectionType="location"
            />
            {interiorSelectVal === "Other" ? (
              <OtherLocation alterDamageState={alterDamageState} />
            ) : null}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default NewDamageSelection;
